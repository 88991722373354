import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import ProfileBusinessHoursTableRow from './ProfileBusinessHoursTableRow';
import { useContext, useEffect, useState } from 'react';
import {
  BusinesScheduleResponse,
  createOrUpdateBusinessSchedules,
  getBusinessSchedules,
  deleteBusinessSchedules,
  Schedule,
} from '../../services/configApi/employees/employeeServices';
import {
  LOCAL_DATE_TIME_UTC_FORMAT,
  LOCAL_TIME_24_HOUR_FORMAT,
  STORAGE_USER_ID_KEY,
} from '../../services/Constant';
import { Box } from '@mui/system';
import MediumTypography from '../../components/formlib/MediumTypography';
import { Card, CardContent, Grid } from '@mui/material';
import ProfileBusssinesschart from './ProfileBusssinesschart';
import TitleText from '../../components/formlib/TitleText';
import { ResponseType } from '../../utils/type';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import ProfileBlockTime from './ProfileBlockTime';
import moment from 'moment';
import MyTimeOff from './MyTimeOff';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import dayjs from 'dayjs';

interface Data {
  date: string;
  startTime: string;
  endTime: string;
  lunchStarttime: string;
  lunchEndtime: string;
  // actions: string;
}

const headCells: HeadCell<Data>[] = [
  {
    id: 'date',
    labelId: 'dayText',
    defaultLabelId: 'Day',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'startTime',
    labelId: 'startTimeText',
    defaultLabelId: 'Start Time',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'endTime',
    labelId: 'endTimeText',
    defaultLabelId: 'End Time',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'lunchStarttime',
    labelId: 'lunchStarttimeText',
    defaultLabelId: 'Lunch Start Time',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'lunchEndtime',
    labelId: 'lunchEndtimeText',
    defaultLabelId: 'Lunch End Time',
    numeric: false,
    requiredSorting: false,
  },
  // {
  //   id: 'actions',
  //   labelId: 'actions',
  //   defaultLabelId: 'Actions',
  //   numeric: false,
  //   requiredSorting: false,
  // },
];

export default function ProfileBussinesHours() {
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');
  const [businessHours, setBusinessHours] = useState<BusinesScheduleResponse>();
  const [toastrId, setToastrId] = useState<string>();
  const [defaultMessage, setToastrDefaultMessage] = useState<string>();

  useEffect(() => {
    if (checkPermissionForFeature('backend.work_schedule', 'viewPermission')) {
      getBusinessHours();
    }
  }, []);

  const getBusinessHours = () => {
    toggleLoader(true);
    const employeeId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (employeeId === null) {
      return null;
    }
    setBusinessHours(undefined);
    getBusinessSchedules(employeeId)
      .then((response) => {
        toggleLoader(false);
        setBusinessHours(response);
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('updateFailureMessage');
          setToastrDefaultMessage('Failed to update details');
        }
      });
  };

  const handlesubmit = (schedule: Schedule) => {
    toggleLoader(true);
    const employeeId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (employeeId === null) {
      return null;
    }
    if (schedule.startTime !== null) {
      schedule.startTime = moment
        .utc(schedule.startTime, LOCAL_DATE_TIME_UTC_FORMAT)
        .format();
    } else {
      schedule.startTime =
        moment
          .utc(
            dayjs('09:00:00', LOCAL_TIME_24_HOUR_FORMAT).format(),
            LOCAL_DATE_TIME_UTC_FORMAT,
          )
          .format() ?? null;
    }
    if (schedule.endTime !== null) {
      schedule.endTime = moment
        .utc(schedule.endTime, LOCAL_DATE_TIME_UTC_FORMAT)
        .format();
    } else {
      schedule.endTime =
        moment
          .utc(
            dayjs('18:00:00', LOCAL_TIME_24_HOUR_FORMAT).format(),
            LOCAL_DATE_TIME_UTC_FORMAT,
          )
          .format() ?? null;
    }
    if (schedule.lunchStartTime !== null) {
      schedule.lunchStartTime = moment
        .utc(schedule.lunchStartTime, LOCAL_DATE_TIME_UTC_FORMAT)
        .format();
    } else {
      schedule.lunchStartTime =
        moment
          .utc(
            dayjs('13:00:00', LOCAL_TIME_24_HOUR_FORMAT).format(),
            LOCAL_DATE_TIME_UTC_FORMAT,
          )
          .format() ?? null;
    }
    if (schedule.lunchEndTime !== null) {
      schedule.lunchEndTime = moment
        .utc(schedule.lunchEndTime, LOCAL_DATE_TIME_UTC_FORMAT)
        .format();
    } else {
      schedule.lunchEndTime =
        moment
          .utc(
            dayjs('14:00:00', LOCAL_TIME_24_HOUR_FORMAT).format(),
            LOCAL_DATE_TIME_UTC_FORMAT,
          )
          .format() ?? null;
    }
    createOrUpdateBusinessSchedules(employeeId, schedule)
      .then(() => {
        getBusinessHours();
        setSuccessOrError('success');
        setToastrId('updateSuccessMessage');
      })
      .catch(() => {
        toggleLoader(false);
        setSuccessOrError('error');
        setToastrId('updateFailureMessage');
      });
  };

  const handleDeleteSchedule = (scheduleId: string) => {
    toggleLoader(true);
    deleteBusinessSchedules(scheduleId)
      .then(() => {
        getBusinessHours();
        setToastrId('deleteSuccessMessage');
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          const response = error as ApiMessage;
          if (response.code === 409) {
            setToastrId('myWorkScheduleConflictMessage');
            setToastrDefaultMessage(
              'My Work Schedule must include at least one working day',
            );
          } else {
            setToastrId('Contacts.deleteToastr');
            setToastrDefaultMessage('Failed to delete');
          }
        }
      });
  };

  const convertScheduleFromUTCtoLocal = (schedule: Schedule | null) => {
    if (schedule === null) {
      return null;
    }

    if (schedule.startTime !== null) {
      schedule.startTime = moment
        .utc(schedule.startTime, LOCAL_DATE_TIME_UTC_FORMAT)
        .local()
        .format();
    }
    if (schedule.endTime !== null) {
      schedule.endTime = moment
        .utc(schedule.endTime, LOCAL_DATE_TIME_UTC_FORMAT)
        .local()
        .format();
    }
    if (schedule.lunchStartTime !== null) {
      schedule.lunchStartTime = moment
        .utc(schedule.lunchStartTime, LOCAL_DATE_TIME_UTC_FORMAT)
        .local()
        .format();
    }
    if (schedule.lunchEndTime !== null) {
      schedule.lunchEndTime = moment
        .utc(schedule.lunchEndTime, LOCAL_DATE_TIME_UTC_FORMAT)
        .local()
        .format();
    }
    return schedule;
  };

  return (
    <>
      {toastrId && (
        <SnackBarComponent
          open={toastrId !== undefined}
          handleClose={() => {
            setToastrId(undefined);
          }}
          successOrError={successOrError}
          labelId={toastrId}
          defaultMessageId={defaultMessage}
        />
      )}
      {businessHours && (
        <>
          <Box>
            <Card
              sx={{
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
              }}
            >
              <CardContent
                sx={{
                  textAlign: 'center',
                  padding: '16px 24px',
                }}
              >
                <Box paddingBottom={'24px'}>
                  <TitleText
                    labelid="myWorkSchedule"
                    defaultlabel="My Work Schedule"
                    Sxprops={{
                      fontSize: '16px',
                      fontWeight: 700,
                      color: '#2A4241',
                      textAlign: 'left',
                    }}
                  />
                </Box>
                {businessHours.workingHours &&
                  businessHours.workingHours !== null &&
                  businessHours.scheduledHours &&
                  businessHours.scheduledHours !== null && (
                    <Grid container>
                      <Grid item md={2}>
                        <ProfileBusssinesschart
                          scheduledHours={businessHours.scheduledHours}
                          workingHours={businessHours.workingHours}
                        />
                      </Grid>
                      <Grid
                        alignItems="center"
                        item
                        md={5}
                        sx={{ m: 0, display: 'flex' }}
                        justifyContent={'center'}
                        paddingLeft={0}
                      >
                        <Box sx={{ display: 'flex' }} justifyContent={'center'}>
                          <Box
                            className="progressDot"
                            marginRight={1}
                            marginTop={0.9}
                          ></Box>
                          <MediumTypography
                            labelid="workingHoursPerWeekText"
                            defaultlabel="Working Hours Per Week"
                            sxProps={{
                              fontWeight: '500',
                              color: '#2A4241',
                            }}
                          />
                          <MediumTypography
                            sxProps={{
                              fontWeight: '700',
                              color: '#008C82',
                              paddingLeft: '5px',
                            }}
                            label={
                              businessHours.workingHours === null
                                ? ''
                                : businessHours.workingHours
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid
                        alignItems="center"
                        item
                        md={5}
                        sx={{ m: 0, display: 'flex' }}
                        justifyContent={'center'}
                      >
                        <Box sx={{ display: 'flex' }} justifyContent={'center'}>
                          <Box
                            className="activeDot"
                            marginRight={1}
                            marginTop={0.9}
                          ></Box>
                          <MediumTypography
                            labelid="scheduledHoursPerWeekText"
                            defaultlabel="Scheduled Hours Per Week"
                            sxProps={{
                              fontWeight: '500',
                              color: '#2A4241',
                            }}
                          />
                          <MediumTypography
                            sxProps={{
                              fontWeight: '700',
                              color: '#008C82',
                              paddingLeft: '5px',
                            }}
                            label={
                              businessHours.scheduledHours === null
                                ? ''
                                : businessHours.scheduledHours
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  )}
              </CardContent>
            </Card>
          </Box>
          <Box
            sx={{
              backgroundColor: '#FFF9E9',
              textAlign: 'center',
              display: 'none',
            }}
            className="p-sm"
          >
            <MediumTypography
              labelid="profile.BusinessNote"
              defaultlabel="Modifying the work schedule will lead to the removal of the future time-offs applied by this employee*"
              sxProps={{
                fontWeight: 600,
              }}
            />
          </Box>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, width: '100%' }}
              aria-label="simple table"
              className="profileTable"
            >
              <TableHeader
                className="listDataTableHead"
                headerNames={headCells}
                checkBoxRequired={false}
              />
              {businessHours.workingHours &&
                businessHours.workingHours !== null &&
                businessHours.scheduledHours &&
                businessHours.scheduledHours !== null && (
                  <TableBody className="tableRowcss">
                    <ProfileBusinessHoursTableRow
                      data={convertScheduleFromUTCtoLocal(
                        businessHours.myWeekSchedule[1],
                      )}
                      day={1}
                      handleSubmit={handlesubmit}
                      handleDelete={(scheduleId) => {
                        handleDeleteSchedule(scheduleId);
                      }}
                    />
                    <ProfileBusinessHoursTableRow
                      data={convertScheduleFromUTCtoLocal(
                        businessHours.myWeekSchedule[2],
                      )}
                      day={2}
                      handleSubmit={handlesubmit}
                      handleDelete={(scheduleId) => {
                        handleDeleteSchedule(scheduleId);
                      }}
                    />
                    <ProfileBusinessHoursTableRow
                      data={convertScheduleFromUTCtoLocal(
                        businessHours.myWeekSchedule[3],
                      )}
                      day={3}
                      handleSubmit={handlesubmit}
                      handleDelete={(scheduleId) => {
                        handleDeleteSchedule(scheduleId);
                      }}
                    />
                    <ProfileBusinessHoursTableRow
                      data={convertScheduleFromUTCtoLocal(
                        businessHours.myWeekSchedule[4],
                      )}
                      day={4}
                      handleSubmit={handlesubmit}
                      handleDelete={(scheduleId) => {
                        handleDeleteSchedule(scheduleId);
                      }}
                    />
                    <ProfileBusinessHoursTableRow
                      data={convertScheduleFromUTCtoLocal(
                        businessHours.myWeekSchedule[5],
                      )}
                      day={5}
                      handleSubmit={handlesubmit}
                      handleDelete={(scheduleId) => {
                        handleDeleteSchedule(scheduleId);
                      }}
                    />
                    <ProfileBusinessHoursTableRow
                      data={convertScheduleFromUTCtoLocal(
                        businessHours.myWeekSchedule[6],
                      )}
                      day={6}
                      handleSubmit={handlesubmit}
                      handleDelete={(scheduleId) => {
                        handleDeleteSchedule(scheduleId);
                      }}
                    />
                    <ProfileBusinessHoursTableRow
                      data={convertScheduleFromUTCtoLocal(
                        businessHours.myWeekSchedule[0],
                      )}
                      day={0}
                      handleSubmit={handlesubmit}
                      handleDelete={(scheduleId) => {
                        handleDeleteSchedule(scheduleId);
                      }}
                    />
                  </TableBody>
                )}
            </Table>
            {(!businessHours.workingHours ||
              businessHours.workingHours === null ||
              !businessHours.scheduledHours ||
              businessHours.scheduledHours === null) && (
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <MediumTypography
                  textstyle={'center'}
                  labelid="noWorkscheduleMessage"
                  defaultlabel="No work scheduled added yet"
                  paddingstyle="12px"
                />
              </Box>
            )}
          </TableContainer>
        </>
      )}
      {checkPermissionForFeature('backend.time_off', 'viewPermission') && (
        <MyTimeOff />
      )}
      {checkPermissionForFeature('backend.block_time', 'viewPermission') && (
        <ProfileBlockTime />
      )}
    </>
  );
}
