import { ResourceResponseList } from "../../../utils/type";
import { ApiMessage, ApiResponseHandler } from "../../ApiResponseHandler";
import ThomApiClient from "../../ThomApiClient";

export const uploadResourceFilesFile = async (file: File, idenTifier: string): Promise<string> => {
    const formData = new FormData();
  
    formData.append('file', file, file.name.toLowerCase());
  
    return ApiResponseHandler(
      await ThomApiClient.getInstance().post(`resource?uiIdentifier=${idenTifier}`, formData),
    );
  };


  export const deleteResourceFile = async (identifier: string): Promise<ApiMessage> => {
    return ApiResponseHandler(
      await ThomApiClient.getInstance().delete<ApiMessage>(
        `resource?uiIdentifier=${identifier}`,
      ),
    );
  };
  

  export const getResourceRepoDetails = async (
    page: number,
    size: number,
    sort: string,
    sortType: string,
    searchText: string
  ): Promise<ResourceResponseList> => {
    return ApiResponseHandler(
      await ThomApiClient.getInstance().get(
        `resource?page=${page}&size=${size}&sort=${sort}&sortType=${sortType}&search=${searchText}`,
      ),
    );
  };

  export const orphanedDelete = async (): Promise<ApiMessage> => {
    return ApiResponseHandler(
      await ThomApiClient.getInstance().delete<ApiMessage>(
        `resource/orphan`,
      ),
    );
  };
  