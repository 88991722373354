import { Box, Dialog, Grid, SxProps } from '@mui/material';
import MediumTypography from '../MediumTypography';
import ButtonComponent from '../ButtonComponent';
import { FC, useMemo } from 'react';
import { AddNewReferral } from '../../../utils/type';
import { prepareClientName } from '../../../utils/nameUtils';
import { ReactComponent as WarningIcon } from '../../../assets/images/WarningIcon.svg';
import { ReactComponent as UserProfileIcon } from '../../../assets/images/UserProfileIcon.svg';
import dayjs from 'dayjs';

export interface DuplicateClientProps {
  enable: boolean;
  referral: AddNewReferral;
  onModelClose: () => void;
  updateClient: (referral: AddNewReferral) => void;
}

const duplicateClientModelstyle: SxProps = {
  bgcolor: 'background.paper',
  padding: '20px 40px 40px 5px',
  borderRadius: '6px',
};

const DuplicateClientModel: FC<DuplicateClientProps> = ({
  enable,
  referral,
  onModelClose,
  updateClient,
}) => {
  const getStatusColor = useMemo(
    () => (status: string) => {
      if (status === 'Active') {
        return '#37D183';
      } else if (status === 'Pending') {
        return 'var(--thom-orange)';
      } else if (status === 'Inactive') {
        return '#EB4C60';
      }
      return '#2A4241';
    },
    [],
  );

  return (
    <Dialog
      open={enable}
      onClose={() => {
        onModelClose();
      }}
      fullWidth
      maxWidth={'sm'}
    >
      <Box sx={duplicateClientModelstyle}>
        <Grid container direction="row" alignItems="center" sx={{ mt: 2 }}>
          <Grid item xs={2} alignItems="center" sx={{ textAlign: 'center' }}>
            <WarningIcon style={{ height: '40px', width: '40px' }} />
          </Grid>
          <Grid item xs={10}>
            <Box>
              <MediumTypography
                labelid="DuplicateClientModal.header"
                defaultlabel="This Client already exists in TCS."
                sxProps={{
                  color: '#2A4241',
                  fontSize: '20px',
                  fontWeight: '700',
                  lineHeight: '24px',
                  fontFamily: 'Lato-Bold',
                }}
              />

              {referral.duplicateClient !== null &&
                (referral.duplicateClient.clientStatus === 'Active' ||
                  referral.duplicateClient.clientStatus === 'Pending') && (
                  <MediumTypography
                    labelid="DuplicateClientModal.activeReferalExist"
                    defaultlabel="This referral cannot be added as a client"
                    sxProps={{
                      color: '#2A4241',
                      fontSize: '20px',
                      fontWeight: '700',
                      lineHeight: '24px',
                      fontFamily: 'Lato-Bold',
                    }}
                  />
                )}
              {referral.duplicateClient !== null &&
                referral.duplicateClient.clientStatus === 'Inactive' && (
                  <MediumTypography
                    labelid="DuplicateClientModal.inAactiveReferalExist"
                    defaultlabel="Do you want to add this referral to this client?"
                    sxProps={{
                      color: '#2A4241',
                      fontSize: '20px',
                      fontWeight: '700',
                      lineHeight: '24px',
                      fontFamily: 'Lato-Bold',
                    }}
                  />
                )}
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" sx={{ mt: 2 }}>
          <Grid item xs={2}></Grid>
          <Grid item xs={10} sx={{ mt: 0.5 }}>
            <MediumTypography
              labelid="DuplicateClientModal.clientInfo"
              defaultlabel="Client Info: "
              sxProps={{
                color: '#97A6A5',
                fontSize: '20px',
                fontWeight: '700',
                lineHeight: '24px',
                fontFamily: 'Lato-Bold',
              }}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" alignItems="center" sx={{ mt: 2 }}>
          <Grid item xs={2}></Grid>

          <Grid item xs={10}>
            <Grid container direction="row">
              <Grid item xs={2.5}>
                <UserProfileIcon style={{ height: '64px', width: '64px' }} />
              </Grid>
              <Grid item xs={9.5} sx={{ mt: 0.5 }}>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={12}>
                    <Box
                      style={{
                        display: 'flex',
                      }}
                    >
                      <MediumTypography
                        labelid="DuplicateClientModal.name"
                        defaultlabel="Name: "
                        sxProps={{
                          color: '#97A6A5',
                          fontSize: '16px',
                          fontWeight: '500',
                          lineHeight: '25px',
                        }}
                      />

                      <MediumTypography
                        label={prepareClientName(
                          referral.firstName,
                          referral.middleName,
                          referral.lastName,
                        )}
                        sxProps={{
                          color: '#008C82',
                          fontSize: '16px',
                          fontWeight: '500',
                          lineHeight: '25px',
                          paddingLeft: '4px',
                          wordBreak: 'break-all',
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={4.5}>
                    <Box sx={{ display: 'flex' }}>
                      <MediumTypography
                        labelid="DuplicateClientModal.sex"
                        defaultlabel="Sex: "
                        sxProps={{
                          color: '#97A6A5',
                          fontSize: '16px',
                          fontWeight: '500',
                          lineHeight: '25px',
                        }}
                      />

                      <MediumTypography
                        label={referral.sex}
                        sxProps={{
                          color: '#2A4241',
                          fontSize: '16px',
                          fontWeight: '500',
                          lineHeight: '25px',
                          paddingLeft: '4px',
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={7.5}>
                    <Box sx={{ display: 'flex' }}>
                      <MediumTypography
                        labelid="DuplicateClientModal.dob"
                        defaultlabel="Date of Birth: "
                        sxProps={{
                          color: '#97A6A5',
                          fontSize: '16px',
                          fontWeight: '500',
                          lineHeight: '25px',
                        }}
                      />

                      <MediumTypography
                        label={dayjs(referral.dateOfBirth).format('MM/DD/YYYY')}
                        sxProps={{
                          color: '#2A4241',
                          fontSize: '16px',
                          fontWeight: '500',
                          lineHeight: '25px',
                          paddingLeft: '4px',
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={4.5}>
                    <Box sx={{ display: 'flex' }}>
                      <MediumTypography
                        labelid="DuplicateClientModal.program"
                        defaultlabel="Program: "
                        sxProps={{
                          color: '#97A6A5',
                          fontSize: '16px',
                          fontWeight: '500',
                          lineHeight: '25px',
                        }}
                      />

                      <MediumTypography
                        label={referral.duplicateClient?.programAcronym}
                        sxProps={{
                          color: '#2A4241',
                          fontSize: '16px',
                          fontWeight: '500',
                          lineHeight: '25px',
                          paddingLeft: '4px',
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={7.5}>
                    <Box sx={{ display: 'flex' }}>
                      <MediumTypography
                        labelid="DuplicateClientModal.status"
                        defaultlabel="Status: "
                        sxProps={{
                          color: '#97A6A5',
                          fontSize: '16px',
                          fontWeight: '500',
                          lineHeight: '25px',
                        }}
                      />

                      {referral.duplicateClient && (
                        <MediumTypography
                          label={referral.duplicateClient.clientStatus}
                          sxProps={{
                            color: getStatusColor(
                              referral.duplicateClient.clientStatus,
                            ),
                            fontSize: '16px',
                            fontWeight: '500',
                            lineHeight: '25px',
                            paddingLeft: '4px',
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="right" textAlign={'right'}>
          {referral.duplicateClient !== null &&
            referral.duplicateClient.clientStatus === 'Inactive' && (
              <Grid item xs={12} textAlign={'right'}>
                <Grid mt={5}>
                  <ButtonComponent
                    className="btn-primary btn-submit mr-md"
                    variantType="contained"
                    type="submit"
                    labelId="DuplicateClientModal.yesButton"
                    defaultLabelId="Yes"
                    onClick={() => {
                      updateClient(referral);
                    }}
                  />

                  <ButtonComponent
                    className="btn-primary btn-cancel"
                    variantType="contained"
                    labelId="DuplicateClientModal.noButton"
                    defaultLabelId="No"
                    onClick={() => {
                      onModelClose();
                    }}
                  />
                </Grid>
              </Grid>
            )}
          {referral.duplicateClient !== null &&
            (referral.duplicateClient.clientStatus === 'Active' ||
              referral.duplicateClient.clientStatus === 'Pending') && (
              <Grid item xs={12} textAlign={'right'}>
                <ButtonComponent
                  className="btn-primary btn-cancel"
                  variantType="contained"
                  labelId="DuplicateClientModal.closeButton"
                  defaultLabelId="Close"
                  onClick={() => {
                    onModelClose();
                  }}
                />
              </Grid>
            )}
        </Grid>
      </Box>
    </Dialog>
  );
};

export default DuplicateClientModel;
