import { ApiResponseHandler, ApiMessage } from '../../ApiResponseHandler';
import ThomApiClient from '../../ThomApiClient';

export type Codes = {
  code: string;
  description: string;
  isValidForInsurance: boolean;
  isUnderlined: boolean;
  date: Date | null | string;
  id?: string;
};
export type SubCategory = {
  subTitleName: string;
  codes: Codes[];
  id?: string;
};

export type Category = {
  categoryName?: string;
  subCategories: SubCategory[];
  id?: string;
};

export const getDiagnosticCodes = async (): Promise<Category[]> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<Category[]>(
      `code-list/diagnostic-code/all`,
    ),
  );
};

export const addDiagnosticCodes = async (
  requestBody: Category,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<Category, ApiMessage>(
      `code-list/diagnostic-code`,
      requestBody,
    ),
  );
};

export const updateDiagnosticCodes = async (
  requestBody: Category,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<Category, ApiMessage>(
      `code-list/diagnostic-code`,
      requestBody,
    ),
  );
};

export const getCodesAndDescription = async ():Promise<Codes[]>=>{
  return ApiResponseHandler(await ThomApiClient.getInstance().get<Codes[]>(`code`))
}