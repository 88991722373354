import { Dialog, DialogTitle, DialogContent, Grid, Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import MediumTypography from '../MediumTypography';
import { Formik } from 'formik';
import SmallTypography from '../SmallTypography';
import ButtonComponent from '../ButtonComponent';
import * as yup from 'yup';
import {
  checkTimeExceeding,
  convertTimeDuration,
  formatStringDateInDecimals,
  formatTimeDurationInDecimals,
} from '../../../utils/dateUtil';
import { STORAGE_USERNAME_KEY } from '../../../services/Constant';
import { EmployeeType } from '../../../utils/type';
import DurationPicker from '../DurationPicker';

interface ActivityNoteModelProps {
  open: boolean;
  nonBillableHours: string | null;
  approvedNonBillableHours: string | null;
  employeeType: EmployeeType | null;
  sickHours: string | null;
  etHours: string | null;
  ltiHours: string | null;
  profHours: string | null;
  otherHours: string | null;
  handleClose: () => void;
  onTimeEdit: (data: {
    nonBillableHours: string | null;
    approvedNonBillableHours: string | null;
    sickHours: string | null;
    etHours: string | null;
    ltiHours: string | null;
    profHours: string | null;
    otherHours: string | null;
  }) => void;
}

const validationSchema = yup.object().shape({
  nonBillableHours: yup.string().nullable(),
  approvedNonBillableHours: yup.string().nullable(),
  sickHours: yup.string().nullable(),
  etHours: yup.string().nullable(),
  ltiHours: yup.string().nullable(),
  professionalHours: yup.string().nullable(),
  otherHours: yup.string().nullable(),
  othersHours: yup.string().nullable(),
});

const ActivityEditModal: FC<ActivityNoteModelProps> = ({
  open,
  employeeType,
  nonBillableHours,
  approvedNonBillableHours,
  sickHours,
  etHours,
  ltiHours,
  profHours,
  otherHours,
  handleClose,
  onTimeEdit,
}) => {
  const [employeeName, setEmployeeName] = useState<string | null>(null);

  useEffect(() => {
    setEmployeeName(localStorage.getItem(STORAGE_USERNAME_KEY));
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
      <DialogTitle>
        <Grid container direction={'column'} rowSpacing={4}>
          <Grid item>
            <MediumTypography
              label={employeeName ?? ''}
              sxProps={{
                fontSize: '24px',
                fontWeight: 'bold',
                marginTop: '8px',
                lineHeight: '30px',
                wordBreak: 'break-word',
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            nonBillableHours: nonBillableHours,
            approvedNonBillableHours: approvedNonBillableHours,
            sickHours: sickHours,
            etHours: etHours,
            ltiHours: ltiHours,
            profHours: profHours,
            otherHours: otherHours,
          }}
          validationSchema={validationSchema}
          validateOnChange={true}
          onSubmit={(values, { setFieldError }) => {
            if (
              values.nonBillableHours !== null &&
              checkTimeExceeding(values.nonBillableHours, '12:00')
            ) {
              setFieldError('nonBillableHours', 'hrsLimitMessage');
              return;
            }
            if (
              values.approvedNonBillableHours !== null &&
              checkTimeExceeding(values.approvedNonBillableHours, '12:00')
            ) {
              setFieldError('approvedNonBillableHours', 'hrsLimitMessage');
              return;
            }

            if (
              values.sickHours !== null &&
              checkTimeExceeding(values.sickHours, '12:00')
            ) {
              setFieldError('sickHours', 'hrsLimitMessage');
              return;
            }

            if (
              values.etHours !== null &&
              checkTimeExceeding(values.etHours, '12:00')
            ) {
              setFieldError('etHours', 'hrsLimitMessage');
              return;
            }

            if (
              values.ltiHours !== null &&
              checkTimeExceeding(values.ltiHours, '12:00')
            ) {
              setFieldError('ltiHours', 'hrsLimitMessage');
              return;
            }

            if (
              values.profHours !== null &&
              checkTimeExceeding(values.profHours, '12:00')
            ) {
              setFieldError('profHours', 'hrsLimitMessage');
              return;
            }

            if (
              values.otherHours !== null &&
              checkTimeExceeding(values.otherHours, '12:00')
            ) {
              setFieldError('otherHours', 'hrsLimitMessage');
              return;
            }

            const nonBillablehoursConversion = convertTimeDuration(
              values.nonBillableHours,
              false,
            );

            const ApprovedNonBillablehoursConversion = convertTimeDuration(
              values.approvedNonBillableHours,
              false,
            );

            const sickHoursConversion = convertTimeDuration(
              values.sickHours,
              false,
            );

            const etHoursConversion = convertTimeDuration(
              values.etHours,
              false,
            );

            const ltiHoursConversion = convertTimeDuration(
              values.ltiHours,
              false,
            );

            const profHoursConversion = convertTimeDuration(
              values.profHours,
              false,
            );

            const otherHoursConcersion = convertTimeDuration(
              values.otherHours,
              false,
            );

            onTimeEdit({
              nonBillableHours: nonBillablehoursConversion,
              approvedNonBillableHours: ApprovedNonBillablehoursConversion,
              sickHours: sickHoursConversion,
              etHours: etHoursConversion,
              ltiHours: ltiHoursConversion,
              profHours: profHoursConversion,
              otherHours: otherHoursConcersion,
            });
          }}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue }) => (
            <Grid sx={{ marginTop: '8px' }}>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
                <Grid item xs={6}>
                  <DurationPicker
                    interval={25}
                    required={false}
                    labelId="nonBillableHrsText"
                    defaultLabel="Non-Billable Hrs"
                    maxDuration={{ hour: 12, minutes: 0 }}
                    duration={formatStringDateInDecimals(
                      values.nonBillableHours,
                    )}
                    setDuration={(duration) => {
                      setFieldValue(
                        'nonBillableHours',
                        formatTimeDurationInDecimals(duration),
                      );
                    }}
                  />
                  {errors.nonBillableHours && touched.nonBillableHours && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.nonBillableHours}
                      defaultLabelId="Field should not be empty"
                    />
                  )}
                </Grid>

                <Grid item xs={6}>
                  <DurationPicker
                    interval={25}
                    required={false}
                    labelId="approvedNonBillableHrsText"
                    defaultLabel="Approved Non-Billable Hrs"
                    maxDuration={{ hour: 12, minutes: 0 }}
                    duration={formatStringDateInDecimals(
                      values.approvedNonBillableHours,
                    )}
                    setDuration={(duration) => {
                      setFieldValue(
                        'approvedNonBillableHours',
                        formatTimeDurationInDecimals(duration),
                      );
                    }}
                  />
                  {errors.approvedNonBillableHours &&
                    touched.approvedNonBillableHours && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId={errors.approvedNonBillableHours}
                        defaultLabelId="Field should not be empty"
                      />
                    )}
                </Grid>

                <Grid item xs={6} sx={{ pointerEvents: 'none', opacity: 0.5 }}>
                  <DurationPicker
                    interval={25}
                    required={false}
                    labelId="sickHrsText"
                    defaultLabel="Sick Hrs"
                    maxDuration={{ hour: 12, minutes: 0 }}
                    duration={formatStringDateInDecimals(values.sickHours)}
                    setDuration={(duration) => {
                      setFieldValue(
                        'sickHours',
                        formatTimeDurationInDecimals(duration),
                      );
                    }}
                  />
                  {errors.sickHours && touched.sickHours && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.sickHours}
                      defaultLabelId="Field should not be empty"
                    />
                  )}
                </Grid>

                {employeeType !== null &&
                  employeeType === 'Salaried Exempt' && (
                    <Grid
                      item
                      xs={6}
                      sx={{ pointerEvents: 'none', opacity: 0.5 }}
                    >
                      <DurationPicker
                        interval={25}
                        required={false}
                        labelId="etHoursText"
                        defaultLabel="ET Hrs"
                        maxDuration={{ hour: 12, minutes: 0 }}
                        duration={formatStringDateInDecimals(values.etHours)}
                        setDuration={(duration) => {
                          setFieldValue(
                            'etHours',
                            formatTimeDurationInDecimals(duration),
                          );
                        }}
                      />
                      {errors.etHours && touched.etHours && (
                        <SmallTypography
                          sxProps={{ color: 'red' }}
                          labelId={errors.etHours}
                          defaultLabelId="Field should not be empty"
                        />
                      )}
                    </Grid>
                  )}

                {employeeType !== null &&
                  employeeType === 'Salaried Exempt' && (
                    <Grid
                      item
                      xs={6}
                      sx={{ pointerEvents: 'none', opacity: 0.5 }}
                    >
                      <DurationPicker
                        interval={25}
                        required={false}
                        labelId="ltiHoursText"
                        defaultLabel="LTI Hrs"
                        maxDuration={{ hour: 12, minutes: 0 }}
                        duration={formatStringDateInDecimals(values.ltiHours)}
                        setDuration={(duration) => {
                          setFieldValue(
                            'ltoHours',
                            formatTimeDurationInDecimals(duration),
                          );
                        }}
                      />
                      {errors.ltiHours && touched.ltiHours && (
                        <SmallTypography
                          sxProps={{ color: 'red' }}
                          labelId={errors.ltiHours}
                          defaultLabelId="Field should not be empty"
                        />
                      )}
                    </Grid>
                  )}

                {employeeType !== null &&
                  employeeType === 'Salaried Exempt' && (
                    <Grid
                      item
                      xs={6}
                      sx={{ pointerEvents: 'none', opacity: 0.5 }}
                    >
                      <DurationPicker
                        interval={25}
                        required={false}
                        labelId="profHoursText"
                        defaultLabel="Professional Hrs"
                        maxDuration={{ hour: 12, minutes: 0 }}
                        duration={formatStringDateInDecimals(values.profHours)}
                        setDuration={(duration) => {
                          setFieldValue(
                            'profHours',
                            formatTimeDurationInDecimals(duration),
                          );
                        }}
                      />
                      {errors.profHours && touched.profHours && (
                        <SmallTypography
                          sxProps={{ color: 'red' }}
                          labelId={errors.profHours}
                          defaultLabelId="Field should not be empty"
                        />
                      )}
                    </Grid>
                  )}

                {employeeType !== null &&
                  employeeType === 'Salaried Exempt' && (
                    <Grid
                      item
                      xs={6}
                      sx={{ pointerEvents: 'none', opacity: 0.5 }}
                    >
                      <DurationPicker
                        interval={25}
                        required={false}
                        labelId="otherHoursText"
                        defaultLabel="Other Hrs"
                        maxDuration={{ hour: 12, minutes: 0 }}
                        duration={formatStringDateInDecimals(values.otherHours)}
                        setDuration={(duration) => {
                          setFieldValue(
                            'otherHours',
                            formatTimeDurationInDecimals(duration),
                          );
                        }}
                      />
                      {errors.otherHours && touched.otherHours && (
                        <SmallTypography
                          sxProps={{ color: 'red' }}
                          labelId={errors.otherHours}
                          defaultLabelId="Field should not be empty"
                        />
                      )}
                    </Grid>
                  )}
              </Grid>

              <Box
                sx={{ marginTop: '24px' }}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <ButtonComponent
                  className="btn-primary btn-cancel"
                  labelId={'cancelText'}
                  variantType="outlined"
                  onClick={handleClose}
                />
                <ButtonComponent
                  className="btn-primary btn-submit ml-md"
                  labelId={'Insurance.update'}
                  onClick={handleSubmit}
                />
              </Box>
            </Grid>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ActivityEditModal;
