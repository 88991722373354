import { Box } from '@mui/material';
import { UpcommingEventItem } from '../../services/configApi/employees/employeeServices';
import { FC } from 'react';
import { ReactComponent as ArrowRight } from '../../assets/images/rightWhite.svg';
import MediumTypography from '../../components/formlib/MediumTypography';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { checkPermissionForFeature } from '../../utils/checkPermission';

interface UpcommingEventsProps {
  events: UpcommingEventItem[];
  handleNavigation: () => void;
  labelId: string;
}

const UpcommingEventsRow: FC<{ event: UpcommingEventItem; index: number }> = ({
  event,
  index,
}) => {
  const rctl = useIntl();

  return (
    <Box sx={{ width: '98%' }}>
      {index !== 0 && <Box className={'defaultHorizontalLine'} />}
      <Box className={'flex__'}>
        <Box className={'flex__'} sx={{ flexGrow: 1, alignItems: 'baseline' }}>
          <MediumTypography
            sxProps={{
              color: 'white',
              marginRight: '4px',
              fontSize: '16px',
              fontWeight: 'bold',
            }}
            label={
              event.date !== null ? dayjs(event.date).format('MMM DD,') : ''
            }
          />
          <MediumTypography
            sxProps={{ color: 'white' }}
            label={
              event.date !== null ? dayjs(event.date).format('YYYY - dddd') : ''
            }
          />
        </Box>
        <MediumTypography
          sxProps={{ color: 'white', fontWeight: 'bold' }}
          label={
            event.type !== null
              ? rctl.formatMessage({ id: event.type, defaultMessage: '-' })
              : '-'
          }
        />
      </Box>
    </Box>
  );
};

const UpcommingEvents: FC<UpcommingEventsProps> = ({
  events,
  handleNavigation,
  labelId,
}) => {
  return (
    <Box className={'p-md'}>
      <Box className={'flex__ pt-xm'}>
        <MediumTypography
          sxProps={{ flexGrow: 1, color: 'white' }}
          className="mainText-lg"
          labelid={labelId}
          defaultlabel="Upcoming Events"
        />
        {checkPermissionForFeature('backend.appointment', 'viewPermission') && (
          <Box sx={{ cursor: 'pointer' }} onClick={handleNavigation}>
            <ArrowRight stroke="white" />
          </Box>
        )}
      </Box>

      <Box
        className={'pt-xs'}
        sx={{
          overflowY: 'scroll',
          height: '195px',
          '&::-webkit-scrollbar': {
            width: '6px',
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(12, 130, 121, 1)',
            borderRadius: '4px',
            border: '1px solid rgba(12, 130, 121, 1)',
          },
        }}
      >
        {events.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '12%',
            }}
          >
            <MediumTypography
              sxProps={{ fontWeight: 'bold', color: 'white' }}
              labelid="noUpcommingFoundText"
              defaultlabel="No Upcoming Events found"
            />
          </Box>
        )}
        {events.map((task, index) => (
          <UpcommingEventsRow event={task} index={index} />
        ))}
      </Box>
    </Box>
  );
};

export default UpcommingEvents;
