import dayjs, { Dayjs } from 'dayjs';
import { ApiMessage, ApiResponseHandler } from '../../ApiResponseHandler';
import ThomApiClient from '../../ThomApiClient';

export interface InsuranceType {
  insuranceId: string | null;
  insuranceCompanyName: string;
  payerId: string;
  phoneNumber: string;
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  state: string;
  modifiedOn?: string;
  startDate: Dayjs | null | string;
  endDate: Dayjs | null | string;
}

export interface GetInsuranceResponse {
  insuranceCount: number | null;
  insurances: InsuranceType[] | null;
}

export const getMasterInsuranceList = async (
  search: string,
  page: number,
  size: number,
  sort: string,
  sortType: string,
): Promise<GetInsuranceResponse> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<GetInsuranceResponse>(
      `master/insurance?search=${search}&page=${page}&size=${size}&sort=${sort}&sortType=${sortType.toUpperCase()}`,
    ),
  );
};

export const createOrUpdateMasterInsurance = async (
  insurance: InsuranceType,
): Promise<ApiMessage> => {
  if (insurance.insuranceId === null) {
    return ApiResponseHandler(
      await ThomApiClient.getInstance().post<InsuranceType, ApiMessage>(
        'master/insurance',
        insurance,
      ),
    );
  } else {
    return ApiResponseHandler(
      await ThomApiClient.getInstance().put<InsuranceType, ApiMessage>(
        'master/insurance',
        insurance,
      ),
    );
  }
};

export const deleteMasterInsurance = async (
  insuranceId: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(
      `master/insurance?insuranceId=${insuranceId}`,
    ),
  );
};

export const getMasterInsuranceListAll = async (): Promise<InsuranceType[]> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<InsuranceType[]>(
      `master/insurance/all?timezone=${timezone}`,
    ),
  );
};
