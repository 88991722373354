import {
  AlertColor,
  Box,
  Card,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Grid,
} from '@mui/material';
import TitleText from '../../components/formlib/TitleText';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { useContext, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import MediumTypography from '../../components/formlib/MediumTypography';
import { CustomTableHeader } from './CustomTableHeader';
import { headCells } from './TravelExpenses';
import { TableHeader } from '../../components/formlib/TableHeader';
import ReviewTravelExpenseEmployeeTable from './ReviewTravelExpenseEmployeeTable';
import AppPagination from '../../components/shared/AppPagination';
import SignatureComponent from '../../components/formlib/modal/SignatureComponent';
import React from 'react';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import ModalPopup from '../../components/formlib/ModalPopup';
import ActivityNoteModal from '../../components/formlib/modal/ActivityNoteModal';
import {
  GetTravelExpenseResponse,
  approveOrRejectTravelExpenses,
  getReviewTravelExpenses,
  getTravelExpenseByEmployeeId,
} from '../../services/travelExpenses/TravelExpensesApi';
import ExpenseTableRow from './ExpenseTableRow';
import { STORAGE_USER_ID_KEY } from '../../services/Constant';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import moment from 'moment';
import { ReactComponent as Editsignature } from '../../assets/images/editsignature.svg';
import { checkPermissionForFeature } from '../../utils/checkPermission';
interface ReviewTable {
  employeeId: string;
  employeeName: string;
  expenseDate: string;
  dateOfSubmission: string;
  totalAmount: string;
  status: string;
  travelId: string;
}

export interface ReviewTravelExpenseTabelType {
  reviewExpenses: ReviewTable[];
  reviewExpensesCount: number;
}

type HeadCell = {
  id: string;
  labelId: string;
  defaultLabelId: string;
  numeric: boolean;
  requiredSorting: boolean;
};
export const reviewHeadCells: HeadCell[] = [
  {
    id: 'employee',
    labelId: 'Employee',
    defaultLabelId: 'Employee',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'expenseDate',
    labelId: 'Expense Month',
    defaultLabelId: 'Expense Month',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'dateSubmitted',
    labelId: 'Date Submitted',
    defaultLabelId: 'Date Submitted',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'totalAmount',
    labelId: 'Total Amount',
    defaultLabelId: 'Total Amount',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'status',
    labelId: 'Status',
    defaultLabelId: 'Status',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'action',
    labelId: 'Action',
    defaultLabelId: 'Action',
    numeric: false,
    requiredSorting: false,
  },
];

const ReviewTravelExpense = () => {
  const [type, setType] = useState<string>('list');
  const [reviewTableData, setReviewTableData] =
    useState<ReviewTravelExpenseTabelType>();
  const [reviewEmployeeTableData, setReviewEmployeeTableData] =
    useState<GetTravelExpenseResponse>();
  const [employeeName, setEmployeeName] = useState<string>('');
  const [selectedEmpId, setSelectedEmpId] = useState<string>('');
  const currentDate = dayjs().format('MMMM YYYY');
  const [selectedSubmissionDate, setSelectedSubmissionDate] =
    useState(currentDate);
  const [selectedExpenseMonth, setSelectedExpenseMonth] = useState(currentDate);
  const [page, setPage] = useState(0);
  const [employeeReviewTablePage, setEmployeeReviewTablePage] = useState(0);
  const [supervisiorSignatureModal, setSupervisiorSignatureModal] =
    useState(false);
  const [signature, setSignature] = useState('');
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [approvalModal, setApprovalModal] = useState(false);
  const [openNoteModel, setOpenNoteModel] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const handleSubmissionDateChange = (date: Dayjs | null) => {
    if (date) {
      const formattedDate = dayjs(date).format('MMMM YYYY');
      setSelectedSubmissionDate(formattedDate);
    }
  };

  const handleExpenseDateChange = (date: Dayjs | null) => {
    if (date) {
      const formattedDate = dayjs(date).format('MMMM YYYY');
      setSelectedExpenseMonth(formattedDate);
    }
  };
  const month = parseInt(
    dayjs(selectedSubmissionDate, 'MMMM YYYY').format('MM'),
    10,
  );
  const year = parseInt(
    dayjs(selectedSubmissionDate, 'MMMM YYYY').format('YYYY'),
    10,
  );

  const handleSuccess = (successError: AlertColor, id: string, mes: string) => {
    setOpen(true);
    setToastrVariable(successError);
    setToastrId(id);
    setToastrDefaultMessage(mes);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleCloseNoteModal = () => {
    setOpenNoteModel(false);
    if (note === '') {
      setNote('');
    }
  };

  function getMonthStartAndEnd(monthNumber: number, yearNumber: number) {
    // Get the start of the fiscal month
    const startFiscalMonth = moment(
      `${yearNumber}-${monthNumber}-01 00:00:00`,
      'YYYY-MM-DD HH:mm:ss',
    )
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss');

    // Get the end of the fiscal month
    let endFiscalMonth;
    if (monthNumber === 12) {
      endFiscalMonth = moment(
        `${yearNumber + 1}-01-01 00:00:00`,
        'YYYY-MM-DD HH:mm:ss',
      )
        .subtract(1, 'second')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss');
    } else {
      endFiscalMonth = moment(
        `${yearNumber}-${monthNumber + 1}-01 00:00:00`,
        'YYYY-MM-DD HH:mm:ss',
      )
        .subtract(1, 'second')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss');
    }

    return {
      fiscalStartTime: startFiscalMonth,
      fiscalEndTime: endFiscalMonth,
    };
  }

  const getReviewTableData = (
    employeeId: string,
    expenceMonthYear?: string,
  ) => {
    toggleLoader(true);
    const { fiscalStartTime, fiscalEndTime } = getMonthStartAndEnd(month, year);

    if (expenceMonthYear) {
      const { expenseMonth, expenseYear } = parseMonthYear(expenceMonthYear);
      function parseMonthYear(monthYearString: string) {
        const [monthName, yearValue] = monthYearString.split(' ');
        const monthNumber = getMonthNumber(monthName);
        return {
          expenseMonth: monthNumber,
          expenseYear: parseInt(yearValue, 10),
        };
      }
      function getMonthNumber(monthName: string) {
        const monthNames = [
          'JANUARY',
          'FEBRUARY',
          'MARCH',
          'APRIL',
          'MAY',
          'JUNE',
          'JULY',
          'AUGUST',
          'SEPTEMBER',
          'OCTOBER',
          'NOVEMBER',
          'DECEMBER',
        ];
        const monthIndex = monthNames.indexOf(monthName.toUpperCase());
        if (monthIndex === -1) {
          throw new Error('Invalid month name');
        }
        return monthIndex + 1; // Add 1 to convert to a 1-based index
      }
      setSelectedExpenseMonth(dayjs(expenceMonthYear).format('MMMM YYYY'));
      getTravelExpenseByEmployeeId(
        employeeId,
        fiscalStartTime,
        fiscalEndTime,
        expenseMonth,
        expenseYear,
        employeeReviewTablePage,
      )
        .then((response) => {
          if (response) {
            toggleLoader(false);
            setType('view');
            if (response.expenses.length !== 0) {
              response.expenses.forEach((exp) => {
                exp.expenseDate = dayjs(exp.expenseDate, 'YYYY-MM-DD').format(
                  'MM/DD/YYYY',
                );
              });
            }
            setReviewEmployeeTableData(response);
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('travelExpensesFailedToLoadError');
            setToastrDefaultMessage('Failed to load travel expenses');
          }
        });
    }
  };

  useEffect(() => {
    if (selectedEmpId !== '') {
      getReviewTableData(selectedEmpId, selectedExpenseMonth);
    }
  }, [employeeReviewTablePage]);

  useEffect(() => {
    if (type === 'list') {
      toggleLoader(true);
      const employeeId = localStorage.getItem(STORAGE_USER_ID_KEY);

      if (employeeId === null) {
        return;
      }
      const { fiscalStartTime, fiscalEndTime } = getMonthStartAndEnd(
        month,
        year,
      );

      getReviewTravelExpenses(fiscalStartTime, fiscalEndTime)
        .then((response) => {
          toggleLoader(false);
          if (response) {
            setReviewTableData(response);
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('travelExpensesFailedToLoadError');
            setToastrDefaultMessage('Failed to load travel expenses');
          }
        });
    } else {
      getReviewTableData(selectedEmpId, selectedExpenseMonth);
    }
  }, [
    selectedSubmissionDate,
    selectedExpenseMonth,
    type,
    employeeName,
    selectedEmpId,
  ]);
  return (
    <Box component="main">
      <Box component="section">
        {
          <SnackBarComponent
            open={open}
            handleClose={handleClose}
            successOrError={toastrVariable}
            labelId={toastrId}
            defaultMessageId={toastrDefaultMessage}
          />
        }
        {openNoteModel && (
          <ActivityNoteModal
            positiveButtonId="reject"
            placeholderId="travelExpensesRejectionNotePlaceholder"
            closeOnBackdropClick={false}
            open={openNoteModel}
            note={note}
            handleClose={handleCloseNoteModal}
            maxLength={300}
            updateNote={(value: string) => {
              if (reviewEmployeeTableData?.travelId) {
                toggleLoader(true);
                approveOrRejectTravelExpenses(
                  'Rejected',
                  value,
                  signature,
                  reviewEmployeeTableData?.travelId,
                )
                  .then(() => {
                    toggleLoader(false);
                    setOpen(true);
                    setType('list');
                    setToastrVariable('success');
                    setToastrId('travelExpensesRejectedSuccess');
                    setToastrDefaultMessage(
                      'Travel expenses rejected successfully',
                    );
                  })
                  .catch((error) => {
                    toggleLoader(false);
                    setOpen(true);
                    setToastrVariable('error');
                    if (isCustomError(error)) {
                      const apiError = error as ApiError;
                      setToastrId(apiError.id);
                      setToastrDefaultMessage(apiError.message);
                    } else {
                      setToastrId('travelExpensesFailedToRejectError');
                      setToastrDefaultMessage('Failed to load travel expenses');
                    }
                  });
              }
              setOpenNoteModel(false);
            }}
          />
        )}
        {approvalModal &&
        signature === '' &&
        reviewEmployeeTableData?.managerSignatureRowKey === '' ? (
          <ModalPopup
            open={approvalModal}
            description="reviewTravelExpense.Approve"
            onCancel={() => setApprovalModal(false)}
            labelId1="reviewTravelExpense.close"
            negativeActionLabel="Close"
            hidePositiveBtn={true}
          />
        ) : (
          <ModalPopup
            open={approvalModal}
            description="travelApprovalSubmission"
            onCancel={() => setApprovalModal(false)}
            onOk={() => {
              if (reviewEmployeeTableData?.travelId) {
                toggleLoader(true);
                approveOrRejectTravelExpenses(
                  'Approved',
                  '',
                  signature,
                  reviewEmployeeTableData?.travelId,
                )
                  .then(() => {
                    toggleLoader(false);
                    setOpen(true);
                    setType('list');
                    setToastrVariable('success');
                    setToastrId('travelExpensesApprovedSuccess');
                    setToastrDefaultMessage(
                      'Travel expenses approved successfully',
                    );
                  })
                  .catch((error) => {
                    toggleLoader(false);
                    setOpen(true);
                    setToastrVariable('error');
                    if (isCustomError(error)) {
                      const apiError = error as ApiError;
                      setToastrId(apiError.id);
                      setToastrDefaultMessage(apiError.message);
                    } else {
                      setToastrId('travelExpensesFailedToApproveError');
                      setToastrDefaultMessage(
                        'Failed to approve travel expenses',
                      );
                    }
                  });
              }
              setApprovalModal(false);
            }}
            labelId1="TimeOff.No"
            negativeActionLabel="No"
            labelId2="TimeOff.Yes"
            positiveActionLabel="Yes"
          />
        )}
        {supervisiorSignatureModal && (
          <Box component="div">
            {checkPermissionForFeature(
              'backend.review_travel_expense',
              'editPermission',
            ) && (
              <SignatureComponent
                onCancelClick={() => {
                  setSupervisiorSignatureModal(false);
                }}
                signatureRowKey={
                  reviewEmployeeTableData?.managerSignatureRowKey || ''
                }
                signaturePartitionKey={
                  reviewEmployeeTableData?.managerSignatureRowKey !== ''
                    ? 'travelExpense'
                    : ''
                }
                signatureSaveOrUPdate={(sign: string) => {
                  setSignature(sign);
                  setSupervisiorSignatureModal(!supervisiorSignatureModal);
                }}
                handleSuccess={handleSuccess}
                type="Employee"
                signature={signature}
                signClicked={supervisiorSignatureModal}
                hideRemoveButton={
                  (reviewEmployeeTableData &&
                    reviewEmployeeTableData.status === 'Approved') ||
                  (reviewEmployeeTableData &&
                    reviewEmployeeTableData.status === 'Rejected')
                }
                resetCallBackForTypeEmployee={() => {
                  setSupervisiorSignatureModal(false);
                  setSignature('');
                }}
              />
            )}
          </Box>
        )}
        <Grid container xs={12} lg={12} className="flex__ mt-md mb-lg">
          <Grid item xs={3} lg={3}>
            <Box className="flex__">
              {type === 'view' && (
                <Box className="pt-sm">
                  <CustomBackArrow
                    onClick={() => {
                      setSelectedSubmissionDate(currentDate);
                      setType('list');
                    }}
                  />
                </Box>
              )}

              <TitleText
                labelid="Review Travel Expense"
                defaultlabel="Review Travel Expense"
                className="ml-sm pt-xs"
              />
            </Box>
          </Grid>
          <Grid item xs={9} lg={9}>
            <Box className="display-flex-center justifyContent-FlexEnd">
              <Box className="display-flex-center ">
                <MediumTypography
                  textColor="#97A6A5"
                  label="Submitted Month"
                  className="mr-md"
                  sxProps={{
                    whiteSpace: 'nowrap',
                  }}
                />
                <Box className="btn_width_170px">
                  <DatePickerComponent
                    needCrossIcon={false}
                    disableManualInput={true}
                    labelid=""
                    disableFuture={false}
                    defaultlabelid=""
                    value={
                      selectedSubmissionDate
                        ? (dayjs(selectedSubmissionDate) as Dayjs)
                        : null
                    }
                    onYearChange={handleSubmissionDateChange}
                    labelRequired={true}
                    views={['month', 'year']}
                    inputFormat="MMMM YYYY"
                  />
                </Box>
              </Box>
              {type === 'view' && (
                <Box className="display-flex-center">
                  <MediumTypography
                    textColor="#97A6A5"
                    label="Expense Month"
                    className="ml-md mr-md"
                    sxProps={{
                      whiteSpace: 'nowrap',
                    }}
                  />
                  <Box className="btn_width_170px">
                    <DatePickerComponent
                      needCrossIcon={false}
                      disableManualInput={true}
                      labelid=""
                      disableFuture={false}
                      defaultlabelid=""
                      value={
                        selectedExpenseMonth
                          ? (dayjs(selectedExpenseMonth) as Dayjs)
                          : null
                      }
                      onYearChange={handleExpenseDateChange}
                      labelRequired={true}
                      views={['month', 'year']}
                      inputFormat="MMMM YYYY"
                    />
                  </Box>
                </Box>
              )}
              {type === 'view' && (
                <ButtonComponent
                  sxProps={{ display: 'none' }}
                  className="btn-primary btn-submit  ml-md"
                  labelId="Generate Report"
                  defaultLabelId="Generate Report"
                  variantType="contained"
                  onClick={() => {}}
                />
              )}
            </Box>
          </Grid>
        </Grid>
        {type === 'view' && reviewEmployeeTableData && (
          <Box component="div">
            <Card className="formCardview">
              <Box className="flex__ justifyContentSpaceBtw ">
                <Box className="mb-md flex__">
                  <MediumTypography
                    labelid="Employee Name:"
                    defaultlabel="Employee Name:"
                    sxProps={{
                      color: '#97A6A5',
                      fontWeight: 700,
                    }}
                  />
                  <MediumTypography
                    label={employeeName}
                    sxProps={{
                      fontWeight: 700,
                    }}
                    className="pl-xs"
                  />
                </Box>
                <Box className="flex__ ">
                  <MediumTypography
                    labelid="travelExpense.totalAmount"
                    defaultlabel="Total Amount ($):"
                    textColor="#97A6A5"
                    fontweight={700}
                  />
                  <MediumTypography
                    label={reviewEmployeeTableData.totalAmount}
                    fontweight={700}
                    className="pl-xs"
                  />
                </Box>
              </Box>
              <TableContainer sx={{ height: '400px', maxHeight: 'auto' }}>
                <Table stickyHeader aria-label="sticky table">
                  <CustomTableHeader
                    headerNames={headCells}
                    numSelected={0}
                    labelSxProp={{
                      paddingLeft: '10px',
                    }}
                  />
                  <TableBody className="tableRowcss travelTablebody ">
                    {reviewEmployeeTableData.expenses.map((expense, index) => (
                      <ExpenseTableRow
                        data={expense}
                        index={index}
                        review={
                          checkPermissionForFeature(
                            'backend.review_travel_expense',
                            'editPermission',
                          ) ||
                          checkPermissionForFeature(
                            'backend.review_travel_expense',
                            'viewPermission',
                          )
                        }
                        key={index}
                        handleDataChange={() => {}}
                        mileageRate={reviewEmployeeTableData.mileageRate}
                        status={reviewEmployeeTableData.status}
                        month={month.toString()}
                        year={year.toString()}
                      />
                    ))}
                  </TableBody>
                </Table>
                {reviewEmployeeTableData.expenses.length === 0 && (
                  <Box sx={{ width: '100%' }}>
                    <MediumTypography
                      textstyle={'center'}
                      labelid="No data added yet"
                      defaultlabel="No data added yet"
                      paddingstyle="16px"
                    />
                  </Box>
                )}
              </TableContainer>
              {reviewEmployeeTableData.expensesCount &&
              reviewEmployeeTableData.expensesCount > 6 ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <AppPagination
                    pageNumber={employeeReviewTablePage}
                    perPageCount={7}
                    paginationCount={reviewEmployeeTableData.expensesCount}
                    handleChangePage={(_event, newPage) => {
                      if (newPage - 1 !== employeeReviewTablePage) {
                        setEmployeeReviewTablePage(newPage - 1);
                      }
                    }}
                  />
                </Box>
              ) : null}
            </Card>
            {reviewEmployeeTableData.expensesCount &&
            reviewEmployeeTableData.expensesCount > 0 ? (
              <Box
                component="div"
                className="flex__ pt-lg pb-lg alignItemsCenter justifyContentSpaceBtw"
              >
                <Box className="flex__ align__items__center ">
                  {checkPermissionForFeature(
                    'backend.review_travel_expense',
                    'editPermission',
                  ) && (
                    <ButtonComponent
                      className={`btn-primary btn-cancel ${
                        reviewEmployeeTableData.managerSignatureRowKey !== '' ||
                        signature !== ''
                          ? 'signature_view_Box'
                          : 'signature_add_Box'
                      }`}
                      labelId={
                        reviewEmployeeTableData.managerSignatureRowKey !== '' ||
                        signature !== ''
                          ? 'Insurance.Viewsignature'
                          : 'Add Supervisior Signature'
                      }
                      defaultLabelId={
                        reviewEmployeeTableData.managerSignatureRowKey !== '' ||
                        signature !== ''
                          ? 'View/update signature'
                          : 'Add Supervisior Signature'
                      }
                      variantType="outlined"
                      LeftIcon={
                        reviewEmployeeTableData.managerSignatureRowKey !== '' ||
                        signature !== '' ? (
                          <Editsignature />
                        ) : null
                      }
                      onClick={() => {
                        setSupervisiorSignatureModal(true);
                      }}
                    />
                  )}
                </Box>
                {checkPermissionForFeature(
                  'backend.review_travel_expense',
                  'editPermission',
                ) && (
                  <Box className="flex__ align__items__center">
                    <ButtonComponent
                      className="btn-primary btn-alert-cancel"
                      labelId={'Reject'}
                      defaultLabelId="Reject"
                      variantType="outlined"
                      disabled={
                        reviewEmployeeTableData.status === 'Approved' ||
                        reviewEmployeeTableData.status === 'Rejected'
                      }
                      onClick={() => {
                        setOpenNoteModel(true);
                      }}
                    />
                    <ButtonComponent
                      className="btn-primary btn-submit  ml-md"
                      labelId="Approve"
                      defaultLabelId="Approve"
                      disabled={
                        reviewEmployeeTableData.status === 'Approved' ||
                        reviewEmployeeTableData.status === 'Rejected'
                      }
                      variantType="contained"
                      onClick={() => {
                        setApprovalModal(true);
                      }}
                    />
                  </Box>
                )}
              </Box>
            ) : null}
          </Box>
        )}
        {type === 'list' && (
          <Box component={'div'}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHeader
                  className="listDataTableHead"
                  headerNames={reviewHeadCells}
                  checkBoxRequired={false}
                />
                <TableBody className="tableRowcss">
                  {reviewTableData &&
                    reviewTableData.reviewExpenses.map((item, index) => (
                      <ReviewTravelExpenseEmployeeTable
                        key={index}
                        data={item}
                        index={index}
                        onViewClick={() => {
                          setEmployeeReviewTablePage(0);
                          setPage(0);
                          setSelectedExpenseMonth(
                            dayjs(item.expenseDate).format('MMMM YYYY'),
                          );
                          setSelectedEmpId(item.employeeId);
                          setEmployeeName(item.employeeName);
                          setType('');
                        }}
                      />
                    ))}
                </TableBody>
              </Table>
              {reviewTableData &&
                reviewTableData.reviewExpenses.length === 0 && (
                  <Box sx={{ width: '100%' }}>
                    <MediumTypography
                      textstyle={'center'}
                      labelid="No data added yet"
                      defaultlabel="No data added yet"
                      paddingstyle="16px"
                    />
                  </Box>
                )}
            </TableContainer>
            {reviewTableData && reviewTableData.reviewExpensesCount > 10 && (
              <Card
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <AppPagination
                  pageNumber={page}
                  perPageCount={10}
                  paginationCount={
                    reviewTableData && reviewTableData.reviewExpensesCount
                  }
                  handleChangePage={(_event, newPage) => {
                    if (newPage - 1 !== page) {
                      setPage(newPage - 1);
                    }
                  }}
                />
              </Card>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ReviewTravelExpense;
