import React from 'react';
import { Box } from '@mui/system';
import { ReactComponent as ArrowRight } from '../../assets/images/right.svg';
import TitleText from '../../components/formlib/TitleText';
import { ClientFile } from '../../services/configApi/clientFiles/clientFilesProvider';
import SmallTypography from '../../components/formlib/SmallTypography';
import { useNavigate } from 'react-router';
import { CLIENTS_ROUTE_NAME } from '../../routes/Routing';
import moment from 'moment';
import { LOCAL_DATE_TIME_FORMAT } from '../../services/Constant';
import MediumTypography from '../../components/formlib/MediumTypography';
import { Tooltip } from '@mui/material';

interface ClientFilesType {
  data: ClientFile[];
}

const ClientFilesFaceSheet: React.FC<ClientFilesType> = (props) => {
  const navigation = useNavigate();

  return (
    <>
      <Box
        sx={{
          float: 'right',
          position: 'relative',
          cursor: 'pointer',
        }}
      >
        <ArrowRight
          onClick={() => {
            sessionStorage.setItem('tabName', '5');
            const clientId = localStorage.getItem('ClientId');
            navigation(CLIENTS_ROUTE_NAME, {
              state: { clientId: clientId, isCreate: true },
            });
          }}
        />
      </Box>
      <TitleText
        labelid="Facesheet.clientDocuments"
        defaultlabel="Client Documents"
        Sxprops={{ fontSize: '18px' }}
      />
      {props.data.length === 0 && (
        <SmallTypography
          sxProps={{
            fontSize: '18px',
            color: '#97A6A5',
            textAlign: 'center',
            marginY: 5,
          }}
          labelId="ClientFilesTableView.emptyMessage"
          defaultLabelId="No files added yet"
        />
      )}
      {props.data.map((clientFileProps: ClientFile) => {
        return (
          <Box sx={{ marginY: '16px' }} key={clientFileProps.tags.timestamp}>
            <SmallTypography
              labelId="fileName"
              sxProps={{
                fontSize: '10px',
                color: '#97A6A5',
                margin: 0,
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ width: '150px' }}>
                <Tooltip
                  title={
                    clientFileProps.name.length > 5 ? clientFileProps.name : ''
                  }
                  followCursor
                  arrow={true}
                  sx={{ maxWidth: 'min-content' }}
                >
                  <Box>
                    <MediumTypography
                      className="clientDocumentFileName"
                      label={clientFileProps.name}
                      textColor="#2A4241"
                      fontweight={600}
                      sxProps={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        maxWidth: '80%',
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>
              <MediumTypography
                label={moment(
                  clientFileProps.tags.timestamp,
                  'MM-DD-YYYY HH:mm:ss',
                )
                  .utc(true)
                  .local()
                  .format(LOCAL_DATE_TIME_FORMAT)}
                textColor="#2A4241"
                fontweight={600}
              />
            </Box>
            {props.data.length - 1 !== props.data.indexOf(clientFileProps) && (
              <Box className="horizontalLine" sx={{ marginY: '8px' }} />
            )}
          </Box>
        );
      })}
    </>
  );
};

export default ClientFilesFaceSheet;
