import React, { useEffect } from 'react';
import { Box, Toolbar, Card, Grid, AlertColor, Tooltip } from '@mui/material';
import { ReactComponent as InfoIcon } from '../../assets/images/infoIcon.svg';
import MediumTypography from '../../components/formlib/MediumTypography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactComponent as AccordionClose } from '../../assets/images/accordianClose.svg';
import { ReactComponent as AccordionExpand } from '../../assets/images/accordianExpand.svg';
import {
  Category,
  getDiagnosticCodes,
} from '../../services/configApi/codelist/diagnosticCodes';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import DiagnosticCodeModal from '../../components/formlib/modal/DiagnosticCodeModal';
import { ReactComponent as Edit } from '../../assets/images/highlight.svg';
import { useNavigate } from 'react-router';
import { DIAGNOSTIC_ADD_CATEGORY } from '../../routes/Routing';
import './DiagnosticCodes.css';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import { checkPermissionForFeature } from '../../utils/checkPermission';

const DiagnosticCodes = () => {
  const [codeListJson, setCodeListJson] = React.useState<Category[]>();
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [openModal, setOpenModal] = React.useState(false);
  const navigate = useNavigate();
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [expandedAccordions, setExpandedAccordions] = React.useState(
    Array(codeListJson?.length).fill(true),
  );

  const handleChange = (index: number) => () => {
    setExpandedAccordions((prevExpandedAccordions) => {
      const newExpandedAccordions = [...prevExpandedAccordions];
      newExpandedAccordions[index] = !newExpandedAccordions[index];
      return newExpandedAccordions;
    });
  };

  React.useEffect(() => {
    if (codeListJson) {
      setExpandedAccordions(Array(codeListJson.length).fill(true));
    }
  }, [codeListJson]);

  const getAllDiagnosticCodeList = () => {
    toggleLoader(true);
    getDiagnosticCodes()
      .then((response: Category[]) => {
        setCodeListJson(response);
        setExpandedAccordions(Array(response.length).fill(true));
        toggleLoader(false);
      })
      .catch((error) => {
        setOpen(true);
        setToastrVariable('error');
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('failedApiMsg');
          setToastrDefaultMessage(
            'Oops, something went wrong. Please try again later.',
          );
        }
      });
  };

  useEffect(() => {
    if (checkPermissionForFeature('backend.code_list', 'viewPermission')) {
      getAllDiagnosticCodeList();
    }
  }, []);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box component="div">
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      {openModal && (
        <DiagnosticCodeModal
          open={openModal}
          handleClose={() => {
            setOpenModal(!openModal);
          }}
        />
      )}
      <Card sx={{ padding: '24px', ml: '0px' }} className="mb-lg">
        <Toolbar disableGutters style={{ minHeight: '0%' }}>
          <Box sx={{ flexGrow: 1 }}>
            <MediumTypography
              labelid="codelist.diognosticHeader"
              defaultlabel="Massachusetts Department of Public Health"
              fontSize="18px"
              fontweight={600}
            />
          </Box>
          <Box sx={{ flexGrow: 0, mx: '0px' }}>
            <InfoIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setOpenModal(true);
              }}
            />
          </Box>
        </Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <MediumTypography
            labelid="codelist.diognosticSubheader"
            defaultlabel="Early Intervention - Diagnosed Conditions list (Effective: October 1, 2015)"
            fontSize="16px"
            fontweight={500}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {codeListJson?.map((category: Category, index: number) => (
            <Accordion
              key={category.id}
              expanded={expandedAccordions[index]}
              onChange={handleChange(index)}
              id="accordianClass"
              sx={{
                background: 'none',
                boxShadow: 'none',
                margin: '0 !important',
                borderBottom: '1px solid #00C6B8',
                borderTop: 'none',
                '&.MuiPaper-root-MuiAccordion-root:last-of-type': {
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                },
                '&::before': {
                  backgroundColor: 'transparent',
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                },
              }}
            >
              <AccordionSummary
                onClick={(e) => e.stopPropagation()}
                sx={{
                  minHeight: '50px',
                  '& .MuiButtonBase-root-MuiAccordionSummary-root .Mui-expanded':
                    { minHeight: '50px' },
                }}
              >
                <Box component="div">
                  <button
                    style={{
                      border: 'none',
                      cursor: 'pointer',
                      background: 'none',
                      position: 'relative',
                      right: '24px',
                    }}
                    onClick={() => handleChange(index)}
                  >
                    {expandedAccordions[index] ? (
                      <AccordionClose />
                    ) : (
                      <AccordionExpand />
                    )}
                  </button>
                </Box>
                <MediumTypography
                  sxProps={{ position: 'relative', right: '24px' }}
                  fontSize="16px"
                  fontweight={600}
                  textColor="#2A4241"
                  className="cursorPointer"
                  label={category.categoryName}
                />

                <Box
                  sx={{ flexGrow: 1, cursor: 'auto' }}
                  onClick={(e) => e.stopPropagation()}
                />
                {checkPermissionForFeature(
                  'backend.code_list',
                  'editPermission',
                ) && (
                  <Box
                    component="div"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(DIAGNOSTIC_ADD_CATEGORY, {
                        state: {
                          categoryDetails: category,
                        },
                      });
                    }}
                    style={{ display: 'flex' }}
                  >
                    <Edit
                      style={{
                        cursor: 'pointer',
                        position: 'relative',
                        top: '15px',
                        width: '12.41px',
                        height: '12.41px',
                        left: '6px',
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <MediumTypography
                      fontSize="12px"
                      fontweight={500}
                      textColor="#97A6A5"
                      labelid="diagnosticCodelist.addoreditText"
                      defaultlabel="Add/Edit"
                      sxProps={{
                        position: 'relative',
                        top: '10px',
                        left: '11px',
                        textDecoration: 'none',
                      }}
                    />
                  </Box>
                )}
              </AccordionSummary>

              <AccordionDetails
                className="position__relative"
                sx={{
                  padding: '0px 16px',
                  marginTop: '-1%',
                }}
              >
                {category.subCategories.map((subCategory) => (
                  <Box
                    key={subCategory.id}
                    sx={{
                      marginBottom: '10px',
                      position: 'relative',
                      right: '15px',
                    }}
                  >
                    <MediumTypography
                      fontSize="14px"
                      fontweight={600}
                      textColor="#97A6A5"
                      label={subCategory.subTitleName}
                    />
                    <Box
                      sx={{
                        marginTop: '5px',
                        marginBottom: '20px',
                      }}
                    >
                      {subCategory.codes.map((code, ind, arr) => (
                        <Box
                          key={code.id}
                          sx={{
                            height: '33px',
                            width: '102%',
                            border: '1px solid #CFDEDE',
                            borderTopWidth: ind === 0 ? '1px' : '0',
                            padding: '8px 16px 8px 16px',
                            '& MuiPaper-root-MuiAccordion-root::before': {
                              border: 'none',
                            },
                            boxSizing: 'border-box',
                            borderTopLeftRadius: ind === 0 ? '5px' : '0px',
                            borderBottomLeftRadius:
                              ind === arr.length - 1 ? '5px' : '0',
                            borderTopRightRadius: ind === 0 ? '5px' : '0px',
                            borderBottomRightRadius:
                              ind === arr.length - 1 ? '5px' : '0px',
                          }}
                        >
                          <Grid
                            item
                            container
                            xs={12}
                            rowSpacing={'24px'}
                            key={index}
                          >
                            <Grid item xs={2}>
                              <MediumTypography
                                fontSize="14px"
                                sxProps={{ lineHeight: '14px' }}
                                fontweight={500}
                                textColor="#2A4241"
                                label={code.code}
                              />
                            </Grid>
                            <Grid item xs={10}>
                              {code.isUnderlined && (
                                <Box
                                  style={{ display: 'flex', marginTop: '-2px' }}
                                >
                                  <Box
                                    sx={{
                                      fontSize: '14px',
                                      fontWeight: 500,
                                      color: '#2A4241',
                                      position: 'relative',
                                      bottom: '4px',
                                      right: '4px',
                                    }}
                                  >
                                    *
                                  </Box>

                                  <Box
                                    style={{
                                      position: 'relative',
                                    }}
                                  >
                                    <Tooltip title={code.description} arrow>
                                      <Box>
                                        <MediumTypography
                                          className="ellipsis_txt_profile"
                                          fontSize="14px"
                                          sxProps={{
                                            lineHeight: '14px',
                                            cursor: 'pointer',
                                            maxWidth: '550px',
                                          }}
                                          fontweight={500}
                                          textColor="#2A4241"
                                          label={code.description}
                                        />
                                      </Box>
                                    </Tooltip>
                                    <span
                                      style={{
                                        position: 'absolute',
                                        left: 0,
                                        bottom: '5px',
                                        width: '100%',
                                        height: '0.5px',
                                        background: '#2A4241',
                                        content: '""',
                                        display: 'block',
                                      }}
                                    />
                                  </Box>
                                  {code.isValidForInsurance && (
                                    <MediumTypography
                                      fontSize="14px"
                                      fontweight={500}
                                      textColor="#EB4C60"
                                      sxProps={{
                                        marginLeft: '5px',
                                        lineHeight: '14px',
                                      }}
                                      label={`(Note: ${code.date} not valid for insurers)`}
                                    />
                                  )}
                                </Box>
                              )}
                              <Box style={{ display: 'flex' }}>
                                {!code.isUnderlined &&
                                  code.isValidForInsurance && (
                                    <Box style={{ display: 'flex' }}>
                                      <Tooltip title={code.description} arrow>
                                        <Box>
                                          <MediumTypography
                                            className="ellipsis_txt_profile"
                                            fontSize="14px"
                                            fontweight={500}
                                            sxProps={{
                                              lineHeight: '14px',
                                              maxWidth: '550px',
                                              cursor: 'pointer',
                                            }}
                                            textColor="#2A4241"
                                            label={code.description}
                                          />
                                        </Box>
                                      </Tooltip>
                                      <MediumTypography
                                        fontSize="14px"
                                        fontweight={500}
                                        textColor="#EB4C60"
                                        sxProps={{
                                          marginLeft: '5px',
                                          lineHeight: '14px',
                                        }}
                                        label={`(Note: ${code.date} not valid for insurers)`}
                                      />
                                    </Box>
                                  )}
                              </Box>
                              <Box style={{ display: 'flex' }}>
                                {!code.isUnderlined &&
                                  !code.isValidForInsurance && (
                                    <Tooltip title={code.description} arrow>
                                      <Box>
                                        <MediumTypography
                                          className="ellipsis_txt_profile"
                                          fontSize="14px"
                                          fontweight={500}
                                          sxProps={{
                                            lineHeight: '14px',
                                            maxWidth: '550px',
                                            cursor: 'pointer',
                                          }}
                                          textColor="#2A4241"
                                          label={code.description}
                                        />
                                      </Box>
                                    </Tooltip>
                                  )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Card>
    </Box>
  );
};

export default DiagnosticCodes;
