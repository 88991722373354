import React from 'react';
import {
  InputBaseComponentProps,
  InputLabelProps,
  TextField,
} from '@mui/material';
import './Textinput.css';
import { FormattedMessage } from 'react-intl';
import { SxProps, styled } from '@mui/system';

const CssTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    fontFamily: 'Lato-Regular',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#97A6A5',
    justifyContent: 'center',
    textAlign: 'center',
  },
  '& .MuiInputBase-input': {
    alignItems: 'center',
    border: '1px  #97A6A5',
    borderRadius: '4px',
    '&:focus': {
      backgroundColor: 'white',
    },
  },
  '& label.Mui-focused': {
    color: '#00C6B8',
  },
  '& .MuiOutlinedInput-multiline': {
    borderColor: '#00C6B8',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      border: '1px solid #00C6B8',
    },
    height: 'inherit',
  },
  '& .MuiOutlinedInput-input': {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
  },
});

interface TextInputProps {
  name?: string;
  fieldheight?: string;
  Value?: string | null;
  handlechange?: (value: string) => void;
  type?: string;
  placeHolder?: string;
  inputProps?: InputBaseComponentProps;
  inputLabelProps?: Partial<InputLabelProps>;
  onblur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onPasteText?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  variant?: 'standard' | 'outlined' | 'filled';
  disabled?: boolean;
  Required?: boolean;
  labelid?: string;
  defaultlabelid?: string;
  multiline?: boolean;
  maxRows?: number;
  sxProps?: SxProps;
  className?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  autoFocus?: boolean;
  loginField?: boolean;
  placeholderOnfloatBackgroundColor?: string;
}

const Textinput: React.FC<TextInputProps> = (props) => {
  const {
    placeHolder,
    type,
    fieldheight,
    name,
    Value,
    handlechange,
    inputProps,
    onblur,
    onFocus,
    onKeyUp,
    variant,
    disabled,
    Required,
    labelid,
    defaultlabelid,
    multiline,
    maxRows = 1,
    sxProps,
    className,
    inputLabelProps,
    inputRef,
    autoFocus,
    loginField,

    onPasteText,
    placeholderOnfloatBackgroundColor,
  } = props;
  const textFieldStyle = {
    height: fieldheight,
  };
  return (
    <CssTextField
      sx={{
        width: '100%',
        left: '0px',

        m: 0,
        '& .MuiOutlinedInput-root': {
          borderColor: '#00C6B8',
          '&:hover fieldset': {
            borderColor: '#00C6B8',
          },
        },
        '& label.MuiFormLabel-filled': {
          backgroundColor: placeholderOnfloatBackgroundColor ?? 'white',
          color: '#97A6A5',
        },
        '& label.Mui-focused': {
          backgroundColor: placeholderOnfloatBackgroundColor ?? 'white',
          color: '#00C6B8',
        },
        ...sxProps,
      }}
      id="textfield"
      label={
        labelid &&
        !loginField && (
          <FormattedMessage id={labelid} defaultMessage={defaultlabelid} />
        )
      }
      inputRef={inputRef}
      autoFocus={autoFocus}
      name={name}
      className={className + ' inputFormLabel'}
      autoComplete="off"
      variant={variant ?? 'outlined'}
      value={Value}
      placeholder={placeHolder}
      onChange={(event) => {
        if (handlechange !== undefined) {
          handlechange(event.target.value.trimStart());
        }
      }}
      style={textFieldStyle}
      type={type ?? 'text'}
      onBlur={onblur}
      onFocus={onFocus}
      onKeyUp={onKeyUp}
      disabled={disabled}
      required={Required}
      multiline={multiline}
      maxRows={maxRows}
      inputProps={{
        autoComplete: type === 'password' ? 'one-time-code' : 'new-password',
        ...inputProps,
      }}
      InputLabelProps={inputLabelProps}
      onPaste={onPasteText}
    />
  );
};
export default Textinput;
