import { Box } from '@mui/system';
import React, { CSSProperties } from 'react';
import MediumTypography from '../formlib/MediumTypography';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';

type QuestionsType = {
  id: string;
  type: string;
  displayQNoId: string;
  displayQueId: string;
  displayQNotext: string;
  displayQuetext: string;
};

type ASQQuestionConcernComponentType = {
  concernQuestions: QuestionsType[];
  onValueChange: (
    value: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
  Value?: { commentId: number; comments: string }[];
  handlechange?: (
    value: { commentId: number; comments: string },
    index: number,
  ) => void;
  placeholder?: string;
  rows?: number;
  initialCheck?: { answer: string }[];
  customCommentStyles?: CSSProperties;
  labelidA?: string;
  defaultlabelidA?: string;
  labelidB?: string;
  defaultlabelidB?: string;
  disabled?: boolean;
};

const SimpleTextRadioButtonAndTextArea = ({
  concernQuestions,
  onValueChange,
  initialCheck,
  placeholder,
  Value,
  handlechange,
  rows,
  customCommentStyles,
  labelidA,
  labelidB,
  defaultlabelidA,
  defaultlabelidB,
  disabled,
}: ASQQuestionConcernComponentType) => {
  return (
    <Box>
      {concernQuestions?.map((questiondetails, index) => (
        <>
          {questiondetails.type === 'simpleQuestionRadioWithInput' && (
            <>
              <Box sx={{ alignItems: 'center', display: 'inline-flex' }}>
                <MediumTypography
                  sxProps={{ fontSize: 14, fontWeight: 500 }}
                  labelid={questiondetails.displayQNoId}
                  defaultlabel={questiondetails.displayQNotext}
                />
                <MediumTypography
                  sxProps={{
                    marginLeft: '4px',
                    marginRight: '24px',
                    fontFamily: 'Lato-Regular',
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                  labelid={questiondetails.displayQueId}
                  defaultlabel={questiondetails.displayQuetext}
                />
                <RadioGroup
                  onChange={(value) => onValueChange(value, index)}
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={initialCheck?.[index].answer}
                  name="radio-buttons-group"
                  sx={{ display: 'inline' }}
                >
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': { color: '#00C6B8' },
                          height: '24px',
                          borderRadius: '14px',
                          color: '#97A6A5',
                        }}
                        disabled={disabled}
                      />
                    }
                    label={
                      <MediumTypography
                        sxProps={{
                          fontWeight: 400,
                        }}
                        labelid={labelidA}
                        defaultlabel={defaultlabelidA}
                      />
                    }
                  />
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': { color: '#00C6B8' },
                          height: '24px',
                          borderRadius: '14px',
                          color: '#97A6A5',
                        }}
                        disabled={disabled}
                      />
                    }
                    label={
                      <MediumTypography
                        sxProps={{
                          fontWeight: 400,
                        }}
                        labelid={labelidB}
                        defaultlabel={defaultlabelidB}
                      />
                    }
                  />
                </RadioGroup>
              </Box>
              <Box sx={{ paddingTop: '8px', width: '48%' }}>
                <textarea
                  placeholder={placeholder}
                  rows={rows}
                  value={Value?.[index] ? Value[index].comments : ''}
                  onChange={(e) =>
                    handlechange &&
                    handlechange(
                      { commentId: index, comments: e.target.value },
                      index,
                    )
                  }
                  maxLength={500}
                  style={{
                    ...customCommentStyles,
                    fontFamily: 'Lato-Regular',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '14px',
                    paddingTop: '10px',
                    paddingLeft: '10px',
                    border: '1px solid #ccc',
                    outline: 'none',
                    overflow: 'hidden',
                    borderRadius: '4px',
                    transition: 'border-color 0.3s',
                    alignItems: 'center',
                    justifyContent: 'center',
                    resize: 'none',
                    overflowY: 'scroll',
                    height: '80px',
                    minHeight: '80px',
                  }}
                  onFocus={(e) => {
                    e.target.style.borderColor = '#00C6B8';
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = '#ccc';
                  }}
                />
              </Box>
            </>
          )}
        </>
      ))}
    </Box>
  );
};

export default SimpleTextRadioButtonAndTextArea;
