import CustomCheckBox from '../../components/formlib/CustomCheckBox';
import { Box } from '@mui/system';
import Textinput from '../../components/formlib/Textinput';
import MediumTypography from '../../components/formlib/MediumTypography';

type FollowUpActionQuestionType = {
  id: string;
  displayQueId: string;
  displayQuetext: string;
  textAreaSize?: string;
  textAreaType?: string;
};

type ASQFollowUpActionComponentType = {
  followUpActionQuestion: FollowUpActionQuestionType[];
  value?: { followUpChecked: boolean }[];
  onClickChange?: (value: boolean, index: number) => void;
  textInputValue?: { followUptextInputValues: string }[];
  handleTextInputChange?: (text: string, index: number) => void;
  onOtherCommentValue?: { followUpComment: string }[];
  handleOtherCommentChange?: (text: string, index: number) => void;
};

function ASQFollowUpActionComponent({
  followUpActionQuestion,
  value,
  onClickChange,
  textInputValue,
  handleTextInputChange,
  onOtherCommentValue,
  handleOtherCommentChange,
}: ASQFollowUpActionComponentType) {
  return (
    <Box>
      {followUpActionQuestion.map((item, index) => (
        <Box key={index}>
          <CustomCheckBox
            value={value && value[index] ? value[index].followUpChecked : false}
            onCheckBoxClick={(e) => onClickChange && onClickChange(e, index)}
          />
          {item.displayQuetext.split('__').map((data, dataIndex) => (
            <Box sx={{ display: 'inline' }}>
              {dataIndex > 0 && (
                <Textinput
                  Value={
                    textInputValue && textInputValue[index]
                      ? textInputValue[index].followUptextInputValues
                      : ''
                  }
                  handlechange={(e) => {
                    const inputValue = e;
                    const regexPattern = item.textAreaType
                      ? new RegExp(item.textAreaType)
                      : undefined;
                    if (
                      handleTextInputChange &&
                      (!regexPattern || regexPattern.test(inputValue))
                    ) {
                      handleTextInputChange(inputValue, index);
                    }
                  }}
                  sxProps={{
                    width:
                      item.textAreaSize === 'small'
                        ? '60px'
                        : item.textAreaSize === 'medium'
                        ? '120px'
                        : '150px',
                    top: '4px',
                    paddingX: '4px',
                  }}
                  inputProps={{
                    style: {
                      height: '24px',
                      paddingLeft: '6px',
                      paddingRight: '6px',
                    },
                  }}
                />
              )}
              <Box sx={{ display: 'inline-flex' }}>
                <MediumTypography
                  sxProps={{ fontSize: 14, fontWeight: 500 }}
                  labelid="ASQFollowUpActionQuestion"
                  defaultlabel={data}
                />
              </Box>
              {index === followUpActionQuestion.length - 1 && (
                <textarea
                  placeholder="Comments"
                  value={
                    onOtherCommentValue && onOtherCommentValue[index]
                      ? onOtherCommentValue[index].followUpComment
                      : ''
                  }
                  onChange={(e) =>
                    handleOtherCommentChange &&
                    handleOtherCommentChange(e.target.value, index)
                  }
                  maxLength={200}
                  style={{
                    display: 'flex',
                    marginLeft: '34px',
                    width: '35%',
                    fontFamily: 'Lato-Regular',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '14px',
                    height: '35px',
                    minHeight: '35px',
                    minWidth: '35%',
                    paddingTop: '10px',
                    paddingLeft: '10px',
                    border: '1px solid #ccc',
                    outline: 'none',
                    overflow: 'hidden',
                    borderRadius: '4px',
                    transition: 'border-color 0.3s',
                    alignItems: 'center',
                    justifyContent: 'center',
                    resize: 'none',
                    overflowY: 'scroll',
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}

export default ASQFollowUpActionComponent;
