import { Box } from '@mui/system';
import React, { CSSProperties } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';

type QuestionsType = {
  id: string;
  type: string;
  displayQNoId?: string;
  displayQueId: string;
  displayQNotext?: string;
  displayQuetext: string;
  displayMainQueId?: string;
  displayMainQuetext?: string;
};

type ASQQuestionConcernComponentType = {
  questions: QuestionsType[];
  Value?: { commentId: number; comments: string }[];
  handlechange?: (
    value: { commentId: number; comments: string },
    index: number,
  ) => void;
  placeholder?: string;
  rows?: number;
  customCommentStyles?: CSSProperties;
  maxLength?: number;
  disabled?: boolean;
};

const SimpleTextAndTextArea = ({
  questions,
  placeholder,
  Value,
  handlechange,
  rows,
  customCommentStyles,
  maxLength,
  disabled,
}: ASQQuestionConcernComponentType) => {
  return (
    <Box>
      {questions?.map((questiondetails, index) => (
        <>
          {questiondetails.type === 'simpleQuestionWithTextArea' && (
            <>
              <Box sx={{ alignItems: 'center', display: 'inline-flex' }}>
                <MediumTypography
                  sxProps={{ fontSize: 14, fontWeight: 500 }}
                  labelid={questiondetails.displayQNoId}
                  defaultlabel={questiondetails.displayQNotext}
                />
                <MediumTypography
                  sxProps={{
                    marginLeft: '4px',
                    marginRight: '24px',
                    fontFamily: 'Lato-Regular',
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                  labelid={questiondetails.displayQueId}
                  defaultlabel={questiondetails.displayQuetext}
                />
              </Box>
              <Box sx={{ paddingTop: '8px', width: '48%' }}>
                <textarea
                  placeholder={placeholder}
                  rows={rows}
                  maxLength={maxLength}
                  value={Value?.[index] ? Value[index].comments : ''}
                  onChange={(e) =>
                    handlechange &&
                    handlechange(
                      { commentId: index, comments: e.target.value },
                      index,
                    )
                  }
                  style={{
                    ...customCommentStyles,
                    fontFamily: 'Lato-Regular',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '14px',
                    paddingTop: '10px',
                    paddingLeft: '10px',
                    border: '1px solid #ccc',
                    outline: 'none',
                    overflow: 'hidden',
                    borderRadius: '4px',
                    transition: 'border-color 0.3s',
                    alignItems: 'center',
                    justifyContent: 'center',
                    resize: 'none',
                    overflowY: 'scroll',
                  }}
                  onFocus={(e) => {
                    e.target.style.borderColor = '#00C6B8';
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = '#ccc';
                  }}
                  disabled={disabled}
                />
              </Box>
            </>
          )}
          {questiondetails.type === 'multipleQuestionWithTextArea' && (
            <>
              <Box sx={{ marginTop: '8px' }}>
                <MediumTypography
                  sxProps={{ fontSize: 14, fontWeight: 600 }}
                  labelid={questiondetails.displayMainQueId}
                  defaultlabel={questiondetails.displayMainQuetext}
                />
              </Box>
              <Box sx={{ marginTop: '8px' }}>
                <MediumTypography
                  sxProps={{
                    marginRight: '24px',
                    fontFamily: 'Lato-Regular',
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                  labelid={questiondetails.displayQueId}
                  defaultlabel={questiondetails.displayQuetext}
                />
              </Box>
              <Box sx={{ paddingTop: '8px', width: '48%' }}>
                <textarea
                  disabled={disabled}
                  placeholder={placeholder}
                  rows={rows}
                  maxLength={maxLength}
                  value={Value?.[index] ? Value[index].comments : ''}
                  onChange={(e) =>
                    handlechange &&
                    handlechange(
                      { commentId: index, comments: e.target.value },
                      index,
                    )
                  }
                  style={{
                    ...customCommentStyles,
                    fontFamily: 'Lato-Regular',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '14px',
                    paddingTop: '10px',
                    paddingLeft: '10px',
                    border: '1px solid #ccc',
                    outline: 'none',
                    overflow: 'hidden',
                    borderRadius: '4px',
                    transition: 'border-color 0.3s',
                    alignItems: 'center',
                    justifyContent: 'center',
                    resize: 'none',
                    overflowY: 'scroll',
                  }}
                  onFocus={(e) => {
                    e.target.style.borderColor = '#00C6B8';
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = '#ccc';
                  }}
                />
              </Box>
            </>
          )}
        </>
      ))}
    </Box>
  );
};

export default SimpleTextAndTextArea;
