import { Card, Drawer, Grid, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useEffect, useRef, useState } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as Closegray } from '../../assets/images/x.svg';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import Textinput from '../../components/formlib/Textinput';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import { ReactComponent as Calendar } from '../../assets/images/Calendar.svg';
import { ReactComponent as Clock } from '../../assets/images/clock.svg';
import AppTimePicker from '../../components/formlib/AppTimePicker';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { Formik, FormikProps } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import * as yup from 'yup';
import SmallTypography from '../../components/formlib/SmallTypography';
import { TimeOffHours, TimeOffListType } from './MyTimeOff';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { OptionType } from '../../utils/type';
import {
  convertTimeDuration,
  datePickerMinDateMaxDateValidate,
} from '../../utils/dateUtil';
import _ from 'lodash';
import ModalPopup from '../../components/formlib/ModalPopup';

interface TimeOffModalProps {
  open: boolean;
  handleClose: () => void;
  handlesubmit: (data: TimeOffListType, action: string) => void;
  rowData?: TimeOffListType;
  availableHrs: TimeOffHours[];
}

const initialData: TimeOffListType = {
  typeId: '',
  durationType: 'days',
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
  reason: '',
  leaveName: '',
};
const TimeOffRadioData = [
  {
    value: 'days',
    labelId: 'myTimeOffDay(s)',
    defaultLabel: 'Day(s)',
  },
  {
    value: 'hours',
    labelId: 'myTimeOffHour(s)',
    defaultLabel: 'Hour(s)',
  },
];

const drawerWidth = 440;

const validationSchema = yup.object().shape({
  typeId: yup.string().required('myTimeOffTypeError'),
  durationType: yup.string(),
  reason: yup.string().required('myTimeOffReasonError'),
  startDate: yup.date().when('durationType', {
    is: 'days',
    then: yup.date().required('myTimeOffStartDateError').nullable(),
    otherwise: yup.date().required('myTimeOffDateError').nullable(),
  }),
  endDate: yup.date().when('durationType', {
    is: 'days',
    then: yup.date().required('myTimeOffEndDateError').nullable(),
    otherwise: yup.date().nullable(),
  }),
  startTime: yup.string().when('durationType', {
    is: 'days',
    then: yup.string().notRequired().nullable(),
    otherwise: yup.string().required('myTimeOffStartTimeError').nullable(),
  }),
  endTime: yup.string().when('durationType', {
    is: 'days',
    then: yup.string().notRequired().nullable(),
    otherwise: yup.string().required('myTimeOffEndTimeError').nullable(),
  }),
});

const MyTimeOffModal: FC<TimeOffModalProps> = ({
  open,
  handleClose,
  handlesubmit,
  rowData,
  availableHrs,
}) => {
  const [timeOff, setTimeOff] = useState<TimeOffListType>();
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();
  const [action, setAction] = useState<'add' | 'edit'>('add');
  const [openModal, setOpenModal] = React.useState(false);
  const formRef = useRef<FormikProps<TimeOffListType>>(null);
  const { loader, toggleLoader } = React.useContext(
    LoaderContext,
  ) as LoaderContextType;
  const leaveTypeData: OptionType[] = availableHrs.map((data) => ({
    id: data.id,
    label: data.type,
  }));
  const blocks = availableHrs.map((data) => {
    if (data.type !== 'Others') {
      return (
        <Box key={data.id} sx={{ display: 'flex' }}>
          <Box sx={{ width: '35%' }}>
            <MediumTypography
              label={data.type + ':'}
              sxProps={{
                fontWeight: 400,
              }}
              textColor="#97A6A5"
            />
          </Box>
          <Box>
            <MediumTypography
              label={convertTimeDuration(data.remainingHrs, true) + ' ' + 'hrs'}
              sxProps={{
                fontWeight: 400,
                marginLeft: '10px',
              }}
              textColor="#2A4241"
            />
          </Box>
        </Box>
      );
    }
  });
  const validateTimeOff = (values: TimeOffListType) => {
    const errors: Partial<TimeOffListType> = {};

    if (values.startTime && values.endTime && values.durationType === 'hours') {
      const startTime = dayjs(values.startTime, 'HH:mm');
      const endTime = dayjs(values.endTime, 'HH:mm');

      if (startTime.isAfter(endTime) || startTime.isSame(endTime)) {
        errors.endTime = 'BlockTime.endTimeGreaterValidationError';
      }
    }
    if (values.startDate && values.endDate && values.durationType === 'days') {
      const startDate = dayjs(values.startDate, 'MM/DD/YYYY');
      const endDate = dayjs(values.endDate, 'MM/DD/YYYY');

      if (startDate.isAfter(endDate)) {
        errors.endDate = 'BlockTime.endDateValidationError';
      }
    }
    if (values.startDate || values.endDate) {
      const startDate = dayjs(values.startDate, 'MM/DD/YYYY');
      const endDate = dayjs(values.endDate, 'MM/DD/YYYY');
      if (startDate.isAfter(endDate)) {
        errors.endDate = 'BlockTime.endDateValidationError';
      }
      if (
        dayjs(values.startDate).isBefore(dayjs(minDate)) ||
        dayjs(values.startDate).isAfter(dayjs(maxDate))
      ) {
        errors.startDate = 'validDate';
      }
      if (
        dayjs(values.endDate).isBefore(dayjs(minDate)) ||
        dayjs(values.endDate).isAfter(dayjs(maxDate))
      ) {
        errors.endDate = 'validDate';
      }
      if (
        datePickerMinDateMaxDateValidate(values.startDate) ||
        datePickerMinDateMaxDateValidate(values.endDate)
      ) {
        errors.startDate = 'validDate';
        errors.endDate = 'validDate';
      }
    }
    return errors;
  };

  useEffect(() => {
    if (rowData) {
      if (rowData.durationType === 'days') {
        setTimeOff({
          id: rowData.id,
          typeId: rowData.typeId,
          durationType: rowData.durationType,
          startDate: rowData.startDate,
          endDate: rowData.endDate,
          startTime: null,
          endTime: null,
          reason: rowData.reason,
          leaveName: rowData.leaveName,
        });
      } else {
        setTimeOff({
          id: rowData.id,
          typeId: rowData.typeId,
          durationType: rowData.durationType,
          startDate: rowData.startDate,
          endDate: rowData.endDate,
          startTime: rowData.startTime,
          endTime: rowData.endTime,
          reason: rowData.reason,
          leaveName: rowData.leaveName,
        });
      }
      setAction('edit');
    } else {
      setTimeOff(initialData);
      setAction('add');
    }
  }, []);

  useEffect(() => {
    if (timeOff && formRef && formRef.current) {
      formRef.current?.setValues({
        ...timeOff,
      });
    }
  }, [timeOff?.durationType]);

  useEffect(() => {
    const currentDate = new Date();

    if (currentDate < new Date(currentDate.getFullYear(), 6, 1)) {
      setMinDate(new Date(currentDate.getFullYear() - 1, 6, 1));
      setMaxDate(new Date(currentDate.getFullYear(), 5, 30));
    } else if (currentDate > new Date(currentDate.getFullYear(), 6, 1)) {
      setMinDate(new Date(currentDate.getFullYear(), 6, 1));
      setMaxDate(new Date(currentDate.getFullYear() + 1, 5, 30));
    }
  }, []);

  const clearForm = () => {
    formRef.current?.setValues(formRef.current?.initialValues);
    setTimeOff(formRef.current?.initialValues);
    setOpenModal(false);
  };
  // useEffect(() => {
  //   const currentDate = dayjs();

  //   if (currentDate.isBefore(dayjs(currentDate.year(), 6, 1))) {
  //     setMinDate(currentDate.subtract(1, 'year').month(5).date(1));
  //     setMaxDate(currentDate.month(4).date(30));
  //   } else if (currentDate.isAfter(dayjs(currentDate.year(), 6, 1))) {
  //     setMinDate(currentDate.month(5).date(1));
  //     setMaxDate(currentDate.add(1, 'year').month(4).date(30));
  //   }
  // }, []);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        paddingInline: '24px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      anchor={'right'}
      open={open}
      onClose={handleClose}
    >
      <ModalPopup
        open={openModal}
        description="formUnsavedChangesMessage"
        onCancel={() => setOpenModal(false)}
        onOk={() => clearForm()}
        labelId1="Clientpage.Nobtn"
        negativeActionLabel="No"
        labelId2="Clientpage.Yesbtn"
        positiveActionLabel="Yes"
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: '#E7F0F0',
          padding: '0px 0px 0px 0px !important',
        }}
      >
        <Card
          sx={{
            my: '0px',
            ml: '0px',
            mt: '0px',
            padding: '24px',
            height: '100%',
          }}
        >
          {timeOff && (
            <Formik
              initialValues={timeOff}
              innerRef={formRef}
              onSubmit={(values: TimeOffListType) => {
                toggleLoader(true);
                handlesubmit(values, action);
              }}
              validationSchema={validationSchema}
              validate={validateTimeOff}
            >
              {({
                values,
                setFieldValue,
                handleSubmit,
                initialValues,
                errors,
                touched,
              }) => (
                <Grid container>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={10.7}>
                      <MediumTypography
                        sxProps={{
                          color: '#2A4241',
                          fontSize: '16px',
                          fontWeight: '600',
                          lineHeight: '36px',
                          fontFamily: 'Lato-Bold',
                        }}
                        labelid={'myTimeOffRequestTimeOff'}
                        defaultlabel={'Request Time Off'}
                      />
                    </Grid>
                    <Grid item xs={1.3} textAlign={'right'}>
                      <IconButton onClick={handleClose}>
                        <Closegray />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    sx={{ padding: '16px 0px 16px 0px' }}
                  >
                    <Grid item xs={12}>
                      <DropdownComponent
                        names={leaveTypeData}
                        labelid="MyTimeOffSelectType"
                        defaultlabelid="Select Type"
                        value={values.typeId}
                        handleChange={(value) => {
                          setFieldValue('typeId', value);
                          setTimeOff({
                            ...timeOff,
                            typeId: value,
                          });
                        }}
                      />
                      {errors.typeId && touched.typeId && (
                        <SmallTypography
                          sxProps={{ color: 'red' }}
                          labelId={errors.typeId}
                        />
                      )}
                    </Grid>
                  </Grid>

                  <CustomRadioButton
                    data={TimeOffRadioData}
                    value={values.durationType}
                    onSelectItem={(value) => {
                      if (value) {
                        setFieldValue('startDate', null);
                        setFieldValue('endDate', null);
                        setFieldValue('startTime', null);
                        setFieldValue('endTime', null);
                        setTimeOff({
                          ...timeOff,
                          startDate: null,
                          endDate: null,
                          startTime: null,
                          endTime: null,
                          durationType: value,
                        });

                        setFieldValue('durationType', value);
                      }
                    }}
                  />
                  {timeOff.durationType === 'days' && (
                    <>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        sx={{ padding: '16px 0px 16px 0px' }}
                      >
                        <Grid item xs={12}>
                          <MediumTypography
                            sxProps={{
                              color: '#2A4241',
                              fontWeight: '600',
                              lineHeight: '18px',
                              fontFamily: 'Lato-Bold',
                            }}
                            labelid="MyTimeOffSelectStartEndDate"
                            defaultlabel="Select Start and End Date"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        columnSpacing={1}
                        alignItems="center"
                      >
                        <Grid item xs={1} sx={{ marginTop: '8px' }}>
                          <Calendar />
                        </Grid>
                        <Grid item xs={5.5}>
                          <DatePickerComponent
                            labelid="MyTimeOffStartDate"
                            minDate={dayjs(minDate) as Dayjs}
                            maxDate={dayjs(maxDate) as Dayjs}
                            disableFuture={false}
                            defaultlabelid="Start Date"
                            value={
                              values.startDate
                                ? (dayjs(values.startDate) as Dayjs)
                                : null
                            }
                            handlechange={(value: Dayjs | null) => {
                              const formattedDate =
                                dayjs(value).format('MM/DD/YYYY');
                              if (value === null) {
                                setFieldValue('startDate', null);
                                setTimeOff({
                                  ...timeOff,
                                  startDate: null,
                                });
                              } else {
                                setFieldValue('startDate', formattedDate);
                                setTimeOff({
                                  ...timeOff,
                                  startDate: formattedDate,
                                });
                              }
                            }}
                          />
                        </Grid>
                        {/* <Grid item xs={1}></Grid> */}
                        <Grid item xs={5.5}>
                          <DatePickerComponent
                            labelid="myTimeOffEndDate"
                            minDate={
                              values.startDate !== null
                                ? dayjs(values.startDate)
                                : (dayjs(minDate) as Dayjs)
                            }
                            maxDate={dayjs(maxDate) as Dayjs}
                            disableFuture={false}
                            defaultlabelid="End Date"
                            value={
                              values.endDate
                                ? (dayjs(values.endDate) as Dayjs)
                                : null
                            }
                            handlechange={(value: Dayjs | null) => {
                              const formattedDate =
                                dayjs(value).format('MM/DD/YYYY');
                              if (value === null) {
                                setFieldValue('endDate', null);
                                setTimeOff({
                                  ...timeOff,
                                  endDate: null,
                                });
                              } else {
                                setFieldValue('endDate', formattedDate);
                                setTimeOff({
                                  ...timeOff,
                                  endDate: formattedDate,
                                });
                              }
                            }}
                          />{' '}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        direction="row"
                        alignItems="center"
                      >
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                          {errors.startDate && touched.startDate && (
                            <SmallTypography
                              sxProps={{ color: 'red' }}
                              labelId={errors.startDate}
                            />
                          )}
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                          {errors.endDate && touched.endDate && (
                            <SmallTypography
                              sxProps={{ color: 'red' }}
                              labelId={errors.endDate}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {values.durationType === 'hours' && (
                    <>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        sx={{ padding: '16px 0px 16px 0px' }}
                      >
                        <Grid item xs={12}>
                          <MediumTypography
                            sxProps={{
                              color: '#2A4241',
                              fontWeight: '600',
                              lineHeight: '18px',
                              fontFamily: 'Lato-Bold',
                            }}
                            labelid="myTimeOffSelectDate"
                            defaultlabel="Select Date"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        columnSpacing={1}
                        alignItems="center"
                      >
                        <Grid item xs={1} sx={{ marginTop: '8px' }}>
                          <Calendar />
                        </Grid>
                        <Grid item xs={5.5}>
                          <DatePickerComponent
                            labelid="myTimeOffSelectDate"
                            disableFuture={false}
                            defaultlabelid="Select Date"
                            minDate={dayjs(minDate) as Dayjs}
                            maxDate={dayjs(maxDate) as Dayjs}
                            value={
                              values.startDate === '' ||
                              values.startDate === null
                                ? null
                                : (dayjs(values.startDate) as Dayjs)
                            }
                            handlechange={(value) => {
                              const formattedDate =
                                dayjs(value).format('MM/DD/YYYY');
                              if (value === null) {
                                setFieldValue('startDate', null);
                                setTimeOff({
                                  ...timeOff,
                                  startDate: null,
                                });
                              } else {
                                setFieldValue('startDate', formattedDate);
                                setTimeOff({
                                  ...timeOff,
                                  startDate: formattedDate,
                                });
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                      {errors.startDate && touched.startDate && (
                        <SmallTypography
                          sxProps={{ color: 'red', paddingLeft: '24px' }}
                          labelId={errors.startDate}
                        />
                      )}
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        sx={{ padding: '20px 0px 16px 0px' }}
                      >
                        <Grid item xs={12}>
                          <MediumTypography
                            sxProps={{
                              color: '#2A4241',
                              fontWeight: '600',

                              fontFamily: 'Lato-Bold',
                            }}
                            labelid="myTimeOffSelectStartEndTime"
                            defaultlabel="Select Start and End Time"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        sx={{ paddingBottom: '8px ' }}
                      >
                        <Grid item xs={1}>
                          <Clock
                            style={{
                              width: '20px',
                              height: '20px',
                              marginTop: '8px',
                            }}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <AppTimePicker
                            minuteStep={15}
                            value={
                              values.startTime === null
                                ? null
                                : dayjs(timeOff.startTime, 'HH:mm')
                            }
                            onTimeChange={(text) => {
                              if (text === null) {
                                setFieldValue('startTime', null);
                                setTimeOff({
                                  ...timeOff,
                                  startTime: null,
                                });
                              } else {
                                setFieldValue(
                                  'startTime',
                                  text.format('HH:mm'),
                                );
                                setTimeOff({
                                  ...timeOff,
                                  startTime: text.format('HH:mm'),
                                });
                              }
                            }}
                            sxButtonProps={{
                              padding: '16px 40px 16px 8px',
                              width: '100%',
                            }}
                            name="Start Time*"
                          />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                          <AppTimePicker
                            minuteStep={15}
                            value={
                              values.endTime === null
                                ? null
                                : dayjs(timeOff.endTime, 'HH:mm')
                            }
                            onTimeChange={(text) => {
                              if (text === null) {
                                setFieldValue('endTime', null);
                                setTimeOff({
                                  ...timeOff,
                                  endTime: null,
                                });
                              } else {
                                setFieldValue('endTime', text.format('HH:mm'));
                                setTimeOff({
                                  ...timeOff,
                                  endTime: text.format('HH:mm'),
                                });
                              }
                            }}
                            name="End Time*"
                            sxButtonProps={{
                              padding: '16px 40px 16px 8px',
                              width: '100%',
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        direction="row"
                        alignItems="center"
                      >
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                          {errors.startTime && touched.startTime && (
                            <SmallTypography
                              sxProps={{ color: 'red' }}
                              labelId={errors.startTime}
                            />
                          )}
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                          {errors.endTime && touched.endTime && (
                            <SmallTypography
                              sxProps={{ color: 'red' }}
                              labelId={errors.endTime}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid
                    container
                    alignItems="center"
                    sx={{ padding: '16px 0px 24px 0px' }}
                  >
                    <Grid item xs={12}>
                      <Textinput
                        name="reason"
                        Value={values.reason}
                        labelid="myTimeOffEnterReason"
                        defaultlabelid="Enter Reason"
                        inputProps={{
                          maxLength: 50,
                        }}
                        handlechange={(value) => {
                          setFieldValue('reason', value);
                          setTimeOff({ ...timeOff, reason: value });
                        }}
                        Required={true}
                      />
                      {errors.reason && touched.reason && (
                        <SmallTypography
                          sxProps={{ color: 'red' }}
                          labelId={errors.reason}
                        />
                      )}
                    </Grid>
                  </Grid>
                  {/* <Grid
                    container
                    direction="row"
                    alignItems="center"
                    sx={{ paddingBottom: '16px' }}
                  >
                    <Grid container item xs={12} direction="row">
                      <MediumTypography
                        sxProps={{
                          color: '#2A4241',
                          fontWeight: '400',
                          lineHeight: '18px',
                        }}
                        labelid="myTimeOffRequestSentTo"
                        defaultMessage="Time of request will be sent to "
                      />
                      <MediumTypography
                        sxProps={{
                          color: '#00C6B8',
                          fontWeight: '400',
                          lineHeight: '18px',
                          marginLeft: '2px',
                        }}
                        label="John Doe"
                      />
                    </Grid>
                  </Grid> */}
                  <Grid
                    container
                    direction="row"
                    alignItems="right"
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      paddingBottom:
                        values.durationType === 'days' ? '100px' : '50px',
                    }}
                  >
                    <Grid item sx={{ marginRight: '16px' }}>
                      <ButtonComponent
                        className="btn-primary btn-cancel"
                        variantType="contained"
                        labelId="myTimeOffCancelButtonText"
                        defaultLabelId="Cancel"
                        onClick={() => {
                          if (_.isEqual(initialValues, values)) {
                            handleClose();
                          } else {
                            setOpenModal(true);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        type="submit"
                        loading={loader}
                        labelId={
                          action === 'add'
                            ? 'myTimeOffSubmitButtonText'
                            : 'myTimeOffUpdateButtonText'
                        }
                        defaultLabelId={action === 'add' ? 'Submit' : 'Update'}
                        onClick={() => {
                          handleSubmit();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Formik>
          )}
          <Box
            sx={{
              backgroundColor: '#ECF9F8',
              padding: '24px',
            }}
          >
            <MediumTypography
              labelid="myTimeOffAvailableHours"
              defaultlabel="Available Hours"
              sxProps={{
                fontSize: '16px',
                fontWeight: 600,
                paddingBottom: '16px',
              }}
            />
            {blocks}
          </Box>
        </Card>
      </Box>
    </Drawer>
  );
};
export default MyTimeOffModal;
