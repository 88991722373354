export const nameValidation = (value: string) =>
  value.replace(/[^a-zA-Z,.!' -]/g, '');

export const zipcodeValidation = (value: string) => value.replace(/\D/g, '');

export const formatPhoneNumber = (phoneNumber: string): string => {
  const cleanValue = phoneNumber.replace(/\D/g, '');
  const formattedValue = cleanValue.replace(
    /(\d{3})(\d{0,3})(\d{0,4})/,
    (match, p1, p2, p3) => {
      let formatted = p1;
      if (p2) formatted += '-' + p2;
      if (p3) formatted += '-' + p3;
      return formatted;
    },
  );
  return formattedValue.slice(0, 12);
};

export const formatFileTag = (value: string) =>
  value.replace(/[^a-zA-Z0-9+-.:=_/ ]/g, '').replace(',', '');

export const nameValidationWithNumeric = (value: string) =>
  value.replace(/[^a-zA-Z0-9,.!' -]/g, '');
