import { ReactNode } from 'react';
import {
  ACTIVITY_APPROVAL_OVERVIEW_SHEET_ROUTE_NAME,
  ACTIVITY_SHEET_ROUTE_NAME,
  CASE_LOAD_LIST,
  CLIENTS_DASHBOARD_ROUTE_NAME,
  CODE_LIST,
  // EMAIL_TEXT_TEMPLATE,
  EMPLOYEES_ROUTE_NAME,
  EMPLOYEE_DASHBOARD_ROUTE_NAME,
  FORM_BUILDER_LIST_DISPLAY,
  // FORM_BUILDER_RESPONSE_LIST,
  INSURANCE_ROUTE_NAME,
  PROGRAM_MANAGEMENT_ROUTE_NAME,
  REFERRAL_DASHBOARD_ROUTE_NAME,
  RESOURCE_REPOSITORY_LIST,
  SCHEDULER_ROUTE_NAME,
  TRAVEL_EXPENSES,
  REVIEW_TRAVEL_EXPENSES,
  SSP_MASTER,
  TASK_MANAGER,
  USER_GROUPS,
  CLIENTS_ROUTE_NAME,
  CONSENT_DASHBOARD,
  QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME,
  INTAKE,
  IFSP_ROUTE_NAME,
  BATELLE_DEVELOPMENT_INVENTORY_2,
  FACESHEET,
  EICS_TBR_FORM,
  LEA_REFERRAL_CONSENT,
  SSP_LIST,
} from '../routes/Routing';
import { checkPermissionForFeature } from '../utils/checkPermission';

export interface SidebarMenuItem {
  id: string;
  labelId: string;
  defaultLabel: string;
  to: string;
  type: 'NORMAL' | 'NESTED';
  leftIcon: string;
  rightIcon?: ReactNode;
  subMenus: SubMenu[];
}

export interface SubMenu {
  id: string;
  labelId: string;
  defaultLabel: string;
  to: string;
  leftIcon: string;
}

export const getSideBarMenus = (): SidebarMenuItem[] => {
  const menus: SidebarMenuItem[] = [
    {
      id: '0',
      labelId: 'dashboardText',
      defaultLabel: 'Dashboard',
      type: 'NORMAL',
      to: EMPLOYEE_DASHBOARD_ROUTE_NAME,
      leftIcon: 'dashboard',
      subMenus: [],
    },
    {
      id: '1',
      labelId: 'backend.clients',
      defaultLabel: 'Clients',
      type: 'NORMAL',
      to: CLIENTS_DASHBOARD_ROUTE_NAME,
      leftIcon: 'client',
      subMenus: [],
    },
    {
      id: '2',
      labelId: 'backend.referral_dashboard',
      defaultLabel: 'ReferralManagement',
      type: 'NORMAL',
      to: REFERRAL_DASHBOARD_ROUTE_NAME,
      leftIcon: 'referral',
      subMenus: [],
    },
    {
      id: '3',
      labelId: 'backend.appointment',
      defaultLabel: 'Scheduler',
      type: 'NORMAL',
      to: SCHEDULER_ROUTE_NAME,
      leftIcon: 'scheduler',
      subMenus: [],
    },
    {
      id: '4',
      labelId: 'backend.case_load',
      defaultLabel: 'Caseload List',
      type: 'NORMAL',
      to: CASE_LOAD_LIST,
      leftIcon: 'caseload',
      subMenus: [],
    },
    {
      id: '5',
      labelId: 'backend.task_manager',
      defaultLabel: 'Task Manager',
      type: 'NORMAL',
      to: TASK_MANAGER,
      leftIcon: 'taskmanager',
      subMenus: [],
    },
    {
      id: '6',
      labelId: 'backend.travel_expense',
      defaultLabel: 'Add Travel Expenses',
      to: TRAVEL_EXPENSES,
      type: 'NORMAL',
      leftIcon: 'addtravelExpense',
      subMenus: [],
    },
    {
      id: '7',
      labelId: 'backend.review_travel_expense',
      defaultLabel: 'Review Travel Expense',
      to: REVIEW_TRAVEL_EXPENSES,
      leftIcon: 'reviewtravelExpense',
      type: 'NORMAL',
      subMenus: [],
    },
    {
      id: '8',
      labelId: 'myActivityText',
      defaultLabel: 'My Activity',
      to: ACTIVITY_SHEET_ROUTE_NAME,
      leftIcon: 'addactivitysheet',
      type: 'NORMAL',
      subMenus: [],
    },
    {
      id: '9',
      labelId: 'backend.review_activity_sheet',
      defaultLabel: 'Review Activity',
      to: ACTIVITY_APPROVAL_OVERVIEW_SHEET_ROUTE_NAME,
      leftIcon: 'Reviewmyactivity',
      type: 'NORMAL',
      subMenus: [],
    },
    {
      id: '10',
      labelId: 'settingText',
      defaultLabel: 'Settings ',
      type: 'NESTED',
      to: 'preference',
      leftIcon: 'setup',
      subMenus: [
        {
          id: '1',
          labelId: 'backend.program_details',
          defaultLabel: 'Program List',
          to: PROGRAM_MANAGEMENT_ROUTE_NAME,
          leftIcon: '',
        },
        {
          id: '2',
          labelId: 'backend.employee',
          defaultLabel: 'Employee List',
          to: EMPLOYEES_ROUTE_NAME,
          leftIcon: 'employeeList',
        },
        {
          id: '3',
          labelId: 'backend.form_builder',
          defaultLabel: 'Form Repository',
          to: FORM_BUILDER_LIST_DISPLAY,
          leftIcon: '',
        },
        {
          id: '4',
          labelId: 'backend.insurance_master',
          defaultLabel: 'Insurance',
          to: INSURANCE_ROUTE_NAME,
          leftIcon: '',
        },
        {
          id: '5',
          labelId: 'backend.resource_repository',
          defaultLabel: 'Resource Repository',
          to: RESOURCE_REPOSITORY_LIST,
          leftIcon: '',
        },
        // {
        //   id: '6',
        //   labelId: 'backend.email_text_template',
        //   defaultLabel: 'Email/Text Template',
        //   to: EMAIL_TEXT_TEMPLATE,
        //   leftIcon: '',
        // },
        {
          id: '7',
          labelId: 'backend.ssp_management',
          defaultLabel: 'SSP Management',
          to: SSP_MASTER,
          leftIcon: '',
        },
        {
          id: '8',
          labelId: 'backend.code_list',
          defaultLabel: 'Code List',
          to: CODE_LIST,
          leftIcon: '',
        },
        {
          id: '9',
          labelId: 'backend.groups',
          defaultLabel: 'Groups',
          to: USER_GROUPS,
          leftIcon: '',
        },
      ],
    },
  ];
  const menuWithPermission: SidebarMenuItem[] = [];
  menus.forEach((menu) => {
    const permissionForTheFeature = checkPermissionForFeature(
      menu.labelId,
      'viewPermission',
    );
    if (permissionForTheFeature) {
      const subMenusWithPermission: SubMenu[] = [];
      menu.subMenus.forEach((subMenu) => {
        if (checkPermissionForFeature(subMenu.labelId, 'viewPermission')) {
          subMenusWithPermission.push(subMenu);
        }
        return subMenu;
      });
      const sidebarMenuItem: SidebarMenuItem = {
        ...menu,
        subMenus: subMenusWithPermission,
      };
      menuWithPermission.push(sidebarMenuItem);
    }
    return menu;
  });
  return menuWithPermission;
};

export const getFaceSheetSideBarManus = (): SidebarMenuItem[] => {
  return [
    {
      id: '0',
      labelId: 'Facesheet',
      defaultLabel: 'Facesheet',
      type: 'NORMAL',
      to: FACESHEET,
      leftIcon: '',
      subMenus: [],
    },
    {
      id: '1',
      labelId: 'Demographic.SidebarText',
      defaultLabel: 'Demographics',
      type: 'NORMAL',
      to: CLIENTS_ROUTE_NAME,
      leftIcon: '',
      subMenus: [],
    },
    {
      id: '2',
      labelId: 'Consents',
      defaultLabel: 'Consents',
      type: 'NORMAL',
      to: CONSENT_DASHBOARD,
      leftIcon: '',
      subMenus: [],
    },
    {
      id: '3',
      labelId: 'Intake',
      defaultLabel: 'Intake',
      type: 'NORMAL',
      to: INTAKE,
      leftIcon: '',
      subMenus: [],
    },
    {
      id: '4',
      labelId: 'BDI2.CreateNew',
      defaultLabel: 'Evaluation',
      type: 'NORMAL',
      to: BATELLE_DEVELOPMENT_INVENTORY_2,
      leftIcon: '',
      subMenus: [],
    },
    {
      id: '5',
      labelId: 'asqSe2Text',
      defaultLabel: 'ASQ: SE-2',
      type: 'NORMAL',
      to: QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME,
      leftIcon: '',
      subMenus: [],
    },
    {
      id: '6',
      labelId: 'IFSP',
      defaultLabel: 'IFSP',
      type: 'NORMAL',
      to: IFSP_ROUTE_NAME,
      leftIcon: '',
      subMenus: [],
    },
    {
      id: '7',
      labelId: 'referralToSSPNotification',
      defaultLabel: 'SSP Notification',
      type: 'NORMAL',
      to: SSP_LIST,
      leftIcon: '',
      subMenus: [],
    },
    {
      id: '8',
      labelId: 'leaReferralTitleSidebar',
      defaultLabel: 'LEA Referral',
      type: 'NORMAL',
      to: LEA_REFERRAL_CONSENT,
      leftIcon: '',
      rightIcon: 'dashboard',
      subMenus: [],
    },
    {
      id: '9',
      labelId: 'DischargeText',
      defaultLabel: 'Discharge',
      type: 'NORMAL',
      to: EICS_TBR_FORM,
      leftIcon: '',
      rightIcon: 'dashboard',
      subMenus: [],
    },
  ];
};

export const clientRoutes = [
  '/faceSheet',
  '/clients',
  '/consent-dashboard',
  '/consent-dashboard/consent-form',
  '/asq-dashboard',
  '/asq-dashboard/asq-questionnaire',
  '/intake',
  '/ifsp',
  '/ssp-dashboard/referral-to-ssp',
  '/BDI-2',
  '/lea-referral',
  '/referral-consent',
  '/eics-tbr-Transition',
  '/asq-dashboard/asq-questionnaire/assessment',
  '/asq-dashboard/asq-questionnaire/summary',
  '/BDI-2/clinical-judgement-eligibility',
  '/BDI-2/eligibility-determination',
  '/BDI-2/risk-factor',
  '/eics-tbr',
  '/intake-review',
  '/ssp-dashboard',
];
