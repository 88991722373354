import { ProgramPanelType, Signature } from '../eligibility/types';
import { DateString } from '../../utils/type';
import { RadioButtonData } from '../../components/formlib/CustomRadioButton';
import { v4 as uuidv4 } from 'uuid';
import {
  ServiceDeliveryPlanPropsData,
  TableData,
} from '../ifsp/ServiceDeliveryPlan';
import { HeadCell } from '../../components/formlib/TableHeader';

export const EvaluationQuestions: RadioButtonData[] = [
  {
    value: 'BDI 2',
    labelId: 'BDI 2',
    defaultLabel: 'BDI 2',
  },
  {
    value: 'Other Assessment',
    labelId: 'Other Assessment',
    defaultLabel: 'Other Assessment',
  },
];

export const DayCareQuestions: RadioButtonData[] = [
  {
    value: 'yes',
    labelId: 'Clientpage.RadiolabelA',
    defaultLabel: 'Yes',
  },
  {
    value: 'no',
    labelId: 'Clientpage.RadiolabelB',
    defaultLabel: 'No',
  },
];

export const LEAReferralReasons: RadioButtonData[] = [
  {
    value: 'leaReason1',
    labelId: 'leaReason1',
    defaultLabel: 'Family moved to another town within catchment area',
  },
  {
    value: 'leaReason2',
    labelId: 'leaReason2',
    defaultLabel: 'Parent consent not provided within timeframe',
  },
  {
    value: 'leaReason3',
    labelId: 'leaReason3',
    defaultLabel: 'Parent initiated referral',
  },
  {
    value: 'leaReason4',
    labelId: 'leaReason4',
    defaultLabel: 'Other',
  },
];

export interface CheckboxType {
  id: string;
  labelId: string;
  defaultLabelId: string;
  isChecked: boolean;
}

export const headCells: HeadCell<TableData>[] = [
  {
    id: 'eiService',
    labelId: 'EI Service/Method',
    defaultLabelId: 'EI Service/Method',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'providedBy',
    labelId: 'Provided by',
    defaultLabelId: 'Provided By',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'locationOfService',
    labelId: 'Location of Service',
    defaultLabelId: 'Location of Service',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'length',
    labelId: 'length',
    defaultLabelId: 'Length',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'period',
    labelId: 'period',
    defaultLabelId: 'Period',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'frequency',
    labelId: 'Frequency & Length',
    defaultLabelId: 'Frequency & Length',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'intensity',
    labelId: 'Intensity',
    defaultLabelId: 'Intensity',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'duration',
    labelId: 'Duration of Service',
    defaultLabelId: 'Duration of Service',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'startDate',
    labelId: 'Start Date',
    defaultLabelId: 'Start Date',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'endDate',
    labelId: 'End Date',
    defaultLabelId: 'End Date',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'reason',
    labelId: 'Reason',
    defaultLabelId: 'Reason',
    numeric: false,
    requiredSorting: false,
  },
];

export const LEAProblems: CheckboxType[] = [
  {
    id: uuidv4(),
    labelId: 'otText',
    defaultLabelId: 'OT',
    isChecked: false,
  },
  {
    id: uuidv4(),
    labelId: 'ptText',
    defaultLabelId: 'PT',
    isChecked: false,
  },
  {
    id: uuidv4(),
    labelId: 'speechText',
    defaultLabelId: 'Speech',
    isChecked: false,
  },
  {
    id: uuidv4(),
    labelId: 'cognitionText',
    defaultLabelId: 'Cognition',
    isChecked: false,
  },
  {
    id: uuidv4(),
    labelId: 'behaviorText',
    defaultLabelId: 'Behavior',
    isChecked: false,
  },
  {
    id: uuidv4(),
    labelId: 'visionText',
    defaultLabelId: 'Vision',
    isChecked: false,
  },
  {
    id: uuidv4(),
    labelId: 'hearingText',
    defaultLabelId: 'Hearing',
    isChecked: false,
  },
  {
    id: uuidv4(),
    labelId: 'grossMotorText',
    defaultLabelId: 'Gross Motor',
    isChecked: false,
  },
  {
    id: 'other',
    labelId: 'otherText',
    defaultLabelId: 'Other',
    isChecked: false,
  },
];

export interface LEAQuestion {
  id: string;
  question: string;
  answer: string;
}

export interface LEAReferralType extends ProgramPanelType {
  referralDate: DateString;
  dischargeReason: string | null;
  daysThirdBirth: number | null;
  referralReason: string | null;
  referralReasonOther: string | null;
  referringTo: string | null;
  currentIssues: string | null;
  currentOtherIssues: string | null;
  evaluationFrom: string | null;
  lastEvaluationDate: DateString;
  attachBDI2Results: boolean | null;
  childIsInDayCare: boolean | null;
  dayCareName: string | null;
  dayCarePhoneNumber: string | null;
  childSchedule: string | null;
  dayCareAddress: string | null;
  otherReleventInfo: string | null;
  reasonForReferral: {
    leaEvaluationRequested: string | null;
    adaptive: string | null;
    communication: string | null;
    personal: string | null;
    motor: string | null;
    cognitive: string | null;
    other: string | null;
  };
  earlyInterventionService: ServiceDeliveryPlanPropsData[];
  questionnaire: LEAQuestion[];
  employeeSignature: Signature | null;
  parentSignature: Signature | null;
  tpc: {
    date: DateString;
    time: string | null;
    location: string | null;
  };
  consentId: string;
  leaConsentSigned: boolean;
}
