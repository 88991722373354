import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import { Appointment } from '../../services/configApi/activitySheet/weeklyActivitySheetProvider';
import { FC, useContext, useEffect, useState } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { convertTimeDuration, getTimeString } from '../../utils/dateUtil';
import { ReactComponent as UndoIcon } from '../../assets/images/undoIcon.svg';
import { ReactComponent as ActivityLine } from '../../assets/images/activityLine.svg';
import { ReactComponent as ProgressNote } from '../../assets/images/ProgressNoteIcon.svg';
import { ReactComponent as ArchiveIcon } from '../../assets/images/archiveIcon.svg';
import TextWithIcon from '../../components/formlib/TextWithIcon';
import { LOCAL_TIME_12_HOUR_FORMAT } from '../../services/Constant';
import ActionToolTip from '../../components/formlib/ActionToolTip';
import { StatusType } from './ActivityTableRow';
import { getFullName } from '../../utils/nameUtils';
import { useNavigate } from 'react-router';
import { PROGRESS_NOTE_SETUP } from '../../routes/Routing';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { useIntl } from 'react-intl';
import { LookUpContext } from '../../context/LookUpContextProvider';
import { LookupItem } from '../../services/configApi/tcsLookups';

interface Header {
  empty: string;
  clientName: string;
  apptStartTime: string;
  duration: string;
  serviceCode: string;
  settingCode: string;
  modifiers: string;
  actions: string;
}

const headCells: HeadCell<Header>[] = [
  {
    id: 'empty',
    labelId: '',
    defaultLabelId: '',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'clientName',
    labelId: 'Dashboard.ClientName',
    defaultLabelId: 'Client Name',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'apptStartTime',
    labelId: 'appt',
    defaultLabelId: 'Appt Start Time',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'duration',
    labelId: 'myTimeOffDuration',
    defaultLabelId: 'Duration',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'serviceCode',
    labelId: 'serviceCodeText',
    defaultLabelId: 'Service Code',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'settingCode',
    labelId: 'settingCodeText',
    defaultLabelId: 'Setting Code',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'modifiers',
    labelId: 'modifiersText',
    defaultLabelId: 'Modifiers',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: 'actions',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'empty',
    labelId: '',
    defaultLabelId: '',
    numeric: false,
    requiredSorting: false,
  },
];

const AppointmentTableRow: FC<{
  appointments: Appointment[];
  parentArchived: boolean;
  isHoliday: boolean;
  rowId: number;
  isEmployee: boolean;
  parentStatus: StatusType | null;
  archiveAppointment: (id: string, archived: boolean) => void;
  screenName: string;
  selectedDate?: Date;
}> = ({
  appointments,
  parentArchived,
  archiveAppointment,
  isEmployee,
  isHoliday,
  rowId,
  parentStatus,
  screenName,
  selectedDate,
}) => {
  const navigate = useNavigate();
  const [serviceCode, setServiceCode] = useState<LookupItem[]>([]);
  const [settingCode, setSettingCode] = useState<LookupItem[]>([]);
  const rctl = useIntl();
  const { lookups } = useContext(LookUpContext);

  useEffect(() => {
    if (lookups === null) {
      return;
    }

    setServiceCode(() => {
      return lookups.serviceCode.map((e) => {
        return e;
      });
    });

    setSettingCode(() => {
      return lookups.settingsCode.map((e) => {
        return e;
      });
    });
  }, [lookups]);

  const getAppStartTime = (
    time: string | null,
    format: string,
    utc: boolean,
  ) => {
    if (time === null) {
      return '';
    }
    return getTimeString(time, format, utc);
  };

  const getServiceCode = (id: string | null) => {
    if (id === null) return '-';
    const code = serviceCode.find((item) => item.code === id || item.id === id);
    return code ? code.code : '-';
  };

  const getSettingsCode = (id: string | null) => {
    if (id === null) return '-';
    const code = settingCode.find((item) => item.code === id || item.id === id);
    return code ? code.code : '-';
  };

  const getDurationInDecimal = (duration: string | null) => {
    return `${convertTimeDuration(duration, true)} hrs`;
  };

  const getBackgroundColor = () => {
    if (isHoliday) {
      return '#FFEBDD';
    } else if (parentArchived) {
      return '#97A6A5';
    } else if (rowId === 0) {
      return '#ffffff';
    } else {
      return '#ECF9F8';
    }
  };

  const getStatusColor = (progressNotesStatus: string) => {
    if (progressNotesStatus === 'Completed') {
      return '#37D183';
    } else if (progressNotesStatus === 'Canceled') {
      return '#EB4C60';
    } else {
      return '#F9B803';
    }
  };

  const getClassName = () => {
    if (isHoliday) {
      return 'activity-table-bg holidayBg listDataTableHead';
    }
    return `activity-table-bg ${
      rowId !== 0 ? 'activitybg' : 'activitytransbg'
    } + ' listDataTableHead `;
  };

  const getActivityTableRow = (appointment: Appointment) => {
    return (
      <TableRow
        className="nestedTableImagePadLeft0"
        key={appointment.appointmentId}
      >
        <TableCell
          padding="none"
          sx={{
            borderBottom: 'unset',
            backgroundColor: getBackgroundColor(),
            position: 'relative',
          }}
        >
          <Box
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              width: 1,
              height: appointment.isLastIndex ? '50%' : '100%',
              backgroundColor: '#00C6B8',
            }}
          />

          <Box
            sx={{
              backgroundColor: getBackgroundColor(),
            }}
          >
            <ActivityLine />
          </Box>
        </TableCell>
        <TooltipTableCell
          value={getFullName(
            appointment.clientFirstName,
            appointment.clientLastName,
          )}
          visible={
            getFullName(appointment.clientFirstName, appointment.clientLastName)
              .length > 14
          }
          textSxProps={{
            color: '#008C82',
            cursor: 'pointer',
            marginLeft: '16px',
          }}
        />
        <TableCell padding={'none'}>
          <MediumTypography
            label={getAppStartTime(
              appointment.startTime,
              LOCAL_TIME_12_HOUR_FORMAT,
              true,
            )}
            sxProps={{ color: '#008C82', marginRight: '4px' }}
          />
        </TableCell>
        <TableCell padding={'none'}>
          <MediumTypography
            label={getDurationInDecimal(appointment.sessionDuration)}
          />
        </TableCell>
        <TableCell padding={'none'}>
          <MediumTypography label={getServiceCode(appointment.serviceCode)} />
        </TableCell>
        <TableCell padding={'none'}>
          <MediumTypography label={getSettingsCode(appointment.settingCode)} />
        </TableCell>
        <TableCell padding={'none'}>
          <MediumTypography label={appointment.modifier ?? '-'} />
        </TableCell>
        {parentArchived ? (
          <TableCell />
        ) : (
          <TableCell
            sx={{
              paddingLeft: '4px',
            }}
          >
            {appointment.archived ? (
              <Box
                display={'inline-block'}
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                }}
                onClick={() => {
                  if (
                    appointment.archivalStatus !== 'Submitted' &&
                    archiveAppointment &&
                    appointment.progressNotesId !== null
                  ) {
                    archiveAppointment(
                      appointment.progressNotesId,
                      !appointment.archived,
                    );
                  }
                }}
              >
                <TextWithIcon
                  className="paddingZero"
                  LeftIcon={
                    appointment.archivalStatus !== 'Submitted' && <UndoIcon />
                  }
                  labelId={'archivedText'}
                  defaultLabel="Archived"
                  sxProps={{ color: '#97A6A5', textTransform: 'none' }}
                />
              </Box>
            ) : (
              <Box
                className="alignItemCenter"
                sx={{
                  display: 'flex',
                }}
              >
                {checkPermissionForFeature(
                  'backend.progress_note',
                  'viewPermission',
                ) && (
                  <ActionToolTip
                    label={`${rctl.formatMessage({
                      id: 'progressNoteText',
                      defaultMessage: 'Progress Note',
                    })}: ${appointment.progressNotesStatus}`}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        cursor: 'pointer',
                        marginLeft: '8px',
                      }}
                      onClick={() => {
                        const attendies: { id: string }[] = [];
                        const clientIds: string[] = [];

                        attendies.push({ id: appointment.attendeeId });
                        clientIds.push(appointment.clientId);

                        if (appointment.commonAppointmentId) {
                          navigate(PROGRESS_NOTE_SETUP, {
                            state: {
                              sortRequired: isEmployee,
                              eventId: appointment.commonAppointmentId,
                              attendeeId: attendies,
                              clientIds: clientIds,
                              screenFrom: screenName,
                              selectedDate: selectedDate,
                            },
                          });
                        }
                      }}
                    >
                      <ProgressNote
                        stroke={getStatusColor(appointment.progressNotesStatus)}
                      />
                    </Box>
                  </ActionToolTip>
                )}
                {(parentStatus === 'Not Submitted' ||
                  parentStatus === 'Rejected') &&
                  isEmployee && (
                    <ActionToolTip labelId="archiveText" defaultLabel="Archive">
                      <Box
                        sx={{
                          padding: '4px',
                          display: 'flex',
                          cursor: 'pointer',
                          marginLeft: '8px',
                          opacity:
                            appointment.progressNotesStatus === 'Pending'
                              ? '0.5'
                              : 'unset',
                          pointerEvents:
                            appointment.progressNotesStatus === 'Pending'
                              ? 'none'
                              : 'auto',
                        }}
                        onClick={() => {
                          if (
                            archiveAppointment &&
                            appointment.progressNotesId !== null
                          ) {
                            if (
                              appointment.archivalStatus === null ||
                              appointment.archivalStatus === 'Pending'
                            ) {
                              archiveAppointment(
                                appointment.progressNotesId,
                                !appointment.archived,
                              );
                            }
                          }
                        }}
                      >
                        <ArchiveIcon />
                      </Box>
                    </ActionToolTip>
                  )}
              </Box>
            )}
          </TableCell>
        )}
        <TableCell
          sx={{
            backgroundColor: getBackgroundColor(),
            borderBottom: 'unset',
          }}
        />
      </TableRow>
    );
  };

  return (
    <Table
      className="activitySheetNextedTable listDataTableHead "
      sx={{
        marginLeft: '4px',
      }}
    >
      <TableHeader
        labelSxProp={{ whiteSpace: 'normal' }}
        headerNames={headCells}
        checkBoxRequired={false}
        className={'ActivityTableBorderHeader ' + getClassName()}
      />
      <TableBody className="activity-table-bg tableRowcss">
        {appointments.map((appointment) => {
          return getActivityTableRow(appointment);
        })}
      </TableBody>
    </Table>
  );
};

export default AppointmentTableRow;
