import { employeeRoles } from './type';

export const getFullName = (
  firstName: string | null,
  lastName: string | null,
): string => {
  const nameArray: string[] = [];
  if (firstName !== null && firstName.length > 0) {
    nameArray.push(firstName);
  }
  if (lastName !== null && lastName.length > 0) {
    nameArray.push(lastName);
  }
  return nameArray.length == 0 ? '-' : nameArray.join(' ');
};

export const getFullNameWithMiddleName = (
  firstName: string | null,
  middleName: string | null,
  lastName: string | null,
): string => {
  const nameArray: string[] = [];
  if (firstName !== null && firstName.length > 0) {
    nameArray.push(firstName);
  }
  if (middleName !== null && middleName.length > 0) {
    nameArray.push(middleName);
  }
  if (lastName !== null && lastName.length > 0) {
    nameArray.push(lastName);
  }
  return nameArray.length == 0 ? '-' : nameArray.join(' ');
};

export const prepareClientName = (
  firstName: string,
  middleName: string,
  lastName: string,
): string => {
  let clientName = '';
  clientName = firstName;
  if (middleName !== undefined && middleName.length !== 0) {
    clientName = clientName
      .concat(' ')
      .concat(middleName.substring(0, 1).toUpperCase());
  }
  return clientName.concat(' ').concat(lastName);
};

export const getRoleName = (employee: string): string => {
  if (!employee) {
    return '';
  }

  const roleLabels = employee.split(',').map((emp: string) => {
    const empRoles = employeeRoles.find((empRole) => empRole.id === emp);
    return empRoles ? empRoles.label : '';
  });

  return roleLabels.join(', ');
};

export const removeDoubleCommas = (input: string): string => {
  return input.replace(/, ,/g, ',').replace(/,,/g, ',');
};
