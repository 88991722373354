import {
  Box,
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Modal,
  Card,
} from '@mui/material';
import React, { FC } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import { CommunicationLog } from '../../utils/type';
import ButtonComponent from '../../components/formlib/ButtonComponent';

interface ModelPropType {
  showModal: boolean;
  onModalClose: () => void;
  data: CommunicationLog;
}

const CommunicationLogModel: FC<ModelPropType> = ({
  showModal,
  onModalClose,
  data,
}) => {
  return (
    <Modal open={showModal} onClose={onModalClose} closeAfterTransition={true}>
      <Card
        style={{
          position: 'absolute',
          left: '20%',
          right: '20%',
          top: '10%',
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          Date: {data.createdOn}
        </DialogTitle>
        <DialogContent>
          <Box>
            <Grid container spacing={4} padding={'8px'}>
              <Grid item xs={6}>
                <MediumTypography
                  fontweight={600}
                  labelid="CommunicationLogPage.contactedBy"
                  defaultlabel="Contacted By"
                />
                <MediumTypography marginTop={'8px'} label={data.contactedBy} />
              </Grid>
              <Grid item xs={6}>
                <MediumTypography
                  fontweight={600}
                  labelid="CommunicationLogPage.contact"
                  defaultlabel="Contact"
                />
                <MediumTypography marginTop={'8px'} label={data.contact} />
              </Grid>
              <Grid item xs={6}>
                <MediumTypography
                  fontweight={600}
                  labelid="CommunicationLogPage.communicationType"
                  defaultlabel="Communication Type"
                />
                <MediumTypography
                  marginTop={'8px'}
                  label={data.communicationType}
                />
              </Grid>
              <Grid item xs={6}>
                <MediumTypography
                  fontweight={600}
                  labelid="CommunicationLogPage.reasonForContact"
                  defaultlabel="Reason for Contact"
                />
                <MediumTypography
                  marginTop={'8px'}
                  label={data.reasonForContact}
                />
              </Grid>
              <Grid item xs={12}>
                <MediumTypography
                  fontweight={600}
                  labelid="CommunicationLogPage.note"
                  defaultlabel="Notes"
                />
                <MediumTypography
                  sxProps={{
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    maxLines: 5,
                    maxHeight: '100px',
                  }}
                  marginTop={'8px'}
                  label={data.notes ? data.notes : '-'}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            className="btn-primary btn-cancel"
            variantType="contained"
            type="submit"
            labelId="CommunicationLogPage.cancelBtn"
            defaultLabelId="Close"
            onClick={onModalClose}
          />
        </DialogActions>
      </Card>
    </Modal>
  );
};

export default CommunicationLogModel;
