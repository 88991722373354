// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#accordianClass .MuiAccordion-root & .MuiPaper-root-MuiAccordion-root {
  background-color: 'none';
}

#accordianClass .MuiPaper-root-MuiAccordion-root:last-of-type {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/codeList/DiagnosticCodes.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,8BAA8B;EAC9B,+BAA+B;AACjC","sourcesContent":["#accordianClass .MuiAccordion-root & .MuiPaper-root-MuiAccordion-root {\n  background-color: 'none';\n}\n\n#accordianClass .MuiPaper-root-MuiAccordion-root:last-of-type {\n  border-bottom-left-radius: 0px;\n  border-bottom-right-radius: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
