import { Box, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';

import { ReactComponent as EditIcon } from '../../assets/images/highlight.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';
import ToolTipIcon from '../../components/formlib/ToolTipIcon';
import { RowDataType } from './ChildandFamilyIFSPOutcomes';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import dayjs from 'dayjs';

export type ProgressReviewTableDataType = {
  evaluationId: number;
  evaluationName: string;
  evaluationDiscipline: string;
};
interface ProgressReviewTableRowType {
  data: RowDataType;
  isIfspScreenLocked: boolean;
  onEdit: (data: RowDataType) => void;
  onDelete: (data: RowDataType) => void;
}

const ProcessReviewTableRow: FC<ProgressReviewTableRowType> = ({
  data,
  isIfspScreenLocked,
  onEdit,
  onDelete,
}) => {
  const handleEdit = () => {
    onEdit(data);
  };

  const handleDelete = (id: number) => {
    if (id < 1) onDelete(data);
  };

  return (
    <TableRow>
      <TableCell>
        <TooltipTableCell
          value={data.concern ?? '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
          textSxProps={{
            maxWidth: '200px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={data.outcome ?? '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
          textSxProps={{
            maxWidth: '200px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={data.measurableProcedure ?? '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
          textSxProps={{
            maxWidth: '200px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={data.targetDate ?? '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
          textSxProps={{
            maxWidth: '200px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={
            data.startDate ? dayjs(data.startDate).format('MM/DD/YYYY') : '-'
          }
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
          textSxProps={{
            maxWidth: '200px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={data.endDate ? dayjs(data.endDate).format('MM/DD/YYYY') : '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
          textSxProps={{
            maxWidth: '200px',
          }}
        />
      </TableCell>
      <TableCell>
        <Box className="flex__">
          <Box
            component="div"
            className="cursor__pointer pointerEvents"
            onClick={() =>
              (data.endDate && data.isRecordSubmitted) || isIfspScreenLocked
                ? null
                : handleEdit()
            }
          >
            <ToolTipIcon
              labelId="BlockTime.edit"
              defaultLabel="Edit"
              Icon={<EditIcon />}
              cursor={
                (data.endDate && data.isRecordSubmitted) || isIfspScreenLocked
                  ? 'not-allowed'
                  : 'pointer'
              }
            />
          </Box>
          <Box
            component="div"
            className="cursor__pointer"
            onClick={() =>
              data.isRecordSubmitted ||
              data.tbrOutcomeId > 0 ||
              (data.endDate && data.isRecordSubmitted) ||
              isIfspScreenLocked
                ? null
                : handleDelete(data.tbrOutcomeId)
            }
          >
            <ToolTipIcon
              cursor={
                data.isRecordSubmitted ||
                data.tbrOutcomeId > 0 ||
                (data.endDate && data.isRecordSubmitted) ||
                isIfspScreenLocked
                  ? 'not-allowed'
                  : 'pointer'
              }
              labelId="ConsentForms.listActionDeleteToolTipLabel"
              defaultLabel="Delete"
              Icon={<DeleteIcon />}
            />
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ProcessReviewTableRow;
