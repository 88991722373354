// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tableheaderstyle {
  font-family: 'Lato-Bold', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: var(--thom-mono-gray);
}

span.Tableheaderstyle.Mui-active {
  color: var(--thom-mono-gray);
}

thead.MuiTableHead-root
  > tr.MuiTableRow-head
  > th.MuiTableCell-head
  > span.Tableheaderstyle {
  color: var(--white);
}

thead > tr > th > span > p {
  color: var(--white) !important;
}

tbody.tableRowcss > tr > td {
  height: 40px;
  padding: 6px 14px;
  color: var(--thom-table-content-black);
}
table > thead.listDataTableHead > tr > th {
  height: 40px;
  padding: 6px 14px;
  color: var(--thom-table-content-black);
  background-color: rgb(34 155 147);
}

svg.MuiTableSortLabel-icon {
  color: var(--white) !important;
}

tbody.tableRowcss > tr > td > div > div:first-child {
  margin-left: -10px !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ClientsDashboardPage.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;;;;EAIE,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,sCAAsC;AACxC;AACA;EACE,YAAY;EACZ,iBAAiB;EACjB,sCAAsC;EACtC,iCAAiC;AACnC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".Tableheaderstyle {\n  font-family: 'Lato-Bold', sans-serif;\n  font-style: normal;\n  font-weight: 700;\n  font-size: 14px;\n  line-height: 17px;\n  color: var(--thom-mono-gray);\n}\n\nspan.Tableheaderstyle.Mui-active {\n  color: var(--thom-mono-gray);\n}\n\nthead.MuiTableHead-root\n  > tr.MuiTableRow-head\n  > th.MuiTableCell-head\n  > span.Tableheaderstyle {\n  color: var(--white);\n}\n\nthead > tr > th > span > p {\n  color: var(--white) !important;\n}\n\ntbody.tableRowcss > tr > td {\n  height: 40px;\n  padding: 6px 14px;\n  color: var(--thom-table-content-black);\n}\ntable > thead.listDataTableHead > tr > th {\n  height: 40px;\n  padding: 6px 14px;\n  color: var(--thom-table-content-black);\n  background-color: rgb(34 155 147);\n}\n\nsvg.MuiTableSortLabel-icon {\n  color: var(--white) !important;\n}\n\ntbody.tableRowcss > tr > td > div > div:first-child {\n  margin-left: -10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
