import { Box, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import { ActivitySheetEmployee } from './types';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import ActionToolTip from '../../components/formlib/ActionToolTip';
import { ReactComponent as ViewIcon } from '../../assets/images/eye on.svg';
import { getFullName } from '../../utils/nameUtils';
import { convertTimeDuration } from '../../utils/dateUtil';

interface ActivitySheetEmployeeRowProps {
  data: ActivitySheetEmployee;
  handleViewClick: (id: string) => void;
}

const ActivitySheetEmployeeRow: FC<ActivitySheetEmployeeRowProps> = ({
  data,
  handleViewClick,
}) => {
  return (
    <TableRow
      hover
      tabIndex={-1}
      sx={{
        alignContent: 'flex-start',
        '&:nth-of-type(odd)': {
          backgroundColor: '#ECF9F8',
        },
      }}
    >
      <TooltipTableCell
        value={getFullName(data.firstName, data.lastName)}
        visible={true}
      />
      <TooltipTableCell
        value={data.employeeType !== null ? data.employeeType : '-'}
        visible={true}
      />
      <TooltipTableCell
        value={
          data.billableHours !== null
            ? convertTimeDuration(data.billableHours, true)
            : '-'
        }
        visible={false}
      />
      <TooltipTableCell
        value={
          data.nonBillableHours !== null
            ? convertTimeDuration(data.nonBillableHours, true)
            : '-'
        }
        visible={false}
      />
      <TooltipTableCell
        value={
          data.hoursOut !== null
            ? convertTimeDuration(data.hoursOut, true)
            : '-'
        }
        visible={false}
      />
      <TooltipTableCell
        value={
          data.totalHours !== null
            ? convertTimeDuration(data.totalHours, true)
            : '-'
        }
        visible={false}
      />
      <TooltipTableCell
        value={
          data.approvedNonBillableHours !== null
            ? convertTimeDuration(data.approvedNonBillableHours, true)
            : '-'
        }
        visible={false}
      />
      <TooltipTableCell
        value={data.productivity !== null ? data.productivity.toString() : '-'}
        visible={false}
      />
      <TooltipTableCell
        value={data.status !== null ? data.status : '-'}
        visible={false}
      />
      <TableCell>
        <ActionToolTip labelId="viewText" defaultLabel="View">
          <Box
            sx={{
              cursor: 'pointer',
              display: 'flex',
            }}
            onClick={() => {
              handleViewClick(data.id);
            }}
          >
            <ViewIcon />
          </Box>
        </ActionToolTip>
      </TableCell>
    </TableRow>
  );
};

export default ActivitySheetEmployeeRow;
