import { BDI2Data } from "../../../utils/type";
import { ApiMessage, ApiResponseHandler } from "../../ApiResponseHandler";
import ThomApiClient from "../../ThomApiClient";

export const addAssesment = async (
    params: BDI2Data,
  ): Promise<ApiMessage> => {
    const url = `client/evaluation?
    `;
    return ApiResponseHandler(
      await ThomApiClient.getInstance().post(url, params),
    );
  };


  export const editAssesment = async (
    params: BDI2Data,
    id: string,
  ): Promise<ApiMessage> => {
    const url = `client/evaluation/${id}

    `;
    return ApiResponseHandler(
      await ThomApiClient.getInstance().put(url, params),
    );
  };

  export const getEvaluationData = async (
  ): Promise<BDI2Data[]> => {
    return ApiResponseHandler(
      await ThomApiClient.getInstance().get(
        `client/evaluation`,
      ),
    );
  };

  export const uploadTBR = async (
    id: string | undefined,
  ): Promise<ApiMessage> => {
    const url = `evaluation/upload?id=${id}`;
    return ApiResponseHandler(
      await ThomApiClient.getInstance().put(url),
    );
  };