import React, { FC, useEffect, useRef, useState, useTransition } from 'react';
import { Box } from '@mui/system';
import TitleText from '../../components/formlib/TitleText';
import { useNavigate } from 'react-router';
import { ReactComponent as BackButton } from '../../assets/images/backarrow.svg';
import { AlertColor, Card, Grid, Typography } from '@mui/material';
import ASQQuestionnaireView from './ASQQuestionnaireView';
import questionnaires0MJson from './ASQQuestionnaireJson/Questionnaire/ASQ-SE-2_0-Mo.json';
import questionnaires2MJson from './ASQQuestionnaireJson/Questionnaire/ASQ-SE-2_02-Mo.json';
import questionnaires6MJson from './ASQQuestionnaireJson/Questionnaire/ASQ-SE-2_06-Mo.json';
import questionnaires12MJson from './ASQQuestionnaireJson/Questionnaire/ASQ-SE-2_12-Mo.json';
import questionnaires18MJson from './ASQQuestionnaireJson/Questionnaire/ASQ-SE-2_18-Mo.json';
import questionnaires24MJson from './ASQQuestionnaireJson/Questionnaire/ASQ-SE-2_24-Mo.json';
import questionnaires30MJson from './ASQQuestionnaireJson/Questionnaire/ASQ-SE-2_30-Mo.json';
import questionnaires36MJson from './ASQQuestionnaireJson/Questionnaire/ASQ-SE-2_36-Mo.json';
import SmallTypography from '../../components/formlib/SmallTypography';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import {
  ASQ_QUESTIONNAIRES_CLIENT_INFO,
  QUESTIONNAIRE_CLIENT_INFO_SUMMARY,
} from '../../routes/Routing';
import MediumTypography from '../../components/formlib/MediumTypography';
import ASQQuestionConcernComponent from '../../components/forms/SimpleTextRadioButtonAndTextArea';
import ASQAdditionalQuestions2MJson from './ASQQuestionnaireJson/OverallQuestion/ASQ-SE-2_02AQ.json';
import ASQAdditionalQuestions6MJson from './ASQQuestionnaireJson/OverallQuestion/ASQ-SE-2_06AQ.json';
import ASQAdditionalQuestions12MJson from './ASQQuestionnaireJson/OverallQuestion/ASQ-SE-2_12AQ.json';
import ASQAdditionalQuestions18MJson from './ASQQuestionnaireJson/OverallQuestion/ASQ-SE-2_18AQ.json';
import ASQAdditionalQuestions24MJson from './ASQQuestionnaireJson/OverallQuestion/ASQ-SE-2_24AQ.json';
import ASQAdditionalQuestions30MJson from './ASQQuestionnaireJson/OverallQuestion/ASQ-SE-2_30AQ.json';
import ASQAdditionalQuestions36MJson from './ASQQuestionnaireJson/OverallQuestion/ASQ-SE-2_36AQ.json';
import { useLocation } from 'react-router-dom';
import ASQQuestionnaireHeaderComponent from './ASQQuestionnaireHeaderComponent';
import SimpleTextAndTextArea from '../../components/forms/SimpleTextAndTextArea';
import { updateAsqse2 } from '../../services/configApi/forms/ASQSE2/ASQQuestionaireServices';

import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import ModalPopup from '../../components/formlib/ModalPopup';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import { checkPermissionForFeature } from '../../utils/checkPermission';

export interface QuestionsType {
  [key: number]: QuestionsTypeJSON;
}

export interface QuestionsTypeJSON {
  type: string;
  choice: string;
  concern: boolean;
  points: number;
  questionDesc: string;
}

const ASQQuestionnaire: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [questionnairesJson, setQuestionnairesJson] =
    useState(questionnaires0MJson);
  const [asqAdditionalQuestions, setAsqAdditionalQuestions] = useState(
    ASQAdditionalQuestions2MJson,
  );
  const [clientTotalMonths, setClientTotalMonths] = useState(2);
  const [clientStartMonth, setClientStartMonth] = useState(1);
  const [clientEndMonth, setClientEndMonth] = useState(2);

  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;

  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');

  const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);

  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);

  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);

  const parentRef = useRef<HTMLDivElement>(null);

  const handleOk = () => {
    setShowAlertDialog(false);
    navigate(ASQ_QUESTIONNAIRES_CLIENT_INFO, {
      state: { data: location.state, pathname: 'Edit' },
    });
  };

  useEffect(() => {
    toggleLoader(true);
    setQuestionnaireViewJson();
  }, []);

  const setQuestionnaireViewJson = () => {
    const clientAge = location.state.age;
    if (clientAge >= 1 && clientAge <= 41) {
      if (clientAge == 2) {
        setQuestionnairesJson(questionnaires2MJson);
        setAsqAdditionalQuestions(ASQAdditionalQuestions2MJson);
        setClientTotalMonths(2);
        setClientStartMonth(1);
        setClientEndMonth(2);
      } else if (clientAge == 6) {
        setQuestionnairesJson(questionnaires6MJson);
        setAsqAdditionalQuestions(ASQAdditionalQuestions6MJson);
        setClientTotalMonths(6);
        setClientStartMonth(3);
        setClientEndMonth(8);
      } else if (clientAge == 12) {
        setQuestionnairesJson(questionnaires12MJson);
        setAsqAdditionalQuestions(ASQAdditionalQuestions12MJson);
        setClientTotalMonths(12);
        setClientStartMonth(9);
        setClientEndMonth(14);
      } else if (clientAge == 18) {
        setQuestionnairesJson(questionnaires18MJson);
        setAsqAdditionalQuestions(ASQAdditionalQuestions18MJson);
        setClientTotalMonths(18);
        setClientStartMonth(15);
        setClientEndMonth(20);
      } else if (clientAge == 24) {
        setQuestionnairesJson(questionnaires24MJson);
        setAsqAdditionalQuestions(ASQAdditionalQuestions24MJson);
        setClientTotalMonths(24);
        setClientStartMonth(21);
        setClientEndMonth(26);
      } else if (clientAge == 30) {
        setQuestionnairesJson(questionnaires30MJson);
        setAsqAdditionalQuestions(ASQAdditionalQuestions30MJson);
        setClientTotalMonths(30);
        setClientStartMonth(27);
        setClientEndMonth(32);
      } else {
        setQuestionnairesJson(questionnaires36MJson);
        setAsqAdditionalQuestions(ASQAdditionalQuestions36MJson);
        setClientTotalMonths(36);
        setClientStartMonth(33);
        setClientEndMonth(41);
      }
    } else {
      setQuestionnairesJson(questionnaires2MJson);
      setAsqAdditionalQuestions(ASQAdditionalQuestions2MJson);
      setClientTotalMonths(2);
      setClientStartMonth(1);
      setClientEndMonth(2);
    }
  };

  const [isCheckedArray, setIsCheckedArray] = useState<boolean[]>(
    Array(questionnairesJson.data.length).fill(false),
  );

  const [selectedValues, setSelectedValues] = useState<string[]>(
    Array(questionnairesJson.data.length).fill(''),
  );

  const [points, setPoints] = useState<number[]>(
    Array(questionnairesJson.data.length).fill(0),
  );

  const [textInputValue, setTextInputValue] = useState<string[]>(
    Array(questionnairesJson.data.length).fill(''),
  );

  const [pending, startTrans] = useTransition();

  useEffect(() => {
    toggleLoader(true);
    setSelectedValues(Array(questionnairesJson.data.length).fill(''));
    setIsCheckedArray(Array(questionnairesJson.data.length).fill(false));
    setTextInputValue(Array(questionnairesJson.data.length).fill(''));
    startTrans(() => {
      setPoints(Array(questionnairesJson.data.length).fill(0));
    });
    toggleLoader(false);
  }, [questionnairesJson]);

  useEffect(() => {
    if (!pending) {
      if (points.length === questionnairesJson.data.length) {
        if (location.state.questions !== null) {
          toggleLoader(true);
          const questions: QuestionsType = location.state
            .questions as QuestionsType;
          Object.values(questions).forEach(
            (data: QuestionsTypeJSON, index: number) => {
              handleRadioChange(index, data.choice, 'Initial');
              handleInputChange(index, data.questionDesc);
              const exampleEvent: React.ChangeEvent<HTMLInputElement> = {
                target: {
                  value: '',
                  checked: data.concern, // Replace this with the actual checked value
                  name: '',
                },
              } as React.ChangeEvent<HTMLInputElement>;
              if (exampleEvent.target.checked) {
                handleCheckboxChange(index, exampleEvent);
              }
            },
          );
        }
        setCommentedValues();
        toggleLoader(false);
      }
    }
  }, [pending]);

  const setCommentedValues = () => {
    if (
      location.state.overallComments !== null &&
      location.state.overallComments.length > 0
    ) {
      const newComments = location.state.overallComments.map(
        (value: { comments: string; answer: string }, index: number) => {
          return {
            commentId: index,
            comments:
              value.comments !== null && value.comments !== undefined
                ? value.comments
                : '',
            answer:
              value.answer !== null && value.answer !== undefined
                ? value.answer
                : '',
          };
        },
      );
      setComment(newComments);
    } else {
      const newComment = Array.from({ length: 3 }, (_, index) => ({
        commentId: index,
        comments: '',
        answer: '',
      }));

      setComment(newComment);
    }
  };

  const handleSubmit = () => {
    toggleLoader(true);
    const result: QuestionsType = {};
    selectedValues.forEach((value, index) => {
      result[index + 1] = {
        type: questionnairesJson.data[index].type,
        choice: selectedValues[index],
        concern: isCheckedArray[index],
        points: points[index],
        questionDesc: textInputValue[index],
      };
    });

    const params = {
      ...location.state,
      questions: result,
      overallComments: comment,
      summary: {
        ...location.state.summary,
        totalScore: points.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0,
        ),
        overallConcern: [
          {
            answer: checkIfAnyConcernTrue(result),
            comments:
              location.state.summary && location.state.summary?.overallConcern
                ? location.state.summary?.overallConcern[0].comments
                : null,
          },
          {
            answer: comment[0].answer,
            comments: comment[0].comments,
          },
          {
            answer: comment[1].answer,
            comments: comment[1].comments,
          },
        ],
      },
    };

    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      updateAsqse2(location.state.id, params)
        .then((response) => {
          if (response)
            navigate(QUESTIONNAIRE_CLIENT_INFO_SUMMARY, { state: params });
          toggleLoader(false);
        })
        .catch((error) => {
          setOpen(true);
          toggleLoader(false);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('updateFailureMessage');
            setToastrDefaultMessage('Failed to update details');
          }
        });
    } else {
      navigate(QUESTIONNAIRE_CLIENT_INFO_SUMMARY, { state: params });
      toggleLoader(false);
    }
  };

  function checkIfAnyConcernTrue(resultData: QuestionsType) {
    for (const key in resultData) {
      if (resultData[key].concern) {
        return 'yes';
      }
    }
    return 'no';
  }

  const [comment, setComment] = useState<
    { commentId: number; comments: string; answer: string }[]
  >(
    asqAdditionalQuestions.map((_, index) => ({
      commentId: index,
      comments: '',
      answer: 'yes',
    })),
  );

  const onCommentValueChange = (
    value: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const updatedComments = [...comment];
    updatedComments[index].answer = value.target.value;
    setComment(updatedComments);
  };

  const onCommentChange = (value: { commentId: number; comments: string }) => {
    const updatedComments = [...comment];
    updatedComments[value.commentId].comments = value.comments;
    setComment(updatedComments);
  };

  const handleCheckboxChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsCheckedArray((previsCheckedArray) => {
      const newIsCheckedArray = [...previsCheckedArray];
      const newPoints = points;
      newIsCheckedArray[index] = event.target.checked;
      if (event.target.checked) {
        newPoints[index] = newPoints[index] + 5;
      } else {
        newPoints[index] = newPoints[index] - 5;
      }

      setPoints(newPoints); // Make sure to use spread operator while updating state
      return newIsCheckedArray;
    });
  };

  const handleRadioChange = (index: number, value: string, type: string) => {
    if (selectedValues.length === points.length)
      setSelectedValues((prevSelectedValues) => {
        const newSelectedValues = [...prevSelectedValues];
        const newPoints = points;

        if (newSelectedValues[index] === value) {
          if (type === 'User') {
            if (value === 'Z') {
              newPoints[index] -= 0;
            } else if (value === 'V') {
              newPoints[index] -= 5;
            } else if (value === 'X') {
              newPoints[index] -= 10;
            }
            newSelectedValues[index] = '';
          }
        } else {
          if (newSelectedValues[index] === 'Z') {
            newPoints[index] -= 0;
          } else if (newSelectedValues[index] === 'V') {
            newPoints[index] -= 5;
          } else if (newSelectedValues[index] === 'X') {
            newPoints[index] -= 10;
          }
          if (value === 'Z') {
            newPoints[index] += 0;
          } else if (value === 'V') {
            newPoints[index] += 5;
          } else if (value === 'X') {
            newPoints[index] += 10;
          }
          newSelectedValues[index] = value;
        }

        setPoints(newPoints); // Make sure to use spread operator while updating state
        return newSelectedValues;
      });
  };

  const handleInputChange = (index: number, value: string) => {
    setTextInputValue((prevTextInputValue) => {
      const newInputValues = [...prevTextInputValue];
      newInputValues[index] = value;
      return newInputValues;
    });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Box sx={{ bgcolor: '#E7F0F0', width: '100%', padding: '16px' }}>
        <ASQQuestionnaireHeaderComponent
          totalMonth={clientTotalMonths}
          startMonth={clientStartMonth}
          endMonth={clientEndMonth}
        />
        <>
          <Box
            sx={{
              display: 'flex',
            }}
            alignItems={'center'}
          >
            <Box
              onClick={() =>
                navigate(ASQ_QUESTIONNAIRES_CLIENT_INFO, {
                  state: { data: location.state, pathname: 'Edit' },
                })
              }
              sx={{
                cursor: 'pointer',
                display: 'flex',
              }}
              alignItems={'center'}
            >
              <BackButton />
            </Box>
            <TitleText
              labelid="QuestionnaireClientInfo.Titletext"
              defaultlabel="ASQ: SE-2 Ages & Stages Questionnaires"
              Sxprops={{ paddingLeft: '16px' }}
            />
          </Box>
          <div
            ref={parentRef}
            style={{
              pointerEvents: isScreenLocked ? 'none' : 'auto',
              opacity: isScreenLocked ? '0.5' : '1 ',
            }}
            onKeyDownCapture={(e) => {
              if (isScreenLocked) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            onFocus={() => {
              if (isScreenLocked) {
                if (parentRef.current) parentRef.current.focus();
              }
            }}
          >
            {/* Header */}
            <Box sx={{ mt: 3 }}>
              <Card>
                <Box
                  sx={{
                    borderBottom: '1px solid var(--mono-grey-2, #BBBFCE)',
                  }}
                >
                  <Grid container>
                    <Grid item xs={5} lg={5}>
                      <Typography
                        sx={{
                          color: 'var(--mono-grey, #97A6A5)',
                          fontFamilyamily: 'Lato-Regular',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          padding: 2,
                        }}
                      >
                        Questions
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      xs={4}
                      lg={4}
                      bgcolor="#ECF9F8"
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      alignContent={'center'}
                      padding={2}
                    >
                      <Typography
                        sx={{
                          color: 'var(--mono-grey, #97A6A5)',
                          fontFamilyamily: 'Lato-Regular',
                          fontSize: '14px',
                          fontStyle: 'normal',
                        }}
                      >
                        Often or Always
                      </Typography>
                      <Typography
                        sx={{
                          color: 'var(--mono-grey, #97A6A5)',
                          fontFamilyamily: 'Lato-Regular',
                          fontSize: '14px',
                          fontStyle: 'normal',
                        }}
                      >
                        Sometimes
                      </Typography>

                      <Typography
                        sx={{
                          color: 'var(--mono-grey, #97A6A5)',
                          fontFamilyamily: 'Lato-Regular',
                          fontSize: '14px',
                          fontStyle: 'normal',
                        }}
                      >
                        Rarely or Never
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1.5}
                      lg={1.5}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      padding={2}
                    >
                      <Typography
                        sx={{
                          color: 'var(--mono-grey, #97A6A5)',
                          fontFamilyamily: 'Lato-Regular',
                          fontSize: '14px',
                          fontStyle: 'normal',
                        }}
                      >
                        Concern
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1.5}
                      lg={1.5}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      padding={2}
                    >
                      <Typography
                        sx={{
                          color: 'var(--mono-grey, #97A6A5)',
                          fontFamilyamily: 'Lato-Regular',
                          fontSize: '14px',
                          fontStyle: 'normal',
                        }}
                      >
                        Points
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Box>
            {/* Header */}

            {/* Table body */}
            <ASQQuestionnaireView
              questionnairesArray={questionnairesJson}
              handleCheckboxChange={(
                index: number,
                event: React.ChangeEvent<HTMLInputElement>,
              ) => handleCheckboxChange(index, event)}
              handleRadioChange={(index: number, value: string) =>
                handleRadioChange(index, value, 'User')
              }
              handleInputChange={(index: number, value: string) =>
                handleInputChange(index, value)
              }
              initialCheck={selectedValues}
              isCheckedArray={isCheckedArray}
              selectedValues={selectedValues}
              points={points}
              textInputValue={textInputValue}
            />
            {/* Table body */}

            <Box>
              <Card>
                <Box
                  sx={{
                    borderBottom: '1px solid var(--mono-grey-2, #BBBFCE)',
                  }}
                >
                  <Grid container>
                    <Grid item xs={9} lg={9}></Grid>
                    <Grid item xs={2} lg={2}>
                      <Grid
                        sx={{
                          display: 'flex',
                          padding: '12px',
                          justifyContent: 'flex-end',
                          alignContent: 'flex-end',
                          alignItems: 'flex-end',
                        }}
                      >
                        <SmallTypography
                          labelId="vdfd"
                          defaultLabelId="Total Points: "
                          sxProps={{
                            fontSize: 18,
                            fontStyle: 'normal',
                            fontWeight: 600,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      lg={1}
                      sx={{
                        display: 'flex',
                        padding: '17px',
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Lato-Regular',
                          fontSize: 18,
                          fontStyle: 'normal',
                          fontWeight: 600,
                        }}
                      >
                        {points.reduce(
                          (accumulator, currentValue) =>
                            accumulator + currentValue,
                          0,
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Box>

            <Box>
              <Card>
                <Box sx={{ padding: '16px' }}>
                  <MediumTypography
                    labelid="QuestionnaireClientInfo"
                    defaultlabel="Overall use the space below for additional comments."
                    sxProps={{
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      marginY: 2,
                    }}
                  />
                  <ASQQuestionConcernComponent
                    customCommentStyles={{
                      height: '80px',
                      minHeight: '80px',
                      minWidth: '100%',
                      width: '100%',
                    }}
                    disabled={false}
                    onValueChange={onCommentValueChange}
                    handlechange={onCommentChange}
                    concernQuestions={asqAdditionalQuestions}
                    Value={comment}
                    initialCheck={comment}
                    labelidA="ASQQuestionnaires.RadioLabelA"
                    labelidB="ASQQuestionnaires.RadioLabelB"
                    defaultlabelidA="Yes"
                    defaultlabelidB="No"
                  />

                  <SimpleTextAndTextArea
                    questions={asqAdditionalQuestions}
                    Value={comment}
                    handlechange={onCommentChange}
                    maxLength={500}
                    customCommentStyles={{
                      height: '80px',
                      minHeight: '80px',
                      minWidth: '100%',
                      width: '100%',
                    }}
                  />
                </Box>
              </Card>
            </Box>
          </div>
          {/* Footer */}
          <Box sx={{ marginTop: '30px' }}>
            <Grid
              container
              direction="row"
              alignItems="right"
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Grid item sx={{ marginRight: '16px' }}>
                <ButtonComponent
                  className="btn-primary btn-cancel"
                  variantType="outlined"
                  labelId="cancelText"
                  defaultLabelId="Cancel"
                  onClick={() => setShowAlertDialog(true)}
                />
              </Grid>
              <Grid item>
                <ButtonComponent
                  className="btn-primary btn-submit"
                  variantType="contained"
                  labelId="QuestionnaireClientInfo.Next"
                  defaultLabelId="Next"
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
          </Box>
          {/* Footer */}
        </>
        {showAlertDialog && (
          <ModalPopup
            open={showAlertDialog}
            onCancel={() => {
              setShowAlertDialog(false);
            }}
            description="cancelWarningMessage"
            onOk={() => handleOk()}
            labelId1="Clientpage.cancelbtn"
            negativeActionLabel="cancelText"
            labelId2="Clientpage.Okbtn"
            positiveActionLabel="Ok"
          />
        )}
      </Box>
    </>
  );
};

export default ASQQuestionnaire;
