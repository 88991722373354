import { Box, TableCell, TableRow } from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import { FC } from 'react';
import { ReactComponent as ViewEye } from '../../assets/images/ViewEye.svg';
import ActionToolTip from '../../components/formlib/ActionToolTip';
import dayjs from 'dayjs';

interface EmployeeListData {
  employeeId: string;
  employeeName: string;
  expenseDate: string;
  dateOfSubmission: string;
  totalAmount: string;
  status: string;
  travelId: string;
}

interface EmployeeProps {
  data: EmployeeListData;
  index: number;
  onViewClick: () => void;
}

const ReviewTravelExpenseEmployeeTable: FC<EmployeeProps> = ({
  data,
  index,
  onViewClick,
}) => {
  const convertToLocalDateFormat = (datetimeString: string) => {
    // Parse the datetime string using Day.js
    const parsedDate = dayjs(datetimeString);
    // Format the date in MM/DD/YYYY format
    const formattedDate = parsedDate.format('MM/DD/YYYY');
    return formattedDate;
  };
  return (
    <TableRow
      hover
      tabIndex={-1}
      sx={{
        cursor: 'pointer',
        backgroundColor: index % 2 === 0 ? '#ECF9F8' : '#ffffff',
      }}
    >
      <TableCell>
        <MediumTypography
          label={
            data.employeeName !== '' || data.employeeName !== null
              ? data.employeeName
              : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          label={
            data.expenseDate !== '' || data.expenseDate !== null
              ? data.expenseDate
              : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          label={
            data.dateOfSubmission !== '' || data.dateOfSubmission !== null
              ? convertToLocalDateFormat(data.dateOfSubmission)
              : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          label={
            data.totalAmount !== '' || data.totalAmount !== null
              ? `$${data.totalAmount}`
              : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          label={data.status !== '' || data.status !== null ? data.status : '-'}
          textColor={
            data.status === 'Approved'
              ? '#37D183'
              : data.status === 'Pending'
              ? '#F9B803'
              : '#EB5757'
          }
        />
      </TableCell>
      <TableCell>
        <Box className="cursor__pointer">
          <ActionToolTip labelId="View" defaultLabel="View">
            <ViewEye onClick={onViewClick} />
          </ActionToolTip>
        </Box>
      </TableCell>
    </TableRow>
  );
};
export default ReviewTravelExpenseEmployeeTable;
