import {
  TableCell,
  TableRow,
  Box,
  Tooltip,
  Chip,
  Popover,
  Grid,
} from '@mui/material';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { ReactComponent as EditIcon } from '../../assets/images/editvector.svg';
import { ReactComponent as MessageIcon } from '../../assets/images/messageIcon.svg';
import { FC, useEffect, useState } from 'react';
import { Activity } from '../../services/configApi/activitySheet/weeklyActivitySheetProvider';
import { ReactComponent as StatusIcon } from '../../assets/images/statusIcon.svg';
import { ReactComponent as AcceptIcon } from '../../assets/images/acceptIcon.svg';
import { ReactComponent as RejectIcon } from '../../assets/images/rejectIcon.svg';
import { ReactComponent as UndoIcon } from '../../assets/images/undoIcon.svg';
import { ReactComponent as ArchiveIcon } from '../../assets/images/archiveIcon.svg';
import { ReactComponent as ChevronUp } from '../../assets/images/chevronUp.svg';
import { ReactComponent as ChevronDown } from '../../assets/images/chevronDown.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg';
import TextWithIcon from '../../components/formlib/TextWithIcon';
import ActivityEditModal from '../../components/formlib/modal/ActivityEditModal';
import ActivityNoteModal from '../../components/formlib/modal/ActivityNoteModal';
import { convertTimeDuration, getTimeString } from '../../utils/dateUtil';
import { EmployeeType } from '../../utils/type';
import AppointmentTableRow from './AppointmentTableRow';
import MediumTypography from '../../components/formlib/MediumTypography';
import ActionToolTip from '../../components/formlib/ActionToolTip';
import { useIntl } from 'react-intl';
import { getStatusColor } from '../../utils/utilities';
import { checkPermissionForFeature } from '../../utils/checkPermission';

export interface UpdateTime {
  nonBillableHrs?: string | null;
  approvedNonBillableHours?: string | null;
  sickHrs?: string | null;
  note?: string;
  flexHours?: string | null;
  hoursOut?: string | null;
  etHours?: string | null;
  ltiHours?: string | null;
  professionalHours?: string | null;
  otherHours?: string | null;
}

interface ActivityProps {
  props: Activity;
  employeeType: EmployeeType | null;
  isEmployee: boolean;
  headerCount: number;
  onStatusUpdate?: (activitySheetId: string | null, status: StatusType) => void;
  updateTimeOrNote?: (
    activitySheetId: string,
    data: UpdateTime,
    updateType: 'NOTE' | 'TIME',
  ) => void;
  archiveItem?: (
    activitySheetId: string,
    progressNotesId: string,
    archive: boolean,
  ) => void;
  screenName: string;
  selectedDate?: Date;
}

export type StatusType =
  | 'Submitted'
  | 'In Progress'
  | 'Approved'
  | 'Rejected'
  | 'Pending'
  | 'Open'
  | 'Completed'
  | 'Canceled'
  | 'Not Submitted';

const ActivityTableRow: FC<ActivityProps> = ({
  props,
  isEmployee,
  headerCount,
  employeeType,
  onStatusUpdate,
  updateTimeOrNote,
  archiveItem,
  screenName,
  selectedDate,
}) => {
  const [state, setState] = useState<{
    status: StatusType;
    openEditModel: boolean;
    openNoteModel: boolean;
  }>({
    status: 'Submitted',
    openEditModel: false,
    openNoteModel: false,
  });
  const [expand, setExpand] = useState<boolean>(
    props.appointments !== null &&
      !props.archived &&
      props.appointments.length > 0,
  );
  const rctl = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getAppStartTime = (
    time: string | null,
    format: string,
    utc: boolean,
  ) => {
    if (time === null) {
      return '';
    }
    return getTimeString(time, format, utc);
  };

  const displayPopup = (activity: Activity) => {
    if (activity.ltiHours !== null && activity.ltiHours !== '00:00') {
      return true;
    }
    if (activity.etHours !== null && activity.etHours !== '00:00') {
      return true;
    }
    if (activity.profHours !== null && activity.profHours !== '00:00') {
      return true;
    }
    if (activity.otherHours !== null && activity.otherHours !== '00:00') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setState((previousState) => ({
      ...previousState,
      status: props.status ?? 'Submitted',
    }));
    return () => {
      setExpand(false);
    };
  }, [props.status]);

  const getBackgroundColor = () => {
    if (props.isHoliday) {
      return '#FFEBDD';
    }
    return props.archived
      ? '#97A6A5'
      : props.rowId === 0
      ? '#ffffff'
      : '#ECF9F8';
  };

  return (
    <>
      {state.openEditModel && (
        <ActivityEditModal
          employeeType={employeeType}
          open={state.openEditModel}
          nonBillableHours={convertTimeDuration(props.nonBillableHours, true)}
          approvedNonBillableHours={convertTimeDuration(
            props.approvedNonBillableHours,
            true,
          )}
          sickHours={convertTimeDuration(props.sickHours, true)}
          etHours={convertTimeDuration(props.etHours, true)}
          ltiHours={convertTimeDuration(props.ltiHours, true)}
          profHours={convertTimeDuration(props.profHours, true)}
          otherHours={convertTimeDuration(props.otherHours, true)}
          handleClose={() => {
            setState((previousState) => ({
              ...previousState,
              openEditModel: !previousState.openEditModel,
            }));
          }}
          onTimeEdit={(values) => {
            if (updateTimeOrNote && props.activitySheetId !== null) {
              setState((previousState) => ({
                ...previousState,
                openEditModel: !previousState.openEditModel,
              }));
              updateTimeOrNote(
                props.activitySheetId,
                {
                  nonBillableHrs: values.nonBillableHours,
                  approvedNonBillableHours: values.approvedNonBillableHours,
                  sickHrs: values.sickHours,
                  note: props.description ?? '',
                  etHours: values.etHours,
                  ltiHours: values.ltiHours,
                  professionalHours: values.profHours,
                  otherHours: values.otherHours,
                },
                'TIME',
              );
            }
          }}
        />
      )}

      {state.openNoteModel && (
        <ActivityNoteModal
          open={state.openNoteModel}
          note={props.description ?? ''}
          handleClose={() => {
            setState((previousState) => ({
              ...previousState,
              openNoteModel: !previousState.openNoteModel,
            }));
          }}
          closeOnBackdropClick={true}
          maxLength={2000}
          updateNote={(note: string) => {
            if (updateTimeOrNote && props.activitySheetId !== null) {
              setState((previousState) => ({
                ...previousState,
                openNoteModel: !previousState.openNoteModel,
              }));
              updateTimeOrNote(
                props.activitySheetId,
                {
                  nonBillableHrs: props.nonBillableHours,
                  approvedNonBillableHours: props.approvedNonBillableHours,
                  sickHrs: props.sickHours,
                  note: note,
                  etHours: props.etHours,
                  ltiHours: props.ltiHours,
                  professionalHours: props.profHours,
                  otherHours: props.otherHours,
                },
                'NOTE',
              );
            }
          }}
        />
      )}

      <TableRow
        key={props.appointmentId}
        sx={{
          backgroundColor: getBackgroundColor(),
          opacity: props.archived ? '0.7' : '1',
        }}
      >
        <TableCell sx={{ borderBottom: expand ? 'unset' : '' }}>
          <Box display={'flex'} alignItems={'center'}>
            {props.appointments !== null &&
            props.appointments.length > 0 &&
            !props.archived ? (
              <Box
                sx={{ paddingLeft: '8px', cursor: 'pointer' }}
                onClick={() => {
                  setExpand(!expand);
                }}
              >
                {expand ? <ChevronUp /> : <ChevronDown />}
              </Box>
            ) : (
              <Box sx={{ paddingLeft: '22px' }} />
            )}
            <Box>
              {props.holidayTitle !== null && (
                <Box>
                  <Tooltip title={props.holidayTitle}>
                    <Chip
                      size="small"
                      label={props.holidayTitle}
                      sx={{
                        position: 'relative',
                        fontFamily: 'Lato-Regular',
                        fontSize: '10px',
                        color: 'white',
                        marginLeft: '8px',
                        backgroundColor: '#F96300',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '150px',
                      }}
                    />
                  </Tooltip>
                </Box>
              )}
              <MediumTypography
                sxProps={{
                  paddingLeft: '8px',
                  color:
                    props.isHoliday !== null && props.isHoliday
                      ? '#F96300'
                      : '#2A4241',
                }}
                label={getAppStartTime(props.day, 'dddd, MMM DD', true)}
              />
            </Box>
          </Box>
        </TableCell>
        <TooltipTableCell
          tooltipSxProps={{
            borderBottom: expand ? 'unset' : '',
          }}
          value={
            props.billableHours !== null
              ? convertTimeDuration(props.billableHours, true)
              : '-'
          }
          visible={false}
        />
        <TooltipTableCell
          tooltipSxProps={{
            borderBottom: expand ? 'unset' : '',
          }}
          value={
            props.approvedNonBillableHours !== null
              ? convertTimeDuration(props.approvedNonBillableHours, true)
              : '-'
          }
          visible={false}
        />
        <TooltipTableCell
          tooltipSxProps={{
            borderBottom: expand ? 'unset' : '',
          }}
          value={
            props.nonBillableHours !== null
              ? convertTimeDuration(props.nonBillableHours, true)
              : '-'
          }
          visible={false}
        />
        <TooltipTableCell
          tooltipSxProps={{
            borderBottom: expand ? 'unset' : '',
          }}
          value={
            props.sickHours !== null
              ? convertTimeDuration(props.sickHours, true)
              : '-'
          }
          visible={false}
        />
        {employeeType === 'Salaried Exempt' && (
          <TableCell sx={{ borderBottom: expand ? 'unset' : '' }}>
            <Box display={'flex'} alignItems={'center'}>
              <MediumTypography
                label={
                  props.hoursOut !== null
                    ? convertTimeDuration(props.hoursOut, true)
                    : '-'
                }
              />
              {displayPopup(props) && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '4px',
                    cursor: 'pointer',
                  }}
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  <InfoIcon />
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Grid
                      container
                      padding={'8px'}
                      display={'flex'}
                      flexDirection={'column'}
                    >
                      {props.etHours !== null && props.etHours !== '00:00' && (
                        <Grid item>
                          <Box display={'flex'}>
                            <MediumTypography
                              label={`${rctl.formatMessage({
                                id: 'etHoursText',
                                defaultMessage: 'ET Hrs',
                              })}:`}
                            />
                            <MediumTypography
                              className="pl-sm"
                              label={convertTimeDuration(props.etHours, true)}
                            />
                          </Box>
                        </Grid>
                      )}

                      {props.ltiHours !== null &&
                        props.ltiHours !== '00:00' && (
                          <Grid item>
                            <Box display={'flex'}>
                              <MediumTypography
                                label={`${rctl.formatMessage({
                                  id: 'ltiHoursText',
                                  defaultMessage: 'LTI Hrs',
                                })}:`}
                              />
                              <MediumTypography
                                className="pl-sm"
                                label={convertTimeDuration(
                                  props.ltiHours,
                                  true,
                                )}
                              />
                            </Box>
                          </Grid>
                        )}

                      {props.profHours !== null &&
                        props.profHours !== '00:00' && (
                          <Grid item>
                            <Box display={'flex'}>
                              <MediumTypography
                                label={`${rctl.formatMessage({
                                  id: 'profHoursText',
                                  defaultMessage: 'Professional Hrs',
                                })}:`}
                              />
                              <MediumTypography
                                className="pl-sm"
                                label={convertTimeDuration(
                                  props.profHours,
                                  true,
                                )}
                              />
                            </Box>
                          </Grid>
                        )}

                      {props.otherHours !== null &&
                        props.otherHours !== '00:00' && (
                          <Grid item>
                            <Box display={'flex'}>
                              <MediumTypography
                                label={`${rctl.formatMessage({
                                  id: 'otherHoursText',
                                  defaultMessage: 'Other Hrs',
                                })}:`}
                              />
                              <MediumTypography
                                className="pl-sm"
                                label={convertTimeDuration(
                                  props.otherHours,
                                  true,
                                )}
                              />
                            </Box>
                          </Grid>
                        )}
                    </Grid>
                  </Popover>
                </Box>
              )}
            </Box>
          </TableCell>
        )}
        <TooltipTableCell
          tooltipSxProps={{
            borderBottom: expand ? 'unset' : '',
          }}
          value={
            props.totalHours !== null
              ? convertTimeDuration(props.totalHours, true)
              : '-'
          }
          visible={false}
        />
        {isEmployee ? (
          <TableCell
            sx={{
              borderBottom: expand ? 'unset' : '',
              display:
                screenName === 'EmployeeActivity'
                  ? null
                  : checkPermissionForFeature(
                      'backend.review_activity_sheet',
                      'editPermission',
                    ) && screenName === 'ApprovalActivity'
                  ? null
                  : 'none',
            }}
          >
            <Box sx={{ display: 'flex' }} alignItems={'center'}>
              <StatusIcon fill={getStatusColor(state.status)} />
              <MediumTypography
                sxProps={{ paddingLeft: '8px' }}
                label={props.status ?? '-'}
              />
            </Box>
          </TableCell>
        ) : (
          <TableCell
            padding={'none'}
            sx={{
              borderBottom: expand ? 'unset' : '',
              display:
                screenName === 'EmployeeActivity'
                  ? null
                  : checkPermissionForFeature(
                      'backend.review_activity_sheet',
                      'editPermission',
                    ) && screenName === 'ApprovalActivity'
                  ? null
                  : 'none',
            }}
          >
            {state.status !== 'Submitted' ? (
              <Box sx={{ display: 'flex' }} alignItems={'center'}>
                <TextWithIcon
                  LeftIcon={<StatusIcon fill={getStatusColor(state.status)} />}
                  label={state.status}
                  className="paddingZero"
                  defaultLabel="Status"
                  sxProps={{
                    color: getStatusColor(state.status),
                    paddingRight: '4px',
                    textTransform: 'none',
                  }}
                />
                {props.actionRequired !== 'NO' && (
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setState({ ...state, status: 'Submitted' });
                      if (onStatusUpdate) {
                        onStatusUpdate(props.activitySheetId, 'Submitted');
                      }
                    }}
                  >
                    <UndoIcon />
                  </Box>
                )}
              </Box>
            ) : (
              <Box sx={{ display: 'flex', marginLeft: '8px' }}>
                <Box
                  sx={{ cursor: 'pointer' }}
                  display={'flex'}
                  alignItems={'center'}
                  onClick={() => {
                    setState({ ...state, status: 'Approved' });
                    if (onStatusUpdate) {
                      onStatusUpdate(props.activitySheetId, 'Approved');
                    }
                  }}
                >
                  <AcceptIcon />
                </Box>
                <Box
                  sx={{ cursor: 'pointer', marginLeft: '24px' }}
                  display={'flex'}
                  alignItems={'center'}
                  onClick={() => {
                    setState({ ...state, status: 'Rejected' });
                    if (onStatusUpdate) {
                      onStatusUpdate(props.activitySheetId, 'Rejected');
                    }
                  }}
                >
                  <RejectIcon />
                </Box>
              </Box>
            )}
          </TableCell>
        )}

        <TableCell
          sx={{
            borderBottom: expand ? 'unset' : '',
            display:
              screenName === 'EmployeeActivity'
                ? null
                : checkPermissionForFeature(
                    'backend.review_activity_sheet',
                    'editPermission',
                  ) && screenName === 'ApprovalActivity'
                ? null
                : 'none',
          }}
        >
          {props.archived ? (
            <Box
              display={'inline-block'}
              sx={{
                backgroundColor: '#fff',
                borderRadius: '4px',
                cursor: 'pointer',
                paddingLeft: '8px',
                paddingRight: '4px',
              }}
              onClick={() => {
                if (
                  props.archivalStatus !== null &&
                  props.archivalStatus !== 'Submitted' &&
                  archiveItem &&
                  props.activitySheetId !== null
                ) {
                  archiveItem(props.activitySheetId, '', !props.archived);
                }
              }}
            >
              <TextWithIcon
                LeftIcon={
                  props.archivalStatus !== null &&
                  props.archivalStatus !== 'Submitted' && <UndoIcon />
                }
                className="paddingZero"
                labelId={'archivedText'}
                defaultLabel="Archived"
                sxProps={{ color: '#97A6A5', textTransform: 'none' }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                pointerEvents: props.status === 'Approved' ? 'none' : 'auto',
                opacity: props.status === 'Approved' ? 0.5 : 1,
              }}
            >
              <ActionToolTip labelId="BlockTime.note" defaultLabel="Note">
                <Box
                  sx={{ padding: '4px', display: 'flex', cursor: 'pointer' }}
                  onClick={() => {
                    setState((previousState) => ({
                      ...previousState,
                      openNoteModel: !previousState.openNoteModel,
                    }));
                  }}
                >
                  <MessageIcon
                    stroke={
                      props.description !== null && props.description.length > 0
                        ? '#00C6B8'
                        : '#97A6A5'
                    }
                  />
                </Box>
              </ActionToolTip>
              {isEmployee &&
                props.status !== 'Approved' &&
                props.status !== 'Submitted' && (
                  <>
                    <ActionToolTip labelId="editText" defaultLabel="Edit">
                      <Box
                        sx={{
                          padding: '4px',
                          display: 'flex',
                          cursor: 'pointer',
                          marginLeft: '8px',
                        }}
                        onClick={() => {
                          setState((previousState) => ({
                            ...previousState,
                            openEditModel: !previousState.openEditModel,
                          }));
                        }}
                      >
                        <EditIcon />
                      </Box>
                    </ActionToolTip>
                    <ActionToolTip labelId="archiveText" defaultLabel="Archive">
                      <Box
                        sx={{
                          padding: '4px',
                          display: 'flex',
                          cursor: 'pointer',
                          marginLeft: '8px',
                        }}
                        onClick={() => {
                          if (
                            props.archivalStatus === null ||
                            props.archivalStatus === 'Pending'
                          ) {
                            if (archiveItem && props.activitySheetId !== null) {
                              if (expand) {
                                setExpand(!expand);
                              }
                              archiveItem(
                                props.activitySheetId,
                                '',
                                !props.archived,
                              );
                            }
                          }
                        }}
                      >
                        <ArchiveIcon />
                      </Box>
                    </ActionToolTip>
                  </>
                )}
              {!isEmployee && state.status !== 'Approved' && (
                <ActionToolTip labelId="editText" defaultLabel="Edit">
                  <Box
                    sx={{
                      padding: '4px',
                      display: 'flex',
                      cursor: 'pointer',
                      marginLeft: '8px',
                    }}
                    onClick={() => {
                      setState((previousState) => ({
                        ...previousState,
                        openEditModel: !previousState.openEditModel,
                      }));
                    }}
                  >
                    <EditIcon />
                  </Box>
                </ActionToolTip>
              )}
            </Box>
          )}
        </TableCell>
      </TableRow>

      {expand === true && props.appointments !== null && (
        <TableRow
          sx={{
            backgroundColor: getBackgroundColor(),
          }}
        >
          <TableCell colSpan={headerCount}>
            <AppointmentTableRow
              rowId={props.rowId}
              appointments={props.appointments}
              parentArchived={props.archived}
              isHoliday={props.isHoliday ?? false}
              parentStatus={props.status}
              isEmployee={isEmployee}
              archiveAppointment={(sheetId, archived) => {
                if (archiveItem) {
                  archiveItem(props.activitySheetId, sheetId, archived);
                }
              }}
              screenName={screenName}
              selectedDate={selectedDate}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ActivityTableRow;
