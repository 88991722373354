import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  AlertColor,
  styled,
  Badge,
} from '@mui/material';
import TitleText from '../../components/formlib/TitleText';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import { Order } from '../../utils/type';
import {
  OrganizationListType,
  deleteOrgById,
  getAllOrganizationListAPI,
} from '../../services/configApi/sspContactList/sspContact';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { ReactComponent as EditIcon } from '../../assets/images/editvector.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';
import AddOrganization, {
  TreatmentTypes,
} from '../../components/formlib/modal/AddOrganization';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import ModalPopup from '../../components/formlib/ModalPopup';
import ProviderList from '../../components/formlib/modal/ProviderList';
import SearchBox from '../../components/formlib/SearchBox';
import { debounce } from 'lodash';
import SearchNotFound from '../../components/formlib/SearchNotFound';
import EmptyScreen from '../../components/shared/EmptyScreen';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import AppPagination from '../../components/shared/AppPagination';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import dayjs from 'dayjs';

const CustomBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#37D183',
    marginRight: '10px',
    height: '8px',
    width: '8px',
    top: 0,
    bottom: 0,
  },
}));

const CustomBadges = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#CF273C',
    marginRight: '10px',
    height: '8px',
    width: '8px',
    top: 0,
    bottom: 0,
  },
}));

interface Data {
  organizationName: string;
  address: string;
  cityState: string;
  status: string;
  treatmentType: string;
  areaServed: string;
  actions: string;
}

const initialHeadCells: HeadCell<Data>[] = [
  {
    id: 'organizationName',
    labelId: 'ssp.organizationName',
    defaultLabelId: 'Organization Name',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'address',
    labelId: 'ssp.address',
    defaultLabelId: 'Address',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'cityState',
    labelId: 'ssp.cityState',
    defaultLabelId: 'City/State',
    numeric: false,
    requiredSorting: false,
  },

  {
    id: 'treatmentType',
    labelId: 'ssp.treatmentType',
    defaultLabelId: 'Treatment Type',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'areaServed',
    labelId: 'ssp.areaServed',
    defaultLabelId: 'Area Served',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'status',
    labelId: 'ssp.status',
    defaultLabelId: 'Status',
    numeric: false,
    requiredSorting: false,
  },
];

const OrganizationList = () => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('organizationName');
  const [openModal, setOpenModal] = React.useState(false);
  const [openSspModal, setOpenSspModal] = React.useState(false);
  const [organizationList, setOrganizationList] =
    useState<OrganizationListType[]>();
  const [paginationCount, setPaginationCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(0);
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const PAGE_SIZE = 10;
  const [onsearchquery, setOnsearchquery] = useState('');
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [searchquery, setSearchquery] = useState('');
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [getOrganizationById, setOrganizationByid] =
    useState<OrganizationListType>();
  const [orgId, setOrgId] = React.useState('');
  const [showAlertDialog, setShowAlertDialog] = React.useState<boolean>(false);
  const [showEmptyScreen, setShowEmptyScreen] = useState<boolean>(false);
  const [headCells, setHeadCells] = useState(initialHeadCells);

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getAllOrganzations = () => {
    toggleLoader(true);
    getAllOrganizationListAPI(
      pageNumber,
      PAGE_SIZE,
      orderBy,
      order,
      onsearchquery,
    )
      .then((response) => {
        setOrganizationList(response.sspOrganizationDtoList);
        setPaginationCount(response.count);
        toggleLoader(false);
        if (response.count === 0 && pageNumber === 0 && onsearchquery === '') {
          setShowEmptyScreen(true);
        } else {
          setShowEmptyScreen(false);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('Organization.getFailedError');
          setToastrDefaultMessage('Failed to get organization details');
        }
      });
  };

  useEffect(() => {
    if (searchTriggered) {
      setOnsearchquery(searchquery);
      setSearchTriggered(false);
    }
    if (!searchquery) {
      setOnsearchquery(searchquery);
    }
  }, [searchquery, searchTriggered]);

  const isValidInput = (value: string) => {
    const allowedCharacters = /^[a-zA-Z0-9,'.\s!-]*$/;
    return allowedCharacters.test(value);
  };

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      if (isValidInput(value)) {
        setPageNumber(0);
        setSearchquery(value);
        setSearchTriggered(true);
      } else {
        setOpen(true);
        setToastrVariable('error');
        setToastrId('Dashboard.ClientList.searchError');
        setToastrDefaultMessage(
          'Only alphanumeric characters, commas, periods, spaces, exclamation marks, and hyphens are allowed to be searched.',
        );
      }
    }, 500),
    [],
  );

  useEffect(() => {
    if (
      headCells.length === 6 &&
      (checkPermissionForFeature('backend.ssp_management', 'editPermission') ||
        checkPermissionForFeature('backend.ssp_management', 'deletePermission'))
    ) {
      setHeadCells([
        ...headCells,
        {
          id: 'actions',
          labelId: 'actions',
          defaultLabelId: 'Actions',
          numeric: false,
          requiredSorting: false,
        },
      ]);
    }
    getAllOrganzations();
  }, [order, orderBy, pageNumber, onsearchquery]);

  const handleSuccess = (successError: AlertColor, id: string, mes: string) => {
    setOpenModal(false);
    toggleLoader(true);
    toggleLoader(false);
    setOpen(true);
    setToastrVariable(successError);
    setToastrId(id);
    setToastrDefaultMessage(mes);
    getAllOrganzations();
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const editOrganization = (editValues: OrganizationListType) => {
    setOrganizationByid(editValues);
    setOpenModal(true);
  };

  const handleDelete = (id: string) => {
    setOrgId(id);
    setShowAlertDialog(true);
  };

  const deleteLog = () => {
    setShowAlertDialog(false);
    toggleLoader(true);
    if (orgId !== '') {
      deleteOrgById(orgId)
        .then(() => {
          if (organizationList?.length == 1) {
            setPageNumber(pageNumber - 1);
          }
          getAllOrganzations();
          setOpen(true);
          setToastrVariable('success');
          setToastrId('Contacts.deleteSuccessToastr');
          setToastrDefaultMessage('Record deleted Successfully');
        })
        .catch((error) => {
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('Contacts.deleteToastr');
            setToastrDefaultMessage('Failed to delete');
          }
        });
    }
  };

  const openSspProviderModal = (orgVals: OrganizationListType) => {
    setOrganizationByid(orgVals);
    setOpenSspModal(true);
  };
  return (
    <Box component="main">
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      {showAlertDialog && (
        <ModalPopup
          open={showAlertDialog}
          onCancel={() => {
            setShowAlertDialog(false);
          }}
          description="CommunicationLogTableView.deleteDescription"
          onOk={() => deleteLog()}
          labelId1="Clientpage.cancelbtn"
          negativeActionLabel="cancelText"
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="deleteText"
        />
      )}
      <Box component="section">
        {openModal && (
          <AddOrganization
            open={openModal}
            onSuccess={handleSuccess}
            organizationVals={getOrganizationById}
            handleClose={() => {
              setOpenModal(!openModal);
            }}
          />
        )}

        {openSspModal && (
          <ProviderList
            modalOpen={openSspModal}
            organizationDetails={getOrganizationById}
            handleCloses={() => {
              setOpenSspModal(!openSspModal);
            }}
          />
        )}

        <Box className="rowContainer">
          <Grid container>
            <Grid item xs={6} lg={6}>
              <TitleText
                labelid="sspLeftSideBar.textName"
                defaultlabel="SSP Management"
              />
            </Grid>
            {!showEmptyScreen && (
              <Grid item xs={6} lg={6} className="text-align-end">
                <SearchBox
                  className="mr-md"
                  labelId="organization.searchText"
                  defaultlabel="Search Organization"
                  onChange={handleSearchChange}
                />
                {checkPermissionForFeature(
                  'backend.ssp_management',
                  'addPermission',
                ) && (
                  <ButtonComponent
                    className="btn-primary btn-submit"
                    variantType="contained"
                    type="submit"
                    labelId="organizationAddBtnText"
                    defaultLabelId="Add Organization"
                    onClick={() => {
                      setOrganizationByid(undefined);
                      setOpenModal(true);
                    }}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Box>
        <Box component="section">
          {showEmptyScreen && (
            <EmptyScreen
              titleLabelId="organization.noOrgText"
              defaultTitleText="No Organization Added Yet."
              buttonLabelId="organizationAddBtnText"
              defaultButtonTitleText="Add Organization"
              showButton={checkPermissionForFeature(
                'backend.ssp_management',
                'addPermission',
              )}
              onButtonClick={() => {
                setOrganizationByid(undefined);
                setOpenModal(true);
              }}
            />
          )}
          {searchquery !== '' &&
            organizationList &&
            organizationList.length === 0 && <SearchNotFound />}
          {organizationList &&
            organizationList.length !== 0 &&
            !showEmptyScreen && (
              <Card>
                <TableContainer>
                  <Table>
                    <TableHeader
                      className="listDataTableHead"
                      headerNames={headCells}
                      checkBoxRequired={true}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody className="tableRowcss">
                      {organizationList &&
                        organizationList.map((data, index: number) => {
                          return (
                            <TableRow
                              hover
                              onClick={() => {}}
                              key={index}
                              sx={{
                                cursor: 'pointer',
                                backgroundColor:
                                  index % 2 === 0 ? '#ECF9F8' : '#ffffff',
                              }}
                            >
                              <TooltipTableCell
                                className="cursorPointer"
                                value={data.organizationName}
                                textSxProps={{ color: '#008C82' }}
                                visible={true}
                                onClick={() => {
                                  if (
                                    checkPermissionForFeature(
                                      'backend.ssp_management',
                                      'viewPermission',
                                    )
                                  ) {
                                    openSspProviderModal(data);
                                  }
                                }}
                              />
                              <TooltipTableCell
                                value={
                                  data.address1 && data.address2
                                    ? `${data.address1} , ${data.address2}`
                                    : data.address1
                                    ? data.address1
                                    : '-'
                                }
                                visible={true}
                              />
                              <TooltipTableCell
                                value={`${data.city}, ${data.state}`}
                                visible={true}
                              />

                              <TooltipTableCell
                                value={
                                  data.treatmentType === ''
                                    ? '-'
                                    : TreatmentTypes.find(
                                        (item) =>
                                          item.id === data.treatmentType,
                                      )?.label || '-'
                                }
                                visible={true}
                              />
                              <TooltipTableCell
                                value={data.areaServed ? data.areaServed : '-'}
                                visible={true}
                              />
                              <TableCell>
                                <div>
                                  <>
                                    {data.endDate &&
                                    data.startDate &&
                                    dayjs(data.endDate).isBefore(
                                      dayjs().format('MM/DD/YYYY'),
                                    ) ? (
                                      <CustomBadges variant="dot" />
                                    ) : (
                                      <CustomBadge variant="dot" />
                                    )}
                                    {data.endDate &&
                                    data.startDate &&
                                    dayjs(data.endDate).isBefore(
                                      dayjs().format('MM/DD/YYYY'),
                                    )
                                      ? 'In-Active'
                                      : 'Active'}
                                  </>
                                </div>
                              </TableCell>
                              {checkPermissionForFeature(
                                'backend.ssp_management',
                                'editPermission',
                              ) ||
                              checkPermissionForFeature(
                                'backend.ssp_management',
                                'deletePermission',
                              ) ? (
                                <TableCell>
                                  <Box className="flex__ alignItemCenter cursorPointer">
                                    {checkPermissionForFeature(
                                      'backend.ssp_management',
                                      'editPermission',
                                    ) && (
                                      <Box>
                                        <Tooltip title="Edit">
                                          <EditIcon
                                            onClick={() => {
                                              editOrganization(data);
                                            }}
                                          />
                                        </Tooltip>
                                      </Box>
                                    )}
                                    {checkPermissionForFeature(
                                      'backend.ssp_management',
                                      'deletePermission',
                                    ) && (
                                      <Box className="ml-md cursorPointer">
                                        <DeleteIcon
                                          onClick={() =>
                                            handleDelete(data.id ? data.id : '')
                                          }
                                        />
                                      </Box>
                                    )}
                                  </Box>
                                </TableCell>
                              ) : null}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            )}
          {paginationCount > 10 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <AppPagination
                pageNumber={pageNumber}
                paginationCount={paginationCount}
                handleChangePage={(_event, newPage) => {
                  if (newPage - 1 !== pageNumber) {
                    setPageNumber(newPage - 1);
                  }
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default OrganizationList;
