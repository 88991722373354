import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import TitleText from '../../components/formlib/TitleText';
import MediumTypography from '../../components/formlib/MediumTypography';
import { AlertColor, Card, Grid, Tooltip } from '@mui/material';
import Textinput from '../../components/formlib/Textinput';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import SmallTypography from '../../components/formlib/SmallTypography';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { ParentInfoType } from '../employees/types';
import { useLocation, useNavigate } from 'react-router';
import {
  ASQ_QUESTIONNAIRES,
  QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME,
} from '../../routes/Routing';
import DropdownComponentProps from '../../components/formlib/DropdownComponent';
import { OptionType } from '../../utils/type';
import {
  chronologicalAgeLogic,
  datePickerMinDateMaxDateValidate,
} from '../../utils/dateUtil';
import {
  QuestionnaireClientInfoFormData,
  createAsqse2,
  getAsqse2,
  updateAsqse2,
} from '../../services/configApi/forms/ASQSE2/ASQQuestionaireServices';

import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import CommentTextbox from '../../components/formlib/CommentTextbox';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import _ from 'lodash';
import ModalPopup from '../../components/formlib/ModalPopup';
import { convertGenderToFrontend } from '../../utils/genderUtil';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import ProgramPanel from '../../components/forms/ProgramPanel';
import { removeDoubleCommas } from '../../utils/nameUtils';
import KeyValueComponent, {
  DataJsonType,
} from '../../components/forms/KeyValueComponent';
import { InatkeParentType } from '../../services/configApi/forms/Intake/IntakeServices';
import { Program } from '../eligibility/types';
import { nameValidation } from '../../utils/formValidationUtil';

const initialValue: QuestionnaireClientInfoFormData = {
  defaultParent: '',
  parentDescription: '',
  prematureWeeks: '',
  asqCompletion: new Date().toDateString(),
  prematureMonths: '',
  prematureDays: '',
  ageAtAdministration: '',
  otherName: '',
};

const validationSchema = yup.object().shape({
  defaultParent: yup
    .string()
    .required('QuestionnaireClientInfo.Parent/Guardian.Validation'),
  otherName: yup.string().when('defaultParent', {
    is: 'OTHER_PARENT_ID',
    then: yup.string().required('Clientpage.immuneerror').nullable(),
    otherwise: yup.string().nullable(),
  }),
  parentDescription: yup.string().nullable(),
  prematureWeeks: yup
    .number()
    .typeError('Please enter a valid number')
    .nullable()
    .integer('Pre-Mature Month must be an number'),
  asqCompletion: yup
    .date()
    .required('QuestionnaireClientInfo.DateComplete.Validation')
    .nullable(),
  prematureMonths: yup.string().nullable(),
  prematureDays: yup.string().nullable(),
});

const validateForm = (value: QuestionnaireClientInfoFormData) => {
  const errors: Partial<QuestionnaireClientInfoFormData> = {};

  if (datePickerMinDateMaxDateValidate(value.asqCompletion)) {
    errors.asqCompletion = 'datePickerMinDateMaxDateValidate';
  }
  return errors;
};

export type TotalResponse = {
  id?: string;
  age?: number | undefined;
  version?: string | null;
  clientIdentity?: string | null;
  asqCompletion: string | null;
  prematureWeeks: string;
  defaultParent: string;
  ageAtAdministration: string | null;
  parentInfo?: ParentInfoType[];
  parentDescription: string;
  prematureMonths: string | null;
  prematureDays: string | null;
  questions?: QuestionsJsonType;
  overallComments?: (CommentsJsonType | null)[];
  summary?: SummaryJsonType;
  programDetails?: Program;
  parentContacts?: InatkeParentType[];
  otherName: string;
};

export type QuestionsJsonType = {
  [key: number]: {
    type: string;
    choice: string;
    concern: boolean;
    points: number;
    questionDesc: string;
  };
};

export type CommentsJsonType = {
  answer: string;
  comments: string;
};

export type SummaryJsonType = {
  totalScore: string;
  overallConcern: (CommentsJsonType | null)[];
  followupConsiderations: (string | null)[];
  followup: FollowupJsonType;
};

export type FollowupJsonType = {
  selected: number[];
  months: string;
  caregiver: string;
  other: string;
};

const QuestionnaireClientInfo = () => {
  const formikRef = useRef<FormikProps<QuestionnaireClientInfoFormData>>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [parentList, setParentList] = useState<OptionType[]>([]);
  const [clientsInfo, setClientsInfo] =
    useState<QuestionnaireClientInfoFormData>(initialValue);
  const [openModal, setOpenModal] = useState(false);

  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;

  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [clientId, setClientId] = React.useState('');
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);

  const [parentViewDetails, setParentViewDetails] = useState<DataJsonType[]>(
    [],
  );
  const [secondaryParentViewDetails, setSecondaryParentViewDetails] = useState<
    DataJsonType[]
  >([]);

  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);

  const parentRef = useRef<HTMLDivElement>(null);

  const constructParentInfo = (parent: InatkeParentType): DataJsonType[] => {
    return [
      {
        id: 'parentGuardianName',
        labelid: 'sspReferral.parentName',
        defaultLabel: 'Parent/Guardian Name:',
        value: parent.name !== null ? parent.name : '-',
      },
      {
        id: 'contactRelationship',
        labelid: 'Facesheet.relationship',
        defaultLabel: 'Relationship:',
        value:
          parent.relationshipToClient !== null
            ? parent.relationshipToClient
            : '-',
      },
      {
        id: 'contactEmail',
        labelid: 'ConsentEmail',
        defaultLabel: 'Email:',
        value: parent.email && parent.email !== null ? parent.email : '-',
      },
      {
        id: 'contactAddress',
        labelid: 'ConsentForms.clientAddress',
        defaultLabel: 'Address:',
        value:
          parent.address !== null ? removeDoubleCommas(parent.address) : '-',
      },
    ];
  };

  const constructSelectedParentInfo = (
    parentInfo: ParentInfoType,
  ): DataJsonType[] => {
    return [
      {
        id: 'contactEmail',
        labelid: 'QuestionnaireClientInfo.Email',
        defaultLabel: 'Email:',
        value: parentInfo.email !== null ? parentInfo.email : '-',
      },
      {
        id: 'contactPhone',
        labelid: 'QuestionnaireClientInfo.HomePhone',
        defaultLabel: 'Primary Phone:',
        value:
          parentInfo.homePhoneNumber !== null
            ? parentInfo.homePhoneNumber
            : '-',
      },
      {
        id: 'contactRelationship',
        labelid: 'Facesheet.relationship',
        defaultLabel: 'Relationship:',
        value:
          parentInfo.relationShipToClient !== null
            ? parentInfo.relationShipToClient
            : '-',
      },
      {
        id: 'contactAddress',
        labelid: 'ConsentForms.contactAddress',
        defaultLabel: 'Address:',
        value:
          parentInfo.street !== null && parentInfo.street !== ''
            ? `${parentInfo.street}, ${
                parentInfo.apartment ? `${parentInfo.apartment}, ` : ''
              }${parentInfo.city}, ${parentInfo.state}, ${parentInfo.zipCode}`
            : '-',
      },
    ];
  };

  useEffect(() => {
    toggleLoader(true);
    const handleParentList = async () => {
      if (location.state.data && location.state.pathname === 'Edit') {
        const parentDetails = await handleParentListFilter(location.state.data);
        if (parentDetails) {
          setClientsInfo({
            ...location.state.data,
            selectedParentInfo: parentDetails[0],
          });
          if (
            location.state.data.parentContacts &&
            location.state.data.parentContacts.length > 0
          ) {
            const parentDetailsfiltered =
              location.state.data.parentContacts.map(constructParentInfo);
            setParentViewDetails(parentDetailsfiltered[0] || []);
            setSecondaryParentViewDetails(parentDetailsfiltered[1] || []);
          }
          setClientId(location.state.data.clientIdentity);
        }
        toggleLoader(false);
      } else if (location.state.data && location.state.pathname === 'Add') {
        setClientsInfo(initialValue);
        getClientInfo();
      } else {
        setClientsInfo(initialValue);
        getClientInfo();
      }
    };
    handleParentList();
  }, []);

  const handleFormSubmit = (value: QuestionnaireClientInfoFormData) => {
    toggleLoader(true);

    const params = {
      ...clientsInfo,
      clientIdentity: clientId,
      asqCompletion: value.asqCompletion,
      prematureWeeks: value.prematureWeeks,
      defaultParent: value.defaultParent,
      parentDescription: value.parentDescription,
      prematureMonths: value.prematureMonths,
      prematureDays: value.prematureDays,
      ageAtAdministration: value.ageAtAdministration,
      otherName: value.otherName,
    };

    if (
      checkPermissionForFeature('backend.clients', 'editPermission') &&
      location.state.pathname === 'Edit'
    ) {
      if (clientsInfo.age && clientsInfo.id)
        updateAsqse2(clientsInfo.id, params)
          .then((response) => {
            if (response)
              navigate(ASQ_QUESTIONNAIRES, {
                state: params,
              });
            toggleLoader(false);
          })
          .catch((error) => {
            setOpen(true);
            toggleLoader(false);
            setToastrVariable('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              setToastrId('updateFailureMessage');
              setToastrDefaultMessage('Failed to update details');
            }
          });
    } else if (
      checkPermissionForFeature('backend.clients', 'editPermission') &&
      location.state.pathname === 'Add'
    ) {
      createAsqse2(params)
        .then((response) => {
          if (response) {
            navigate(ASQ_QUESTIONNAIRES, {
              state: { ...params, id: response.id },
            });
            toggleLoader(false);
          }
        })
        .catch((error) => {
          setOpen(true);
          toggleLoader(false);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('createFailureMessage');
            setToastrDefaultMessage('Failed to create details');
          }
        });
    } else {
      if (clientsInfo.age) {
        navigate(ASQ_QUESTIONNAIRES, {
          state: params,
        });
        toggleLoader(false);
      }
    }
  };

  const handleParentDetails = (parentId: string) => {
    if (clientsInfo.parentInfo) {
      const parentDetails = clientsInfo.parentInfo.filter(
        (value: ParentInfoType) => {
          return value.parentInfoId === parentId;
        },
      );

      if (formikRef.current)
        setClientsInfo({
          ...clientsInfo,
          asqCompletion: formikRef.current.values.asqCompletion,
          prematureWeeks: formikRef.current.values.prematureWeeks,
          parentDescription: clientsInfo.parentDescription,
          prematureMonths: formikRef.current.values.prematureMonths,
          prematureDays: formikRef.current.values.prematureDays,
          defaultParent: parentId,
          selectedParentInfo: parentDetails[0],
        });
    }
  };

  const getClientInfo = () => {
    const valueFiltered = location.state.data;

    const calculatedAge = chronologicalAgeLogic(
      new Date(valueFiltered.dateOfBirth),
      new Date(),
    );

    let ageAtAdministration = '';
    let clientAge = 0;
    if (location.state.data && location.state.data.ageAtAdministration) {
      ageAtAdministration = location.state.data.ageAtAdministration;
      clientAge = location.state.data.age;
    } else {
      ageAtAdministration =
        calculatedAge.totalMonths + ' M' + ', ' + calculatedAge.days + ' Days';
      clientAge = getAge(calculatedAge.totalMonths);
    }

    const programIdString = localStorage.getItem('selectedProgram') ?? '';
    const programId =
      programIdString !== '' ? JSON.parse(programIdString) : null;

    if (programId !== null) toggleLoader(true);
    const clientIdentity = localStorage.getItem('ClientId');
    if (clientIdentity) {
      setClientId(clientIdentity);
      getAsqse2()
        .then(async (response) => {
          const parentDetails = await handleParentListFilter(response);
          if (parentDetails)
            setClientsInfo({
              ...response,
              asqCompletion:
                response.asqCompletion === null
                  ? new Date().toDateString()
                  : response.asqCompletion,
              age: clientAge,
              ageAtAdministration: ageAtAdministration,
              selectedParentInfo: parentDetails[0],
              otherName: response.otherName ? response.otherName : '',
            });
          if (response.parentContacts && response.parentContacts.length > 0) {
            const parentDetailsfiltered =
              response.parentContacts.map(constructParentInfo);

            setParentViewDetails(parentDetailsfiltered[0] || []);
            setSecondaryParentViewDetails(parentDetailsfiltered[1] || []);
          }
          toggleLoader(false);
        })
        .catch((error) => {
          setOpen(true);
          setToastrVariable('error');
          toggleLoader(false);
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('loadFailureMessage');
            setToastrDefaultMessage('Failed to get details');
          }
        });
    }
  };

  const handleParentListFilter = async (response: TotalResponse) => {
    if (response.parentInfo && response.parentInfo.length > 0) {
      const parentListItem: OptionType[] = [];
      response.parentInfo.forEach((data: ParentInfoType) => {
        parentListItem.push({
          id: data.parentInfoId,
          label: data.name,
        });
      });
      setParentList(parentListItem);

      const parentDetails = response.parentInfo.filter(
        (values: ParentInfoType) => {
          return values.parentInfoId === response.defaultParent;
        },
      );

      return parentDetails;
    } else {
      setOpen(true);
      setToastrVariable('error');
      setToastrId('ASQQuestionnaireClientInfo.ParentInfoError');
      setToastrDefaultMessage(
        'No Parent/Guardian Found for the client Kindly add before you proceed',
      );
      toggleLoader(false);
    }
  };

  function getAge(clientAge: number) {
    if (clientAge >= 1 && clientAge <= 41) {
      if (clientAge <= 2) {
        return 2;
      } else if (clientAge <= 8) {
        return 6;
      } else if (clientAge <= 14) {
        return 12;
      } else if (clientAge <= 20) {
        return 18;
      } else if (clientAge <= 26) {
        return 24;
      } else if (clientAge <= 32) {
        return 30;
      } else {
        return 36;
      }
    } else {
      return 2;
    }
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box component="main">
      <Box component="section">
        <ModalPopup
          open={openModal}
          description="formUnsavedChangesMessage"
          onCancel={() => setOpenModal(false)}
          onOk={() => {
            setOpenModal(false);
            navigate(QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME, {
              state: {
                data: location.state.data,
                pathname: QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME,
              },
            });
          }}
          labelId1="Clientpage.cancelbtn"
          negativeActionLabel="Cancel"
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="Ok"
        />
        <SnackBarComponent
          open={open}
          handleClose={handleClose}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />
        <Box className="rowContainer">
          <CustomBackArrow
            onClick={() =>
              navigate(QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME, {
                state: {
                  data: location.state.data,
                  pathname: QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME,
                },
              })
            }
          />
          <Box component="div" className="ml-md">
            <TitleText
              labelid="QuestionnaireClientInfo.Titletext"
              defaultlabel="ASQ: SE-2 Ages & Stages Questionnaires"
            />
          </Box>
        </Box>
        <Formik
          innerRef={formikRef}
          initialValues={clientsInfo}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize
          validate={validateForm}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            setFieldValue,
            initialValues,
            errors,
            touched,
            handleSubmit,
          }) => (
            <>
              <Card className="formCardview mb-md p-sm">
                <Box component="div">
                  <Box component="div" className="p-sm">
                    <Box
                      component="div"
                      className="flex__justify__space-between"
                    ></Box>
                    <Box component="div">
                      <ProgramPanel program={clientsInfo.programDetails} />

                      <Grid item xs={12}>
                        <Box className="defaultHorizontalLine" />
                      </Grid>
                      <Box
                        component="div"
                        className="flex__ align__items__center"
                      >
                        <div
                          ref={parentRef}
                          style={{
                            pointerEvents: isScreenLocked ? 'none' : 'auto',
                            opacity: isScreenLocked ? '0.5' : '1 ',
                          }}
                          onKeyDownCapture={(e) => {
                            if (isScreenLocked) {
                              e.preventDefault();
                              e.stopPropagation();
                            }
                          }}
                          onFocus={() => {
                            if (isScreenLocked) {
                              if (parentRef.current) parentRef.current.focus();
                            }
                          }}
                        >
                          <Box sx={{ display: 'flex' }}>
                            <Box className="pr-md flex__ ">
                              <MediumTypography
                                labelid={'QuestionnaireClientInfo.FirstName'}
                                defaultlabel={'First Name:'}
                                sxProps={{
                                  color: '#97A6A5',
                                }}
                              />
                              <Box className="width100px">
                                <Tooltip
                                  title={clientsInfo.clientInfo?.firstName}
                                  arrow
                                >
                                  <Box>
                                    <MediumTypography
                                      fontSize="14px"
                                      className="pl-xs"
                                      textColor="#2A4241"
                                      sxProps={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                      }}
                                      label={
                                        clientsInfo.clientInfo?.firstName ===
                                          '' ||
                                        clientsInfo.clientInfo?.firstName ===
                                          null
                                          ? '-'
                                          : clientsInfo.clientInfo?.firstName
                                      }
                                    />
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                            <Box className="pr-md flex__">
                              <MediumTypography
                                labelid={'QuestionnaireClientInfo.LastName'}
                                defaultlabel={'Last Name:'}
                                sxProps={{
                                  color: '#97A6A5',
                                }}
                              />
                              <Box className="width100px">
                                <Tooltip
                                  title={clientsInfo.clientInfo?.lastName}
                                  arrow={true}
                                >
                                  <Box>
                                    <MediumTypography
                                      fontSize="14px"
                                      className="pl-xs"
                                      textColor="#2A4241"
                                      sxProps={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                      }}
                                      label={
                                        clientsInfo.clientInfo?.lastName ===
                                          '' ||
                                        clientsInfo.clientInfo?.lastName ===
                                          null
                                          ? '-'
                                          : clientsInfo.clientInfo?.lastName
                                      }
                                    />
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                            <Box className="pr-md flex__">
                              <MediumTypography
                                labelid={'QuestionnaireClientInfo.Sex'}
                                defaultlabel={'Sex:'}
                                sxProps={{
                                  color: '#97A6A5',
                                }}
                              />
                              <Box className="width100px">
                                <Tooltip
                                  title={convertGenderToFrontend(
                                    clientsInfo.clientInfo?.sex ?? '',
                                  )}
                                  arrow={true}
                                >
                                  <Box>
                                    <MediumTypography
                                      fontSize="14px"
                                      className="pl-xs"
                                      textColor="#2A4241"
                                      sxProps={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                      }}
                                      label={
                                        clientsInfo.clientInfo?.sex === '' ||
                                        clientsInfo.clientInfo?.sex === null
                                          ? '-'
                                          : convertGenderToFrontend(
                                              clientsInfo.clientInfo?.sex ?? '',
                                            )
                                      }
                                    />
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                            <Box className="pr-md flex__">
                              <MediumTypography
                                labelid={'QuestionnaireClientInfo.DPHID'}
                                defaultlabel={'DPH ID:'}
                                sxProps={{
                                  color: '#97A6A5',
                                }}
                              />
                              <Box className="width100px">
                                <Tooltip
                                  title={clientsInfo.clientInfo?.dphId}
                                  arrow={true}
                                >
                                  <Box>
                                    <MediumTypography
                                      fontSize="14px"
                                      className="pl-xs"
                                      textColor="#2A4241"
                                      sxProps={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                      }}
                                      label={
                                        clientsInfo.clientInfo?.dphId === '' ||
                                        clientsInfo.clientInfo?.dphId === null
                                          ? '-'
                                          : clientsInfo.clientInfo?.dphId
                                      }
                                    />
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                            <Box className="pr-md flex__">
                              <MediumTypography
                                labelid={'QuestionnaireClientInfo.DOB'}
                                defaultlabel={'Date of Birth:'}
                                sxProps={{
                                  color: '#97A6A5',
                                }}
                              />
                              <Box className="width100px">
                                <Tooltip
                                  title={clientsInfo.clientInfo?.dob}
                                  arrow={true}
                                >
                                  <Box>
                                    <MediumTypography
                                      fontSize="14px"
                                      className="pl-xs"
                                      textColor="#2A4241"
                                      sxProps={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                      }}
                                      label={
                                        clientsInfo.clientInfo?.dob === '' ||
                                        clientsInfo.clientInfo?.dob === null
                                          ? '-'
                                          : clientsInfo.clientInfo?.dob
                                      }
                                    />
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                        </div>
                      </Box>
                      {parentViewDetails.length > 0 && (
                        <Grid container>
                          <Grid item xs={12}>
                            <Box className="defaultHorizontalLine" />
                          </Grid>
                          <Grid direction={'row'} item>
                            <KeyValueComponent data={parentViewDetails} />
                          </Grid>
                          {secondaryParentViewDetails.length > 0 && (
                            <Grid direction={'row'} className="pt-sm" item>
                              <KeyValueComponent
                                data={secondaryParentViewDetails}
                              />
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Box>
                    <Grid item xs={12}>
                      <Box className="defaultHorizontalLine" />
                    </Grid>
                  </Box>

                  <Box component="div" className="pl-sm pr-sm">
                    <div
                      ref={parentRef}
                      style={{
                        pointerEvents: isScreenLocked ? 'none' : 'auto',
                        opacity: isScreenLocked ? '0.5' : '1 ',
                      }}
                      onKeyDownCapture={(e) => {
                        if (isScreenLocked) {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                      }}
                      onFocus={() => {
                        if (isScreenLocked) {
                          if (parentRef.current) parentRef.current.focus();
                        }
                      }}
                    >
                      <Grid
                        container
                        rowSpacing={'24px'}
                        columnSpacing={'40px'}
                      >
                        <Grid item xs={6} lg={4}>
                          <Box>
                            <DropdownComponentProps
                              labelid="QuestionnaireClientInfo.GuardianName"
                              defaultlabelid="Parent / Guardian Name"
                              names={parentList}
                              value={values.defaultParent}
                              handleChange={(value) => {
                                setFieldValue('defaultParent', value);
                                handleParentDetails(value);
                              }}
                              sxProps={{
                                whiteSpace: 'normal',
                                overflow: 'hidden',
                              }}
                              disabled={parentList.length === 0 ? true : false}
                            />
                            {errors.defaultParent && touched.defaultParent && (
                              <MediumTypography
                                labelid={errors.defaultParent}
                                defaultlabel="Parent / Guardian is Required"
                                className="errorText-md"
                              />
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={6} lg={8}></Grid>
                        {values.defaultParent === 'OTHER_PARENT_ID' && (
                          <>
                            <Grid
                              item
                              xs={6}
                              lg={4}
                              className="multisearchHgt pt-md"
                            >
                              <Textinput
                                labelid="ConsentForms.otherContactLabel"
                                defaultlabelid="Other Contact Name"
                                Required={true}
                                inputProps={{
                                  maxLength: 50,
                                }}
                                handlechange={(value: string) => {
                                  const inputValue = value;
                                  const validatedValue =
                                    nameValidation(inputValue);
                                  if (validatedValue !== undefined) {
                                    setFieldValue('otherName', validatedValue);
                                  }
                                }}
                                Value={values.otherName}
                              />
                              {errors.otherName && touched.otherName && (
                                <MediumTypography
                                  labelid={errors.otherName}
                                  defaultlabel="Other Contact is Required"
                                  className="errorText-md"
                                />
                              )}
                            </Grid>
                            <Grid item xs={6} lg={8}></Grid>
                          </>
                        )}
                        {values.defaultParent !== 'OTHER_PARENT_ID' && (
                          <Grid item xs={12} lg={12} className="pt-sm">
                            {clientsInfo.selectedParentInfo && (
                              <Grid direction={'row'} item>
                                <KeyValueComponent
                                  data={constructSelectedParentInfo(
                                    clientsInfo.selectedParentInfo,
                                  )}
                                />
                              </Grid>
                            )}
                          </Grid>
                        )}
                        <Grid item xs={12} lg={8} className="pt-sm">
                          <CommentTextbox
                            Value={values.parentDescription}
                            placeholder="Description"
                            name="parentDescription"
                            maxLength={500}
                            handlechange={(e) => {
                              setFieldValue('parentDescription', e);
                            }}
                          />
                          {errors.parentDescription &&
                            touched.parentDescription && (
                              <MediumTypography
                                labelid={errors.parentDescription}
                                defaultlabel="Description is Required"
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                      </Grid>
                    </div>
                    <Grid item xs={12}>
                      <Box className="defaultHorizontalLine" />
                    </Grid>
                  </Box>

                  <Box component="div" className="pl-sm pr-sm">
                    <div
                      ref={parentRef}
                      style={{
                        pointerEvents: isScreenLocked ? 'none' : 'auto',
                        opacity: isScreenLocked ? '0.5' : '1 ',
                      }}
                      onKeyDownCapture={(e) => {
                        if (isScreenLocked) {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                      }}
                      onFocus={() => {
                        if (isScreenLocked) {
                          if (parentRef.current) parentRef.current.focus();
                        }
                      }}
                    >
                      <Grid
                        container
                        rowSpacing={'24px'}
                        columnSpacing={'40px'}
                      >
                        <Grid item xs={12} lg={12}>
                          <SmallTypography
                            labelId="QuestionnaireClientInfo.DateAge"
                            defaultLabelId="If premature, adjusted age months and days"
                            sxProps={{
                              fontSize: 14,
                              color: '#97A6A5',
                            }}
                          />
                        </Grid>

                        <Grid
                          container
                          item
                          direction={'row'}
                          className="pt-sm"
                        >
                          <Grid item xs={1.5} lg={1.5} className="pr-sm">
                            <Textinput
                              name="prematureMonths"
                              labelid="prematureMonths"
                              defaultlabelid="Months"
                              Value={values.prematureMonths}
                              inputProps={{
                                maxLength: 2,
                              }}
                              sxProps={{
                                backgroundColor: 'white',
                              }}
                              handlechange={(text) => {
                                const filteredValue = text.replace(
                                  /[^0-9]/g,
                                  '',
                                );
                                setFieldValue('prematureMonths', filteredValue);
                              }}
                            />
                          </Grid>
                          <Grid item xs={1.5} lg={1.5}>
                            <Textinput
                              name="prematureDays"
                              labelid="prematureDays"
                              defaultlabelid="Days"
                              Value={values.prematureDays}
                              inputProps={{
                                maxLength: 2,
                              }}
                              sxProps={{
                                backgroundColor: 'white',
                              }}
                              handlechange={(text) => {
                                const filteredValue = text.replace(
                                  /[^0-9]/g,
                                  '',
                                );
                                setFieldValue('prematureDays', filteredValue);
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12} lg={12}>
                          <SmallTypography
                            labelId="QuestionnaireClientInfo.SubHeaderOne"
                            defaultLabelId="If baby was born 3 more weeks pre-mature, please enter the number of weeks:"
                            sxProps={{
                              fontSize: 14,
                              color: '#97A6A5',
                            }}
                          />
                          <Grid item xs={2} lg={2}>
                            <Textinput
                              name="preMatureAge"
                              labelid=""
                              defaultlabelid=""
                              Value={values.prematureWeeks}
                              inputProps={{
                                maxLength: 2,
                              }}
                              sxProps={{
                                backgroundColor: 'white',
                              }}
                              handlechange={(text) => {
                                const filteredValue = text.replace(
                                  /[^0-9]/g,
                                  '',
                                );
                                setFieldValue('prematureWeeks', filteredValue);
                              }}
                            />
                            {errors.prematureWeeks &&
                              touched.prematureWeeks && (
                                <MediumTypography
                                  labelid={errors.prematureWeeks}
                                  className="errorText-md"
                                />
                              )}
                          </Grid>
                        </Grid>

                        <Grid item xs={12} lg={12}>
                          <Box className="pr-md flex__">
                            <MediumTypography
                              labelid={
                                'QuestionnaireClientInfo.AgeAtAdministration'
                              }
                              defaultlabel={
                                'Age at administration in months and days:'
                              }
                              sxProps={{
                                color: '#97A6A5',
                              }}
                            />
                            <Box className="width100px">
                              <Tooltip
                                title={clientsInfo.ageAtAdministration}
                                arrow
                                followCursor
                                placement="bottom"
                              >
                                <Box>
                                  <MediumTypography
                                    fontSize="14px"
                                    className="pl-xs"
                                    textColor="#2A4241"
                                    sxProps={{
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                    }}
                                    label={
                                      clientsInfo.ageAtAdministration === '' ||
                                      clientsInfo.ageAtAdministration === null
                                        ? '-'
                                        : clientsInfo.ageAtAdministration
                                    }
                                  />
                                </Box>
                              </Tooltip>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={6} lg={4} className="mb-xlg">
                          <DatePickerComponent
                            className="bg__white"
                            value={dayjs(values.asqCompletion) as Dayjs}
                            disableFuture={false}
                            labelid="QuestionnaireClientInfo.asqCompletion"
                            defaultlabelid="Date of ASQ: SE2 Completion"
                            handlechange={(date: Dayjs | null) => {
                              const formattedDate =
                                dayjs(date).format('MM/DD/YYYY');
                              if (date === null) {
                                setFieldValue('asqCompletion', null);
                              } else {
                                setFieldValue('asqCompletion', formattedDate);
                              }
                            }}
                          />
                          {errors.asqCompletion && touched.asqCompletion && (
                            <MediumTypography
                              labelid={errors.asqCompletion}
                              className="errorText-md"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                </Box>
              </Card>

              <Box className="mt-md mb-xlg">
                <Grid
                  container
                  direction="row"
                  alignItems="right"
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Grid item sx={{ marginRight: '16px' }}>
                    <ButtonComponent
                      className="btn-primary btn-cancel"
                      variantType="outlined"
                      labelId="cancelText"
                      defaultLabelId="Cancel"
                      onClick={() => {
                        if (_.isEqual(initialValues, values)) {
                          navigate(QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME, {
                            state: {
                              data: location.state.data,
                              pathname: QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME,
                            },
                          });
                        } else {
                          setOpenModal(true);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item>
                    {checkPermissionForFeature(
                      'backend.clients',
                      'editPermission',
                    ) ? (
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        labelId={
                          location.state.pathname === 'Add'
                            ? 'QuestionnaireClientInfo.Start'
                            : 'QuestionnaireClientInfo.Next'
                        }
                        defaultLabelId={
                          location.state.pathname === 'Add' ? 'Start' : 'Next'
                        }
                        onClick={handleSubmit}
                      />
                    ) : (
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        labelId="QuestionnaireClientInfo.Next"
                        defaultLabelId="Next"
                        onClick={() => {
                          if (!errors.defaultParent) {
                            handleSubmit();
                          } else {
                            setOpen(true);
                            setToastrVariable('error');
                            setToastrId(
                              'ASQQuestionnaireClientInfo.notStarted',
                            );
                            setToastrDefaultMessage('Not Started');
                          }
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default QuestionnaireClientInfo;
