import { Box, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import TitleText from '../../components/formlib/TitleText';
import { ReactComponent as AcceptIcon } from '../../assets/images/acceptIcon.svg';
import { ReactComponent as RejectIcon } from '../../assets/images/rejectIcon.svg';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as StatusIcon } from '../../assets/images/statusIcon.svg';
import {
  ActivitySheet,
  ActivitySubmitRequest,
  getApprovalActivities,
  submitApprovedActivitySheet,
} from '../../services/configApi/activitySheet/weeklyActivitySheetProvider';
import ActivityListTableView from './ActivityListTableView';
import { formatDate } from '../../utils/dateUtil';
import TextWithIcon from '../../components/formlib/TextWithIcon';
import { ResponseType } from '../../utils/type';
import { useLocation, useNavigate } from 'react-router-dom';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { StatusType } from './ActivityTableRow';
import { ACTIVITY_APPROVAL_OVERVIEW_SHEET_ROUTE_NAME } from '../../routes/Routing';
import { useIntl } from 'react-intl';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import WeekDatePicker from '../../components/formlib/modal/WeekDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import ProductivityChart from './ProductivityChart';
import ActivitySheetChart from './ActivitySheetChart';
import { getStatusColor } from '../../utils/utilities';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import HolidayListDialog from '../holidays/HolidayListDialog';
import { Context } from '../../LanguageWrapper';
import {
  Holiday,
  getHolidayList,
} from '../../services/configApi/employees/holidayServices';

const ApprovalActivityScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const rctl = useIntl();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [activityDetails, setActivityDetails] = useState<ActivitySheet>();
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [reload, setReload] = useState<boolean>(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>('');
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');
  const [toastrId, setToastrId] = useState<string>();
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const context = useContext(Context);
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const [showHolidays, setShowHolidays] = useState<boolean>(false);

  const getActvitySheet = (newDate: Date) => {
    if (selectedEmployeeId === '') {
      return;
    }
    toggleLoader(true);
    getApprovalActivities(formatDate(newDate, 'MM/DD/YYYY'), selectedEmployeeId)
      .then((response0: ActivitySheet) => {
        response0.activities.forEach((activity) => {
          activity.appointments?.forEach((appt) => {
            appt.attendeeId = selectedEmployeeId;
          });
        });
        //TODO update this logic based on the requirement
        const filteredActivities = response0.activities.filter(
          (activity) => activity.archived === false,
        );
        response0.activities = filteredActivities;
        return response0;
      })
      .then((response: ActivitySheet) => {
        return JSON.parse(JSON.stringify(formatAndReadResponse(response)));
      })
      .then((response: ActivitySheet) => {
        response.activities.forEach((activity) => {
          if (activity.status === 'Approved') {
            activity.actionRequired = 'NO';
          } else {
            activity.actionRequired = 'NONE';
          }
        });
        setReload(false);
        setActivityDetails(response);
        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        }
      });
  };

  const formatAndReadResponse = (response: ActivitySheet) => {
    let rowId = 0;
    const newResponse: ActivitySheet = {
      ...response,
      activities: response.activities.map(
        (activity, index, arrayActivities) => {
          if (index === 0) {
            activity.rowId = rowId;
          } else {
            const previousActivity = arrayActivities[index - 1];
            if (previousActivity.day !== activity.day) {
              rowId = rowId === 0 ? 1 : 0;
            }
            activity.rowId = rowId;
          }
          return activity;
        },
      ),
    };
    newResponse.activities.forEach((activity) => {
      if (activity.appointments !== null && activity.appointments?.length > 0) {
        const lastIndex = activity.appointments.length - 1;
        activity.appointments.forEach((appt, index) => {
          appt.isLastIndex = index === lastIndex;
          return appt;
        });
      }
      return activity;
    });
    return newResponse;
  };

  const getEmployeeType = () => {
    if (activityDetails && activityDetails.employeeType !== null) {
      return activityDetails.employeeType;
    }
    return '';
  };

  const getClientName = () => {
    const navParams = location.state;
    if (navParams !== null) {
      return `${navParams.firstName} ${navParams.lastName}`;
    }
    return '';
  };

  const approveAllActivities = () => {
    if (activityDetails && activityDetails.activities.length > 0) {
      const activities = activityDetails.activities.map((activity) => {
        if (
          activity.actionRequired === 'NONE' ||
          activity.actionRequired === 'YES'
        ) {
          activity.status = 'Approved';
          activity.actionRequired = 'YES';
        }
        return activity;
      });
      setActivityDetails({ ...activityDetails, activities: activities });
    }
  };

  const rejectAllActivities = () => {
    if (activityDetails && activityDetails.activities.length > 0) {
      const activities = activityDetails.activities.map((activity) => {
        if (
          activity.actionRequired === 'NONE' ||
          activity.actionRequired === 'YES'
        ) {
          activity.status = 'Rejected';
          activity.actionRequired = 'YES';
        }
        return activity;
      });
      setActivityDetails({ ...activityDetails, activities: activities });
    }
  };

  const updateActivities = (
    activitySheetId: string | null,
    newStatus: StatusType,
  ) => {
    if (activityDetails && activityDetails.activities.length > 0) {
      const activities = activityDetails.activities.map((activity) => {
        if (activity.actionRequired !== 'NO') {
          if (activitySheetId !== null) {
            if (activitySheetId === activity.activitySheetId) {
              activity.status = newStatus;
              if (newStatus === 'Submitted') {
                activity.actionRequired = 'NONE';
              } else {
                activity.actionRequired = 'YES';
              }
            }
          } else {
            activity.status = newStatus;
            activity.actionRequired = 'NONE';
          }
        }
        return activity;
      });
      setActivityDetails({ ...activityDetails, activities: activities });
    }
  };

  const readHolidayList = () => {
    toggleLoader(true);
    const program = context.selectedProgram;
    if (program === undefined || selectedDate === undefined) {
      return;
    }

    getHolidayList(formatDate(selectedDate, 'MM/DD/YYYY'), program.id)
      .then((response) => {
        toggleLoader(false);
        setShowHolidays(true);
        setHolidays(response.holidayList);
      })
      .catch(() => {
        toggleLoader(false);
      });
  };

  const submitApprovedActivities = () => {
    if (activityDetails && activityDetails.activities.length > 0) {
      const submitRequest: ActivitySubmitRequest = {
        employeeId: selectedEmployeeId,
        activities: [],
        startDate: '',
        endDate: '',
      };

      let hasPending = false;

      activityDetails.activities.forEach((activity) => {
        if (activity.actionRequired === 'NONE') {
          hasPending = true;
        }
        const subIds: string[] = [];
        activity.appointments?.forEach((appt) => {
          if (
            (appt.archivalStatus === null ||
              appt.archivalStatus === 'Pending') &&
            !appt.archived &&
            activity.actionRequired === 'YES'
          ) {
            subIds.push(appt.progressNotesId);
          }
        });
        submitRequest.activities.push({
          progressNotesIds: subIds,
          activitySheetId: activity.activitySheetId,
          status: activity.status,
        });
      });

      if (hasPending) {
        return;
      }

      if (submitRequest.activities.length === 0) {
        return;
      }
      toggleLoader(true);
      submitApprovedActivitySheet(submitRequest)
        .then(() => {
          setSuccessOrError('success');
          setToastrId('activityApprovalSuccessMessage');
          setToastrDefaultMessage('Activity sheet reviewed successfully');
          toggleLoader(false);
          navigate(ACTIVITY_APPROVAL_OVERVIEW_SHEET_ROUTE_NAME);
        })
        .catch((error) => {
          toggleLoader(false);
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('activityApprovalFailureMessage');
            setToastrDefaultMessage('Failed to submit reviewed activity sheet');
            setSuccessOrError('error');
          }
        });
    }
  };

  useEffect(() => {
    const navParams = location.state;
    if (navParams !== null) {
      setReload(true);
      setSelectedDate(navParams.date as Date);
      setSelectedEmployeeId(navParams.id);
    }
  }, []);

  useEffect(() => {
    if (reload && selectedDate) {
      getActvitySheet(selectedDate);
    }
  }, [reload, selectedDate]);

  return (
    <>
      <Box
        component="main"
        sx={{
          width: '100%',
          backgroundColor: '#E7F0F0',
          padding: '16px',
        }}
      >
        {toastrId && (
          <SnackBarComponent
            open={toastrId !== undefined}
            handleClose={() => {
              setToastrId(undefined);
            }}
            labelId={toastrId}
            defaultMessageId={toastrDefaultMessage}
            successOrError={successOrError}
          />
        )}
        {showHolidays && (
          <HolidayListDialog
            open={showHolidays}
            holidays={holidays}
            onClose={() => setShowHolidays(false)}
          />
        )}
        <Box>
          <Box
            sx={{
              padding: '16px 0px',
              display: 'flex',
              flexDirection: 'row',
            }}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TitleText label={getClientName()} />
              {activityDetails && (
                <Box
                  sx={{
                    display: 'flex',
                    marginLeft: '16px',
                    alignItems: 'self-end',
                  }}
                  justifyContent={'center'}
                >
                  <Box>
                    <MediumTypography
                      labelid={`${rctl.formatMessage({
                        id: 'statusText',
                        defaultMessage: 'Status',
                      })}:`}
                      sxProps={{ color: '#97A6A5' }}
                    />
                  </Box>
                  <Box className="activityStatusmt" sx={{ padding: '0px 5px' }}>
                    <StatusIcon fill={getStatusColor(activityDetails.status)} />
                  </Box>
                  <Box>
                    <MediumTypography
                      label={
                        activityDetails.status !== null
                          ? activityDetails.status
                          : ''
                      }
                      sxProps={{
                        color: getStatusColor(activityDetails.status),
                        paddingLeft: '4px',
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          <Grid container alignItems={'center'}>
            <Grid item>
              <Box
                sx={{ display: 'flex', marginRight: '16px' }}
                alignItems={'center'}
              >
                {selectedDate && (
                  <WeekDatePicker
                    date={dayjs(selectedDate)}
                    disableFutureDate={true}
                    onDateSelect={(newDate: Dayjs) => {
                      setSelectedDate(newDate.toDate());
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={4} display={'flex'}>
              <Box sx={{ display: 'flex' }}>
                <MediumTypography
                  labelid={`${rctl.formatMessage({
                    id: 'employeeTypeText',
                    defaultMessage: 'Employee Type',
                  })} : `}
                  defaultlabel="Employee Type"
                  sxProps={{ color: '#97A6A5' }}
                />
                <MediumTypography
                  label={getEmployeeType()}
                  defaultlabel="Hourly"
                  sxProps={{ color: '#008C82', marginLeft: '8px' }}
                />
              </Box>
            </Grid>
            <Grid item sx={{ flex: 1, display: 'flex' }} justifyContent={'end'}>
              <Box
                sx={{
                  alignSelf: 'center',
                  marginLeft: 'auto',
                }}
                onClick={() => {
                  if (holidays.length === 0) {
                    readHolidayList();
                  } else {
                    setShowHolidays(true);
                  }
                }}
              >
                <MediumTypography
                  sxProps={{ color: '#008C82', cursor: 'pointer' }}
                  labelid={`${rctl.formatMessage({
                    id: 'holidayListForText',
                  })} ${dayjs().format('YYYY')}`}
                  defaultlabel="Holiday"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        {activityDetails && (
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '16px',
              }}
            >
              <ActivitySheetChart
                activity={activityDetails}
                reload={reload}
                cardStyle={{
                  width:
                    activityDetails.employeeType === 'Salaried Exempt'
                      ? '65%'
                      : '100%',
                  marginRight: '8px',
                }}
              />

              {activityDetails.employeeType === 'Salaried Exempt' && (
                <ProductivityChart
                  productivity={activityDetails.productivity}
                  cardStyle={{ width: '35%' }}
                  barHeight="100px"
                />
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '8px',
                marginTop: '16px',
              }}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <MediumTypography
                sxProps={{
                  fontWeight: 600,
                }}
                labelid="activitySheetRememberNoteText"
                defaultlabel="activitySheetRememberNoteText"
              />
              {checkPermissionForFeature(
                'backend.review_activity_sheet',
                'editPermission',
              ) && (
                <Box sx={{ display: 'flex' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      borderRadius: '4px',
                      border: '1px solid #008C82',
                      width: '250px',
                    }}
                    justifyContent={'space-around'}
                    alignItems={'center'}
                  >
                    <TextWithIcon
                      LeftIcon={<AcceptIcon />}
                      labelId="acceptText"
                      defaultLabel="Approve All"
                      sxProps={{
                        cursor: 'pointer',
                        textTransform: 'none',
                        color: '#2A4241',
                      }}
                      onClick={() => {
                        approveAllActivities();
                      }}
                    />
                    <MediumTypography label="|" />
                    <TextWithIcon
                      LeftIcon={<RejectIcon />}
                      labelId="rejectText"
                      defaultLabel="Reject All"
                      sxProps={{
                        cursor: 'pointer',
                        textTransform: 'none',
                        color: '#2A4241',
                      }}
                      onClick={() => {
                        rejectAllActivities();
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>

            <ActivityListTableView
              selectedDate={selectedDate}
              employeeType={activityDetails.employeeType}
              activities={activityDetails ? activityDetails.activities : []}
              setReload={setReload}
              reload={reload}
              isEmployee={false}
              updateActivities={(activitySheetId, newStatus) =>
                updateActivities(activitySheetId, newStatus)
              }
              submitApprovedActivities={submitApprovedActivities}
              screenName="ApprovalActivity"
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ApprovalActivityScreen;
