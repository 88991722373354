import { LoginBody } from '../../pages/SignInComponent';
import { LoginStateType } from '../../utils/type';
import { ApiResponseHandler } from '../ApiResponseHandler';
import UserApiClient from '../UserApiClient';

export const loginUser = async (params: LoginBody): Promise<LoginStateType> => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().post<LoginBody, LoginStateType>(
      `login`,
      params,
    ),
  );
};

export const signOutUser = async (refreshToken: string) => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().post('signout', {
      refreshToken: refreshToken,
    }),
  );
};
