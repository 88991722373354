import React, { useContext, useEffect, useRef, useState } from 'react';
import { EICSRadioData, RoutingProps } from './IntakeClientsInfo';
import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableContainer,
  autocompleteClasses,
} from '@mui/material';
import KeyValueComponent, {
  DataJsonType,
} from '../../components/forms/KeyValueComponent';
import {
  InatkeParentType,
  IntakeFamilyHistoryInfoResponseType,
  getIntakeFamilyHistoryInfoDetails,
  putIntakeFamilyHistoryInfo,
} from '../../services/configApi/forms/Intake/IntakeServices';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import MediumTypography from '../../components/formlib/MediumTypography';
import { OptionType, ResponseType } from '../../utils/type';
import Textinput from '../../components/formlib/Textinput';
import QualificationJsonData from './IntakeJSON/FamilyHistory/Qualification.json';
import JobTypeJsonData from './IntakeJSON/FamilyHistory/JobType.json';
import ParentStatusJsonData from './IntakeJSON/FamilyHistory/ParentMaritalStatus.json';
import FamilyHealthIssueData from './IntakeJSON/FamilyHistory/FamilyHealthIssue.json';
import PovertyJsonData from './IntakeJSON/FamilyHistory/Poverty.json';
import siblingTypeRadioData from './IntakeJSON/FamilyHistory/SiblingType.json';
import CustomCheckBox from '../../components/formlib/CustomCheckBox';
import CommentTextbox from '../../components/formlib/CommentTextbox';
import { formatPhoneNumber } from '../../utils/formValidationUtil';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
import { IntakeRadioData } from './IntakePregnancyInfo';
import { ReactComponent as DownArrow } from '../../assets/images/downarrowcolored.svg';
import { ReactComponent as UpArrow } from '../../assets/images/uparrowcolored.svg';
import { ReactComponent as AddNew } from '../../assets/images/Plus.svg';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import {
  SiblingTypeResponse,
  getAllContactSiblingsList,
} from '../../services/configApi/User';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import IntakeSiblingsTableRow from './IntakeSiblingsTableRow';
import ModalPopup from '../../components/formlib/ModalPopup';
import MultipleAutocompleteSearch from '../../components/formlib/MultipleAutocompleteSearch';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';
import {
  DownloadContext,
  DownloadContextType,
  showCancelToaster,
} from './IntakeTabNavigationComponent';
import { checkPermissionForFeature } from '../../utils/checkPermission';

interface FosterParentSubType {
  id: string;
  labelid: string;
  defaultLabel: string;
  value: string;
}

interface FosterParentType {
  [x: number]: FosterParentSubType[];
}

export interface Data {
  name: string;
  dateOfBirth: string;
  school: string;
  grade: string;
  siblingType: string;
  actions: string;
}

const headCells: HeadCell<Data>[] = [
  {
    id: 'name',
    labelId: 'IntakeFamilyHistory.name',
    defaultLabelId: 'Name',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'dateOfBirth',
    labelId: 'IntakeFamilyHistory.dateOfBirth',
    defaultLabelId: 'Date of Birth',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'school',
    labelId: 'IntakeFamilyHistory.school',
    defaultLabelId: 'School',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'grade',
    labelId: 'IntakeFamilyHistory.grade',
    defaultLabelId: 'Grade',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'siblingType',
    labelId: 'IntakeFamilyHistory.siblingTypeHeader',
    defaultLabelId: 'Sibling Type',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: 'Actions',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
];

export interface IntakeFamilyHistoryInfoType {
  formHandleFlag: boolean;
  parent: DataJsonType[];
  race: string;
  ethnicity: string;
  education: string;
  educationYears: string;
  schoolCompleted: string;
  qualificationNames: string[];
  occupation: string;
  employeedBy: string;
  employerAddress: string;
  workPhone: string;
  jobType: string;
  jobTypeOther: string;
  primarySpokenLanguage: string;
  primarySpokenLanguageOther: string;
  primaryWrittenLanguage: string;
  primaryWrittenLanguageOther: string;
  parentsMaritalStatus: string;
  kinshipCare: string;
  fosterParents: FosterParentType[];
  withWhomChildLive: string;
  siblingNameId: string;
  school: string;
  grade: string;
  siblingType: string;
  siblingsInfo: SiblingTypeResponse[];
  pets: string;
  smoking: string;
  unsecuredWeapons: string;
  extendedFamily: string;
  compromisedImmuneSystem: string;
  familyHealthIssueNames: string[];
  familyMemberHistoryOther: string;
  parentalChronicIllness: string;
  affectingChild: string;
  siblingWithSEN: string;
  mothersEducationYear: string;
  mothersEducationYearCheck: boolean;
  povertyLevel: string;
  lackingAdequate: string;
  protectiveServiceInvestigation: string;
  homelessness: string;
  homelessnessInPast: string;
  birthMotherParity: string;
  abuseAffectingChild: string;
  socialSupport: string;
}

const IntakeFamilyHistoryInitialValues = {
  formHandleFlag: false,
  parent: [
    {
      id: 'ProgressNote.Name',
      labelid: 'ProgressNote.Name',
      defaultLabel: 'Name:',
      value: '-',
    },
    {
      id: 'Facesheet.relationship',
      labelid: 'Facesheet.relationship',
      defaultLabel: 'Relationship:',
      value: '-',
    },
    {
      id: 'ConsentForms.programPhone',
      labelid: 'ConsentForms.programPhone',
      defaultLabel: 'Phone:',
      value: '-',
    },
    {
      id: 'ConsentForms.clientAddress',
      labelid: 'ConsentForms.clientAddress',
      defaultLabel: 'Address:',
      value: '-',
    },
  ],
  race: '',
  ethnicity: '',
  education: '',
  educationYears: '',
  schoolCompleted: '',
  qualificationNames: [],
  occupation: '',
  employeedBy: '',
  employerAddress: '',
  workPhone: '',
  jobType: '',
  jobTypeOther: '',
  primarySpokenLanguage: '',
  primarySpokenLanguageOther: '',
  primaryWrittenLanguage: '',
  primaryWrittenLanguageOther: '',
  parentsMaritalStatus: '',
  kinshipCare: '',
  fosterParents: [
    {
      0: [
        {
          id: 'ProgressNote.Name',
          labelid: 'ProgressNote.Name',
          defaultLabel: 'Name:',
          value: '-',
        },
        {
          id: 'Facesheet.relationship',
          labelid: 'Facesheet.relationship',
          defaultLabel: 'Relationship:',
          value: '-',
        },
        {
          id: 'ConsentForms.programPhone',
          labelid: 'ConsentForms.programPhone',
          defaultLabel: 'Phone:',
          value: '-',
        },
        {
          id: 'ConsentForms.clientAddress',
          labelid: 'ConsentForms.clientAddress',
          defaultLabel: 'Address:',
          value: '-',
        },
      ],
    },
  ],
  withWhomChildLive: '',
  siblingNameId: '',
  school: '',
  grade: '',
  siblingType: '',
  siblingsInfo: [],
  pets: '',
  smoking: '',
  unsecuredWeapons: '',
  extendedFamily: '',
  compromisedImmuneSystem: '',
  familyHealthIssueNames: [],
  familyMemberHistoryOther: '',
  parentalChronicIllness: '',
  affectingChild: '',
  siblingWithSEN: '',
  mothersEducationYear: '',
  mothersEducationYearCheck: false,
  povertyLevel: '',
  lackingAdequate: '',
  protectiveServiceInvestigation: '',
  homelessness: '',
  homelessnessInPast: '',
  birthMotherParity: '',
  abuseAffectingChild: '',
  socialSupport: '',
};

const validationSchema = yup.object().shape({
  formHandleFlag: yup.boolean(),
  race: yup.string().nullable(),
  ethnicity: yup.string().nullable(),
  education: yup.string().nullable(),
  educationYears: yup.string().nullable(),
  schoolCompleted: yup.string(),
  occupation: yup.string(),
  employeedBy: yup.string(),
  employerAddress: yup.string(),
  workPhone: yup.string(),
  jobType: yup.string(),
  jobTypeOther: yup.string(),
  primarySpokenLanguage: yup.string().nullable(),
  primarySpokenLanguageOther: yup.string().nullable(),
  primaryWrittenLanguage: yup.string().nullable(),
  primaryWrittenLanguageOther: yup.string().nullable(),
  parentsMaritalStatus: yup.string(),
  kinshipCare: yup.string(),
  withWhomChildLive: yup.string(),
  siblingNameId: yup.string().nullable(),
  school: yup.string(),
  grade: yup.string(),
  siblingType: yup.string().nullable(),
  pets: yup.string().nullable(),
  smoking: yup.string().nullable(),
  unsecuredWeapons: yup.string().nullable(),
  extendedFamily: yup.string(),
  compromisedImmuneSystem: yup.string(),
  familyMemberHistoryOther: yup.string(),
  parentalChronicIllness: yup.string().nullable(),
  affectingChild: yup.string().nullable(),
  siblingWithSEN: yup.string().nullable(),
  mothersEducationYear: yup.string().nullable(),
  mothersEducationYearCheck: yup.boolean().nullable(),
  povertyLevel: yup.string().nullable(),
  lackingAdequate: yup.string().nullable(),
  protectiveServiceInvestigation: yup.string().nullable(),
  homelessness: yup.string().nullable(),
  homelessnessInPast: yup.string().nullable(),
  birthMotherParity: yup.string().nullable(),
  abuseAffectingChild: yup.string().nullable(),
  socialSupport: yup.string().nullable(),
});

export interface IntakeFamilyHistoryFormikType {
  race: string;
  ethnicity: string;
  education: string;
  educationYears: string;
  schoolCompleted: string;
  occupation: string;
  employeedBy: string;
  employerAddress: string;
  workPhone: string;
  jobType: string;
  jobTypeOther: string;
  primarySpokenLanguage: string;
  primarySpokenLanguageOther: string;
  primaryWrittenLanguage: string;
  primaryWrittenLanguageOther: string;
  parentsMaritalStatus: string;
  kinshipCare: string;
  withWhomChildLive: string;
  siblingNameId: string;
  school: string;
  grade: string;
  siblingType: string;
  pets: string;
  smoking: string;
  unsecuredWeapons: string;
  extendedFamily: string;
  compromisedImmuneSystem: string;
  familyMemberHistoryOther: string;
  parentalChronicIllness: string;
  affectingChild: string;
  siblingWithSEN: string;
  mothersEducationYear: string;
  mothersEducationYearCheck: boolean;
  povertyLevel: string;
  lackingAdequate: string;
  protectiveServiceInvestigation: string;
  homelessness: string;
  homelessnessInPast: string;
  birthMotherParity: string;
  abuseAffectingChild: string;
  socialSupport: string;
}

const IntakeFamilyHistoryInfo: React.FC<RoutingProps> = () => {
  const [intakeFamilyHistoryInfo, setIntakeFamilyHistoryInfo] =
    useState<IntakeFamilyHistoryInfoType>(IntakeFamilyHistoryInitialValues);

  const formikRef = useRef<FormikProps<IntakeFamilyHistoryFormikType>>(null);
  const clientId = localStorage.getItem('ClientId');

  const [raceValues, setRaceValues] = useState<OptionType[]>([]);
  const [ethnicityValues, setEthnicityValues] = useState<OptionType[]>([]);
  const [languageValues, setLanguageValues] = useState<OptionType[]>([]);
  const [fosterParentValues, setFosterParentValues] = useState<OptionType[]>(
    [],
  );
  const [siblingsList, setSiblingsList] = useState<OptionType[]>([]);

  const [siblingsDetailsList, setSiblingsDetailsList] = useState<
    SiblingTypeResponse[]
  >([]);

  const [qualificationData, setQualificationData] = useState(
    QualificationJsonData,
  );

  const [qualificationNames, setQualificationNames] = useState<string[]>([]);

  const [familyHealthIssueData, setFamilyHealthIssueData] = useState(
    FamilyHealthIssueData,
  );

  const [familyHealthIssueNames, setFamilyHealthIssueNames] = useState<
    string[]
  >([]);

  const [openEICSSection, setOpenEICSSection] = useState(true);
  const [openSiblingsInfoSection, setOpenSiblingsInfoSection] = useState(true);

  const [intakeSiblingsTable, setIntakeSiblingsTable] = useState<
    SiblingTypeResponse[]
  >([]);

  const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);

  const [deleteWaring, setDeleteWarning] = useState<boolean>(false);
  const [deleteIndex, setDeleteIndex] = useState<number>(0);

  const [editRecordIndex, setEditRecordIndex] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const [fosterParentDetails, setFosterParentDetails] = useState<
    InatkeParentType[] | []
  >([]);

  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;

  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = useState<string>();
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');
  const [viewToBeLocked, setViewToBeLocked] = useState<boolean>(false);
  const parentRef = React.useRef<HTMLDivElement>(null);
  const { lookups } = useContext(LookUpContext) as LookUpContextType;

  const [
    intakeFamilyHistoryInfoSavedValues,
    setIntakeFamilyHistoryInfoSavedValues,
  ] = useState<IntakeFamilyHistoryInfoResponseType>();

  useEffect(() => {
    if (lookups === null) {
      return;
    }
    setRaceValues(() => {
      return lookups.race.map((r) => {
        return {
          label: r.description,
          id: r.code,
        };
      });
    });
    setEthnicityValues(() => {
      return lookups.ethnicity.map((e) => {
        return {
          label: e.description,
          id: e.code,
        };
      });
    });
    setLanguageValues(() => {
      return lookups.language.map((lang) => {
        return {
          label: lang.description,
          id: lang.code,
        };
      });
    });
    getSiblingsData();
    handleIntakeGetAPI();
  }, [lookups]);

  useEffect(() => {
    const intakeSiblingsMembers: SiblingTypeResponse[] = [];

    intakeFamilyHistoryInfo.siblingsInfo.forEach((other) => {
      intakeSiblingsMembers.push(other);
    });

    setIntakeSiblingsTable(intakeSiblingsMembers);
  }, [intakeFamilyHistoryInfo.siblingsInfo]);

  const { toggleDownload } = React.useContext(
    DownloadContext,
  ) as DownloadContextType;

  const handleIntakeGetAPI = async () => {
    if (clientId) {
      window.scrollTo(0, 0);
      toggleLoader(true);
      getIntakeFamilyHistoryInfoDetails(clientId)
        .then((response: IntakeFamilyHistoryInfoResponseType) => {
          if (response.readyToDownload) {
            toggleDownload(response.readyToDownload);
          }

          if (response.isSigned) {
            setViewToBeLocked(true);
          } else {
            if (response.isCurrentlyHeld) {
              setViewToBeLocked(true);
              showCancelToaster(response.heldBy);
            } else {
              // unlock the page
              if (
                checkPermissionForFeature('backend.clients', 'editPermission')
              ) {
                setViewToBeLocked(false);
              } else {
                setViewToBeLocked(true);
              }
            }
          }
          setFosterParentDetails(response.fosterParents);
          const transformedData = response.fosterParents.map(
            (parent, index) => ({
              [index]: [
                {
                  id: 'ProgressNote.Name',
                  labelid: 'ProgressNote.Name',
                  defaultLabel: 'Name:',
                  value: parent.name ? parent.name : '-',
                },
                {
                  id: 'Facesheet.relationship',
                  labelid: 'Facesheet.relationship',
                  defaultLabel: 'Relationship:',
                  value: parent.relationshipToClient
                    ? parent.relationshipToClient
                    : '-',
                },
                {
                  id: 'ConsentForms.programPhone',
                  labelid: 'ConsentForms.programPhone',
                  defaultLabel: 'Phone:',
                  value: parent.phoneNumber ? parent.phoneNumber : '-',
                },
                {
                  id: 'ConsentForms.clientAddress',
                  labelid: 'ConsentForms.clientAddress',
                  defaultLabel: 'Address:',
                  value: parent.address ? parent.address : '-',
                },
              ],
            }),
          );

          if (response.fosterParents && response.fosterParents.length > 0) {
            const fosterParentValuesList: OptionType[] = [];
            response.fosterParents.forEach((Details) => {
              fosterParentValuesList.push({
                id: Details.id,
                label: Details.name,
              });
            });
            setFosterParentValues(fosterParentValuesList);
          }

          setIntakeFamilyHistoryInfoSavedValues(response);

          setIntakeFamilyHistoryInfo({
            ...intakeFamilyHistoryInfo,
            parent: [
              {
                id: 'ProgressNote.Name',
                labelid: 'ProgressNote.Name',
                defaultLabel: 'Name:',
                value: response.eics.parent.name
                  ? response.eics.parent.name
                  : '-',
              },
              {
                id: 'Facesheet.relationship',
                labelid: 'Facesheet.relationship',
                defaultLabel: 'Relationship:',
                value: response.eics.parent.relationshipToClient
                  ? response.eics.parent.relationshipToClient
                  : '-',
              },
              {
                id: 'ConsentForms.programPhone',
                labelid: 'ConsentForms.programPhone',
                defaultLabel: 'Phone:',
                value: response.eics.parent.phoneNumber
                  ? response.eics.parent.phoneNumber
                  : '-',
              },
              {
                id: 'ConsentForms.clientAddress',
                labelid: 'ConsentForms.clientAddress',
                defaultLabel: 'Address:',
                value: response.eics.parent.address
                  ? response.eics.parent.address
                  : '-',
              },
            ],
            fosterParents:
              transformedData.length > 0
                ? transformedData
                : [
                    {
                      0: [
                        {
                          id: 'ProgressNote.Name',
                          labelid: 'ProgressNote.Name',
                          defaultLabel: 'Name:',
                          value: '-',
                        },
                        {
                          id: 'Facesheet.relationship',
                          labelid: 'Facesheet.relationship',
                          defaultLabel: 'Relationship:',
                          value: '-',
                        },
                        {
                          id: 'ConsentForms.programPhone',
                          labelid: 'ConsentForms.programPhone',
                          defaultLabel: 'Phone:',
                          value: '-',
                        },
                        {
                          id: 'ConsentForms.clientAddress',
                          labelid: 'ConsentForms.clientAddress',
                          defaultLabel: 'Address:',
                          value: '-',
                        },
                      ],
                    },
                  ],
            race: response.eics.race.toString(),
            ethnicity: response.eics.ethnicity,
            education: response.eics.education,
            educationYears: response.eics.educationYear,
            schoolCompleted: response.schoolCompleted,
            occupation: response.occupation,
            employeedBy: response.employeedBy,
            employerAddress: response.employerAddress,
            workPhone: response.workPhone,
            jobType: response.jobType,
            jobTypeOther: response.jobTypeOther,
            primarySpokenLanguage:
              response.eics.primarySpokenLanguage.toString(),
            primarySpokenLanguageOther:
              response.eics.primarySpokenLanguageOther,
            primaryWrittenLanguage:
              response.eics.primaryWrittenLanguage.toString(),
            primaryWrittenLanguageOther:
              response.eics.primaryWrittenLanguageOther,
            parentsMaritalStatus: response.parentsMaritalStatus,
            kinshipCare: response.kinshipCare,
            withWhomChildLive: response.withWhomChildLive,
            siblingsInfo: response.siblings,
            pets: response.eics.pets,
            smoking: response.eics.smoking,
            unsecuredWeapons: response.eics.unsecuredWeapons,
            extendedFamily: response.extendedFamily,
            compromisedImmuneSystem: response.compromisedImmuneSystem,
            familyMemberHistoryOther: response.familyMemberHistoryOther,
            parentalChronicIllness: response.eics.parentalChronicIllness,
            affectingChild: response.eics.affectingChild,
            siblingWithSEN: response.eics.siblingWithSEN,
            mothersEducationYear: response.eics.mothersEducationYear,
            mothersEducationYearCheck:
              response.eics.mothersEducationYear !== 'Unknown' ? false : true,
            povertyLevel: response.eics.povertyLevel,
            lackingAdequate: response.eics.lackingAdequate,
            protectiveServiceInvestigation:
              response.eics.protectiveServiceInvestigation,
            homelessness: response.eics.homelessness,
            homelessnessInPast: response.eics.homelessnessInPast,
            birthMotherParity: response.eics.birthMotherParity,
            abuseAffectingChild: response.eics.abuseAffectingChild,
            socialSupport: response.eics.socialSupport,
          });

          getCheckBoxSelectedNamesGenericFunc(
            'qualificationNames',
            response.qualification,
          );
          getCheckBoxSelectedNamesGenericFunc(
            'familyHealthIssueNames',
            response.familyMemberHistory,
          );

          toggleLoader(false);
        })
        .catch((error) => {
          toggleLoader(false);
          setSuccessOrError('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId(error);
            setToastrDefaultMessage(error);
          }
        });
    }
  };

  const getCheckBoxSelectedNamesGenericFunc = (
    type: string,
    selectedProblems: string[],
  ) => {
    if (type === 'qualificationNames') {
      setQualificationData(
        qualificationData.map((data) => {
          if (selectedProblems.includes(data.dataLabelId)) {
            data.checked = true;
          } else {
            data.checked = false;
          }
          setQualificationNames((prevNames) => {
            if (data.checked) {
              return [...prevNames, data.dataLabelId];
            } else {
              return prevNames.filter((name) => name !== data.dataLabelId);
            }
          });
          return data;
        }),
      );
    } else if (type === 'familyHealthIssueNames') {
      setFamilyHealthIssueData(
        familyHealthIssueData.map((data) => {
          if (selectedProblems.includes(data.dataLabelId)) {
            data.checked = true;
          } else {
            data.checked = false;
          }
          setFamilyHealthIssueNames((prevNames) => {
            if (data.checked) {
              return [...prevNames, data.dataLabelId];
            } else {
              return prevNames.filter((name) => name !== data.dataLabelId);
            }
          });
          return data;
        }),
      );
    }
  };

  const getSiblingsData = async () => {
    if (clientId) {
      toggleLoader(true);
      await getAllContactSiblingsList(clientId)
        .then((response: SiblingTypeResponse[]) => {
          setSiblingsDetailsList(response);
          const siblingValuesList: OptionType[] = [];
          response.forEach((siblingDetails: SiblingTypeResponse) => {
            siblingValuesList.push({
              id: siblingDetails.id,
              label: siblingDetails.name,
            });
          });
          setSiblingsList(siblingValuesList);
          toggleLoader(false);
        })
        .catch((error) => {
          toggleLoader(false);
          setSuccessOrError('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId(error);
            setToastrDefaultMessage(error);
          }
        });
    }
  };

  const handleQualificationCheckBoxClick = (id: number, checked: boolean) => {
    if (id === 4 && checked) {
      setQualificationData(
        qualificationData.map((data) => ({
          ...data,
          checked: data.id === id ? checked : false,
        })),
      );
      setQualificationNames(['EducationLevel.unknown']);
    } else {
      setQualificationData(
        qualificationData.map((data) => {
          if (data.id === id) {
            data.checked = checked;
          } else if (data.id === 4) {
            data.checked = false;
          }
          return data;
        }),
      );

      setQualificationNames((prevNames) => {
        const dataLabelId =
          qualificationData.find((item) => item.id === id)?.dataLabelId || '';
        let updatedNames = prevNames.filter(
          (name) => name !== 'EducationLevel.unknown',
        );

        if (checked) {
          updatedNames = [...updatedNames, dataLabelId];
        } else {
          updatedNames = updatedNames.filter((name) => name !== dataLabelId);
        }

        return updatedNames;
      });
    }
  };

  const handleFamilyHealthIssueCheckBoxClick = (
    id: number,
    checked: boolean,
  ) => {
    setFamilyHealthIssueData(
      familyHealthIssueData.map((data) => {
        if (data.id === id) {
          data.checked = checked;
        }
        setFamilyHealthIssueNames((prevNames) => {
          if (data.checked) {
            return [...prevNames, data.dataLabelId];
          } else {
            return prevNames.filter((name) => name !== data.dataLabelId);
          }
        });

        if (id === familyHealthIssueData.length - 1 && !checked) {
          formikRef.current?.setFieldValue('familyMemberHistoryOther', '');
        }

        return data;
      }),
    );
  };

  const handleAddNewOtherSibling = async (
    submittedValues: IntakeFamilyHistoryFormikType,
  ) => {
    const newOtherContact = siblingsDetailsList.find(
      (sibling) => sibling.id === submittedValues.siblingNameId,
    );

    const isDuplicated = intakeSiblingsTable.map((otherSibling) => {
      const isRecordDuplicated =
        otherSibling.siblingType === submittedValues.siblingType &&
        otherSibling.name === newOtherContact?.name &&
        otherSibling.dateOfBirth === newOtherContact?.dateOfBirth &&
        otherSibling.school === submittedValues.school &&
        otherSibling.grade === submittedValues.grade;

      return isRecordDuplicated;
    });

    if (editRecordIndex !== null && !isDuplicated.includes(true)) {
      const updatedRecord = {
        id: newOtherContact?.id ?? intakeSiblingsTable[editRecordIndex].id,
        name:
          newOtherContact?.name ?? intakeSiblingsTable[editRecordIndex].name,
        dateOfBirth:
          newOtherContact?.dateOfBirth ??
          intakeSiblingsTable[editRecordIndex].dateOfBirth,
        school: submittedValues.school,
        grade: submittedValues.grade,
        siblingType: submittedValues.siblingType,
      };

      setIntakeSiblingsTable((prev) => {
        const updatedTable = [...prev];
        updatedTable[editRecordIndex] = updatedRecord;

        if (formikRef.current) {
          setIntakeFamilyHistoryInfo({
            ...intakeFamilyHistoryInfo,
            education: formikRef.current.values.education,
            educationYears: formikRef.current.values.educationYears,
            schoolCompleted: formikRef.current.values.schoolCompleted,
            occupation: formikRef.current.values.occupation,
            employeedBy: formikRef.current.values.employeedBy,
            employerAddress: formikRef.current.values.employerAddress,
            workPhone: formikRef.current.values.workPhone,
            jobType: formikRef.current.values.jobType,
            jobTypeOther: formikRef.current.values.jobTypeOther,
            parentsMaritalStatus: formikRef.current.values.parentsMaritalStatus,
            kinshipCare: formikRef.current.values.kinshipCare,
            siblingsInfo: updatedTable.filter(
              (item) => typeof item.id !== 'number',
            ),
            extendedFamily: formikRef.current.values.extendedFamily,
            compromisedImmuneSystem:
              formikRef.current.values.compromisedImmuneSystem,
            familyMemberHistoryOther:
              formikRef.current.values.familyMemberHistoryOther,
            parentalChronicIllness:
              formikRef.current.values.parentalChronicIllness,
            affectingChild: formikRef.current.values.affectingChild,
            race: formikRef.current.values.race,
            ethnicity: formikRef.current.values.ethnicity,
            primarySpokenLanguage:
              formikRef.current.values.primarySpokenLanguage,
            primarySpokenLanguageOther:
              formikRef.current.values.primarySpokenLanguageOther,
            primaryWrittenLanguage:
              formikRef.current.values.primaryWrittenLanguage,
            primaryWrittenLanguageOther:
              formikRef.current.values.primaryWrittenLanguageOther,
            withWhomChildLive: formikRef.current.values.withWhomChildLive,
            pets: formikRef.current.values.pets,
            smoking: formikRef.current.values.smoking,
            unsecuredWeapons: formikRef.current.values.unsecuredWeapons,
            siblingWithSEN: formikRef.current.values.siblingWithSEN,
            mothersEducationYear: formikRef.current.values.mothersEducationYear,
            povertyLevel: formikRef.current.values.povertyLevel,
            lackingAdequate: formikRef.current.values.lackingAdequate,
            protectiveServiceInvestigation:
              formikRef.current.values.protectiveServiceInvestigation,
            homelessness: formikRef.current.values.homelessness,
            homelessnessInPast: formikRef.current.values.homelessnessInPast,
            birthMotherParity: formikRef.current.values.birthMotherParity,
            abuseAffectingChild: formikRef.current.values.abuseAffectingChild,
            socialSupport: formikRef.current.values.socialSupport,
          });
        }

        return updatedTable;
      });
    } else if (
      newOtherContact &&
      !isDuplicated.includes(true) &&
      formikRef.current
    ) {
      setIntakeFamilyHistoryInfo({
        ...intakeFamilyHistoryInfo,
        education: formikRef.current.values.education,
        educationYears: formikRef.current.values.educationYears,
        schoolCompleted: formikRef.current.values.schoolCompleted,
        occupation: formikRef.current.values.occupation,
        employeedBy: formikRef.current.values.employeedBy,
        employerAddress: formikRef.current.values.employerAddress,
        workPhone: formikRef.current.values.workPhone,
        jobType: formikRef.current.values.jobType,
        jobTypeOther: formikRef.current.values.jobTypeOther,
        parentsMaritalStatus: formikRef.current.values.parentsMaritalStatus,
        kinshipCare: formikRef.current.values.kinshipCare,
        siblingsInfo: [
          ...intakeFamilyHistoryInfo.siblingsInfo,
          {
            id: newOtherContact.id || '',
            name: newOtherContact.name ?? '',
            dateOfBirth: newOtherContact.dateOfBirth ?? '',
            school: submittedValues.school ?? '',
            grade: submittedValues.grade ?? '',
            siblingType: submittedValues.siblingType ?? '',
          },
        ],
        extendedFamily: formikRef.current.values.extendedFamily,
        compromisedImmuneSystem:
          formikRef.current.values.compromisedImmuneSystem,
        familyMemberHistoryOther:
          formikRef.current.values.familyMemberHistoryOther,
        parentalChronicIllness: formikRef.current.values.parentalChronicIllness,
        affectingChild: formikRef.current.values.affectingChild,
        race: formikRef.current.values.race,
        ethnicity: formikRef.current.values.ethnicity,
        primarySpokenLanguage: formikRef.current.values.primarySpokenLanguage,
        primarySpokenLanguageOther:
          formikRef.current.values.primarySpokenLanguageOther,
        primaryWrittenLanguage: formikRef.current.values.primaryWrittenLanguage,
        primaryWrittenLanguageOther:
          formikRef.current.values.primaryWrittenLanguageOther,
        withWhomChildLive: formikRef.current.values.withWhomChildLive,
        pets: formikRef.current.values.pets,
        smoking: formikRef.current.values.smoking,
        unsecuredWeapons: formikRef.current.values.unsecuredWeapons,
        siblingWithSEN: formikRef.current.values.siblingWithSEN,
        mothersEducationYear: formikRef.current.values.mothersEducationYear,
        povertyLevel: formikRef.current.values.povertyLevel,
        lackingAdequate: formikRef.current.values.lackingAdequate,
        protectiveServiceInvestigation:
          formikRef.current.values.protectiveServiceInvestigation,
        homelessness: formikRef.current.values.homelessness,
        homelessnessInPast: formikRef.current.values.homelessnessInPast,
        birthMotherParity: formikRef.current.values.birthMotherParity,
        abuseAffectingChild: formikRef.current.values.abuseAffectingChild,
        socialSupport: formikRef.current.values.socialSupport,
      });
    } else if (isDuplicated.includes(true)) {
      setShowAlertDialog(true);
    }
    setEditRecordIndex(null);
  };

  const handleEditOtherContact = async (editIndex: number) => {
    setEditRecordIndex(editIndex);

    const data = intakeSiblingsTable[editIndex];

    if (data) {
      await formikRef.current?.setFieldValue('siblingNameId', data.id);
      await formikRef.current?.setFieldValue('dateOfBirth', data.dateOfBirth);
      await formikRef.current?.setFieldValue('school', data.school);
      await formikRef.current?.setFieldValue('grade', data.grade);
      await formikRef.current?.setFieldValue('siblingType', data.siblingType);
    }
  };

  const handleDeleteOtherContact = (index: number) => {
    setDeleteIndex(index);
    setDeleteWarning(true);
  };

  const handleFormSubmit = async () => {
    // Very very important
    await formikRef.current?.submitForm();
    await formikRef.current?.validateForm();
    // Very very important

    if (formikRef.current && formikRef.current.isValid) {
      const params: IntakeFamilyHistoryInfoResponseType = {
        schoolCompleted: formikRef.current.values.schoolCompleted,
        qualification: [...new Set(qualificationNames)],
        occupation: formikRef.current.values.occupation,
        employeedBy: formikRef.current.values.employeedBy,
        employerAddress: formikRef.current.values.employerAddress,
        workPhone: formikRef.current.values.workPhone,
        jobType: formikRef.current.values.jobType,
        jobTypeOther: formikRef.current.values.jobTypeOther,
        parentsMaritalStatus: formikRef.current.values.parentsMaritalStatus,
        kinshipCare: formikRef.current.values.kinshipCare,
        fosterParents: fosterParentDetails,
        withWhomChildLive: formikRef.current.values.withWhomChildLive,
        siblings: intakeFamilyHistoryInfo.siblingsInfo,
        extendedFamily: formikRef.current.values.extendedFamily,
        compromisedImmuneSystem:
          formikRef.current.values.compromisedImmuneSystem,
        familyMemberHistory: [...new Set(familyHealthIssueNames)],
        familyMemberHistoryOther:
          formikRef.current.values.familyMemberHistoryOther,
        eics: {
          parent: {
            id: intakeFamilyHistoryInfo.parent[0].value,
            name: intakeFamilyHistoryInfo.parent[0].value,
            relationshipToClient: intakeFamilyHistoryInfo.parent[1].value,
            phoneNumber: intakeFamilyHistoryInfo.parent[2].value,
            address: intakeFamilyHistoryInfo.parent[3].value,
          },
          parentalChronicIllness:
            formikRef.current.values.parentalChronicIllness,
          affectingChild: formikRef.current.values.affectingChild,
          race: formikRef.current.values.race,
          ethnicity: formikRef.current.values.ethnicity,
          primarySpokenLanguage: formikRef.current.values.primarySpokenLanguage,
          primarySpokenLanguageOther:
            formikRef.current.values.primarySpokenLanguageOther,
          primaryWrittenLanguage:
            formikRef.current.values.primaryWrittenLanguage,
          primaryWrittenLanguageOther:
            formikRef.current.values.primaryWrittenLanguageOther,
          pets: formikRef.current.values.pets,
          smoking: formikRef.current.values.smoking,
          unsecuredWeapons: formikRef.current.values.unsecuredWeapons,
          siblingWithSEN: formikRef.current.values.siblingWithSEN,
          mothersEducationYear: formikRef.current.values.mothersEducationYear,
          povertyLevel: formikRef.current.values.povertyLevel,
          lackingAdequate: formikRef.current.values.lackingAdequate,
          protectiveServiceInvestigation:
            formikRef.current.values.protectiveServiceInvestigation,
          homelessness: formikRef.current.values.homelessness,
          homelessnessInPast: formikRef.current.values.homelessnessInPast,
          education: formikRef.current.values.education,
          educationYear: formikRef.current.values.educationYears,
          birthMotherParity: formikRef.current.values.birthMotherParity,
          abuseAffectingChild: formikRef.current.values.abuseAffectingChild,
          socialSupport: formikRef.current.values.socialSupport,
        },
      };

      if (clientId) {
        toggleLoader(true);
        putIntakeFamilyHistoryInfo(clientId, params)
          .then((response) => {
            if (response) {
              setSuccessOrError('success');
              setToastrId('Intake.successMsg');
              setToastrDefaultMessage('Intake updated successfully');

              setTimeout(() => {
                toggleLoader(false);
              }, 1000);

              handleIntakeGetAPI();
            }
          })
          .catch((error) => {
            toggleLoader(false);
            setSuccessOrError('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              setToastrId(error);
              setToastrDefaultMessage(error);
            }
          });
      }
    }
  };

  return (
    <>
      {toastrId && (
        <SnackBarComponent
          open={toastrId !== undefined}
          handleClose={() => {
            setToastrId(undefined);
          }}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
          successOrError={successOrError}
        />
      )}
      <Box
        ref={parentRef}
        style={{
          pointerEvents: viewToBeLocked ? 'none' : 'auto',
          opacity: viewToBeLocked ? '0.8' : 'unset',
        }}
        onKeyDownCapture={(e) => {
          if (viewToBeLocked) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        onFocus={() => {
          if (parentRef.current) parentRef.current.focus();
        }}
      >
        <Card className="formCardview gray3 borderRadius4 mb-md p-sm">
          <ModalPopup
            open={showAlertDialog}
            description="duplicateIntakeTeamWarningMessage"
            onOk={() => {
              setShowAlertDialog(false);
            }}
            labelId2="Clientpage.Okbtn"
            positiveActionLabel="Ok"
          />
          <ModalPopup
            open={deleteWaring}
            description="deleteConfirmationMessage"
            onOk={() => {
              const updatedTeamMembers = intakeSiblingsTable.filter(
                (_, i) => i !== deleteIndex,
              );
              if (formikRef.current) {
                setIntakeFamilyHistoryInfo({
                  ...intakeFamilyHistoryInfo,
                  education: formikRef.current.values.education,
                  educationYears: formikRef.current.values.educationYears,
                  schoolCompleted: formikRef.current.values.schoolCompleted,
                  occupation: formikRef.current.values.occupation,
                  employeedBy: formikRef.current.values.employeedBy,
                  employerAddress: formikRef.current.values.employerAddress,
                  workPhone: formikRef.current.values.workPhone,
                  jobType: formikRef.current.values.jobType,
                  jobTypeOther: formikRef.current.values.jobTypeOther,
                  parentsMaritalStatus:
                    formikRef.current.values.parentsMaritalStatus,
                  kinshipCare: formikRef.current.values.kinshipCare,
                  siblingsInfo: updatedTeamMembers.filter(
                    (item) => typeof item.id !== 'number',
                  ),
                  extendedFamily: formikRef.current.values.extendedFamily,
                  compromisedImmuneSystem:
                    formikRef.current.values.compromisedImmuneSystem,
                  familyMemberHistoryOther:
                    formikRef.current.values.familyMemberHistoryOther,
                  parentalChronicIllness:
                    formikRef.current.values.parentalChronicIllness,
                  affectingChild: formikRef.current.values.affectingChild,
                  race: formikRef.current.values.race,
                  ethnicity: formikRef.current.values.ethnicity,
                  primarySpokenLanguage:
                    formikRef.current.values.primarySpokenLanguage,
                  primarySpokenLanguageOther:
                    formikRef.current.values.primarySpokenLanguageOther,
                  primaryWrittenLanguage:
                    formikRef.current.values.primaryWrittenLanguage,
                  primaryWrittenLanguageOther:
                    formikRef.current.values.primaryWrittenLanguageOther,
                  withWhomChildLive: formikRef.current.values.withWhomChildLive,
                  pets: formikRef.current.values.pets,
                  smoking: formikRef.current.values.smoking,
                  unsecuredWeapons: formikRef.current.values.unsecuredWeapons,
                  siblingWithSEN: formikRef.current.values.siblingWithSEN,
                  mothersEducationYear:
                    formikRef.current.values.mothersEducationYear,
                  povertyLevel: formikRef.current.values.povertyLevel,
                  lackingAdequate: formikRef.current.values.lackingAdequate,
                  protectiveServiceInvestigation:
                    formikRef.current.values.protectiveServiceInvestigation,
                  homelessness: formikRef.current.values.homelessness,
                  homelessnessInPast:
                    formikRef.current.values.homelessnessInPast,
                  birthMotherParity: formikRef.current.values.birthMotherParity,
                  abuseAffectingChild:
                    formikRef.current.values.abuseAffectingChild,
                  socialSupport: formikRef.current.values.socialSupport,
                });
                setDeleteWarning(false);
              }
            }}
            onCancel={() => {
              setDeleteWarning(false);
            }}
            labelId1="Clientpage.cancelbtn"
            negativeActionLabel="cancelText"
            labelId2="Clientpage.Okbtn"
            positiveActionLabel="Ok"
          />
          <Formik
            initialValues={intakeFamilyHistoryInfo}
            innerRef={formikRef}
            validateOnMount={true}
            validateOnChange={true}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              await handleAddNewOtherSibling(values);
            }}
          >
            {({ values, setFieldValue, errors, touched, handleSubmit }) => (
              <Box component="div">
                <Box
                  component="div"
                  className="p-sm gray3 borderRadius4"
                  sx={{
                    border: '1px solid #00C6B8',
                  }}
                >
                  <Box component="div" className="flex__ align__items__center">
                    <KeyValueComponent data={intakeFamilyHistoryInfo.parent} />
                  </Box>
                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'24px'}
                    className="mt-md mb-md"
                  >
                    <Grid item xs={4.5} lg={4.5} className="multisearchHgt">
                      <MultipleAutocompleteSearch
                        labelId="IntakeFamilyHistory.RacePlaceholder"
                        defaultId="Race *"
                        data={raceValues}
                        type="string"
                        onSelectItem={(value: OptionType[] | null) => {
                          if (value) {
                            const selectedRaceIds = value.map((r) => r.id);
                            setFieldValue('race', selectedRaceIds.toString());
                          }
                        }}
                        disableLabel={true}
                        optionHintId={'No Race found'}
                        sxProps={{
                          [`& .${autocompleteClasses.popupIndicator}`]: {
                            transform: 'none',
                            marginTop: '2px',
                          },
                          backgroundColor: '#fff',
                        }}
                        value={values.race
                          .split(',') // Split the string by comma
                          .map((r) => ({
                            id: r,
                            label:
                              raceValues.find((raceValue) => raceValue.id === r)
                                ?.label || '', // Set label to empty string if not found
                          }))
                          .filter(
                            (option): option is { id: string; label: string } =>
                              option.label !== '', // Filter out options with empty labels
                          )}
                        disabled
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.race && touched.race && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.race}
                        />
                      )}
                    </Grid>
                    <Grid item xs={4.5} lg={4.5}>
                      <DropdownComponent
                        names={ethnicityValues}
                        labelid={'IntakeFamilyHistory.EthnicityPlaceholder'}
                        value={values.ethnicity}
                        defaultlabelid="Ethnicity *"
                        handleChange={(e) => {
                          if (e) {
                            setFieldValue('ethnicity', e);
                          }
                        }}
                        disabled
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.ethnicity && touched.ethnicity && (
                        <MediumTypography
                          labelid={errors.ethnicity}
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'24px'}
                    className="mt-md mb-md"
                  >
                    <Grid item xs={4.5}>
                      <Textinput
                        name="education"
                        labelid="IntakeFamilyHistory.EducationPlaceholder"
                        defaultlabelid="Education"
                        Value={values.education}
                        handlechange={(text: string) => {
                          setFieldValue('education', text);
                        }}
                        inputProps={{
                          maxLength: 50,
                        }}
                        sxProps={{
                          backgroundColor: 'white',
                        }}
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.education && touched.education && (
                        <MediumTypography
                          labelid={errors.education}
                          defaultlabel="Education is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                    <Grid item xs={4.5}>
                      <Textinput
                        name="educationYears"
                        labelid="IntakeFamilyHistory.EducationYearsPlaceholder"
                        defaultlabelid="Education"
                        Value={values.educationYears}
                        handlechange={(text: string) => {
                          const regex = /^\d+(\.\d{0,1})?$/;
                          if (regex.test(text) || text === '') {
                            setFieldValue('educationYears', text);
                          }
                        }}
                        inputProps={{
                          maxLength: 4,
                          pattren: ['0-9'],
                        }}
                        sxProps={{
                          backgroundColor: 'white',
                        }}
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.educationYears && touched.educationYears && (
                        <MediumTypography
                          labelid={errors.educationYears}
                          defaultlabel="Education Years is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'24px'}
                    className="mt-md mb-md"
                  >
                    <Grid item xs={9}>
                      <Textinput
                        name="schoolCompleted"
                        labelid="IntakeFamilyHistory.schoolCompleted"
                        defaultlabelid="Education"
                        Value={values.schoolCompleted}
                        handlechange={(text: string) => {
                          setFieldValue('schoolCompleted', text);
                        }}
                        inputProps={{
                          maxLength: 100,
                        }}
                        sxProps={{
                          backgroundColor: 'white',
                        }}
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.schoolCompleted && touched.schoolCompleted && (
                        <MediumTypography
                          labelid={errors.schoolCompleted}
                          defaultlabel="Is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Box
                    component="div"
                    className="flex__ align__items__center mt-md"
                  >
                    <MediumTypography
                      labelid="IntakeFamilyHistory.Qualification"
                      defaultlabel="Qualification"
                      fontSize="14px"
                      fontweight={600}
                      textColor="#00C6B8"
                    />
                  </Box>
                  <Box component="div" className="mt-md">
                    <Box className="flex__">
                      {qualificationData.map((q) => (
                        <Box key={q.id} className="mr-lg">
                          <Box component="div" className="flex__">
                            <CustomCheckBox
                              value={q.checked}
                              style={{ padding: 0, paddingRight: '8px' }}
                              onCheckBoxClick={(e) =>
                                handleQualificationCheckBoxClick(q.id, e)
                              }
                            />
                            <MediumTypography
                              label={q.defaultDataLabelId}
                              fontweight={400}
                              textColor="#2A4241"
                            />
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'24px'}
                    className="mt-md mb-md"
                  >
                    <Grid item xs={9}>
                      <Textinput
                        name="occupation"
                        labelid="IntakeFamilyHistory.occupation"
                        defaultlabelid="Occupation"
                        Value={values.occupation}
                        handlechange={(text: string) => {
                          setFieldValue('occupation', text);
                        }}
                        inputProps={{
                          maxLength: 100,
                        }}
                        sxProps={{
                          backgroundColor: 'white',
                        }}
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.occupation && touched.occupation && (
                        <MediumTypography
                          labelid={errors.occupation}
                          defaultlabel="Is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'24px'}
                    className="mt-md"
                  >
                    <Grid item xs={9}>
                      <Textinput
                        name="employeedBy"
                        labelid="IntakeFamilyHistory.employedBy"
                        defaultlabelid="Employed By"
                        Value={values.employeedBy}
                        handlechange={(text: string) => {
                          setFieldValue('employeedBy', text);
                        }}
                        inputProps={{
                          maxLength: 100,
                        }}
                        sxProps={{
                          backgroundColor: 'white',
                        }}
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.employeedBy && touched.employeedBy && (
                        <MediumTypography
                          labelid={errors.employeedBy}
                          defaultlabel="Is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'24px'}
                    className="mt-sm"
                  >
                    <Grid item xs={9}>
                      <CommentTextbox
                        Value={values.employerAddress}
                        placeholder="Employer Address"
                        name="employerAddress"
                        maxLength={300}
                        handlechange={(e) => {
                          setFieldValue('employerAddress', e);
                        }}
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.employerAddress && touched.employerAddress && (
                        <MediumTypography
                          labelid={errors.employerAddress}
                          defaultlabel="Employer Address is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'24px'}
                    className="mt-sm mb-md"
                  >
                    <Grid item xs={4.5}>
                      <Textinput
                        name="workPhone"
                        labelid="parentGuardian.workPhoneNumber"
                        defaultlabelid="Work Phone Number"
                        Value={values.workPhone}
                        handlechange={(text: string) => {
                          const validatedNumber = formatPhoneNumber(text);
                          setFieldValue('workPhone', validatedNumber);
                        }}
                        inputProps={{
                          maxLength: 12,
                          pattren: ['0-9'],
                        }}
                        sxProps={{
                          backgroundColor: 'white',
                        }}
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.workPhone && touched.workPhone && (
                        <MediumTypography
                          labelid={errors.workPhone}
                          defaultlabel="Phone Number is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Box component="div">
                    <MediumTypography
                      className="mr-lg"
                      labelid="IntakeFamilyHistory.MoreInfo"
                      defaultlabel="More Info"
                    />
                    <Grid
                      container
                      direction={'row'}
                      columnSpacing={'40px'}
                      className="mt-md mb-md"
                    >
                      <Grid item xs={9} lg={9}>
                        <CustomRadioButton
                          data={JobTypeJsonData}
                          value={values.jobType}
                          onSelectItem={(value) => {
                            if (value) {
                              if (values.jobType !== 'Other') {
                                setFieldValue('jobTypeOther', '');
                              }
                              setFieldValue('jobType', value);
                            }
                          }}
                        />
                        {errors.jobType && touched.jobType && (
                          <MediumTypography
                            labelid={errors.jobType}
                            defaultlabel="Job Type is Required"
                            className="errorText-md"
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>

                  {values.jobType === 'Other' && (
                    <Grid
                      container
                      direction={'row'}
                      columnSpacing={'24px'}
                      className="mt-md mb-md"
                    >
                      <Grid item xs={9}>
                        <Textinput
                          name="jobTypeOther"
                          labelid="otherText"
                          defaultlabelid="Other"
                          Value={values.jobTypeOther}
                          handlechange={(text: string) => {
                            setFieldValue('jobTypeOther', text);
                          }}
                          inputProps={{
                            maxLength: 100,
                          }}
                          sxProps={{
                            backgroundColor: 'white',
                          }}
                          placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.jobTypeOther && touched.jobTypeOther && (
                          <MediumTypography
                            labelid={errors.jobTypeOther}
                            defaultlabel="Is Required"
                            className="errorText-md"
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'24px'}
                    className="mt-md mb-lg"
                  >
                    <Grid item xs={4.5} lg={4.5}>
                      <DropdownComponent
                        names={languageValues}
                        labelid={'Clientpage.primarySpokenLanguage'}
                        value={values.primarySpokenLanguage}
                        defaultlabelid="Primary Spoken Language"
                        handleChange={(e) => {
                          if (e) {
                            setFieldValue('primarySpokenLanguage', e);
                          }
                        }}
                        disabled
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.primarySpokenLanguage &&
                        touched.primarySpokenLanguage && (
                          <MediumTypography
                            className="errorText-md"
                            labelid={errors.primarySpokenLanguage}
                          />
                        )}
                    </Grid>
                    <Grid item xs={4.5} lg={4.5}>
                      <DropdownComponent
                        names={languageValues}
                        labelid={'Clientpage.PrimaryWritten'}
                        value={values.primaryWrittenLanguage}
                        defaultlabelid="Primary Written Language"
                        handleChange={(e) => {
                          if (e) {
                            setFieldValue('primaryWrittenLanguage', e);
                          }
                        }}
                        disabled
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.primaryWrittenLanguage &&
                        touched.primaryWrittenLanguage && (
                          <MediumTypography
                            labelid={errors.primaryWrittenLanguage}
                            className="errorText-md"
                          />
                        )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'24px'}
                    className="mt-md mb-sm"
                  >
                    {values.primarySpokenLanguage.match('OTHER') && (
                      <>
                        <Grid item xs={4.5} lg={4.5}>
                          <Textinput
                            name="primarySpokenLanguageOther"
                            labelid="otherText"
                            defaultlabelid="Other"
                            Value={values.primarySpokenLanguageOther}
                            handlechange={(text: string) => {
                              setFieldValue('primarySpokenLanguageOther', text);
                            }}
                            inputProps={{
                              maxLength: 100,
                            }}
                            sxProps={{
                              backgroundColor: 'white',
                            }}
                            disabled
                            placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.primarySpokenLanguageOther &&
                            touched.primarySpokenLanguageOther && (
                              <MediumTypography
                                labelid={errors.primarySpokenLanguageOther}
                                defaultlabel="Is Required"
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                        {!values.primarySpokenLanguage.match('OTHER') && (
                          <Grid item xs={4.5} lg={4.5}></Grid>
                        )}
                      </>
                    )}

                    {values.primaryWrittenLanguage.match('OTHER') && (
                      <>
                        {!values.primarySpokenLanguage.match('OTHER') && (
                          <Grid item xs={4.5} lg={4.5}></Grid>
                        )}
                        <Grid item xs={4.5} lg={4.5}>
                          <Textinput
                            name="primaryWrittenLanguageOther"
                            labelid="otherText"
                            defaultlabelid="Other"
                            Value={values.primaryWrittenLanguageOther}
                            handlechange={(text: string) => {
                              setFieldValue(
                                'primaryWrittenLanguageOther',
                                text,
                              );
                            }}
                            inputProps={{
                              maxLength: 100,
                            }}
                            sxProps={{
                              backgroundColor: 'white',
                            }}
                            disabled
                            placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.primaryWrittenLanguageOther &&
                            touched.primaryWrittenLanguageOther && (
                              <MediumTypography
                                labelid={errors.primaryWrittenLanguageOther}
                                defaultlabel="Is Required"
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Box component="div">
                    <MediumTypography
                      labelid="IntakeFamilyHistory.ParentStatus"
                      defaultlabel="Parent(s) status"
                    />
                    <Box display="flex" flexDirection="row" className="mt-md">
                      <CustomRadioButton
                        data={ParentStatusJsonData}
                        value={values.parentsMaritalStatus}
                        onSelectItem={(value) => {
                          if (value) {
                            setFieldValue('parentsMaritalStatus', value);
                          }
                        }}
                      />
                      {errors.parentsMaritalStatus &&
                        touched.parentsMaritalStatus && (
                          <MediumTypography
                            labelid={errors.parentsMaritalStatus}
                            defaultlabel="Parents Marital Status is Required"
                            className="errorText-md"
                          />
                        )}
                    </Box>
                  </Box>

                  <Box
                    component="div"
                    className="flex__ align__items__center mt-md"
                  >
                    <Box>
                      <MediumTypography
                        labelid="IntakeFamilyHistory.FosterParents"
                        defaultlabel="Foster Parents - "
                        fontSize="14px"
                        fontweight={600}
                        textColor="#00C6B8"
                        sxProps={{ pr: 0.5 }}
                      />
                    </Box>
                    <Box>
                      <MediumTypography
                        labelid="IntakeFamilyHistory.InfoNote"
                        defaultlabel="Enter this only if the child has Foster parents"
                        fontSize="10px"
                      />
                    </Box>
                  </Box>

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-lg"
                      labelid="IntakeFamilyHistory.kinshipCare"
                      defaultlabel="Is this kinship care ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.kinshipCare}
                      onSelectItem={(value) => {
                        if (value) {
                          setFieldValue('kinshipCare', value);
                        }
                      }}
                    />
                    {errors.kinshipCare && touched.kinshipCare && (
                      <MediumTypography
                        labelid={errors.kinshipCare}
                        defaultlabel="kinshipCare is Required"
                        className="errorText-md"
                      />
                    )}
                  </Box>

                  {intakeFamilyHistoryInfo.fosterParents.length > 0 &&
                    intakeFamilyHistoryInfo.fosterParents.map(
                      (item: FosterParentType, index: number) => (
                        <Box
                          component="div"
                          className="flex__ align__items__center mt-sm"
                          key={index}
                        >
                          <KeyValueComponent data={item[index]} />
                        </Box>
                      ),
                    )}

                  {fosterParentValues.length > 0 && (
                    <Grid
                      container
                      direction={'row'}
                      columnSpacing={'24px'}
                      className="mt-md mb-md"
                    >
                      <Grid item xs={4.5} lg={4.5}>
                        <DropdownComponent
                          names={fosterParentValues}
                          labelid={'IntakeFamilyHistory.withWhomChildLive'}
                          value={values.withWhomChildLive}
                          defaultlabelid="With whom does child live at present ?"
                          handleChange={(e) => {
                            if (e) {
                              setFieldValue('withWhomChildLive', e);
                            }
                          }}
                          placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.withWhomChildLive &&
                          touched.withWhomChildLive && (
                            <MediumTypography
                              labelid={errors.withWhomChildLive}
                              className="errorText-md"
                            />
                          )}
                      </Grid>
                    </Grid>
                  )}

                  {siblingsList && siblingsList.length > 0 && (
                    <Box
                      component="div"
                      className="p-sm gray3 borderRadius4 mt-md"
                      sx={{
                        border: '1px solid #00C6B8',
                      }}
                    >
                      <Box
                        component="div"
                        className="flex__justify__space-between"
                      >
                        <MediumTypography
                          labelid="Intake.siblingTitle"
                          defaultlabel="Siblings Information"
                          fontSize="16px"
                          fontweight={600}
                          textColor="#2A4241"
                        />

                        {!openSiblingsInfoSection ? (
                          <DownArrow
                            className="cursorPointer"
                            style={{ color: '#008C82' }}
                            onClick={() => {
                              setOpenSiblingsInfoSection(true);
                            }}
                          />
                        ) : (
                          <UpArrow
                            className="cursorPointer"
                            onClick={() => {
                              setOpenSiblingsInfoSection(false);
                            }}
                          />
                        )}
                      </Box>

                      {openSiblingsInfoSection && (
                        <Box component="div">
                          <Box
                            className="horizontalLine"
                            sx={{ marginY: '8px' }}
                          />
                          <Box component="div" className="mt-md mb-md">
                            <Grid container columnSpacing={'24px'}>
                              <Grid item xs={4.5}>
                                <DropdownComponent
                                  names={siblingsList}
                                  labelid="IFSP.selectName"
                                  defaultlabelid="Select Name"
                                  Required={true}
                                  value={values.siblingNameId}
                                  handleChange={(e) => {
                                    setFieldValue('siblingNameId', e);
                                  }}
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                />
                                {errors.siblingNameId &&
                                  touched.siblingNameId && (
                                    <MediumTypography
                                      className="errorText-md"
                                      labelid={errors.siblingNameId}
                                      defaultlabel="Sibling is required"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={4.5}>
                                <Textinput
                                  name="school"
                                  labelid="IntakeFamilyHistory.school"
                                  defaultlabelid="School"
                                  Value={values.school}
                                  handlechange={(text: string) => {
                                    setFieldValue('school', text);
                                  }}
                                  inputProps={{
                                    maxLength: 100,
                                  }}
                                  sxProps={{
                                    backgroundColor: 'white',
                                  }}
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                />
                                {errors.school && touched.school && (
                                  <MediumTypography
                                    labelid={errors.school}
                                    defaultlabel="School is Required"
                                    className="errorText-md"
                                  />
                                )}
                              </Grid>
                              <Grid item xs={4.5} className="mt-md mb-md">
                                <Textinput
                                  name="grade"
                                  labelid="IntakeFamilyHistory.grade"
                                  defaultlabelid="Grade"
                                  Value={values.grade}
                                  handlechange={(text: string) => {
                                    setFieldValue('grade', text);
                                  }}
                                  inputProps={{
                                    maxLength: 100,
                                  }}
                                  sxProps={{
                                    backgroundColor: 'white',
                                  }}
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                />
                                {errors.grade && touched.grade && (
                                  <MediumTypography
                                    labelid={errors.grade}
                                    defaultlabel="grade is Required"
                                    className="errorText-md"
                                  />
                                )}
                              </Grid>
                              <Grid
                                container
                                item
                                xs={4.5}
                                display="flex"
                                flexDirection="row" // Changed to column
                                className="mt-lg"
                              >
                                <Grid item>
                                  <MediumTypography
                                    className="mr-lg"
                                    labelid="IntakeFamilyHistory.siblingType"
                                    defaultlabel="Sibling Type ? *"
                                  />
                                  {errors.siblingType &&
                                    touched.siblingType && (
                                      <MediumTypography
                                        labelid={errors.siblingType}
                                        defaultlabel="Sibling Type is Required"
                                        className="errorText-md"
                                      />
                                    )}
                                </Grid>
                                <Grid item>
                                  <CustomRadioButton
                                    data={siblingTypeRadioData}
                                    value={values.siblingType}
                                    onSelectItem={(value) => {
                                      if (value) {
                                        setFieldValue('siblingType', value);
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                xs={1}
                                className="cursorPointer "
                                onClick={() => handleSubmit()}
                              >
                                <Grid item>
                                  <Box component="div" className="flex__ mt-lg">
                                    <AddNew className="ml-sm mr-sm" />
                                    <MediumTypography
                                      labelid="IFSP.Add"
                                      defaultlabel="Add"
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Box component="section">
                                  <TableContainer
                                    sx={{ maxHeight: 310, borderRadius: '4px' }}
                                  >
                                    <Table
                                      stickyHeader
                                      aria-label="sticky table"
                                    >
                                      <TableHeader
                                        className="listDataTableHead"
                                        headerNames={headCells}
                                        checkBoxRequired={true}
                                      />

                                      <TableBody className="tableRowcss">
                                        {intakeSiblingsTable.map(
                                          (listData, index) => (
                                            <IntakeSiblingsTableRow
                                              key={index}
                                              data={listData}
                                              onDelete={() =>
                                                handleDeleteOtherContact(index)
                                              }
                                              onEdit={() =>
                                                handleEditOtherContact(index)
                                              }
                                            />
                                          ),
                                        )}
                                      </TableBody>
                                    </Table>
                                    {intakeSiblingsTable.length === 0 && (
                                      <Box
                                        component="div"
                                        className="width__100 flex__align__center"
                                      >
                                        <MediumTypography
                                          sxProps={{
                                            backgroundColor: '#ECF9F8',
                                          }}
                                          textstyle={'center'}
                                          labelid="Insurance.nosiblingsdataValidation"
                                          defaultlabel="No Siblings information added yet"
                                          className="p-sm"
                                        />
                                      </Box>
                                    )}
                                  </TableContainer>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-lg"
                      labelid="IntakeFamilyHistory.anyPets"
                      defaultlabel="Any pets ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.pets}
                      onSelectItem={(value) => {
                        if (value) {
                          setFieldValue('pets', value);
                        }
                      }}
                      disable
                    />
                    {errors.pets && touched.pets && (
                      <MediumTypography
                        labelid={errors.pets}
                        defaultlabel="pets is Required"
                        className="errorText-md"
                      />
                    )}
                  </Box>

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-lg"
                      labelid="IntakeFamilyHistory.smokePlaceholder"
                      defaultlabel="Does anyone smoke inside or outside the home ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.smoking}
                      onSelectItem={(value) => {
                        if (value) {
                          setFieldValue('smoking', value);
                        }
                      }}
                      disable
                    />
                    {errors.smoking && touched.smoking && (
                      <MediumTypography
                        labelid={errors.smoking}
                        defaultlabel="smoking is Required"
                        className="errorText-md"
                      />
                    )}
                  </Box>

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-lg"
                      labelid="Clientpage.weapons"
                      defaultlabel="Guns/Weapons/Firearms in the home"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.unsecuredWeapons}
                      onSelectItem={(value) => {
                        if (value) {
                          setFieldValue('unsecuredWeapons', value);
                        }
                      }}
                      disable
                    />
                    {errors.unsecuredWeapons && touched.unsecuredWeapons && (
                      <MediumTypography
                        labelid={errors.unsecuredWeapons}
                        defaultlabel="unsecuredWeapons is Required"
                        className="errorText-md"
                      />
                    )}
                  </Box>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'24px'}
                    className="mt-sm"
                  >
                    <Grid item xs={9}>
                      <MediumTypography
                        labelid="Clientpage.ExtendedFamily"
                        defaultlabel="unsecuredWeapons is Required"
                      />
                      <CommentTextbox
                        Value={values.extendedFamily}
                        placeholder="Enter the text here..."
                        name="extendedFamily"
                        maxLength={2000}
                        handlechange={(e) => {
                          setFieldValue('extendedFamily', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.extendedFamily && touched.extendedFamily && (
                        <MediumTypography
                          labelid={errors.extendedFamily}
                          defaultlabel="Extended Family is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-lg"
                      labelid="IntakeFamilyHistory.compromisedImmuneSystem"
                      defaultlabel="Member of household with weakened/compromised immune system, OR > 65 OR chronic health condition ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.compromisedImmuneSystem}
                      onSelectItem={(value) => {
                        if (value) {
                          setFieldValue('compromisedImmuneSystem', value);
                        }
                      }}
                    />
                    {errors.compromisedImmuneSystem &&
                      touched.compromisedImmuneSystem && (
                        <MediumTypography
                          labelid={errors.compromisedImmuneSystem}
                          defaultlabel="compromisedImmuneSystem is Required"
                          className="errorText-md"
                        />
                      )}
                  </Box>

                  <Box component="div" className="mt-md">
                    <MediumTypography
                      className="mb-sm"
                      label="Have you or any family members had a history of the following difficulties ?"
                    />

                    <Grid container spacing={1}>
                      {familyHealthIssueData.map((familyMemberHistory) => (
                        <Grid key={familyMemberHistory.id} item xs={3.5}>
                          <Box component="div" className="flex__">
                            <CustomCheckBox
                              value={familyMemberHistory.checked}
                              style={{ padding: 0, paddingRight: '8px' }}
                              onCheckBoxClick={(e) =>
                                handleFamilyHealthIssueCheckBoxClick(
                                  familyMemberHistory.id,
                                  e,
                                )
                              }
                            />
                            <MediumTypography
                              label={familyMemberHistory.defaultDataLabelId}
                              fontweight={400}
                              textColor="#2A4241"
                            />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>

                  {familyHealthIssueData[familyHealthIssueData.length - 1]
                    .checked && (
                    <Grid
                      container
                      direction={'row'}
                      columnSpacing={'24px'}
                      className="mt-sm"
                    >
                      <Grid item xs={9}>
                        <CommentTextbox
                          Value={values.familyMemberHistoryOther}
                          placeholder="Other"
                          name="familyMemberHistoryOther"
                          maxLength={200}
                          handlechange={(e) => {
                            setFieldValue('familyMemberHistoryOther', e);
                          }}
                          placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.familyMemberHistoryOther &&
                          touched.familyMemberHistoryOther && (
                            <MediumTypography
                              labelid={errors.extendedFamily}
                              defaultlabel="familyMemberHistoryOther is Required"
                              className="errorText-md"
                            />
                          )}
                      </Grid>
                    </Grid>
                  )}
                </Box>

                <Box
                  component="div"
                  className="p-sm gray3 borderRadius4 mt-md"
                  sx={{
                    border: '1px solid #00C6B8',
                  }}
                >
                  <Box component="div" className="flex__justify__space-between">
                    <MediumTypography
                      labelid="Intake.eicsTitle"
                      defaultlabel="EICS Additional Data"
                      fontSize="16px"
                      fontweight={600}
                      textColor="#2A4241"
                    />

                    {!openEICSSection ? (
                      <DownArrow
                        className="cursorPointer"
                        style={{ color: '#008C82' }}
                        onClick={() => {
                          setOpenEICSSection(true);
                        }}
                      />
                    ) : (
                      <UpArrow
                        className="cursorPointer"
                        onClick={() => {
                          setOpenEICSSection(false);
                        }}
                      />
                    )}
                  </Box>

                  {openEICSSection && (
                    <Box component="div">
                      <Box className="horizontalLine" sx={{ marginY: '8px' }} />

                      <Grid
                        container
                        direction={'row'}
                        columnSpacing={'40px'}
                        className="mt-md mb-lg"
                      >
                        <Grid item xs={4.5} lg={4.5}>
                          <MediumTypography
                            labelid="IntakeFamilyHistory.parentalChronicIllness"
                            defaultlabel="Parental chronic illness or disability that affects caregiving ability ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.parentalChronicIllness}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('parentalChronicIllness', value);
                              }
                            }}
                          />
                          {errors.parentalChronicIllness &&
                            touched.parentalChronicIllness && (
                              <MediumTypography
                                labelid={errors.parentalChronicIllness}
                                className="errorText-md"
                              />
                            )}
                        </Grid>

                        <Grid item xs={4.5} lg={4.5}>
                          <MediumTypography
                            labelid="IntakeFamilyHistory.affectingChild"
                            defaultlabel="Substance abuse affecting child ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.affectingChild}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('affectingChild', value);
                              }
                            }}
                          />
                          {errors.affectingChild && touched.affectingChild && (
                            <MediumTypography
                              labelid={errors.affectingChild}
                              className="errorText-md"
                            />
                          )}
                        </Grid>

                        <Grid item xs={4.5} lg={4.5} className="mt-md">
                          <MediumTypography
                            labelid="IntakeFamilyHistory.siblingWithSEN"
                            defaultlabel="Sibling with a diagnosis of Substance Exposed Newborn (SEN) ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.siblingWithSEN}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('siblingWithSEN', value);
                              }
                            }}
                          />
                          {errors.siblingWithSEN && touched.siblingWithSEN && (
                            <MediumTypography
                              labelid={errors.siblingWithSEN}
                              className="errorText-md"
                            />
                          )}
                        </Grid>

                        <Grid item xs={5} lg={5} className="mt-md">
                          <MediumTypography
                            labelid="IntakeFamilyHistory.mothersEducationYear"
                            defaultlabel="Biological mother’s years of formal education ?"
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Textinput
                              name="mothersEducationYear"
                              labelid=""
                              defaultlabelid=""
                              Value={values.mothersEducationYear}
                              handlechange={(text: string) => {
                                const regex = /^\d+$/;
                                if (regex.test(text) || text === '') {
                                  setFieldValue('mothersEducationYear', text);
                                }
                              }}
                              inputProps={{
                                maxLength: 2,
                              }}
                              sxProps={{
                                backgroundColor: 'white',
                                width: '30%',
                              }}
                              className="mr-md"
                              placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                              disabled={values.mothersEducationYearCheck}
                            />

                            <CustomCheckBox
                              value={values.mothersEducationYearCheck}
                              style={{ padding: 0, paddingRight: '8px' }}
                              onCheckBoxClick={(e) => {
                                if (e === true) {
                                  setFieldValue(
                                    'mothersEducationYear',
                                    'Unknown',
                                  );
                                } else {
                                  setFieldValue('mothersEducationYear', '');
                                }
                                setFieldValue('mothersEducationYearCheck', e);
                              }}
                            />
                            <MediumTypography
                              labelid="Intake.RadioLabelC"
                              defaultlabel="Unknown"
                            />
                          </Box>
                          {errors.mothersEducationYear &&
                            touched.mothersEducationYear &&
                            values.mothersEducationYear !== 'Unknown' && (
                              <MediumTypography
                                labelid={errors.mothersEducationYear}
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                        <Grid item xs={4.5} lg={4.5} className="mt-md">
                          <MediumTypography
                            labelid="IntakeFamilyHistory.lackingAdequate"
                            defaultlabel="Lacking adequate food or clothing ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.lackingAdequate}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('lackingAdequate', value);
                              }
                            }}
                          />
                          {errors.lackingAdequate &&
                            touched.lackingAdequate && (
                              <MediumTypography
                                labelid={errors.lackingAdequate}
                                className="errorText-md"
                              />
                            )}
                        </Grid>

                        <Grid item xs={4.5} lg={4.5} className="mt-md">
                          <MediumTypography
                            labelid="IntakeFamilyHistory.protectiveService"
                            defaultlabel="Open/Confirmed protective service investigation ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.protectiveServiceInvestigation}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue(
                                  'protectiveServiceInvestigation',
                                  value,
                                );
                              }
                            }}
                          />
                          {errors.protectiveServiceInvestigation &&
                            touched.protectiveServiceInvestigation && (
                              <MediumTypography
                                labelid={errors.protectiveServiceInvestigation}
                                className="errorText-md"
                              />
                            )}
                        </Grid>

                        <Grid item xs={4.5} lg={4.5} className="mt-md">
                          <MediumTypography
                            labelid="IntakeFamilyHistory.homelessness"
                            defaultlabel="Homelessness ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.homelessness}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('homelessness', value);
                              }
                            }}
                          />
                          {errors.homelessness && touched.homelessness && (
                            <MediumTypography
                              labelid={errors.homelessness}
                              className="errorText-md"
                            />
                          )}
                        </Grid>

                        <Grid item xs={4.5} lg={4.5} className="mt-md">
                          <MediumTypography
                            labelid="IntakeFamilyHistory.homelessnessInPast"
                            defaultlabel="Homeless in the past 12 months ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.homelessnessInPast}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('homelessnessInPast', value);
                              }
                            }}
                          />
                          {errors.homelessnessInPast &&
                            touched.homelessnessInPast && (
                              <MediumTypography
                                labelid={errors.homelessnessInPast}
                                className="errorText-md"
                              />
                            )}
                        </Grid>

                        <Grid item xs={4.5} lg={4.5} className="mt-md">
                          <MediumTypography
                            labelid="IntakeFamilyHistory.abuse"
                            defaultlabel="Abuse (physical or emotional) affecting child ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.abuseAffectingChild}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('abuseAffectingChild', value);
                              }
                            }}
                          />
                          {errors.abuseAffectingChild &&
                            touched.abuseAffectingChild && (
                              <MediumTypography
                                labelid={errors.abuseAffectingChild}
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                        <Grid item xs={4.5} lg={4.5} className="mt-md">
                          <MediumTypography
                            labelid="IntakeFamilyHistory.socialSupport"
                            defaultlabel="Family lacking social supports ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.socialSupport}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('socialSupport', value);
                              }
                            }}
                          />
                          {errors.socialSupport && touched.socialSupport && (
                            <MediumTypography
                              labelid={errors.socialSupport}
                              className="errorText-md"
                            />
                          )}
                        </Grid>

                        <Grid item xs={12} lg={12} className="mt-md">
                          <MediumTypography
                            labelid="IntakeFamilyHistory.birthMotherParity"
                            defaultlabel="Mother less than 17 years of age and/or with 3 or more children before 20 (Birth Mother Parity) ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.birthMotherParity}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('birthMotherParity', value);
                              }
                            }}
                          />
                          {errors.birthMotherParity &&
                            touched.birthMotherParity && (
                              <MediumTypography
                                labelid={errors.birthMotherParity}
                                className="errorText-md"
                              />
                            )}
                        </Grid>

                        <Grid item xs={9} lg={9} className="mt-md">
                          <MediumTypography
                            className="mb-sm"
                            labelid="IntakeFamilyHistory.povertyLevel"
                            defaultlabel="Poverty level ? *"
                          />
                          <CustomRadioButton
                            data={PovertyJsonData}
                            value={values.povertyLevel}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('povertyLevel', value);
                              }
                            }}
                          />
                          {errors.povertyLevel && touched.povertyLevel && (
                            <MediumTypography
                              labelid={errors.povertyLevel}
                              defaultlabel="Poverty Level is Required"
                              className="errorText-md"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Formik>
          <ModalPopup
            description="formUnsavedChangesMessage"
            open={openModal}
            onCancel={() => {
              setOpenModal(false);
            }}
            onOk={() => {
              formikRef.current?.resetForm();

              getCheckBoxSelectedNamesGenericFunc(
                'qualificationNames',
                intakeFamilyHistoryInfoSavedValues?.qualification || [],
              );
              getCheckBoxSelectedNamesGenericFunc(
                'familyHealthIssueNames',
                intakeFamilyHistoryInfoSavedValues?.familyMemberHistory || [],
              );
              setIntakeFamilyHistoryInfo({
                ...intakeFamilyHistoryInfo,
                parent: intakeFamilyHistoryInfo.parent,
                fosterParents: intakeFamilyHistoryInfo.fosterParents,
                siblingsInfo: intakeFamilyHistoryInfo.siblingsInfo,
              });
              setOpenModal(false);
            }}
            labelId1="Clientpage.Nobtn"
            negativeActionLabel="No"
            labelId2="Clientpage.Yesbtn"
            positiveActionLabel="Yes"
          />
        </Card>
        <Box
          className="mt-md mb-xlg"
          display={'flex'}
          justifyContent={'flex-end'}
        >
          <ButtonComponent
            className="btn-primary btn-cancel alrt_Width_btn mr-md"
            variantType="contained"
            labelId={'cancelText'}
            defaultLabelId={'Cancel'}
            onClick={() => {
              if (
                JSON.stringify(intakeFamilyHistoryInfo) !==
                JSON.stringify(formikRef.current?.values)
              ) {
                setOpenModal(true);
              }
            }}
          />

          <ButtonComponent
            variantType="contained"
            type="submit"
            className="btn-primary btn-submit"
            labelId={'save'}
            defaultLabelId={'Save'}
            onClick={async () => {
              await formikRef.current?.setFieldValue('formHandleFlag', true);
              if (!formikRef.current?.isSubmitting) {
                handleFormSubmit();
              }
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default IntakeFamilyHistoryInfo;
