import { Grid } from '@mui/material';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import Textinput from './Textinput';
import MediumTypography from './MediumTypography';
import { FormikErrors, FormikTouched } from 'formik';
import SmallTypography from './SmallTypography';
import { Address } from '../../pages/employees/types';
import {
  ZipCodeResponse,
  getZipCodeDetails,
} from '../../services/configApi/User';
import { OptionType, ZipCodeType } from '../../utils/type';
import AutocompleteSearch from './AutocompleteSearch';
import { debounce } from 'lodash';

interface AddressFormPropType {
  headerTitleId?: string;
  address1PlaceholderId: string;
  address2PlaceholderId: string;
  zipCodePlaceholderId: string;
  cityPlaceholderId: string;
  statePlaceholderId: string;
  value: Address;
  isClient: boolean;
  disableForm: boolean;
  formRequired: boolean;
  formikErrors?: FormikErrors<Address>;
  formikTouched?: FormikTouched<Address>;
  handleAddressUpdate: (address: Address) => void;
}

const AddressForm: FC<AddressFormPropType> = ({
  address1PlaceholderId,
  address2PlaceholderId,
  cityPlaceholderId,
  headerTitleId,
  statePlaceholderId,
  zipCodePlaceholderId,
  value,
  isClient,
  disableForm,
  formRequired,
  formikErrors,
  formikTouched,
  handleAddressUpdate,
}) => {
  const [input, setInput] = useState<string>('');
  const [zipCodes, setZipCodes] = useState<ZipCodeType[]>([]);
  const [zipCodeOptions, setZipCodeOptions] = useState<OptionType[]>([]);
  const [address1Focus, setAddress1Focus] = useState(false);
  const [address2Focus, setAddress2Focus] = useState(false);

  const changeTextDebouncer = useCallback(
    debounce((code: string) => fetchZipCodeDetail(code, isClient), 500),
    [],
  );

  const formatAddressLine = (text: string): string => {
    return text.replace(/[^a-zA-Z0-9.,!#&*)] /g, '');
  };

  const getAutoSelectValue = () => input.length === 5;

  const fetchZipCodeDetail = (zipCode: string, tbrApproved: boolean) => {
    getZipCodeDetails(zipCode, tbrApproved)
      .then((response: ZipCodeResponse) => {
        setZipCodes(response.zipcodes);
        const codes: OptionType[] = [];
        response.zipcodes.forEach((code, index) => {
          codes.push({ id: (index + 1).toString(), label: code.zipCode });
        });
        setZipCodeOptions(codes);
      })
      .catch(() => {
        const codes: OptionType[] = [];
        setZipCodeOptions(codes);
      });
  };
  return (
    <Grid container rowSpacing={'24px'} columnSpacing={'40px'}>
      {headerTitleId && (
        <Grid item xs={12}>
          <MediumTypography
            textColor="#2A4241"
            fontweight={600}
            labelid={headerTitleId}
            defaultlabel="Address"
          />
        </Grid>
      )}

      <Grid item xs={6}>
        <Textinput
          name="address1"
          labelid={address1PlaceholderId}
          defaultlabelid="Address Line 1"
          type="text"
          disabled={disableForm}
          Value={value.address1 ?? ''}
          inputProps={{
            maxLength: 75,
          }}
          onFocus={() => {
            setAddress1Focus(true);
          }}
          onblur={() => {
            setAddress1Focus(false);
          }}
          inputLabelProps={{
            shrink:
              (value.address1 !== null && value.address1.length > 0) ||
              address1Focus,
          }}
          handlechange={(text) => {
            handleAddressUpdate({
              ...value,
              address1: formatAddressLine(text),
            });
          }}
          Required={formRequired}
        />
        {formikErrors?.address1 &&
          formikTouched &&
          formikTouched.address1 &&
          formRequired && (
            <SmallTypography
              sxProps={{ color: 'red' }}
              labelId={formikErrors.address1}
              defaultLabelId="Address Line 1 is required"
            />
          )}
      </Grid>
      <Grid item xs={6}>
        <Textinput
          name="address2"
          Value={value.address2 ?? ''}
          type="text"
          disabled={disableForm}
          labelid={address2PlaceholderId}
          defaultlabelid="Address Line 2"
          inputProps={{
            maxLength: 75,
          }}
          onFocus={() => {
            setAddress2Focus(true);
          }}
          onblur={() => {
            setAddress2Focus(false);
          }}
          inputLabelProps={{
            shrink:
              (value.address2 !== null && value.address2.length > 0) ||
              address2Focus,
          }}
          handlechange={(text) => {
            handleAddressUpdate({
              ...value,
              address2: formatAddressLine(text),
            });
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <AutocompleteSearch
          className="consentAutoSearch"
          labelId={zipCodePlaceholderId}
          defaultId="Zip Code"
          selectedValue={
            typeof value.zipCode === 'string' ? null : value.zipCode
          }
          data={zipCodeOptions}
          freeSolo={true}
          type={'number'}
          autoSelect={getAutoSelectValue()}
          disabled={disableForm}
          onSelectItem={(item: OptionType | null) => {
            if (item === null) {
              handleAddressUpdate({
                ...value,
                zipCode: { id: '', label: '' },
                city: '',
                state: '',
              });
              setZipCodes([]);
              setZipCodeOptions([]);
            } else {
              const zipCode = zipCodes[Number(item.id) - 1];
              if (zipCode) {
                handleAddressUpdate({
                  ...value,
                  zipCode: item,
                  city: zipCode.city,
                  state: zipCode.state,
                });
              }
            }
          }}
          onTextChange={(event: ChangeEvent<HTMLInputElement>) => {
            handleAddressUpdate({
              ...value,
              city: '',
              state: '',
            });
            changeTextDebouncer(event.target.value);
            setInput(event.target.value);
          }}
          renderOption={(props, option: OptionType) => {
            return (
              <li {...props}>
                <span>{option.label}</span>
                <span
                  style={{
                    color: 'grey',
                    fontSize: '12px',
                    paddingRight: '8px',
                    paddingLeft: '8px',
                  }}
                >
                  {'-'}
                </span>
                <span
                  style={{
                    color: 'grey',
                    fontSize: '12px',
                    paddingRight: '8px',
                  }}
                >
                  {zipCodes[Number(option.id) - 1].city},{' '}
                  {zipCodes[Number(option.id) - 1].state}
                </span>
              </li>
            );
          }}
          disableLabel={false}
          textFieldVariant="outlined"
          Required={formRequired}
        />
        {formikErrors?.zipCode &&
          formikTouched &&
          formikTouched.zipCode &&
          formRequired && (
            <SmallTypography
              sxProps={{ color: 'red' }}
              labelId={formikErrors.zipCode}
              defaultLabelId="Zip Code is Required"
            />
          )}
      </Grid>

      <Grid item xs={4}>
        <Textinput
          name="city"
          labelid={cityPlaceholderId}
          defaultlabelid="City"
          Value={value.city ?? ''}
          disabled={disableForm}
          inputProps={{
            readOnly: true,
          }}
          Required={formRequired}
        />
        {formikErrors?.city &&
          formikTouched &&
          formikTouched.city &&
          formRequired && (
            <SmallTypography
              sxProps={{ color: 'red' }}
              labelId={formikErrors.city}
              defaultLabelId="City Name is Required"
            />
          )}
      </Grid>
      <Grid item xs={4}>
        <Textinput
          name="state"
          labelid={statePlaceholderId}
          defaultlabelid="State"
          Value={value.state ?? ''}
          disabled={disableForm}
          inputProps={{ readOnly: true }}
          Required={formRequired}
        />
        {formikErrors?.state &&
          formikTouched &&
          formikTouched.state &&
          formRequired && (
            <SmallTypography
              sxProps={{ color: 'red' }}
              labelId={formikErrors.state}
              defaultLabelId="State Name is Required"
            />
          )}
      </Grid>
    </Grid>
  );
};

export default AddressForm;
