import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ProgressNoteContact } from '../../utils/type';

interface SelectedContactsScreenType {
  multiSelectContact: ProgressNoteContact[];
}

const SelectedContacts = ({
  multiSelectContact,
}: SelectedContactsScreenType) => {
  return (
    <Grid container rowGap={2} alignItems={'center'} marginTop={2}>
      {multiSelectContact.map((contact) => (
        <Grid item key={contact.contactId}>
          <Box
            sx={{
              display: 'inline-flex',
              padding: '6px',
              marginRight: '8px',
              borderRadius: '4px',
              alignItems: 'center',
              backgroundColor: '#E7F0F0',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                marginRight: '20px',
              }}
            >
              <MediumTypography
                labelid="ProgressNote.Name"
                defaultlabel="Name:"
                textColor="#97A6A5"
                sxProps={{
                  fontSize: '16px',
                }}
              />

              <MediumTypography
                sxProps={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  marginLeft: contact.name ? '4px' : '16px',
                }}
                textColor="#2A4241"
                label={contact.name ? contact.name : '-'}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <MediumTypography
                labelid="ProgressNote.RelationshipToChild"
                defaultlabel="Relationship with Child:"
                textColor="#97A6A5"
                sxProps={{
                  fontSize: '16px',
                }}
              />

              <MediumTypography
                sxProps={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  marginLeft: contact.relationshipToClient ? '4px' : '16px',
                }}
                textColor="#2A4241"
                label={
                  contact.relationshipToClient
                    ? contact.relationshipToClient
                    : '-'
                }
              />
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default SelectedContacts;
