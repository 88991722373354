import {
  Box,
  Grid,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Fab,
  AlertColor,
  Typography,
  styled,
  Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as GraphicBottomRight } from '../../assets/images/Graphic-BottomRight.svg';
import { ReactComponent as GraphicBottomLeft } from '../../assets/images/Graphic-righttop.svg';
import TitleText from '../../components/formlib/TitleText';
import { weekList } from '../Dropdown';
import SearchBox from '../../components/formlib/SearchBox';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { ReactComponent as ViewEye } from '../../assets/images/ViewEye.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';
import TaskManagerAddModal from '../../components/formlib/modal/TaskManagerAddModal';
import './TaskManagerList.css';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import { Order } from '../../utils/type';
import {
  TaskListResponse,
  TaskResponse,
  deleteTaskId,
  getTaskListAPI,
} from '../../services/configApi/taskManager/taskManagerServices';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import MediumTypography from '../../components/formlib/MediumTypography';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import AppPagination from '../../components/shared/AppPagination';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { CLIENT_NAME_KEY, REFERRAL_ID_KEY } from '../../services/Constant';
import ModalPopup from '../../components/formlib/ModalPopup';
// import { FACESHEET } from '../../routes/Routing';
// import { useNavigate } from 'react-router';

interface Data {
  taskName: string;
  origin: string;
  dateAdded: string;
  dueDate: string;
  status: string;
  actions: string;
  clientName: string;
}

interface SortData {
  order: string;
  orderBy: string;
}

const headCells: HeadCell<Data>[] = [
  {
    id: 'taskName',
    labelId: 'taskNameText',
    defaultLabelId: 'Task Name',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'origin',
    labelId: 'originText',
    defaultLabelId: 'Origin',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'clientName',
    labelId: 'clientName',
    defaultLabelId: 'Client Name',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'dateAdded',
    labelId: 'dateAddedText',
    defaultLabelId: 'Date Added',
    numeric: false,
    requiredSorting: true,
  },

  {
    id: 'dueDate',
    labelId: 'dueDateText',
    defaultLabelId: 'Due Date',
    numeric: false,
    requiredSorting: true,
  },

  {
    id: 'status',
    labelId: 'ssp.status',
    defaultLabelId: 'Status',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'actions',
    labelId: 'actions',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
];

const TaskManagerList = () => {
  const allStatus: string =
    'backend.task_status.open,backend.task_status.due,backend.task_status.warning,backend.task_status.overdue,backend.task_status.completed';
  const allStatusExceptCompleted: string =
    'backend.task_status.open,backend.task_status.due,backend.task_status.warning,backend.task_status.overdue';
  const [page, setPage] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [checkbox, setCheckbox] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedValue, setSelectedValue] = React.useState('All');
  const [sortData, setSortData] = React.useState<SortData>({
    order: 'asc',
    orderBy: 'due_date',
  });
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('dueDate');
  const [backgroundColorForAll, setBackgroundColorForAll] = useState(
    'var(--thom-green-primary2)',
  );
  const [textColorForAll, setTextColorForAll] = useState('var(--white)');
  const [backgroundColorForOpen, setBackgroundColorForOpen] =
    useState('#EDF6FF');
  const [textColorForOpen, setTextColorForOpen] = useState('#2a4241');
  const [backgroundColorForDue, setBackgroundColorForDue] = useState('#ECFFFE');
  const [textColorForDue, setTextColorForDue] = useState('#2a4241');
  const [backgroundColorForWarning, setBackgroundColorForWarning] =
    useState('#ECFFFE');
  const [textColorForWarning, setTextColorForWarning] = useState('#2a4241');
  const [backgroundColorForOverdue, setBackgroundColorForOverdue] =
    useState('#ECFFFE');
  const [textColorForOverdue, setTextColorForOverdue] = useState('#2a4241');
  const [backgroundColorForCompleted, setBackgroundColorForCompleted] =
    useState('#ECFFFE');
  const [textColorForCompleted, setTextColorForCompleted] = useState('#2a4241');
  const [openTaskManagerModal, setOpenTaskManagerModal] = React.useState(false);
  const [tasks, setTask] = React.useState<TaskListResponse>();
  const [toastrId, setToastrId] = useState<string>();
  const [successOrError, setSuccessOrError] = useState<AlertColor>('success');
  const [defaultlabel, setToastrDefaultMessage] = useState<string>();
  const [taskResponse, setTaskResponse] = useState<TaskResponse | null>(null);
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [selectedStatus, setSelectedStatus] = useState<string>(
    allStatusExceptCompleted,
  );
  const [showAlertDialog, setShowAlertDialog] = React.useState<string>();
  // const navigation = useNavigate();

  const Custombuttonsubmit = styled(Button)`
    background: #00938e;
    border-radius: 4px;
    font-family: 'Lato-Regular', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.8px;
    color: #ffffff;
    padding: 10px, 16px, 10px, 16px;
    text-transform: 'none';
    &:hover {
      background: #00938e;
      color: #ffffff;
    }
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 122px;
    height: 40px;
  `;

  const handleAdd = (responseData: TaskResponse | null) => {
    setTaskResponse(responseData);
    setOpenTaskManagerModal(true);
  };
  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    let orderDirection = order;
    if (property === orderBy) {
      orderDirection = order === 'asc' ? 'desc' : 'asc';
      setOrder(orderDirection);
    } else {
      setOrder('asc');
      orderDirection = 'asc';
    }
    setOrderBy(property);
    setSortData({ order: orderDirection, orderBy: getOrderBy(property) });
  };

  const getOrderBy = (orderby: string): string => {
    const orderByMapping: Record<string, string> = {
      dueDate: 'due_date',
      taskName: 'name',
      status: 'status',
      dateAdded: 'created_on',
    };
    return orderByMapping[orderby] || 'due_date';
  };

  const handleAllClick = () => {
    setSelectedStatus(allStatusExceptCompleted);
    setCheckbox(false);
    setAllClick();
  };

  const setAllClick = () => {
    setBackgroundColorForAll('var(--thom-green-primary2)');
    setBackgroundColorForOpen('#ECFFFE');
    setBackgroundColorForDue('#ECFFFE');
    setBackgroundColorForWarning('#ECFFFE');
    setBackgroundColorForOverdue('#ECFFFE');
    setBackgroundColorForCompleted('#ECFFFE');
    setTextColorForAll('var(--white)');
    setTextColorForOpen('#2a4241');
    setTextColorForDue('#2a4241');
    setTextColorForWarning('#2a4241');
    setTextColorForOverdue('#2a4241');
    setTextColorForCompleted('#2a4241');
  };

  const handleOpenClick = () => {
    setSelectedStatus('backend.task_status.open');
    setBackgroundColorForOpen('#017AFF');

    setBackgroundColorForAll('#ECFFFE');
    setBackgroundColorForDue('#ECFFFE');
    setBackgroundColorForWarning('#ECFFFE');
    setBackgroundColorForOverdue('#ECFFFE');
    setBackgroundColorForCompleted('#ECFFFE');
    setTextColorForAll('#2a4241');
    setTextColorForOpen('var(--white');
    setTextColorForDue('#2a4241');
    setTextColorForWarning('#2a4241');
    setTextColorForOverdue('#2a4241');
    setTextColorForCompleted('#2a4241');
  };

  const handleDueClick = () => {
    setSelectedStatus('backend.task_status.due');
    setBackgroundColorForAll('#ECFFFE');
    setBackgroundColorForOpen('#ECFFFE');
    setBackgroundColorForDue('#F96300');
    setBackgroundColorForWarning('#ECFFFE');
    setBackgroundColorForOverdue('#ECFFFE');
    setBackgroundColorForCompleted('#ECFFFE');
    setTextColorForAll('#2a4241');
    setTextColorForOpen('#2a4241');
    setTextColorForOpen('#2a4241');
    setTextColorForDue('var(--white)');
    setTextColorForWarning('#2a4241');
    setTextColorForOverdue('#2a4241');
    setTextColorForCompleted('#2a4241');
  };

  const handleWarningClick = () => {
    setSelectedStatus('backend.task_status.warning');
    setBackgroundColorForAll('#ECFFFE');
    setBackgroundColorForOpen('#ECFFFE');
    setBackgroundColorForDue('#ECFFFE');
    setBackgroundColorForWarning('#F9B803');
    setBackgroundColorForOverdue('#ECFFFE');
    setBackgroundColorForCompleted('#ECFFFE');
    setTextColorForAll('#2a4241');
    setTextColorForOpen('#2a4241');
    setTextColorForOpen('#2a4241');
    setTextColorForDue('#2a4241');
    setTextColorForWarning('var(--white)');
    setTextColorForOverdue('#2a4241');
    setTextColorForCompleted('#2a4241');
  };

  const handleOverdueClick = () => {
    setSelectedStatus('backend.task_status.overdue');
    setBackgroundColorForAll('#ECFFFE');
    setBackgroundColorForOpen('#ECFFFE');
    setBackgroundColorForDue('#ECFFFE');
    setBackgroundColorForWarning('#ECFFFE');
    setBackgroundColorForOverdue('#EB4C60');
    setBackgroundColorForCompleted('#ECFFFE');
    setTextColorForAll('#2a4241');
    setTextColorForOpen('#2a4241');
    setTextColorForOpen('#2a4241');
    setTextColorForDue('#2a4241');
    setTextColorForWarning('#2a4241');
    setTextColorForOverdue('var(--white)');
    setTextColorForCompleted('#2a4241');
  };

  const handleCompletedClick = () => {
    setSelectedStatus('backend.task_status.completed');
    setBackgroundColorForAll('#ECFFFE');
    setBackgroundColorForOpen('#ECFFFE');
    setBackgroundColorForDue('#ECFFFE');
    setBackgroundColorForWarning('#ECFFFE');
    setBackgroundColorForOverdue('#ECFFFE');
    setBackgroundColorForCompleted('#37D183');
    setTextColorForAll('#2a4241');
    setTextColorForOpen('#2a4241');
    setTextColorForOpen('#2a4241');
    setTextColorForDue('#2a4241');
    setTextColorForWarning('#2a4241');
    setTextColorForOverdue('#2a4241');
    setTextColorForCompleted('var(--white)');
  };

  // create a function to get the end date based on the selectedValue the Week dropdown
  const getEndDate = () => {
    const endDate = new Date();
    if (selectedValue === 'Week') {
      endDate.setDate(endDate.getDate() + 6);
    } else if (selectedValue === 'Month') {
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(endDate.getDate() - 1);
    } else if (selectedValue === 'All') {
      endDate.setFullYear(endDate.getFullYear() + 3);
      endDate.setDate(endDate.getDate() - 1);
    }
    // return end date in MM/dd/yyyy format
    return formatDate(endDate, 'MM/DD/YYYY');
  };

  const formatDate = (date: Date, format: string) => {
    return dayjs(date).format(format);
  };

  useEffect(() => {
    if (page === 0) {
      getTaskList(formatDate(new Date(), 'MM/DD/YYYY'), getEndDate(), 0);
    } else {
      setPage(0);
    }
  }, [selectedStatus, selectedValue, searchTerm]);

  const handleDelete = (id: string) => {
    toggleLoader(true);
    deleteTaskId(id)
      .then(() => {
        setToastrId('taskDeleteSuccessMessage');
        setToastrDefaultMessage('Deleted the task successfully');
        setShowAlertDialog(undefined);
        toggleLoader(false);
        if (page === 0) {
          getTaskList(formatDate(new Date(), 'MM/DD/YYYY'), getEndDate(), 0);
        } else {
          setPage(0);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          const response = error as ApiMessage;
          if (response.code === 400) {
            setToastrId('taskDeleteFailedMessage');
            setToastrDefaultMessage('Failed to delete the task');
          }
        }
      });
  };

  const getTaskList = (
    startDate: string,
    endDate: string,
    pageNumber: number,
  ) => {
    toggleLoader(true);
    const empId = localStorage.getItem('userId');
    if (empId === null) return;
    getTaskListAPI(
      pageNumber,
      10,
      sortData.orderBy,
      sortData.order,
      searchTerm,
      selectedStatus,
      startDate,
      endDate,
      empId,
      selectedValue === 'All',
    )
      .then((response: TaskListResponse) => {
        setTotalCount(response.totalTasks);
        setTask(response);
        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          const response = error as ApiMessage;
          if (response.code === 400) {
            setToastrId('taskFetchFailedMessage');
            setToastrDefaultMessage('Failed to get the tasks');
          }
        }
      });
  };

  useEffect(() => {
    getTaskList(formatDate(new Date(), 'MM/DD/YYYY'), getEndDate(), 0);
  }, []);

  useEffect(() => {
    getTaskList(formatDate(new Date(), 'MM/DD/YYYY'), getEndDate(), page);
  }, [page, sortData]);

  return (
    <Box component="main">
      {toastrId && (
        <SnackBarComponent
          open={toastrId !== undefined}
          handleClose={() => {
            setToastrId(undefined);
          }}
          labelId={toastrId}
          defaultMessageId={defaultlabel}
          successOrError={successOrError}
        />
      )}
      {showAlertDialog && (
        <ModalPopup
          open={showAlertDialog !== undefined}
          onCancel={() => {
            setShowAlertDialog(undefined);
          }}
          description="CommunicationLogTableView.deleteDescription"
          onOk={() => handleDelete(showAlertDialog)}
          labelId1="Clientpage.cancelbtn"
          negativeActionLabel="cancelText"
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="deleteText"
        />
      )}
      {openTaskManagerModal && (
        <TaskManagerAddModal
          open={openTaskManagerModal}
          taskResponse={taskResponse}
          handleClose={() => {
            setOpenTaskManagerModal(!openTaskManagerModal);
          }}
          onSuccess={() => {
            if (
              selectedStatus === allStatus ||
              selectedStatus === allStatusExceptCompleted
            ) {
              if (page === 0) {
                getTaskList(
                  formatDate(new Date(), 'MM/DD/YYYY'),
                  getEndDate(),
                  0,
                );
              } else {
                setPage(0);
              }
            } else {
              if (checkbox) {
                setSelectedStatus(allStatus);
              } else {
                setSelectedStatus(allStatusExceptCompleted);
              }
              setAllClick();
            }
          }}
          onMarkAsComplete={() => {
            getTaskList(
              formatDate(new Date(), 'MM/DD/YYYY'),
              getEndDate(),
              page,
            );
          }}
          onError={(errorId: string, errorMessage: string) => {
            setToastrId(errorId);
            setToastrDefaultMessage(errorMessage);
            setSuccessOrError('error');
          }}
        />
      )}
      <Box component="section">
        <Box className="rowContainer">
          <Grid container>
            <Grid item xs={4} lg={4}>
              <Box className="flex__ align__items__center">
                <TitleText
                  labelid="taskManagerTitleText"
                  defaultlabel="Task Manager"
                />
              </Box>
            </Grid>

            <Grid item xs={8} lg={8}>
              <Box className="flex__ justifyContent-FlexEnd">
                <SearchBox
                  labelId="taskManagerTaskSearch"
                  defaultlabel="Search Task"
                  className="mr-xlg"
                  onChange={(e) => {
                    setSearchTerm(e);
                  }}
                />
                <Box className="fitWidthContent headerButtonHeight mr-xlg">
                  <DropdownComponent
                    names={weekList}
                    value={selectedValue}
                    handleChange={(e) => {
                      setSelectedValue(e);
                    }}
                  />
                </Box>
                {checkPermissionForFeature(
                  'backend.task_manager',
                  'addPermission',
                ) &&
                  tasks &&
                  tasks.tasks.length > 0 && (
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      type="submit"
                      labelId="taskManagerAddText"
                      defaultLabelId="Add Task"
                      onClick={() => {
                        setTaskResponse(null);
                        setOpenTaskManagerModal(!openTaskManagerModal);
                      }}
                    />
                  )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="flex__ pt-none pb-md pl-none">
          <Box
            className="flex__ "
            alignItems={'center'}
            sx={{ bottom: '12px' }}
          >
            <Fab
              variant="extended"
              size="small"
              className="fabStyles"
              sx={{
                backgroundColor: backgroundColorForAll,
                color: textColorForAll,
                borderLeft: '5px solid #00C6B8',
                '&:hover': {
                  backgroundColor: backgroundColorForAll,
                  color: textColorForAll,
                },
              }}
              onClick={handleAllClick}
            >
              <MediumTypography
                labelid={'backend.task_status.all'}
                defaultlabel="All"
              />
              <Box className="pl-sm">
                {tasks && (
                  <MediumTypography
                    label={`${
                      tasks.completedStatusCount +
                      tasks.openStatusCount +
                      tasks.dueStatusCount +
                      tasks.warningStatusCount +
                      tasks.overdueStatusCount
                    }`}
                  />
                )}
              </Box>
            </Fab>
            <Fab
              variant="extended"
              size="small"
              className="fabStyles ml-sm"
              sx={{
                backgroundColor: backgroundColorForOpen,
                borderLeft: '5px solid #017AFF',
                color: textColorForOpen,
                '&:hover': {
                  backgroundColor: backgroundColorForOpen,
                  color: textColorForOpen,
                },
              }}
              onClick={handleOpenClick}
            >
              <MediumTypography
                labelid={'backend.task_status.open'}
                defaultlabel="Open"
              />
              <Box className="pl-sm">
                {tasks && (
                  <MediumTypography label={`${tasks.openStatusCount}`} />
                )}
              </Box>
            </Fab>
            <Fab
              variant="extended"
              size="small"
              className="fabStyles ml-sm"
              sx={{
                backgroundColor: backgroundColorForWarning,
                borderLeft: '5px solid #F9B803',
                color: textColorForWarning,
                '&:hover': {
                  backgroundColor: backgroundColorForWarning,
                  color: textColorForWarning,
                },
              }}
              onClick={handleWarningClick}
            >
              <MediumTypography
                labelid={'backend.task_status.warning'}
                defaultlabel="Warning"
              />
              <Box className="pl-sm">
                {tasks && (
                  <MediumTypography label={`${tasks.warningStatusCount}`} />
                )}
              </Box>
            </Fab>
            <Fab
              variant="extended"
              size="small"
              className="fabStyles ml-sm"
              sx={{
                backgroundColor: backgroundColorForDue,
                borderLeft: '5px solid #F96300',
                color: textColorForDue,
                '&:hover': {
                  backgroundColor: backgroundColorForDue,
                  color: textColorForDue,
                },
              }}
              onClick={handleDueClick}
            >
              <MediumTypography
                labelid={'backend.task_status.due'}
                defaultlabel="Due"
              />
              <Box className="pl-sm">
                {tasks && (
                  <MediumTypography label={`${tasks.dueStatusCount}`} />
                )}
              </Box>
            </Fab>
            <Fab
              variant="extended"
              size="small"
              className="fabStyles ml-sm"
              sx={{
                backgroundColor: backgroundColorForOverdue,
                borderLeft: '5px solid #EB4C60',
                color: textColorForOverdue,
                '&:hover': {
                  backgroundColor: backgroundColorForOverdue,
                  color: textColorForOverdue,
                },
              }}
              onClick={handleOverdueClick}
            >
              <MediumTypography
                labelid={'backend.task_status.overdue'}
                defaultlabel="Overdue"
              />
              <Box className="pl-sm">
                {tasks && (
                  <MediumTypography label={`${tasks.overdueStatusCount}`} />
                )}
              </Box>
            </Fab>
            <Fab
              variant="extended"
              size="small"
              className="fabStyles ml-sm"
              sx={{
                backgroundColor: backgroundColorForCompleted,
                borderLeft: '5px solid #37D183',
                color: textColorForCompleted,
                '&:hover': {
                  backgroundColor: backgroundColorForCompleted,
                  color: textColorForCompleted,
                },
              }}
              onClick={handleCompletedClick}
            >
              <MediumTypography
                labelid={'backend.task_status.completed'}
                defaultlabel="Completed"
              />
              <Box className="pl-sm">
                {tasks && (
                  <MediumTypography label={`${tasks.completedStatusCount}`} />
                )}
              </Box>
            </Fab>
            {(selectedStatus == allStatus ||
              selectedStatus == allStatusExceptCompleted) && (
              <Box className="flex__ ">
                <Box component="div" className="pl-md">
                  <CheckBoxComponent
                    defaultlabelid="Include Completed"
                    labelid="includeCompleted"
                    ischecked={checkbox}
                    CheckHandleChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                      if (e.target.checked) {
                        setSelectedStatus(allStatus);
                      } else {
                        setSelectedStatus(allStatusExceptCompleted);
                      }

                      setCheckbox(e.target.checked);
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {tasks && tasks.tasks.length === 0 && (
          <Box>
            <Card sx={{ height: '80vh', alignContent: 'center' }}>
              <GraphicBottomLeft className="GraphicBottomRightEmty " />

              <GraphicBottomRight className="GraphicBottomLeftEmtyTask " />

              <div className="textCenter">
                <Typography id="text-contact" sx={{ marginLeft: '20px' }}>
                  <FormattedMessage
                    id="noTasksFound"
                    defaultMessage="No Tasks Found"
                  />
                </Typography>
                {checkPermissionForFeature(
                  'backend.task_manager',
                  'addPermission',
                ) && (
                  <Custombuttonsubmit
                    id="contact-btn"
                    onClick={() => handleAdd(null)}
                  >
                    <FormattedMessage id="addTask" defaultMessage="Add Task" />
                  </Custombuttonsubmit>
                )}
              </div>
            </Card>
          </Box>
        )}
        {tasks && tasks.tasks.length > 0 && (
          <Box component="div">
            <Card>
              <TableContainer>
                <Table>
                  <TableHeader
                    className="listDataTableHead"
                    headerNames={headCells}
                    checkBoxRequired={true}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody className="tableRowcss">
                    {tasks.tasks.map((data, index: number) => {
                      return (
                        <TableRow
                          hover
                          onClick={() => {}}
                          key={index}
                          sx={{
                            cursor: 'pointer',
                            backgroundColor:
                              index % 2 === 0 ? '#ECF9F8' : '#ffffff',
                          }}
                        >
                          <TooltipTableCell
                            value={data.name ? data.name : '-'}
                            visible={true}
                            textSxProps={{ width: '100%', maxWidth: '200px' }}
                          />
                          <TooltipTableCell
                            className={
                              data.userType === 'Client' ? 'cursorPointer' : ''
                            }
                            onClick={() => {
                              if (
                                data.userType === 'Client' &&
                                data.functionalityId != null &&
                                data.clientId !== null
                              ) {
                                const taskClientId = data.clientId;
                                localStorage.setItem('ClientId', taskClientId);
                                localStorage.setItem(
                                  CLIENT_NAME_KEY,
                                  data.origin,
                                );
                                localStorage.setItem(
                                  REFERRAL_ID_KEY,
                                  data.functionalityId,
                                );
                                // if (
                                //   checkPermissionForFeature(
                                //     'backend.clients',
                                //     'viewPermission',
                                //   )
                                // ) {
                                //   navigation(FACESHEET, {
                                //     state: { taskClientId },
                                //   });
                                // }
                              }
                            }}
                            value={data.origin ? data.origin : '-'}
                            visible={true}
                          />
                          <TooltipTableCell
                            value={
                              data.clientName && data.clientName.trim() !== ''
                                ? data.clientName
                                : '-'
                            }
                            visible={true}
                          />
                          <TooltipTableCell
                            value={data.createdOn ? data.createdOn : '-'}
                            visible={true}
                          />

                          <TooltipTableCell
                            value={
                              data.dueDate
                                ? data.dueDate === ''
                                  ? '-'
                                  : data.dueDate
                                : '-'
                            }
                            visible={true}
                          />

                          <TooltipTableCell
                            className="cursorPointer"
                            localizationId={data.status}
                            textSxProps={{
                              ...(data.status ===
                              'backend.task_status.completed'
                                ? { color: '#008C82' }
                                : data.status === 'backend.task_status.open'
                                ? { color: '#017AFF' }
                                : data.status === 'backend.task_status.due'
                                ? { color: '#F96300' }
                                : data.status === 'backend.task_status.warning'
                                ? { color: '#F9B803' }
                                : data.status === 'backend.task_status.overdue'
                                ? { color: '#EB4C60' }
                                : { color: '#2A4241' }),
                            }}
                            visible={true}
                          />

                          <TableCell>
                            <Box className="flex__ alignItemCenter cursorPointer">
                              <Box>
                                <ViewEye onClick={() => handleAdd(data)} />
                              </Box>
                              {checkPermissionForFeature(
                                'backend.task_manager',
                                'deletePermission',
                              ) &&
                                data &&
                                data.taskType === 'Default' && (
                                  <Box className="ml-md cursorPointer">
                                    <DeleteIcon
                                      onClick={() =>
                                        setShowAlertDialog(data.id)
                                      }
                                    />
                                  </Box>
                                )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
            {totalCount > 10 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <AppPagination
                  pageNumber={page}
                  paginationCount={totalCount}
                  handleChangePage={(_event, newPage) => {
                    if (newPage - 1 !== page) {
                      setPage(newPage - 1);
                    }
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TaskManagerList;
