import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  AlertColor,
  Card,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { ReactComponent as EditIcon } from '../../assets/images/editvector.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';
import {
  MasterProviderType,
  OptionType,
  Order,
  ProviderList,
} from '../../utils/type';
import { FACESHEET, REFERRAL_TO_SSP } from '../../routes/Routing';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import SearchBox from '../../components/formlib/SearchBox';
import TitleText from '../../components/formlib/TitleText';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import SearchNotFound from '../../components/formlib/SearchNotFound';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import EmptyScreen from '../../components/shared/EmptyScreen';
import { debounce } from 'lodash';
import AppPagination from '../../components/shared/AppPagination';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import {
  ReferralSSP,
  SSPListGrid,
  deleteSSPGridLineItem,
  getAllSSPList,
  getSSPList,
} from '../../services/referralToSSPForm/ReferralToSSPApi';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import { GetMasterSSPProviderList } from '../../services/configApi/sspContactList/sspContact';
import MediumTypography from '../../components/formlib/MediumTypography';
import Close from '@mui/icons-material/Close';
import { Types } from '../../components/formlib/modal/ProviderList';
import dayjs from 'dayjs';
import CustomBackArrow from '../../components/imagepickers/backArrow';

interface Data2 {
  firstName: string;
  organizationName: string;
  jobTitle: string;
  type: string;
  email: string;
  phoneNumber: string;
}

const headCells2: HeadCell<Data2>[] = [
  {
    id: 'firstName',
    labelId: 'ssp.providerName',
    defaultLabelId: 'Provider Name',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'organizationName',
    labelId: 'ssp.organizationName',
    defaultLabelId: 'Organization Name',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'jobTitle',
    labelId: 'ssp.jobTitle',
    defaultLabelId: 'Job Title',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'type',
    labelId: 'ssp.type',
    defaultLabelId: 'Type',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'email',
    labelId: 'AddNewReferralDetails.Email',
    defaultLabelId: 'Email',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'phoneNumber',
    labelId: 'Contacts.phone',
    defaultLabelId: 'Phone Number',
    numeric: false,
    requiredSorting: false,
  },
];

const initialHeadCells: HeadCell<Data>[] = [
  {
    id: 'dateOfReferral',
    labelId: 'sspList.dateOfreferral',
    defaultLabelId: 'Date of Referral',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'referralFor',
    labelId: 'sspList.referralfor',
    defaultLabelId: 'Referral For',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'agency',
    labelId: 'sspList.agency',
    defaultLabelId: 'Agency',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'ssp',
    labelId: 'sspList.ssp',
    defaultLabelId: 'SSP',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'actions',
    labelId: 'actions',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
];

export type Data = {
  dateOfReferral: string;
  referralFor: string;
  agency: string;
  ssp: string;
  actions: string;
};

const SSPRepository = () => {
  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const [orderBy, setOrderBy] = useState<keyof Data | string>('');
  const [orderByProviders, setOrderByProviders] = useState<
    keyof Data2 | string
  >('');
  const [order, setOrder] = useState<Order>('desc');
  const [orderSSP, setOrderSSP] = useState<Order>('desc');
  const [searchText, setSearchText] = useState('');
  const [showEmptyScreen, setShowEmptyScreen] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [responseDetailsCount, setResponseDetailsCount] = useState<number>(0);
  const [providerDetailsCount, setProvidersDetailsCount] = useState<number>(0);
  const [sspList, setSSPList] = useState<ReferralSSP[]>([]);
  const [headCells] = useState(initialHeadCells);
  const [sspProviderTabelFlag, setSspProviderTabelFlag] = useState(false);
  const [providerList, setProviderList] = useState<ProviderList[]>([]);
  const [onsearchquery] = useState('');
  const [searchquery, setSearchquery] = useState('');
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const navigate = useNavigate();

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage - 1 !== page) {
      setPage(newPage - 1);
      setSSPList([]);
      setResponseDetailsCount(0);
    }
  };

  const handleChangePage2 = (event: unknown, newPage: number) => {
    if (newPage - 1 !== page2) {
      setPage2(newPage - 1);
      setProviderList([]);
      setProvidersDetailsCount(0);
      toggleLoader(true);
      GetMasterSSPProviderList(
        newPage - 1,
        5,
        orderSSP,
        orderByProviders,
        onsearchquery,
      )
        .then((response: MasterProviderType) => {
          if (response) {
            toggleLoader(false);
            setProviderList(response.providers);
            setProvidersDetailsCount(response.providerCount);
          }
        })
        .catch(() => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          setToastrId('failedApiMsg');
          setToastrDefaultMessage(
            'Oops, something went wrong. Please try again later.',
          );
        });
    }
  };

  useEffect(() => {
    toggleLoader(true);
    getSSPListGGrid();
  }, [page, order, orderBy, searchText]);

  const getSSPListGGrid = () => {
    getAllSSPList(page, 10, order, searchText, orderBy)
      .then((response: SSPListGrid) => {
        const { sspNotificationList } = response;
        setSSPList(sspNotificationList);
        setResponseDetailsCount(response.count);
        toggleLoader(false);
        if (response.count === 0 && page === 0 && searchText === '') {
          setShowEmptyScreen(true);
        } else {
          setShowEmptyScreen(false);
        }
      })
      .catch((error) => {
        setOpen(true);
        toggleLoader(false);
        setToastrVariable('error');
        setToastrId('formBuilder.ListLoadFail');
        setToastrDefaultMessage(error.message);
      });
  };

  const CustomTableCell = styled(TableCell)(() => ({
    fontFamily: 'Lato-Regular',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    padding: '14px 16px',
  }));

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const localTimezone = dayjs.tz.guess();
  useEffect(() => {
    toggleLoader(true);
    getSSPList(localTimezone)
      .then((data) => {
        const sspAgency: OptionType[] = [];
        data.organization.forEach((agency) => {
          sspAgency.push({
            id: agency.organizationId,
            label: `${agency.organizationName}`,
          });
          return sspAgency;
        });
      })
      .catch((error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('loadFailureMessage');
          setToastrDefaultMessage('loadFailureMessage');
        }
      });
  }, []);

  const deleteRowItem = (id: string) => {
    deleteSSPGridLineItem(id)
      .then((response) => {
        setOpen(true);
        setToastrId(response.message);
        setToastrDefaultMessage(response.message);
        setToastrVariable('success');
        getSSPListGGrid();
      })
      .catch((error) => {
        setOpen(true);
        setToastrId(error.message);
        setToastrDefaultMessage(error.message);
        setToastrVariable('error');
      });
  };

  const isValidInput = (value: string) => {
    const allowedCharacters = /^[a-zA-Z0-9,'.\s!-]*$/;
    return allowedCharacters.test(value);
  };

  const changeTextDebouncer = useCallback(
    debounce((value: string) => {
      if (isValidInput(value)) {
        setPage(0);
        setSearchText(value);
      } else {
        setToastrVariable('error');
        setToastrId('Dashboard.ClientList.searchError');
      }
    }, 500),
    [],
  );

  const handleSSPProviders = () => {
    getMasterProvider();
    setSspProviderTabelFlag(true);
  };

  const getMasterProvider = () => {
    toggleLoader(true);
    GetMasterSSPProviderList(
      page2,
      5,
      orderSSP,
      orderByProviders,
      onsearchquery,
    )
      .then((response: MasterProviderType) => {
        if (response) {
          toggleLoader(false);
          setProviderList(response.providers);
          setProvidersDetailsCount(response.providerCount);
        }
      })
      .catch(() => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        setToastrId('failedApiMsg');
        setToastrDefaultMessage(
          'Oops, something went wrong. Please try again later.',
        );
      });
  };

  const handleCloses = () => {
    setSspProviderTabelFlag(false);
  };

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      if (isValidInput(value)) {
        setSearchquery(value);
        toggleLoader(true);
        GetMasterSSPProviderList(page2, 5, order, orderByProviders, value)
          .then((response) => {
            if (response) {
              toggleLoader(false);
              setProviderList(response.providers);
              setProvidersDetailsCount(response.providerCount);
            }
          })
          .catch(() => {
            toggleLoader(false);
            setOpen(true);
            setToastrVariable('error');
            setToastrId('failedApiMsg');
            setToastrDefaultMessage(
              'Oops, something went wrong. Please try again later.',
            );
          });
      } else {
        setOpen(true);
        setToastrVariable('error');
        setToastrId('Dashboard.ClientList.searchError');
        setToastrDefaultMessage(
          'Only alphanumeric characters, commas, periods, spaces, exclamation marks, and hyphens are allowed to be searched.',
        );
      }
    }, 500),
    [],
  );

  const handleRequestSort2 = (
    event: React.MouseEvent<unknown>,
    property: keyof Data2,
  ) => {
    setOrderSSP(orderSSP === 'asc' ? 'desc' : 'asc');
    setOrderByProviders(property);
    toggleLoader(true);
    GetMasterSSPProviderList(
      page2,
      5,
      orderSSP === 'asc' ? 'desc' : 'asc',
      property,
      onsearchquery,
    )
      .then((response) => {
        if (response) {
          toggleLoader(false);
          setProviderList(response.providers);
          setProvidersDetailsCount(response.providerCount);
        }
      })
      .catch(() => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        setToastrId('failedApiMsg');
        setToastrDefaultMessage(
          'Oops, something went wrong. Please try again later.',
        );
      });
  };

  return (
    <Box component="main">
      <Box component="section">
        <SnackBarComponent
          open={open}
          handleClose={() => setOpen(false)}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />

        <Box className="rowContainer">
          <Grid container>
            <Grid item xs={4} lg={4}>
              <Box className="flex__ alignItemCenter">
                <CustomBackArrow onClick={() => navigate(FACESHEET)} />
                <TitleText
                  labelid="referralToSSPList"
                  defaultlabel="SSP List"
                  className="ml-md"
                />
              </Box>
            </Grid>
            <Grid item xs={8} lg={8}>
              <Box
                component="div"
                className="flex__ text-align-end justifyContent-FlexEnd"
              >
                <ButtonComponent
                  className="btn-primary btn-cancel mr-md"
                  variantType="contained"
                  labelId="ssp.providers"
                  defaultLabelId="SSP Providers"
                  onClick={() => {
                    handleSSPProviders();
                  }}
                />
                <SearchBox
                  labelId="ssp.listSearch"
                  defaultlabel="Search SSP"
                  onChange={changeTextDebouncer}
                />
                {checkPermissionForFeature(
                  'backend.clients',
                  'editPermission',
                ) &&
                  sspList.length >= 1 && (
                    <ButtonComponent
                      className="btn-primary btn-submit ml-md"
                      variantType="contained"
                      type="submit"
                      labelId="referralToSSP.AddNew"
                      onClick={() =>
                        navigate(REFERRAL_TO_SSP, {
                          state: {
                            id: '',
                          },
                        })
                      }
                      disabled={false}
                      defaultLabelId="Add new"
                    />
                  )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        {searchText != '' && sspList && sspList.length === 0 && (
          <SearchNotFound />
        )}
        <Box component="div">
          {sspList && sspList.length !== 0 && (
            <Card>
              <TableContainer>
                <Table>
                  <TableHeader
                    className="listDataTableHead"
                    headerNames={headCells}
                    checkBoxRequired={true}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />

                  <TableBody className="tableRowcss">
                    {sspList &&
                      sspList.map((row) => {
                        return (
                          <TableRow
                            hover
                            key={row.id}
                            tabIndex={-1}
                            sx={{
                              cursor: 'pointer',
                              alignContent: 'flex-start',
                              '&:nth-of-type(odd)': {
                                backgroundColor: '#ECF9F8',
                              },
                            }}
                          >
                            <TooltipTableCell
                              value={row.dateOfReferral}
                              visible={true}
                            />
                            <TooltipTableCell
                              value={
                                row.referralFor.charAt(0).toUpperCase() +
                                row.referralFor.slice(1)
                              }
                              visible={true}
                            />
                            <TooltipTableCell
                              value={row.agency ? row.agency : '-'}
                              visible={true}
                            />
                            <TooltipTableCell
                              value={row.ssp ? row.ssp : '-'}
                              visible={true}
                            />
                            <CustomTableCell className="ellipsis">
                              <Box className="flex__ justifyContent-FlexStart alignItemCenter">
                                <Box className="cursorPointer mr-md">
                                  <Tooltip
                                    id="tool-tip"
                                    title={'Edit'}
                                    placement="bottom-start"
                                  >
                                    <EditIcon
                                      onClick={() =>
                                        navigate(REFERRAL_TO_SSP, {
                                          state: {
                                            id: row.id,
                                          },
                                        })
                                      }
                                    />
                                  </Tooltip>
                                </Box>
                                {checkPermissionForFeature(
                                  'backend.clients',
                                  'editPermission',
                                ) && (
                                  <Box className="cursorPointer">
                                    <Tooltip
                                      id="tool-tip"
                                      title={'Delete'}
                                      placement="bottom-start"
                                    >
                                      <DeleteIcon
                                        onClick={() => {
                                          deleteRowItem(row.id);
                                        }}
                                      />
                                    </Tooltip>
                                  </Box>
                                )}
                              </Box>
                            </CustomTableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </Box>
        {showEmptyScreen && (
          <EmptyScreen
            titleLabelId="formBuilder.EmptyFormListResponse"
            defaultTitleText="No forms added yet."
            buttonLabelId="referralToSSP.AddNew"
            defaultButtonTitleText="Add New"
            showButton={true}
            onButtonClick={() => {
              navigate(REFERRAL_TO_SSP, {
                state: {
                  id: '',
                },
              });
            }}
          />
        )}
      </Box>

      {responseDetailsCount > 10 && (
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <AppPagination
              pageNumber={page}
              paginationCount={responseDetailsCount}
              handleChangePage={handleChangePage}
            />
          </Box>
        </Grid>
      )}

      <Dialog
        open={sspProviderTabelFlag}
        onClose={handleCloses}
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle>
          <Grid container sx={{ justifyContent: 'space-between' }}>
            <Grid item>
              <Box>
                <TitleText
                  Sxprops={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    cursor: 'pointer',
                  }}
                  label={'SSP Provider List'}
                />
              </Box>
            </Grid>

            <Grid item className="text-align-end flex__ alignItemCenter">
              <SearchBox
                className="mr-md"
                labelId="provider.searchText"
                defaultlabel="Search SSP Provider"
                sxProps={{ minWidth: '250px' }}
                backgroundColor="rgba(231, 240, 240, 1)"
                onChange={handleSearchChange}
              />
              <Close className="cursorPointer" onClick={handleCloses} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Card>
              <TableContainer>
                <Table>
                  <TableHeader
                    className="listDataTableHead"
                    headerNames={headCells2}
                    checkBoxRequired={true}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort2}
                  />
                  {providerList?.map((ssp: ProviderList, sspIndex: number) => {
                    return (
                      <TableBody className="tableRowcss">
                        <TableRow
                          hover
                          onClick={() => {}}
                          key={ssp.providerId}
                          sx={{
                            cursor: 'pointer',
                            backgroundColor:
                              sspIndex % 2 === 0 ? '#ECF9F8' : '#ffffff',
                          }}
                        >
                          <TooltipTableCell
                            value={`${ssp.firstName}  ${ssp.lastName}`}
                            visible={true}
                          />
                          <TooltipTableCell
                            value={`${ssp.organizationName}`}
                            visible={true}
                          />
                          <TooltipTableCell
                            value={ssp.jobTitle ? ssp.jobTitle : '-'}
                            visible={true}
                          />
                          <TooltipTableCell
                            value={
                              ssp.type
                                ? Types.find((t) => t.id === ssp.type)?.label
                                : '-'
                            }
                            visible={true}
                          />
                          <TooltipTableCell
                            value={ssp.email ? ssp.email : '-'}
                            visible={true}
                          />
                          <TooltipTableCell
                            value={ssp.phone ? ssp.phone : '-'}
                            visible={true}
                          />
                        </TableRow>
                      </TableBody>
                    );
                  })}
                </Table>
              </TableContainer>
              {providerDetailsCount > 5 && (
                <Grid item>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <AppPagination
                      pageNumber={page2}
                      perPageCount={5}
                      paginationCount={providerDetailsCount}
                      handleChangePage={handleChangePage2}
                    />
                  </Box>
                </Grid>
              )}
            </Card>
            {searchquery !== '' &&
              providerList &&
              providerList.length === 0 && (
                <Box className="bg__white">
                  <MediumTypography
                    textstyle={'center'}
                    labelid="provider.nosearchText"
                    defaultlabel="No Search Found"
                    paddingstyle="16px"
                  />
                </Box>
              )}
            {searchquery === '' &&
              providerList &&
              providerList.length === 0 && (
                <Box className="bg__white">
                  <MediumTypography
                    textstyle={'center'}
                    labelid="provider.noData"
                    defaultlabel="No SSP Added"
                    paddingstyle="16px"
                  />
                </Box>
              )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SSPRepository;
