import React from 'react';
import { Box } from '@mui/system';
import { ReactComponent as ArrowRight } from '../../assets/images/right.svg';
import TitleText from '../../components/formlib/TitleText';
import SmallTypography from '../../components/formlib/SmallTypography';
import { CommunicationLog } from '../../utils/type';
import { useNavigate } from 'react-router';
import { CLIENTS_ROUTE_NAME } from '../../routes/Routing';
import { LOCAL_DATE_TIME_FORMAT } from '../../services/Constant';
import moment from 'moment';
import MediumTypography from '../../components/formlib/MediumTypography';

interface CommunicationLogType {
  data: CommunicationLog[];
}

const CommunicationFaceSheet: React.FC<CommunicationLogType> = (props) => {
  const navigation = useNavigate();

  return (
    <>
      <Box
        sx={{
          float: 'right',
          position: 'relative',
          cursor: 'pointer',
        }}
      >
        <ArrowRight
          onMouseDown={(e) => e.stopPropagation}
          onTouchStart={(e) => e.stopPropagation}
          onClick={() => {
            sessionStorage.setItem('tabName', '4');
            const clientId = localStorage.getItem('ClientId');
            navigation(CLIENTS_ROUTE_NAME, {
              state: { clientId: clientId, isCreate: true },
            });
          }}
        />
      </Box>
      <TitleText
        labelid="Facesheet.communicationLog"
        defaultlabel="Communication Log"
        Sxprops={{ fontSize: '18px' }}
      />
      {props.data.length === 0 && (
        <SmallTypography
          sxProps={{
            fontSize: '18px',
            color: '#97A6A5',
            textAlign: 'center',
            marginY: 5,
          }}
          labelId="CommunicationLogTableView.emptyMessage"
          defaultLabelId="No logs added yet"
        />
      )}
      {props.data.map((communicationProps: CommunicationLog) => {
        return (
          <Box
            sx={{ marginY: '16px' }}
            key={communicationProps.communicationLogId}
          >
            <SmallTypography
              labelId="CommunicationLogPage.contactedBy"
              sxProps={{
                fontSize: '10px',
                color: '#97A6A5',
                fontWeight: '500',
                margin: 0,
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <MediumTypography
                label={communicationProps.contactedBy}
                textColor="#2A4241"
                fontweight={600}
              />
              <MediumTypography
                label={moment(
                  communicationProps.createdOn,
                  'YYYY-MM-DD HH:mm:ss',
                )
                  .utc(true)
                  .local()
                  .format(LOCAL_DATE_TIME_FORMAT)}
                textColor="#2A4241"
                fontweight={600}
              />
            </Box>
            {props.data.length - 1 !==
              props.data.indexOf(communicationProps) && (
              <Box className="horizontalLine" sx={{ marginY: '8px' }} />
            )}
          </Box>
        );
      })}
    </>
  );
};

export default CommunicationFaceSheet;
