import {
  AlertColor,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import { Box } from '@mui/system';
import TitleText from '../../components/formlib/TitleText';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import ProfileBlockTimeTableRow from './ProfileBlockTimeTableRow';
import React, { useContext, useEffect, useState } from 'react';
import ProfileBlockTimeModal from './ProfileBlockTimeModal';
import {
  BlockTimeData,
  BlockTimeDataList,
  addEmployeeBlockTime,
  deleteEmployeeBlockTime,
  getEmployeeBlockTime,
  updateEmployeeBlockTime,
} from '../../services/configApi/employees/employeeServices';
import { STORAGE_USER_ID_KEY } from '../../services/Constant';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import moment from 'moment';
import MediumTypography from '../../components/formlib/MediumTypography';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import dayjs from 'dayjs';
import SelectComponent from '../../components/formlib/SelectComponent';
import ModalPopup from '../../components/formlib/ModalPopup';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import AppPagination from '../../components/shared/AppPagination';
import { checkPermissionForFeature } from '../../utils/checkPermission';

interface Data {
  blockType: string;
  startAndEndDate: string;
  startAndEndTime: string;
  note: string;
  actions: string;
}

const initialHeadCells: HeadCell<Data>[] = [
  {
    id: 'blockType',
    labelId: 'TableBlock.blockingType',
    defaultLabelId: 'Blocking Type',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'startAndEndDate',
    labelId: 'TableBlock.startAndEndDate',
    defaultLabelId: 'Start and End Date',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'startAndEndTime',
    labelId: 'TableBlock.startAndEndTime',
    defaultLabelId: 'Start and End Time',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'note',
    labelId: 'BlockTime.note',
    defaultLabelId: 'Note',
    numeric: false,
    requiredSorting: false,
  },
];

const ProfileBlockTime = () => {
  const currentYear = moment().year();
  const fiveYearsAgo = moment().subtract(5, 'years').year();
  const yearsArray: string[] = [];
  const [page, setPage] = useState<number>(0);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [yearValue, setYearValue] = useState<string>(currentYear.toString());
  const [showBlockTimeModel, setShowBlockTimeModel] = useState<boolean>(false);
  const [blockTimes, setBlockTimes] = useState<BlockTimeDataList>({
    blockTimeDetails: [],
    totalBlockTimeCount: 0,
  });
  const [deleteBlockTime, setDeleteBlockTime] = useState<BlockTimeData | null>(
    null,
  );
  const [editRowData, setEditRowData] = useState<BlockTimeData>();
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [headCells, setHeadCells] = useState(initialHeadCells);
  const [toastrId, setToastrId] = React.useState('');
  const { loader, toggleLoader } = useContext(
    LoaderContext,
  ) as LoaderContextType;

  useEffect(() => {
    getBlockTime();
  }, [page, yearValue]);

  useEffect(() => {
    if (
      (checkPermissionForFeature('backend.block_time', 'editPermission') ||
        checkPermissionForFeature('backend.block_time', 'deletePermission')) &&
      headCells.length === 4
    ) {
      setHeadCells((prev) => [
        ...prev,
        {
          id: 'actions',
          labelId: 'BlockTime.actions',
          defaultLabelId: 'Actions',
          numeric: false,
          requiredSorting: false,
        },
      ]);
      return () => {
        setHeadCells(initialHeadCells);
      };
    }
  }, []);

  const onClose = () => {
    setShowBlockTimeModel(false);
  };

  const handleBlockTime = () => {
    setEditRowData(undefined);
    setShowBlockTimeModel(true);
  };

  const handleEditClick = (rowData: BlockTimeData) => {
    setEditRowData(rowData);
    setShowBlockTimeModel(true);
  };

  const getBlockTime = () => {
    toggleLoader(true);
    const employeeId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (employeeId === null) {
      return null;
    }
    getEmployeeBlockTime(employeeId, page, yearValue)
      .then((response) => {
        toggleLoader(false);
        setBlockTimes({
          blockTimeDetails: response.blockTimeDetails,
          totalBlockTimeCount: response.totalBlockTimeCount,
        });
        setOpen(true);
        setToastrVariable('success');
      })
      .catch((error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('BlockTime.getData');
          setToastrDefaultMessage(error);
        }
      });
  };

  const handlesubmit = (requestBody: BlockTimeData) => {
    const employeeId = localStorage.getItem(STORAGE_USER_ID_KEY);
    toggleLoader(true);
    if (employeeId === null) {
      return;
    }
    const utcStartDateTime = moment(
      `${requestBody.startDate} ${requestBody.startTime}`,
      'MM/DD/YYYY HH:mm',
    ).utc();
    const utcStartDateEndTime = moment(
      `${requestBody.startDate} ${requestBody.endTime}`,
      'MM/DD/YYYY HH:mm',
    ).utc();
    requestBody.startDate = utcStartDateTime.format('MM/DD/YYYY');
    requestBody.startTime = utcStartDateTime.format('HH:mm');

    const utcEndDateTime = moment(
      `${requestBody.endDate} ${requestBody.endTime}`,
      'MM/DD/YYYY HH:mm',
    ).utc();
    requestBody.endDate = utcEndDateTime.format('MM/DD/YYYY');
    requestBody.endTime = utcEndDateTime.format('HH:mm');

    const diffMin = utcStartDateEndTime.diff(utcStartDateTime, 'minutes', true);
    const duration = moment
      .utc(moment.duration(diffMin, 'minutes').asMilliseconds())
      .format('HH:mm');
    requestBody.duration = duration;

    requestBody.occurrenceDates = [];
    const startDate = moment(utcStartDateTime).local();
    const endDate = moment(utcEndDateTime).local();
    while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
      if (
        requestBody.occurrenceDays.includes(
          startDate.format('dddd').toUpperCase(),
        )
      ) {
        requestBody.occurrenceDates.push({
          start: dayjs.utc(startDate.format('MM/DD/YYYY HH:mm')).format(),
          end: dayjs
            .utc(startDate.format('MM/DD/YYYY HH:mm'))
            .add(diffMin, 'minutes')
            .format(),
        });
      }
      startDate.add(1, 'days');
    }

    if (requestBody.blockTimeId === '') {
      addEmployeeBlockTime(employeeId, requestBody)
        .then(() => {
          getBlockTime();
          onClose();
          setToastrId('BlockTime.addToastMsg');
          setToastrDefaultMessage('Block Time added successfully');
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('BlockTime.addToastError');
            setToastrDefaultMessage('Failed to add block time');
          }
        });
    } else {
      updateEmployeeBlockTime(requestBody)
        .then(() => {
          getBlockTime();
          onClose();
          setToastrId('BlockTime.updateToastMsg');
          setToastrDefaultMessage('Block Time updated successfully');
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('BlockTime.updateToastError');
            setToastrDefaultMessage('Failed to update block time');
          }
        });
    }
  };
  const handleDeleteBlockTime = (blockingTimeId: string) => {
    toggleLoader(true);

    deleteEmployeeBlockTime(blockingTimeId)
      .then(() => {
        getBlockTime();
        setToastrId('BlockTime.deleteToastMsg');
        setToastrDefaultMessage('Block Time deleted successfully');
      })
      .catch((error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('BlockTime.deleteToastError');
          setToastrDefaultMessage('Failed to delete block time');
        }
      });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const getYears = () => {
    for (let year = fiveYearsAgo; year <= currentYear; year++) {
      if (year >= 2022 && year > fiveYearsAgo) {
        yearsArray.push(year.toString());
      }
    }
    return yearsArray;
  };

  const years = getYears();

  return (
    <>
      {confirmationModal && (
        <ModalPopup
          open={confirmationModal}
          description="BlockTime.deleteConfirmationMessage"
          onCancel={() => setConfirmationModal(false)}
          onOk={() => {
            if (deleteBlockTime && deleteBlockTime.blockTimeId !== undefined) {
              handleDeleteBlockTime(deleteBlockTime.blockTimeId);
            }
            setDeleteBlockTime(null);
            setConfirmationModal(false);
          }}
          labelId1="BlockTime.No"
          negativeActionLabel="No"
          labelId2="BlockTime.Yes"
          positiveActionLabel="Yes"
        />
      )}
      {toastrId && (
        <SnackBarComponent
          open={open}
          handleClose={handleClose}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />
      )}

      <Box paddingTop={'16px'}>
        <Card
          sx={{
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
          }}
        >
          <CardContent
            sx={{
              padding: '16px 16px 0px 16px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TitleText
                labelid="BlockTime.blockType"
                defaultlabel="Block Time"
                Sxprops={{
                  fontSize: '16px',
                  fontWeight: 700,
                  color: '#2A4241',
                  textAlign: 'left',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  flexGrow: 1,
                }}
              >
                <Box>
                  <SelectComponent
                    value={yearValue}
                    handleChange={(value) => {
                      setToastrDefaultMessage('');
                      setToastrId('');
                      setYearValue(value);
                      setPage(0);
                    }}
                    names={years}
                    height="32px"
                  />
                </Box>
                {checkPermissionForFeature(
                  'backend.block_time',
                  'addPermission',
                ) && (
                  <ButtonComponent
                    className="btn-primary btn-submit ml-md"
                    variantType="contained"
                    type="submit"
                    labelId="BlockTime.blockTypeButton"
                    defaultLabelId="Add Block Time"
                    onClick={() => {
                      handleBlockTime();
                    }}
                  />
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>

      {showBlockTimeModel && (
        <ProfileBlockTimeModal
          open={showBlockTimeModel}
          handleClose={onClose}
          handlesubmit={handlesubmit}
          rowData={editRowData}
        />
      )}

      <TableContainer component={Paper}>
        <Table aria-label="simple table" className="profileTable">
          <TableHeader
            className="listDataTableHead"
            headerNames={headCells}
            checkBoxRequired={false}
          />
          <TableBody className="tableRowcss">
            {blockTimes.blockTimeDetails.map((item, index) => (
              <ProfileBlockTimeTableRow
                key={index}
                data={item}
                onDelete={() => {
                  if (item.blockTimeId !== undefined) {
                    setDeleteBlockTime(item);
                    setConfirmationModal(true);
                  }
                }}
                onEdit={(blockTime) => handleEditClick(blockTime)}
              />
            ))}
          </TableBody>
        </Table>
        {blockTimes.totalBlockTimeCount === 0 && !loader && (
          <Box sx={{ width: '100%' }}>
            <MediumTypography
              textstyle={'center'}
              labelid="BlockTime.emptyMessage"
              defaultlabel="No data added yet"
              paddingstyle="12px"
            />
          </Box>
        )}
      </TableContainer>
      {blockTimes.totalBlockTimeCount > 5 && (
        <Card
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <AppPagination
            pageNumber={page}
            perPageCount={5}
            paginationCount={blockTimes.totalBlockTimeCount}
            handleChangePage={(_event, newPage) => {
              if (newPage - 1 !== page) {
                setPage(newPage - 1);
              }
            }}
          />
        </Card>
      )}
    </>
  );
};

export default ProfileBlockTime;
