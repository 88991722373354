import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Wrapper from './LanguageWrapper';
import en from './languagelocalisation/en-us.json';
import fr from './languagelocalisation/fr.json';
import { BrowserRouter } from 'react-router-dom';
import { LookUpContextProvider } from './context/LookUpContextProvider';
import { initDB } from './services/storageDB';

export default {
  en,
  fr,
};
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

initDB()
  .then(async () => {
    console.log('DB initialized');
    document.cookie =
      'googtrans=; path=/; domain=' +
      window.location.hostname +
      '; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    root.render(
      <div className="notranslate" translate="no">
        <BrowserRouter>
          <Wrapper>
            <LookUpContextProvider>
              <App />
            </LookUpContextProvider>
          </Wrapper>
        </BrowserRouter>
      </div>,
    );
  })
  .catch((error) => {
    console.log('DB initialized', error);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
