import {
  AlertColor,
  Box,
  Card,
  Dialog,
  DialogContent,
  Grid,
} from '@mui/material';
import TitleText from '../../components/formlib/TitleText';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as DownArrow } from '../../assets/images/downarrowcolored.svg';
import { ReactComponent as UpArrow } from '../../assets/images/uparrowcolored.svg';
import { ReactComponent as GoTo } from '../../assets/images/goTo.svg';
import { ReactComponent as EditComponent } from '../../assets/images/Edit.svg';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import CustomcardComponent from '../../components/formlib/CustomCardComponent';
import { Formik, FormikErrors, FormikProps } from 'formik';
import {
  BDI2Data,
  Examiner,
  OptionType,
  employeeRoles,
} from '../../utils/type';
import * as Yup from 'yup';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import {
  EmployeeListType,
  getAllEmployeeMinimalList,
} from '../../services/ifspClient/IfspApi';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import Textinput from '../../components/formlib/Textinput';
import { useIntl } from 'react-intl';
import {
  addAssesment,
  editAssesment,
  getEvaluationData,
  uploadTBR,
} from '../../services/configApi/BDI2/BDI2Services';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { ReactComponent as TrashIcon } from '../../assets/images/trashIcon.svg';
import {
  CLINICAL_JUDGEMENT_ROUTE,
  ELIGIBILITY_DETERMINATION,
  FACESHEET,
  RISK_FACTOR,
} from '../../routes/Routing';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import { useNavigate } from 'react-router';
import EmptyScreen from '../../components/shared/EmptyScreen';
import moment from 'moment';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import ModalPopup from '../../components/formlib/ModalPopup';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';
import { ReactComponent as InfoIcon } from '../../assets/images/infoIcon.svg';
import InformationModal from '../ifsp/InformationModal';
import CloseIcon from '../../assets/images/x.svg';
import { debounce } from 'lodash';
import { datePickerMinDateMaxDateValidate } from '../../utils/dateUtil';

const initialValuess: BDI2Data = {
  completed: false,
  dateOfAssessment: null,
  status: '',
  type: '',
  examiners: [
    {
      userId: '',
      role: '',
    },
  ],
  subDomainsResults: {
    selfCareScore: '',
    personalResponsibilityScore: '',
    adultInteractionScore: '',
    peerInteractionScore: '',
    selfConceptSocialRoleScore: '',
    respectiveCommunicationScore: '',
    expressiveCommunicationScore: '',
    grossMotorScore: '',
    fineMotorScore: '',
    perceptualMotorScore: '',
    attentionMemoryScore: '',
    reasoningAcademicSkillsScore: '',
    perceptionConceptsScore: '',
  },
  developmentQuotientResults: {
    adaptiveDQScore: '',
    personalSocialDQScore: '',
    communicationDQScore: '',
    motorDQScore: '',
    cognitiveDQScore: '',
  },
};

const OthersFormsList = () => {
  const navigate = useNavigate();
  const rctl = useIntl();
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [personalRespScoreNotApplicable, setPersonalRespScoreNotApplicable] =
    useState<boolean>(false);
  const [
    perceptualMotorScoreNotApplicable,
    setPerceptualMotorScoreNotApplicable,
  ] = useState<boolean>(false);
  const [peerInteractionNotAplicable, setPeerInteractionNotAplicable] =
    useState<boolean>(false);
  const [reasoningScoreNotApplicable, setReasoningScoreNotApplicable] =
    useState<boolean>(false);
  const formRef = useRef<FormikProps<BDI2Data>>(null);
  const [initialValues, setInitialValues] = useState<BDI2Data>(initialValuess);
  const [editData, setEditData] = useState<string>('');
  const [uploadedToTBR, setUploadedToTBR] = useState<boolean | undefined>(
    false,
  );
  const [othersContacts, setOthersContacts] = useState<OptionType[]>([]);
  const [evaluationData, setEvaluationData] = useState<BDI2Data[]>([]);
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState<string>('');
  const [toastrId, setToastrId] = useState<string>('Client.addtoastr');
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [open, setOpen] = useState<boolean>(false);
  const [editFlag, setEditFlag] = useState<boolean>(false);
  const [uploadToTBRModal, setUploadToTBRModal] = useState<boolean>(false);
  const [evaluationID, setEvaluationID] = useState<string | undefined>('');
  const { lookups } = useContext(LookUpContext) as LookUpContextType;
  const [showEmptyScreen, setShowEmptyScreen] = useState(false);
  const [employeeList, setEmployeeList] = useState<EmployeeListType[]>([
    {
      id: '',
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      disciplineType: '',
      roles: [],
    },
  ]);
  const [openAccordians, setOpenAccordians] = useState<number[]>([]);
  const [infoModal, setInfoModal] = useState(false);

  const handleOpenInfoModal = () => {
    setInfoModal(true);
  };

  const handleCloseInfoModal = () => {
    setInfoModal(false);
  };

  const info = `The "Update TBR" button will be enabled only once the necessary DPH information for the client is recorded. Ensure that all required EICS fields are updated in Evaluation, Risk Factors and Eligibility Determination.`;

  useEffect(() => {
    toggleLoader(true);
    getAllEmployeeMinimalList()
      .then((data) => {
        setEmployeeList(data);
        toggleLoader(false);
      })
      .catch(() => {
        toggleLoader(false);
      });
  }, []);

  useEffect(() => {
    toggleLoader(true);
    getBDI2List();
  }, []);

  useEffect(() => {
    setOthersContacts(() => {
      return employeeList.map((data) => {
        return {
          label: data.name ?? '',
          id: data.id,
        };
      });
    });
  }, [employeeList]);

  useEffect(() => {
    setOpenAccordians([...openAccordians, 0]);
  }, []);

  const getBDI2List = () => {
    toggleLoader(true);
    getEvaluationData()
      .then(async (response) => {
        toggleLoader(false);
        setEvaluationData(response);
        setShowEmptyScreen(response.length === 0 ? true : false);
      })
      .catch(async (error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        setToastrId(error.message);
        setToastrDefaultMessage(error);
      });
  };

  const validationSchema = Yup.object().shape({
    examiners: Yup.array().of(
      Yup.object().shape({
        userId: Yup.string().required('Employee name is required'),
        role: Yup.string().required('Role is required'),
      }),
    ),
    dateOfAssessment: Yup.string()
      .required('Assessment date is required')
      .nullable(),
    // status: Yup.string().required('Status is required').nullable(),
    type: Yup.string().required('Type is required').nullable(),
  });

  const validateForm = (value: BDI2Data) => {
    const errors: Partial<BDI2Data> = {};
    if (value.dateOfAssessment) {
      if (datePickerMinDateMaxDateValidate(value.dateOfAssessment)) {
        errors.dateOfAssessment = 'datePickerMinDateMaxDateValidate';
      }
    }
    return errors;
  };

  const typeList: OptionType[] = [];

  if (lookups && lookups.evaluationType) {
    for (const item of lookups.evaluationType) {
      typeList.push({
        id: item.code,
        label: item.description,
      });
    }
  }

  const uploadToTBR = () => {
    setUploadToTBRModal(false);
    toggleLoader(true);
    uploadTBR(evaluationID)
      .then(async (response) => {
        toggleLoader(false);
        setOpen(true);
        setToastrId(response.message);
        setToastrDefaultMessage(response.message);
        setToastrVariable('success');
        setModalVisible(false);
        toggleLoader(false);
        getBDI2List();
      })
      .catch(async (error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        setToastrId('internetCheckConnectionError');
        setToastrDefaultMessage(error);
        toggleLoader(false);
      });
  };

  const allFieldsEnteredCheck = (values: BDI2Data) => {
    if (
      values.developmentQuotientResults.adaptiveDQScore === '' ||
      values.developmentQuotientResults.personalSocialDQScore === '' ||
      values.developmentQuotientResults.communicationDQScore === '' ||
      values.developmentQuotientResults.motorDQScore === '' ||
      values.developmentQuotientResults.cognitiveDQScore === '' ||
      values.subDomainsResults.selfCareScore === '' ||
      values.subDomainsResults.personalResponsibilityScore === '' ||
      values.subDomainsResults.adultInteractionScore === '' ||
      values.subDomainsResults.peerInteractionScore === '' ||
      values.subDomainsResults.selfConceptSocialRoleScore === '' ||
      values.subDomainsResults.respectiveCommunicationScore === '' ||
      values.subDomainsResults.expressiveCommunicationScore === '' ||
      values.subDomainsResults.grossMotorScore === '' ||
      values.subDomainsResults.fineMotorScore === '' ||
      values.subDomainsResults.perceptualMotorScore === '' ||
      values.subDomainsResults.attentionMemoryScore === '' ||
      values.subDomainsResults.reasoningAcademicSkillsScore === '' ||
      values.subDomainsResults.perceptionConceptsScore === ''
    ) {
      return false;
    }
    return true;
  };

  const saveDebounce = useCallback(
    debounce((updatedValues: BDI2Data) => {
      addAssesment(updatedValues)
        .then(async (response) => {
          toggleLoader(false);
          setOpen(true);
          setToastrId(response.message);
          setToastrDefaultMessage(response.message);
          setToastrVariable('success');
          setModalVisible(false);
          toggleLoader(false);
          getBDI2List();
          setInitialValues({
            completed: false,
            dateOfAssessment: null,
            status: '',
            examiners: [
              {
                userId: '',
                role: '',
              },
            ],
            subDomainsResults: {
              selfCareScore: '',
              personalResponsibilityScore: '',
              adultInteractionScore: '',
              peerInteractionScore: '',
              selfConceptSocialRoleScore: '',
              respectiveCommunicationScore: '',
              expressiveCommunicationScore: '',
              grossMotorScore: '',
              fineMotorScore: '',
              perceptualMotorScore: '',
              attentionMemoryScore: '',
              reasoningAcademicSkillsScore: '',
              perceptionConceptsScore: '',
            },
            developmentQuotientResults: {
              adaptiveDQScore: '',
              personalSocialDQScore: '',
              communicationDQScore: '',
              motorDQScore: '',
              cognitiveDQScore: '',
            },
          });
          formRef.current?.resetForm();
        })
        .catch(async (error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          setToastrId('failed');
          setToastrDefaultMessage(error);
          toggleLoader(false);
          setModalVisible(false);
          setInitialValues({
            completed: false,
            dateOfAssessment: null,
            status: '',
            examiners: [
              {
                userId: '',
                role: '',
              },
            ],
            subDomainsResults: {
              selfCareScore: '',
              personalResponsibilityScore: '',
              adultInteractionScore: '',
              peerInteractionScore: '',
              selfConceptSocialRoleScore: '',
              respectiveCommunicationScore: '',
              expressiveCommunicationScore: '',
              grossMotorScore: '',
              fineMotorScore: '',
              perceptualMotorScore: '',
              attentionMemoryScore: '',
              reasoningAcademicSkillsScore: '',
              perceptionConceptsScore: '',
            },
            developmentQuotientResults: {
              adaptiveDQScore: '',
              personalSocialDQScore: '',
              communicationDQScore: '',
              motorDQScore: '',
              cognitiveDQScore: '',
            },
          });
          formRef.current?.resetForm();
        });
    }, 500),
    [],
  );
  const handleSave = (values: BDI2Data) => {
    setPersonalRespScoreNotApplicable(false);
    setPerceptualMotorScoreNotApplicable(false);
    setReasoningScoreNotApplicable(false);
    setPeerInteractionNotAplicable(false);
    toggleLoader(true);
    setInitialValues(values);
    const updatedValues = {
      ...values,
      completed: allFieldsEnteredCheck(values),
    };
    saveDebounce(updatedValues);
  };

  const editDebounce = useCallback(
    debounce((updatedValues: BDI2Data, eeditData) => {
      editAssesment(updatedValues, eeditData)
        .then(async (response) => {
          toggleLoader(false);
          setOpen(true);
          setToastrId(response.message);
          setToastrDefaultMessage(response.message);
          setToastrVariable('success');
          setModalVisible(false);
          toggleLoader(false);
          getBDI2List();
          setInitialValues({
            completed: false,
            dateOfAssessment: null,
            status: '',
            type: '',
            examiners: [
              {
                userId: '',
                role: '',
              },
            ],
            subDomainsResults: {
              selfCareScore: '',
              personalResponsibilityScore: '',
              adultInteractionScore: '',
              peerInteractionScore: '',
              selfConceptSocialRoleScore: '',
              respectiveCommunicationScore: '',
              expressiveCommunicationScore: '',
              grossMotorScore: '',
              fineMotorScore: '',
              perceptualMotorScore: '',
              attentionMemoryScore: '',
              reasoningAcademicSkillsScore: '',
              perceptionConceptsScore: '',
            },
            developmentQuotientResults: {
              adaptiveDQScore: '',
              personalSocialDQScore: '',
              communicationDQScore: '',
              motorDQScore: '',
              cognitiveDQScore: '',
            },
          });
          formRef.current?.resetForm();
        })
        .catch(async (error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          setToastrId('failed');
          setToastrDefaultMessage(error);

          setModalVisible(false);
          toggleLoader(false);
          formRef.current?.resetForm();
        });
    }, 500),
    [],
  );

  const handleEdit = (values: BDI2Data) => {
    toggleLoader(true);
    const updatedValues = {
      ...values,
      completed: allFieldsEnteredCheck(values),
    };

    editDebounce(updatedValues, editData);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const editFunction = (data: BDI2Data) => {
    setEditFlag(true);
    setModalVisible(true);
    setPersonalRespScoreNotApplicable(
      data.subDomainsResults.personalResponsibilityScore == 'Not Applicable'
        ? true
        : false,
    );
    setPerceptualMotorScoreNotApplicable(
      data.subDomainsResults.perceptualMotorScore == 'Not Applicable'
        ? true
        : false,
    );
    setPeerInteractionNotAplicable(
      data.subDomainsResults.peerInteractionScore == 'Not Applicable'
        ? true
        : false,
    );
    setReasoningScoreNotApplicable(
      data.subDomainsResults.reasoningAcademicSkillsScore == 'Not Applicable'
        ? true
        : false,
    );
    if (formRef.current) {
      formRef.current.setValues(data);
      formRef.current.setFieldValue('completed', allFieldsEnteredCheck(data));
    }
  };

  return (
    <Box component="main" className="mb-xlg">
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Box component="section">
        <Box className="rowContainer">
          <Grid container>
            <Grid item xs={6} lg={6}>
              <Box className="flex__ alignItemCenter">
                <CustomBackArrow onClick={() => navigate(FACESHEET)} />
                <TitleText
                  labelid="BDI2.CreateNew"
                  defaultlabel="BDI2.CreateNew"
                  className="ml-md"
                />
              </Box>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Box
                component="div"
                className="flex__ text-align-end justifyContent-FlexEnd"
              >
                {evaluationData.length > 0 &&
                  checkPermissionForFeature(
                    'backend.clients',
                    'editPermission',
                  ) && (
                    <ButtonComponent
                      className="btn-primary btn-submit ml-md"
                      variantType="contained"
                      type="submit"
                      labelId="BDI2.AddNew"
                      onClick={() => {
                        formRef.current?.setFieldValue('completed', false);
                        setModalVisible(true);
                        setEditFlag(false);
                        setInitialValues({
                          completed: false,
                          dateOfAssessment: null,
                          status: '',
                          type: '',
                          examiners: [
                            {
                              userId: '',
                              role: '',
                            },
                          ],
                          subDomainsResults: {
                            selfCareScore: '',
                            personalResponsibilityScore: '',
                            adultInteractionScore: '',
                            peerInteractionScore: '',
                            selfConceptSocialRoleScore: '',
                            respectiveCommunicationScore: '',
                            expressiveCommunicationScore: '',
                            grossMotorScore: '',
                            fineMotorScore: '',
                            perceptualMotorScore: '',
                            attentionMemoryScore: '',
                            reasoningAcademicSkillsScore: '',
                            perceptionConceptsScore: '',
                          },
                          developmentQuotientResults: {
                            adaptiveDQScore: '',
                            personalSocialDQScore: '',
                            communicationDQScore: '',
                            motorDQScore: '',
                            cognitiveDQScore: '',
                          },
                        });
                      }}
                      disabled={false}
                      defaultLabelId="Save"
                    />
                  )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        {evaluationData.length > 0 && (
          <Card className="formCardview">
            {evaluationData.map((data, index) => (
              <Box
                key={0}
                sx={{
                  backgroundColor: '#ECF9F8',
                  padding: '16px',
                  marginBottom: '16px',
                  border: '1px solid #00C6B8',
                  borderRadius: '4px',
                }}
              >
                <Box className="flex__ alignItemCenter">
                  <Grid container>
                    <Grid container item lg={10}>
                      <Grid item lg={12}>
                        <Box
                          sx={{
                            display: 'flex',
                          }}
                        >
                          <Box className="flex__">
                            <MediumTypography
                              labelid="BDI2.Evaluation"
                              defaultlabel="Date of Evaluation"
                              textColor="#97A6A5"
                              sxProps={{ fontSize: '16px' }}
                            />
                            <MediumTypography
                              sxProps={{
                                fontSize: '16px',
                                fontWeight: 500,
                                paddingLeft: '6px',
                                color: '#2A4241',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                              label={
                                data.doe
                                  ? moment(data.doe).format('MM/DD/YYYY')
                                  : '-'
                              }
                            />
                          </Box>
                          <Box className="flex__ ml-xlg mr-lg">
                            <MediumTypography
                              labelid="BDI2.Type"
                              defaultlabel="Type"
                              textColor="#97A6A5"
                              sxProps={{ fontSize: '16px' }}
                            />
                            <MediumTypography
                              sxProps={{
                                fontSize: '16px',
                                fontWeight: 500,
                                paddingLeft: '6px',
                                color: '#2A4241',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                              label={
                                (
                                  lookups &&
                                  lookups.evaluationType?.find(
                                    (obj) => obj.code === data.type,
                                  )
                                )?.description
                              }
                            />
                          </Box>
                          <Box className="flex__ ml-xlg mr-lg">
                            <MediumTypography
                              labelid="BDI2.Status"
                              defaultlabel="Status"
                              textColor="#97A6A5"
                              sxProps={{ fontSize: '16px' }}
                            />
                            <MediumTypography
                              sxProps={{
                                fontSize: '16px',
                                fontWeight: 500,
                                paddingLeft: '6px',
                                color: '#2A4241',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                              label={data.status}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item lg={12} className="mt-xs">
                        <Box
                          sx={{
                            display: 'flex',
                          }}
                        >
                          <Box className="flex__  mr-lg">
                            <MediumTypography
                              labelid="BDI2.DateOfAssesment"
                              defaultlabel="Date Of Assesment"
                              textColor="#97A6A5"
                              sxProps={{ fontSize: '16px' }}
                            />
                            <MediumTypography
                              sxProps={{
                                fontSize: '16px',
                                fontWeight: 500,
                                paddingLeft: '6px',
                                color: '#2A4241',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                              label={
                                data.dateOfAssessment
                                  ? moment(data.dateOfAssessment).format(
                                      'MM/DD/YYYY',
                                    )
                                  : '-'
                              }
                            />
                          </Box>
                          <Box className="flex__">
                            <MediumTypography
                              labelid="BDI2.NotificationDate"
                              defaultlabel="Date Of Notification"
                              textColor="#97A6A5"
                              sxProps={{ fontSize: '16px' }}
                            />
                            <MediumTypography
                              sxProps={{
                                fontSize: '16px',
                                fontWeight: 500,
                                paddingLeft: '6px',
                                color: '#2A4241',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                              label={
                                data.notificationDate
                                  ? dayjs(data.notificationDate).format(
                                      'MM/DD/YYYY',
                                    )
                                  : '-'
                              }
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item lg={2}>
                      <Box
                        component="div"
                        className="flex__ text-align-end justifyContent-FlexEnd alignItemCenter"
                      >
                        {checkPermissionForFeature(
                          'backend.clients',
                          'editPermission',
                        ) && (
                          <Box className="mr-md">
                            <EditComponent
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                editFunction(data);
                                setEditData(
                                  data.id !== undefined ? data.id : '',
                                );
                                setUploadedToTBR(data.uploadToTbr);
                              }}
                            />
                          </Box>
                        )}
                        <Box>
                          {openAccordians.includes(index) ? (
                            <Box
                              sx={{
                                display: 'flex',
                                padding: '5px',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                if (openAccordians.includes(index)) {
                                  setOpenAccordians(
                                    openAccordians.filter(
                                      (item) => item !== index,
                                    ),
                                  );
                                } else {
                                  setOpenAccordians([...openAccordians, index]);
                                }
                              }}
                            >
                              <UpArrow />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                padding: '5px',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                if (openAccordians.includes(index)) {
                                  setOpenAccordians(
                                    openAccordians.filter(
                                      (item) => item !== index,
                                    ),
                                  );
                                } else {
                                  setOpenAccordians([...openAccordians, index]);
                                }
                              }}
                            >
                              <DownArrow />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {openAccordians.includes(index) && (
                  <Box>
                    <MediumTypography
                      labelid={'Domain Raw Scores'}
                      defaultlabel={'Domain Raw Scores'}
                      className="mt-sm"
                      sxProps={{
                        fontSize: '14px',
                        color: '#97A6A5',
                      }}
                    />
                    <Grid container columnSpacing={'20px'}>
                      <Grid item xs={3.5} className="pt-md">
                        <CustomcardComponent
                          headTextWithValue={`Adaptive(DQ):`}
                          headTextWithValue2={
                            data &&
                            data.developmentQuotientResults &&
                            data.developmentQuotientResults.adaptiveDQScore
                          }
                          subText1={`Self Care(ScRaw):`}
                          subText1Value={
                            data &&
                            data.subDomainsResults &&
                            data.subDomainsResults.selfCareScore
                          }
                          subText2={`Personal responsibility (PrRaw):`}
                          subText2Value={
                            data &&
                            data.subDomainsResults &&
                            data.subDomainsResults
                              .personalResponsibilityScore === 'Not Applicable'
                              ? 'N/A'
                              : data.subDomainsResults
                                  .personalResponsibilityScore
                          }
                        />
                      </Grid>
                      <Grid item xs={3.5} className="pt-md">
                        <CustomcardComponent
                          headTextWithValue={`Personal-Social(DQ):`}
                          headTextWithValue2={
                            data &&
                            data.developmentQuotientResults &&
                            data.developmentQuotientResults
                              .personalSocialDQScore
                          }
                          subText1={`Adult Interaction(AiRaw):`}
                          subText1Value={
                            data &&
                            data.subDomainsResults &&
                            data.subDomainsResults.adultInteractionScore
                          }
                          subText2={`Peer Interaction(PiRaw):`}
                          subText2Value={
                            data &&
                            data.subDomainsResults &&
                            data.subDomainsResults.peerInteractionScore ===
                              'Not Applicable'
                              ? 'N/A'
                              : data.subDomainsResults.peerInteractionScore
                          }
                          subText3={`Self Concept & Social Role (SrRaw):`}
                          subText3Value={
                            data &&
                            data.subDomainsResults &&
                            data.subDomainsResults.selfConceptSocialRoleScore
                          }
                        />
                      </Grid>
                      <Grid item xs={3.5} className="pt-md">
                        <CustomcardComponent
                          headTextWithValue={`Communication(DQ):`}
                          headTextWithValue2={
                            data &&
                            data.developmentQuotientResults &&
                            data.developmentQuotientResults.communicationDQScore
                          }
                          subText1={`Receptive Communication (RcRaw):`}
                          subText1Value={
                            data &&
                            data.subDomainsResults &&
                            data.subDomainsResults.respectiveCommunicationScore
                          }
                          subText2={`Expressive Communication (EcRaw):`}
                          subText2Value={
                            data &&
                            data.subDomainsResults &&
                            data.subDomainsResults.expressiveCommunicationScore
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container columnSpacing={'20px'}>
                      <Grid item xs={3.5} className="pt-md">
                        <CustomcardComponent
                          headTextWithValue={`Motor(DQ):`}
                          headTextWithValue2={
                            data &&
                            data.developmentQuotientResults &&
                            data.developmentQuotientResults.motorDQScore
                          }
                          subText1={`Gross Motor(GmRaw):`}
                          subText1Value={
                            data &&
                            data.subDomainsResults &&
                            data.subDomainsResults.grossMotorScore
                          }
                          subText2={`Fine Motor(FmRaw):`}
                          subText2Value={
                            data &&
                            data.subDomainsResults &&
                            data.subDomainsResults.fineMotorScore
                          }
                          subText3={`Perceptual Motor(PmRaw):`}
                          subText3Value={
                            data &&
                            data.subDomainsResults &&
                            data.subDomainsResults.perceptualMotorScore ===
                              'Not Applicable'
                              ? 'N/A'
                              : data.subDomainsResults.perceptualMotorScore
                          }
                        />
                      </Grid>
                      <Grid item xs={3.5} className="pt-md">
                        <CustomcardComponent
                          headTextWithValue={`Cognitive(DQ):`}
                          headTextWithValue2={
                            data &&
                            data.developmentQuotientResults &&
                            data.developmentQuotientResults.cognitiveDQScore
                          }
                          subText1={`Attention / Memory (AmRaw): `}
                          subText1Value={
                            data &&
                            data.subDomainsResults &&
                            data.subDomainsResults.attentionMemoryScore
                          }
                          subText2={`Reasoning / Academic Skills (RaRaw):`}
                          subText2Value={
                            data &&
                            data.subDomainsResults &&
                            data.subDomainsResults
                              .reasoningAcademicSkillsScore === 'Not Applicable'
                              ? 'N/A'
                              : data.subDomainsResults
                                  .reasoningAcademicSkillsScore
                          }
                          subText3={`Perception / Concepts (PcRaw):`}
                          subText3Value={
                            data &&
                            data.subDomainsResults &&
                            data.subDomainsResults.perceptionConceptsScore
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={8} className="pt-md">
                        <Box className="flex__">
                          <Box className="flex__ alignItemCenter mr-xlg">
                            <MediumTypography
                              onClick={() =>
                                navigate(CLINICAL_JUDGEMENT_ROUTE, {
                                  state: {
                                    evaluationId: data.id,
                                    isUploadedToTBR: data.uploadToTbr,
                                  },
                                })
                              }
                              labelid={'clinicalJudgementTextFacesheet'}
                              defaultlabel="Clinical Judgement"
                              sxProps={{
                                fontSize: '16px',
                                fontWeight: '500',
                                color: '#008C82',
                                cursor: 'pointer',
                              }}
                            />
                            <GoTo className="ml-xs" />
                          </Box>

                          <Box className="flex__ alignItemCenter ml-xlg mr-xlg">
                            <MediumTypography
                              onClick={() =>
                                navigate(RISK_FACTOR, {
                                  state: {
                                    evaluationId: data.id,
                                    evaluationType: data.type,
                                    isUploadedToTBR: data.uploadToTbr,
                                  },
                                })
                              }
                              labelid={'riskFactorText'}
                              defaultlabel="Risk Factor"
                              sxProps={{
                                fontSize: '16px',
                                fontWeight: '500',
                                color: '#008C82',
                                cursor: 'pointer',
                              }}
                            />
                            <GoTo className="ml-xs" />
                          </Box>

                          <Box className="flex__ alignItemCenter ml-xlg">
                            <MediumTypography
                              onClick={() =>
                                navigate(ELIGIBILITY_DETERMINATION, {
                                  state: {
                                    evaluationId: data.id,
                                    evaluationStatus: data.status,
                                    isUploadedToTBR: data.uploadToTbr,
                                  },
                                })
                              }
                              labelid={'EligibilityDeterminationText'}
                              defaultlabel="Eligibility Determination"
                              sxProps={{
                                fontSize: '16px',
                                fontWeight: '500',
                                color: '#008C82',
                                cursor: 'pointer',
                              }}
                            />
                            <GoTo className="ml-xs" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4} className="pt-md">
                        <Box
                          component="div"
                          className="flex__ text-align-end justifyContent-FlexEnd align__items__center"
                        >
                          <ButtonComponent
                            className="btn-primary btn-submit ml-md"
                            variantType="contained"
                            type="submit"
                            labelId={
                              data.uploadToTbr
                                ? 'BDI2.UploadedToTBR'
                                : 'BDI2.UploadToTBR'
                            }
                            onClick={() => {
                              if (!data.uploadToTbr) setUploadToTBRModal(true);
                              setEvaluationID(data.id);
                            }}
                            disabled={data.uploadToTbr || !data.readyToUpload}
                            defaultLabelId="Save"
                          />
                          <InfoIcon
                            className="ml-xs"
                            onClick={handleOpenInfoModal}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            ))}
          </Card>
        )}

        {showEmptyScreen && (
          <EmptyScreen
            titleLabelId="BDI2.NoAssesments"
            defaultTitleText="No assesments added yet."
            buttonLabelId="BDI2.AddNew"
            defaultButtonTitleText="Add New Assessment"
            showButton={checkPermissionForFeature(
              'backend.clients',
              'editPermission',
            )}
            onButtonClick={() => {
              formRef.current?.setFieldValue('completed', false);
              setModalVisible(true);
              setEditFlag(false);
              setInitialValues({
                completed: false,
                dateOfAssessment: null,
                status: '',
                type: '',
                examiners: [
                  {
                    userId: '',
                    role: '',
                  },
                ],
                subDomainsResults: {
                  selfCareScore: '',
                  personalResponsibilityScore: '',
                  adultInteractionScore: '',
                  peerInteractionScore: '',
                  selfConceptSocialRoleScore: '',
                  respectiveCommunicationScore: '',
                  expressiveCommunicationScore: '',
                  grossMotorScore: '',
                  fineMotorScore: '',
                  perceptualMotorScore: '',
                  attentionMemoryScore: '',
                  reasoningAcademicSkillsScore: '',
                  perceptionConceptsScore: '',
                },
                developmentQuotientResults: {
                  adaptiveDQScore: '',
                  personalSocialDQScore: '',
                  communicationDQScore: '',
                  motorDQScore: '',
                  cognitiveDQScore: '',
                },
              });
            }}
          />
        )}
      </Box>

      <Box component="div">
        <Dialog
          open={modalVisible}
          keepMounted
          onClose={() => ({})}
          fullWidth
          aria-describedby="alert-dialog-slide-description"
          maxWidth={'lg'}
        >
          <DialogContent sx={{ padding: '16px 24px' }}>
            <Box
              component="div"
              className="rowContainer2 flex__justify__space-between"
            >
              <Box component="div" sx={{ whiteSpace: 'nowrap' }}>
                <MediumTypography
                  labelid={'BDI2.AddNewAssesment'}
                  defaultlabel="Add new evaluation"
                  fontSize="24px"
                  fontweight={600}
                  textColor="#2a4241"
                />
              </Box>
              <Box component="div" className="ml-xs">
                <img
                  src={CloseIcon}
                  alt="backarrow"
                  style={{
                    position: 'relative',
                    cursor: 'pointer',
                    top: '10%',
                  }}
                  onClick={() => {
                    setPersonalRespScoreNotApplicable(false);
                    setPerceptualMotorScoreNotApplicable(false);
                    setReasoningScoreNotApplicable(false);
                    setPeerInteractionNotAplicable(false);
                    setModalVisible(false);
                    setInitialValues({
                      completed: false,
                      dateOfAssessment: null,
                      status: '',
                      type: '',
                      examiners: [
                        {
                          userId: '',
                          role: '',
                        },
                      ],
                      subDomainsResults: {
                        selfCareScore: '',
                        personalResponsibilityScore: '',
                        adultInteractionScore: '',
                        peerInteractionScore: '',
                        selfConceptSocialRoleScore: '',
                        respectiveCommunicationScore: '',
                        expressiveCommunicationScore: '',
                        grossMotorScore: '',
                        fineMotorScore: '',
                        perceptualMotorScore: '',
                        attentionMemoryScore: '',
                        reasoningAcademicSkillsScore: '',
                        perceptionConceptsScore: '',
                      },
                      developmentQuotientResults: {
                        adaptiveDQScore: '',
                        personalSocialDQScore: '',
                        communicationDQScore: '',
                        motorDQScore: '',
                        cognitiveDQScore: '',
                      },
                    });
                    formRef.current?.resetForm();
                  }}
                />
              </Box>
            </Box>
            <Formik
              initialValues={initialValues}
              validateOnMount={true}
              innerRef={formRef}
              enableReinitialize={true}
              onSubmit={(values) =>
                editFlag ? handleEdit(values) : handleSave(values)
              }
              validationSchema={validationSchema}
              validateOnChange={true}
              validateOnBlur={true}
              validate={validateForm}
            >
              {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                <Box component="div">
                  <Box component="div" className="">
                    <Box component="div" className="mt-lg mb-lg">
                      <Box
                        className="formCardview p-sm gray3 borderRadius4"
                        sx={{
                          border: '1px solid #00C6B8',
                          background: '#ECF9F8',
                        }}
                      >
                        {values.examiners.map((examiner, index) => {
                          const selectedEmployee = employeeList.find(
                            (contact) => contact.id === examiner?.userId,
                          );
                          const examinerRoles =
                            selectedEmployee?.roles?.flatMap((roleString) =>
                              roleString.split(',').map((roleId) => ({
                                id: roleId.trim(),
                                label:
                                  employeeRoles.find(
                                    (empRole) => empRole.id === roleId.trim(),
                                  )?.label || '',
                              })),
                            ) || [];

                          const filteredOthersContacts = othersContacts.filter(
                            (contact) =>
                              !values.examiners.some(
                                (ex) => ex.userId === contact.id,
                              ) || examiner?.userId === contact.id,
                          );
                          return (
                            <Grid
                              container
                              spacing={3}
                              key={index}
                              className="pt-xlg"
                            >
                              <Grid item xs={4} className="pt-md">
                                <DropdownComponent
                                  disabled={uploadedToTBR}
                                  names={filteredOthersContacts}
                                  labelid="IFSP.selectName"
                                  defaultlabelid="Select Name"
                                  Required={true}
                                  value={examiner?.userId || ''}
                                  handleChange={(e) => {
                                    if (e) {
                                      setFieldValue(
                                        `examiners[${index}].userId`,
                                        e,
                                      );
                                      setInitialValues((prevValues) => {
                                        const newValues = { ...prevValues };
                                        newValues.examiners[index] = {
                                          ...newValues.examiners[index],
                                          userId: e,
                                        };
                                        return newValues;
                                      });

                                      setFieldValue(
                                        `examiners[${index}].role`,
                                        '',
                                      );
                                      setInitialValues((prevValues) => {
                                        const newValues = { ...prevValues };
                                        newValues.examiners[index].role = '';
                                        return newValues;
                                      });
                                    }
                                  }}
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                />
                                {errors.examiners &&
                                  Array.isArray(errors.examiners) &&
                                  index < errors.examiners.length &&
                                  (
                                    errors.examiners as FormikErrors<Examiner>[]
                                  )[index]?.userId &&
                                  touched.examiners?.[index]?.userId && (
                                    <MediumTypography
                                      label={
                                        (
                                          errors.examiners as FormikErrors<Examiner>[]
                                        )[index]?.userId
                                      }
                                      sxProps={{
                                        color: 'red',
                                        fontSize: '14px',
                                      }}
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={4} className="pt-md">
                                <DropdownComponent
                                  disabled={uploadedToTBR}
                                  names={examinerRoles}
                                  labelid="Ifsp.selectRole"
                                  defaultlabelid="Select Role*"
                                  value={examiner?.role || ''}
                                  handleChange={(e) => {
                                    if (e) {
                                      setFieldValue(
                                        `examiners[${index}].role`,
                                        e,
                                      );
                                      setInitialValues((prevValues) => {
                                        const newValues = { ...prevValues };
                                        newValues.examiners[index].role = e;
                                        return newValues;
                                      });
                                    }
                                  }}
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                />
                                {errors.examiners &&
                                  Array.isArray(errors.examiners) &&
                                  index < errors.examiners.length &&
                                  (
                                    errors.examiners as FormikErrors<Examiner>[]
                                  )[index]?.role &&
                                  touched.examiners?.[index]?.role && (
                                    <MediumTypography
                                      label={
                                        (
                                          errors.examiners as FormikErrors<Examiner>[]
                                        )[index]?.role
                                      }
                                      sxProps={{
                                        color: 'red',
                                        fontSize: '14px',
                                      }}
                                    />
                                  )}
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                lg={2}
                                className="mt-lg pt-xs pl-md"
                              >
                                {index > 0 && (
                                  <Grid item xs={6} lg={2}>
                                    <TrashIcon
                                      className="cursor__pointer"
                                      onClick={() => {
                                        setFieldValue(
                                          `examiners`,
                                          values.examiners.filter(
                                            (_, i) => i !== index,
                                          ),
                                        );
                                      }}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          );
                        })}
                        <ButtonComponent
                          className="btn-add-code mt-md"
                          variantType="contained"
                          type="submit"
                          label={`+ ${rctl.formatMessage({
                            id: 'Add Employee',
                            defaultMessage: 'Add Employee',
                          })}`}
                          defaultLabelId="+ Add Parent"
                          onClick={() => {
                            if (
                              values.examiners.length !== employeeList.length &&
                              !uploadedToTBR
                            ) {
                              setFieldValue('examiners', [
                                ...values.examiners,
                                { userId: '', role: '' },
                              ]);
                            }
                          }}
                        />
                      </Box>
                    </Box>

                    <Box className="mb-lg">
                      <Card
                        className="formCardview p-sm gray3 borderRadius4"
                        sx={{
                          border: '1px solid #00C6B8',
                          background: '#ECF9F8',
                        }}
                      >
                        <Box component="div" className="mt-xlg mb-xlg">
                          <Grid container spacing={3} className="mb-xlg">
                            <Grid item xs={4} className="pt-md">
                              <Box className="bg__white">
                                {values.dateOfAssessment && (
                                  <DatePickerComponent
                                    name={`dateOfAssessment`}
                                    labelid="BDI2.AssesmentDate"
                                    disabledDate={uploadedToTBR}
                                    defaultlabelid="Assessment Date *"
                                    required={true}
                                    disableFuture={true}
                                    handlechange={(date: Dayjs | null) => {
                                      const formattedDate =
                                        dayjs(date).format('YYYY-MM-DD');
                                      if (date === null) {
                                        setFieldValue('dateOfAssessment', null);
                                      } else {
                                        setFieldValue(
                                          'dateOfAssessment',
                                          formattedDate,
                                        );
                                      }
                                    }}
                                    value={dayjs(values.dateOfAssessment)}
                                  />
                                )}
                                {values.dateOfAssessment === null && (
                                  <DatePickerComponent
                                    name={`dateOfAssessment`}
                                    labelid="BDI2.AssesmentDate"
                                    disabledDate={uploadedToTBR}
                                    defaultlabelid="Assessment Date *"
                                    required={true}
                                    disableFuture={true}
                                    handlechange={(date: Dayjs | null) => {
                                      const formattedDate =
                                        dayjs(date).format('YYYY-MM-DD');
                                      if (date === null) {
                                        setFieldValue('dateOfAssessment', null);
                                      } else {
                                        setFieldValue(
                                          'dateOfAssessment',
                                          formattedDate,
                                        );
                                      }
                                    }}
                                    value={dayjs(values.dateOfAssessment)}
                                  />
                                )}
                              </Box>
                              {errors.dateOfAssessment &&
                              touched.dateOfAssessment ? (
                                <Box className="loginError">
                                  <MediumTypography
                                    labelid={errors.dateOfAssessment}
                                    defaultlabel="Enter Name"
                                    sxProps={{
                                      color: 'red',
                                      fontSize: '14px',
                                    }}
                                  />
                                </Box>
                              ) : null}
                            </Grid>
                            <Grid item xs={4} className="pt-md">
                              <DropdownComponent
                                disabled={uploadedToTBR}
                                name="type"
                                names={typeList}
                                labelid="Type"
                                defaultlabelid="Type"
                                value={values.type}
                                handleChange={(e) => {
                                  // setFieldValue('status', e);
                                  setFieldValue('type', e);
                                }}
                                Required={true}
                              />
                              {errors.type && touched.type ? (
                                <Box className="loginError">
                                  <MediumTypography
                                    labelid={errors.type}
                                    defaultlabel="Enter Name"
                                    sxProps={{
                                      color: 'red',
                                      fontSize: '14px',
                                    }}
                                  />
                                </Box>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Box>
                  </Box>

                  {/*Adaptive*/}
                  <Box className="mb-lg">
                    <Card
                      className="formCardview p-sm gray3 borderRadius4"
                      sx={{
                        border: '1px solid #00C6B8',
                        background: '#ECF9F8',
                      }}
                    >
                      <MediumTypography
                        label={'Adaptive'}
                        sxProps={{
                          marginTop: '10px',
                          fontWeight: 'bold',
                        }}
                      />
                      <Box component="div" className="mt-xlg mb-xlg">
                        <Grid container spacing={3}>
                          <Grid item xs={4} className="pt-md">
                            <Textinput
                              name="adaptiveDQScore"
                              labelid={'BDI2.Adaptive'}
                              defaultlabelid="Adaptive DQ Score"
                              Required={false}
                              inputProps={{
                                maxLength: 5,
                              }}
                              sxProps={{ background: 'white' }}
                              handlechange={(value) => {
                                const numericValue = value.replace(/\D/g, '');
                                setFieldValue(
                                  'developmentQuotientResults.adaptiveDQScore',
                                  numericValue,
                                );
                              }}
                              type="text"
                              Value={
                                values.developmentQuotientResults
                                  .adaptiveDQScore
                              }
                              disabled={uploadedToTBR}
                            />
                          </Grid>

                          <Grid item xs={4} className="pt-md">
                            <Textinput
                              name="selfCareScore"
                              labelid={'BDI2.SelfCare'}
                              defaultlabelid="Self Care Raw Score"
                              Required={false}
                              inputProps={{
                                maxLength: 5,
                              }}
                              sxProps={{ background: 'white' }}
                              handlechange={(value) => {
                                const numericValue = value.replace(/\D/g, '');
                                setFieldValue(
                                  'subDomainsResults.selfCareScore',
                                  numericValue,
                                );
                              }}
                              type="text"
                              Value={values.subDomainsResults.selfCareScore}
                              disabled={uploadedToTBR}
                            />
                          </Grid>

                          <Grid item xs={4} className="pt-md">
                            <Textinput
                              name="personalResponsibilityScore"
                              labelid={'BDI2.PersonalResponsibilityScore'}
                              defaultlabelid="Personal Responsibility Raw Score"
                              Required={false}
                              inputProps={{
                                maxLength: 5,
                              }}
                              sxProps={{ background: 'white' }}
                              handlechange={(value) => {
                                const numericValue = value.replace(/\D/g, '');
                                setFieldValue(
                                  'subDomainsResults.personalResponsibilityScore',
                                  numericValue,
                                );
                              }}
                              type="text"
                              Value={
                                values.subDomainsResults
                                  .personalResponsibilityScore
                              }
                              disabled={
                                uploadedToTBR || personalRespScoreNotApplicable
                              }
                            />
                            <CheckBoxComponent
                              defaultlabelid="Not Applicable"
                              labelid="Not Applicable"
                              ischecked={personalRespScoreNotApplicable}
                              CheckHandleChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                setPersonalRespScoreNotApplicable(
                                  e.target.checked,
                                );
                                setFieldValue(
                                  'subDomainsResults.personalResponsibilityScore',
                                  e.target.checked ? 'Not Applicable' : '',
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Box>

                  {/*PersonalSocial*/}
                  <Box className="mb-lg">
                    <Card
                      className="formCardview p-sm gray3 borderRadius4"
                      sx={{
                        border: '1px solid #00C6B8',
                        background: '#ECF9F8',
                      }}
                    >
                      <MediumTypography
                        label={'Personal Social'}
                        sxProps={{
                          marginTop: '10px',
                          fontWeight: 'bold',
                        }}
                      />
                      <Box component="div" className="mt-xlg mb-xlg">
                        <Grid container spacing={3}>
                          <Grid item xs={4} className="pt-md">
                            <Textinput
                              name="personalSocialDQScore"
                              labelid={'BDI2.PersonalSocialDQScore'}
                              defaultlabelid="Personal-Social DQ Score"
                              Required={false}
                              sxProps={{ background: 'white' }}
                              inputProps={{
                                maxLength: 5,
                              }}
                              handlechange={(value) => {
                                const numericValue = value.replace(/\D/g, '');
                                setFieldValue(
                                  'developmentQuotientResults.personalSocialDQScore',
                                  numericValue,
                                );
                              }}
                              type="text"
                              Value={
                                values.developmentQuotientResults
                                  .personalSocialDQScore
                              }
                              disabled={uploadedToTBR}
                            />
                          </Grid>

                          <Grid item xs={4} className="pt-md">
                            <Textinput
                              name="adultInteractionScore"
                              labelid={'BDI2.AdultInteractionScore'}
                              defaultlabelid="Adult Interaction Raw Score"
                              Required={false}
                              sxProps={{ background: 'white' }}
                              inputProps={{
                                maxLength: 5,
                              }}
                              handlechange={(value) => {
                                const numericValue = value.replace(/\D/g, '');
                                setFieldValue(
                                  'subDomainsResults.adultInteractionScore',
                                  numericValue,
                                );
                              }}
                              type="text"
                              Value={
                                values.subDomainsResults.adultInteractionScore
                              }
                              disabled={uploadedToTBR}
                            />
                          </Grid>

                          <Grid item xs={4} className="pt-md">
                            <Textinput
                              name="peerInteractionScore"
                              labelid={'BDI2.PeerInteractionScore'}
                              defaultlabelid="Peer Interaction Raw Score"
                              Required={false}
                              sxProps={{ background: 'white' }}
                              inputProps={{
                                maxLength: 5,
                              }}
                              handlechange={(value) => {
                                const numericValue = value.replace(/\D/g, '');
                                setFieldValue(
                                  'subDomainsResults.peerInteractionScore',
                                  numericValue,
                                );
                              }}
                              type="text"
                              Value={
                                values.subDomainsResults.peerInteractionScore
                              }
                              disabled={
                                uploadedToTBR || peerInteractionNotAplicable
                              }
                            />
                            <CheckBoxComponent
                              defaultlabelid="Not Applicable"
                              labelid="Not Applicable"
                              ischecked={peerInteractionNotAplicable}
                              CheckHandleChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                setPeerInteractionNotAplicable(
                                  e.target.checked,
                                );
                                setFieldValue(
                                  'subDomainsResults.peerInteractionScore',
                                  e.target.checked ? 'Not Applicable' : '',
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={4} className="pt-xlg mt-md ">
                            <Textinput
                              name="selfConceptSocialRoleScore"
                              labelid={'BDI2.SelfConceptSocialRoleScore'}
                              defaultlabelid="Self Concept & Social Role Raw Score"
                              Required={false}
                              sxProps={{ background: 'white' }}
                              inputProps={{
                                maxLength: 5,
                              }}
                              handlechange={(value) => {
                                const numericValue = value.replace(/\D/g, '');
                                setFieldValue(
                                  'subDomainsResults.selfConceptSocialRoleScore',
                                  numericValue,
                                );
                              }}
                              type="text"
                              Value={
                                values.subDomainsResults
                                  .selfConceptSocialRoleScore
                              }
                              disabled={uploadedToTBR}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Box>
                  {/*Communication*/}
                  <Box className="mb-lg">
                    <Card
                      className="formCardview p-sm gray3 borderRadius4"
                      sx={{
                        border: '1px solid #00C6B8',
                        background: '#ECF9F8',
                      }}
                    >
                      <MediumTypography
                        label={'Communication'}
                        sxProps={{
                          marginTop: '10px',
                          fontWeight: 'bold',
                        }}
                      />
                      <Box component="div" className="mt-xlg mb-xlg">
                        <Grid container spacing={3}>
                          <Grid item xs={4} className="pt-md">
                            <Textinput
                              name="communicationDQScore"
                              labelid={'BDI2.communicationDQScore'}
                              defaultlabelid="communication DQ Score"
                              Required={false}
                              sxProps={{ background: 'white' }}
                              inputProps={{
                                maxLength: 5,
                              }}
                              handlechange={(value) => {
                                const numericValue = value.replace(/\D/g, '');
                                setFieldValue(
                                  'developmentQuotientResults.communicationDQScore',
                                  numericValue,
                                );
                              }}
                              type="text"
                              Value={
                                values.developmentQuotientResults
                                  .communicationDQScore
                              }
                              disabled={uploadedToTBR}
                            />
                          </Grid>

                          <Grid item xs={4} className="pt-md">
                            <Textinput
                              name="respectiveCommunicationScore"
                              labelid={'BDI2.respectiveCommunicationScore'}
                              defaultlabelid="Receptive  Communication Raw Score"
                              Required={false}
                              sxProps={{ background: 'white' }}
                              inputProps={{
                                maxLength: 5,
                              }}
                              handlechange={(value) => {
                                const numericValue = value.replace(/\D/g, '');
                                setFieldValue(
                                  'subDomainsResults.respectiveCommunicationScore',
                                  numericValue,
                                );
                              }}
                              type="text"
                              Value={
                                values.subDomainsResults
                                  .respectiveCommunicationScore
                              }
                              disabled={uploadedToTBR}
                            />
                          </Grid>

                          <Grid item xs={4} className="pt-md">
                            <Textinput
                              name="expressiveCommunicationScore"
                              labelid={'BDI2.ExpressiveCommunicationScore'}
                              defaultlabelid="Expressive Communication Raw Score"
                              Required={false}
                              sxProps={{ background: 'white' }}
                              inputProps={{
                                maxLength: 5,
                              }}
                              handlechange={(value) => {
                                const numericValue = value.replace(/\D/g, '');
                                setFieldValue(
                                  'subDomainsResults.expressiveCommunicationScore',
                                  numericValue,
                                );
                              }}
                              type="text"
                              Value={
                                values.subDomainsResults
                                  .expressiveCommunicationScore
                              }
                              disabled={uploadedToTBR}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Box>

                  {/*Motor*/}
                  <Box className="mb-lg">
                    <Card
                      className="formCardview p-sm gray3 borderRadius4"
                      sx={{
                        border: '1px solid #00C6B8',
                        background: '#ECF9F8',
                      }}
                    >
                      <MediumTypography
                        label={'Motor'}
                        sxProps={{
                          marginTop: '10px',
                          fontWeight: 'bold',
                        }}
                      />
                      <Box component="div" className="mt-xlg mb-xlg">
                        <Grid container spacing={3}>
                          <Grid item xs={4} className="pt-md">
                            <Textinput
                              name="motorDQScore"
                              labelid={'BDI2.MotorDQScore'}
                              defaultlabelid="Motor DQ Score"
                              Required={false}
                              sxProps={{ background: 'white' }}
                              inputProps={{
                                maxLength: 5,
                              }}
                              handlechange={(value) => {
                                const numericValue = value.replace(/\D/g, '');
                                setFieldValue(
                                  'developmentQuotientResults.motorDQScore',
                                  numericValue,
                                );
                              }}
                              type="text"
                              Value={
                                values.developmentQuotientResults.motorDQScore
                              }
                              disabled={uploadedToTBR}
                            />
                          </Grid>

                          <Grid item xs={4} className="pt-md">
                            <Textinput
                              name="grossMotorScore"
                              labelid={'BDI2.GrossMotorScore'}
                              defaultlabelid="Gross Motor Raw Score"
                              Required={false}
                              sxProps={{ background: 'white' }}
                              inputProps={{
                                maxLength: 5,
                              }}
                              handlechange={(value) => {
                                const numericValue = value.replace(/\D/g, '');
                                setFieldValue(
                                  'subDomainsResults.grossMotorScore',
                                  numericValue,
                                );
                              }}
                              type="text"
                              Value={values.subDomainsResults.grossMotorScore}
                              disabled={uploadedToTBR}
                            />
                          </Grid>

                          <Grid item xs={4} className="pt-md">
                            <Textinput
                              name="fineMotorScore"
                              labelid={'fineMotorScore'}
                              defaultlabelid="Fine Motor Raw Score"
                              Required={false}
                              sxProps={{ background: 'white' }}
                              inputProps={{
                                maxLength: 5,
                              }}
                              handlechange={(value) => {
                                const numericValue = value.replace(/\D/g, '');
                                setFieldValue(
                                  'subDomainsResults.fineMotorScore',
                                  numericValue,
                                );
                              }}
                              type="text"
                              Value={values.subDomainsResults.fineMotorScore}
                              disabled={uploadedToTBR}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={4} className="pt-xlg mt-md">
                            <Textinput
                              name="perceptualMotorScore"
                              labelid={'BDI2.Perceptual Motor Score'}
                              defaultlabelid="Perceptual Motor Raw Score"
                              Required={false}
                              sxProps={{ background: 'white' }}
                              inputProps={{
                                maxLength: 5,
                              }}
                              handlechange={(value) => {
                                const numericValue = value.replace(/\D/g, '');
                                setFieldValue(
                                  'subDomainsResults.perceptualMotorScore',
                                  numericValue,
                                );
                              }}
                              type="text"
                              Value={
                                values.subDomainsResults.perceptualMotorScore
                              }
                              disabled={
                                uploadedToTBR ||
                                perceptualMotorScoreNotApplicable
                              }
                            />
                            <CheckBoxComponent
                              defaultlabelid="Not Applicable"
                              labelid="Not Applicable"
                              ischecked={perceptualMotorScoreNotApplicable}
                              CheckHandleChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                setPerceptualMotorScoreNotApplicable(
                                  e.target.checked,
                                );
                                setFieldValue(
                                  'subDomainsResults.perceptualMotorScore',
                                  e.target.checked ? 'Not Applicable' : '',
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Box>

                  {/*Cognitive*/}
                  <Card
                    className="formCardview p-sm gray3 borderRadius4"
                    sx={{
                      border: '1px solid #00C6B8',
                      background: '#ECF9F8',
                    }}
                  >
                    <MediumTypography
                      label={'Cognitive'}
                      sxProps={{
                        marginTop: '10px',
                        fontWeight: 'bold',
                      }}
                    />
                    <Box component="div" className="mt-xlg">
                      <Grid container spacing={3}>
                        <Grid item xs={4} className="pt-md">
                          <Textinput
                            name="cognitiveDQScore"
                            labelid={'BDI2.cognitiveDQScore'}
                            defaultlabelid="Cognitive DQ Score"
                            Required={false}
                            sxProps={{ background: 'white' }}
                            inputProps={{
                              maxLength: 5,
                            }}
                            handlechange={(value) => {
                              const numericValue = value.replace(/\D/g, '');
                              setFieldValue(
                                'developmentQuotientResults.cognitiveDQScore',
                                numericValue,
                              );
                            }}
                            type="text"
                            Value={
                              values.developmentQuotientResults.cognitiveDQScore
                            }
                            disabled={uploadedToTBR}
                          />
                        </Grid>

                        <Grid item xs={4} className="pt-md">
                          <Textinput
                            name="attentionMemoryScore"
                            labelid={'BDI2.AttentionMemoryScore'}
                            defaultlabelid="Attention & Memory Raw Score"
                            Required={false}
                            sxProps={{ background: 'white' }}
                            inputProps={{
                              maxLength: 5,
                            }}
                            handlechange={(value) => {
                              const numericValue = value.replace(/\D/g, '');
                              setFieldValue(
                                'subDomainsResults.attentionMemoryScore',
                                numericValue,
                              );
                            }}
                            type="text"
                            Value={
                              values.subDomainsResults.attentionMemoryScore
                            }
                            disabled={uploadedToTBR}
                          />
                        </Grid>

                        <Grid item xs={4} className="pt-md">
                          <Textinput
                            name="reasoningAcademicSkillsScore"
                            labelid={'BDI2.ReasoningAcademicSkillsScore'}
                            defaultlabelid="Reasoning & Academic Skills Raw Score"
                            Required={false}
                            sxProps={{ background: 'white' }}
                            inputProps={{
                              maxLength: 5,
                            }}
                            handlechange={(value) => {
                              const numericValue = value.replace(/\D/g, '');
                              setFieldValue(
                                'subDomainsResults.reasoningAcademicSkillsScore',
                                numericValue,
                              );
                            }}
                            type="text"
                            Value={
                              values.subDomainsResults
                                .reasoningAcademicSkillsScore
                            }
                            disabled={
                              uploadedToTBR || reasoningScoreNotApplicable
                            }
                          />
                          <CheckBoxComponent
                            defaultlabelid="Not Applicable"
                            labelid="Not Applicable"
                            ischecked={reasoningScoreNotApplicable}
                            CheckHandleChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              setReasoningScoreNotApplicable(e.target.checked);
                              setFieldValue(
                                'subDomainsResults.reasoningAcademicSkillsScore',
                                e.target.checked ? 'Not Applicable' : '',
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={4} className="pt-xlg mt-md">
                          <Textinput
                            name="perceptionConceptsScore"
                            labelid={'BDI2.PerceptionConceptsScore'}
                            defaultlabelid="Perception & Concepts Raw Score"
                            Required={false}
                            sxProps={{ background: 'white' }}
                            inputProps={{
                              maxLength: 5,
                            }}
                            handlechange={(value) => {
                              const numericValue = value.replace(/\D/g, '');
                              setFieldValue(
                                'subDomainsResults.perceptionConceptsScore',
                                numericValue,
                              );
                            }}
                            type="text"
                            Value={
                              values.subDomainsResults.perceptionConceptsScore
                            }
                            disabled={uploadedToTBR}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                  <Box>
                    <Grid
                      className="flex__ justifyContent-FlexEnd pt-lg "
                      container
                      direction="row"
                      alignItems="right"
                    >
                      <Grid item>
                        <ButtonComponent
                          className="btn-primary btn-cancel btn_width_90px mr-md"
                          variantType="outlined"
                          labelId="cancelText"
                          defaultLabelId="Cancel"
                          onClick={() => {
                            setPersonalRespScoreNotApplicable(false);
                            setPerceptualMotorScoreNotApplicable(false);
                            setReasoningScoreNotApplicable(false);
                            setPeerInteractionNotAplicable(false);
                            setModalVisible(false);
                            setInitialValues({
                              completed: false,
                              dateOfAssessment: null,
                              status: '',
                              type: '',
                              examiners: [
                                {
                                  userId: '',
                                  role: '',
                                },
                              ],
                              subDomainsResults: {
                                selfCareScore: '',
                                personalResponsibilityScore: '',
                                adultInteractionScore: '',
                                peerInteractionScore: '',
                                selfConceptSocialRoleScore: '',
                                respectiveCommunicationScore: '',
                                expressiveCommunicationScore: '',
                                grossMotorScore: '',
                                fineMotorScore: '',
                                perceptualMotorScore: '',
                                attentionMemoryScore: '',
                                reasoningAcademicSkillsScore: '',
                                perceptionConceptsScore: '',
                              },
                              developmentQuotientResults: {
                                adaptiveDQScore: '',
                                personalSocialDQScore: '',
                                communicationDQScore: '',
                                motorDQScore: '',
                                cognitiveDQScore: '',
                              },
                            });
                            formRef.current?.resetForm();
                          }}
                        />

                        <ButtonComponent
                          className="btn-primary btn-submit"
                          variantType="contained"
                          labelId={editFlag ? 'BDI2.Edit' : 'BDI2.Save'}
                          defaultLabelId="Save"
                          onClick={handleSubmit}
                          disabled={uploadedToTBR}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </Box>

      {uploadToTBRModal && (
        <ModalPopup
          open={uploadToTBRModal}
          onCancel={() => {
            setUploadToTBRModal(false);
          }}
          description={'BDI2.UploadToTBRText'}
          onOk={() => uploadToTBR()}
          labelId1="Clientpage.Nobtn"
          negativeActionLabel="cancelText"
          labelId2="Clientpage.Yesbtn"
          positiveActionLabel="deleteText"
        />
      )}

      {infoModal && (
        <InformationModal
          open={infoModal}
          titleLabel="Information"
          titleDefaultLabel="Information"
          buttonLabel="Ok"
          buttonDefaultLabel="Ok"
          onClose={handleCloseInfoModal}
          info={info}
        />
      )}
    </Box>
  );
};

export default OthersFormsList;
