import React, { createContext, useContext } from 'react';
import Box from '@mui/material/Box';
import backarrow from '../../assets/images/backarrow.svg';
import TabNavigationComponent from '../../components/formlib/TabNavigationComponent';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FACESHEET } from '../../routes/Routing';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';
import { Tooltip } from '@mui/material';

export interface ClientNameType {
  clientnameString: (value: string) => void;
}

export const ClientContext = createContext<ClientNameType | undefined>(
  undefined,
);

export default function SubHeaderComponent() {
  const navigate = useNavigate();
  const [Client, setClient] = React.useState('');
  const { lookupsDemographics } = useContext(
    LookUpContext,
  ) as LookUpContextType;

  const clientnameString = (value: string) => {
    setClient(value);
  };

  const contextValue: ClientNameType = {
    clientnameString,
  };

  React.useEffect(() => {
    const clientFromLocalStorage = localStorage.getItem('clientName');
    if (clientFromLocalStorage) {
      setClient(clientFromLocalStorage);
    }
  }, [localStorage.getItem('clientName')]);

  React.useEffect(() => {
    if (lookupsDemographics !== null)
      setClient(
        lookupsDemographics.firstName +
          ' ' +
          lookupsDemographics.middleName +
          ' ' +
          lookupsDemographics.lastName,
      );
  }, [lookupsDemographics]);

  return (
    <>
      <ClientContext.Provider value={contextValue}>
        <div style={{ width: '100%' }}>
          <Box component="main" sx={{ flexGrow: 1, bgcolor: '#E7F0F0' }}>
            <Box
              display="flex"
              alignItems={'center'}
              padding={'20px 0px 8px 0px'}
            >
              <img
                src={backarrow}
                alt="backarrow"
                onClick={() => {
                  const clientId = localStorage.getItem('ClientId');
                  if (clientId !== null) {
                    navigate(FACESHEET);
                  }
                }}
                style={{ cursor: 'pointer', marginTop: '5px' }}
              />
              <Box className="addnewclient">
                {Client ? (
                  <Tooltip
                    title={Client}
                    arrow={true}
                    followCursor
                    placement="bottom"
                  >
                    <Box
                      sx={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        width: '600px',
                      }}
                    >
                      {Client}
                    </Box>
                  </Tooltip>
                ) : (
                  <FormattedMessage
                    id="Dashboard.AddClient"
                    defaultMessage="Add New Client"
                  />
                )}
              </Box>
            </Box>
            <Box>
              <TabNavigationComponent />
            </Box>
          </Box>
        </div>
      </ClientContext.Provider>
    </>
  );
}
