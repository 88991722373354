import { OptionType } from '../utils/type';

export const contactTypes = [
  'contactType1',
  'contactType2',
  'contactType3',
  'contactType4',
  'contactType5',
  'contactType6',
];

export const RelationShips = [
  'Father',
  'Mother',
  'Grand Parent',
  'Foster',
  'Guardian',
];

export const Languages = [
  'Arabic',
  'English',
  'Haitian Creole',
  'Portuguese',
  'Spanish',
  'Vietnamese',
];

export const LanguagesValues: OptionType[] = [
  { id: 'Arabic', label: 'Arabic' },
  { id: 'English', label: 'English' },
  { id: 'American Sign Language', label: 'American Sign Language' },
  { id: 'Chinese', label: 'Chinese' },
  { id: 'Crioula (Cape Verdean)', label: 'Crioula (Cape Verdean)' },
  { id: 'Haitian Creole', label: 'Haitian Creole' },
  { id: 'Khmer', label: 'Khmer' },
  { id: 'Laotian', label: 'Laotian' },
  { id: 'Portuguese', label: 'Portuguese' },
  { id: 'Spanish', label: 'Spanish' },
  { id: 'Vietnamese', label: 'Vietnamese' },
];

export const Race = [
  'American Indian/Alaska Native',
  'Asian',
  'Black or African American',
  'Native Hawaiian or Other Pacific Islander',
  'White',
];

export const RaceValues: OptionType[] = [
  {
    id: 'American Indian/Alaska Native',
    label: 'American Indian/Alaska Native',
  },
  { id: 'Asian', label: 'Asian' },
  { id: 'Black or African American', label: 'Black or African American' },
  {
    id: 'Native Hawaiian or Other Pacific Islander',
    label: 'Native Hawaiian or Other Pacific Islander',
  },
  { id: 'White', label: 'White' },
];

export const EthnicityValues: OptionType[] = [
  { id: 'Not Hispanic/Latino', label: 'Not Hispanic/Latino' },
  { id: 'Hispanic/Latino', label: 'Hispanic/Latino' },
];

export const CultureValues: OptionType[] = [
  { id: 'African', label: 'African' },
  { id: 'African American', label: 'African American' },
  { id: 'American (USA & Canada)', label: 'American (USA & Canada)' },
  { id: 'Asian Indian/Pakistani', label: 'Asian Indian/Pakistani' },
  { id: 'Brazilian', label: 'Brazilian' },
  { id: 'Cambodian', label: 'Cambodian' },
  { id: 'Cape Verdean', label: 'Cape Verdean' },
  { id: 'Caribbean Islander', label: 'Caribbean Islander' },
  { id: 'Central American', label: 'Central American' },
  { id: 'Chinese', label: 'Chinese' },
  { id: 'Cuban', label: 'Cuban' },
  { id: 'Dominican', label: 'Dominican' },
  { id: 'European', label: 'European' },
  { id: 'Haitian', label: 'Haitian' },
  { id: 'Japanese', label: 'Japanese' },
  { id: 'Korean', label: 'Korean' },
  { id: 'Laotian (including Hmong)', label: 'Laotian (including Hmong)' },
  {
    id: 'Mexican, Mexican American, Chicano',
    label: 'Mexican, Mexican American, Chicano',
  },
  { id: 'Middle Eastern', label: 'Middle Eastern' },
  { id: 'Portuguese', label: 'Portuguese' },
  { id: 'Puerto Rican', label: 'Puerto Rican' },
  { id: 'Russian', label: 'Russian' },
  { id: 'Salvadoran', label: 'Salvadoran' },
  { id: 'South American', label: 'South American' },
  { id: 'Vietnamese', label: 'Vietnamese' },
];

export const primaryResidenceValues: OptionType[] = [
  { id: 'Family Home', label: 'Family Home' },
  { id: 'Foster Home (Kinship)', label: 'Foster Home (Kinship)' },
  { id: 'Foster Home (Non-Relative)', label: 'Foster Home (Non-Relative)' },
  {
    id: 'Grandparent’s Home (Non-Foster)',
    label: 'Grandparent’s Home (Non-Foster)',
  },
  {
    id: 'Other Relative’s Home (Non-Foster)',
    label: 'Other Relative’s Home (Non-Foster)',
  },
  {
    id: 'Shelter (includes Hotels, etc.)',
    label: 'Shelter (includes Hotels, etc.)',
  },
];

export const HispanicLatino = {
  title: 'HispanicLatino',
  options: [
    'Central Americano',
    'Cubano',
    'Dominicano',
    'Mexican, Chicano',
    'Puerto Ricano',
    'Salvadorano',
    'South Americano',
    'Unknown Hispanico',
    'Other Hispanic',
  ],
};

export const NotHispanicLatino = {
  title: 'NotHispanicLatino',
  options: [
    'Africano',
    'African Americano',
    'American (USA & Canada)',
    'Asian Indian/Pakistani',
    'Braziliano',
    'Cambodiano',
    'Cape Verdeano',
    'Caribbean Islandero',
    'Chinese',
    'Europeano',
    'Haitiano',
    'Japanese',
    'Koreano',
    'Laotian (incl. Hmong)',
    'Middle Eastern',
    'Portuguese',
    'Russian',
    'Vietnamese',
    'Unknown',
    'Other',
  ],
};

export const SchedulerFrequency = [
  'Daily',
  'Weekly',
  'Biweekly',
  'Monthly',
  'Yearly',
];

export const Gender = ['Female', 'Male', 'Unassigned'];

export const contacts = [
  'Family',
  'Provider/Medical Specialist',
  'Specialty Service Provider',
  'Local Education Authority',
  'Insurance',
  'Referral Contact',
  'Others',
];

export const RelationshipFamily = [
  'Father',
  'Mother',
  'Foster Parent',
  'Guardian',
  'Sibling',
  'Grand Parent',
  'Aunt',
  'Uncle',
  'Other',
];

export const maritalStatusTypes = ['Single', 'Married', 'Widowed', 'Divorced'];

export const Types = ['Primary', 'Secondary'];

export const Designation = [
  'Pediatrician',
  'Primary Care Physician (PCP)',
  'Medical Specialist',
  'Child Care/Day Care Provider',
];

export const SSPService = [
  'Hearing',
  'Vision',
  'Autism',
  'Hippotherapy',
  ' Applied Behavior Analysis (ABA)',
];

export const initialCheckboxData = [
  'Administrative Contact',
  'Catchment area-specific',
  'Send Intakes',
];

export const eligibilityDeterminationDiagnosisType: OptionType[] = [
  {
    id: '1 year',
    label: '1 year',
  },
  { id: '3 years', label: '3 years' },
];

export const DisciplineList: OptionType[] = [
  {
    id: 'Developmental Specialist',
    label: 'Developmental Specialist',
  },
  { id: 'Occupational Therapist', label: 'Occupational Therapist' },
  { id: 'Physical Therapist', label: 'Physical Therapist' },
  { id: 'Speech/Lang Pathologist', label: 'Speech/Lang Pathologist' },
  { id: 'Social Worker', label: 'Social Worker' },
  { id: 'Mental Health Counselor', label: 'Mental Health Counselor' },
  { id: 'Nurse', label: 'Nurse' },
  { id: 'Music Therapist', label: 'Music Therapist' },
  { id: 'Nutritionist', label: 'Nutritionist' },
  { id: 'Occupational Therapy Asst', label: 'Occupational Therapy Asst' },
  { id: 'Speech Therapy Asst', label: 'Speech Therapy Asst' },
  { id: 'Physical Therapy Asst', label: 'Physical Therapy Asst' },
  { id: 'Specialty Service Provider', label: 'Specialty Service Provider' },
  { id: 'BCBA/LABA', label: 'BCBA/LABA' },
  { id: 'Not Applicable', label: 'Not Applicable' },
];

export const weekList: OptionType[] = [
  {
    id: 'Day',
    label: 'Day',
  },
  { id: 'Week', label: 'Week' },
  { id: 'Month', label: 'Month' },
  { id: 'All', label: 'All' },
];

export const schedulerViewType: OptionType[] = [
  {
    id: 'timeGridDay',
    label: 'Day',
  },
  { id: 'timeGridWeek', label: 'Week' },
  { id: 'dayGridMonth', label: 'Month' },
];
