import React, { FC } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import MediumTypography from '../MediumTypography';
import ButtonComponent from '../ButtonComponent';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

const DiagnosticCodeModal: FC<ModalProps> = ({ open, handleClose }) => {
  return (
    <Box component="main">
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={'md'}
        className="imagePreviewModal"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <MediumTypography
            labelid="diagnosticCodelist.modalText"
            defaultlabel="”All children diagnosed with one of the following non-asterisked established conditions that have a high probability of resulting in developmental delay, are eligible for early intervention services until the child’s third birthday, unless a change in the status of a diagnosis or condition resolves with medical/surgical treatment.  Those diagnoses or conditions that may improve are denoted on the list with an asterisk * and are Underlined.  These conditions can be entered on only ONE evaluation and will have an eligibility timeframe of one year.  The evaluation having this condition does NOT have to be the initial evaluation.  No other evaluations for this child can have this same asterisked established condition”"
          />
          <Box>
            <Grid
              className="flex__ justifyContent-FlexEnd pt-md pb-lg "
              container
              direction="row"
              alignItems="right"
            >
              <Grid item>
                <ButtonComponent
                  className="btn-primary btn-submit"
                  variantType="contained"
                  type="submit"
                  defaultLabelId="Ok"
                  labelId="Clientpage.Okbtn"
                  onClick={handleClose}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DiagnosticCodeModal;
