import { Box, Card, Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { EICSRadioData, RoutingProps } from './IntakeClientsInfo';
import MediumTypography from '../../components/formlib/MediumTypography';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import CommentTextbox from '../../components/formlib/CommentTextbox';
import CustomCheckBox from '../../components/formlib/CustomCheckBox';
import Textinput from '../../components/formlib/Textinput';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { ReactComponent as DownArrow } from '../../assets/images/downarrowcolored.svg';
import { ReactComponent as UpArrow } from '../../assets/images/uparrowcolored.svg';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import {
  IntakePregnancyInfoResponseType,
  getIntakePregnancyInfo,
  putIntakePregnancyInfo,
} from '../../services/configApi/forms/Intake/IntakeServices';
import { ResponseType } from '../../utils/type';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import {
  formatPhoneNumber,
  nameValidationWithNumeric,
} from '../../utils/formValidationUtil';
import pregnancyProblemsJsonData from './IntakeJSON/PregnancyTab/PregnancyProblems.json';
import deliveryMethodJsonData from './IntakeJSON/PregnancyTab/DeliveryMethod.json';
import specialCareBirthAtJsonData from './IntakeJSON/PregnancyTab/SpecialCareBirthAt.json';
import treatmentsJsonData from './IntakeJSON/PregnancyTab/Treatments.json';
import ModalPopup from '../../components/formlib/ModalPopup';
import {
  DownloadContext,
  DownloadContextType,
  showCancelToaster,
} from './IntakeTabNavigationComponent';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { datePickerMinDateMaxDateValidate } from '../../utils/dateUtil';

export const IntakeRadioData = [
  {
    value: 'Yes',
    labelId: 'Intake.RadioLabelA',
    defaultLabel: 'Yes',
  },
  {
    value: 'No',
    labelId: 'Intake.RadioLabelB',
    defaultLabel: 'No',
  },
];

export const BirthRadioData = [
  {
    value: 'In Pounds',
    labelId: 'Intake.BirthRadioLabelA',
    defaultLabel: 'In Pounds',
  },
  {
    value: 'In Grams',
    labelId: 'Intake.BirthRadioLabelB',
    defaultLabel: 'In Grams',
  },
  {
    value: 'Unknown',
    labelId: 'Intake.RadioLabelC',
    defaultLabel: 'Unknown',
  },
];

export interface IntakePregnancyInfoType {
  prenatalCare: string;
  prenatalCareStart: string;
  prenatalCareRecievedAt: string;
  pregnancyProblems: string;
  pregnancyProblemOther: string;
  prenatalTests: string;
  problemWhenChildBorn: string;
  problemWhenChildBornDeal: string;
  problemWhenChildBornNow: string;
  alcoholTobaccoUse: string;
  recreationalDrugUse: string;
  medicationDuringTrimester: string;
  complicationWithPregnancy: string;
  babyBornOnTime: string;
  babyBornEarlyOrLate: string;
  gestationalAgeCheck: boolean;
  gestationalAge: string;
  labor: string;
  caesareanExplain: string;
  considerationsAboutLaborOther: string;
  diagnosisOfSubstanceExposedNewborn: string;
  medicationsAnesthesia: string;
  birthWeight: string;
  weightInLbs: string;
  weightInOz: string;
  weightInGrams: string;
  multipleBirth: string;
  neonatalCourse: string;
  specialCareAfterBirth: string;
  hospitalNameNICU: string;
  hospitalPhoneNoNICU: string;
  howLongNICU: string;
  noOfDaysCCN: string;
  hospitalNameSC: string;
  hospitalPhoneNoSC: string;
  howLongSC: string;
  hospitalNameRN: string;
  hospitalPhoneNoRN: string;
  howLongRN: string;
  roomedInRN: string;
  motherInHospital: string;
  dischargeDate: string | null;
  treatmentOther: string;
  newbornHearingScreening: string;
  specialNeedsMedicalEquipment: string;
  hearingEvaluation: string;
  hearingEvaluationDesc: string;
  visionScreening: string;
  visionScreeningDesc: string;
  hearingVisionConcerns: string;
  childInNICU: string;
  daysInNICU: string;
  daysInNICUCheck: boolean;
}

const IntakePregnancyInitialValues = {
  prenatalCare: '',
  prenatalCareStart: '',
  prenatalCareRecievedAt: '',
  pregnancyProblems: '',
  pregnancyProblemName: [],
  pregnancyProblemOther: '',
  prenatalTests: '',
  problemWhenChildBorn: '',
  problemWhenChildBornDeal: '',
  problemWhenChildBornNow: '',
  alcoholTobaccoUse: '',
  recreationalDrugUse: '',
  medicationDuringTrimester: '',
  complicationWithPregnancy: '',
  babyBornOnTime: '',
  babyBornEarlyOrLate: '',
  gestationalAgeCheck: false,
  gestationalAge: '',
  labor: '',
  deliveryMethodNames: [],
  caesareanExplain: '',
  considerationsAboutLaborOther: '',
  diagnosisOfSubstanceExposedNewborn: '',
  medicationsAnesthesia: '',
  birthWeight: '',
  weightInLbs: '',
  weightInOz: '',
  weightInGrams: '',
  multipleBirth: '',
  neonatalCourse: '',
  specialCareAfterBirth: '',
  specialCareAfterBirthAt: [],
  hospitalNameNICU: '',
  hospitalPhoneNoNICU: '',
  howLongNICU: '',
  noOfDaysCCN: '',
  hospitalNameSC: '',
  hospitalPhoneNoSC: '',
  howLongSC: '',
  hospitalNameRN: '',
  hospitalPhoneNoRN: '',
  howLongRN: '',
  roomedInRN: '',
  motherInHospital: '',
  dischargeDate: null,
  treatmentName: [],
  treatmentOther: '',
  newbornHearingScreening: '',
  specialNeedsMedicalEquipment: '',
  hearingEvaluation: '',
  hearingEvaluationDesc: '',
  visionScreening: '',
  visionScreeningDesc: '',
  hearingVisionConcerns: '',
  childInNICU: '',
  daysInNICU: '',
  daysInNICUCheck: false,
};

const validationSchema = yup.object().shape({
  prenatalCare: yup.string().nullable(),
  prenatalCareStart: yup.string().nullable(),
  prenatalCareRecievedAt: yup.string().nullable(),
  pregnancyProblems: yup.string().nullable(),
  pregnancyProblemOther: yup.string().nullable(),
  prenatalTests: yup.string().nullable(),
  problemWhenChildBorn: yup.string().nullable(),
  problemWhenChildBornDeal: yup.string().nullable(),
  problemWhenChildBornNow: yup.string().nullable(),
  alcoholTobaccoUse: yup.string().nullable(),
  recreationalDrugUse: yup.string().nullable(),
  medicationDuringTrimester: yup.string().nullable(),
  complicationWithPregnancy: yup.string().nullable(),
  babyBornOnTime: yup.string().nullable(),
  babyBornEarlyOrLate: yup.string().nullable(),
  gestationalAgeCheck: yup.boolean().nullable(),
  gestationalAge: yup.string().nullable(),
  labor: yup.string().nullable(),
  caesareanExplain: yup.string().nullable(),
  considerationsAboutLaborOther: yup.string().nullable(),
  diagnosisOfSubstanceExposedNewborn: yup.string().nullable(),
  medicationsAnesthesia: yup.string().nullable(),
  birthWeight: yup.string().nullable(),
  weightInLbs: yup.string().when('birthWeight', {
    is: 'In Pounds',
    then: yup.string().required('Required').nullable(),
    otherwise: yup.string().nullable(),
  }),
  weightInOz: yup.string().when('birthWeight', {
    is: 'In Pounds',
    then: yup.string().required('Required').nullable(),
    otherwise: yup.string().nullable(),
  }),
  weightInGrams: yup.string().when('birthWeight', {
    is: 'In Grams',
    then: yup.string().required('Required').nullable(),
    otherwise: yup.string().nullable(),
  }),
  multipleBirth: yup.string().nullable(),
  neonatalCourse: yup.string().nullable(),
  specialCareAfterBirth: yup.string().nullable(),
  hospitalNameNICU: yup.string().nullable(),
  hospitalPhoneNoNICU: yup.string().nullable(),
  howLongNICU: yup.string().nullable(),
  noOfDaysCCN: yup.string().nullable(),
  hospitalNameSC: yup.string().nullable(),
  hospitalPhoneNoSC: yup.string().nullable(),
  howLongSC: yup.string().nullable(),
  hospitalNameRN: yup.string().nullable(),
  hospitalPhoneNoRN: yup.string().nullable(),
  howLongRN: yup.string().nullable(),
  roomedInRN: yup.string().nullable(),
  motherInHospital: yup.string().nullable(),
  dischargeDate: yup.date().nullable().nullable(),
  treatmentOther: yup.string().nullable(),
  newbornHearingScreening: yup.string().nullable(),
  specialNeedsMedicalEquipment: yup.string().nullable(),
  hearingEvaluation: yup.string().nullable(),
  hearingEvaluationDesc: yup.string().nullable(),
  visionScreening: yup.string().nullable(),
  visionScreeningDesc: yup.string().nullable(),
  hearingVisionConcerns: yup.string().nullable(),
  childInNICU: yup.string().nullable(),
  daysInNICU: yup.string().when('childInNICU', {
    is: 'Yes',
    then: yup.string().required('Required'),
    otherwise: yup.string().nullable(),
  }),
  daysInNICUCheck: yup.boolean().nullable(),
});

const validateForm = (value: IntakePregnancyInfoType) => {
  const errors: Partial<IntakePregnancyInfoType> = {};

  if (datePickerMinDateMaxDateValidate(value.dischargeDate)) {
    errors.dischargeDate = 'datePickerMinDateMaxDateValidate';
  }
  return errors;
};

const IntakePregnancyInfo: React.FC<RoutingProps> = ({ setTabProp }) => {
  const clientId = localStorage.getItem('ClientId');
  const [intakePregnancyInfo, setIntakePregnancyInfo] =
    useState<IntakePregnancyInfoType>(IntakePregnancyInitialValues);

  const [pregnancyProblemsData, setPregnancyProblemsData] = useState(
    pregnancyProblemsJsonData,
  );

  const [pregnancyProblemsNames, setPregnancyProblemsNames] = useState<
    string[]
  >([]);

  const [deliveryMethodData, setDeliveryMethodData] = useState(
    deliveryMethodJsonData,
  );

  const [deliveryMethodNames, setDeliveryMethodNames] = useState<string[]>([]);

  const [specialCareBirthAtData, setSpecialCareBirthAtData] = useState(
    specialCareBirthAtJsonData,
  );

  const [specialCareBirthAtNames, setSpecialCareBirthAtNames] = useState<
    string[]
  >([]);

  const [treatmentsData, setTreatmentsData] = useState(treatmentsJsonData);

  const [treatmentsNames, setTreatmentsNames] = useState<string[]>([]);

  const [openEICSSection, setOpenEICSSection] = useState(true);

  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;

  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = useState<string>();
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');
  const [openModal, setOpenModal] = useState(false);
  const [viewToBeLocked, setViewToBeLocked] = useState<boolean>(false);
  const [intakePregnancyInfoSavedValues, setIntakePregnancyInfoSavedValues] =
    useState<IntakePregnancyInfoResponseType>();
  const parentRef = React.useRef<HTMLDivElement>(null);

  const { toggleDownload } = React.useContext(
    DownloadContext,
  ) as DownloadContextType;

  useEffect(() => {
    const handleIntakeGet = async () => {
      handleIntakeGetAPI();
    };
    handleIntakeGet();
  }, []);

  const handleIntakeGetAPI = async () => {
    window.scrollTo(0, 0);
    if (clientId) {
      toggleLoader(true);
      getIntakePregnancyInfo(clientId)
        .then((response: IntakePregnancyInfoResponseType) => {
          if (response.readyToDownload)
            toggleDownload(response.readyToDownload);
          if (response.isSigned) {
            setViewToBeLocked(true);
          } else {
            if (response.isCurrentlyHeld) {
              setViewToBeLocked(true);

              showCancelToaster(response.heldBy);
            } else {
              // unlock the page
              if (
                checkPermissionForFeature('backend.clients', 'editPermission')
              ) {
                setViewToBeLocked(false);
              } else {
                setViewToBeLocked(true);
              }
            }
          }
          setIntakePregnancyInfoSavedValues(response);
          setIntakePregnancyInfo({
            prenatalCare: response.prenatal.prenatalCare,
            prenatalCareStart: response.prenatal.prenatalCareStart,
            prenatalCareRecievedAt: response.prenatal.prenatalCareRecievedAt,
            pregnancyProblems: response.pregnancy.pregnancyProblems,
            pregnancyProblemOther: response.pregnancy.pregnancyProblemOther,
            prenatalTests: response.prenatal.prenatalTests,
            problemWhenChildBorn: response.childBorn.problemWhenChildBorn,
            problemWhenChildBornDeal:
              response.childBorn.problemWhenChildBornDeal,
            problemWhenChildBornNow: response.childBorn.problemWhenChildBornNow,
            alcoholTobaccoUse: response.drugUse.alcoholTobaccoUse,
            recreationalDrugUse: response.drugUse.recreationalDrugUse,
            medicationDuringTrimester:
              response.drugUse.medicationDuringTrimester,
            complicationWithPregnancy:
              response.pregnancy.complicationWithPregnancy,
            babyBornOnTime: response.childBorn.babyBornOnTime,
            babyBornEarlyOrLate: response.childBorn.babyBornEarlyOrLate,
            gestationalAgeCheck:
              response.eics.gestationalAge !== 'Unknown' ? false : true,
            gestationalAge: response.eics.gestationalAge,
            labor: response.delivery.labor,
            caesareanExplain: response.delivery.caesareanExplain,
            considerationsAboutLaborOther:
              response.delivery.considerationsAboutLaborOther,
            diagnosisOfSubstanceExposedNewborn:
              response.eics.diagnosisOfSubstanceExposedNewborn,
            medicationsAnesthesia: response.drugUse.medicationsAnesthesia,
            birthWeight: response.eics.birthWeight,
            weightInLbs: response.eics.weightInLbs,
            weightInOz: response.eics.weightInOz,
            weightInGrams: response.eics.weightInGrams,
            multipleBirth: response.eics.multipleBirth,
            neonatalCourse: response.pregnancy.neonatalCourse,
            specialCareAfterBirth: response.eics.specialCareAfterBirth,
            hospitalNameNICU: response.specialCare.hospitalNameNICU,
            hospitalPhoneNoNICU: response.specialCare.hospitalPhoneNoNICU,
            howLongNICU: response.specialCare.howLongNICU,
            noOfDaysCCN: response.specialCare.noOfDaysCCN,
            hospitalNameSC: response.specialCare.hospitalNameSC,
            hospitalPhoneNoSC: response.specialCare.hospitalPhoneNoSC,
            howLongSC: response.specialCare.howLongSC,
            hospitalNameRN: response.specialCare.hospitalNameRN,
            hospitalPhoneNoRN: response.specialCare.hospitalPhoneNoRN,
            howLongRN: response.specialCare.howLongRN,
            roomedInRN: response.specialCare.roomedInRN,
            motherInHospital: response.delivery.motherInHospital,
            dischargeDate: response.delivery.dischargeDate
              ? response.delivery.dischargeDate
              : null,
            treatmentOther: response.tests.treatmentOther,
            newbornHearingScreening: response.tests.newbornHearingScreening,
            specialNeedsMedicalEquipment:
              response.tests.specialNeedsMedicalEquipment,
            hearingEvaluation: response.tests.hearingEvaluation,
            hearingEvaluationDesc: response.tests.hearingEvaluationDesc,
            visionScreening: response.tests.visionScreening,
            visionScreeningDesc: response.tests.visionScreeningDesc,
            hearingVisionConcerns: response.tests.hearingVisionConcerns,
            childInNICU: response.eics.childInNICU,
            daysInNICU: response.eics.daysInNICU,
            daysInNICUCheck:
              response.eics.daysInNICU !== 'Unknown' ? false : true,
          });

          getCheckBoxSelectedNamesGenericFunc(
            'pregnancyProblemName',
            response.pregnancy.pregnancyProblemName,
          );
          getCheckBoxSelectedNamesGenericFunc(
            'deliveryMethodNames',
            response.delivery.deliveryMethodNames,
          );
          getCheckBoxSelectedNamesGenericFunc(
            'specialCareAfterBirthAt',
            response.specialCare.specialCareAfterBirthAt,
          );
          getCheckBoxSelectedNamesGenericFunc(
            'treatmentsNames',
            response.tests.treatmentName,
          );

          toggleLoader(false);
        })
        .catch((error) => {
          toggleLoader(false);
          setSuccessOrError('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId(error);
            setToastrDefaultMessage(error);
          }
        });
    }
  };

  const formikRef = useRef<FormikProps<IntakePregnancyInfoType>>(null);

  const handlePregnancyProblemsCheckBoxClick = (
    id: number,
    checked: boolean,
  ) => {
    setPregnancyProblemsData(
      pregnancyProblemsData.map((data) => {
        if (data.id === id) {
          data.checked = checked;
        }
        setPregnancyProblemsNames((prevNames) => {
          if (data.checked) {
            return [...prevNames, data.dataLabelId];
          } else {
            return prevNames.filter((name) => name !== data.dataLabelId);
          }
        });
        if (id === 11 && !checked) {
          formikRef.current?.setFieldValue('pregnancyProblemOther', '');
        }

        return data;
      }),
    );
  };

  const handleDeliveryMethodCheckBoxClick = (id: number, checked: boolean) => {
    setDeliveryMethodData(
      deliveryMethodData.map((data) => {
        if (data.id === id) {
          data.checked = checked;
        }
        setDeliveryMethodNames((prevNames) => {
          if (data.checked) {
            return [...prevNames, data.dataLabelId];
          } else {
            return prevNames.filter((name) => name !== data.dataLabelId);
          }
        });
        if (id === 17 && !checked) {
          formikRef.current?.setFieldValue('considerationsAboutLaborOther', '');
        }
        if (id === 16 && !checked) {
          formikRef.current?.setFieldValue('caesareanExplain', '');
        }

        return data;
      }),
    );
  };

  const handleSpecialCareBirthAtCheckBoxClick = (
    id: number,
    checked: boolean,
  ) => {
    setSpecialCareBirthAtData(
      specialCareBirthAtData.map((data) => {
        if (data.id === id) {
          data.checked = checked;
        }
        setSpecialCareBirthAtNames((prevNames) => {
          if (data.checked) {
            return [...prevNames, data.dataLabelId];
          } else {
            return prevNames.filter((name) => name !== data.dataLabelId);
          }
        });

        if (id === 0 && !checked) {
          formikRef.current?.setFieldValue('noOfDaysCCN', '');
        }

        if (id === 1 && !checked) {
          formikRef.current?.setFieldValue('hospitalNameSC', '');
          formikRef.current?.setFieldValue('hospitalPhoneNoSC', '');
          formikRef.current?.setFieldValue('howLongSC', '');
        }

        if (id === 2 && !checked) {
          formikRef.current?.setFieldValue('hospitalNameRN', '');
          formikRef.current?.setFieldValue('hospitalPhoneNoRN', '');
          formikRef.current?.setFieldValue('howLongRN', '');
          formikRef.current?.setFieldValue('roomedInRN', '');
        }

        return data;
      }),
    );
  };

  const handleTreatmentCheckBoxClick = (id: number, checked: boolean) => {
    setTreatmentsData(
      treatmentsData.map((data) => {
        if (data.id === id) {
          data.checked = checked;
        }
        setTreatmentsNames((prevNames) => {
          if (data.checked) {
            return [...prevNames, data.dataLabelId];
          } else {
            return prevNames.filter((name) => name !== data.dataLabelId);
          }
        });

        return data;
      }),
    );
  };

  const getCheckBoxSelectedNamesGenericFunc = (
    type: string,
    selectedProblems: string[],
  ) => {
    if (type === 'pregnancyProblemName') {
      setPregnancyProblemsData(
        pregnancyProblemsData.map((data) => {
          if (selectedProblems.includes(data.dataLabelId)) {
            data.checked = true;
          } else {
            data.checked = false;
          }
          setPregnancyProblemsNames((prevNames) => {
            if (data.checked) {
              return [...prevNames, data.dataLabelId];
            } else {
              return prevNames.filter((name) => name !== data.dataLabelId);
            }
          });
          return data;
        }),
      );
    } else if (type === 'deliveryMethodNames') {
      setDeliveryMethodData(
        deliveryMethodData.map((data) => {
          if (selectedProblems.includes(data.dataLabelId)) {
            data.checked = true;
          } else {
            data.checked = false;
          }
          setDeliveryMethodNames((prevNames) => {
            if (data.checked) {
              return [...prevNames, data.dataLabelId];
            } else {
              return prevNames.filter((name) => name !== data.dataLabelId);
            }
          });
          return data;
        }),
      );
    } else if (type === 'specialCareAfterBirthAt') {
      setSpecialCareBirthAtData(
        specialCareBirthAtData.map((data) => {
          if (selectedProblems.includes(data.dataLabelId)) {
            data.checked = true;
          } else {
            data.checked = false;
          }
          setSpecialCareBirthAtNames((prevNames) => {
            if (data.checked) {
              return [...prevNames, data.dataLabelId];
            } else {
              return prevNames.filter((name) => name !== data.dataLabelId);
            }
          });
          return data;
        }),
      );
    } else if (type === 'treatmentsNames') {
      setTreatmentsData(
        treatmentsData.map((data) => {
          if (selectedProblems.includes(data.dataLabelId)) {
            data.checked = true;
          } else {
            data.checked = false;
          }
          setTreatmentsNames((prevNames) => {
            if (data.checked) {
              return [...prevNames, data.dataLabelId];
            } else {
              return prevNames.filter((name) => name !== data.dataLabelId);
            }
          });
          return data;
        }),
      );
    }
  };

  const handleFormSubmit = async (values: IntakePregnancyInfoType) => {
    const params: IntakePregnancyInfoResponseType = {
      prenatal: {
        prenatalCare: values.prenatalCare,
        prenatalCareStart: values.prenatalCareStart,
        prenatalCareRecievedAt: values.prenatalCareRecievedAt,
        prenatalTests: values.prenatalTests,
      },
      pregnancy: {
        pregnancyProblems: values.pregnancyProblems,
        pregnancyProblemName: [...new Set(pregnancyProblemsNames)],
        pregnancyProblemOther: values.pregnancyProblemOther,
        complicationWithPregnancy: values.complicationWithPregnancy,
        neonatalCourse: values.neonatalCourse,
      },
      childBorn: {
        problemWhenChildBorn: values.problemWhenChildBorn,
        problemWhenChildBornDeal: values.problemWhenChildBornDeal,
        problemWhenChildBornNow: values.problemWhenChildBornNow,
        babyBornOnTime: values.babyBornOnTime,
        babyBornEarlyOrLate: values.babyBornEarlyOrLate,
      },
      drugUse: {
        alcoholTobaccoUse: values.alcoholTobaccoUse,
        recreationalDrugUse: values.recreationalDrugUse,
        medicationDuringTrimester: values.medicationDuringTrimester,
        medicationsAnesthesia: values.medicationsAnesthesia,
      },
      delivery: {
        labor: values.labor,
        deliveryMethodNames: [...new Set(deliveryMethodNames)],
        caesareanExplain: values.caesareanExplain,
        considerationsAboutLaborOther: values.considerationsAboutLaborOther,
        motherInHospital: values.motherInHospital,
        dischargeDate: values.dischargeDate,
      },
      tests: {
        treatmentName: [...new Set(treatmentsNames)],
        treatmentOther: values.treatmentOther,
        newbornHearingScreening: values.newbornHearingScreening,
        specialNeedsMedicalEquipment: values.specialNeedsMedicalEquipment,
        hearingEvaluation: values.hearingEvaluation,
        hearingEvaluationDesc: values.hearingEvaluationDesc,
        visionScreening: values.visionScreening,
        visionScreeningDesc: values.visionScreeningDesc,
        hearingVisionConcerns: values.hearingVisionConcerns,
      },
      specialCare: {
        specialCareAfterBirthAt: [...new Set(specialCareBirthAtNames)],
        hospitalNameNICU: values.hospitalNameNICU,
        hospitalPhoneNoNICU: values.hospitalPhoneNoNICU,
        howLongNICU: values.howLongNICU,
        noOfDaysCCN: values.noOfDaysCCN,
        hospitalNameSC: values.hospitalNameSC,
        hospitalPhoneNoSC: values.hospitalPhoneNoSC,
        howLongSC: values.howLongSC,
        hospitalNameRN: values.hospitalNameRN,
        hospitalPhoneNoRN: values.hospitalPhoneNoRN,
        howLongRN: values.howLongRN,
        roomedInRN: values.roomedInRN,
      },
      eics: {
        diagnosisOfSubstanceExposedNewborn:
          values.diagnosisOfSubstanceExposedNewborn,
        gestationalAge: values.gestationalAge,
        birthWeight: values.birthWeight,
        weightInLbs: values.weightInLbs,
        weightInOz: values.weightInOz,
        weightInGrams: values.weightInGrams,
        multipleBirth: values.multipleBirth,
        specialCareAfterBirth: values.specialCareAfterBirth,
        childInNICU: values.childInNICU,
        daysInNICU: values.daysInNICU,
      },
    };

    toggleLoader(true);
    if (clientId) {
      putIntakePregnancyInfo(clientId, params)
        .then((response) => {
          if (response) {
            setSuccessOrError('success');
            setToastrId('Intake.successMsg');
            setToastrDefaultMessage('Intake updated successfully');

            setTimeout(() => {
              toggleLoader(false);
              setTabProp((value) => {
                sessionStorage.setItem(
                  'intakeTabName',
                  String(value.tabPosition),
                );
                return {
                  tabPosition: value.tabPosition,
                };
              });
            }, 1000);

            handleIntakeGetAPI();
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setSuccessOrError('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId(error);
            setToastrDefaultMessage(error);
          }
        });
    }
  };

  return (
    <>
      {toastrId && (
        <SnackBarComponent
          open={toastrId !== undefined}
          handleClose={() => {
            setToastrId(undefined);
          }}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
          successOrError={successOrError}
        />
      )}
      <Box
        ref={parentRef}
        style={{
          pointerEvents: viewToBeLocked ? 'none' : 'auto',
          opacity: viewToBeLocked ? '0.8' : 'unset',
        }}
        onKeyDownCapture={(e) => {
          if (viewToBeLocked) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        onFocus={() => {
          if (parentRef.current) parentRef.current.focus();
        }}
      >
        <Card className="formCardview gray3 borderRadius4 mb-md p-sm">
          <Formik
            initialValues={intakePregnancyInfo}
            innerRef={formikRef}
            validateOnMount={true}
            validateOnChange={true}
            enableReinitialize
            validationSchema={validationSchema}
            validate={validateForm}
            onSubmit={(values) => {
              handleFormSubmit(values);
            }}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Box component="div">
                <Box
                  component="div"
                  className="p-sm gray3 borderRadius4"
                  sx={{ border: '1px solid #00C6B8' }}
                >
                  <Box component="div" className="flex__justify__space-between">
                    <Box display="flex" flexDirection="row">
                      <MediumTypography
                        className="mr-lg"
                        labelid="IntakePregnancy.question1"
                        defaultlabel="Did you receive prenatal care ?"
                      />
                      <CustomRadioButton
                        data={IntakeRadioData}
                        value={values.prenatalCare}
                        onSelectItem={(value) => {
                          if (value) {
                            if (values.prenatalCare === 'No') {
                              setFieldValue('prenatalCareStart', '');
                              setFieldValue('prenatalCareRecievedAt', '');
                            }
                            setFieldValue('prenatalCare', value);
                          }
                        }}
                      />
                      {errors.prenatalCare && touched.prenatalCare && (
                        <MediumTypography
                          labelid={errors.prenatalCare}
                          defaultlabel="Prenatal Care is Required"
                          className="errorText-md"
                        />
                      )}
                    </Box>
                  </Box>

                  {values.prenatalCare === 'Yes' && (
                    <>
                      <Grid
                        container
                        direction={'row'}
                        columnSpacing={'40px'}
                        className="mt-md mb-lg"
                      >
                        {' '}
                        <Grid item xs={9} lg={9}>
                          <CommentTextbox
                            Value={values.prenatalCareStart}
                            placeholder="When did you start prenatal care?"
                            name="prenatalCareStart"
                            maxLength={2000}
                            handlechange={(e) => {
                              setFieldValue('prenatalCareStart', e);
                            }}
                            height="150px"
                            placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.prenatalCareStart &&
                            touched.prenatalCareStart && (
                              <MediumTypography
                                labelid={errors.prenatalCareStart}
                                defaultlabel="Prenatal Care Start is Required"
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                        <Grid item xs={9} lg={9}>
                          <CommentTextbox
                            Value={values.prenatalCareRecievedAt}
                            placeholder="Where did you receive prenatal care?"
                            name="prenatalCareRecievedAt"
                            maxLength={2000}
                            handlechange={(e) => {
                              setFieldValue('prenatalCareRecievedAt', e);
                            }}
                            height="150px"
                            placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.prenatalCareRecievedAt &&
                            touched.prenatalCareRecievedAt && (
                              <MediumTypography
                                labelid={errors.prenatalCareRecievedAt}
                                defaultlabel="Prenatal Care Received At is Required"
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <Box
                    component="div"
                    className="flex__ align__items__center mt-md"
                  >
                    <MediumTypography
                      labelid="IntakePregnancy.subtitle"
                      defaultlabel="Pregnancy (illnesses, infections, bleeding, injuries)"
                      fontSize="14px"
                      fontweight={600}
                      textColor="#00C6B8"
                    />
                  </Box>

                  <Box
                    component="div"
                    className="flex__justify__space-between mt-md"
                  >
                    <Box display="flex" flexDirection="row">
                      <MediumTypography
                        className="mr-lg"
                        labelid="IntakePregnancy.question3"
                        defaultlabel="Did you have any problems during your pregnancy ?"
                      />
                      <CustomRadioButton
                        data={IntakeRadioData}
                        value={values.pregnancyProblems}
                        onSelectItem={(value) => {
                          if (value === 'No') {
                            setPregnancyProblemsData((prevData) => {
                              return prevData.map((item) => ({
                                ...item,
                                checked: false,
                              }));
                            });
                            setFieldValue('pregnancyProblemOther', '');
                          }
                          setFieldValue('pregnancyProblems', value);
                        }}
                      />
                      {errors.pregnancyProblems &&
                        touched.pregnancyProblems && (
                          <MediumTypography
                            labelid={errors.pregnancyProblems}
                            defaultlabel="Pregnancy Problems is Required"
                            className="errorText-md"
                          />
                        )}
                    </Box>
                  </Box>

                  {values.pregnancyProblems === 'Yes' && (
                    <Box component="div" className="ml-lg mt-md">
                      <Grid container spacing={1}>
                        {pregnancyProblemsData.map((problem) => (
                          <Grid key={problem.id} item xs={4.5}>
                            <Box
                              component="div"
                              className="flex__ align__items__center"
                            >
                              <CustomCheckBox
                                value={problem.checked}
                                style={{ padding: 0, paddingRight: '8px' }}
                                onCheckBoxClick={(e) =>
                                  handlePregnancyProblemsCheckBoxClick(
                                    problem.id,
                                    e,
                                  )
                                }
                              />
                              <MediumTypography
                                label={problem.defaultDataLabelId}
                                fontweight={400}
                                textColor="#2A4241"
                              />
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}
                  {pregnancyProblemsData[pregnancyProblemsData.length - 1]
                    .checked && (
                    <Grid
                      container
                      direction={'row'}
                      columnSpacing={'40px'}
                      className="mt-md mb-lg"
                    >
                      <Grid item xs={9} lg={9}>
                        <Textinput
                          name="pregnancyProblemOther"
                          labelid="pregnancyProblemOther"
                          defaultlabelid="Other problems"
                          Value={values.pregnancyProblemOther}
                          inputProps={{ maxLength: 50 }}
                          handlechange={(text: string) => {
                            setFieldValue('pregnancyProblemOther', text);
                          }}
                          sxProps={{
                            backgroundColor: 'white',
                          }}
                          placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.pregnancyProblemOther &&
                          touched.pregnancyProblemOther && (
                            <MediumTypography
                              labelid={errors.pregnancyProblemOther}
                              defaultlabel="Description is Required"
                              className="errorText-md"
                            />
                          )}
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-md mb-lg"
                  >
                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.prenatalTests}
                        placeholder="What prenatal tests were performed during your pregnancy ?"
                        name="prenatalTests"
                        maxLength={2000}
                        handlechange={(e) => {
                          setFieldValue('prenatalTests', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.prenatalTests && touched.prenatalTests && (
                        <MediumTypography
                          labelid={errors.prenatalTests}
                          defaultlabel="Prenatal Tests is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Box
                    component="div"
                    className="flex__justify__space-between mt-sm"
                  >
                    <Box display="flex" flexDirection="row">
                      <MediumTypography
                        className="mr-lg"
                        label="Since your child was born, have you had times when you have felt down, anxious, depressed, or hopeless ?"
                      />
                      <CustomRadioButton
                        data={IntakeRadioData}
                        value={values.problemWhenChildBorn}
                        onSelectItem={(value) => {
                          if (value) {
                            if (value === 'No') {
                              setFieldValue('problemWhenChildBornDeal', '');
                              setFieldValue('problemWhenChildBornNow', '');
                            }
                            setFieldValue('problemWhenChildBorn', value);
                          }
                        }}
                      />
                      {errors.problemWhenChildBorn &&
                        touched.problemWhenChildBorn && (
                          <MediumTypography
                            labelid={errors.problemWhenChildBorn}
                            className="errorText-md"
                          />
                        )}
                    </Box>
                  </Box>

                  {values.problemWhenChildBorn === 'Yes' && (
                    <>
                      <Grid
                        container
                        direction={'row'}
                        columnSpacing={'40px'}
                        className="mt-md mb-lg"
                      >
                        <Grid item xs={9} lg={9}>
                          <CommentTextbox
                            Value={values.problemWhenChildBornDeal}
                            placeholder="How did you deal with that?"
                            name="problemWhenChildBornDeal"
                            maxLength={2000}
                            handlechange={(e) => {
                              setFieldValue('problemWhenChildBornDeal', e);
                            }}
                            height="150px"
                            placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.problemWhenChildBornDeal &&
                            touched.problemWhenChildBornDeal && (
                              <MediumTypography
                                labelid={errors.problemWhenChildBornDeal}
                                defaultlabel="problemWhenChildBornDeal is Required"
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                        <Grid item xs={9} lg={9}>
                          <CommentTextbox
                            Value={values.problemWhenChildBornNow}
                            placeholder="How are you doing now?"
                            name="problemWhenChildBornNow"
                            maxLength={2000}
                            handlechange={(e) => {
                              setFieldValue('problemWhenChildBornNow', e);
                            }}
                            height="150px"
                            placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.problemWhenChildBornNow &&
                            touched.problemWhenChildBornNow && (
                              <MediumTypography
                                labelid={errors.problemWhenChildBornNow}
                                defaultlabel="problemWhenChildBornNow is Required"
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-md"
                      label="Alcohol/Tobacco use ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.alcoholTobaccoUse}
                      onSelectItem={(value) => {
                        if (value) {
                          setFieldValue('alcoholTobaccoUse', value);
                        }
                      }}
                    />
                    {errors.alcoholTobaccoUse && touched.alcoholTobaccoUse && (
                      <MediumTypography
                        labelid={errors.alcoholTobaccoUse}
                        className="errorText-md"
                      />
                    )}
                  </Box>

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-md"
                      label="Prescription or recreational drug use ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.recreationalDrugUse}
                      onSelectItem={(value) => {
                        if (value) {
                          if (value === 'No') {
                            setFieldValue('medicationDuringTrimester', '');
                          }
                          setFieldValue('recreationalDrugUse', value);
                        }
                      }}
                    />
                    {errors.recreationalDrugUse &&
                      touched.recreationalDrugUse && (
                        <MediumTypography
                          labelid={errors.recreationalDrugUse}
                          className="errorText-md"
                        />
                      )}
                  </Box>

                  {values.recreationalDrugUse === 'Yes' && (
                    <>
                      <Grid
                        container
                        direction={'row'}
                        columnSpacing={'40px'}
                        className="mt-md mb-lg"
                      >
                        <Grid item xs={9} lg={9}>
                          <CommentTextbox
                            Value={values.medicationDuringTrimester}
                            placeholder="What medication(s) did you take during each trimester of your pregnancy (e.g., aspirin, vitamins, birth control pills)?"
                            name="medicationDuringTrimester"
                            maxLength={2000}
                            handlechange={(e) => {
                              setFieldValue('medicationDuringTrimester', e);
                            }}
                            height="150px"
                            placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.medicationDuringTrimester &&
                            touched.medicationDuringTrimester && (
                              <MediumTypography
                                labelid={errors.medicationDuringTrimester}
                                defaultlabel="medicationDuringTrimester is Required"
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-md mb-lg"
                  >
                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.complicationWithPregnancy}
                        placeholder="History of complications with other pregnancies?"
                        name="complicationWithPregnancy"
                        maxLength={2000}
                        handlechange={(e) => {
                          setFieldValue('complicationWithPregnancy', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.complicationWithPregnancy &&
                        touched.complicationWithPregnancy && (
                          <MediumTypography
                            labelid={errors.complicationWithPregnancy}
                            defaultlabel="complicationWithPregnancy is Required"
                            className="errorText-md"
                          />
                        )}
                    </Grid>
                  </Grid>

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-md"
                      labelid="IntakePregnancy.babyBornOnTime"
                      defaultlabel="Was baby born “on time” ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.babyBornOnTime}
                      onSelectItem={(value) => {
                        if (value) {
                          if (value === 'Yes') {
                            setFieldValue('babyBornEarlyOrLate', '');
                          }
                          setFieldValue('babyBornOnTime', value);
                        }
                      }}
                    />
                    {errors.babyBornOnTime && touched.babyBornOnTime && (
                      <MediumTypography
                        labelid={errors.babyBornOnTime}
                        className="errorText-md"
                      />
                    )}
                  </Box>

                  {values.babyBornOnTime === 'No' && (
                    <Grid
                      container
                      direction={'row'}
                      columnSpacing={'40px'}
                      className="mt-md mb-lg"
                    >
                      <Grid item xs={9} lg={9}>
                        <CommentTextbox
                          Value={values.babyBornEarlyOrLate}
                          placeholder="How early or late? "
                          name="babyBornEarlyOrLate"
                          maxLength={500}
                          handlechange={(e) => {
                            setFieldValue('babyBornEarlyOrLate', e);
                          }}
                          height="150px"
                          placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.babyBornEarlyOrLate &&
                          touched.babyBornEarlyOrLate && (
                            <MediumTypography
                              labelid={errors.babyBornEarlyOrLate}
                              defaultlabel="babyBornEarlyOrLate is Required"
                              className="errorText-md"
                            />
                          )}
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-md mb-lg"
                  >
                    <Grid item xs={4.5} lg={4.5}>
                      <Textinput
                        labelid="IntakePregnancy.gestationalAge"
                        defaultlabelid="Gestational Age (in weeks)"
                        Value={values.gestationalAge}
                        inputProps={{ maxLength: 2 }}
                        handlechange={(text: string) => {
                          setFieldValue(
                            'gestationalAge',
                            text.replace(/[^0-9]/g, ''),
                          );
                        }}
                        sxProps={{
                          backgroundColor: 'white',
                        }}
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                        disabled={values.gestationalAgeCheck}
                      />
                      {errors.gestationalAge &&
                        touched.gestationalAge &&
                        values.gestationalAge !== 'Unknown' && (
                          <MediumTypography
                            labelid={errors.gestationalAge}
                            className="errorText-md"
                          />
                        )}
                    </Grid>

                    <Grid
                      item
                      xs={4.5}
                      lg={4.5}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <CustomCheckBox
                        value={values.gestationalAgeCheck}
                        style={{ padding: 0, paddingRight: '8px' }}
                        onCheckBoxClick={(e) => {
                          if (e === true) {
                            setFieldValue('gestationalAge', 'Unknown');
                          } else {
                            setFieldValue('gestationalAge', '');
                          }
                          setFieldValue('gestationalAgeCheck', e);
                        }}
                      />
                      <MediumTypography
                        labelid="Intake.RadioLabelC"
                        defaultlabel="Unknown"
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-md mb-lg"
                  >
                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.labor}
                        placeholder="Labor (any difficulties – induced, prolonged, monitored, etc.)"
                        name="labor"
                        maxLength={2000}
                        handlechange={(e) => {
                          setFieldValue('labor', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.labor && touched.labor && (
                        <MediumTypography
                          labelid={errors.labor}
                          defaultlabel="labor is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Box
                    component="div"
                    className="flex__ align__items__center mt-sm"
                  >
                    <MediumTypography
                      labelid="IntakePregnancy.SpecialConsiderationsSubtitle"
                      defaultlabel="Special considerations about labor and delivery"
                      fontSize="14px"
                      fontweight={600}
                      textColor="#00C6B8"
                    />
                  </Box>

                  <Box component="div" className="ml-lg mt-md">
                    <Grid container spacing={1}>
                      {deliveryMethodData.map((method) => (
                        <Grid key={method.id} item xs={3.5}>
                          <Box
                            component="div"
                            className="flex__ align__items__center"
                          >
                            <CustomCheckBox
                              value={method.checked}
                              style={{ padding: 0, paddingRight: '8px' }}
                              onCheckBoxClick={(e) =>
                                handleDeliveryMethodCheckBoxClick(method.id, e)
                              }
                            />
                            <MediumTypography
                              label={method.defaultDataLabelId}
                              fontweight={400}
                              textColor="#2A4241"
                            />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>

                  {deliveryMethodData[deliveryMethodData.length - 2]
                    .checked && (
                    <Grid
                      container
                      direction={'row'}
                      columnSpacing={'40px'}
                      className="mt-md mb-lg"
                    >
                      <Grid item xs={9} lg={9}>
                        <CommentTextbox
                          Value={values.caesareanExplain}
                          placeholder="Caesarean delivery - Explain why?"
                          name="caesareanExplain"
                          maxLength={300}
                          handlechange={(e) => {
                            setFieldValue('caesareanExplain', e);
                          }}
                          height="150px"
                          placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.caesareanExplain &&
                          touched.caesareanExplain && (
                            <MediumTypography
                              labelid={errors.caesareanExplain}
                              defaultlabel="Explaination is Required"
                              className="errorText-md"
                            />
                          )}
                      </Grid>
                    </Grid>
                  )}

                  {deliveryMethodData[deliveryMethodData.length - 1]
                    .checked && (
                    <Grid
                      container
                      direction={'row'}
                      columnSpacing={'40px'}
                      className="mt-md mb-lg"
                    >
                      <Grid item xs={9} lg={9}>
                        <CommentTextbox
                          Value={values.considerationsAboutLaborOther}
                          placeholder="Other"
                          name="considerationsAboutLaborOther"
                          maxLength={100}
                          handlechange={(e) => {
                            setFieldValue('considerationsAboutLaborOther', e);
                          }}
                          height="150px"
                          placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.considerationsAboutLaborOther &&
                          touched.considerationsAboutLaborOther && (
                            <MediumTypography
                              labelid={errors.considerationsAboutLaborOther}
                              defaultlabel="Other Description is Required"
                              className="errorText-md"
                            />
                          )}
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-md mb-lg"
                  >
                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.medicationsAnesthesia}
                        placeholder="Medications/Anesthesia"
                        name="medicationsAnesthesia"
                        maxLength={2000}
                        handlechange={(e) => {
                          setFieldValue('medicationsAnesthesia', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.medicationsAnesthesia &&
                        touched.medicationsAnesthesia && (
                          <MediumTypography
                            labelid={errors.medicationsAnesthesia}
                            defaultlabel="Medications/Anesthesia is Required"
                            className="errorText-md"
                          />
                        )}
                    </Grid>
                  </Grid>

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-md"
                      labelid="IntakePregnancy.BirthWeight"
                      defaultlabel="Child’s birth weight ?"
                    />
                    <CustomRadioButton
                      data={BirthRadioData}
                      value={values.birthWeight}
                      onSelectItem={(value) => {
                        if (value) {
                          if (value === 'In Pounds') {
                            setFieldValue('weightInGrams', '');
                          } else {
                            setFieldValue('weightInLbs', '');
                            setFieldValue('weightInOz', '');
                          }
                          setFieldValue('birthWeight', value);
                        }
                      }}
                    />
                    {errors.birthWeight && touched.birthWeight && (
                      <MediumTypography
                        labelid={errors.birthWeight}
                        className="errorText-md"
                      />
                    )}
                  </Box>

                  {values.birthWeight === 'In Pounds' && (
                    <Box
                      component="div"
                      display="flex"
                      flexDirection="row"
                      className="mt-md"
                    >
                      <Box className="mr-md">
                        <Textinput
                          name="weightInLbs"
                          labelid="weightInLbs"
                          defaultlabelid="Lbs *"
                          Value={values.weightInLbs}
                          inputProps={{ maxLength: 2 }}
                          handlechange={(text: string) => {
                            setFieldValue(
                              'weightInLbs',
                              text.replace(/[^0-9 ]/g, ''),
                            );
                          }}
                          sxProps={{
                            backgroundColor: 'white',
                          }}
                          placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.weightInLbs && touched.weightInLbs && (
                          <MediumTypography
                            labelid={errors.weightInLbs}
                            defaultlabel="Weight In Lbs is Required"
                            className="errorText-md"
                          />
                        )}
                      </Box>

                      <Box>
                        <Textinput
                          name="weightInOz"
                          labelid="weightInOz"
                          defaultlabelid="Oz *"
                          Value={values.weightInOz}
                          inputProps={{ maxLength: 2 }}
                          handlechange={(text: string) => {
                            setFieldValue(
                              'weightInOz',
                              text.replace(/[^0-9 ]/g, ''),
                            );
                          }}
                          sxProps={{
                            backgroundColor: 'white',
                          }}
                          placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.weightInOz && touched.weightInOz && (
                          <MediumTypography
                            labelid={errors.weightInOz}
                            defaultlabel="Weight In Oz is Required"
                            className="errorText-md"
                          />
                        )}
                      </Box>
                    </Box>
                  )}

                  {values.birthWeight === 'In Grams' && (
                    <Box
                      component="div"
                      display="flex"
                      flexDirection="row"
                      className="mt-md"
                    >
                      <Box>
                        <Textinput
                          name="weightInGrams"
                          labelid="weightInGrams"
                          defaultlabelid="Grams *"
                          Value={values.weightInGrams}
                          inputProps={{ maxLength: 5 }}
                          handlechange={(text: string) => {
                            setFieldValue(
                              'weightInGrams',
                              text.replace(/[^0-9 ]/g, ''),
                            );
                          }}
                          sxProps={{
                            backgroundColor: 'white',
                          }}
                          placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.weightInGrams && touched.weightInGrams && (
                          <MediumTypography
                            labelid={errors.weightInGrams}
                            defaultlabel="Weight In Grams is Required"
                            className="errorText-md"
                          />
                        )}
                      </Box>
                    </Box>
                  )}

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-md"
                      labelid="IntakePregnancy.MultipleBirth"
                      defaultlabel="Multiple Birth ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.multipleBirth}
                      onSelectItem={(value) => {
                        if (value) {
                          setFieldValue('multipleBirth', value);
                        }
                      }}
                      disable
                    />
                  </Box>
                  {errors.multipleBirth && touched.multipleBirth && (
                    <MediumTypography
                      labelid={errors.multipleBirth}
                      className="errorText-md"
                    />
                  )}

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-md mb-lg"
                  >
                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.neonatalCourse}
                        placeholder="Neonatal course (problems, medications, treatments, etc.)"
                        name="neonatalCourse"
                        maxLength={2000}
                        handlechange={(e) => {
                          setFieldValue('neonatalCourse', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.neonatalCourse && touched.neonatalCourse && (
                        <MediumTypography
                          labelid={errors.neonatalCourse}
                          defaultlabel="Neonatal Course is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-md"
                      labelid="IntakePregnancy.SpecialCareAfterBirth"
                      defaultlabel="Did your child receive special care after birth ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.specialCareAfterBirth}
                      onSelectItem={(value) => {
                        if (value) {
                          if (value === 'No') {
                            setSpecialCareBirthAtData((prevData) => {
                              return prevData.map((item) => ({
                                ...item,
                                checked: false,
                              }));
                            });
                            setFieldValue('hospitalNameNICU', '');
                            setFieldValue('hospitalPhoneNoNICU', '');
                            setFieldValue('howLongNICU', '');
                            setFieldValue('noOfDaysCCN', '');
                            setFieldValue('hospitalNameSC', '');
                            setFieldValue('hospitalPhoneNoSC', '');
                            setFieldValue('howLongSC', '');
                            setFieldValue('hospitalNameRN', '');
                            setFieldValue('hospitalPhoneNoRN', '');
                            setFieldValue('howLongRN', '');
                            setFieldValue('roomedInRN', '');
                          }
                          setFieldValue('specialCareAfterBirth', value);
                        }
                      }}
                    />
                  </Box>
                  {errors.specialCareAfterBirth &&
                    touched.specialCareAfterBirth && (
                      <MediumTypography
                        labelid={errors.specialCareAfterBirth}
                        className="errorText-md"
                      />
                    )}

                  {values.specialCareAfterBirth === 'Yes' && (
                    <Box component="div" className="ml-lg mt-md">
                      <Grid container spacing={1}>
                        {specialCareBirthAtData.map((birthAt) => (
                          <Grid key={birthAt.id} item xs={2}>
                            <Box
                              component="div"
                              className="flex__ align__items__center"
                            >
                              <CustomCheckBox
                                value={birthAt.checked}
                                style={{ padding: 0, paddingRight: '8px' }}
                                onCheckBoxClick={(e) =>
                                  handleSpecialCareBirthAtCheckBoxClick(
                                    birthAt.id,
                                    e,
                                  )
                                }
                              />
                              <MediumTypography
                                label={birthAt.defaultDataLabelId}
                                fontweight={400}
                                textColor="#2A4241"
                              />
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}

                  {specialCareBirthAtData[0].checked && (
                    <>
                      <MediumTypography
                        labelid="IntakePregnancy.CCN"
                        defaultlabel="CCN (continuing care nursery) "
                        fontSize="14px"
                        fontweight={600}
                        textColor="#00C6B8"
                        className="mt-md"
                      />
                      <Box display="flex" flexDirection="row">
                        <Textinput
                          name="noOfDaysCCN"
                          labelid="noOfDaysCCN"
                          defaultlabelid="Number of days child was in CCN"
                          Value={values.noOfDaysCCN}
                          inputProps={{ maxLength: 4 }}
                          handlechange={(text: string) => {
                            setFieldValue(
                              'noOfDaysCCN',
                              text.replace(/[^0-9]/g, ''),
                            );
                          }}
                          sxProps={{
                            backgroundColor: 'white',
                            width: '30%',
                          }}
                          className="mt-md"
                          placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.noOfDaysCCN && touched.noOfDaysCCN && (
                          <MediumTypography
                            labelid={errors.noOfDaysCCN}
                            defaultlabel="hospitalName is Required"
                            className="errorText-md"
                          />
                        )}
                      </Box>
                    </>
                  )}

                  {specialCareBirthAtData[1].checked && (
                    <>
                      <MediumTypography
                        labelid="IntakePregnancy.specialCare"
                        defaultlabel="Special Care"
                        fontSize="14px"
                        fontweight={600}
                        textColor="#00C6B8"
                        className="mt-md"
                      />
                      <Box display="flex" flexDirection="row">
                        <Textinput
                          name="hospitalNameSC"
                          labelid="hospitalNameSC"
                          defaultlabelid="Hospital Name"
                          Value={values.hospitalNameSC}
                          inputProps={{ maxLength: 100 }}
                          handlechange={(text: string) => {
                            setFieldValue('hospitalNameSC', text);
                          }}
                          sxProps={{
                            backgroundColor: 'white',
                            width: '30%',
                          }}
                          placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                          className="mt-md mr-md"
                        />
                        {errors.hospitalNameSC && touched.hospitalNameSC && (
                          <MediumTypography
                            labelid={errors.hospitalNameSC}
                            defaultlabel="hospital Name is Required"
                            className="errorText-md"
                          />
                        )}

                        <Textinput
                          name="hospitalPhoneNoSC"
                          labelid="Contacts.phone"
                          defaultlabelid="Phone Number"
                          handlechange={(value: string) => {
                            const validatedNumber = formatPhoneNumber(value);
                            setFieldValue('hospitalPhoneNoSC', validatedNumber);
                          }}
                          inputProps={{
                            pattren: ['0-9'],
                          }}
                          sxProps={{
                            backgroundColor: 'white',
                            width: '30%',
                          }}
                          className="mt-md"
                          placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                          Value={values.hospitalPhoneNoSC}
                        />
                        {errors.hospitalPhoneNoSC &&
                          touched.hospitalPhoneNoSC && (
                            <MediumTypography
                              labelid={errors.hospitalPhoneNoSC}
                              defaultlabel="Phone Number is Required"
                              className="errorText-md"
                            />
                          )}
                      </Box>

                      <Box component="div">
                        <Textinput
                          name="howLongSC"
                          labelid="howLongSC"
                          defaultlabelid="How long ?"
                          Value={values.howLongSC}
                          inputProps={{ maxLength: 100 }}
                          handlechange={(text: string) => {
                            setFieldValue('howLongSC', text);
                          }}
                          sxProps={{
                            backgroundColor: 'white',
                            width: '61%',
                          }}
                          className="mt-md"
                          placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.howLongSC && touched.howLongSC && (
                          <MediumTypography
                            labelid={errors.howLongSC}
                            defaultlabel="How long Description is Required"
                            className="errorText-md"
                          />
                        )}
                      </Box>
                    </>
                  )}

                  {specialCareBirthAtData[2].checked && (
                    <>
                      <MediumTypography
                        labelid="IntakePregnancy.regularNursery"
                        defaultlabel="Regular Nursery"
                        fontSize="14px"
                        fontweight={600}
                        textColor="#00C6B8"
                        className="mt-md"
                      />
                      <Box display="flex" flexDirection="row">
                        <Textinput
                          name="hospitalNameRN"
                          labelid="hospitalNameRN"
                          defaultlabelid="Hospital Name"
                          Value={values.hospitalNameRN}
                          inputProps={{ maxLength: 100 }}
                          handlechange={(text: string) => {
                            setFieldValue('hospitalNameRN', text);
                          }}
                          sxProps={{
                            backgroundColor: 'white',
                            width: '30%',
                          }}
                          className="mt-md mr-md"
                          placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.hospitalNameRN && touched.hospitalNameRN && (
                          <MediumTypography
                            labelid={errors.hospitalNameRN}
                            defaultlabel="hospital Name is Required"
                            className="errorText-md"
                          />
                        )}

                        <Textinput
                          name="hospitalPhoneNoRN"
                          labelid="Contacts.phone"
                          defaultlabelid="Phone Number"
                          handlechange={(value: string) => {
                            const validatedNumber = formatPhoneNumber(value);
                            setFieldValue('hospitalPhoneNoRN', validatedNumber);
                          }}
                          inputProps={{
                            pattren: ['0-9'],
                          }}
                          sxProps={{
                            backgroundColor: 'white',
                            width: '30%',
                          }}
                          className="mt-md"
                          Value={values.hospitalPhoneNoRN}
                          placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.hospitalPhoneNoRN &&
                          touched.hospitalPhoneNoRN && (
                            <MediumTypography
                              labelid={errors.hospitalPhoneNoRN}
                              defaultlabel="Phone Number is Required"
                              className="errorText-md"
                            />
                          )}
                      </Box>

                      <Box component="div">
                        <Textinput
                          name="howLongRN"
                          labelid="howLongSC"
                          defaultlabelid="How long ?"
                          Value={values.howLongRN}
                          inputProps={{ maxLength: 100 }}
                          handlechange={(text: string) => {
                            setFieldValue('howLongRN', text);
                          }}
                          sxProps={{
                            backgroundColor: 'white',
                            width: '61%',
                          }}
                          className="mt-md"
                          placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.howLongRN && touched.howLongRN && (
                          <MediumTypography
                            labelid={errors.howLongRN}
                            defaultlabel="How long Description is Required"
                            className="errorText-md"
                          />
                        )}
                      </Box>
                      <Box display="flex" flexDirection="row" className="mt-sm">
                        <MediumTypography
                          className="mr-md"
                          label="Roomed In ?"
                        />
                        <CustomRadioButton
                          data={IntakeRadioData}
                          value={values.roomedInRN}
                          onSelectItem={(value) => {
                            if (value) {
                              setFieldValue('roomedInRN', value);
                            }
                          }}
                        />
                        {errors.roomedInRN && touched.roomedInRN && (
                          <MediumTypography
                            labelid={errors.roomedInRN}
                            className="errorText-md"
                          />
                        )}
                      </Box>
                    </>
                  )}

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-md mb-lg"
                  >
                    <Grid item xs={4.5} lg={4.5}>
                      <Textinput
                        name="motherInHospital"
                        labelid="IntakePregnancy.motherInHospital"
                        defaultlabelid="How long was the mother’s stay in the hospital ?"
                        Value={values.motherInHospital}
                        inputProps={{ maxLength: 30 }}
                        handlechange={(text: string) => {
                          const validatedValue =
                            nameValidationWithNumeric(text);
                          if (validatedValue !== undefined) {
                            setFieldValue('motherInHospital', validatedValue);
                          }
                        }}
                        sxProps={{
                          backgroundColor: 'white',
                        }}
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.motherInHospital && touched.motherInHospital && (
                        <MediumTypography
                          labelid={errors.motherInHospital}
                          defaultlabel="Mother In Hospital is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                    <Grid item xs={4.5} lg={4.5}>
                      <DatePickerComponent
                        className="bg__white"
                        value={dayjs(values.dischargeDate) as Dayjs}
                        disableFuture={false}
                        labelid="intake.dischargeDate"
                        defaultlabelid="Discharge Date"
                        handlechange={(date: Dayjs | null) => {
                          const formattedDate =
                            dayjs(date).format('MM/DD/YYYY');
                          if (date === null) {
                            setFieldValue('dischargeDate', null);
                          } else {
                            setFieldValue('dischargeDate', formattedDate);
                          }
                        }}
                      />
                      {errors.dischargeDate && touched.dischargeDate && (
                        <MediumTypography
                          labelid={errors.dischargeDate}
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Box className="mt-md">
                    <MediumTypography
                      className="mr-md"
                      label="Did your child need any of the following ?"
                    />
                    <Box component="div" className="ml-lg mt-md">
                      <Grid container spacing={1}>
                        {treatmentsData.map((treatment) => (
                          <Grid key={treatment.id} item xs={3.5}>
                            <Box
                              component="div"
                              className="flex__ align__items__center"
                            >
                              <CustomCheckBox
                                value={treatment.checked}
                                style={{ padding: 0, paddingRight: '8px' }}
                                onCheckBoxClick={(e) =>
                                  handleTreatmentCheckBoxClick(treatment.id, e)
                                }
                              />
                              <MediumTypography
                                label={treatment.defaultDataLabelId}
                                fontweight={400}
                                textColor="#2A4241"
                              />
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>

                  {treatmentsData[treatmentsData.length - 1].checked && (
                    <Grid
                      container
                      direction={'row'}
                      columnSpacing={'40px'}
                      className="mt-md mb-lg"
                    >
                      <Grid item xs={9} lg={9}>
                        <CommentTextbox
                          Value={values.treatmentOther}
                          placeholder="Other"
                          name="treatmentOther"
                          maxLength={100}
                          handlechange={(e) => {
                            setFieldValue('treatmentOther', e);
                          }}
                          height="150px"
                          placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.treatmentOther && touched.treatmentOther && (
                          <MediumTypography
                            labelid={errors.treatmentOther}
                            defaultlabel="Other Description is Required"
                            className="errorText-md"
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-md"
                      label="Did your child pass the newborn hearing screening ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.newbornHearingScreening}
                      onSelectItem={(value) => {
                        if (value) {
                          setFieldValue('newbornHearingScreening', value);
                        }
                      }}
                    />
                    {errors.newbornHearingScreening &&
                      touched.newbornHearingScreening && (
                        <MediumTypography
                          labelid={errors.newbornHearingScreening}
                          className="errorText-md"
                        />
                      )}
                  </Box>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-md mb-lg"
                  >
                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.specialNeedsMedicalEquipment}
                        placeholder="Did baby leave with any special needs/medical equipment ?"
                        name="specialNeedsMedicalEquipment"
                        maxLength={2000}
                        handlechange={(e) => {
                          setFieldValue('specialNeedsMedicalEquipment', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.specialNeedsMedicalEquipment &&
                        touched.specialNeedsMedicalEquipment && (
                          <MediumTypography
                            labelid={errors.specialNeedsMedicalEquipment}
                            defaultlabel="Description is Required"
                            className="errorText-md"
                          />
                        )}
                    </Grid>
                  </Grid>

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-md"
                      label="Has your child had any other hearing evaluations ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.hearingEvaluation}
                      onSelectItem={(value) => {
                        if (value) {
                          if (value === 'No') {
                            setFieldValue('hearingEvaluationDesc', '');
                          }
                          setFieldValue('hearingEvaluation', value);
                        }
                      }}
                    />
                    {errors.hearingEvaluation && touched.hearingEvaluation && (
                      <MediumTypography
                        labelid={errors.hearingEvaluation}
                        className="errorText-md"
                      />
                    )}
                  </Box>

                  {values.hearingEvaluation === 'Yes' && (
                    <Grid
                      container
                      direction={'row'}
                      columnSpacing={'40px'}
                      className="mt-md mb-lg"
                    >
                      <Grid item xs={9} lg={9}>
                        <CommentTextbox
                          Value={values.hearingEvaluationDesc}
                          placeholder="Comments"
                          name="hearingEvaluationDesc"
                          maxLength={2000}
                          handlechange={(e) => {
                            setFieldValue('hearingEvaluationDesc', e);
                          }}
                          height="150px"
                          placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.hearingEvaluationDesc &&
                          touched.hearingEvaluationDesc && (
                            <MediumTypography
                              labelid={errors.hearingEvaluationDesc}
                              defaultlabel="Description is Required"
                              className="errorText-md"
                            />
                          )}
                      </Grid>
                    </Grid>
                  )}

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-md"
                      label="Has your child had any vision screenings ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.visionScreening}
                      onSelectItem={(value) => {
                        if (value) {
                          setFieldValue('visionScreening', value);
                        }
                      }}
                    />
                    {errors.visionScreening && touched.visionScreening && (
                      <MediumTypography
                        labelid={errors.visionScreening}
                        className="errorText-md"
                      />
                    )}
                  </Box>

                  {values.visionScreening === 'Yes' && (
                    <Grid
                      container
                      direction={'row'}
                      columnSpacing={'40px'}
                      className="mt-md mb-lg"
                    >
                      <Grid item xs={9} lg={9}>
                        <CommentTextbox
                          Value={values.visionScreeningDesc}
                          placeholder="Comments"
                          name="visionScreeningDesc"
                          maxLength={2000}
                          handlechange={(e) => {
                            setFieldValue('visionScreeningDesc', e);
                          }}
                          height="150px"
                          placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.visionScreeningDesc &&
                          touched.visionScreeningDesc && (
                            <MediumTypography
                              labelid={errors.visionScreeningDesc}
                              defaultlabel="Description is Required"
                              className="errorText-md"
                            />
                          )}
                      </Grid>
                    </Grid>
                  )}

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-md"
                      label="Hearing or Vision concerns ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.hearingVisionConcerns}
                      onSelectItem={(value) => {
                        if (value) {
                          setFieldValue('hearingVisionConcerns', value);
                        }
                      }}
                    />
                    {errors.hearingVisionConcerns &&
                      touched.hearingVisionConcerns && (
                        <MediumTypography
                          labelid={errors.hearingVisionConcerns}
                          className="errorText-md"
                        />
                      )}
                  </Box>
                </Box>
                <Box
                  component="div"
                  className="p-sm gray3 borderRadius4 mt-md"
                  sx={{
                    border: '1px solid #00C6B8',
                  }}
                >
                  <Box component="div" className="flex__justify__space-between">
                    <MediumTypography
                      labelid="Intake.eicsTitle"
                      defaultlabel="EICS Additional Data"
                      fontSize="16px"
                      fontweight={600}
                      textColor="#2A4241"
                    />

                    {!openEICSSection ? (
                      <DownArrow
                        className="cursorPointer"
                        style={{ color: '#008C82' }}
                        onClick={() => {
                          setOpenEICSSection(true);
                        }}
                      />
                    ) : (
                      <UpArrow
                        className="cursorPointer"
                        onClick={() => {
                          setOpenEICSSection(false);
                        }}
                      />
                    )}
                  </Box>

                  {openEICSSection && (
                    <Box component="div">
                      <Box className="horizontalLine" sx={{ marginY: '8px' }} />

                      <Grid
                        container
                        direction={'row'}
                        columnSpacing={'40px'}
                        className="mt-md mb-lg"
                      >
                        <Grid item xs={3} lg={3} className="mt-md">
                          <MediumTypography
                            labelid="IntakePregnancy.childInNICU"
                            defaultlabel="Was this child in NICU ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.childInNICU}
                            onSelectItem={(value) => {
                              if (value) {
                                if (
                                  values.daysInNICU &&
                                  values.daysInNICU !== ''
                                ) {
                                  setFieldValue(
                                    'daysInNICU',
                                    values.daysInNICU,
                                  );
                                } else {
                                  setFieldValue('daysInNICU', '');
                                }
                                if (value !== 'Yes') {
                                  setFieldValue('daysInNICUCheck', false);
                                }
                                setFieldValue('childInNICU', value);
                              }
                            }}
                          />
                          {errors.childInNICU && touched.childInNICU && (
                            <MediumTypography
                              labelid={errors.childInNICU}
                              className="errorText-md"
                            />
                          )}
                        </Grid>

                        {values.childInNICU === 'Yes' && (
                          <Grid
                            container
                            direction={'row'}
                            columnSpacing={'20px'}
                            className="mt-md ml-lg"
                          >
                            <Grid item xs={3} lg={3}>
                              <Textinput
                                name="daysInNICU"
                                labelid="daysInNICU"
                                defaultlabelid="NICU days ? *"
                                Value={values.daysInNICU}
                                inputProps={{ maxLength: 3 }}
                                handlechange={(text: string) => {
                                  setFieldValue(
                                    'daysInNICU',
                                    text.replace(/[^0-9]/g, ''),
                                  );
                                }}
                                sxProps={{
                                  backgroundColor: 'white',
                                }}
                                placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                disabled={values.daysInNICUCheck}
                              />
                              {errors.daysInNICU &&
                                touched.daysInNICU &&
                                values.daysInNICU !== 'Unknown' && (
                                  <MediumTypography
                                    labelid={errors.daysInNICU}
                                    className="errorText-md"
                                  />
                                )}
                            </Grid>

                            <Grid
                              item
                              xs={4.5}
                              lg={4.5}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <CustomCheckBox
                                value={values.daysInNICUCheck}
                                style={{ padding: 0, paddingRight: '8px' }}
                                onCheckBoxClick={(e) => {
                                  if (e === true) {
                                    setFieldValue('daysInNICU', 'Unknown');
                                  } else {
                                    setFieldValue('daysInNICU', '');
                                  }
                                  setFieldValue('daysInNICUCheck', e);
                                }}
                              />
                              <MediumTypography
                                labelid="Intake.RadioLabelC"
                                defaultlabel="Unknown"
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      <Box className="mt-lg">
                        <MediumTypography
                          className="mr-md"
                          labelid="IntakePregnancy.DiagnosisOfSubstanceExposedNewborn"
                          defaultlabel="Diagnosis of Substance Exposed Newborn (SEN) ?"
                        />
                        <CustomRadioButton
                          data={EICSRadioData}
                          value={values.diagnosisOfSubstanceExposedNewborn}
                          onSelectItem={(value) => {
                            if (value) {
                              setFieldValue(
                                'diagnosisOfSubstanceExposedNewborn',
                                value,
                              );
                            }
                          }}
                        />
                      </Box>
                      {errors.diagnosisOfSubstanceExposedNewborn &&
                        touched.diagnosisOfSubstanceExposedNewborn && (
                          <MediumTypography
                            labelid={errors.diagnosisOfSubstanceExposedNewborn}
                            className="errorText-md"
                          />
                        )}
                    </Box>
                  )}
                </Box>
                <ModalPopup
                  description="formUnsavedChangesMessage"
                  open={openModal}
                  onCancel={() => {
                    setOpenModal(false);
                  }}
                  onOk={() => {
                    formikRef.current?.resetForm();

                    setIntakePregnancyInfo({
                      ...intakePregnancyInfo,
                    });

                    getCheckBoxSelectedNamesGenericFunc(
                      'pregnancyProblemName',
                      intakePregnancyInfoSavedValues?.pregnancy
                        .pregnancyProblemName || [],
                    );
                    getCheckBoxSelectedNamesGenericFunc(
                      'deliveryMethodNames',
                      intakePregnancyInfoSavedValues?.delivery
                        .deliveryMethodNames || [],
                    );
                    getCheckBoxSelectedNamesGenericFunc(
                      'specialCareAfterBirthAt',
                      intakePregnancyInfoSavedValues?.specialCare
                        .specialCareAfterBirthAt || [],
                    );
                    getCheckBoxSelectedNamesGenericFunc(
                      'treatmentsNames',
                      intakePregnancyInfoSavedValues?.tests.treatmentName || [],
                    );

                    setOpenModal(false);
                  }}
                  labelId1="Clientpage.Nobtn"
                  negativeActionLabel="No"
                  labelId2="Clientpage.Yesbtn"
                  positiveActionLabel="Yes"
                />
              </Box>
            )}
          </Formik>
        </Card>
        <Box
          className="mt-md mb-xlg"
          display={'flex'}
          justifyContent={'flex-end'}
        >
          <ButtonComponent
            className="btn-primary btn-cancel alrt_Width_btn mr-md"
            variantType="contained"
            labelId={'cancelText'}
            defaultLabelId={'Cancel'}
            onClick={() => {
              if (
                JSON.stringify(intakePregnancyInfo) !==
                JSON.stringify(formikRef.current?.values)
              ) {
                setOpenModal(true);
              }
            }}
          />

          <ButtonComponent
            variantType="contained"
            type="submit"
            className="btn-primary btn-submit alrt_Width_btn"
            labelId={'Intake.submit'}
            defaultLabelId={'Save & Next'}
            onClick={() => {
              formikRef.current?.handleSubmit();
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default IntakePregnancyInfo;
