import { Program } from '../../../../pages/eligibility/types';
import { OptionType } from '../../../../utils/type';
import { ApiMessage, ApiResponseHandler } from '../../../ApiResponseHandler';
import { THOM_BASE_URL } from '../../../Constant';
import ThomApiClient from '../../../ThomApiClient';

export type ConsentForChangeType = {
  changes: string;
  isEligibleUntilThirdBirthday: string;
  eligibleTool: OptionType;
  assessmentCompletionDate: string | null;
  reEstablishedDate: string | null;
  parentInitial: OptionType;
  isChecked: boolean;
  notifiedParent: string;
  participated: boolean;
  agreeDisagree: string;
  exceptionList: string;
  consent: boolean;
};

export interface ConsentDetailsType {
  schoolNameMandatory?:boolean;
  source?: string;
  type: string;
  name: string;
  consentId: string;
  version: number;
  signedDate: string | Date | null;
  revokedDate: string;
  status: string;
  requestedDate: string;
  requester: ConsentRequester;
  client: ConsentClient;
  contact: ConsentClientContact[];
  refusal: boolean;
  signatureType?: string;
  signature: ConsentClientSignature[];
  selectedContact: number[] | [];
  programProposes: number[] | null;
  communicationMode: number[] | null;
  communicationComments: string;
  purpose: number[] | null;
  purposeComments: string;
  serviceDeliveryPlan: ConsentForChangeType;
  programDetails: Program;
  ifsp: number[] | null;
  eligibility: number[] | null;
  other: boolean;
  otherContactName: string;
  othersComment: string;
  summary: string;
  description: string;
}

export interface ConsentRequester {
  name: string;
  email: string;
  phone: string;
}

export interface ConsentClient {
  id: string;
  name: string;
  dphId: string | null;
  gender: string;
  dob: string;
  address: string | null;
}

export interface ConsentClientContact {
  id: number;
  name: string;
  address: string | null;
  email: string;
  phone: string;
  type?: string;
}

export interface ConsentClientSignature {
  id: string;
  name: string;
  relationship: string;
  email: string;
  signature: string;
  verbal: boolean;
  date?: string;
  license?: string;
  rowKey?: string;
  partitionKey?: string;
  isPrimary?: boolean;
  isLegal?: boolean;
  isLatest?: boolean;
}

export interface ConsentProgramDetails {
  name: string;
  phoneNumber: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  faxNumber: string;
  website: string | null;
  imageUrl: string | null;
}

export type ConsentListData = {
  consentId: string;
  consentType: string;
  consentName: string;
  consentDate: string;
  expirationDate: string;
  status: string;
  permissionGrantedBy: string;
  permissionGrantedTo: string;
  version: number;
};

export type ConsentList = {
  consents: ConsentListData[];
  totalConsentsCount: number;
};

export const updateConsent = async (
  type: string,
  consentId: string,
  params: ConsentDetailsType,
  version?: number | null,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<ConsentDetailsType, ApiMessage>(
      `consent?type=${type}&consentId=${consentId}&version=${version}`,
      params,
    ),
  );
};

export const getConsentList = async (
  sort: string,
  sortType: string,
): Promise<ConsentList> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ConsentList>(
      `consent/list?sort=${sort}&sortType=${sortType}`,
    ),
  );
};

export const getConsentDetails = async (
  type: string,
  version: number,
  consentId?: string,
): Promise<ConsentDetailsType> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ConsentDetailsType>(
      `consent?type=${type}&consentId=${consentId}&version=${version}`,
    ),
  );
};

export const downloadConsent = async (
  type: string,
  consentId: string,
  localTimeZone: string,
): Promise<Blob> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<Blob>(
      `consent/download?type=${type}&consentId=${consentId}&timezone=${localTimeZone}`,
      {},
      {
        baseURL: THOM_BASE_URL,
        responseType: 'blob',
      },
    ),
  );
};

export const getConsentDetailsLEA = async (
  type: string,
  consentId?: string,
): Promise<ConsentDetailsType> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ConsentDetailsType>(
      `consent?type=${type}&consentId=${consentId}`,
    ),
  );
};
