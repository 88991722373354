import { ApiMessage, ApiResponseHandler } from '../ApiResponseHandler';
import ThomApiClient from '../ThomApiClient';
import { ProgressNoteType } from '../../utils/type';
import dayjs from 'dayjs';
import { CreateDeleteProgressNoteType } from '../../pages/ProgressNote/ProgressNote';
import { THOM_BASE_URL } from '../Constant';

export const getProgressNote = async (
  attendeeId: string,
  commonAppointmentId: string,
): Promise<ProgressNoteType> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      `progress-notes?attendeeId=${attendeeId}&commonAppointmentId=${commonAppointmentId}`,
    ),
  );
};

export const EditProgressNote = async (
  params: ProgressNoteType,
  attendeeId: string,
  commonAppointmentId: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put(
      `progress-notes?attendeeId=${attendeeId}&commonAppointmentId=${commonAppointmentId}`,
      params,
    ),
  );
};

export const CancelProgressNoteVisit = async (
  body: {
    cancelReason: string;
    status: string;
  },
  attendeeId: string,
  appointmentId: string,
  clientId: string,
): Promise<ApiMessage> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  return ApiResponseHandler(
    await ThomApiClient.getInstance().patch(
      `progress-notes?attendeeId=${attendeeId}&appointmentId=${appointmentId}&clientId=${clientId}&timezone=${timezone}`,
      body,
    ),
  );
};

export const CreateDeleteProgressNote = async (
  params: CreateDeleteProgressNoteType,
  attendeeId: string,
  appointmentId: string,
): Promise<ApiMessage> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(
      `progress-notes?attendeeId=${attendeeId}&appointmentId=${appointmentId}&timezone=${timezone}`,
      params,
    ),
  );
};

export const downloadProgressNote = async (
  clientId: string,
  attendeeId: string,
  commonAppointmentId: string,
  localTimeZone: string,
): Promise<Blob> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<Blob>(
      `progress-notes/download?clientId=${clientId}&attendeeId=${attendeeId}&commonAppointmentId=${commonAppointmentId}&timezone=${localTimeZone}`,
      {},
      {
        baseURL: THOM_BASE_URL,
        responseType: 'blob',
      },
    ),
  );
};
