import { Box, Card, Dialog, DialogContent } from '@mui/material';
import $ from 'jquery';
import { createRef, useContext, useEffect, useRef, useState } from 'react';
import {
  addResponse,
  fetchFormJSON,
  getRecordById,
  questionnaireresponsePost,
} from '../../services/configApi/forms/FormBuilder/FormBuilderServices';
import { useLocation } from 'react-router-dom';
import { AlertColor, Grid } from '@mui/material';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { usePDF } from 'react-to-pdf';
import {
  AddQuestionFormRender,
  CreateResponse,
  DynamicQuestion,
  QuestionnaireintakeResponse,
  ResponseList,
} from '../../utils/type';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as DeleteIcon } from '../../assets/images/redDeleteIcon.svg';
import { ReactComponent as TrashIcon } from '../../assets/images/trashIcon.svg';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import ModalPopup from '../../components/formlib/ModalPopup';
import { Formik, FormikProps } from 'formik';
import Textinput from '../../components/formlib/Textinput';
import * as Yup from 'yup';
import { ResponseItem } from '../../services/ApiResponseHandler';
window.jQuery = $;
window.$ = $;

require('formBuilder/dist/form-render.min');

type MyErrorType = {
  message: string;
};

declare global {
  interface JQuery {
    formRender: (data: string | object) => string | object;
  }
}

const initialState: CreateResponse = {
  id: '',
  formId: '',
  response: [],
};
/* eslint-disable */
let formRender;

interface ButtonProps {
  mainId?: string;
  id?: string | number | undefined;
  name?: string | undefined;
  clientId?: string | null;
  saveCallback?: () => void;
  deleteRecord?: () => void;
  deleteModal?: boolean;
  setDeleteModal?: () => void;
  onOk?: () => void;
  newForm?: boolean;
  className?: string;
  forms?: ResponseList[];
}

const FormRender: React.FC<ButtonProps> = (props) => {
  const {
    id,
    name,
    clientId,
    deleteRecord,
    saveCallback,
    deleteModal,
    setDeleteModal,
    onOk,
    newForm,
    className,
    mainId,
    forms,
  } = props;
  const location = useLocation();

  const initialValues: AddQuestionFormRender = {
    questionName: '',
  };
  const formRef = useRef<FormikProps<AddQuestionFormRender>>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [question, setQuestion] = useState('');
  const [list, setList] = useState<DynamicQuestion[]>([]);
  const [dynamicData, setDynamicData] = useState([]);
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('');
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [responseDetails, setResponseDetails] = useState<CreateResponse[]>([
    initialState,
  ]);
  const [open, setOpen] = useState(false);
  const fb = createRef<HTMLFormElement>();
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [othersObject, setOthersObject] = useState<ResponseList>();
  const [defaultObject, setDefaultObject] = useState<ResponseList>();
  const [cancelModal, setCancelModal] = useState(false);

  const navParams = location.state;
  const { toPDF, targetRef } = usePDF({ filename: `${name}.pdf` });
  useEffect(() => {
    toggleLoader(true);
    getFormData();
  }, []);

  const getFormData = () => {
    if (id) {
      getRecordById(id)
        .then((response) => {
          fetchResponseTemplate(response);
        })
        .catch((error) => {
          setOpen(true);
          setToastrVariable('error');
          setToastrId(error.message);
          setToastrDefaultMessage(error.message);
          toggleLoader(false);
        });
    }
  };

  useEffect(() => {
    const others = forms && forms.filter((item) => item.type === 'OTHERS');
    setOthersObject(others && others[0]);

    const defaults = forms && forms.filter((item) => item.type === 'Default');
    setDefaultObject(defaults && defaults[0]);
  }, []);

  useEffect(() => {
    if (!newForm) {
      getQuestionnaireRenderresponse(othersObject?.responseUploadUrl);

      getAdditionalQuestionsResponse(defaultObject?.responseUploadUrl);
    }
  }, [othersObject, defaultObject]);

  const getQuestionnaireRenderresponse = (
    responseUploadUrl: string | undefined,
  ) => {
    if (responseUploadUrl) {
      fetchFormTemplate(responseUploadUrl)
        .then((response) => {
          toggleLoader(false);
        })
        .catch((error) => {
          setOpen(true);
          setToastrVariable('error');
          setToastrId(error.message);
          setToastrDefaultMessage(error.message);
          toggleLoader(false);
        });
    }
  };

  const getAdditionalQuestionsResponse = (
    responseUploadUrl: string | undefined,
  ) => {
    if (responseUploadUrl) {
      fetchFormTemplate2(responseUploadUrl)
        .then((response) => {
          toggleLoader(false);
        })
        .catch((error) => {
          setOpen(true);
          setToastrVariable('error');
          setToastrId(error.message);
          setToastrDefaultMessage(error.message);
          toggleLoader(false);
        });
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  async function fetchFormTemplate(responseUploadUrl: string) {
    try {
      const apiResponse = await fetchFormJSON(responseUploadUrl);
      setDynamicData(apiResponse.data);
      toggleLoader(false);
    } catch (error) {
      const typedError = error as MyErrorType;
      setOpen(true);
      setToastrVariable('error');
      setToastrId(typedError.message);
      setToastrDefaultMessage(typedError.message);
      toggleLoader(false);
    }
  }

  async function fetchFormTemplate2(responseUploadUrl: string) {
    try {
      const apiResponse = await fetchFormJSON(responseUploadUrl);
      setList(apiResponse.data);
      toggleLoader(false);
    } catch (error) {
      const typedError = error as MyErrorType;
      setOpen(true);
      setToastrVariable('error');
      setToastrId(typedError.message);
      setToastrDefaultMessage(typedError.message);
      toggleLoader(false);
    }
  }

  async function fetchResponseTemplate(response: { templateUrl: string }) {
    try {
      const apiResponse = await fetchFormJSON(response.templateUrl);
      setDynamicData(apiResponse.data);
      toggleLoader(false);
    } catch (error) {
      const typedError = error as MyErrorType;
      setOpen(true);
      setToastrVariable('error');
      setToastrId(typedError.message);
      setToastrDefaultMessage(typedError.message);
      toggleLoader(false);
    }
  }

  useEffect(() => {
    const editModeData = {
      formData: dynamicData,
      editMode: true,
    };
    formRender = $(fb.current!).formRender(editModeData);
  }, [dynamicData]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    toggleLoader(true);
    addResponse(responseDetails, clientId).then(
      async (response: ResponseItem[]) => {
        setOpen(true);
        setToastrId('formBuilder.SaveResponse');
        setToastrDefaultMessage('response submitted successfully');
        setToastrVariable('success');
        if (saveCallback) {
          saveCallback();
        }

        const hardcodedId = '86d679d1-e140-49f3-9bee-d293029f3e31';
        let defaultId = '';
        let defaultId2 = '';

        response.forEach((item) => {
          if (item.formId === hardcodedId) {
            defaultId = item.responseId;
          } else {
            defaultId2 = item.responseId;
          }
        });

        const object: QuestionnaireintakeResponse = {
          id: mainId ? mainId : '',
          name: name,
          defaultResponseId: defaultId,
          questionnaireResponseId: defaultId2,
        };

        questionnaireresponsePost(object, clientId);
      },
    );
  }, [responseDetails]);

  function saveData() {
    if (Object.keys($(fb.current!)?.formRender('userData')).length > 0) {
      const userData = $(fb.current!).formRender('userData');

      setResponseDetails((prevFormDetails) => [
        {
          id: othersObject?.id ? othersObject.id : '',
          formId:
            othersObject && othersObject.questionnaireId
              ? othersObject.questionnaireId
              : id,
          response: Array.isArray(userData) ? [...userData] : [userData],
        },
        {
          id: defaultObject?.id ? defaultObject.id : '',
          formId: '86d679d1-e140-49f3-9bee-d293029f3e31',
          response: list,
        },
      ]);
    }
  }

  const clearFormData = () => {
    setCancelModal(true);
  };

  const validationSchema = Yup.object({
    questionName: Yup.string().required('formBuilder.Name'),
  });

  const handleSaveQuestion = () => {
    const newQuestion = {
      id: list.length,
      question: question,
      answer: '',
    };
    setList((prevList) => [...prevList, newQuestion] as DynamicQuestion[]);
    setQuestion('');
    setModalVisible(false);
  };

  const handleDeleteQuestion = (id: string) => {
    const updatedList = list.filter((item) => item.id !== id);
    setList(updatedList);
  };

  return (
    <Box component="div" className={className}>
      <Box component="section">
        <SnackBarComponent
          open={open}
          handleClose={handleClose}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />
        {dynamicData.length > 0 && (
          <Box component="div">
            <Box component="div" className=" p-md bg__white">
              <Box className="rowContainer p-none">
                <Box component="div">
                  <MediumTypography
                    labelid="Intake.QuestionnaireName"
                    defaultlabel="Questionnaire name"
                    fontSize="14px"
                    fontweight={600}
                  />
                </Box>
                <Box component="div" className="pl-xs">
                  <MediumTypography
                    labelid={name}
                    defaultlabel=""
                    fontSize="14px"
                    fontweight={600}
                  />
                </Box>
              </Box>
              <Box className="pt-md">
                <Card
                  className="formCardview p-sm gray3 borderRadius4"
                  ref={targetRef}
                  sx={{
                    border: '1px solid #00C6B8',
                    background: '#ECF9F8',
                  }}
                >
                  <Box
                    component="div"
                    sx={{ wordBreak: 'break-all', wordWrap: 'break-word' }}
                  >
                    <form ref={fb}></form>
                  </Box>
                  {list.map((item) => (
                    <Grid container className="flex__ align__items__center">
                      <Grid item xs={6} lg={10} className="mt-lg">
                        <Box key={item.id}>
                          <Textinput
                            className="bg__white"
                            name="name"
                            labelid={item.question}
                            defaultlabelid={item.question}
                            Required={false}
                            inputProps={{
                              maxLength: 1000,
                            }}
                            handlechange={(value) => {
                              const updatedList = list.map((q) =>
                                q.id === item.id ? { ...q, answer: value } : q,
                              );
                              setList(updatedList);
                            }}
                            type="text"
                            Value={item.answer}
                            disabled={false}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} lg={2} className="mt-lg pt-xs pl-md">
                        <TrashIcon
                          className="cursor__pointer"
                          onClick={() => handleDeleteQuestion(item.id)}
                        />
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item xs={6} lg={6}>
                    <Box
                      component="div"
                      className="flex__ text-align-end justifyContent-FlexStart mt-lg"
                    >
                      <ButtonComponent
                        className="btn-primary btn-submit mr-md"
                        variantType="outlined"
                        labelId="Intake.AddQuestionnaireExtra"
                        defaultLabelId="Add Question"
                        onClick={() => setModalVisible(true)}
                        disabled={list.length === 5}
                      />
                    </Box>
                  </Grid>
                </Card>
              </Box>
            </Box>

            <Box className="flex__ align__items__center">
              <Grid
                className="flex__ justifyContent-FlexStart pt-md pb-lg "
                container
                direction="row"
                alignItems="right"
              >
                <Grid item>
                  <Box
                    className="rowContainer cursor__pointer"
                    onClick={() => {
                      if (deleteRecord) deleteRecord();
                    }}
                  >
                    <DeleteIcon />
                    <MediumTypography
                      labelid={'Intake.DeleteQuestionnaire'}
                      defaultlabel="Delete Questionnaire"
                      sxProps={{
                        marginLeft: '8px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: '#EB4C60',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                className="flex__ justifyContent-FlexEnd pt-md pb-lg "
                container
                direction="row"
                alignItems="right"
              >
                {' '}
                <Grid item>
                  <ButtonComponent
                    className="btn-primary btn-cancel btn_width_90px mr-md"
                    variantType="outlined"
                    labelId="cancelText"
                    defaultLabelId="cancel"
                    onClick={clearFormData}
                  />
                  <ButtonComponent
                    className="btn-primary btn-submit btn_width_90px "
                    variantType="outlined"
                    type="submit"
                    labelId="formBuilder.Save"
                    defaultLabelId="Save"
                    onClick={saveData}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
      {deleteModal && (
        <ModalPopup
          open={deleteModal}
          onCancel={() => {
            if (setDeleteModal) setDeleteModal();
          }}
          description="formBuilder.DeleteResponse"
          onOk={onOk}
          labelId1="Clientpage.cancelbtn"
          negativeActionLabel="cancelText"
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="deleteText"
        />
      )}

      <Box component="div">
        <Dialog
          open={modalVisible}
          keepMounted
          onClose={() => ({})}
          fullWidth
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className="p-md">
            <MediumTypography
              labelid={'Intake.EnterQuestionName'}
              defaultlabel="Add Question"
              fontweight={1000}
              textColor="#2a4241"
            />
            <Formik
              initialValues={initialValues}
              validateOnMount={true}
              innerRef={formRef}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                handleSaveQuestion();
                actions.resetForm({
                  values: { questionName: '' },
                  errors: {},
                  touched: {},
                  status: {},
                });
              }}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                <Box component="div">
                  <Box component="div" className="mt-lg">
                    <Textinput
                      name="questionName"
                      labelid={'formBuilder.NameText'}
                      defaultlabelid="Name"
                      Required={true}
                      inputProps={{
                        maxLength: 100,
                      }}
                      handlechange={(value) => {
                        setQuestion(value);
                        setFieldValue('questionName', value);
                      }}
                      type="text"
                      Value={values.questionName}
                      disabled={false}
                    />
                    {errors.questionName && touched.questionName ? (
                      <Box className="loginError">
                        <MediumTypography
                          labelid={errors.questionName}
                          defaultlabel="Enter Name"
                          sxProps={{
                            color: 'red',
                            marginLeft: '10px',
                            fontSize: '14px',
                          }}
                        />
                      </Box>
                    ) : null}
                  </Box>
                  <Box>
                    <Grid
                      className="flex__ justifyContent-FlexEnd pt-lg "
                      container
                      direction="row"
                      alignItems="right"
                    >
                      <Grid item>
                        <ButtonComponent
                          className="btn-primary btn-cancel btn_width_90px mr-md"
                          variantType="outlined"
                          labelId="cancelText"
                          defaultLabelId="Cancel"
                          onClick={() => setModalVisible(false)}
                        />

                        <ButtonComponent
                          className="btn-primary btn-submit"
                          variantType="contained"
                          labelId="Add"
                          defaultLabelId="Add"
                          onClick={handleSubmit}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </Box>

      {cancelModal && (
        <ModalPopup
          open={cancelModal}
          onCancel={() => {
            setCancelModal(false);
          }}
          description={'formUnsavedChangesMessage'}
          onOk={() => {
            if (newForm) {
              const formElement = fb.current as HTMLFormElement | null;
              if (formElement) {
                formElement.reset();
                setList([]);
              }
            } else {
              getQuestionnaireRenderresponse(othersObject?.responseUploadUrl);
              getAdditionalQuestionsResponse(defaultObject?.responseUploadUrl);
            }
            setCancelModal(false);
          }}
          labelId1="Clientpage.Nobtn"
          negativeActionLabel="cancelText"
          labelId2="Clientpage.Yesbtn"
          positiveActionLabel="deleteText"
        />
      )}
    </Box>
  );
};

export default FormRender;
