export const convertGenderToBackend = (gender: string) => {
  if (gender === 'Male') {
    return 'M';
  } else if (gender === 'Female') {
    return 'F';
  } else if (gender === 'Unassigned') {
    return 'U';
  }
};

export const convertGenderToFrontend = (gender: string) => {
  if (gender === 'M') {
    return 'Male';
  } else if (gender === 'F') {
    return 'Female';
  } else if (gender === 'U') {
    return 'Unassigned';
  }
};
