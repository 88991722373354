import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import SelectComponent from '../../components/formlib/SelectComponent';
import Textinput from '../../components/formlib/Textinput';
import { Box } from '@mui/material';
import CommunicationLogTableView from './CommunicationLogTableView';
import * as yup from 'yup';
import { Formik, FormikProps } from 'formik';
import SmallTypography from '../../components/formlib/SmallTypography';
import { useRef, useContext, useEffect, useState } from 'react';
import { Context } from '../../LanguageWrapper';
import { useIntl } from 'react-intl';
import AutocompleteSearch from '../../components/formlib/AutocompleteSearch';
import { CommunicationLog, OptionType, ResponseType } from '../../utils/type';
import {
  getClientContacts,
  submitCommunicationLog,
} from '../../services/configApi/communicationLog/communication';
import {
  STORAGE_USERNAME_KEY,
  STORAGE_USER_ID_KEY,
} from '../../services/Constant';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import './CommunicationLogPage.css';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import ModalPopup from '../../components/formlib/ModalPopup';
import _ from 'lodash';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { checkPermissionForFeature } from '../../utils/checkPermission';

type FormFields = {
  contact: string;
  communicationType: string;
  reason: string;
  note: string;
};

const initialFormValues: FormFields = {
  contact: '',
  communicationType: '',
  reason: '',
  note: '',
};

const validationScema = yup.object().shape({
  contact: yup.string().required('CommunicationLogPage.contactIsRequired'),
  communicationType: yup
    .string()
    .required('CommunicationLogPage.communicationTypeIsRequired'),
  reason: yup
    .string()
    .required('CommunicationLogPage.reasonForContactIsRequired'),
  note: yup.string().required('CommunicationLogPage.noteIsRequired'),
});

const reasons: string[] = [
  'cLogReasons1',
  'cLogReasons2',
  'cLogReasons3',
  'cLogReasons4',
  'cLogReasons5',
  'otherText',
];

const communicationsTypes: string[] = [
  'cType1',
  'cType2',
  'cType3',
  'cType4',
  'cType5',
];

const CommunicationLogPage = () => {
  const formRef = useRef<FormikProps<FormFields>>(null);
  const [clientId, setClientId] = useState<string>('');
  const [selectedContact, setSelectedContact] = useState<OptionType | null>(
    null,
  );
  const [openModal, setOpenModal] = useState(false);
  const [contactedBy, setContactedBy] = useState<OptionType | null>(null);
  const [contacts, setContacts] = useState<OptionType[]>([]);
  const context = useContext(Context);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [reload, setReload] = useState<boolean>(true);
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');
  const [toastrId, setToastrId] = useState<string>();
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);

  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);
  const parentRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  useEffect(() => {
    context.selectLanguage();
    const id = localStorage.getItem('ClientId');
    if (id !== null) {
      setClientId(id);
      getContacts(id);
    }
  }, []);

  const getContacts = (id: string) => {
    getClientContacts(id)
      .then((response) => {
        const contactList: OptionType[] = [];
        response.forEach((contact) => {
          contactList.push({
            id: contact.contactId.toString(),
            label: contact.firstName
              ? `${contact.firstName} ${contact.lastName}`
              : `${contact.contactType}`,
          });
        });
        contactList.push({
          id: '0',
          label: 'Other',
        });
        return contactList;
      })
      .then((result) => {
        setContacts(result);
      })
      .catch(() => {
        setContacts([
          {
            id: '0',
            label: 'Other',
          },
        ]);
      });
  };

  const submitLog = async (values: FormFields) => {
    toggleLoader(true);
    setErrorMessage(undefined);
    const body: CommunicationLog = {
      contactedBy: contactedBy ? contactedBy.label : '',
      contactedById: contactedBy?.id,
      contact: intl.formatMessage({ id: values.contact }),
      contactId: selectedContact?.id,
      communicationType: intl.formatMessage({ id: values.communicationType }),
      reasonForContact: intl.formatMessage({ id: values.reason }),
      notes: values.note,
    };

    submitCommunicationLog(clientId, body)
      .then(() => {
        formRef.current?.resetForm();
        setSelectedContact(null);
        setToastrId('Communicationlog.logsuccess');
        setErrorMessage('Log Added successfully');
        toggleLoader(false);
        setSuccessOrError('success');
        setReload(!reload);
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setErrorMessage(apiError.message);
        } else {
          setErrorMessage(error);
          setToastrId('Communicationlog.logerror');
        }
      });
  };

  useEffect(() => {
    const userName = localStorage.getItem(STORAGE_USERNAME_KEY);
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (userName && userId) {
      setContactedBy({ id: userId, label: userName });
    }
  }, []);

  return (
    <Card>
      <Box>
        {errorMessage && (
          <SnackBarComponent
            open={errorMessage !== undefined}
            handleClose={() => {
              setErrorMessage(undefined);
            }}
            successOrError={successOrError}
            labelId={toastrId}
            defaultMessageId={errorMessage}
          />
        )}

        {
          <ModalPopup
            open={openModal}
            description="formUnsavedChangesMessage"
            onCancel={() => setOpenModal(false)}
            onOk={() => {
              setSelectedContact(null);
              setOpenModal(false);
              formRef.current?.resetForm();
            }}
            labelId1="Clientpage.Nobtn"
            negativeActionLabel="No"
            labelId2="Clientpage.Yesbtn"
            positiveActionLabel="Yes"
          />
        }
        <Box
          ref={parentRef}
          style={{
            pointerEvents: isScreenLocked ? 'none' : 'auto',
            opacity: isScreenLocked ? '0.5' : '1 ',
          }}
          onKeyDownCapture={(e) => {
            if (isScreenLocked) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          onFocus={() => {
            if (isScreenLocked) {
              if (parentRef.current) parentRef.current.focus();
            }
          }}
        >
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationScema}
            innerRef={formRef}
            onSubmit={(values) => {
              submitLog(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              initialValues,
              handleSubmit,
            }) => (
              <Grid
                container
                rowSpacing={'16px'}
                columnSpacing={'20px'}
                padding={'24px'}
              >
                <Grid container item spacing={2}>
                  <Grid item xs={5} lg={5}>
                    <Textinput
                      name="contactedBy"
                      labelid="CommunicationLogPage.contactedBy"
                      defaultlabelid="Contacted by"
                      Value={contactedBy?.label}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item xs={5} lg={5}>
                    <AutocompleteSearch
                      className="consentAutoSearch"
                      labelId="CommunicationLogPage.selectContact"
                      defaultId="Select Contact"
                      selectedValue={selectedContact}
                      optionHintId="CommunicationLogPage.contactSearch"
                      data={contacts}
                      onSelectItem={(value: OptionType | null) => {
                        if (value === null) {
                          setFieldValue('contact', '');
                          setSelectedContact(null);
                        } else {
                          setFieldValue('contact', value.label);
                          setSelectedContact(value);
                        }
                      }}
                      disableLabel={false}
                      textFieldVariant="outlined"
                      Required
                    />
                    {errors.contact && touched.contact && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId={errors.contact}
                        defaultLabelId="Contact is required"
                      />
                    )}
                  </Grid>
                </Grid>

                <Grid container item spacing={2}>
                  <Grid item xs={5} lg={5} style={{ marginTop: '8px' }}>
                    <SelectComponent
                      labelid="CommunicationLogPage.selectCommunicationType"
                      defaultlabelid="Select Communication Type"
                      name={'communicationType'}
                      handleChange={(value: string) => {
                        setFieldValue('communicationType', value);
                      }}
                      value={values.communicationType}
                      names={communicationsTypes}
                      Required
                    />
                    {errors.communicationType && touched.communicationType && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId={errors.communicationType}
                        defaultLabelId="Communication Type is required"
                      />
                    )}
                  </Grid>

                  <Grid item xs={5} lg={5} style={{ marginTop: '8px' }}>
                    <SelectComponent
                      labelid="CommunicationLogPage.reasonForContact"
                      defaultlabelid="Reason for Contact"
                      name={'reasonForContact'}
                      handleChange={(value: string) => {
                        setFieldValue('reason', value);
                      }}
                      value={values.reason}
                      names={reasons}
                      Required
                    />
                    {errors.reason && touched.reason && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId={errors.reason}
                        defaultLabelId="Reason for Contact is required"
                      />
                    )}
                  </Grid>
                </Grid>

                <Grid container item spacing={2}>
                  <Grid
                    item
                    xs={10}
                    style={{ marginTop: '8px' }}
                    className="textarea"
                  >
                    <Textinput
                      name="note"
                      labelid="CommunicationLogPage.enterNote"
                      defaultlabelid="Enter note"
                      Required={true}
                      multiline={true}
                      maxRows={4}
                      variant="outlined"
                      inputProps={{ maxLength: 3000 }}
                      handlechange={(value: string) => {
                        setFieldValue('note', value);
                      }}
                      Value={values.note}
                    />
                    {errors.note && touched.note && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId={errors.note}
                        defaultLabelId="Note is required"
                      />
                    )}
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={10}
                  spacing={1}
                  alignItems="right"
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Grid item>
                    <ButtonComponent
                      className="btn-primary btn-cancel"
                      variantType="contained"
                      labelId="CommunicationLogPage.cancelButton"
                      defaultLabelId="Cancel"
                      onClick={() => {
                        if (!_.isEqual(initialValues, values)) {
                          setOpenModal(true);
                        } else {
                          history.back();
                        }
                      }}
                    />

                    <ButtonComponent
                      className="btn-primary btn-submit ml-md su"
                      variantType="contained"
                      type="submit"
                      labelId="CommunicationLogPage.submitButton"
                      onClick={() => handleSubmit()}
                      defaultLabelId="submit"
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Formik>
        </Box>

        <Grid container className="table_padding">
          <CommunicationLogTableView reload={reload} />
        </Grid>
      </Box>
    </Card>
  );
};

export default CommunicationLogPage;
