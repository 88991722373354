import { Grid, autocompleteClasses } from '@mui/material';
import { Box } from '@mui/system';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import CustomCheckBox from '../../components/formlib/CustomCheckBox';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import MediumTypography from '../../components/formlib/MediumTypography';
import Textinput from '../../components/formlib/Textinput';
import { ReactComponent as InfoIcon } from '../../assets/images/infoIcon.svg';
import InformationModal from '../ifsp/InformationModal';
import {
  ConsentClientContact,
  ConsentForChangeType,
} from '../../services/configApi/forms/Consent/ConsentServices';
import AutocompleteSearch from '../../components/formlib/AutocompleteSearch';
import { OptionType } from '../../utils/type';
import { datePickerMinDateMaxDateValidate } from '../../utils/dateUtil';
import SmallTypography from '../../components/formlib/SmallTypography';

type ConsentServiceDeliveryPlanType = {
  SDPData: ConsentForChangeType;
  onConsentChange: (data: ConsentForChangeType) => void;
  disableConsentDueToAssessmentCompletionDate: (valid: boolean) => void;
  disableConsentDueToReEstablishDate: (valid: boolean) => void;
  contact: ConsentClientContact[];
};
const ConsentServiceDeliveryPlan = ({
  SDPData,
  contact,
  disableConsentDueToAssessmentCompletionDate,
  disableConsentDueToReEstablishDate,
  onConsentChange,
}: ConsentServiceDeliveryPlanType) => {
  const [consentForChangeforSDP, setConsentForChangeforSDP] =
    useState<ConsentForChangeType>(SDPData);
  const [closeInfo, setCloseInfo] = useState(false);
  const [reEstablishDateError, setReEstablishDateError] =
    useState<boolean>(false);
  const [reEstablishDateErrorMsg, setReEstablishDateErrorMsg] =
    useState<string>('test');
  const [assessmentCompletionDateError, setAssessmentCompletionDateError] =
    useState<boolean>(false);
  const [
    assessmentCompletionDateErrorMsg,
    setAssessmentCompletionDateErrorMsg,
  ] = useState<string>('test');

  useEffect(() => {
    onConsentChange(consentForChangeforSDP);
  }, [consentForChangeforSDP]);
  return (
    <Box>
      <Box component="section">
        <InformationModal
          open={closeInfo}
          titleLabel="IFSP.Information"
          titleDefaultLabel="Information"
          buttonLabel="IFSP.InfoOkButton"
          buttonDefaultLabel="Ok"
          onClose={() => {
            setCloseInfo(false);
          }}
          info={
            'Early Intervention is a voluntary service and parent(s) have the right to provide, withhold or revoke consent for any and all services. Parents must give written consent before services can begin. Parents may choose to give consent for some services and not others. Your consent means that you agree to the services outlined in this IFSP. If the parents do not give consent for an Early Intervention Service or if they withdraw consent after first giving it, that specific service will not be provided. Any service for which a parent provides consent must be provided within 30 days.'
          }
        />
        <Box component="div">
          <Box component="div" className="flex__ alignItemCenter pt-sm">
            <MediumTypography
              labelid="IfspConsent"
              defaultlabel="IFSP Consent"
              fontSize="16px"
              fontweight={600}
              textColor="#2A4241"
              className="pr-sm"
            />
            <InfoIcon
              onClick={() => {
                setCloseInfo(true);
              }}
              className="cursorPointer"
            />
          </Box>
          <MediumTypography
            labelid="IFSP.Eligibility"
            defaultlabel="Eligibility"
            fontSize="16px"
            fontweight={600}
            textColor="#2A4241"
            className="pt-md pb-md"
          />
          <MediumTypography
            labelid="IFSP.ifspServicesProvidedForAsLongAs"
            defaultlabel="IFSP services are provided for as long as a child is eligible or until the parent/guardian revokes consent for any or all services."
            fontweight={400}
            textColor="#2A4241"
          />
          <Box component="div" className="pl-md pt-xs">
            <CustomRadioButton
              data={[
                {
                  value: 'iseligible',
                  labelId: 'IFSP.IsEligible',
                  defaultLabel:
                    'Is eligible based on the eligibility evaluation and assessment completed',
                },
                {
                  value: 'untilnotonthirdbirthday',
                  labelId: 'IFSP.UntilButNotOnOurChildsThirdBirthday',
                  defaultLabel:
                    "Until (but not on) our child's third birthday based on a diagnosis from the DPH Diagnosed Conditions List.",
                },
              ]}
              value={consentForChangeforSDP?.isEligibleUntilThirdBirthday}
              onSelectItem={(value) => {
                if (value) {
                  if (value === 'untilnotonthirdbirthday') {
                    setConsentForChangeforSDP({
                      ...consentForChangeforSDP,
                      isEligibleUntilThirdBirthday: value,
                      eligibleTool: {
                        id: '',
                        label: '',
                      },
                      assessmentCompletionDate: null,
                    });
                  } else {
                    setConsentForChangeforSDP({
                      ...consentForChangeforSDP,
                      assessmentCompletionDate: dayjs().format('MM/DD/YYYY'),
                      isEligibleUntilThirdBirthday: value,
                    });
                  }
                }
              }}
              row={false}
              paddingY="4px"
            />
          </Box>
          {consentForChangeforSDP.isEligibleUntilThirdBirthday ===
            'iseligible' && (
            <Box component="div">
              <Grid container className="alignItemCenter pt-sm">
                <Grid item>
                  <MediumTypography
                    labelid="IFSP.EligibilityEvaluation"
                    defaultlabel="Eligibility evaluation:"
                    className="pr-md"
                    fontweight={400}
                    textColor="#2A4241"
                  />
                </Grid>
                <Grid item>
                  <AutocompleteSearch
                    labelId={'IFSP.Tool'}
                    defaultId={'Tool'}
                    optionHintId={'noOptionFound'}
                    className="consentAutoSearch"
                    sxProps={{
                      [`& .${autocompleteClasses.popupIndicator}`]: {
                        transform: 'none',
                      },
                      backgroundColor: '#fff',
                      width: '220px',
                    }}
                    selectedValue={consentForChangeforSDP.eligibleTool}
                    data={[
                      { id: 'bdi2', label: 'BDI-2' },
                      { id: 'asq:se2', label: 'ASQ-SE2' },
                      { id: 'meidp', label: 'MEIDP' },
                    ]}
                    onSelectItem={(value: OptionType | null) => {
                      if (value) {
                        setConsentForChangeforSDP({
                          ...consentForChangeforSDP,
                          eligibleTool: {
                            id: value.id,
                            label: value.label,
                          },
                        });
                      }
                    }}
                    disableLabel={false}
                  />
                </Grid>
              </Grid>
              <Grid container className="alignItemCenter pt-sm">
                <Grid item>
                  <MediumTypography
                    labelid="IFSP.AssessmentCompletedOn"
                    defaultlabel="Assessment completed on:"
                    className="pr-md"
                    fontweight={400}
                    textColor="#2A4241"
                  />
                </Grid>
                <Grid item>
                  <DatePickerComponent
                    labelid={'dateText'}
                    defaultlabelid={'Date'}
                    value={
                      consentForChangeforSDP.assessmentCompletionDate === '' ||
                      null
                        ? (dayjs() as Dayjs)
                        : (dayjs(
                            consentForChangeforSDP.assessmentCompletionDate,
                          ) as Dayjs)
                    }
                    handlechange={(dateValue: Dayjs | null) => {
                      const formattedDate =
                        dayjs(dateValue).format('MM/DD/YYYY');
                      if (dateValue !== null) {
                        if (datePickerMinDateMaxDateValidate(formattedDate)) {
                          setAssessmentCompletionDateError(true);
                          disableConsentDueToAssessmentCompletionDate(true);
                          setAssessmentCompletionDateErrorMsg(
                            'datePickerMinDateMaxDateValidate',
                          );
                        } else {
                          setAssessmentCompletionDateError(false);
                          disableConsentDueToAssessmentCompletionDate(false);
                        }
                        setConsentForChangeforSDP({
                          ...consentForChangeforSDP,
                          assessmentCompletionDate: formattedDate,
                        });
                      } else {
                        setAssessmentCompletionDateErrorMsg(
                          'assessmentCompletionDateRequired',
                        );
                        setAssessmentCompletionDateError(true);
                        disableConsentDueToAssessmentCompletionDate(true);
                        setConsentForChangeforSDP({
                          ...consentForChangeforSDP,
                          assessmentCompletionDate: null,
                        });
                      }
                    }}
                  />
                  {assessmentCompletionDateError && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={assessmentCompletionDateErrorMsg}
                      defaultLabelId="Please enter a valid date."
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
          <Grid container className="alignItemCenter pt-sm">
            <Grid item>
              <MediumTypography
                labelid="IFSP.EligibilityMustBeReestablishedOn"
                defaultlabel="Eligibility must be re-established on:"
                className="pr-md"
                fontweight={400}
                textColor="#2A4241"
              />
            </Grid>
            <Grid item>
              <DatePickerComponent
                labelid={'dateText'}
                defaultlabelid={'Date'}
                value={
                  consentForChangeforSDP.reEstablishedDate === '' || null
                    ? (dayjs() as Dayjs)
                    : (dayjs(consentForChangeforSDP.reEstablishedDate) as Dayjs)
                }
                handlechange={(dateValue: Dayjs | null) => {
                  if (dateValue !== null) {
                    const formattedDate = dayjs(dateValue).format('MM/DD/YYYY');
                    if (datePickerMinDateMaxDateValidate(formattedDate)) {
                      setReEstablishDateErrorMsg(
                        'datePickerMinDateMaxDateValidate',
                      );
                      setReEstablishDateError(true);
                      disableConsentDueToReEstablishDate(true);
                    } else {
                      setReEstablishDateError(false);
                      disableConsentDueToReEstablishDate(false);
                    }
                    setConsentForChangeforSDP({
                      ...consentForChangeforSDP,
                      reEstablishedDate: formattedDate,
                    });
                  } else {
                    setReEstablishDateErrorMsg('reEstablishedDateRequired');
                    setReEstablishDateError(true);
                    disableConsentDueToReEstablishDate(true);
                    setConsentForChangeforSDP({
                      ...consentForChangeforSDP,
                      reEstablishedDate: null,
                    });
                  }
                }}
              />
              {reEstablishDateError && (
                <SmallTypography
                  sxProps={{ color: 'red' }}
                  labelId={reEstablishDateErrorMsg}
                  defaultLabelId="Please enter a valid date."
                />
              )}
            </Grid>
          </Grid>
          <MediumTypography
            labelid="IFSP.servicesMayOccurForLessThan"
            defaultlabel="*Services may occur for less than the specified period based on the child's age at the time of the eligibility evaluation. All EI services end one day prior to the child's third birthday."
            className="pt-sm"
            fontweight={400}
            textColor="#2A4241"
          />
          <MediumTypography
            labelid="IFSP.familyRightsNotice"
            defaultlabel="Family Rights Notice"
            fontSize="16px"
            fontweight={600}
            textColor="#2A4241"
            className="pt-md pb-md"
          />
          <AutocompleteSearch
            labelId={'IFSP.familyRightsNotice'}
            defaultId="Family Rights Notice"
            optionHintId={'noOptionFound'}
            className="consentAutoSearch"
            sxProps={{
              [`& .${autocompleteClasses.popupIndicator}`]: {
                transform: 'none',
              },
              backgroundColor: '#fff',
              width: '840px',
            }}
            data={contact
              .filter(
                (item) =>
                  item.type === 'Family' &&
                  item.id !== null &&
                  item.name !== null,
              )
              .map((item) => ({
                id: item.id.toString(),
                label: item.name,
              }))}
            onSelectItem={(value: OptionType | null) => {
              if (value) {
                setConsentForChangeforSDP({
                  ...consentForChangeforSDP,
                  parentInitial: { id: value.id, label: value.label },
                });
              }
            }}
            selectedValue={consentForChangeforSDP.parentInitial}
            disableLabel={false}
          />
          <MediumTypography
            labelid="IFSP.understandingTheConsent"
            defaultlabel="I/We have been informed of and received a copy of our Family Rights during the IFSP development process and understand that any accepted services will be provided."
            fontweight={400}
            textColor="#2A4241"
            className="pt-lg"
          />
          <MediumTypography
            labelid="IFSP.consentForService"
            defaultlabel="Consent For Service"
            fontSize="16px"
            fontweight={600}
            textColor="#2A4241"
            className="pt-md"
          />
          <MediumTypography
            labelid="IFSP.parentRights"
            defaultlabel="Parents have the right to provide, withhold or revoke consent for any and all services. Parents must give written consent for any change in EI services. Consent means that you have been made aware of changes and that you agree to them. Any service for which a parent provides consent must be provided within 30 days."
            fontweight={400}
            textColor="#2A4241"
            className="pt-sm"
          />
          <Box
            component="div"
            className="flex__ align__items__center pb-xs pt-md"
          >
            <CustomCheckBox
              value={consentForChangeforSDP.participated}
              onCheckBoxClick={(value) =>
                setConsentForChangeforSDP({
                  ...consentForChangeforSDP,
                  participated: value,
                })
              }
              style={{ padding: 0 }}
            />
            <MediumTypography
              labelid="IFSP.participatedIFSP"
              defaultlabel="I/We have participated in the development of our IFSP and:"
              fontweight={400}
              textColor="#2A4241"
              className="pl-sm"
            />
          </Box>
          <Box component="div" className="pl-lg">
            <CustomRadioButton
              data={[
                {
                  value: 'agree',
                  labelId: 'IFSP.agree',
                  defaultLabel:
                    'I/We agree to to the services described in this plan.',
                },
                {
                  value: 'disagree',
                  labelId: 'IFSP.disagree',
                  defaultLabel: 'I/We decline IFSP services at this time',
                },
                {
                  value: 'agreeException',
                  labelId: 'IFSP.agreeException',
                  defaultLabel:
                    'I/We agree to the services in this plan with the following exceptions',
                },
              ]}
              value={consentForChangeforSDP.agreeDisagree}
              onSelectItem={(value) => {
                if (value) {
                  setConsentForChangeforSDP({
                    ...consentForChangeforSDP,
                    agreeDisagree: value,
                  });
                }
              }}
              row={false}
              paddingY="4px"
            />
          </Box>
          <MediumTypography
            labelid="IFSP.exceptionList"
            defaultlabel="List exceptions"
            fontweight={400}
            textColor="#2A4241"
            className="pt-md"
          />
          <Textinput
            className="pt-sm "
            sxProps={{ width: '840px' }}
            inputProps={{
              maxLength: 250,
            }}
            Value={consentForChangeforSDP.exceptionList}
            handlechange={(text) =>
              setConsentForChangeforSDP({
                ...consentForChangeforSDP,
                exceptionList: text,
              })
            }
          />
          <MediumTypography
            labelid="IFSP.consentToAccessInsurance"
            defaultlabel="Consent To Access Insurance"
            fontSize="16px"
            fontweight={600}
            textColor="#2A4241"
            className="pt-md pb-md"
          />
          <Box component="div" className="flex__">
            <Box component="div">
              <CustomCheckBox
                value={consentForChangeforSDP.consent}
                onCheckBoxClick={(value) =>
                  setConsentForChangeforSDP({
                    ...consentForChangeforSDP,
                    consent: value,
                  })
                }
                style={{ padding: 0 }}
              />
            </Box>
            <MediumTypography
              labelid="IFSP.consent"
              defaultlabel="Parents have the right to provide, withhold or revoke consent for any and all services. Parents must give written consent for any change in EI services. Consent means that you have been made aware of changes and that you agree to them. Any service for which a parent provides consent must be provided within 30 days."
              fontweight={400}
              textColor="#2A4241"
              className="pl-sm"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ConsentServiceDeliveryPlan;
