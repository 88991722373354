import {
  Box,
  Card,
  Grid,
  InputAdornment,
  TextField,
  Chip,
  Tooltip,
  autocompleteClasses,
} from '@mui/material';
import {
  ChangeEvent,
  useContext,
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import TitleText from '../../components/formlib/TitleText';
import Textinput from '../../components/formlib/Textinput';
import SelectComponent from '../../components/formlib/SelectComponent';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import MediumTypography from '../../components/formlib/MediumTypography';
import AddressForm from '../../components/formlib/AddressForm';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { Formik, FormikErrors, FormikProps } from 'formik';
import * as yup from 'yup';
import {
  EMAIL_REGEX,
  PREVIEW_DOCUMENT_EXTENSIONS,
  STORAGE_USER_ID_KEY,
  employeeTypes,
} from '../../services/Constant';
import { ReactComponent as DeleteAlertIcon } from '../../assets/images/deleteAlertIcon.svg';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import dayjs, { Dayjs } from 'dayjs';
import { Address, EmployeeFormData, Program } from './types';
import {
  nameValidation,
  formatPhoneNumber,
  zipcodeValidation,
} from '../../utils/formValidationUtil';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Employee,
  LocalProgram,
  fileDelete,
  getEmployeeById,
  updateEmployeeDataByAdmin,
  updateEmployeeDataByUser,
  uploadEmployeeProfileOrCredentialFile,
} from '../../services/configApi/employees/employeeServices';
import {
  DateString,
  OptionType,
  ResponseType,
  SignatureStateType,
  SignatureType,
  employeeRoles,
} from '../../utils/type';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { getFullName } from '../../utils/nameUtils';
import './EditEmployeeScreen.css';
import { ReactComponent as DocIcon } from '../../assets/images/decIcon.svg';
import UploadButton from '../../components/formlib/UploadButton';
import CloseIcon from '../../assets/images/x.svg';
import { extractFileExtension } from '../../utils/fileUtils';
import FilePreviewDialog from '../../components/formlib/modal/FilePreviewDialog';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import ModalPopup from '../../components/formlib/ModalPopup';
import MultipleAutocompleteSearch from '../../components/formlib/MultipleAutocompleteSearch';
import { debounce } from 'lodash';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
import EmployeeSignatureModal, {
  EmployeeSignatureType,
} from '../../components/formlib/modal/EmployeeSignatureModal';
import { ReactComponent as Editsignature } from '../../assets/images/editsignature.svg';
import { forgetPinApi, getSignature } from '../../services/SignatureApi';
import ResetPinModal from '../../components/formlib/modal/ResetPinModal';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import _ from 'lodash';
import AutocompleteSearch from '../../components/formlib/AutocompleteSearch';
import { getReportingToList } from '../../services/ifspClient/IfspApi';
import {
  EMPLOYEE_DASHBOARD_ROUTE_NAME,
  EMPLOYEES_ROUTE_NAME,
  PROFILE_ROUTE_NAME,
} from '../../routes/Routing';
import OnBoardingEmployeeModal from './OnBoardingEmployeeModal';
import { datePickerMinDateMaxDateValidate } from '../../utils/dateUtil';
import { getAllGroupsAPIForOnBoarding } from '../../services/configApi/userGroups/userGroupServices';
import { isPointingToUAT, isSuperSuperAdmin } from '../../utils/utilities';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';

const initialValues: EmployeeFormData = {
  nameSuffix: '',
  id: '',
  clientId: '',
  kcUsername: '',
  firstName: '',
  middleName: '',
  lastName: '',
  preferredName: '',
  email: '',
  phoneNumber1: '',
  phoneNumber2: '',
  workingHours: 0,
  employeeRole: '',
  group: { id: '', groupName: '' },
  reportingTo: null,
  employeeType: null,
  employeeStartDate: null,
  employeeEndDate: null,
  superProgramId: '',
  programs: [
    {
      id: '',
      acronym: '',
      programId: '',
      editable: true,
      programName: '',
      programStartDate: null,
      programEndDate: null,
    },
  ],
  employeeCredentials: {
    credentialLicenseNumber: '',
    credentialExpirationDate: null,
    credentialFileUrl: '',
  },
  address1: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: { id: '', label: '' },
  },
  secondaryAddressRequired: false,
  address2: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: { id: '', label: '' },
  },
  emergencyContact: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: { id: '', label: '' },
    },
  },
  signaturePartitionKey: '',
  signatureRowKey: '',
  phoneNumberExtension: '',
  phoneNumberExtension2: '',
  defaultProgram: '',
  disciplineType: null,
  signatureValue: null,
};

const addressSchema = yup.object().shape({
  address1: yup.string().required('addressLine1RequiredMessage'),
  address2: yup.string(),
  zipCode: yup.object().shape({
    label: yup.string().required('zipCodeRequiredMessage'),
  }),
  city: yup.string().required('cityRequiredMessage'),
  state: yup.string().required('stateRequiredMessage'),
});

const validationSchema = yup.object().shape({
  firstName: yup.string().required('CommunicationLogPage.fieldEmptyMessage'),
  lastName: yup.string().required('CommunicationLogPage.fieldEmptyMessage'),
  middleName: yup.string(),
  preferredName: yup
    .string()
    .required('CommunicationLogPage.fieldEmptyMessage'),
  email: yup
    .string()
    .matches(EMAIL_REGEX, 'EmailFormat.Validation')
    .required('SignIn.validation.emailRequired'),
  phoneNumber1: yup
    .string()
    .min(12, 'PhoneNumber.Validation')
    .required('CommunicationLogPage.fieldEmptyMessage'),
  phoneNumber2: yup.string().min(12, 'PhoneNumber.Validation'),
  workingHours: yup
    .number()
    .max(120, 'workingHoursErrorMessage')
    .required('CommunicationLogPage.fieldEmptyMessage'),
  reportingTo: yup
    .object()
    .shape({
      id: yup.string(),
      label: yup.string(),
    })
    .required('CommunicationLogPage.fieldEmptyMessage')
    .nullable(),
  group: yup
    .object()
    .shape({
      id: yup.string().required('CommunicationLogPage.fieldEmptyMessage'),
      groupName: yup
        .string()
        .required('CommunicationLogPage.fieldEmptyMessage'),
    })
    .nullable(),
  employeeRole: yup.string().required('employeeRole.DefaultProgram'),
  employeeType: yup.string().required('CommunicationLogPage.fieldEmptyMessage'),
  defaultProgram: yup.string().required('Please check a default program'),
  programs: yup.array().of(
    yup.object().shape({
      programName: yup
        .string()
        .required('CommunicationLogPage.fieldEmptyMessage'),
      programStartDate: yup
        .string()
        .required('CommunicationLogPage.fieldEmptyMessage'),
    }),
  ),
  address1: addressSchema,
  secondaryAddressRequired: yup.boolean(),
  address2: yup.object().when('secondaryAddressRequired', {
    is: true,
    then: addressSchema,
    otherwise: yup.object(),
  }),
  emergencyContact: yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string().matches(EMAIL_REGEX, 'EmailFormat.Validation'),
    phoneNumber: yup.string().min(12, 'PhoneNumber.Validation'),
    address: yup.object().shape({
      address1: yup.string(),
      address2: yup.string(),
      zipCode: yup.object().shape({
        label: yup.string(),
      }),
      city: yup.string(),
      state: yup.string(),
    }),
  }),
  disciplineType: yup
    .string()
    .required('discipline.fieldEmptyMessage')
    .nullable(),
  signatureValue: yup
    .string()
    .required('Signature.fieldEmptyMessage')
    .nullable(),
});

const validateForm = (value: EmployeeFormData) => {
  const errors: Partial<EmployeeFormData> = {};

  if (value.employeeStartDate) {
    if (datePickerMinDateMaxDateValidate(value.employeeStartDate)) {
      errors.employeeStartDate = 'datePickerMinDateMaxDateValidate';
    }
  }

  if (value.employeeEndDate) {
    if (
      dayjs(value.employeeStartDate)
        .add(1, 'day')
        .isAfter(dayjs(value.employeeEndDate))
    ) {
      errors.employeeEndDate = 'effectiveEndDateValidationMessage';
    }
    if (datePickerMinDateMaxDateValidate(value.employeeEndDate)) {
      errors.employeeEndDate = 'datePickerMinDateMaxDateValidate';
    }
  }

  if (value.employeeCredentials.credentialExpirationDate) {
    if (
      datePickerMinDateMaxDateValidate(
        value.employeeCredentials.credentialExpirationDate,
      )
    ) {
      errors.employeeCredentials = {
        credentialExpirationDate: 'datePickerMinDateMaxDateValidate',
        credentialFileUrl: '',
        credentialLicenseNumber: '',
      };
    }
  }
  value.programs.forEach((program, index) => {
    if (datePickerMinDateMaxDateValidate(program.programStartDate)) {
      if (!errors.programs) {
        errors.programs = [];
      }
      if (!errors.programs[index]) {
        errors.programs[index] = {
          id: '',
          programName: '',
          programCode: '',
          programDirector: '',
          phoneNumber1: '',
          phoneNumber2: '',
          email: '',
          addressLine1: '',
          addressLine2: '',
          zipCode: '',
          city: '',
          state: '',
          faxNumber1: '',
          faxNumber2: '',
          acronym: '',
          programId: '',
          website: '',
          programImageId: '',
          programImageName: '',
          programImageUrl: '',
          isEdit: false,
          editable: false,
          programStartDate: '',
          programEndDate: '',
        };
      }
      errors.programs[index].programStartDate =
        'datePickerMinDateMaxDateValidate';
    }
  });

  value.programs.forEach((program, index) => {
    if (datePickerMinDateMaxDateValidate(program.programEndDate)) {
      if (!errors.programs) {
        errors.programs = [];
      }
      if (!errors.programs[index]) {
        errors.programs[index] = {
          id: '',
          programName: '',
          programCode: '',
          programDirector: '',
          phoneNumber1: '',
          phoneNumber2: '',
          email: '',
          addressLine1: '',
          addressLine2: '',
          zipCode: '',
          city: '',
          state: '',
          faxNumber1: '',
          faxNumber2: '',
          acronym: '',
          programId: '',
          website: '',
          programImageId: '',
          programImageName: '',
          programImageUrl: '',
          isEdit: false,
          editable: false,
          programStartDate: '',
          programEndDate: '',
        };
      }
      errors.programs[index].programEndDate =
        'datePickerMinDateMaxDateValidate';
    }
  });

  value.programs.forEach((program, index) => {
    if (dayjs(program.programEndDate).isBefore(program.programStartDate)) {
      if (!errors.programs) {
        errors.programs = [];
      }
      if (!errors.programs[index]) {
        errors.programs[index] = {
          id: '',
          programName: '',
          programCode: '',
          programDirector: '',
          phoneNumber1: '',
          phoneNumber2: '',
          email: '',
          addressLine1: '',
          addressLine2: '',
          zipCode: '',
          city: '',
          state: '',
          faxNumber1: '',
          faxNumber2: '',
          acronym: '',
          programId: '',
          website: '',
          programImageId: '',
          programImageName: '',
          programImageUrl: '',
          isEdit: false,
          editable: false,
          programStartDate: '',
          programEndDate: '',
        };
      }
      errors.programs[index].programEndDate =
        'effectiveEndDateValidationMessage';
    }
  });

  return errors;
};

const EditEmployeeScreen = () => {
  const location = useLocation();
  const [numOfPrograms, setNumOfPrograms] = useState<number>(0);
  const [employeeData, setEmployeeData] = useState<EmployeeFormData>();
  const [originalEmployeeData, setOriginalEmployeeData] =
    useState<EmployeeFormData>(initialValues);
  const formikRef = useRef<FormikProps<EmployeeFormData>>(null);
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');
  const [toastrId, setToastrId] = useState<string>();
  const [defaultMessage, setToastrDefaultMessage] = useState<string>();
  const [programList, setProgramList] = useState<string[]>([]);
  const [errorEmail, setErrorEmail] = useState('');
  const [programAddressDetails, setProgramAddressDetails] = useState<
    LocalProgram[]
  >([]);
  const navigate = useNavigate();
  const [imagefileSelected] = useState<File>();
  const [showPreviewModel, setShowPreviewModel] = useState<boolean>(false);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openModal, setOpenModal] = useState(false);
  const fileUploadedRef = useRef<File>();
  const isFileUploadedRef = useRef<boolean>(false);
  const isFileDeleted = useRef<boolean>(false);
  const isFileDeletedBeforeUploadRef = useRef<boolean>(false);
  const [fileSelected, setFileSelected] = useState<File>();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [openEmployeeSignature, setOpenEmployeeSignature] = useState(false);
  const signatureDetails = useRef<EmployeeSignatureType>({
    pin: '',
    employeeSignature: '',
  });
  const [employeeList, setEmployeeList] = useState<OptionType[]>([]);
  const signatureViewType = useRef<string>('');
  const [openResetModal, setOpenResetModal] = useState(false);
  const [screenFrom, setScreenFrom] = useState<string>('');
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [deflauttProgram, setDefatultProgram] = useState<string>('');
  // const [groups, setGroups] = useState<GroupType[]>([]);
  const [groupsDropdown, setGroupsDropdown] = useState<OptionType[]>([]);
  const [disciplineList, setDisciplineList] = useState<OptionType[]>([]);

  const { lookups } = useContext(LookUpContext) as LookUpContextType;
  const handleCancelAction = () => {
    formikRef.current?.resetForm();
    const initialProgram = originalEmployeeData.programs.filter(
      (item) => item.programId === employeeData?.defaultProgram,
    );
    setDefatultProgram(initialProgram[0]?.programId);
    setEmployeeData(originalEmployeeData);
    setNumOfPrograms(numOfPrograms);
  };

  useEffect(() => {
    if (lookups === null) {
      return;
    }

    setDisciplineList(() => {
      return lookups.discipline?.map((e) => {
        return {
          label: e.description,
          id: e.code,
        };
      });
    });
  }, [lookups]);

  const constructPrograms = useMemo(
    () =>
      (programs: LocalProgram[] | null): Program[] => {
        if (programs === null || programs.length === 0) {
          return [
            {
              id: '',
              acronym: '',
              programName: '',
              editable: true,
              programId: '',
              programStartDate: null,
              programEndDate: null,
            },
          ];
        }

        return programs.map((program) => ({
          id: program.id,
          editable: false,
          programId: program.programId || '',
          acronym: program.acronym || '',
          programName: program.programName || '',
          programStartDate: program.startDate,
          programEndDate: program.endDate,
        }));
      },
    [],
  );

  const getPrograms = useMemo(
    () =>
      (programs: Program[]): LocalProgram[] => {
        const newProgams: LocalProgram[] = [];
        programs.forEach((program) => {
          newProgams.push({
            id: program.id,
            acronym: program.acronym,
            programName: program.programName,
            programId: program.programId,
            startDate: program.programStartDate as string,
            endDate: program.programEndDate as string,
          });
        });
        return newProgams;
      },
    [],
  );

  const getPreferredName = (
    firstName: string | null,
    lastName: string | null | undefined,
    preferredName?: string | null,
  ): string => {
    if (preferredName) {
      return preferredName;
    }
    const finalPreferredName = `${firstName || ''}${
      lastName ? lastName.charAt(0).toUpperCase() : ''
    }`;
    return finalPreferredName.replace(/[^a-zA-Z]/g, '');
  };

  const handlePreviewClick = () => {
    setShowPreviewModel(true);
  };

  const getEmployeeList = () => {
    toggleLoader(true);
    getReportingToList()
      .then((response) => {
        const employees: OptionType[] = [];
        const emp = location.state.employee as Employee;
        // const userId = emp.id;
        response.forEach((empl) => {
          // if (empl.id !== userId || isSuperSuperAdmin()) {
          employees.push({
            id: empl.id,
            label: `${empl.name} - ${empl.email}`,
          });
          // }
          return employees;
        });
        setEmployeeList(employees);

        if (emp && emp.reportingTo !== null) {
          const foundEmp = employees.find((e) => e.id === emp.reportingTo);
          if (foundEmp) {
            getEmployeebyId(emp, foundEmp);
          } else {
            getEmployeebyId(emp, null);
          }
        } else {
          getEmployeebyId(emp, null);
        }
      })
      .catch((error) => {
        if (isCustomError(error)) {
          toggleLoader(false);
          setSuccessOrError('error');
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          const emp = location.state as Employee;
          getEmployeebyId(emp, null);
        }
      });
  };

  useEffect(() => {
    if (
      location.state?.screenFrom !== undefined ||
      location.state?.screenFrom !== null
    ) {
      setScreenFrom(location.state?.screenFrom);
    }
    getEmployeeList();
  }, []);

  const getFormatedEmployee = (
    employee: Employee,
    reportee: OptionType | null,
  ) => {
    return {
      ...initialValues,
      nameSuffix: employee.nameSuffix,
      id: employee.id,
      group: { id: employee.group.id, groupName: employee.group.groupName },
      kcUsername: employee.kcUsername !== null ? employee.kcUsername : '',
      firstName: employee.firstName !== null ? employee.firstName : '',
      lastName: employee.lastName !== null ? employee.lastName : '',
      middleName: employee.middleName !== null ? employee.middleName : '',
      preferredName: getPreferredName(
        employee.firstName,
        employee.lastName,
        employee.preferredName,
      ),
      email: employee.email !== null ? employee.email : '',
      phoneNumber1: employee.phone1 !== null ? employee.phone1 : '',
      phoneNumber2: employee.phone2 !== null ? employee.phone2 : '',
      workingHours:
        employee.workingHoursPerWeek !== null
          ? employee.workingHoursPerWeek
          : 0,
      employeeRole: employee.employeeRole !== null ? employee.employeeRole : '',
      reportingTo: reportee,
      employeeType:
        employee.employeeType !== null ? employee.employeeType : null,
      employeeStartDate: employee.startDate,
      employeeEndDate: employee.endDate,
      programs: constructPrograms(employee.programs),
      address1: {
        address1:
          employee.primaryAddress1 !== null ? employee.primaryAddress1 : '',
        address2:
          employee.primaryAddress2 !== null ? employee.primaryAddress2 : '',
        zipCode:
          employee.primaryZipcode !== null
            ? { id: 'aa', label: employee.primaryZipcode }
            : '',
        city: employee.primaryCity !== null ? employee.primaryCity : '',
        state: employee.primaryState !== null ? employee.primaryState : '',
      },
      address2: {
        address1:
          employee.secondaryAddress1 !== null ? employee.secondaryAddress1 : '',
        address2:
          employee.secondaryAddress2 !== null ? employee.secondaryAddress2 : '',
        zipCode:
          employee.secondaryZipcode !== null
            ? { id: 'aa', label: employee.secondaryZipcode }
            : '',
        city: employee.secondaryCity !== null ? employee.secondaryCity : '',
        state: employee.secondaryState !== null ? employee.secondaryState : '',
      },
      secondaryAddressRequired: employee.secondaryAddressRequired,
      emergencyContact: {
        firstName:
          employee.emergencyFirstName !== null
            ? employee.emergencyFirstName
            : '',
        lastName:
          employee.emergencyLastName !== null ? employee.emergencyLastName : '',
        email: employee.emergencyEmail !== null ? employee.emergencyEmail : '',
        phoneNumber:
          employee.emergencyPhone !== null ? employee.emergencyPhone : '',
        address: {
          address1:
            employee.emergencyAddress1 !== null
              ? employee.emergencyAddress1
              : '',
          address2:
            employee.emergencyAddress2 !== null
              ? employee.emergencyAddress2
              : '',
          zipCode:
            employee.emergencyZipcode !== null
              ? { id: 'aa', label: employee.emergencyZipcode }
              : '',
          city: employee.emergencyCity !== null ? employee.emergencyCity : '',
          state:
            employee.emergencyState !== null ? employee.emergencyState : '',
        },
      },
      employeeCredentials: {
        credentialLicenseNumber:
          employee.employeeCredentials?.credentialLicenseNumber,
        credentialExpirationDate:
          employee.employeeCredentials?.credentialExpirationDate,
        credentialFileUrl: employee.employeeCredentials?.credentialFileUrl,
      },
      signaturePartitionKey: employee.signaturePartitionKey,
      signatureRowKey: employee.signatureRowKey,
      phoneNumberExtension: employee.phoneNumberExtension,
      phoneNumberExtension2: employee.phoneNumberExtension2,
      defaultProgram: employee.defaultProgram,
      disciplineType: employee.disciplineType,
      signatureValue: employee.signaturePartitionKey,
    } as EmployeeFormData;
  };

  const getEmployeebyId = (
    employeeObject: Employee,
    reportee: OptionType | null,
  ) => {
    getEmployeeById(employeeObject.id)
      .then((employee: Employee) => {
        const originalData = getFormatedEmployee(employee, reportee);
        if (employee.signaturePartitionKey) {
          signatureDetails.current.employeeSignature = employee.signature;
        }
        if (
          employee.employeeCredentials !== null &&
          employee.employeeCredentials.credentialFileUrl !== null
        ) {
          const url = new URL(employee.employeeCredentials.credentialFileUrl);
          const parts = url.pathname.split('/');
          const uploadedFilename = decodeURIComponent(parts[parts.length - 1]);

          const filenameWithoutTimestamp = uploadedFilename.replace(
            /-\d{4}-\d{2}-\d{2}-\d{2}-\d{2}-\d{2}\.pdf$/,
            '.pdf',
          );

          const fakeFile = new File([new Blob()], filenameWithoutTimestamp);
          isFileDeletedBeforeUploadRef.current = true;
          fileUploadedRef.current = fakeFile;
          setFileSelected(fakeFile);
        }
        const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
        const programs: string[] = [];
        if (employee.programs !== null) {
          employee.programs.forEach((program) => {
            if (program.programName) {
              programs.push(program.programName);
            }
          });
        }
        if (employee.programs !== null)
          setProgramAddressDetails(employee.programs);
        setProgramList(programs);
        setNumOfPrograms(originalData.programs.length);
        setEmployeeData(originalData);
        setOriginalEmployeeData(originalData);
        setIsAdmin(userId !== originalData.id);
        return getAllGroupsAPIForOnBoarding();
      })
      .then((response) => {
        const groupsForDropdown: OptionType[] = [];

        const selectedProgram = localStorage.getItem('selectedProgram');
        const superProgramId = localStorage.getItem('superProgramId');

        let localSelectedProgramId = '';
        if (selectedProgram) {
          localSelectedProgramId = JSON.parse(selectedProgram).id;
        }

        response.groups.forEach((group) => {
          if (
            localSelectedProgramId &&
            localSelectedProgramId === superProgramId
          ) {
            groupsForDropdown.push({
              id: group.id,
              label: group.groupName,
            });
          } else if (group.createdBy !== 'Default' || group.level !== 1) {
            groupsForDropdown.push({
              id: group.id,
              label: group.groupName,
            });
          }
          return group;
        });
        setGroupsDropdown(groupsForDropdown);
        toggleLoader(false);
      })
      .catch((error) => {
        // Handle errors for the first API call
        toggleLoader(false);
        if (isCustomError(error)) {
          setSuccessOrError('error');
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          const errorMessage = error as ApiMessage;
          if (errorMessage.code === 404) {
            const originalData = getFormatedEmployee(employeeObject, reportee);
            const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
            setNumOfPrograms(originalData.programs.length);
            setEmployeeData(originalData);
            setOriginalEmployeeData(originalData);
            setIsAdmin(userId !== originalData.id);
          } else {
            setSuccessOrError('error');
            setToastrId('employeeError');
            setToastrDefaultMessage('Failed to fetch employee details');
          }
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('employeeError');
          setToastrDefaultMessage('Failed to Fetch Data');
        }
      });
  };

  const handleSubmission = useCallback(
    debounce((values: EmployeeFormData) => {
      setEmployeeData(values);
      setOriginalEmployeeData(values);
      updateEmployeeForm(JSON.parse(JSON.stringify(values)));
    }, 500),
    [],
  );
  const updateEmployeeForm = (values: EmployeeFormData) => {
    toggleLoader(true);
    const selectedProgram = localStorage.getItem('selectedProgram');
    let selectedProgramId = '';
    let joiningDateForUAT: DateString = '';
    if (selectedProgram) {
      selectedProgramId = JSON.parse(selectedProgram).id;
    }
    if (selectedProgramId) {
      joiningDateForUAT =
        values.programs.find(
          (program) => program.programId === selectedProgramId,
        )?.programStartDate ?? values.employeeStartDate;
    }
    const employee: Employee = {
      nameSuffix: values.nameSuffix,
      id: values.id,
      clientId: values.clientId,
      kcUsername: values.kcUsername,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      preferredName: values.preferredName,
      email: values.email,
      phone1: values.phoneNumber1,
      phone2: values.phoneNumber2,
      workingHoursPerWeek: values.workingHours,
      employeeRole: values.employeeRole,
      reportingTo:
        values.reportingTo !== null
          ? (values.reportingTo as OptionType).id
          : null,
      employeeType: values.employeeType,
      group: {
        id: values.group.id,
        groupName: values.group.groupName,
      },
      startDate: isPointingToUAT()
        ? dayjs(joiningDateForUAT).format('MM/DD/YYYY')
        : (values.employeeStartDate as string),
      endDate: values.employeeEndDate as string,
      primaryAddress1: values.address1.address1,
      primaryAddress2: values.address1.address2,
      primaryZipcode: (values.address1.zipCode as OptionType).label,
      primaryCity: values.address1.city,
      primaryState: values.address1.state,
      secondaryAddressRequired: values.secondaryAddressRequired,
      secondaryAddress1:
        values.secondaryAddressRequired === true
          ? values.address2.address1
          : '',
      secondaryAddress2:
        values.secondaryAddressRequired === true
          ? values.address2.address2
          : '',
      secondaryZipcode:
        values.secondaryAddressRequired === true
          ? (values.address2.zipCode as OptionType).label
          : '',
      secondaryCity:
        values.secondaryAddressRequired === true ? values.address2.city : '',
      secondaryState:
        values.secondaryAddressRequired === true ? values.address2.state : '',
      emergencyFirstName: values.emergencyContact.firstName,
      emergencyLastName: values.emergencyContact.lastName,
      emergencyPhone: values.emergencyContact.phoneNumber,
      emergencyEmail: values.emergencyContact.email,
      emergencyAddress1: values.emergencyContact.address.address1,
      emergencyAddress2: values.emergencyContact.address.address2,
      emergencyZipcode: (values.emergencyContact.address.zipCode as OptionType)
        .label,
      emergencyCity: values.emergencyContact.address.city,
      emergencyState: values.emergencyContact.address.state,
      superProgramId: values.superProgramId,
      programs: getPrograms(values.programs),
      name: '',
      status: null,
      allowDelete: null,
      employeeCredentials: {
        credentialLicenseNumber:
          values.employeeCredentials?.credentialLicenseNumber,
        credentialExpirationDate:
          values.employeeCredentials?.credentialExpirationDate,
        credentialFileUrl: values.employeeCredentials?.credentialFileUrl,
      },
      signature: signatureDetails.current.employeeSignature,
      pin:
        signatureDetails.current.pin !== ''
          ? signatureDetails.current.pin
          : null,
      signaturePartitionKey: values.signaturePartitionKey,
      signatureRowKey: values.signatureRowKey,
      phoneNumberExtension: values.phoneNumberExtension,
      phoneNumberExtension2: values.phoneNumberExtension2,
      defaultProgram: values.defaultProgram,
      disciplineType: values.disciplineType,
    };

    let tempFile: File | null;
    let tempImageFile: File | null;

    if (fileUploadedRef.current !== undefined) {
      tempFile = fileUploadedRef.current;
    } else {
      tempFile = null;
    }
    if (imagefileSelected !== undefined) {
      tempImageFile = imagefileSelected;
    } else {
      tempImageFile = null;
    }
    if (isFileUploadedRef.current === true && !isFileDeleted.current === true) {
      uploadFileEmployee(tempImageFile, tempFile, employee);
    } else if (
      isFileDeleted.current === true &&
      isFileUploadedRef.current === false
    ) {
      fileDelete(employee.id, false, isFileDeleted.current)
        .then(() => {
          updateEmployee(employee);
        })
        .catch((error) => {
          toggleLoader(false);
          setSuccessOrError('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('employeefiledeletefail');
            setToastrDefaultMessage('employeefiledeletefail');
          }
        });
    } else if (
      isFileDeleted.current === true &&
      isFileUploadedRef.current === true
    ) {
      fileDelete(employee.id, false, isFileDeleted.current)
        .then(() => {
          uploadFileEmployee(tempImageFile, tempFile, employee);
        })
        .catch((error) => {
          setSuccessOrError('error');
          toggleLoader(false);
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('employeefiledeletefail');
            setToastrDefaultMessage('Failed to delete file');
          }
        });
    } else if (
      isFileDeletedBeforeUploadRef.current === true &&
      isFileDeleted.current === true
    ) {
      updateEmployee(employee);
    } else {
      updateEmployee(employee);
    }
  };

  const uploadFileEmployee = (
    credFile: File | null,
    tempFile: File | null,
    employee: Employee,
  ) => {
    uploadEmployeeProfileOrCredentialFile(credFile, tempFile, employee.id)
      .then(() => {
        updateEmployee(employee);
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('employeeFileUploadFailure');
          setToastrDefaultMessage('Failed to Upload File');
        }
      });
  };
  const updateEmployee = (employee: Employee) => {
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (userId !== employee.id) {
      updateEmployeeDataByAdmin(employee)
        .then(() => {
          toggleLoader(false);
          isFileDeleted.current = false;
          formikRef.current?.resetForm();
          setSuccessOrError('success');
          setToastrId('updateSuccessMessage');
          const selectedProgreamId: string = JSON.parse(
            localStorage.getItem('selectedProgram') || '{}',
          ).id;
          if (localStorage.getItem(selectedProgreamId)) {
            setTimeout(() => {
              navigate(
                screenFrom === 'employeeList' ||
                  location.state?.screenFrom === 'employeeList'
                  ? EMPLOYEES_ROUTE_NAME
                  : PROFILE_ROUTE_NAME,
              );
            }, 1000);
          } else {
            setTimeout(() => {
              navigate(EMPLOYEE_DASHBOARD_ROUTE_NAME);
            }, 1000);
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setSuccessOrError('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrDefaultMessage('Failed to update employee details');
            setToastrId('updateFailureMessage');
          }
        });
    } else {
      updateEmployeeDataByUser(employee)
        .then(() => {
          toggleLoader(false);
          isFileDeleted.current = false;
          formikRef.current?.resetForm();
          setSuccessOrError('success');
          setToastrId('updateSuccessMessage');
          const selectedProgreamId: string = JSON.parse(
            localStorage.getItem('selectedProgram') || '{}',
          ).id;
          if (localStorage.getItem(selectedProgreamId)) {
            setTimeout(() => {
              navigate(
                screenFrom === 'employeeList' ||
                  location.state?.screenFrom === 'employeeList'
                  ? EMPLOYEES_ROUTE_NAME
                  : PROFILE_ROUTE_NAME,
              );
            }, 1000);
          } else {
            setTimeout(() => {
              navigate(EMPLOYEE_DASHBOARD_ROUTE_NAME);
            }, 1000);
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setSuccessOrError('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrDefaultMessage('Failed to update employee details');
            setToastrId('updateFailureMessage');
          }
        });
    }
  };

  const getEmployeeName = () => {
    if (employeeData) {
      return getFullName(employeeData.firstName, employeeData.lastName);
    }
    return '';
  };

  const onChipDelete = () => {
    setFileSelected(undefined);
    fileUploadedRef.current = undefined;

    if (isFileDeletedBeforeUploadRef.current === true) {
      isFileDeleted.current = true;
    } else {
      isFileUploadedRef.current = false;
      isFileDeleted.current = false;
    }
    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile =
      event.target.files !== null && event.target.files.length > 0
        ? event.target.files[0]
        : null;
    if (selectedFile !== null) {
      if (
        selectedFile.type === 'application/pdf' &&
        selectedFile.size <= 5242880 &&
        selectedFile.size !== 0
      ) {
        fileUploadedRef.current = selectedFile;
        setFileSelected(selectedFile);
        isFileUploadedRef.current = true;
        if (employeeData) {
          employeeData.employeeCredentials.credentialFileUrl =
            URL.createObjectURL(selectedFile);
          setEmployeeData(employeeData);
        }
      } else {
        setSuccessOrError('error');
        setToastrId('pdfValidate');
        setToastrDefaultMessage(
          'Please select a valid PDF file with a size of up to 5MB.',
        );
      }
    }
  };

  const browseFiles = () => {
    inputFileRef.current?.click();
  };

  useEffect(() => {
    const checked = formikRef.current?.values.programs.filter(
      (item) => item.programId === employeeData?.defaultProgram,
    );
    if (checked && checked.length > 0) {
      setDefatultProgram(checked[0]?.programId);
    }
  }, [employeeData]);

  const licenseNumValidation = (value: string): string | undefined => {
    const trimmedValue = value?.replace(/^\s+/, '');
    const regex = /^[A-Za-z0-9!,'. ]*$/;
    if (regex.test(trimmedValue)) {
      return trimmedValue;
    }
  };

  const clearForm = () => {
    setOpenModal(false);
    handleCancelAction();
  };

  const fetchSiganture = () => {
    toggleLoader(true);
    let signatureObj: SignatureType[] = [];
    if (employeeData) {
      signatureObj = [
        {
          partitionkey: employeeData.signaturePartitionKey,
          id: employeeData.id,
          rowkey: employeeData.signatureRowKey,
        },
      ];
    }

    getSignature(signatureObj)
      .then((response: SignatureStateType[]) => {
        toggleLoader(false);
        signatureDetails.current.employeeSignature = response[0].signaturedata;
        setOpenEmployeeSignature(!openEmployeeSignature);
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('sigantureFetchFail');
          setToastrDefaultMessage('Failed To fetch signature');
        }
      });
  };

  const callForgotPinApi = () => {
    toggleLoader(true);

    let forgotPinEmployeeId: string = '';
    if (employeeData) {
      forgotPinEmployeeId = employeeData.id;
    }
    forgetPinApi(forgotPinEmployeeId)
      .then(() => {
        toggleLoader(false);
        setOpenResetModal(!openResetModal);
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrDefaultMessage(
            'Failed to Send Temporary Pin To Your Registered Email',
          );
          setToastrId('forgorApiErrorMesg');
        }
      });
  };

  const handleSuccess = (
    successerror: ResponseType,
    id: string,
    mes: string,
  ) => {
    setSuccessOrError(successerror);
    setToastrId(id);
    setToastrDefaultMessage(mes);
  };

  const pinChangeCallback = (newPin: string) => {
    signatureDetails.current.pin = newPin;
  };

  const handleEmailChange = (e: string) => {
    const value = e;
    const emailRegex = /^[^\s@]{1,64}@[A-Za-z0-9.-]{1,255}\.[A-Za-z]{2,}$/;
    if (value !== '' && !emailRegex.test(value)) {
      setErrorEmail('Invalid email address');
    } else {
      setErrorEmail('');
    }
  };
  return employeeData !== undefined ? (
    <Box component="main">
      <Box component="section">
        {showPreviewModel &&
          fileSelected &&
          employeeData.employeeCredentials !== null &&
          employeeData.employeeCredentials.credentialFileUrl !== null && (
            <FilePreviewDialog
              url={employeeData.employeeCredentials.credentialFileUrl}
              fileName={fileSelected.name}
              onClose={() => {
                setShowPreviewModel(false);
              }}
            />
          )}
        <Box className="rowContainer">
          <CustomBackArrow
            onClick={() =>
              navigate(
                screenFrom === 'employeeList'
                  ? EMPLOYEES_ROUTE_NAME
                  : PROFILE_ROUTE_NAME,
              )
            }
          />
          <Box component="div" className="ml-md">
            <TitleText label={getEmployeeName()} defaultlabel="Employee" />
          </Box>
        </Box>
        {toastrId && (
          <SnackBarComponent
            open={toastrId !== undefined}
            handleClose={() => {
              setToastrId(undefined);
            }}
            labelId={toastrId}
            defaultMessageId={defaultMessage}
            successOrError={successOrError}
          />
        )}
        <OnBoardingEmployeeModal
          modalVisible={
            isAdmin &&
            (employeeData.signatureRowKey === '' ||
              employeeData.signatureRowKey === null)
              ? true
              : false
          }
          employeeData={employeeData}
          employeeList={employeeList}
          groups={groupsDropdown}
          handleSubmission={handleSubmission}
        />
        {openEmployeeSignature && (
          <EmployeeSignatureModal
            open={openEmployeeSignature}
            details={signatureDetails.current}
            modalType={signatureViewType.current}
            employeeDetails={employeeData}
            onSuccess={handleSuccess}
            signCanChage={isAdmin ? false : true}
            onSave={(signature: string, pin: string) => {
              signatureDetails.current.employeeSignature = signature;
              signatureDetails.current.pin = pin;
              if (formikRef.current !== null) {
                formikRef.current.values.signatureValue = signature;
              }
            }}
            handleClose={() => {
              setOpenEmployeeSignature(!openEmployeeSignature);
            }}
          />
        )}

        {openResetModal && (
          <ResetPinModal
            open={openResetModal}
            employeeDetails={employeeData}
            onSuccess={handleSuccess}
            handleClose={() => {
              setOpenResetModal(!openResetModal);
            }}
            handlePin={pinChangeCallback}
          />
        )}
        <Box component="div">
          <Formik
            innerRef={formikRef}
            initialValues={employeeData}
            validateOnBlur={true}
            validateOnChange={false}
            validateOnMount={true}
            enableReinitialize={true}
            validationSchema={validationSchema}
            validate={validateForm}
            onSubmit={(values) => {
              handleSubmission(values);
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              touched,
              handleSubmit,
            }) => (
              <Box component="div" onBlur={handleBlur}>
                <Card className="formCardview">
                  <Grid container rowSpacing={'24px'} columnSpacing={'40px'}>
                    <Grid item xs={2.5}>
                      <Textinput
                        name="firstName"
                        labelid="AddNewReferralDetails.First"
                        defaultlabelid="First Name"
                        Value={values.firstName}
                        inputProps={{ readOnly: true }}
                        handlechange={(text) => {
                          setFieldValue('firstName', nameValidation(text));
                        }}
                        Required
                      />
                      {errors.firstName && touched.firstName && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.firstName}
                          defaultlabel="Field should not be empty"
                        />
                      )}
                    </Grid>
                    <Grid item xs={2.5}>
                      <Textinput
                        name="middleName"
                        labelid="Clientpage.Middle"
                        defaultlabelid="Middle Name"
                        Value={values.middleName}
                        handlechange={(text) => {
                          setFieldValue('middleName', nameValidation(text));
                        }}
                      />
                    </Grid>
                    <Grid item xs={2.5}>
                      <Textinput
                        name="lastName"
                        labelid="AddNewReferralDetails.Last"
                        defaultlabelid="Last Name"
                        Value={values.lastName}
                        inputProps={{ readOnly: true }}
                        handlechange={(text) => {
                          setFieldValue('lastName', nameValidation(text));
                          setFieldValue(
                            'preferredName',
                            getPreferredName(
                              values.firstName,
                              nameValidation(text),
                            ),
                          );
                        }}
                        Required
                      />
                      {errors.lastName && touched.lastName && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.lastName}
                          defaultlabel="Field should not be empty"
                        />
                      )}
                    </Grid>
                    <Grid item xs={1.5}>
                      <Textinput
                        name="nameSuffix"
                        labelid="AddNewReferralDetails.Suffix"
                        defaultlabelid="Suffix"
                        Value={values.nameSuffix}
                        inputProps={{ maxLength: 20 }}
                        handlechange={(text) => {
                          setFieldValue('nameSuffix', nameValidation(text));
                        }}
                      />
                    </Grid>
                    <Grid item xs={4.5}>
                      <Textinput
                        name="preferredName"
                        labelid="preferredName"
                        type="text"
                        defaultlabelid="Preferred Name"
                        inputProps={{ maxLength: 25 }}
                        Value={values.preferredName}
                        handlechange={(text) => {
                          setFieldValue(
                            'preferredName',
                            nameValidation(text).replace(' ', ''),
                          );
                        }}
                        Required
                      />
                      {errors.preferredName && touched.preferredName && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.preferredName}
                          defaultlabel="Field should not be empty"
                        />
                      )}
                    </Grid>
                    <Grid item xs={4.5}>
                      <Textinput
                        name="email"
                        labelid="Contacts.email"
                        defaultlabelid="Email"
                        Value={values.email}
                        type={'email'}
                        inputProps={{ readOnly: true }}
                        handlechange={handleChange('email')}
                        Required
                      />
                      {errors.email && touched.email && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.email}
                          defaultlabel="Field should not be empty"
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}></Grid>

                    <Grid item xs={3}>
                      <Textinput
                        name="phoneNumber1"
                        labelid="phoneNumber1Text"
                        defaultlabelid="Primary Phone No."
                        Value={values.phoneNumber1}
                        handlechange={(phoneNumber) => {
                          setFieldValue(
                            'phoneNumber1',
                            formatPhoneNumber(phoneNumber),
                          );
                        }}
                        Required
                      />
                      {errors.phoneNumber1 && touched.phoneNumber1 && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.phoneNumber1}
                          defaultlabel="Field should not be empty"
                        />
                      )}
                    </Grid>
                    <Grid item xs={1.5}>
                      <Textinput
                        name="ext"
                        labelid="EmployeeScreen.extentionText"
                        defaultlabelid="Ext."
                        inputProps={{ maxLength: 5 }}
                        Value={values.phoneNumberExtension}
                        handlechange={(phoneNumber) => {
                          const newValue = phoneNumber
                            .trimStart()
                            .replace(/\D/g, '');
                          setFieldValue('phoneNumberExtension', newValue);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Textinput
                        name="phoneNumber2"
                        labelid="phoneNumber2Text"
                        defaultlabelid="Secondary Phone No."
                        Value={values.phoneNumber2}
                        handlechange={(phoneNumber) => {
                          setFieldValue(
                            'phoneNumber2',
                            formatPhoneNumber(phoneNumber),
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={1.5}>
                      <Textinput
                        name="ext"
                        labelid="EmployeeScreen.extentionText"
                        defaultlabelid="Ext."
                        inputProps={{ maxLength: 5 }}
                        Value={values.phoneNumberExtension2}
                        handlechange={(phoneNumber) => {
                          const newValue = phoneNumber
                            .trimStart()
                            .replace(/\D/g, '');
                          setFieldValue('phoneNumberExtension2', newValue);
                        }}
                      />
                    </Grid>
                    <Grid item xs={4.5}>
                      <SelectComponent
                        name="employeeType"
                        labelid="employeeTypeText"
                        defaultlabelid="Employee Type"
                        names={employeeTypes}
                        value={
                          values.employeeType !== null
                            ? values.employeeType
                            : ''
                        }
                        disabled={!isPointingToUAT()}
                        handleChange={handleChange('employeeType')}
                        Required={true}
                      />
                      {errors.employeeType && touched.employeeType && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.employeeType}
                          defaultlabel="Field should not be empty"
                        />
                      )}
                    </Grid>

                    <Grid item xs={2.25} display={'none'}>
                      <DatePickerComponent
                        labelid="editEmpstartDateText"
                        defaultlabelid="Start Date*"
                        value={dayjs(values.employeeStartDate) as Dayjs}
                        required={true}
                        disableFuture={false}
                        handlechange={(date: Dayjs | null) => {
                          if (date === null) {
                            formikRef.current?.setValues({
                              ...values,
                              employeeStartDate: null,
                              employeeEndDate: null,
                            });
                          } else {
                            formikRef.current?.setValues({
                              ...values,
                              employeeStartDate:
                                dayjs(date).format('MM/DD/YYYY'),
                              employeeEndDate: null,
                            });
                          }
                        }}
                      />
                      {errors.employeeStartDate &&
                        touched.employeeStartDate && (
                          <MediumTypography
                            className="errorText-md"
                            labelid={errors.employeeStartDate}
                            defaultlabel="Field should not be empty"
                          />
                        )}
                    </Grid>

                    <Grid item xs={2.25} display={'none'}>
                      <DatePickerComponent
                        labelid="endDateText"
                        defaultlabelid="End Date"
                        value={dayjs(values.employeeEndDate) as Dayjs}
                        minDate={dayjs(values.employeeStartDate).add(1, 'day')}
                        handlechange={(date: Dayjs | null) => {
                          if (values.employeeStartDate) {
                            if (date === null) {
                              setFieldValue('employeeEndDate', null);
                            } else {
                              setFieldValue(
                                'employeeEndDate',
                                dayjs(date).format('MM/DD/YYYY'),
                              );
                            }
                          }
                        }}
                      />
                      {errors.employeeEndDate && touched.employeeEndDate && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.employeeEndDate}
                          defaultlabel="Field should not be empty"
                        />
                      )}
                    </Grid>
                    <Grid item xs={4.5}>
                      <Textinput
                        name="workingHoursPerWeek"
                        labelid="workingHoursPerWeek"
                        defaultlabelid="Working Hours Per Week"
                        inputProps={{ maxLength: 3 }}
                        Value={values.workingHours.toString()}
                        handlechange={(text) => {
                          if (text.length < 4) {
                            const zip = zipcodeValidation(text);
                            if (zip.length > 0) {
                              setFieldValue('workingHours', parseInt(zip));
                            } else {
                              setFieldValue('workingHours', '');
                            }
                          }
                        }}
                        disabled={
                          !isAdmin && !isSuperSuperAdmin() && !isPointingToUAT()
                        }
                        Required
                      />
                      {errors.workingHours && touched.workingHours && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.workingHours}
                          defaultlabel="Field should not be empty"
                        />
                      )}
                    </Grid>
                    <Grid item xs={4.5}>
                      <AutocompleteSearch
                        className="consentAutoSearch"
                        labelId={'reportingToText'}
                        defaultId="Reporting To*"
                        optionHintId={'noemployeeDropdownTextId'}
                        sxProps={{
                          [`& .${autocompleteClasses.popupIndicator}`]: {
                            transform: 'none',
                          },
                        }}
                        data={employeeList}
                        selectedValue={values.reportingTo as OptionType}
                        onSelectItem={(value: OptionType | null) => {
                          if (value) {
                            setFieldValue('reportingTo', value);
                          } else {
                            setFieldValue('reportingTo', '');
                          }
                        }}
                        disabled={
                          !isAdmin && !isSuperSuperAdmin() && !isPointingToUAT()
                        }
                        disableLabel={true}
                      />
                      {errors.reportingTo && touched.reportingTo && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.reportingTo}
                          defaultlabel="Field should not be empty"
                        />
                      )}
                    </Grid>
                    <Grid item xs={4.5} className="multisearchHgt">
                      <MultipleAutocompleteSearch
                        labelId="employeeRoleText"
                        defaultId="Employee Role"
                        data={employeeRoles}
                        type="string"
                        required
                        onSelectItem={(value: OptionType[] | null) => {
                          if (value) {
                            const selectedContactIds = value.map(
                              (emp) => emp.id,
                            );
                            setFieldValue(
                              'employeeRole',
                              selectedContactIds.toString(),
                            );
                          }
                        }}
                        disableLabel={true}
                        optionHintId={'No Employee Role found'}
                        sxProps={{
                          [`& .${autocompleteClasses.popupIndicator}`]: {
                            transform: 'none',
                            marginTop: '2px',
                          },
                        }}
                        value={values.employeeRole
                          .split(',') // Split the string by comma
                          .map((emp) => ({
                            id: emp,
                            label:
                              employeeRoles.find(
                                (empRole) => empRole.id === emp,
                              )?.label || '', // Set label to empty string if not found
                          }))
                          .filter(
                            (option): option is { id: string; label: string } =>
                              option.label !== '', // Filter out options with empty labels
                          )}
                        disabled={
                          !isAdmin && !isSuperSuperAdmin() && !isPointingToUAT()
                        }
                      />
                      {errors.employeeRole && touched.employeeRole && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.employeeRole}
                          defaultlabel="Field should not be empty"
                        />
                      )}
                    </Grid>
                    <Grid item xs={4.5} className="multisearchHgt">
                      <AutocompleteSearch
                        className="consentAutoSearch"
                        labelId={'selectGroupText'}
                        defaultId="Select Group*"
                        optionHintId={'noOptionFound'}
                        sxProps={{
                          [`& .${autocompleteClasses.popupIndicator}`]: {
                            transform: 'none',
                          },
                          backgroundColor: 'white',
                        }}
                        data={groupsDropdown}
                        selectedValue={{
                          id: values.group.id || '',
                          label: values.group.groupName || '',
                        }}
                        onSelectItem={(value: OptionType | null) => {
                          if (value) {
                            setFieldValue('group', {
                              id: value.id,
                              groupName: value.label,
                            });
                          } else {
                            setFieldValue('group', { id: '', groupName: '' });
                          }
                        }}
                        disableLabel={true}
                        disabled={
                          !isAdmin && !isSuperSuperAdmin() && !isPointingToUAT()
                        }
                      />
                      {errors.group && touched.group && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.group.id}
                          defaultlabel="Field should not be empty"
                        />
                      )}
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Box className="flex__">
                        {employeeData.signaturePartitionKey === '' ||
                        (employeeData.signaturePartitionKey === null &&
                          signatureDetails.current.employeeSignature === '') ? (
                          <Box>
                            <ButtonComponent
                              className="btn-primary btn-cancel signature_view_Box"
                              labelId="Insurance.Addsignature"
                              defaultLabelId="Add Signature"
                              variantType="outlined"
                              onClick={() => {
                                setOpenEmployeeSignature(
                                  !openEmployeeSignature,
                                );
                                signatureViewType.current = 'add';
                              }}
                            />
                            {errors.signatureValue &&
                              touched.signatureValue && (
                                <MediumTypography
                                  className="errorText-md mt-lg"
                                  labelid={errors.signatureValue}
                                  defaultlabel="Signature is required"
                                />
                              )}
                          </Box>
                        ) : (
                          <ButtonComponent
                            className="btn-primary btn-cancel signature_view_Box"
                            labelId="Insurance.Viewsignature"
                            defaultLabelId="View/update signature"
                            variantType="outlined"
                            LeftIcon={<Editsignature />}
                            onClick={() => {
                              if (employeeData.signaturePartitionKey) {
                                fetchSiganture();
                              } else {
                                setOpenEmployeeSignature(
                                  !openEmployeeSignature,
                                );
                              }
                              signatureViewType.current = 'view';
                            }}
                          />
                        )}

                        {employeeData.signaturePartitionKey && !isAdmin && (
                          <Box className="ml-sm " sx={{ alignSelf: 'center' }}>
                            <Box className="flex__ alignItemCenter">
                              <ButtonComponent
                                className="btn-add-code"
                                variantType="contained"
                                type="submit"
                                labelId="employeeSig.changeSignatureText"
                                defaultLabelId="Change Signature"
                                onClick={() => {
                                  signatureViewType.current = 'change';
                                  setOpenEmployeeSignature(
                                    !openEmployeeSignature,
                                  );
                                }}
                              />
                            </Box>
                          </Box>
                        )}
                        {employeeData.signaturePartitionKey && !isAdmin && (
                          <Box className="ml-sm " sx={{ alignSelf: 'center' }}>
                            <Box className="flex__ alignItemCenter">
                              <ButtonComponent
                                className="btn-add-code"
                                variantType="contained"
                                type="submit"
                                labelId="employeeSig.forgotPinText"
                                defaultLabelId="Reset/Forgot PIN"
                                onClick={() => {
                                  callForgotPinApi();
                                }}
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Grid>

                    <Grid item lg={10} className="mt-sm">
                      <MediumTypography
                        textColor="#2A4241"
                        fontweight={600}
                        labelid="employeeCredentialsText"
                        defaultlabel="Employee Discipline"
                      />
                    </Grid>

                    <Grid item xs={4.5} lg={4.5}>
                      <DropdownComponent
                        names={disciplineList}
                        labelid="EmployeeScreen.DisciplineText"
                        defaultlabelid="Discipline"
                        value={
                          values.disciplineType !== null
                            ? values.disciplineType
                            : ''
                        }
                        handleChange={(e) => {
                          if (e) {
                            setFieldValue('disciplineType', e);
                          }
                        }}
                        Required
                      />
                      {errors.disciplineType && touched.disciplineType && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.disciplineType}
                          defaultlabel="Discipline Type is required"
                        />
                      )}
                    </Grid>
                    <Grid item xs={4.5} lg={4.5}>
                      <Textinput
                        name="credentialLicenceNum"
                        labelid="employee.credentialLicenceNum"
                        defaultlabelid="License Number"
                        Value={
                          values.employeeCredentials.credentialLicenseNumber
                        }
                        inputProps={{
                          maxLength: 20,
                        }}
                        handlechange={(text) => {
                          const validatedValue = licenseNumValidation(text);
                          if (validatedValue !== undefined) {
                            setFieldValue(
                              'employeeCredentials.credentialLicenseNumber',
                              validatedValue,
                            );
                          }
                        }}
                      />
                      {errors.employeeCredentials?.credentialLicenseNumber &&
                        touched.employeeCredentials
                          ?.credentialLicenseNumber && (
                          <MediumTypography
                            className="errorText-md"
                            labelid={
                              errors.employeeCredentials
                                ?.credentialLicenseNumber
                            }
                            defaultlabel="Field should not be empty"
                          />
                        )}
                    </Grid>

                    <Grid item xs={4.5} lg={4.5}>
                      <DatePickerComponent
                        labelid="expDate"
                        defaultlabelid="Expiration Date"
                        value={
                          dayjs(
                            values.employeeCredentials.credentialExpirationDate,
                          ) as Dayjs
                        }
                        handlechange={(date: Dayjs | null) => {
                          if (date === null) {
                            setFieldValue(
                              'employeeCredentials.credentialExpirationDate',
                              null,
                            );
                          } else {
                            setFieldValue(
                              'employeeCredentials.credentialExpirationDate',
                              dayjs(date).format('MM/DD/YYYY'),
                            );
                          }
                        }}
                      />
                      {errors.employeeCredentials?.credentialExpirationDate &&
                        touched.employeeCredentials
                          ?.credentialExpirationDate && (
                          <MediumTypography
                            className="errorText-md"
                            labelid={
                              errors.employeeCredentials
                                ?.credentialExpirationDate
                            }
                            defaultlabel="Field should not be empty"
                          />
                        )}
                    </Grid>
                    <Grid item xs={4.5} lg={4.5}>
                      <TextField
                        className="fileUploadInput"
                        disabled
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <Grid
                              item
                              sx={{
                                display: 'flex',
                                maxWidth: 'auto',
                                position: 'absolute',
                              }}
                            >
                              {fileSelected !== undefined && (
                                <Grid
                                  item
                                  width={'100%'}
                                  className="fileuploadwidth"
                                >
                                  <Tooltip title={fileSelected.name}>
                                    <Chip
                                      label={fileSelected.name}
                                      clickable
                                      sx={{
                                        borderRadius: '4px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        height: '26px',
                                        display: 'block',
                                        position: 'relative',
                                        textDecorationLine: 'underline',
                                      }}
                                      onClick={() => {
                                        if (
                                          PREVIEW_DOCUMENT_EXTENSIONS.includes(
                                            extractFileExtension(
                                              fileUploadedRef.current?.name !==
                                                undefined
                                                ? fileUploadedRef.current?.name
                                                : '',
                                            ).toLowerCase(),
                                          )
                                        ) {
                                          handlePreviewClick();
                                        }
                                      }}
                                    />
                                  </Tooltip>
                                </Grid>
                              )}
                              <Grid item>
                                {fileUploadedRef.current !== undefined && (
                                  <img
                                    src={CloseIcon}
                                    alt="backarrow"
                                    style={{
                                      position: 'relative',
                                      cursor: 'pointer',
                                      top: '10%',
                                    }}
                                    onClick={onChipDelete}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          ),
                          endAdornment: (
                            <InputAdornment
                              className="sdfsdfsdfsdfsdf"
                              position="end"
                              sx={{
                                marginLeft: '0px',
                                position: 'relative',
                                left: '1%',
                              }}
                            >
                              <input
                                ref={inputFileRef}
                                type="file"
                                accept=".pdf"
                                style={{ display: 'none', height: '36px' }}
                                onChange={handleFileChange}
                              />
                              <UploadButton
                                title="uploaddocument"
                                paddingBottom="0px"
                                paddingTop="0px"
                                Icon={<DocIcon />}
                                onClick={browseFiles}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={10}>
                      <MediumTypography
                        textColor="#2A4241"
                        fontweight={600}
                        labelid="addProgramsText"
                        defaultlabel="Programs"
                      />
                    </Grid>

                    {[...Array(numOfPrograms)].map((e, i) => (
                      <Grid
                        container
                        rowSpacing={'24px'}
                        columnSpacing={'40px'}
                        className="pt-md"
                      >
                        <Grid item xs={0.1}></Grid>
                        <Grid item xs={0.1}>
                          <CheckBoxComponent
                            name="defaultProgram"
                            labelid=""
                            defaultlabelid=""
                            fontWeight={400}
                            fontFamily="Lato-Regular"
                            ischecked={
                              values.programs[i].programId === deflauttProgram
                            }
                            CheckHandleChange={() => {
                              const newDefaultProgram =
                                values.programs[i].programId;
                              setDefatultProgram(values.programs[i].programId);

                              const currectAddress =
                                programAddressDetails.filter(
                                  (item) =>
                                    item.programId ===
                                    values.programs[i].programId,
                                );
                              const address: Address = {
                                address1: currectAddress[0].addressLine1 ?? '',
                                address2: currectAddress[0].addressLine2 ?? '',
                                state: currectAddress[0].state ?? '',
                                city: currectAddress[0].city ?? '',
                                zipCode: {
                                  id: 'asd',
                                  label: currectAddress[0].zipCode ?? '',
                                },
                              };
                              formikRef.current?.setValues({
                                ...values,
                                address1: address,
                                defaultProgram: newDefaultProgram,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <SelectComponent
                            name="selectProgram"
                            labelid="selectProgramText"
                            defaultlabelid="Select Program"
                            SelectLabel={values.programs[i].programName}
                            value={values.programs[i].programName}
                            names={programList}
                            disabled={!values.programs[i].editable}
                            handleChange={(value: string) => {
                              let pgs = '';
                              values.programs.forEach((pg) => {
                                if (pg.programName === value) {
                                  pgs += pg;
                                }
                              });
                              if (pgs.length == 0) {
                                setFieldValue(
                                  `programs.${i}.programName`,
                                  value,
                                );
                              }
                            }}
                          />
                          {errors.programs &&
                            errors.programs.length !== 0 &&
                            errors.programs.length > i &&
                            errors.programs[i] &&
                            (errors.programs[i] as FormikErrors<Program>)
                              .programName &&
                            touched.programs &&
                            touched.programs.length > i &&
                            touched.programs[i].programName && (
                              <MediumTypography
                                className="errorText-md"
                                labelid={
                                  (errors.programs[i] as FormikErrors<Program>)
                                    .programName
                                }
                                defaultlabel="Field should not be empty"
                              />
                            )}
                        </Grid>

                        <Grid item xs={2.2}>
                          <DatePickerComponent
                            disabledDate={
                              !isAdmin &&
                              !isSuperSuperAdmin() &&
                              !isPointingToUAT()
                            }
                            labelid="startDateText"
                            defaultlabelid="Start Date"
                            required={true}
                            value={
                              dayjs(
                                values.programs[i].programStartDate,
                              ) as Dayjs
                            }
                            handlechange={(date: Dayjs | null) => {
                              const formattedDate =
                                dayjs(date).format('MM/DD/YYYY');

                              if (date === null) {
                                setFieldValue(
                                  `programs.${i}.programStartDate`,
                                  null,
                                );
                              } else {
                                setFieldValue(
                                  `programs.${i}.programStartDate`,
                                  formattedDate,
                                );
                              }
                              const updatedPrograms = [...values.programs]; // Create a copy of the programs array
                              if (date === null) {
                                updatedPrograms[i] = {
                                  ...updatedPrograms[i],
                                  programStartDate: null,
                                };
                              } else {
                                updatedPrograms[i] = {
                                  ...updatedPrograms[i],
                                  programStartDate:
                                    dayjs(date).format('MM/DD/YYYY'),
                                };
                              }

                              formikRef.current?.setValues({
                                ...values,
                                programs: updatedPrograms,
                              });
                            }}
                          />
                          {errors.programs &&
                            errors.programs.length !== 0 &&
                            errors.programs.length > i &&
                            errors.programs[i] &&
                            (errors.programs[i] as FormikErrors<Program>)
                              .programStartDate &&
                            touched.programs &&
                            touched.programs.length > i &&
                            touched.programs[i].programStartDate && (
                              <MediumTypography
                                className="errorText-md"
                                labelid={(
                                  errors.programs[i] as FormikErrors<Program>
                                ).programStartDate?.toString()}
                                defaultlabel="Field should not be empty"
                              />
                            )}
                        </Grid>

                        <Grid item xs={2.2}>
                          <DatePickerComponent
                            labelid="endDateText"
                            defaultlabelid="End Date"
                            disabledDate={
                              !isAdmin &&
                              !isSuperSuperAdmin() &&
                              !isPointingToUAT()
                            }
                            minDate={dayjs(
                              values.programs[i].programStartDate,
                            ).add(1, 'day')}
                            value={dayjs(values.programs[i].programEndDate)}
                            handlechange={(date: Dayjs | null) => {
                              if (values.programs[i].programStartDate) {
                                if (date === null) {
                                  setFieldValue(
                                    `programs.${i}.programEndDate`,
                                    null,
                                  );
                                } else {
                                  setFieldValue(
                                    `programs.${i}.programEndDate`,
                                    dayjs(date).format('MM/DD/YYYY'),
                                  );
                                }
                              }
                            }}
                          />
                          {errors.programs &&
                            errors.programs.length !== 0 &&
                            errors.programs.length > i &&
                            errors.programs[i] &&
                            (errors.programs[i] as FormikErrors<Program>)
                              .programEndDate &&
                            touched.programs &&
                            touched.programs.length > i &&
                            touched.programs[i].programEndDate && (
                              <MediumTypography
                                className="errorText-md"
                                labelid={(
                                  errors.programs[i] as FormikErrors<Program>
                                ).programEndDate?.toString()}
                                defaultlabel="Field should not be empty"
                              />
                            )}
                        </Grid>

                        {i !== 0 && values.programs[i].editable && (
                          <Grid
                            item
                            xs={2.5}
                            style={{
                              display: 'flex',
                              marginTop: '16px',
                            }}
                          >
                            <DeleteAlertIcon
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                values.programs = values.programs.filter(
                                  (__, index) => index !== i,
                                );
                                setEmployeeData(values);
                                setNumOfPrograms(numOfPrograms - 1);
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    ))}

                    <Grid item xs={9} className="pt-none">
                      {errors.defaultProgram && touched.defaultProgram && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.defaultProgram}
                          defaultlabel="Please select a default program"
                        />
                      )}
                    </Grid>
                    <Grid item xs={9}>
                      <AddressForm
                        address1PlaceholderId="address1Text"
                        address2PlaceholderId="address2Text"
                        zipCodePlaceholderId="Clientpage.Zip"
                        cityPlaceholderId="Clientpage.City"
                        statePlaceholderId="Clientpage.State"
                        headerTitleId="primaryAddressText"
                        value={values.address1}
                        isClient={false}
                        disableForm={false}
                        formRequired={true}
                        formikErrors={errors.address1}
                        formikTouched={touched.address1}
                        handleAddressUpdate={(newAddress) => {
                          formikRef.current?.setValues({
                            ...values,
                            address1: newAddress,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={9} className="pt-sm">
                      <CheckBoxComponent
                        name="secondaryAddressFlag"
                        labelid="secondaryAddressText"
                        defaultlabelid="Secondary Address"
                        ischecked={values.secondaryAddressRequired}
                        CheckHandleChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          setFieldValue(
                            'secondaryAddressRequired',
                            event.target.checked,
                          );
                        }}
                      />
                      {values.secondaryAddressRequired && (
                        <Box sx={{ marginTop: '16px' }}>
                          <AddressForm
                            address1PlaceholderId="address1Text"
                            address2PlaceholderId="address2Text"
                            zipCodePlaceholderId="Clientpage.Zip"
                            cityPlaceholderId="Clientpage.City"
                            statePlaceholderId="Clientpage.State"
                            value={values.address2}
                            isClient={false}
                            disableForm={false}
                            formRequired={true}
                            formikErrors={errors.address2}
                            formikTouched={touched.address2}
                            handleAddressUpdate={(newAddress) => {
                              formikRef.current?.setValues({
                                ...values,
                                address2: newAddress,
                              });
                            }}
                          />
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={10} className="pt-sm">
                      <MediumTypography
                        textColor="#2A4241"
                        fontweight={600}
                        labelid="emergencyContactText"
                        defaultlabel="Emergency contact"
                      />
                    </Grid>

                    <Grid item xs={4.5}>
                      <Textinput
                        name="firstName"
                        labelid="AddNewReferralDetails.First"
                        defaultlabelid="First Name"
                        Value={values.emergencyContact.firstName}
                        inputProps={{ maxLength: 50 }}
                        handlechange={(text) => {
                          setFieldValue(
                            'emergencyContact.firstName',
                            nameValidation(text),
                          );
                        }}
                      />
                      {errors.emergencyContact &&
                        errors.emergencyContact.firstName &&
                        touched.emergencyContact &&
                        touched.emergencyContact.firstName && (
                          <MediumTypography
                            className="errorText-md"
                            labelid={errors.emergencyContact.firstName}
                            defaultlabel="Field should not be empty"
                          />
                        )}
                    </Grid>
                    <Grid item xs={4.5}>
                      <Textinput
                        name="lastName"
                        labelid="AddNewReferralDetails.Last"
                        defaultlabelid="Last Name"
                        Value={values.emergencyContact.lastName}
                        inputProps={{ maxLength: 50 }}
                        handlechange={(text) => {
                          setFieldValue(
                            'emergencyContact.lastName',
                            nameValidation(text),
                          );
                        }}
                      />
                      {errors.emergencyContact &&
                        errors.emergencyContact.lastName &&
                        touched.emergencyContact &&
                        touched.emergencyContact.lastName && (
                          <MediumTypography
                            className="errorText-md"
                            labelid={errors.emergencyContact.lastName}
                            defaultlabel="Field should not be empty"
                          />
                        )}
                    </Grid>

                    <Grid item xs={4.5}>
                      <Textinput
                        name="phoneNumber"
                        labelid="Contacts.phone"
                        defaultlabelid="Phone number"
                        Value={values.emergencyContact.phoneNumber}
                        handlechange={(phoneNumber) => {
                          setFieldValue(
                            'emergencyContact.phoneNumber',
                            formatPhoneNumber(phoneNumber),
                          );
                        }}
                      />
                      {errors.emergencyContact &&
                        errors.emergencyContact.phoneNumber &&
                        touched.emergencyContact &&
                        touched.emergencyContact.phoneNumber && (
                          <MediumTypography
                            className="errorText-md"
                            labelid={errors.emergencyContact.phoneNumber}
                            defaultlabel="Field should not be empty"
                          />
                        )}
                    </Grid>
                    <Grid item xs={4.5}>
                      <Textinput
                        name="email"
                        labelid="Contacts.email"
                        defaultlabelid="Email"
                        type={'email'}
                        Value={values.emergencyContact.email}
                        handlechange={(value: string) => {
                          handleEmailChange(value);
                          setFieldValue('emergencyContact.email', value);
                        }}
                        inputProps={{
                          maxLength: 320,
                        }}
                      />
                      {errorEmail !== '' && (
                        <MediumTypography
                          sxProps={{ color: 'red' }}
                          labelid="parent.emailMessage"
                          defaultlabel="Invalid Email"
                        />
                      )}
                      {errors.emergencyContact &&
                        errors.emergencyContact.email &&
                        touched.emergencyContact &&
                        touched.emergencyContact.email && (
                          <MediumTypography
                            className="errorText-md"
                            labelid={errors.emergencyContact.email}
                            defaultlabel="Field should not be empty"
                          />
                        )}
                    </Grid>
                    <Grid item xs={9}>
                      <AddressForm
                        address1PlaceholderId="address1Text"
                        address2PlaceholderId="address2Text"
                        zipCodePlaceholderId="Clientpage.Zip"
                        cityPlaceholderId="Clientpage.City"
                        statePlaceholderId="Clientpage.State"
                        value={values.emergencyContact.address}
                        isClient={false}
                        disableForm={false}
                        formRequired={false}
                        formikErrors={errors.emergencyContact?.address}
                        formikTouched={touched.emergencyContact?.address}
                        handleAddressUpdate={(newAddress) => {
                          formikRef.current?.setValues({
                            ...values,
                            emergencyContact: {
                              ...values.emergencyContact,
                              address: newAddress,
                            },
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Card>

                <Box>
                  <Grid
                    className="flex__ justifyContent-FlexEnd pt-md pb-xlg "
                    container
                    direction="row"
                    alignItems="right"
                  >
                    <Grid item>
                      <ButtonComponent
                        className="btn-primary btn-cancel btn_width_90px mr-md"
                        variantType="outlined"
                        labelId="cancelText"
                        defaultLabelId="Cancel"
                        onClick={() => {
                          if (_.isEqual(originalEmployeeData, values)) {
                            navigate(
                              screenFrom === 'employeeList'
                                ? EMPLOYEES_ROUTE_NAME
                                : PROFILE_ROUTE_NAME,
                            );
                          } else {
                            setOpenModal(true);
                          }
                        }}
                      />

                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        labelId="Insurance.update"
                        defaultLabelId="Update"
                        onClick={() => {
                          handleSubmit();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
        <ModalPopup
          open={openModal}
          description="formUnsavedChangesMessage"
          onCancel={() => setOpenModal(false)}
          onOk={() => {
            clearForm();
          }}
          labelId1="Clientpage.cancelbtn"
          negativeActionLabel="Cancel"
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="Ok"
        />
      </Box>
    </Box>
  ) : (
    <div></div>
  );
};

export default EditEmployeeScreen;
