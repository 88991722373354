import { Box, Grid } from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import Textinput from '../../components/formlib/Textinput';
import { ReactComponent as DeleteAlertIcon } from '../../assets/images/deleteAlertIcon.svg';
import {
  FieldArray,
  FieldArrayRenderProps,
  FormikErrors,
  FormikTouched,
} from 'formik';
import { FC } from 'react';
import { Address } from '../employees/types';
import AddressForm from '../../components/formlib/AddressForm';
import {
  formatPhoneNumber,
  nameValidation,
} from '../../utils/formValidationUtil';
import SelectComponent from '../../components/formlib/SelectComponent';
import { RelationShips } from '../Dropdown';
import TextWithIcon from '../../components/formlib/TextWithIcon';
import CustomCheckBox from '../../components/formlib/CustomCheckBox';

export interface ReferralParent {
  firstName: string;
  lastName: string;
  email: string;
  homePhoneNumber: string;
  relationShipToClient: string;
  address: Address;
  isAddressSameAsClientAddress: boolean;
}

interface ReferralParentPropType {
  headerTitleId?: string;
  defaultHeaderTitle?: string;
  formikErrors?: FormikErrors<ReferralParent[]>;
  formikTouched?: FormikTouched<ReferralParent[]>;
  onDeleteClick: (index: number) => void;
  clientAddress: Address;
}

export const ReferralParentComponent: FC<ReferralParentPropType> = ({
  headerTitleId,
  defaultHeaderTitle,
  formikErrors,
  formikTouched,
  onDeleteClick,
  clientAddress,
}) => {
  return (
    <FieldArray
      name="parents"
      render={(arrayHelpers: FieldArrayRenderProps) => (
        <Box>
          {arrayHelpers.form.values.parents &&
            arrayHelpers.form.values.parents.map(
              (parent: ReferralParent, index: number) => (
                <Grid
                  container
                  rowSpacing={'24px'}
                  columnSpacing={'40px'}
                  key={index}
                >
                  <Grid item xs={12}>
                    <MediumTypography
                      labelid={headerTitleId}
                      defaultlabel={defaultHeaderTitle}
                      sxProps={{
                        color: '#2A4241',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        marginTop: index > 0 ? '24px' : '0px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={4.5} lg={4.5}>
                    <Textinput
                      name={`parents.${index}.firstName`}
                      Value={parent.firstName}
                      labelid="AddNewReferralDetails.ParentFirstName"
                      defaultlabelid="Parent First Name"
                      Required={true}
                      inputProps={{
                        maxLength: 40,
                      }}
                      handlechange={(newValue: string) => {
                        const validatedValue = nameValidation(newValue);
                        arrayHelpers.form.setFieldValue(
                          `parents.${index}.firstName`,
                          validatedValue,
                        );
                      }}
                    />
                    {formikErrors &&
                      formikErrors[index] &&
                      formikErrors[index]?.firstName &&
                      formikTouched &&
                      formikTouched[index] &&
                      formikTouched[index]?.firstName && (
                        <MediumTypography
                          labelid={formikErrors[index]?.firstName}
                          defaultlabel="Parent First Name is Required"
                          className="errorText-md"
                        />
                      )}
                  </Grid>
                  <Grid item xs={4.5} lg={4.5}>
                    <Textinput
                      name={`parents.${index}.lastName`}
                      Value={parent.lastName}
                      labelid="AddNewReferralDetails.ParentLastName"
                      defaultlabelid="Parent Last Name"
                      Required={true}
                      inputProps={{
                        maxLength: 40,
                      }}
                      handlechange={(newValue: string) => {
                        const validatedValue = nameValidation(newValue);
                        arrayHelpers.form.setFieldValue(
                          `parents.${index}.lastName`,
                          validatedValue,
                        );
                      }}
                    />
                    {formikErrors &&
                      formikErrors[index] &&
                      formikErrors[index]?.lastName &&
                      formikTouched &&
                      formikTouched[index] &&
                      formikTouched[index]?.lastName && (
                        <MediumTypography
                          labelid={formikErrors[index]?.lastName}
                          defaultlabel="Parent Last Name is Required"
                          className="errorText-md"
                        />
                      )}
                  </Grid>
                  <Grid item xs={4.5} lg={4.5}>
                    <Textinput
                      name={`parents.${index}.email`}
                      Value={parent.email}
                      labelid="AddNewReferralDetails.Email"
                      defaultlabelid="Parent Email"
                      inputProps={{
                        maxLength: 50,
                      }}
                      handlechange={(newValue: string) => {
                        arrayHelpers.form.setFieldValue(
                          `parents.${index}.email`,
                          newValue,
                        );
                      }}
                    />
                    {formikErrors &&
                      formikErrors[index] &&
                      formikErrors[index]?.email &&
                      formikTouched &&
                      formikTouched[index] &&
                      formikTouched[index]?.email && (
                        <MediumTypography
                          labelid={formikErrors[index]?.email}
                          defaultlabel="Invalid Email Address"
                          className="errorText-md"
                        />
                      )}
                  </Grid>
                  <Grid item xs={4.5} lg={4.5}>
                    <Textinput
                      name={`parents.${index}.homePhoneNumber`}
                      Value={parent.homePhoneNumber}
                      labelid="parentGuardian.homePhoneNumber"
                      defaultlabelid="Primary Phone Number"
                      Required={true}
                      handlechange={(newValue: string) => {
                        const validatedValue = formatPhoneNumber(newValue);
                        arrayHelpers.form.setFieldValue(
                          `parents.${index}.homePhoneNumber`,
                          validatedValue,
                        );
                      }}
                    />
                    {formikErrors &&
                      formikErrors[index] &&
                      formikErrors[index]?.homePhoneNumber &&
                      formikTouched &&
                      formikTouched[index] &&
                      formikTouched[index]?.homePhoneNumber && (
                        <MediumTypography
                          labelid={formikErrors[index]?.homePhoneNumber}
                          className="errorText-md"
                          defaultlabel="Primary Phone Number is required"
                        />
                      )}
                  </Grid>
                  <Grid item xs={4.5} sx={{ marginTop: '0.5px' }}>
                    <SelectComponent
                      name={`parents.${index}.relationShipToClient`}
                      labelid="parentGuardian.relationShipToChild"
                      defaultlabelid="Relationship to Client"
                      handleChange={(value: string) => {
                        arrayHelpers.form.setFieldValue(
                          `parents.${index}.relationShipToClient`,
                          value,
                        );
                      }}
                      value={parent.relationShipToClient}
                      names={RelationShips}
                      Required={true}
                    />
                    {formikErrors &&
                      formikErrors[index] &&
                      formikErrors[index]?.relationShipToClient &&
                      formikTouched &&
                      formikTouched[index] &&
                      formikTouched[index]?.relationShipToClient && (
                        <MediumTypography
                          labelid={formikErrors[index]?.relationShipToClient}
                          className="errorText-md"
                          defaultlabel="Relationship to Client is required"
                        />
                      )}
                    <Box component="div" className="flex__">
                      <CustomCheckBox
                        disable={
                          !clientAddress.address1 ||
                          !clientAddress.zipCode ||
                          !clientAddress.city ||
                          !clientAddress.state
                        }
                        value={parent.isAddressSameAsClientAddress}
                        style={{ marginTop: '24px' }}
                        onCheckBoxClick={(value) => {
                          arrayHelpers.form.setFieldValue(
                            `parents.${index}.isAddressSameAsClientAddress`,
                            !parent.isAddressSameAsClientAddress,
                          );
                          if (value) {
                            arrayHelpers.form.setFieldValue(
                              `parents.${index}.address`,
                              clientAddress,
                            );
                          } else {
                            arrayHelpers.form.setFieldValue(
                              `parents.${index}.address`,
                              {
                                address1: '',
                                address2: '',
                                city: '',
                                state: '',
                                zipCode: { id: '', label: '' },
                              },
                            );
                          }
                        }}
                      />
                      <MediumTypography
                        labelid={'parentGuardian.sameAsClientAddress'}
                        defaultlabel={'Same as client address'}
                        sxProps={{
                          color: '#2A4241',
                          fontWeight: 'bold',
                          fontSize: '16px',
                          marginTop: '30px',
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid container item xs={9}>
                    <AddressForm
                      address1PlaceholderId="address1Text"
                      address2PlaceholderId="address2Text"
                      zipCodePlaceholderId="Clientpage.Zip"
                      cityPlaceholderId="Clientpage.City"
                      statePlaceholderId="Clientpage.State"
                      value={
                        parent.isAddressSameAsClientAddress
                          ? clientAddress
                          : parent.address
                      }
                      isClient={false}
                      disableForm={parent.isAddressSameAsClientAddress}
                      formRequired={true}
                      formikErrors={
                        formikErrors && formikErrors[index]?.address
                      }
                      formikTouched={
                        formikTouched && formikTouched[index]?.address
                      }
                      handleAddressUpdate={(newAddress) => {
                        arrayHelpers.form.setFieldValue(
                          `parents.${index}.address.address1`,
                          newAddress.address1,
                        );
                        arrayHelpers.form.setFieldValue(
                          `parents.${index}.address.address2`,
                          newAddress.address2,
                        );
                        arrayHelpers.form.setFieldValue(
                          `parents.${index}.address.zipCode`,
                          newAddress.zipCode,
                        );
                        arrayHelpers.form.setFieldValue(
                          `parents.${index}.address.city`,
                          newAddress.city,
                        );
                        arrayHelpers.form.setFieldValue(
                          `parents.${index}.address.state`,
                          newAddress.state,
                        );
                      }}
                    />
                    {index !== 0 && (
                      <Box
                        sx={{
                          marginTop: '16px',
                          marginBottom: '16px',
                          cursor: 'pointer',
                        }}
                      >
                        <TextWithIcon
                          LeftIcon={<DeleteAlertIcon />}
                          labelId={'deleteText'}
                          defaultLabel="Delete"
                          sxProps={{
                            paddingRight: '4px',
                            color: '#EB4C60',
                            textTransform: 'none',
                          }}
                          onClick={() => {
                            onDeleteClick(index);
                          }}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              ),
            )}
        </Box>
      )}
    />
  );
};
