import React, { FC, useEffect, useRef, useState } from 'react';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';
import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import { CommunicationLog, Order, ResponseType } from '../../utils/type';
import {
  deleteCommunicationLog,
  getCommunicationLogs,
} from '../../services/configApi/communicationLog/communication';
import CommunicationLogModel from './CommunicationLogModel';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import MediumTypography from '../../components/formlib/MediumTypography';
import ModalPopup from '../../components/formlib/ModalPopup';
import moment from 'moment';
import { LOCAL_DATE_TIME_FORMAT, PAGE_SIZE } from '../../services/Constant';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import AppPagination from '../../components/shared/AppPagination';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { getResponseDB } from '../../services/storageDB';

interface Data {
  contactedBy: string;
  contact: string;
  communicationType: string;
  createdOn: string;
  reasonForContact: string;
  notes: string;
  actions: string;
}

const ellipsisCellSX = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '150px',
};

const headCells: HeadCell<Data>[] = [
  {
    id: 'contactedBy',
    numeric: false,
    labelId: 'CommunicationLogPage.contactedBy',
    defaultLabelId: 'Contacted by',
    requiredSorting: true,
  },
  {
    id: 'contact',
    numeric: false,
    labelId: 'CommunicationLogModal.contact',
    defaultLabelId: 'Contact',
    requiredSorting: true,
  },
  {
    id: 'communicationType',
    numeric: false,
    labelId: 'CommunicationLogModal.communicationType',
    defaultLabelId: 'Communication Type',
    requiredSorting: true,
  },
  {
    id: 'createdOn',
    numeric: false,
    labelId: 'CommunicationLogModal.dateTime',
    defaultLabelId: 'Date & Time',
    requiredSorting: true,
  },
  {
    id: 'reasonForContact',
    numeric: false,
    labelId: 'CommunicationLogPage.reasonForContact',
    defaultLabelId: 'Reason for Contact',
    requiredSorting: false,
  },
  {
    id: 'notes',
    numeric: false,
    labelId: 'CommunicationLogModal.note',
    defaultLabelId: 'Notes',
    requiredSorting: false,
  },
];

const CommunicationLogTableView: FC<{ reload: boolean }> = ({ reload }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [selectedLog, setSelectedLog] = useState<CommunicationLog | null>(null);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>('createdOn');
  const [logs, setLogs] = useState<CommunicationLog[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [paginationCount, setPaginationCount] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');
  const [toastrId, setToastrId] = useState<string>();
  const [offline, setOffline] = useState<boolean>(false);
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);
  const [headCell, setHeadCell] = useState<HeadCell<Data>[]>(headCells);

  useEffect(() => {
    async function checkOffline() {
      const programIds = (await getResponseDB('programIds')) as string[];
      const selectedProgram: string | null =
        localStorage.getItem('selectedProgram');
      let programId: string;
      if (selectedProgram !== null) {
        programId = JSON.parse(selectedProgram).id;
        if (programIds?.includes(programId)) {
          setOffline(true);
        } else {
          setOffline(false);
          setHeadCell((prev) => {
            return [
              ...prev,
              {
                id: 'actions',
                numeric: false,
                labelId: 'actions',
                defaultLabelId: 'Actions',
                requiredSorting: false,
              },
            ];
          });
        }
      }
    }

    checkOffline();

    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
    return () => {
      setHeadCell(headCells);
    };
  }, []);
  const parentRef = useRef<HTMLDivElement>(null);

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const displayModelWithDetails = (log: CommunicationLog) => {
    setSelectedLog(log);
    setShowModal(true);
  };

  useEffect(() => {
    const clientId = localStorage.getItem('ClientId');
    if (clientId === null) {
      return;
    }
    setShowLoader(true);
    getCommunicationLogs(clientId, pageNumber, PAGE_SIZE, orderBy, order)
      .then((response) => {
        response.communicationLogs.forEach((log) => {
          if (log.createdOn) {
            log.createdOn = moment(log.createdOn, 'YYYY-MM-DD HH:mm:ss')
              .utc(true)
              .local()
              .format(LOCAL_DATE_TIME_FORMAT);
          }
        });

        setLogs(response.communicationLogs);
        setPaginationCount(response.totalCount);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setErrorMessage(apiError.message);
        } else {
          setErrorMessage(error);
          setToastrId('Communication.tableError');
        }
      });
  }, [order, orderBy, pageNumber, reload]);

  const deleteLog = () => {
    setShowAlertDialog(false);
    setShowLoader(true);
    deleteCommunicationLog(selectedLog?.communicationLogId)
      .then(() => {
        const filteredLogs = logs.filter(
          (log) => log.communicationLogId !== selectedLog?.communicationLogId,
        );
        if (filteredLogs.length === 0 && pageNumber > 0) {
          setPageNumber(pageNumber - 1);
        }
        setLogs(filteredLogs);
        setSelectedLog(null);
        setShowLoader(false);
        setSuccessOrError('success');
      })
      .catch((error) => {
        setSuccessOrError('error');
        setShowLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setErrorMessage(apiError.message);
        } else {
          setErrorMessage('Failed to delete communication log');
          setToastrId('CommunicationLogPage.tableDeleteError');
        }
      });
  };

  return (
    <>
      {selectedLog && (
        <CommunicationLogModel
          data={selectedLog}
          showModal={showModal}
          onModalClose={() => {
            setShowModal(false);
            setSelectedLog(null);
          }}
        />
      )}

      {errorMessage && (
        <SnackBarComponent
          open={errorMessage !== undefined}
          handleClose={() => {
            setErrorMessage(undefined);
          }}
          successOrError={successOrError}
          labelId={toastrId}
          defaultMessageId={errorMessage}
        />
      )}

      {showAlertDialog && (
        <ModalPopup
          open={showAlertDialog}
          onCancel={() => {
            setShowAlertDialog(false);
            setSelectedLog(null);
          }}
          description="CommunicationLogTableView.deleteDescription"
          onOk={() => deleteLog()}
          labelId1="Clientpage.cancelbtn"
          negativeActionLabel="cancelText"
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="deleteText"
        />
      )}

      <Box
        sx={{
          width: '100%',
          opacity: showLoader ? 0.5 : 1,
          pointerEvents: showLoader ? 'none' : 'auto',
        }}
      >
        <Box
          ref={parentRef}
          style={{
            pointerEvents: isScreenLocked ? 'none' : 'auto',
            opacity: isScreenLocked ? '0.5' : '1 ',
          }}
          role="presentation"
          onKeyDownCapture={(e) => {
            if (isScreenLocked) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          onFocus={() => {
            if (isScreenLocked) {
              if (parentRef.current) parentRef.current.focus();
            }
          }}
        >
          <TableContainer>
            {showLoader && (
              <Box
                sx={{
                  position: 'absolute',
                  left: '55%',
                  marginTop: '5%',
                }}
              >
                <CircularProgress sx={{ color: '#00938E' }} />
              </Box>
            )}
            <>
              <Table>
                <TableHeader
                  className="listDataTableHead "
                  checkBoxRequired={false}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  headerNames={headCell}
                />
                <TableBody className="tableRowcss">
                  {logs.map((log, index) => {
                    return (
                      <TableRow
                        hover
                        onClick={() => {
                          displayModelWithDetails(log);
                        }}
                        key={log.communicationLogId}
                        sx={{
                          cursor: 'pointer',
                          '&:nth-of-type(odd)': { backgroundColor: '#ECF9F8' },
                          backgroundColor:
                            index % 2 === 0 ? '#ECF9F8' : '#ffffff',
                        }}
                      >
                        <TooltipTableCell
                          value={log.contactedBy}
                          visible={true}
                          textSxProps={ellipsisCellSX}
                        />
                        <TooltipTableCell
                          value={log.contact}
                          visible={true}
                          textSxProps={ellipsisCellSX}
                        />
                        <TooltipTableCell
                          value={log.communicationType}
                          visible={false}
                        />
                        <TooltipTableCell
                          value={log.createdOn}
                          visible={false}
                          textSxProps={ellipsisCellSX}
                        />
                        <TooltipTableCell
                          value={log.reasonForContact}
                          visible={true}
                          textSxProps={ellipsisCellSX}
                        />
                        <Tooltip
                          className="cursorPointer"
                          id="tool-tip"
                          arrow
                          followCursor
                          title="Click to view more"
                          placement="top"
                        >
                          <TableCell className="cursorPointer ellipsis">
                            {log.notes ?? '-'}
                          </TableCell>
                        </Tooltip>
                        {!offline && (
                          <TableCell
                            onClick={(event) => {
                              event.stopPropagation();
                              setSelectedLog(log);
                              if (!offline) setShowAlertDialog(true);
                            }}
                            sx={{ paddingRight: '24px' }}
                          >
                            <DeleteIcon
                              style={{
                                width: '100%',
                                cursor: offline ? 'not-allowed' : 'pointer',
                              }}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>

              {pageNumber === 0 && logs.length === 0 && !showLoader && (
                <Box sx={{ width: '100%' }}>
                  <MediumTypography
                    textstyle={'center'}
                    labelid="CommunicationLogTableView.emptyMessage"
                    defaultlabel="No logs found"
                    paddingstyle="16px"
                  />
                </Box>
              )}
            </>
          </TableContainer>
        </Box>

        {paginationCount > 10 && (
          <Grid
            container
            spacing={1}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Grid item>
              <AppPagination
                pageNumber={pageNumber}
                paginationCount={paginationCount}
                handleChangePage={(_event, newPage) => {
                  if (newPage - 1 !== pageNumber) {
                    setPageNumber(newPage - 1);
                  }
                }}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default CommunicationLogTableView;
