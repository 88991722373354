import {
  AlertColor,
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TitleText from '../../components/formlib/TitleText';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ProgramPanel from '../../components/forms/ProgramPanel';
import { v4 as uuidv4 } from 'uuid';
import {
  CheckboxType,
  DayCareQuestions,
  EvaluationQuestions,
  LEAProblems,
  LEAQuestion,
  LEAReferralReasons,
  LEAReferralType,
  headCells,
} from './type';
import MediumTypography from '../../components/formlib/MediumTypography';
import CustomRadioButton, {
  RadioButtonData,
} from '../../components/formlib/CustomRadioButton';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import Textinput from '../../components/formlib/Textinput';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import CommentTextbox from '../../components/formlib/CommentTextbox';
import { useIntl } from 'react-intl';
import KeyValueComponent, {
  DataJsonType,
} from '../../components/forms/KeyValueComponent';
import CustomCheckBox from '../../components/formlib/CustomCheckBox';
import { ReactComponent as FileIconGray } from '../../assets/images/fileGrayedOut.svg';
import QuestionnaireModel from '../../components/formlib/QuestionnaireModel';
import QuestionItem from './QuestionItem';
import dayjs, { Dayjs } from 'dayjs';
import { formatPhoneNumber } from '../../utils/formValidationUtil';
import {
  getLEAPreschoolInfo,
  updateLEAPreschoolInfo,
} from '../../services/referralClients/leaPreSchoolService';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { Client } from '../eligibility/types';
import { TableHeader } from '../../components/formlib/TableHeader';
import { ServiceDeliveryPlanPropsData } from '../ifsp/ServiceDeliveryPlan';
import EIServiceTableRow from '../ifsp/EIServiceTableRow';
import { ReactComponent as Editsignature } from '../../assets/images/editsignature.svg';
import SignatureComponent from '../../components/formlib/modal/SignatureComponent';
import ModalPopup from '../../components/formlib/ModalPopup';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';
import { OptionType } from '../../utils/type';
import SignatureName from '../../components/formlib/modal/SignatureName';
import AppTimePicker from '../../components/formlib/AppTimePicker';
import {
  datePickerMinDateMaxDateValidate,
  getTime,
} from '../../utils/dateUtil';
import moment from 'moment';
import { debounce } from 'lodash';
import { LEA_REFERRAL_CONSENT } from '../../routes/Routing';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import { checkPermissionForFeature } from '../../utils/checkPermission';

const validationSchema = yup.object().shape({
  referralDate: yup
    .date()
    .nullable()
    .required('AddNewReferralDetails.referralDate.Validation'),
  dischargeReason: yup
    .string()
    .required('dischargeReasonRequiredText')
    .nullable(),
  daysThirdBirth: yup.number().nullable(),
  referralReason: yup
    .string()
    .when('daysThirdBirth', (daysThirdBirth: number, schema) => {
      if (daysThirdBirth < 90 || daysThirdBirth > 270) {
        return schema.required(
          'AddNewReferralDetails.referralReason.Validation',
        );
      }
      return schema;
    })
    .nullable(),
  referralReasonOther: yup
    .string()
    .when('referralReason', {
      is: 'Other',
      then: yup
        .string()
        .required('AddNewReferralDetails.referralReasonOther.Validation'),
    })
    .nullable(),
  referringTo: yup.string().nullable().required('referringToText.Validation'),
  currentIssues: yup.string().required('presentIssuesText.Validation'),
  currentOtherIssues: yup
    .string()
    .when('currentIssues', {
      is: 'other',
      then: yup.string().required('presentIssuesText.Validation'),
    })
    .nullable(),
  evaluationFrom: yup.string().default('BDI 2'),
  lastEvaluationDate: yup
    .date()
    .when('evaluationFrom', {
      is: 'Other Assessment',
      then: yup.date().required('lastEvaluationDateText.Validation').nullable(),
    })
    .nullable(),
  attachBDI2Results: yup.bool().nullable(),
  childIsInDayCare: yup.boolean().nullable(),
  dayCareName: yup
    .string()
    .when('childIsInDayCare', {
      is: true,
      then: yup.string().required('dayCareNameText.Validation'),
    })
    .nullable(),
  dayCarePhoneNumber: yup
    .string()
    .when('childIsInDayCare', {
      is: true,
      then: yup
        .string()
        .min(12, 'PhoneNumber.Validation')
        .required('dayCarePhoneText.Validation'),
    })
    .nullable(),
  childSchedule: yup
    .string()
    .when('childIsInDayCare', {
      is: true,
      then: yup.string().required('childScheduleText.Validation'),
    })
    .nullable(),
  dayCareAddress: yup
    .string()
    .when('childIsInDayCare', {
      is: true,
      then: yup.string().required('addressRequiredValidation'),
    })
    .nullable(),
  otherReleventInfo: yup.string().required('otherReleventInfoText.Validation'),
  reasonForReferral: yup.object().shape({
    leaEvaluationRequested: yup.string().nullable(),
    adaptive: yup.string().nullable(),
    communication: yup.string().nullable(),
    personal: yup.string().nullable(),
    motor: yup.string().nullable(),
    cognitive: yup.string().nullable(),
    other: yup.string().nullable(),
  }),
  tpc: yup.object().shape({
    date: yup.string().nullable(),
    time: yup
      .string()
      .when('date', (date: string, schema) => {
        if (date && date !== null) {
          return schema.required('timeRequiredValidation');
        }
        return schema;
      })
      .nullable(),
    location: yup.string().nullable(),
  }),
  questionnaire: yup
    .array()
    .of(
      yup.object().shape({
        question: yup.string(),
        answer: yup.string().when('question', (question: string, schema) => {
          if (question && question.length > 0) {
            return schema.required('answerRequiredValidation');
          }
          return schema;
        }),
      }),
    )
    .nullable(),
  employeeSignature: yup
    .object()
    .shape({
      signatureValue: yup.string(),
      partitionkey: yup.string(),
      rowkey: yup.string(),
      name: yup.string().nullable(),
      date: yup.string().nullable(),
    })
    .nullable(),
  parentSignature: yup
    .object()
    .shape({
      signatureValue: yup.string(),
      partitionkey: yup.string(),
      rowkey: yup.string().nullable(),
      name: yup.string().nullable(),
      date: yup.string().nullable(),
    })
    .nullable(),
});

const validateForm = (value: LEAReferralType) => {
  const errors: Partial<LEAReferralType> = {};

  if (datePickerMinDateMaxDateValidate(value.referralDate)) {
    errors.referralDate = 'datePickerMinDateMaxDateValidate';
  }
  if (datePickerMinDateMaxDateValidate(value.lastEvaluationDate)) {
    errors.lastEvaluationDate = 'datePickerMinDateMaxDateValidate';
  }
  if (datePickerMinDateMaxDateValidate(value.tpc.date)) {
    errors.tpc = {
      date: 'datePickerMinDateMaxDateValidate',
      location: errors.tpc?.location || '',
      time: errors.tpc?.time || '',
    };
  }
  return errors;
};

const firstValues = {
  otherReleventInfo: '',
  referralDate: '',
  dischargeReason: '',
  daysThirdBirth: 0,
  referralReason: '',
  referralReasonOther: '',
  referringTo: '',
  currentIssues: '',
  currentOtherIssues: '',
  lastEvaluationDate: '',
  evaluationFrom: '',
  attachBDI2Results: false,
  childIsInDayCare: false,
  dayCareName: '',
  dayCarePhoneNumber: '',
  dayCareAddress: '',
  childSchedule: '',
  otherRelevantInfo: '',
  consentId: '',
  leaConsentSigned: false,
  earlyInterventionService: [],
  reasonForReferral: {
    leaEvaluationRequested: '',
    adaptive: '',
    communication: '',
    personal: '',
    motor: '',
    cognitive: '',
    other: '',
  },
  questionnaire: [] as LEAQuestion[],
  employeeSignature: {
    rowkey: '',
    partitionkey: '',
    signatureValue: '',
    name: '',
    date: '',
  },
  parentSignature: {
    rowkey: '',
    partitionkey: '',
    signatureValue: '',
    name: '',
    date: '',
  },
  tpc: {
    date: '',
    time: '',
    location: '',
  },
  createdOn: '',
  modifiedOn: '',
  createdBy: '',
  modifiedBy: '',
};

const LEAPreschoolForm = () => {
  const navigate = useNavigate();
  const rctl = useIntl();
  const [eiService, setEiService] = useState<OptionType[]>([]);
  const [locationOfService, setLocationOfService] = useState<OptionType[]>([]);
  const [period, setPeriod] = useState<OptionType[]>([]);
  const [intensity, setIntensity] = useState<OptionType[]>([]);
  const [providedBy, setProvidedBy] = useState<OptionType[]>([]);
  const [lookUpReasons, setLookUpReasons] = useState<OptionType[]>([]);
  const [showQuestionModel, setShowQuestionModel] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [reasons] = useState<RadioButtonData[]>(LEAReferralReasons);
  const [leaProblems, setLeaProblems] = useState<CheckboxType[]>(
    JSON.parse(JSON.stringify(LEAProblems)),
  );
  const formRef = useRef<FormikProps<LEAReferralType>>(null);
  const [leaReferral, setLeaReferral] = useState<LEAReferralType>(firstValues);
  const [toastrId, setToastrId] = useState<string>();
  const [parentSignatureClicked, setParentSignatureClicked] =
    useState<boolean>(false);
  const [employeeSignatureClicked, setEmployeeSignatureClicked] =
    useState<boolean>(false);
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const { lookups } = useContext(LookUpContext) as LookUpContextType;
  const [referralReasonsSelected, setReferralReasonsSelected] = useState<
    string[]
  >([]);
  const [
    initialReferralReasonsSelectedValue,
    setInitialReferralReasonsSelectedValue,
  ] = useState<string[]>([]);
  const [dischargeReasons, setDischargeReasons] = useState<OptionType[]>([]);
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);
  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (lookups === null) {
      return;
    }
    setDischargeReasons(() => {
      return lookups.referralDischargeReason.map((item) => {
        return {
          label: item.description,
          id: item.code,
        };
      });
    });
    setEiService(() => {
      return lookups.eiService?.map((e) => {
        return {
          label: e.description,
          id: e.code,
        };
      });
    });

    setLocationOfService(() => {
      return lookups.locationOfService?.map((l) => {
        return {
          label: l.description,
          id: l.code,
        };
      });
    });

    setPeriod(() => {
      return lookups.period?.map((p) => {
        return {
          label: p.description,
          id: p.code,
        };
      });
    });

    setProvidedBy(() => {
      return lookups.discipline?.map((p) => {
        return {
          label: p.description,
          id: p.code,
        };
      });
    });

    setIntensity(() => {
      return lookups.intensity?.map((i) => {
        return {
          label: i.description,
          id: i.code,
        };
      });
    });
    setLookUpReasons(() => {
      return lookups.reason?.map((e) => {
        return {
          label: e.description,
          id: e.code,
        };
      });
    });
  }, [lookups]);

  useEffect(() => {
    const clientId = localStorage.getItem('ClientId');
    const userId = localStorage.getItem('userId');
    if (clientId === null || userId === null) {
      return;
    }
    toggleLoader(true);
    getLEAPreschoolInfo(userId, clientId)
      .then((data) => {
        toggleLoader(false);
        if (data.parentSignature === null) {
          data.parentSignature = {
            signatureValue: '',
            partitionkey: '',
            rowkey: '',
            name: data.parent ? data.parent.name : '',
            date: '',
          };
        } else {
          data.parentSignature = {
            ...data.parentSignature,
            name: data.parent ? data.parent.name : '',
          };
        }
        if (data.employeeSignature === null) {
          data.employeeSignature = {
            signatureValue: '',
            partitionkey: '',
            rowkey: '',
            name: localStorage.getItem('userName') ?? '',
            date: '',
          };
        } else {
          data.employeeSignature = {
            ...data.employeeSignature,
            name: localStorage.getItem('userName') ?? '',
          };
        }

        if (data.tpc.time !== null) {
          data.tpc.time = moment(data.tpc.time, 'YYYY-MM-DDTHH:mm:ssZ')
            .utc()
            .local()
            .format();
        }

        if (data.reasonForReferral.adaptive !== null) {
          referralReasonsSelected.push('ADP');
        }
        if (data.reasonForReferral.communication !== null) {
          referralReasonsSelected.push('COM');
        }
        if (data.reasonForReferral.personal !== null) {
          referralReasonsSelected.push('P-S');
        }
        if (data.reasonForReferral.motor !== null) {
          referralReasonsSelected.push('MOT');
        }
        if (data.reasonForReferral.cognitive !== null) {
          referralReasonsSelected.push('COG');
        }
        if (data.reasonForReferral.other !== null) {
          referralReasonsSelected.push('OTHER');
        }
        setReferralReasonsSelected(referralReasonsSelected);
        setInitialReferralReasonsSelectedValue(referralReasonsSelected);
        leaProblems.forEach((problem) => {
          if (
            data.currentIssues !== null &&
            data.currentIssues.includes(problem.labelId)
          ) {
            problem.isChecked = true;
          }
        });
        setLeaProblems(leaProblems);
        setLeaReferral(data);
      })
      .catch((error) => {
        setToastrVariable('error');
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('formBuilder.EditFail');
          setToastrDefaultMessage('Failed to load form');
        }
      });
  }, []);

  const updateReferralReasonsChecks = (checked: boolean, id: string) => {
    if (checked) {
      setReferralReasonsSelected([...referralReasonsSelected, id]);
    } else {
      setReferralReasonsSelected(
        referralReasonsSelected.filter((item) => item !== id),
      );
    }
  };

  const getLanguageDescription = (code: string) => {
    if (lookups && lookups.language) {
      const language = lookups.language.find((lang) => lang.code === code);
      return language ? language.description : '-';
    }
    return '-';
  };

  const getRaceDescription = (code: string) => {
    if (lookups && lookups.race) {
      const race = lookups.race.find((r) => r.code === code);
      return race ? race.description : '-';
    }
    return '-';
  };

  const getEthnicityDescription = (code: string) => {
    if (lookups && lookups.ethnicity) {
      const ethnicity = lookups.ethnicity.find((eth) => eth.code === code);
      return ethnicity ? ethnicity.description : '-';
    }
    return '-';
  };

  const getClientDemographicData = useMemo(
    () =>
      (client: Client): DataJsonType[] => {
        const data: DataJsonType[] = [];

        data.push({
          id: 'spokenLanguage',
          labelid: 'primarySpokenLanguage',
          defaultLabel: 'Primary Spoken Language : ',
          value:
            client.primarySpokenLanguage !== null
              ? getLanguageDescription(client.primarySpokenLanguage)
              : '-',
        });
        data.push({
          id: 'writtenLanguage',
          labelid: 'primaryWrittenLanguage',
          defaultLabel: 'Primary Written Language : ',
          value:
            client.primaryWrittenLanguage !== null
              ? getLanguageDescription(client.primaryWrittenLanguage)
              : '-',
        });
        data.push({
          id: 'race',
          labelid: 'raceText',
          defaultLabel: 'Race : ',
          value:
            client.race !== null
              ? client.race.split(',').map(getRaceDescription).toString()
              : '-',
        });
        data.push({
          id: 'ethnicity',
          labelid: 'ethnicity',
          defaultLabel: 'Ethnicity : ',
          value:
            client.ethnicity !== null
              ? getEthnicityDescription(client.ethnicity)
              : '-',
        });
        return data;
      },
    [],
  );

  const getPediatricianName = useMemo(
    () =>
      (client: Client): string => {
        let pediatricianName = '';
        if (client.pediatricianName !== null) {
          pediatricianName = client.pediatricianName;
        }
        if (client.pediatricianPhoneNumber !== null) {
          pediatricianName =
            pediatricianName + ', ' + client.pediatricianPhoneNumber;
        }
        return pediatricianName;
      },
    [],
  );

  const handleFormSubmission = useCallback(
    debounce((values: LEAReferralType) => {
      handleLeaSubmission(values);
    }, 500),
    [],
  );

  const handleLeaSubmission = (lea: LEAReferralType) => {
    const clientId = localStorage.getItem('ClientId');
    const userId = localStorage.getItem('userId');
    if (clientId === null || userId === null) {
      return;
    }

    if (lea.tpc.date !== null && lea.tpc.time !== null) {
      const time = dayjs(lea.tpc.time).format('HH:mm:ss');
      const timeZOne = dayjs(lea.tpc.time).format('Z');
      const utcDateTime = dayjs
        .utc(`${lea.tpc.date} ${time}${timeZOne}`)
        .format();
      lea.tpc.time = utcDateTime;
    }

    toggleLoader(true);
    updateLEAPreschoolInfo(userId, clientId, lea)
      .then(() => {
        toggleLoader(false);
        setToastrVariable('success');
        setToastrId('formBuilder.updateSuccessMessage');
        setToastrDefaultMessage('Form updated successfully');
      })
      .catch((error) => {
        toggleLoader(false);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('formBuilder.EditFail');
          setToastrDefaultMessage('Failed to update form');
        }
      });
  };

  const handleSuccess = (successError: AlertColor, id: string, mes: string) => {
    setToastrVariable(successError);
    setToastrId(id);
    setToastrDefaultMessage(mes);
  };

  const resetCallBackForTypeEmployee = () => {
    setEmployeeSignatureClicked(!employeeSignatureClicked);
    const sig = formRef.current?.values.employeeSignature;
    if (sig && sig !== null) {
      formRef.current?.setFieldValue('employeeSignature', {
        ...sig,
        partitionkey: '',
        rowkey: '',
        signatureValue: '',
        date: '',
      });
    }
  };

  const onSignatureSaveOrUpdate = (signed: string, type: string) => {
    if (type === 'parent') {
      const sig = formRef.current?.values.parentSignature;
      if (sig && sig !== null) {
        formRef.current?.setFieldValue('parentSignature', {
          ...sig,
          partitionkey: '',
          rowkey: '',
          signatureValue: signed,
          date: dayjs().format('MM/DD/YYYY'),
        });
        setParentSignatureClicked(false);
      }
    } else {
      const sig = formRef.current?.values.employeeSignature;
      if (sig && sig !== null) {
        formRef.current?.setFieldValue('employeeSignature', {
          ...sig,
          partitionkey: '',
          rowkey: '',
          signatureValue: signed,
          date: dayjs().format('MM/DD/YYYY'),
        });
        setEmployeeSignatureClicked(false);
      }
    }
  };
  return (
    <Box component="main">
      {toastrId !== undefined && (
        <SnackBarComponent
          open={toastrId !== undefined}
          handleClose={() => {
            setToastrId(undefined);
          }}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />
      )}
      <Box className="rowContainer">
        <Grid container>
          <Grid item xs={6} lg={6}>
            <Box display="flex" alignItems="center" className="mr-md">
              <CustomBackArrow
                onClick={() =>
                  navigate(LEA_REFERRAL_CONSENT, {
                    state: { consentId: '' },
                  })
                }
              />
              <TitleText
                className="ml-md"
                labelid="leaReferralTitle"
                defaultlabel="LEA Preschool Information Form"
              />
            </Box>
          </Grid>
          <Grid item xs={6} lg={6}></Grid>
        </Grid>
      </Box>
      <ModalPopup
        description="formUnsavedChangesMessage"
        open={openModal}
        onOk={() => {
          formRef.current?.setValues(formRef.current?.initialValues);
          setReferralReasonsSelected(initialReferralReasonsSelectedValue);
          leaProblems.forEach((problem) => {
            if (
              formRef.current?.initialValues.currentIssues !== null &&
              formRef.current?.initialValues.currentIssues.includes(
                problem.labelId,
              )
            ) {
              problem.isChecked = true;
            } else {
              problem.isChecked = false;
            }
          });
          setLeaProblems(LEAProblems);
          setOpenModal(false);
        }}
        onCancel={() => setOpenModal(false)}
        labelId1="Clientpage.cancelbtn"
        negativeActionLabel="cancelText"
        labelId2="Clientpage.Okbtn"
        positiveActionLabel="deleteText"
      />

      {showQuestionModel && (
        <QuestionnaireModel
          open={showQuestionModel}
          onClose={() => setShowQuestionModel(false)}
          onSubmit={(question: string) => {
            const questionnaire = formRef.current?.values.questionnaire;
            if (questionnaire !== undefined) {
              if (questionnaire === null) {
                formRef.current?.setFieldValue('questionnaire', [
                  {
                    id: uuidv4(),
                    question: question,
                    answer: '',
                  },
                ]);
              } else {
                formRef.current?.setFieldValue('questionnaire', [
                  ...questionnaire,
                  {
                    id: uuidv4(),
                    question: question,
                    answer: '',
                  },
                ]);
              }
            }
            setShowQuestionModel(false);
          }}
        />
      )}
      <>
        <div
          ref={parentRef}
          style={{
            pointerEvents: isScreenLocked ? 'none' : 'auto',
            opacity: isScreenLocked ? '0.5' : '1 ',
          }}
          onKeyDownCapture={(e) => {
            if (isScreenLocked) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          onFocus={() => {
            if (isScreenLocked) {
              if (parentRef.current) parentRef.current.focus();
            }
          }}
        >
          <Card className="p-md">
            <Box component="section">
              <ProgramPanel
                program={leaReferral.program}
                client={leaReferral.client}
                parent={leaReferral.parent}
                showIntakeFacilitator={true}
              />
            </Box>

            <Box component="section">
              <Box className="defaultHorizontalLine" />
            </Box>

            <Formik
              innerRef={formRef}
              initialValues={leaReferral}
              enableReinitialize={true}
              validationSchema={validationSchema}
              validate={validateForm}
              onSubmit={(values) => {
                handleFormSubmission(JSON.parse(JSON.stringify(values)));
              }}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Box component="section" className="mt-lg">
                  <Grid container xs={12} rowSpacing={'16px'} columnSpacing={2}>
                    <Grid item xs={9}>
                      <MediumTypography
                        sxProps={{ fontSize: '16px' }}
                        labelid="referralToLocalEducationAgencyText"
                        defaultlabel="Referral to the local education agency (LEA) / School district for SPED services"
                      />
                    </Grid>

                    <Grid item xs={4.5}>
                      <DatePickerComponent
                        labelid="dateOfReferralSchoolDistrictText"
                        defaultlabelid="Date of referral to the LEA/School district"
                        value={dayjs(values.referralDate) as Dayjs}
                        handlechange={(date: Dayjs | null) => {
                          const formattedDate =
                            dayjs(date).format('MM/DD/YYYY');
                          if (date === null) {
                            setFieldValue('referralDate', null);
                          } else {
                            setFieldValue('referralDate', formattedDate);
                          }
                        }}
                      />
                      {errors.referralDate && touched.referralDate && (
                        <MediumTypography
                          label={rctl.formatMessage({
                            id: errors.referralDate,
                            defaultMessage: 'Referral Date is Required',
                          })}
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                    <Grid item xs={4.5}>
                      <DropdownComponent
                        names={dischargeReasons}
                        name="dischargeReasonText"
                        labelid="dischargeReasonText"
                        defaultlabelid="Discharge Reason"
                        value={
                          values.dischargeReason !== null
                            ? values.dischargeReason
                            : ''
                        }
                        handleChange={(reason: string) => {
                          setFieldValue('dischargeReason', reason);
                        }}
                      />
                      {errors.dischargeReason && touched.dischargeReason && (
                        <MediumTypography
                          label={rctl.formatMessage({
                            id: errors.dischargeReason,
                            defaultMessage: 'Discharge Reason is required',
                          })}
                          className="errorText-md"
                        />
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <MediumTypography
                        sxProps={{ fontSize: '16px' }}
                        labelid="parentsWrittenConsentText"
                        defaultlabel="(With a parent's written consent, a referral must be made at least 90 days and up to 9 months prior to the child's third birthday.)"
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <Textinput
                        disabled={true}
                        Value={
                          values.daysThirdBirth !== null
                            ? values.daysThirdBirth.toString()
                            : ''
                        }
                        labelid="daysBODText"
                        defaultlabelid="# of Days to 3rd Birth Date"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MediumTypography
                        className="mainText-md mb-sm"
                        labelid="compliantTimeframeReasonsTitle"
                        defaultlabel="Reason referral did not occur within compliant timeframe"
                      />
                      <CustomRadioButton
                        className="grid-radio-button ml-xs"
                        data={reasons}
                        paddingY="2px"
                        row={false}
                        value={values.referralReason}
                        onSelectItem={(value) => {
                          if (value !== 'leaReason4') {
                            setFieldValue('referralReasonOther', '');
                          }
                          setFieldValue('referralReason', value);
                        }}
                      />

                      {errors.referralReason && touched.referralReason && (
                        <MediumTypography
                          label={rctl.formatMessage({
                            id: errors.referralReason,
                            defaultMessage: 'Referral reason is required',
                          })}
                          className="errorText-md"
                        />
                      )}

                      {values.referralReason !== null &&
                        values.referralReason === 'leaReason4' && (
                          <Grid item xs={9}>
                            <CommentTextbox
                              Value={values.referralReasonOther}
                              maxLength={200}
                              placeholder={rctl.formatMessage({
                                id: 'enterReasonText',
                                defaultMessage: 'Enter Reason',
                              })}
                              handlechange={(e) => {
                                setFieldValue('referralReasonOther', e);
                              }}
                            />

                            {errors.referralReasonOther &&
                              touched.referralReasonOther && (
                                <MediumTypography
                                  label={rctl.formatMessage({
                                    id: errors.referralReasonOther,
                                    defaultMessage:
                                      'Referral other reason is required',
                                  })}
                                  className="errorText-md"
                                />
                              )}
                          </Grid>
                        )}
                    </Grid>

                    <Grid item xs={9}>
                      <MediumTypography
                        className="mainText-md"
                        labelid="leaDistrictInformation"
                        defaultlabel="LEA/School District information"
                      />
                    </Grid>
                    <Grid item xs={4.5}>
                      <Textinput
                        labelid="referringToText"
                        defaultlabelid="Referring to"
                        inputProps={{
                          maxLength: 100,
                        }}
                        Value={
                          values.referringTo !== null ? values.referringTo : ''
                        }
                        handlechange={(e) => {
                          setFieldValue('referringTo', e);
                        }}
                      />
                      {errors.referringTo && touched.referringTo && (
                        <MediumTypography
                          label={rctl.formatMessage({
                            id: errors.referringTo,
                            defaultMessage: 'Referring to is required',
                          })}
                          className="errorText-md mt-xs"
                        />
                      )}
                    </Grid>

                    {leaReferral.client && (
                      <Grid item xs={12}>
                        <KeyValueComponent
                          data={getClientDemographicData(leaReferral.client)}
                        />
                      </Grid>
                    )}
                    {leaReferral.client && (
                      <Grid item xs={12}>
                        <KeyValueComponent
                          data={[
                            {
                              id: 'pediatrician',
                              labelid: 'pediatricianText',
                              defaultLabel: 'Pediatrician : ',
                              value: getPediatricianName(leaReferral.client),
                            },
                          ]}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <MediumTypography
                        className="mainText-md"
                        labelid="presentIssuesText"
                        defaultlabel="Present/Problem issues"
                      />
                      {leaProblems.map((item, index) => (
                        <Box className="flex__ alignItemCenter">
                          <CustomCheckBox
                            value={item.isChecked}
                            onCheckBoxClick={(checked) => {
                              leaProblems[index].isChecked = checked;
                              setLeaProblems([...leaProblems]);
                              setFieldValue(
                                'currentIssues',
                                leaProblems
                                  .filter((issues) => issues.isChecked)
                                  .map((i) => i.labelId)
                                  .join(','),
                              );
                            }}
                          />
                          <MediumTypography
                            labelid={item.labelId}
                            defaultlabel={item.defaultLabelId}
                          />
                        </Box>
                      ))}
                      {values.currentIssues &&
                        values.currentIssues.includes('other') && (
                          <Grid item xs={9}>
                            <CommentTextbox
                              placeholder={rctl.formatMessage({
                                id: 'otherIssuesText',
                                defaultMessage: 'Other issues',
                              })}
                              maxLength={200}
                              Value={values.currentOtherIssues}
                              handlechange={(e) => {
                                setFieldValue('currentOtherIssues', e);
                              }}
                            />
                          </Grid>
                        )}
                      {errors.currentIssues && touched.currentIssues && (
                        <MediumTypography
                          label={rctl.formatMessage({
                            id: errors.currentIssues,
                            defaultMessage:
                              'Present/Problem issues are required',
                          })}
                          className="errorText-md"
                        />
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <MediumTypography
                        className="mainText-md"
                        labelid="lastEvaluationDateText"
                        defaultlabel="Last Evaluation Date"
                      />
                    </Grid>

                    <Grid item className="flex__">
                      <Box className="mr-lg">
                        <DatePickerComponent
                          labelid="lastEvaluationDate"
                          defaultlabelid="Last Evaluation Date"
                          value={dayjs(values.lastEvaluationDate) as Dayjs}
                          handlechange={(date: Dayjs | null) => {
                            const formattedDate =
                              dayjs(date).format('MM/DD/YYYY');
                            if (date === null) {
                              setFieldValue('lastEvaluationDate', null);
                            } else {
                              setFieldValue(
                                'lastEvaluationDate',
                                formattedDate,
                              );
                            }
                          }}
                        />
                        {errors.lastEvaluationDate &&
                          touched.lastEvaluationDate && (
                            <MediumTypography
                              label={rctl.formatMessage({
                                id: errors.lastEvaluationDate,
                                defaultMessage:
                                  'Last Evaluation Date is required',
                              })}
                              className="errorText-md mt-xs"
                            />
                          )}
                      </Box>
                      <CustomRadioButton
                        className="mt-xs"
                        data={EvaluationQuestions}
                        onSelectItem={(item) => {
                          setFieldValue('evaluationFrom', item);
                        }}
                        row={true}
                        value={values.evaluationFrom}
                      />
                    </Grid>

                    <Grid item xs={12} className={'flex__ alignItemCenter'}>
                      <CustomCheckBox
                        value={
                          values.attachBDI2Results !== null
                            ? values.attachBDI2Results
                            : false
                        }
                        onCheckBoxClick={(checked) => {
                          setFieldValue('attachBDI2Results', checked);
                        }}
                      />
                      <MediumTypography
                        labelid="attachBdi2Text"
                        defaultlabel="Attach BDI2 results"
                      />
                    </Grid>

                    <Grid item className={'flex__ alignItemCenter'} xs={12}>
                      <MediumTypography
                        className="mainText-md"
                        labelid="isDayCareText"
                        defaultlabel="Is Child is in Day care?"
                      />
                      <CustomRadioButton
                        className="pl-lg"
                        data={DayCareQuestions}
                        onSelectItem={(item) => {
                          setFieldValue('childIsInDayCare', item === 'yes');
                        }}
                        row={true}
                        value={
                          values.childIsInDayCare !== null
                            ? values.childIsInDayCare
                              ? 'yes'
                              : 'no'
                            : 'no'
                        }
                      />
                    </Grid>

                    {values.childIsInDayCare && (
                      <Grid item xs={3}>
                        <Textinput
                          labelid="dayCareNameText"
                          defaultlabelid="Day Care Name"
                          Value={values.dayCareName}
                          inputProps={{
                            maxLength: 50,
                          }}
                          handlechange={(e) => {
                            setFieldValue('dayCareName', e);
                          }}
                        />
                        {errors.dayCareName && touched.dayCareName && (
                          <MediumTypography
                            label={rctl.formatMessage({
                              id: errors.dayCareName,
                              defaultMessage: 'Day Care Name is required',
                            })}
                            className="errorText-md"
                          />
                        )}
                      </Grid>
                    )}

                    {values.childIsInDayCare && (
                      <Grid item xs={3}>
                        <Textinput
                          labelid="dayCarePhoneText"
                          defaultlabelid="Day Care Phone Number"
                          Value={values.dayCarePhoneNumber}
                          handlechange={(e) => {
                            const validatedValue = formatPhoneNumber(e);
                            setFieldValue('dayCarePhoneNumber', validatedValue);
                          }}
                        />
                        {errors.dayCarePhoneNumber &&
                          touched.dayCarePhoneNumber && (
                            <MediumTypography
                              label={rctl.formatMessage({
                                id: errors.dayCarePhoneNumber,
                                defaultMessage:
                                  'Day Care Phone Number is required',
                              })}
                              className="errorText-md"
                            />
                          )}
                      </Grid>
                    )}

                    {values.childIsInDayCare && (
                      <Grid item xs={3}>
                        <Textinput
                          labelid="childScheduleText"
                          defaultlabelid="Child Schedule"
                          inputProps={{ maxLength: 50 }}
                          Value={values.childSchedule}
                          handlechange={(e) => {
                            setFieldValue('childSchedule', e);
                          }}
                        />
                        {errors.childSchedule && touched.childSchedule && (
                          <MediumTypography
                            label={rctl.formatMessage({
                              id: errors.childSchedule,
                              defaultMessage: 'Child Schedule is required',
                            })}
                            className="errorText-md"
                          />
                        )}
                      </Grid>
                    )}

                    {values.childIsInDayCare && (
                      <Grid item xs={9}>
                        <CommentTextbox
                          placeholder={rctl.formatMessage({
                            id: 'addressText',
                            defaultMessage: 'Address',
                          })}
                          maxLength={200}
                          Value={values.dayCareAddress}
                          handlechange={(e) => {
                            setFieldValue('dayCareAddress', e);
                          }}
                        />
                        {errors.dayCareAddress && touched.dayCareAddress && (
                          <MediumTypography
                            label={rctl.formatMessage({
                              id: errors.dayCareAddress,
                              defaultMessage: 'Address is required',
                            })}
                            className="errorText-md"
                          />
                        )}
                      </Grid>
                    )}

                    <Grid item xs={9}>
                      <MediumTypography
                        className="mainText-md mb-sm"
                        labelid="otherReleventInfoText"
                        defaultlabel="Other Relevant Information"
                      />
                      <CommentTextbox
                        placeholder={rctl.formatMessage({
                          id: 'otherReleventPlaceholderText',
                          defaultMessage:
                            'Other agency involvement or evaluations, living situation, etc.',
                        })}
                        maxLength={500}
                        Value={values.otherReleventInfo}
                        handlechange={(e) => {
                          setFieldValue('otherReleventInfo', e);
                        }}
                      />
                      {errors.otherReleventInfo &&
                        touched.otherReleventInfo && (
                          <MediumTypography
                            label={rctl.formatMessage({
                              id: errors.otherReleventInfo,
                              defaultMessage:
                                'Other Relevant Information is required',
                            })}
                            className="errorText-md"
                          />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      <Box component="div" className="respoScrollTable mb-md">
                        <MediumTypography
                          className="mainText-md mb-sm"
                          labelid="currentEIServices"
                          defaultlabel="Current EI Services"
                        />
                        <TableContainer>
                          <Table>
                            <TableHeader
                              className="listDataTableHead"
                              headerNames={headCells}
                              checkBoxRequired={false}
                            />
                            <TableBody className="tableRowcss">
                              {values.earlyInterventionService !== null &&
                                values.earlyInterventionService.map(
                                  (
                                    listData: ServiceDeliveryPlanPropsData,
                                    index,
                                  ) => (
                                    <EIServiceTableRow
                                      key={index}
                                      data={listData}
                                      actionRequired={false}
                                      onEdit={() => {}}
                                      onDelete={() => {}}
                                      reasonIfLate={lookUpReasons}
                                      eiService={eiService}
                                      intensity={intensity}
                                      locationOfService={locationOfService}
                                      period={period}
                                      providedBy={providedBy}
                                    />
                                  ),
                                )}
                            </TableBody>
                          </Table>
                          {(values.earlyInterventionService === null ||
                            values.earlyInterventionService.length === 0) && (
                            <Box component="div" className="width__100">
                              <MediumTypography
                                textstyle={'center'}
                                labelid="IFSP.emptyMessage"
                                defaultlabel="No data added yet"
                                className="p-sm"
                              />
                            </Box>
                          )}
                        </TableContainer>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <MediumTypography
                        className="mainText-md"
                        labelid="reasonForReferralText"
                        defaultlabel="Reason for a Referral"
                      />
                      <MediumTypography
                        className="mt-sm"
                        label={`${rctl.formatMessage({
                          id: 'reasonForReferralSubText',
                          defaultMessage: 'LEA Evaluation Requested',
                        })} - ${
                          values.reasonForReferral.leaEvaluationRequested
                        }`}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item xs={9}>
                        <Box className={'flex__ alignItemCenter'}>
                          <CustomCheckBox
                            value={referralReasonsSelected.includes('ADP')}
                            onCheckBoxClick={(checked) => {
                              updateReferralReasonsChecks(checked, 'ADP');
                              if (!checked) {
                                setFieldValue(
                                  'reasonForReferral.adaptive',
                                  null,
                                );
                              }
                            }}
                          />
                          <MediumTypography
                            labelid="Adaptive (ADP)"
                            defaultlabel="Adaptive (ADP)"
                          />
                        </Box>
                        {referralReasonsSelected.includes('ADP') && (
                          <Box>
                            <Textinput
                              className="ml-xlg pr-xlg"
                              labelid="myTimeOffReason"
                              defaultlabelid="Reason"
                              inputProps={{
                                maxLength: 200,
                              }}
                              Value={
                                values.reasonForReferral !== null
                                  ? values.reasonForReferral.adaptive
                                  : null
                              }
                              handlechange={(e) => {
                                setFieldValue('reasonForReferral.adaptive', e);
                              }}
                            />
                          </Box>
                        )}
                      </Grid>

                      <Grid item xs={9}>
                        <Box className={'flex__ alignItemCenter'}>
                          <CustomCheckBox
                            value={referralReasonsSelected.includes('COM')}
                            onCheckBoxClick={(checked) => {
                              updateReferralReasonsChecks(checked, 'COM');
                              if (!checked) {
                                setFieldValue(
                                  'reasonForReferral.communication',
                                  null,
                                );
                              }
                            }}
                          />
                          <MediumTypography
                            labelid="Communication (COM)"
                            defaultlabel="Communication (COM)"
                          />
                        </Box>
                        {referralReasonsSelected.includes('COM') && (
                          <Box>
                            <Textinput
                              className="ml-xlg pr-xlg"
                              labelid="myTimeOffReason"
                              defaultlabelid="Reason"
                              inputProps={{
                                maxLength: 200,
                              }}
                              Value={
                                values.reasonForReferral !== null
                                  ? values.reasonForReferral.communication
                                  : null
                              }
                              handlechange={(e) => {
                                setFieldValue(
                                  'reasonForReferral.communication',
                                  e,
                                );
                              }}
                            />
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={9}>
                        <Box className={'flex__ alignItemCenter'}>
                          <CustomCheckBox
                            value={referralReasonsSelected.includes('P-S')}
                            onCheckBoxClick={(checked) => {
                              updateReferralReasonsChecks(checked, 'P-S');
                              if (!checked) {
                                setFieldValue(
                                  'reasonForReferral.personal',
                                  null,
                                );
                              }
                            }}
                          />
                          <MediumTypography
                            labelid="Personal – Social (P-S)"
                            defaultlabel="Personal – Social (P-S)"
                          />
                        </Box>
                        {referralReasonsSelected.includes('P-S') && (
                          <Box>
                            <Textinput
                              className="ml-xlg pr-xlg"
                              labelid="myTimeOffReason"
                              defaultlabelid="Reason"
                              inputProps={{
                                maxLength: 200,
                              }}
                              Value={
                                values.reasonForReferral !== null
                                  ? values.reasonForReferral.personal
                                  : null
                              }
                              handlechange={(e) => {
                                setFieldValue('reasonForReferral.personal', e);
                              }}
                            />
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={9}>
                        <Box className={'flex__ alignItemCenter'}>
                          <CustomCheckBox
                            value={referralReasonsSelected.includes('MOT')}
                            onCheckBoxClick={(checked) => {
                              updateReferralReasonsChecks(checked, 'MOT');
                              if (!checked) {
                                setFieldValue('reasonForReferral.motor', null);
                              }
                            }}
                          />
                          <MediumTypography
                            labelid="Motor (MOT)"
                            defaultlabel="Motor (MOT)"
                          />
                        </Box>
                        {referralReasonsSelected.includes('MOT') && (
                          <Box>
                            <Textinput
                              className="ml-xlg pr-xlg"
                              labelid="myTimeOffReason"
                              defaultlabelid="Reason"
                              inputProps={{
                                maxLength: 200,
                              }}
                              Value={
                                values.reasonForReferral !== null
                                  ? values.reasonForReferral.motor
                                  : null
                              }
                              handlechange={(e) => {
                                setFieldValue('reasonForReferral.motor', e);
                              }}
                            />
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={9}>
                        <Box className={'flex__ alignItemCenter'}>
                          <CustomCheckBox
                            value={referralReasonsSelected.includes('COG')}
                            onCheckBoxClick={(checked) => {
                              updateReferralReasonsChecks(checked, 'COG');
                              if (!checked) {
                                setFieldValue(
                                  'reasonForReferral.cognitive',
                                  null,
                                );
                              }
                            }}
                          />
                          <MediumTypography
                            labelid="Cognitive (COG)"
                            defaultlabel="Cognitive (COG)"
                          />
                        </Box>
                        {referralReasonsSelected.includes('COG') && (
                          <Box>
                            <Textinput
                              className="ml-xlg pr-xlg"
                              labelid="myTimeOffReason"
                              defaultlabelid="Reason"
                              inputProps={{
                                maxLength: 200,
                              }}
                              Value={
                                values.reasonForReferral !== null
                                  ? values.reasonForReferral.cognitive
                                  : null
                              }
                              handlechange={(e) => {
                                setFieldValue('reasonForReferral.cognitive', e);
                              }}
                            />
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={9}>
                        <Box className={'flex__ alignItemCenter'}>
                          <CustomCheckBox
                            value={referralReasonsSelected.includes('OTHER')}
                            onCheckBoxClick={(checked) => {
                              updateReferralReasonsChecks(checked, 'OTHER');
                              if (!checked) {
                                setFieldValue('reasonForReferral.other', null);
                              }
                            }}
                          />
                          <MediumTypography
                            labelid="otherText"
                            defaultlabel="Other"
                          />
                        </Box>
                        {referralReasonsSelected.includes('OTHER') && (
                          <Box>
                            <Textinput
                              className="ml-xlg pr-xlg"
                              labelid="myTimeOffReason"
                              defaultlabelid="Reason"
                              inputProps={{ maxLength: 1000 }}
                              Value={
                                values.reasonForReferral !== null
                                  ? values.reasonForReferral.other
                                  : null
                              }
                              handlechange={(e) => {
                                setFieldValue('reasonForReferral.other', e);
                              }}
                            />
                          </Box>
                        )}
                      </Grid>
                      {values.questionnaire &&
                        values.questionnaire.length > 0 && (
                          <MediumTypography
                            className="mainText-md mt-lg"
                            labelid="ProgressNote.Questionnaire"
                            defaultlabel="Questionnaire"
                          />
                        )}
                      {values.questionnaire &&
                        values.questionnaire.length > 0 &&
                        values.questionnaire.map((question, index) => {
                          return (
                            <Grid item xs={9} className="mt-md">
                              <QuestionItem
                                index={index}
                                key={question.id}
                                question={question}
                                onAnswerChange={(answer: LEAQuestion) => {
                                  setFieldValue(
                                    'questionnaire',
                                    values.questionnaire.map((item) => {
                                      if (item.id === answer.id) {
                                        return answer;
                                      }
                                      return item;
                                    }),
                                  );
                                }}
                                onDelete={(id: string) => {
                                  setFieldValue(
                                    'questionnaire',
                                    values.questionnaire.filter(
                                      (item) => item.id !== id,
                                    ),
                                  );
                                }}
                              />
                              {errors.questionnaire &&
                                errors.questionnaire[index] &&
                                touched.questionnaire &&
                                touched.questionnaire[index] && (
                                  <MediumTypography
                                    label={rctl.formatMessage({
                                      id: (
                                        errors.questionnaire[
                                          index
                                        ] as LEAQuestion
                                      ).answer,
                                      defaultMessage: 'Answer is required',
                                    })}
                                    className="errorText-md"
                                  />
                                )}
                            </Grid>
                          );
                        })}

                      {(values.questionnaire === null ||
                        (values.questionnaire &&
                          values.questionnaire.length < 5)) && (
                        <Grid item>
                          <ButtonComponent
                            className="btn-primary btn-cancel mt-md"
                            variantType="outlined"
                            labelId="ProgressNote.AddQuestionnaire"
                            defaultLabelId="Add Questionnaire"
                            LeftIcon={<FileIconGray />}
                            sxProps={{ textTransform: 'none' }}
                            onClick={() => setShowQuestionModel(true)}
                          />
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <MediumTypography
                          className="mainText-md mt-lg mb-md"
                          labelid="invitationTpcText"
                          defaultlabel="Invitation to Transition Planning Conference (TPC)"
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        display={'flex'}
                        rowSpacing={'16px'}
                        columnSpacing={2}
                      >
                        <Grid item xs={3} className="mr-lg">
                          <DatePickerComponent
                            labelid="dateText"
                            defaultlabelid="Date"
                            value={dayjs(values.tpc.date)}
                            handlechange={(date: Dayjs | null) => {
                              const formattedDate =
                                dayjs(date).format('MM/DD/YYYY');
                              if (date === null) {
                                setFieldValue('tpc.date', null);
                              } else {
                                setFieldValue('tpc.date', formattedDate);
                              }
                            }}
                          />
                          {errors.tpc &&
                            errors.tpc.date &&
                            touched.tpc &&
                            touched.tpc.date && (
                              <MediumTypography
                                label={rctl.formatMessage({
                                  id: errors.tpc.date,
                                  defaultMessage: 'Enter valid Date',
                                })}
                                className="errorText-md"
                              />
                            )}
                        </Grid>

                        <Grid item xs={3} className="ml-lg">
                          <AppTimePicker
                            sxButtonProps={{
                              height: '36px',
                              color: '#2a4241',
                              fontSize: '14px',
                              fontFamily: 'Lato-Regular',
                            }}
                            minuteStep={15}
                            value={
                              values.tpc.time !== null
                                ? getTime(values.tpc.time, '00:00')
                                : null
                            }
                            onTimeChange={(time) => {
                              if (time === null) {
                                setFieldValue('tpc.time', null);
                              } else {
                                setFieldValue('tpc.time', time.format());
                              }
                            }}
                          />
                          {errors.tpc &&
                            errors.tpc.time &&
                            touched.tpc &&
                            touched.tpc.time && (
                              <MediumTypography
                                label={rctl.formatMessage({
                                  id: errors.tpc.time,
                                  defaultMessage: 'Time is required',
                                })}
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                      </Grid>

                      <Grid item xs={6.5}>
                        <Textinput
                          className="mt-md"
                          labelid="locationText"
                          defaultlabelid="Location"
                          Value={
                            values.tpc.location !== null
                              ? values.tpc.location
                              : ''
                          }
                          inputProps={{
                            maxLength: 200,
                          }}
                          handlechange={(e) => {
                            setFieldValue('tpc.location', e);
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} display={'flex'}>
                      <Grid item xs={4} display={'flex'}>
                        <Box className="pb-md pt-md">
                          <MediumTypography
                            labelid="Insurance.Parentsignature"
                            defaultlabel="Parent Signature"
                            className="mainText-xlg pt-md"
                          />
                          {values.parentSignature !== null &&
                            (values.parentSignature.signatureValue !== '' ||
                              values.parentSignature.rowkey !== '') && (
                              <>
                                <SignatureName
                                  date={values.parentSignature.date}
                                  name={values.parentSignature.name}
                                />
                                <ButtonComponent
                                  className="btn-primary btn-cancel signature_view_Box"
                                  labelId={'ProgressNoteViewSignature'}
                                  defaultLabelId={'View signature'}
                                  variantType="outlined"
                                  LeftIcon={<Editsignature />}
                                  onClick={() =>
                                    setParentSignatureClicked(true)
                                  }
                                />
                              </>
                            )}
                          {values.parentSignature !== null &&
                            values.parentSignature.signatureValue === '' &&
                            values.parentSignature.rowkey === '' && (
                              <>
                                <SignatureName
                                  date={values.parentSignature.date}
                                  name={values.parentSignature.name}
                                />
                                <ButtonComponent
                                  className="btn-primary btn-cancel signature_view_Box"
                                  labelId="Insurance.Addsignature"
                                  defaultLabelId="Add Signature"
                                  variantType="outlined"
                                  onClick={() =>
                                    setParentSignatureClicked(true)
                                  }
                                />
                              </>
                            )}
                          {parentSignatureClicked && (
                            <Box component="div">
                              <SignatureComponent
                                onCancelClick={() =>
                                  setParentSignatureClicked(false)
                                }
                                signaturePartitionKey={
                                  values.parentSignature !== null
                                    ? values.parentSignature.partitionkey
                                    : ''
                                }
                                signatureRowKey={
                                  values.parentSignature !== null
                                    ? values.parentSignature.rowkey
                                    : ''
                                }
                                signature={
                                  values.parentSignature !== null
                                    ? values.parentSignature.signatureValue
                                    : ''
                                }
                                signatureSaveOrUPdate={(value) =>
                                  onSignatureSaveOrUpdate(value, 'parent')
                                }
                                handleSuccess={handleSuccess}
                                signClicked={parentSignatureClicked}
                                hideRemoveButton={false}
                                resetCallBackForTypeEmployee={
                                  resetCallBackForTypeEmployee
                                }
                                type="Parent"
                              />
                            </Box>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={4} display={'flex'}>
                        <Box className="pb-md pt-md">
                          <MediumTypography
                            labelid="EmployeeSignatureText"
                            defaultlabel="Employee Signature"
                            className="mainText-xlg pt-md"
                          />

                          {values.employeeSignature !== null &&
                            (values.employeeSignature.signatureValue !== '' ||
                              values.employeeSignature.rowkey !== '') && (
                              <>
                                <SignatureName
                                  date={values.employeeSignature.date}
                                  name={values.employeeSignature.name}
                                />
                                <ButtonComponent
                                  className="btn-primary btn-cancel signature_view_Box"
                                  labelId={'ProgressNoteViewSignature'}
                                  defaultLabelId={'View signature'}
                                  variantType="outlined"
                                  LeftIcon={<Editsignature />}
                                  onClick={() =>
                                    setEmployeeSignatureClicked(true)
                                  }
                                />
                              </>
                            )}

                          {values.employeeSignature !== null &&
                            values.employeeSignature.signatureValue === '' &&
                            values.employeeSignature.rowkey === '' && (
                              <>
                                <SignatureName
                                  date={values.employeeSignature.date}
                                  name={values.employeeSignature.name}
                                />
                                <ButtonComponent
                                  className="btn-primary btn-cancel signature_view_Box"
                                  labelId="Insurance.Addsignature"
                                  defaultLabelId="Add Signature"
                                  variantType="outlined"
                                  onClick={() =>
                                    setEmployeeSignatureClicked(true)
                                  }
                                />
                              </>
                            )}
                          {employeeSignatureClicked && (
                            <Box component="div">
                              <SignatureComponent
                                onCancelClick={() =>
                                  setEmployeeSignatureClicked(false)
                                }
                                signaturePartitionKey={
                                  values.employeeSignature !== null
                                    ? values.employeeSignature.partitionkey
                                    : ''
                                }
                                signatureRowKey={
                                  values.employeeSignature !== null
                                    ? values.employeeSignature.rowkey
                                    : ''
                                }
                                signature={
                                  values.employeeSignature !== null
                                    ? values.employeeSignature.signatureValue
                                    : ''
                                }
                                signatureSaveOrUPdate={(value) =>
                                  onSignatureSaveOrUpdate(value, 'employee')
                                }
                                handleSuccess={handleSuccess}
                                signClicked={employeeSignatureClicked}
                                hideRemoveButton={false}
                                resetCallBackForTypeEmployee={
                                  resetCallBackForTypeEmployee
                                }
                                type="Employee"
                              />
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Formik>
          </Card>

          <Box className="mt-md mb-lg">
            <Grid
              container
              direction="row"
              alignItems="right"
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Grid item sx={{ marginRight: '16px' }}>
                <ButtonComponent
                  className="btn-primary btn-cancel"
                  variantType="contained"
                  labelId="Clientpage.cancelbtn"
                  defaultLabelId="Cancel"
                  onClick={() => {
                    if (
                      JSON.stringify(formRef.current?.initialValues) !==
                      JSON.stringify(formRef.current?.values)
                    ) {
                      setOpenModal(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <ButtonComponent
                  className="btn-primary btn-submit"
                  variantType="contained"
                  type="submit"
                  labelId={'Insurance.save'}
                  defaultLabelId="Save"
                  onClick={() => {
                    formRef.current?.submitForm();
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </div>
      </>
    </Box>
  );
};

export default LEAPreschoolForm;
