import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MediumTypography from './MediumTypography';
import { Box } from '@mui/system';

type CustomCardComponentType = {
  headTextWithValue: string;
  headTextWithValue2: string;
  subText1: string;
  subText1Value: string;
  subText2: string;
  subText2Value: string;
  subText3?: string;
  subText3Value?: string;
};

const CustomcardComponent = ({
  headTextWithValue,
  headTextWithValue2,
  subText1,
  subText1Value,
  subText2,
  subText2Value,
  subText3,
  subText3Value,
}: CustomCardComponentType) => {
  return (
    <Card
      sx={{
        width: '100%',
        border: '1px solid #00C6B8',
        background: 'white',
        height: 126,
      }}
    >
      <CardContent>
        <Box component="div" className="flex__ alignItemCenter">
          <MediumTypography
            labelid={headTextWithValue}
            defaultlabel={headTextWithValue}
            sxProps={{
              fontSize: '16px',
              fontWeight: 'bold',
            }}
          />
          <MediumTypography
            labelid={headTextWithValue2}
            defaultlabel={headTextWithValue2}
            className="ml-xs"
            sxProps={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#00C6B8',
            }}
          />
        </Box>
        <Box component="div" className="flex__ alignItemCenter">
          <MediumTypography
            labelid={subText1}
            defaultlabel={subText1}
            sxProps={{
              fontSize: '12px',
              fontWeight: '100',
              color: '#97A6A5',
            }}
          />
          <MediumTypography
            labelid={subText1Value}
            defaultlabel={subText1Value}
            className="ml-xs"
            sxProps={{
              fontSize: '12px',
              fontWeight: '100',
            }}
          />
        </Box>
        <Box component="div" className="flex__ alignItemCenter">
          <MediumTypography
            labelid={subText2}
            defaultlabel={subText2}
            sxProps={{
              fontSize: '12px',
              fontWeight: '100',
              color: '#97A6A5',
            }}
          />
          <MediumTypography
            labelid={subText2Value}
            defaultlabel={subText2Value}
            className="ml-xs"
            sxProps={{
              fontSize: '12px',
              fontWeight: '100',
            }}
          />
        </Box>
        <Box component="div" className="flex__ alignItemCenter">
          <MediumTypography
            labelid={subText3}
            defaultlabel={subText3}
            sxProps={{
              fontSize: '12px',
              fontWeight: '100',
              color: '#97A6A5',
            }}
          />
          <MediumTypography
            labelid={subText3Value}
            defaultlabel={subText3Value}
            className="ml-xs"
            sxProps={{
              fontSize: '12px',
              fontWeight: '100',
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default CustomcardComponent;
