import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  AlertColor,
  Card,
  Grid,
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { ReactComponent as EditIcon } from '../../assets/images/editvector.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';
import { FormList, FormResponseList, Order } from '../../utils/type';
import {
  EDIT_FORM_BUILDER_ROUTE,
  FORM_BUILDER_ROUTE,
} from '../../routes/Routing';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import {
  deleteForms,
  getAllForms,
} from '../../services/configApi/forms/FormBuilder/FormBuilderServices';
import moment from 'moment';
import SearchBox from '../../components/formlib/SearchBox';
import TitleText from '../../components/formlib/TitleText';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import SearchNotFound from '../../components/formlib/SearchNotFound';
import ModalPopup from '../../components/formlib/ModalPopup';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import EmptyScreen from '../../components/shared/EmptyScreen';
import { debounce } from 'lodash';
import AppPagination from '../../components/shared/AppPagination';
import { checkPermissionForFeature } from '../../utils/checkPermission';

const initialHeadCells: HeadCell<Data>[] = [
  {
    id: 'name',
    labelId: 'formBuilder.FormNameTable',
    defaultLabelId: 'Form Name',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'createdBy',
    labelId: 'formBuilder.CreatedByTable',
    defaultLabelId: 'Created by',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'createdOn',
    labelId: 'formBuilder.CreatedOnTable',
    defaultLabelId: 'Created on',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'modifiedBy',
    labelId: 'formBuilder.ModifiedByTable',
    defaultLabelId: 'Modified by',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'modifiedOn',
    labelId: 'formBuilder.ModifiedOnTable',
    defaultLabelId: 'Modified on',
    numeric: false,
    requiredSorting: true,
  },
];

export type Data = {
  name: string;
  createdOn: string;
  createdBy: string;
  program: string;
  actions: string;
  modifiedBy: string;
  modifiedOn: string;
};

const OthersFormsList = () => {
  const CustomTableCell = styled(TableCell)(() => ({
    fontFamily: 'Lato-Regular',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    padding: '14px 16px',
  }));

  const Others = 'OTHERS';

  const [formList, setFormList] = useState<FormList[]>([]);
  const [open, setOpen] = useState(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [searchText, setSearchText] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowId, setRowId] = useState<string>('');
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState<keyof Data | string>('');
  const [order, setOrder] = useState<Order>('desc');
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const navigate = useNavigate();
  const [responseDetailsCount, setResponseDetailsCount] = useState<number>(0);
  const [showEmptyScreen, setShowEmptyScreen] = useState<boolean>(false);
  const [headCells, setHeadCells] = useState(initialHeadCells);

  useEffect(() => {
    toggleLoader(true);
    getInitialFormList();
  }, [page, order, orderBy, searchText]);

  useEffect(() => {
    if (
      (checkPermissionForFeature('backend.form_builder', 'editPermission') ||
        checkPermissionForFeature(
          'backend.form_builder',
          'deletePermission',
        )) &&
      headCells.length === 5
    ) {
      setHeadCells([
        ...headCells,
        {
          id: 'actions',
          labelId: 'formBuilder.Actions',
          defaultLabelId: 'Actions',
          numeric: false,
          requiredSorting: false,
        },
      ]);
    }
    return () => {
      setHeadCells(initialHeadCells);
    };
  }, []);

  const getInitialFormList = () => {
    getAllForms(Others, page, 10, order, searchText, orderBy, true)
      .then((response: FormResponseList) => {
        const { forms } = response;
        setFormList(forms);
        setResponseDetailsCount(response.totalFormsCount);
        toggleLoader(false);
        if (response.totalFormsCount === 0 && page === 0 && searchText === '') {
          setShowEmptyScreen(true);
        } else {
          setShowEmptyScreen(false);
        }
      })
      .catch((error) => {
        setOpen(true);
        toggleLoader(false);
        setToastrVariable('error');
        setToastrId('formBuilder.ListLoadFail');
        setToastrDefaultMessage(error.message);
      });
  };

  const deleteRecord = (id: string, val: boolean) => {
    toggleLoader(true);
    deleteForms(id, val)
      .then((response) => {
        setDeleteModal(false);
        setOpen(true);
        setToastrId(response.message);
        setToastrDefaultMessage(response.message);
        setToastrVariable('success');
        if (formList.length == 1) {
          setPage(page - 1);
        } else {
          getInitialFormList();
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setDeleteModal(false);
        setOpen(true);
        toggleLoader(false);
        setToastrVariable('error');
        setToastrId(error.message);
        setToastrDefaultMessage(error);
      });
  };

  const isValidInput = (value: string) => {
    const allowedCharacters = /^[a-zA-Z0-9,'.\s!-]*$/;
    return allowedCharacters.test(value);
  };

  const changeTextDebouncer = useCallback(
    debounce((value: string) => {
      if (isValidInput(value)) {
        setPage(0);
        setSearchText(value);
      } else {
        setToastrVariable('error');
        setToastrId('Dashboard.ClientList.searchError');
      }
    }, 500),
    [],
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage - 1 !== page) {
      setPage(newPage - 1);
      setFormList([]);
      setResponseDetailsCount(0);
    }
  };

  return (
    <Box component="main">
      <Box component="section">
        <SnackBarComponent
          open={open}
          handleClose={() => setOpen(false)}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />

        <Box className="rowContainer">
          <Grid container>
            <Grid item xs={6} lg={6}>
              <TitleText labelid="formBuilder" defaultlabel="Form Repository" />
            </Grid>
            <Grid item xs={6} lg={6}>
              <Box
                component="div"
                className="flex__ text-align-end justifyContent-FlexEnd"
              >
                <SearchBox
                  labelId="formBuilder.searchForms"
                  defaultlabel="Search Forms"
                  onChange={changeTextDebouncer}
                />
                {checkPermissionForFeature(
                  'backend.form_builder',
                  'addPermission',
                ) && (
                  <ButtonComponent
                    className="btn-primary btn-submit ml-md"
                    variantType="contained"
                    type="submit"
                    labelId="formBuilder.createNew"
                    onClick={() => navigate(FORM_BUILDER_ROUTE)}
                    disabled={false}
                    defaultLabelId="Save"
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        {searchText != '' && formList.length === 0 && <SearchNotFound />}
        <Box component="div">
          {formList.length !== 0 && (
            <Card>
              <TableContainer>
                <Table>
                  <TableHeader
                    className="listDataTableHead"
                    headerNames={headCells}
                    checkBoxRequired={true}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />

                  <TableBody className="tableRowcss">
                    {formList
                      .filter((row) => {
                        return row?.name
                          ?.toLowerCase()
                          .includes(searchText?.toLowerCase());
                      })
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            key={row.id}
                            tabIndex={-1}
                            sx={{
                              cursor: 'pointer',
                              alignContent: 'flex-start',
                              '&:nth-of-type(odd)': {
                                backgroundColor: '#ECF9F8',
                              },
                            }}
                          >
                            <TooltipTableCell value={row.name} visible={true} />
                            <TooltipTableCell
                              value={row.createdBy}
                              visible={true}
                            />
                            <TooltipTableCell
                              value={moment(row.createdOn).format('MM/DD/YYYY')}
                              visible={true}
                            />
                            <TooltipTableCell
                              value={row.modifiedBy ? row.modifiedBy : '-'}
                              visible={row.modifiedBy ? true : false}
                            />
                            <TooltipTableCell
                              value={
                                row.modifiedOn !== null &&
                                moment(row.modifiedOn).format('MM/DD/YYYY')
                                  ? moment(row.modifiedOn).format('MM/DD/YYYY')
                                  : '-'
                              }
                              visible={
                                row.modifiedOn !== null &&
                                moment(row.modifiedOn).format('MM/DD/YYYY')
                                  ? true
                                  : false
                              }
                            />
                            {checkPermissionForFeature(
                              'backend.form_builder',
                              'editPermission',
                            ) ||
                            checkPermissionForFeature(
                              'backend.form_builder',
                              'deletePermission',
                            ) ? (
                              <CustomTableCell className="ellipsis">
                                <Box className="flex__ justifyContent-FlexStart alignItemCenter">
                                  {checkPermissionForFeature(
                                    'backend.form_builder',
                                    'editPermission',
                                  ) && (
                                    <Box className="cursorPointer mr-md">
                                      <Tooltip
                                        id="tool-tip"
                                        title={'Edit'}
                                        placement="bottom-start"
                                      >
                                        <EditIcon
                                          onClick={() =>
                                            navigate(EDIT_FORM_BUILDER_ROUTE, {
                                              replace: true,
                                              state: {
                                                id: row.id,
                                                type: row.type,
                                                name: row.name,
                                              },
                                            })
                                          }
                                        />
                                      </Tooltip>
                                    </Box>
                                  )}
                                  {checkPermissionForFeature(
                                    'backend.form_builder',
                                    'deletePermission',
                                  ) && (
                                    <Box className="cursorPointer">
                                      <Tooltip
                                        id="tool-tip"
                                        title={'Delete'}
                                        placement="bottom-start"
                                      >
                                        <DeleteIcon
                                          onClick={() => {
                                            setDeleteModal(true);
                                            setRowId(row.id);
                                          }}
                                        />
                                      </Tooltip>
                                    </Box>
                                  )}
                                </Box>
                              </CustomTableCell>
                            ) : null}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </Box>
        {showEmptyScreen && (
          <EmptyScreen
            titleLabelId="formBuilder.EmptyFormListResponse"
            defaultTitleText="No forms added yet."
            buttonLabelId="formBuilder.EmptyFormListResponse"
            defaultButtonTitleText="Create"
            showButton={false}
          />
        )}
        {deleteModal && (
          <ModalPopup
            open={deleteModal}
            onCancel={() => {
              setDeleteModal(false);
            }}
            description="formBuilder.DeleteForm"
            onOk={() => deleteRecord(rowId, true)}
            labelId1="Clientpage.cancelbtn"
            negativeActionLabel="cancelText"
            labelId2="Clientpage.Okbtn"
            positiveActionLabel="deleteText"
          />
        )}
      </Box>

      {responseDetailsCount > 10 && (
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <AppPagination
              pageNumber={page}
              paginationCount={responseDetailsCount}
              handleChangePage={handleChangePage}
            />
          </Box>
        </Grid>
      )}
    </Box>
  );
};

export default OthersFormsList;
