import { FC, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import MediumTypography from '../formlib/MediumTypography';
import { ReactComponent as CloseIcon } from '../../assets/images/Close.svg';
import { convertTimeDuration } from '../../utils/dateUtil';
import { OptionType } from '../../utils/type';
// import { Grid } from '@mui/material';

export interface FilterBy {
  key: string;
  value: string | OptionType[];
}
export interface FilteredData {
  filteredData: FilterBy[];
  handleCloseClick?: (value: number, key?: string) => void;
  handleClearClick?: () => void;
  handleCloseClickArrayType?: (main: number, sub: number) => void;
  selectedIntakeFacilitator?: OptionType | null | undefined;
  selectedServiceCoordinator?: OptionType | null | undefined;
}
const DisplayFilter: FC<FilteredData> = (props) => {
  const [filterProps, setFilterProps] = useState<FilterBy[]>(
    props.filteredData,
  );

  useEffect(() => {
    setFilterProps(props.filteredData);
  }, [props]);

  const previousIntakeFacilitatorLabelRef = useRef('');
  const previousServiceCoordinatorLabelRef = useRef('');

  const fetchLabel = (key: string, value: string) => {
    if (key == 'noIntakeFacilitator') {
      if (value === 'true') {
        return 'T';
      } else return '';
    }
    if (key == 'noServiceCoordinator') {
      if (value === 'true') {
        return 'T';
      } else return '';
    }
    if (key == 'Facesheet.intakeFacilitator') {
      if (
        props &&
        props.selectedIntakeFacilitator &&
        props.selectedIntakeFacilitator.id === value
      ) {
        previousIntakeFacilitatorLabelRef.current =
          props.selectedIntakeFacilitator.label;
        return props.selectedIntakeFacilitator.label;
      } else {
        return previousIntakeFacilitatorLabelRef.current;
      }
    }

    if (key == 'Facesheet.serviceCoordinator') {
      if (
        props &&
        props.selectedServiceCoordinator &&
        props.selectedServiceCoordinator.id === value
      ) {
        previousServiceCoordinatorLabelRef.current =
          props.selectedServiceCoordinator.label;
        return props.selectedServiceCoordinator.label;
      } else {
        return previousServiceCoordinatorLabelRef.current;
      }
    }

    if (!key.startsWith('assessmenthours')) {
      return value;
    }

    const [startTime, endTime] = value
      .split('-')
      .map((time: string) => time.trim());

    return `${convertTimeDuration(startTime, true)} - ${convertTimeDuration(
      endTime,
      true,
    )}`;
  };

  const fetchKey = (key: string, value: string | OptionType[]) => {
    if (key == 'noIntakeFacilitator') {
      if (value !== 'true') {
        return '';
      }
    }
    if (key == 'noServiceCoordinator') {
      if (value !== 'true') {
        return '';
      }
    }

    return key;
  };
  const mappedProps = filterProps.map((filter, index) => {
    return (
      <Box
        alignItems="left"
        justifyContent="left"
        display={'ruby !important'}
        key={index + 'j'}
        // sx={{
        //   display: 'flex',
        //   flexDirection: 'row',
        //   alignItems: 'flex-start',
        //   justifyContent: 'center',
        // }}
      >
        <MediumTypography
          textColor="#97A6A5"
          fontweight={590}
          paddingstyle="0px 0px 0px 10px"
          labelid={fetchKey(filter.key, filter.value)}
          defaultlabel="key"
          fontSize="14px"
          sxProps={{ whiteSpace: 'nowrap' }}
        />
        {typeof filter.value == 'string' ? (
          <Box sx={{ display: 'flex !important' }}>
            <MediumTypography
              textColor="#2A4241"
              fontweight={590}
              paddingstyle="0px 0px 0px 5px"
              label={fetchLabel(filter.key, filter.value)}
              defaultlabel="value"
              fontSize="14px"
              sxProps={{
                whiteSpace: 'nowrap',
                color:
                  fetchLabel(filter.key, filter.value) == 'T'
                    ? 'rgba(231, 240, 240, 1)'
                    : '',
              }}
            />
            <CloseIcon
              cursor="pointer"
              style={{
                marginTop: '3px',
                display:
                  filter.key === 'noIntakeFacilitator' ||
                  filter.key === 'noServiceCoordinator'
                    ? filter.value !== 'true'
                      ? 'none'
                      : ''
                    : '',
              }}
              onClick={() => props.handleCloseClick?.(index, filter.key)}
            />
          </Box>
        ) : (
          filter.value.map((value, indexj) => {
            return (
              <Box sx={{ display: 'flex !important' }}>
                <MediumTypography
                  textColor="#2A4241"
                  fontweight={590}
                  paddingstyle="0px 0px 0px 5px"
                  label={fetchLabel(filter.key, value.label)}
                  defaultlabel="value"
                  fontSize="14px"
                  sxProps={{ whiteSpace: 'nowrap' }}
                />
                <CloseIcon
                  cursor="pointer"
                  style={{ marginTop: '3px' }}
                  onClick={() =>
                    props.handleCloseClickArrayType?.(index, indexj)
                  }
                />
              </Box>
            );
          })
        )}
        <Box>
          <MediumTypography
            textColor="#97A6A5"
            fontweight={500}
            paddingstyle="0px 0px 0px 5px"
            label="|"
            defaultlabel="|"
            fontSize="22px"
            sxProps={{
              lineHeight: '16px',
              display:
                filter.key === 'noIntakeFacilitator' ||
                filter.key === 'noServiceCoordinator'
                  ? filter.value !== 'true'
                    ? 'none'
                    : ''
                  : '',
            }}
          />
        </Box>
      </Box>
    );
  });

  return (
    <Box
      sx={{
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'flex-start',
      }}
    >
      <Box
        // wrap="wrap"
        sx={{
          display: 'flex !important',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'left',
        }}
      >
        <MediumTypography
          textColor="#008C82"
          fontweight={590}
          labelid="clearAllFilters"
          defaultlabel="clearAllFilters"
          fontSize="14px"
          sxProps={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
          onClick={() => props.handleClearClick?.()}
        />
        {mappedProps}
      </Box>
    </Box>
  );
};

export default DisplayFilter;
