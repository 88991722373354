import { AlertColor, Box, Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import ASQQuestionConcernComponent from '../../components/forms/SimpleTextRadioButtonAndTextArea';
import ASQQuestionConsiderationComponent from '../../components/forms/SimpleTextAndTrippleRadioButton';
import ASQFollowUpActionComponent from './ASQFollowUpActionComponent';
import MediumTypography from '../../components/formlib/MediumTypography';
import ASQScoreComponent from './ASQScoreComponent';
import TitleText from '../../components/formlib/TitleText';

import { ReactComponent as BackButton } from '../../assets/images/backarrow.svg';
import { useLocation, useNavigate } from 'react-router';

import ASQConcernQuestion2MJson from '../questionnaire/ASQQuestionnaireJson/Concerns/ASQ-SE-2_02Concern.json';
import ASQConcernQuestion6MJson from '../questionnaire/ASQQuestionnaireJson/Concerns/ASQ-SE-2_06Concern.json';
import ASQConcernQuestion12MJson from '../questionnaire/ASQQuestionnaireJson/Concerns/ASQ-SE-2_12Concern.json';
import ASQConcernQuestion18MJson from '../questionnaire/ASQQuestionnaireJson/Concerns/ASQ-SE-2_18Concern.json';
import ASQConcernQuestion24MJson from '../questionnaire/ASQQuestionnaireJson/Concerns/ASQ-SE-2_24Concern.json';
import ASQConcernQuestion30MJson from '../questionnaire/ASQQuestionnaireJson/Concerns/ASQ-SE-2_30Concern.json';
import ASQConcernQuestion36MJson from '../questionnaire/ASQQuestionnaireJson/Concerns/ASQ-SE-2_36Concern.json';

import ASQConsiderationQuestion2MJson from '../questionnaire/ASQQuestionnaireJson/Consideration/ASQ-SE-2_02Con.json';
import ASQConsiderationQuestion6MJson from '../questionnaire/ASQQuestionnaireJson/Consideration/ASQ-SE-2_06Con.json';
import ASQConsiderationQuestion12MJson from '../questionnaire/ASQQuestionnaireJson/Consideration/ASQ-SE-2_12Con.json';
import ASQConsiderationQuestion18MJson from '../questionnaire/ASQQuestionnaireJson/Consideration/ASQ-SE-2_18Con.json';
import ASQConsiderationQuestion24MJson from '../questionnaire/ASQQuestionnaireJson/Consideration/ASQ-SE-2_24Con.json';
import ASQConsiderationQuestion30MJson from '../questionnaire/ASQQuestionnaireJson/Consideration/ASQ-SE-2_30Con.json';
import ASQConsiderationQuestion36MJson from '../questionnaire/ASQQuestionnaireJson/Consideration/ASQ-SE-2_36Con.json';

import ASQActionQuestion2MJson from '../questionnaire/ASQQuestionnaireJson/Actions/ASQ-SE-2_02Action.json';
import ASQActionQuestion6MJson from '../questionnaire/ASQQuestionnaireJson/Actions/ASQ-SE-2_06Action.json';
import ASQActionQuestion12MJson from '../questionnaire/ASQQuestionnaireJson/Actions/ASQ-SE-2_12Action.json';
import ASQActionQuestion18MJson from '../questionnaire/ASQQuestionnaireJson/Actions/ASQ-SE-2_18Action.json';
import ASQActionQuestion24MJson from '../questionnaire/ASQQuestionnaireJson/Actions/ASQ-SE-2_24Action.json';
import ASQActionQuestion30MJson from '../questionnaire/ASQQuestionnaireJson/Actions/ASQ-SE-2_30Action.json';
import ASQActionQuestion36MJson from '../questionnaire/ASQQuestionnaireJson/Actions/ASQ-SE-2_36Action.json';

import ASQQuestionnaireHeaderComponent from './ASQQuestionnaireHeaderComponent';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import {
  ASQ_QUESTIONNAIRES,
  QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME,
  QUESTIONNAIRE_CLIENT_INFO_SUMMARY,
} from '../../routes/Routing';
import { updateAsqse2 } from '../../services/configApi/forms/ASQSE2/ASQQuestionaireServices';

import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import ModalPopup from '../../components/formlib/ModalPopup';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import { checkPermissionForFeature } from '../../utils/checkPermission';

type AgeToCutOffMapType = {
  [key: number]: number;
};

const ASQQuestionnairesInfoSummary = () => {
  const location = useLocation();

  const [ASQConcernQuestion, setASQConcernQuestion] = useState(
    ASQConcernQuestion2MJson,
  );
  const [ASQConsiderationQuestion, setASQConsiderationQuestion] = useState(
    ASQConsiderationQuestion2MJson,
  );
  const [ASQActionQuestion, setASQActionQuestion] = useState(
    ASQActionQuestion2MJson,
  );
  const [titleTextInfo, setTitleTextInfo] = useState(
    'ASQConcernQuestionnairesInfoSummary2M.TitleText',
  );
  const [clientTotalMonths, setclientTotalMonths] = useState(2);
  const [clientStartMonth, setclientStartMonth] = useState(1);
  const [clientEndMonth, setclientEndMonth] = useState(2);
  const [progressValues, setprogressValues] = useState({
    lowRisk: 25,
    mediumRisk: 35,
    highRisk: 55,
  });

  const [comment, setComment] = useState<
    { commentId: number; comments: string; answer: string }[]
  >(
    ASQConcernQuestion.map((_, index) => ({
      commentId: index,
      comments: '',
      answer: 'yes',
    })),
  );

  const navigate = useNavigate();

  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;

  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');

  const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);

  const [consideration, setConsideration] = useState(
    ASQConsiderationQuestion.map((_, index) => ({
      considerationId: index,
      answer: '',
    })),
  );

  const [followUp, setFollowUp] = useState(
    ASQActionQuestion.map(() => ({
      followUpChecked: false,
      followUptextInputValues: '',
      followUpComment: '',
    })),
  );

  const [scoringRawData, setScoringRawData] = useState<
    {
      cutOff: number;
      totalCutOff: number;
    }[]
  >([
    {
      cutOff: 35,
      totalCutOff: location.state?.summary?.totalScore,
    },
  ]);

  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);

  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);

  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getScoringChart();
    setQuestionnaireViewJson();
  }, []);

  useEffect(() => {
    setCommentedValues();
    setConsiderationValues();
    setFollowUpValues();
  }, [ASQConcernQuestion]);

  const setQuestionnaireViewJson = () => {
    const clientAge = location.state.age;
    if (clientAge >= 1 && clientAge <= 41) {
      if (clientAge == 2) {
        setASQConcernQuestion(ASQConcernQuestion2MJson);
        setASQConsiderationQuestion(ASQConsiderationQuestion2MJson);
        setASQActionQuestion(ASQActionQuestion2MJson);
        setTitleTextInfo('ASQConcernQuestionnairesInfoSummary2M.TitleText');
        setclientTotalMonths(2);
        setclientStartMonth(1);
        setclientEndMonth(2);
        setprogressValues({
          lowRisk: 25,
          mediumRisk: 35,
          highRisk: 55,
        });
      } else if (clientAge == 6) {
        setASQConcernQuestion(ASQConcernQuestion6MJson);
        setASQConsiderationQuestion(ASQConsiderationQuestion6MJson);
        setASQActionQuestion(ASQActionQuestion6MJson);
        setTitleTextInfo('ASQConcernQuestionnairesInfoSummary6M.TitleText');
        setclientTotalMonths(6);
        setclientStartMonth(3);
        setclientEndMonth(8);
        setprogressValues({
          lowRisk: 30,
          mediumRisk: 45,
          highRisk: 55,
        });
      } else if (clientAge == 12) {
        setASQConcernQuestion(ASQConcernQuestion12MJson);
        setASQConsiderationQuestion(ASQConsiderationQuestion12MJson);
        setASQActionQuestion(ASQActionQuestion12MJson);
        setTitleTextInfo('ASQConcernQuestionnairesInfoSummary12M.TitleText');
        setclientTotalMonths(12);
        setclientStartMonth(9);
        setclientEndMonth(14);
        setprogressValues({
          lowRisk: 40,
          mediumRisk: 50,
          highRisk: 75,
        });
      } else if (clientAge == 18) {
        setASQConcernQuestion(ASQConcernQuestion18MJson);
        setASQConsiderationQuestion(ASQConsiderationQuestion18MJson);
        setASQActionQuestion(ASQActionQuestion18MJson);
        setTitleTextInfo('ASQConcernQuestionnairesInfoSummary18M.TitleText');
        setclientTotalMonths(18);
        setclientStartMonth(15);
        setclientEndMonth(20);
        setprogressValues({
          lowRisk: 50,
          mediumRisk: 65,
          highRisk: 105,
        });
      } else if (clientAge == 24) {
        setASQConcernQuestion(ASQConcernQuestion24MJson);
        setASQConsiderationQuestion(ASQConsiderationQuestion24MJson);
        setASQActionQuestion(ASQActionQuestion24MJson);
        setTitleTextInfo('ASQConcernQuestionnairesInfoSummary24M.TitleText');
        setclientTotalMonths(24);
        setclientStartMonth(21);
        setclientEndMonth(26);
        setprogressValues({
          lowRisk: 50,
          mediumRisk: 65,
          highRisk: 110,
        });
      } else if (clientAge == 30) {
        setASQConcernQuestion(ASQConcernQuestion30MJson);
        setASQConsiderationQuestion(ASQConsiderationQuestion30MJson);
        setASQActionQuestion(ASQActionQuestion30MJson);
        setTitleTextInfo('ASQConcernQuestionnairesInfoSummary30M.TitleText');
        setclientTotalMonths(30);
        setclientStartMonth(27);
        setclientEndMonth(32);
        setprogressValues({
          lowRisk: 65,
          mediumRisk: 85,
          highRisk: 135,
        });
      } else if (clientAge == 36) {
        setASQConcernQuestion(ASQConcernQuestion36MJson);
        setASQConsiderationQuestion(ASQConsiderationQuestion36MJson);
        setASQActionQuestion(ASQActionQuestion36MJson);
        setTitleTextInfo('ASQConcernQuestionnairesInfoSummary36M.TitleText');
        setclientTotalMonths(36);
        setclientStartMonth(33);
        setclientEndMonth(41);
        setprogressValues({
          lowRisk: 75,
          mediumRisk: 105,
          highRisk: 155,
        });
      }
    } else {
      setASQConcernQuestion(ASQConcernQuestion2MJson);
      setASQConsiderationQuestion(ASQConsiderationQuestion2MJson);
      setASQActionQuestion(ASQActionQuestion2MJson);
      setTitleTextInfo('ASQConcernQuestionnairesInfoSummary2M.TitleText');
      setclientTotalMonths(2);
      setclientStartMonth(1);
      setclientEndMonth(2);
    }
  };

  const getScoringChart = () => {
    const ageToCutOffMap: AgeToCutOffMapType = {
      2: 35,
      6: 45,
      12: 50,
      18: 65,
      24: 65,
      30: 85,
      36: 105,
    };

    const age = location.state.age;
    if (ageToCutOffMap[age]) {
      setScoringRawData([
        {
          cutOff: ageToCutOffMap[age],
          totalCutOff: location.state.summary.totalScore,
        },
      ]);
    }
  };

  const setCommentedValues = () => {
    if (location.state.summary !== null) {
      if (
        location.state.summary.overallConcern &&
        location.state.summary.overallConcern.length > 0
      ) {
        const newComments = location.state.summary.overallConcern.map(
          (value: { comments: string; answer: string }, index: number) => {
            return {
              commentId: index,
              comments:
                value.comments !== null && value.comments !== undefined
                  ? value.comments
                  : '',
              answer:
                value.answer !== null && value.answer !== undefined
                  ? value.answer
                  : '',
            };
          },
        );
        setComment(newComments);
      } else {
        const newComment = Array.from({ length: 3 }, (_, index) => ({
          commentId: index,
          comments: '',
          answer: '',
        }));

        setComment(newComment);
      }
    }
  };

  const setConsiderationValues = () => {
    if (location.state.summary?.followupConsiderations?.length > 0) {
      const newConsideration = Array.from(
        { length: location.state.summary.followupConsiderations.length },
        (_, index) => ({
          considerationId: index,
          answer:
            location.state.summary.followupConsiderations[index] !== null
              ? location.state.summary.followupConsiderations[index].answer
              : '',
        }),
      );

      setConsideration(newConsideration);
    } else {
      const newConsideration = Array.from({ length: 5 }, (_, index) => ({
        considerationId: index,
        answer: '',
      }));

      setConsideration(newConsideration);
    }
  };

  const setFollowUpValues = () => {
    if (location.state.summary?.followup?.selected?.length > 0) {
      const originalArray = ASQActionQuestion.map((_, index) => index);

      const newFollowUp = originalArray.map((data, index) => {
        let followUptextInputValues = '';
        let followUpComment = '';

        if (index === 0) {
          followUptextInputValues = location.state.summary.followup.months;
        } else if (index === 4) {
          followUptextInputValues = location.state.summary.followup.caregiver;
        }

        if (index === originalArray.length - 1) {
          followUpComment = location.state.summary.followup.other;
        }

        return {
          followUpChecked:
            location.state.summary.followup.selected.includes(data),
          followUptextInputValues,
          followUpComment,
        };
      });

      setFollowUp(newFollowUp);
    } else {
      const newFollowUp = Array.from({ length: 10 }, () => ({
        followUpChecked: false,
        followUptextInputValues: '',
        followUpComment: '',
      }));
      setFollowUp(newFollowUp);
    }
  };

  const onCommentChange = (value: { commentId: number; comments: string }) => {
    const updatedComments = [...comment];
    updatedComments[value.commentId].comments = value.comments;
    setComment(updatedComments);
  };

  const onCommentValueChange = (
    value: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const updatedComments = [...comment];
    updatedComments[index].answer = value.target.value;
    setComment(updatedComments);
  };

  const onConsiserationValueChange = (
    value: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const updateConsideration = [...consideration];
    updateConsideration[index].answer = value.target.value;
    setConsideration(updateConsideration);
  };

  const onFollowUpActionClick = (value: boolean, index: number) => {
    const updateAction = [...followUp];
    updateAction[index].followUpChecked = value;
    setFollowUp(updateAction);
  };

  const handleTextInputChange = (newValue: string, index: number) => {
    const newTextInputValues = [...followUp];
    newTextInputValues[index].followUptextInputValues = newValue;
    setFollowUp(newTextInputValues);
  };

  const handleOtherCommentChange = (text: string, index: number) => {
    const newActionCommentValues = [...followUp];
    newActionCommentValues[index].followUpComment = text;
    setFollowUp(newActionCommentValues);
  };

  const combinedData = followUp.map((value, index) => ({
    followUpChecked: followUp[index].followUpChecked,
    textInputValue: followUp[index].followUptextInputValues,
    actionComment: followUp[index].followUpComment,
  }));

  const selectedIndices = combinedData
    .map((data, index) => (data.followUpChecked ? index : undefined))
    .filter((index) => index !== undefined);

  const followup = {
    selected: selectedIndices,
    months: combinedData[0].textInputValue || '',
    caregiver: combinedData[4].textInputValue || '',
    other: combinedData[combinedData.length - 1].actionComment || '',
  };

  const handleSubmit = () => {
    toggleLoader(true);
    const params = {
      ...location.state,
      summary: {
        ...location.state.summary,
        overallConcern: comment,
        followupConsiderations: consideration,
        followup,
      },
    };

    updateAsqse2(params.id, params)
      .then((response) => {
        if (response.code === 200) {
          setOpen(true);
          setToastrVariable('success');
          setToastrId('updateSuccessMessage');
          setToastrDefaultMessage(response.message);

          setTimeout(() => {
            toggleLoader(false);
            navigate(QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME, {
              state: {
                data: params,
                pathname: QUESTIONNAIRE_CLIENT_INFO_SUMMARY,
              },
            });
          }, 1000);
        }
      })
      .catch((error) => {
        setOpen(true);
        toggleLoader(false);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('updateFailureMessage');
          setToastrDefaultMessage('Failed to update details');
        }
      });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleOk = () => {
    setShowAlertDialog(false);
    navigate(ASQ_QUESTIONNAIRES, {
      state: location.state,
    });
  };

  return (
    <>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Box
        sx={{
          backgroundColor: '#E7F0F0',
          padding: '16px',
          width: '100%',
        }}
      >
        <ASQQuestionnaireHeaderComponent
          totalMonth={clientTotalMonths}
          startMonth={clientStartMonth}
          endMonth={clientEndMonth}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '16px',
          }}
        >
          <Box
            onClick={() =>
              navigate(ASQ_QUESTIONNAIRES, {
                state: location.state,
              })
            }
            sx={{
              cursor: 'pointer',
              display: 'flex',
            }}
          >
            <BackButton />
          </Box>
          <TitleText
            labelid={titleTextInfo}
            defaultlabel="2 Month Information Summary"
            Sxprops={{ paddingLeft: '16px' }}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            padding: '16px',
            paddingLeft: '24px',
          }}
        >
          <div
            ref={parentRef}
            style={{
              pointerEvents: isScreenLocked ? 'none' : 'auto',
              opacity: isScreenLocked ? '0.5' : '1 ',
            }}
            onKeyDownCapture={(e) => {
              if (isScreenLocked) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            onFocus={() => {
              if (isScreenLocked) {
                if (parentRef.current) parentRef.current.focus();
              }
            }}
          >
            <ASQScoreComponent
              rows={scoringRawData}
              progressValues={progressValues}
            />
            <MediumTypography
              textColor="#2A4241"
              marginTop="22px"
              fontweight={600}
              fontSize="18px"
              labelid={'ASQConcernQuestionnairesInfoSummary.ConcernTitle'}
              defaultlabel="3. OVERALL RESPONSES AND CONCERNS:"
            />
            <Box sx={{ paddingLeft: '18px' }}>
              <MediumTypography
                sxProps={{ paddingY: '22px' }}
                textColor="#2A4241"
                fontweight={500}
                fontSize="14px"
                labelid={
                  'ASQConcernQuestionnairesInfoSummary.ConcernDescription'
                }
                defaultlabel="Record responses and transfer parent/caregiver comments. YES responses require follow-up."
              />
              <ASQQuestionConcernComponent
                customCommentStyles={{
                  height: '40px',
                  minHeight: '40px',
                  minWidth: '100%',
                  width: '100%',
                }}
                disabled={true}
                placeholder="Comments"
                onValueChange={onCommentValueChange}
                handlechange={onCommentChange}
                concernQuestions={ASQConcernQuestion}
                Value={comment}
                initialCheck={comment}
                labelidA="ASQConcernQuestionnairesInfoSummary.RadioLabelA"
                labelidB="ASQConcernQuestionnairesInfoSummary.RadioLabelB"
                defaultlabelidA="Yes"
                defaultlabelidB="No"
              />
            </Box>
            <MediumTypography
              textColor="#2A4241"
              fontweight={600}
              fontSize="18px"
              marginTop="16px"
              labelid={'ASQConcernQuestionnairesInfoSummary.ReferalTitle'}
              defaultlabel="4. FOLLOW-UP REFERRAL CONSIDERATIONS:"
            />
            <Box sx={{ paddingLeft: '18px' }}>
              <MediumTypography
                sxProps={{ paddingY: '22px' }}
                textColor="#2A4241"
                fontweight={500}
                fontSize="14px"
                labelid={
                  'ASQConcernQuestionnairesInfoSummary.ReferalnDescription'
                }
                defaultlabel="Mark all as Yes, No, or Unsure (Y, N, U). See pages 98-103 in the ASO:SE-2 User's Guide."
              />
              <ASQQuestionConsiderationComponent
                onValueChange={onConsiserationValueChange}
                considerationQuestion={ASQConsiderationQuestion}
                initialCheck={consideration}
                labelidA="ASQConcernQuestionnairesInfoSummary.RadioLabelA"
                labelidB="ASQConcernQuestionnairesInfoSummary.RadioLabelB"
                labelidC="ASQConcernQuestionnairesInfoSummary.RadioLabelC"
                defaultlabelidA="Yes"
                defaultlabelidB="No"
                defaultlabelidC="Unsure"
              />
            </Box>
            <MediumTypography
              textColor="#2A4241"
              fontweight={600}
              fontSize="18px"
              marginTop="16px"
              labelid={
                'ASQConcernQuestionnairesInfoSummary.FollowUpActionTitle'
              }
              defaultlabel="5. FOLLOW-UP ACTION: Check all that apply."
            />
            <Box sx={{ paddingLeft: '8px', paddingTop: '22px' }}>
              {
                <ASQFollowUpActionComponent
                  followUpActionQuestion={ASQActionQuestion}
                  onClickChange={onFollowUpActionClick}
                  handleTextInputChange={handleTextInputChange}
                  value={followUp}
                  textInputValue={followUp}
                  onOtherCommentValue={followUp}
                  handleOtherCommentChange={handleOtherCommentChange}
                />
              }
            </Box>
          </div>
        </Box>

        {/* Footer */}
        <Box sx={{ marginTop: '30px' }}>
          <Grid
            container
            direction="row"
            alignItems="right"
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Grid item sx={{ marginRight: '16px' }}>
              <ButtonComponent
                className="btn-primary btn-cancel"
                variantType="outlined"
                labelId="cancelText"
                defaultLabelId="Cancel"
                onClick={() => setShowAlertDialog(true)}
              />
            </Grid>
            <Grid item>
              <ButtonComponent
                className="btn-primary btn-submit"
                variantType="contained"
                labelId="QuestionnaireClientInfo.Submit"
                defaultLabelId="Submit"
                onClick={handleSubmit}
                disabled={
                  !checkPermissionForFeature(
                    'backend.clients',
                    'editPermission',
                  )
                }
              />
            </Grid>
          </Grid>
        </Box>
        {showAlertDialog && (
          <ModalPopup
            open={showAlertDialog}
            onCancel={() => {
              setShowAlertDialog(false);
            }}
            description="cancelWarningMessage"
            onOk={() => handleOk()}
            labelId1="Clientpage.cancelbtn"
            negativeActionLabel="cancelText"
            labelId2="Clientpage.Okbtn"
            positiveActionLabel="Ok"
          />
        )}
        {/* Footer */}
      </Box>
    </>
  );
};

export default ASQQuestionnairesInfoSummary;
