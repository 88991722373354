import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './Textinput.css';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormattedMessage } from 'react-intl';
import MediumTypography from './MediumTypography';
import { SxProps } from '@mui/system';
import { Box } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      scroll: 'hidden',
      maxWidth: '100px',
    },
  },
};
interface SelectComponentProps {
  SelectLabel?: string;
  name?: string;
  value?: string;
  handleChange?: (value: string) => void;
  names: string[] | number[];
  defaultValue?: string;
  disabled?: boolean;
  labelid?: string;
  defaultlabelid?: string;
  Required?: boolean;
  height?: string;
}

export default function SelectComponent(props: Readonly<SelectComponentProps>) {
  const {
    SelectLabel,
    name,
    value,
    handleChange,
    names,
    defaultValue,
    disabled,
    labelid,
    defaultlabelid,
    Required,
    height,
  } = props;
  const selectStyles: SxProps = {
    fontFamily: 'Lato-Regular',
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#00C6B8',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: !disabled ? '#00C6B8' : 'inherit',
    },
    height: height ?? '36px',
    backgroundColor: '#fff',
  };
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (disabled) {
      return;
    }
    setOpen(true);
  };
  return (
    <FormControl
      className="inputSelectFormLabel"
      sx={{
        width: '100%',
        left: '0px',
        m: 0,
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            border: '1px solid #00C6B8',
          },
        },
      }}
    >
      <InputLabel
        sx={{
          fontFamily: 'Lato-Regular',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '17px',
          color: '#97A6A5',
          '&.Mui-focused': {
            color: '#00C6B8',
          },
        }}
      >
        {labelid && (
          <FormattedMessage id={labelid} defaultMessage={defaultlabelid} />
        )}
        {Required && <span>*</span>}
      </InputLabel>
      <Select
        MenuProps={MenuProps}
        name={name}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={value}
        disabled={disabled}
        defaultValue={defaultValue}
        IconComponent={() => null}
        onChange={(event: SelectChangeEvent) => {
          if (handleChange !== undefined) {
            handleChange(event.target.value);
          }
        }}
        required={Required}
        sx={selectStyles}
        input={
          <OutlinedInput
            disabled={disabled}
            id="selectcomponentid"
            label={
              !labelid ? (
                SelectLabel
              ) : (
                <FormattedMessage
                  id={labelid}
                  defaultMessage={defaultlabelid}
                />
              )
            }
            endAdornment={
              <Box
                onClick={handleOpen}
                style={{
                  cursor: disabled ? 'not-allowed' : 'pointer',
                  marginRight: '-5px',
                  color: '#97a6a5',
                }}
              >
                <KeyboardArrowDownIcon className="verticalAlign_Middle" />
              </Box>
            }
            required={Required}
          />
        }
      >
        {names.map((item: string | number) => (
          <MenuItem
            key={item}
            value={item}
            defaultValue={item}
            sx={{
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              fontFamily: 'Lato-Regular',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '17px',
              whiteSpace: 'normal',
            }}
          >
            {typeof item == 'string' && <MediumTypography labelid={item} />}
            {typeof item == 'number' && item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
