import {
  AddNewClient,
  AddNewParent,
  AddNewReferral,
  ZipCodeType,
  InsuranceOtherDetails,
  ContactTypes,
  FamilyContactType,
  ProviderSpecialistType,
  SSPContactTypes,
  InsuranceContactType,
  OtherContactType,
  LeaSchoolContactType,
  GetClientsProgramsRequestType,
  GetClientProgramsResponseType,
  ProfilePicture,
  ReferralContactType,
} from '../../utils/type';
import { ApiResponseHandler } from '../ApiResponseHandler';
import ThomApiClient from '../ThomApiClient';

export type AddNewGuardian = {
  caregivers: AddNewParent[];
};

export interface ZipCodeResponse {
  totalItems: number;
  zipcodes: ZipCodeType[];
}

export type InsuranceResponse = {
  code: number;
  status: string;
  message: string;
};

export interface SiblingTypeResponse {
  id: string;
  name: string;
  dateOfBirth: string;
  school: string;
  grade: string;
  siblingType: string;
}

export type ContactsTypes =
  | FamilyContactType
  | ProviderSpecialistType
  | SSPContactTypes
  | InsuranceContactType
  | OtherContactType
  | LeaSchoolContactType
  | ReferralContactType;

export const addApplication = async (
  params: AddNewClient,
): Promise<AddNewClient> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<AddNewClient, AddNewClient>(
      `clients/demographics`,
      params,
    ),
  );
};

export const uploadProfilePic = async (
  clientId: string,
  file: File | null,
): Promise<ProfilePicture> => {
  const formData = new FormData();
  if (file != null) {
    formData.append('file', file);
  }
  let url;
  if (clientId === '') {
    url = 'client/uploadProfile';
  } else {
    url = `client/uploadProfile?clientID=${clientId}`;
  }
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(url, formData),
  );
};

export const deleteClientProfilePicture = async (clientId: string) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<AddNewGuardian>(
      `client/deleteProfile?clientId=${clientId}`,
    ),
  );
};

export const getClientsProgram = async (
  params: GetClientsProgramsRequestType,
): Promise<GetClientProgramsResponseType> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<GetClientProgramsResponseType>(
      `clients/program?`,
      params,
    ),
  );
};

export const addParentGuardian = async (id: string, params: AddNewGuardian) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<AddNewGuardian, AddNewGuardian>(
      `clients/guardian?clientId=${id}`,
      params,
    ),
  );
};

export const getZipCodeDetails = async (
  code: string,
  tbrApproved: boolean,
): Promise<ZipCodeResponse> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ZipCodeResponse>(
      `zipcode?pageNo=0&pageSize=10&zipCode=${code}&sort=zip_code&tbrApproved=${tbrApproved}`,
    ),
  );
};

export const getParentGuardians = async (
  clientId: string,
): Promise<AddNewGuardian> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<AddNewGuardian>(
      `clients/guardian?clientId=${clientId}`,
    ),
  );
};

export const updateParentGuardian = async (
  id: string,
  params: AddNewGuardian,
) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<AddNewGuardian, AddNewGuardian>(
      `clients/guardian?clientId=${id}`,
      params,
    ),
  );
};

export const deleteParentGuardian = async (
  careGiverIds: string,
): Promise<AddNewGuardian> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<AddNewGuardian>(
      `clients/guardian?careGiverIds=${careGiverIds}`,
    ),
  );
};

export const viewClientDetails = async (
  ClientId: string,
): Promise<AddNewClient> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<AddNewClient>(
      `clients/demographics?clientId=${ClientId}`,
    ),
  );
};

export const updateClientdata = async (
  ClientId: string,
  params: AddNewClient,
) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<AddNewClient, AddNewClient>(
      `clients/demographics?clientId=${ClientId}`,
      params,
    ),
  );
};

export const addOrUpdateReferral = async (
  body: AddNewReferral,
  createDuplicateClient: boolean,
) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<AddNewReferral, AddNewReferral>(
      `referral?createDuplicateClient=${createDuplicateClient}`,
      body,
    ),
  );
  // if (body.id === '') {
  //   return ApiResponseHandler(
  //     await ThomApiClient.getInstance().post<AddNewReferral, AddNewReferral>(
  //       `referral?createDuplicateClient=${createDuplicateClient}&&saveAsDraft=${saveAsDraft}`,
  //       body,
  //     ),
  //   );
  // } else {
  //   return ApiResponseHandler(
  //     await ThomApiClient.getInstance().put<AddNewReferral, AddNewReferral>(
  //       `referral?referralId=${body.id}&saveAsDraft=${saveAsDraft}`,
  //       body,
  //     ),
  //   );
  // }
};

export const getDraftById = async (
): Promise<AddNewReferral> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(`referral/draft`),
  );
};

export const addOrUpdateReferralDraft = async (
  body: AddNewReferral,
) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<AddNewReferral, AddNewReferral>(
      `/referral/draft`,
      body,
    ),
  );
};

export const createUpdateInsurance = async (
  ClientId: string,
  params: InsuranceOtherDetails,
) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<
      InsuranceOtherDetails,
      InsuranceResponse
    >(`clients/insurance?clientId=${ClientId}`, params),
  );
};

export const getInsuranceDetails = async (ClientId: string) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<InsuranceOtherDetails>(
      `clients/insurance?clientId=${ClientId}`,
    ),
  );
};

export const addContacts = async (id: string, params: ContactsTypes) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<ContactsTypes, InsuranceResponse>(
      `clients/contacts?clientId=${id}`,
      params,
    ),
  );
};

export const getAllContacts = async (ClientId: string) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ContactTypes[]>(
      `clients/contacts?clientId=${ClientId}`,
    ),
  );
};

export const getContactsBaedOnType = async (
  ClientId: string,
  contactId: string,
) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ContactsTypes[]>(
      `clients/contacts?clientId=${ClientId}&contactId=${contactId}`,
    ),
  );
};

export const updateContacts = async (id: string, params: ContactsTypes) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<ContactsTypes, InsuranceResponse>(
      `clients/contacts?contactId=${id}`,
      params,
    ),
  );
};

export const deleteContact = async (
  contactId: string,
): Promise<AddNewGuardian> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<AddNewGuardian>(
      `clients/contacts?contactId=${contactId}`,
    ),
  );
};

export const getAllContactSiblingsList = async (ClientId: string) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<SiblingTypeResponse[]>(
      `client/siblings?clientId=${ClientId}`,
    ),
  );
};
