import React, { useEffect, ReactNode, useRef, useState } from 'react';
import { FC } from 'react';
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google: any;
  }
}

interface TranslateProps {
  screenId: string;
  initialLanguage: string;
  languageChangeCallback: (language: string) => void;
  children: ReactNode;
}

const addGoogleTranslateScript = async (element: string) => {
  console.log('addGoogleTranslateScript trying to add');

  // Function to load the script and wait for it to load
  const loadScript = () => {
    return new Promise<void>((resolve, reject) => {
      const existingScript = document.getElementById('google_script');
      if (existingScript) {
        resolve();
      }

      const script = document.createElement('script');
      script.id = 'google_script';
      script.type = 'text/javascript';
      script.src =
        'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;
      script.defer = false;

      script.onload = async () => {
        console.log('Google Translate loaded');
        const waitForGoogleTranslate = async () => {
          while (
            !window.google ||
            !window.google.translate ||
            !window.google.translate.TranslateElement ||
            !window.google.translate.TranslateElement.InlineLayout ||
            !window.google.translate.TranslateElement.InlineLayout.SIMPLE
          ) {
            console.log('Google Translate loading...');
            await new Promise((resolve2) => setTimeout(resolve2, 100));
          }
        };

        await waitForGoogleTranslate();
        resolve();
      };

      script.onerror = () => {
        console.error('Google Translate failed to load');
        reject(new Error('Google Translate script failed to load'));
      };

      document.body.appendChild(script);
    });
  };

  try {
    await loadScript();
    console.log('addGoogleTranslateScript loaded');
    // Ensure Google Translate is available before using it
    if (
      window.google &&
      window.google.translate &&
      window.google.translate.TranslateElement &&
      window.google.translate.TranslateElement.InlineLayout &&
      window.google.translate.TranslateElement.InlineLayout.SIMPLE
    ) {
      const elementid = document.getElementById(element);
      if (!elementid) {
        throw new Error(`Element with ID ${elementid} not found`);
      }
      new window.google.translate.TranslateElement(
        {
          includedLanguages: 'en,ar,zh-CN,kea,ht,km,lo,pt,es,vi', // Specify the languages you want
          layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
          autoDisplay: false,
          multilanguagePage: true,
          autoDetect: true,
        },
        element,
      );
    } else {
      console.log('Google Translate API is not available');
    }
  } catch (error) {
    console.error('Error initializing Google Translate:', error);
  }
};

export const translate = async (element: string) => {
  await addGoogleTranslateScript(element);
};

export const removeTranslate = async () => {
  try {
    const script = document.getElementById('google_script');
    if (script && script.id === 'google_script') {
      document.cookie =
        'googtrans=; path=/; domain=' +
        window.location.hostname +
        '; expires=Thu, 01 Jan 1970 00:00:00 UTC;';

      document.body.removeChild(script);
      script.remove();
      window.location.reload();
    }
  } catch (error) {
    console.error('Google Translate:', error);
  }
};

export const setLanguage = (initialLanguage = 'en') => {
  let langPosition = 3;
  switch (initialLanguage) {
    case 'ar':
      langPosition = 1;
      break;
    case 'zh-CN':
      langPosition = 2;
      break;
    case 'en':
      langPosition = 3;
      break;
    case 'ht':
      langPosition = 4;
      break;
    case 'km':
      langPosition = 5;
      break;
    case 'lo':
      langPosition = 6;
      break;
    case 'pt':
      langPosition = 7;
      break;
    case 'es':
      langPosition = 8;
      break;
    case 'vi':
      langPosition = 9;
      break;
    default:
      langPosition = 3;
      break;
  }
  const iframe = document.querySelector(
    '.VIpgJd-ZVi9od-xl07Ob-OEVmcd',
  ) as HTMLIFrameElement;

  if (iframe) {
    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;

    if (iframeDoc) {
      const element = iframeDoc.querySelector(
        '#\\:1\\.menuBody > table > tbody > tr > td > a:nth-child(' +
          langPosition +
          ')',
      ) as HTMLAnchorElement | null;

      if (element) {
        element.click(); // Simulate click
      } else {
        console.log('Element not found inside iframe!');
      }
    } else {
      console.log('Unable to access iframe document!');
    }
  } else {
    console.log('Iframe not found!');
  }
};

export function isOtherLanguage() {
  if (document.documentElement.lang !== 'en') {
    return true;
  }
  return false;
}

// function to translate even the text area text
export const translateTextArea = (id: string) => {
  const element =
    (document.getElementById(id) as HTMLTextAreaElement) || HTMLInputElement;
  element.setAttribute('value', element.value);
};

const TranslateWidget: FC<TranslateProps> = ({
  children,
  screenId,
  initialLanguage,
  languageChangeCallback,
}) => {
  const [transLoading, setTransLoading] = useState(false);
  const translationId = useRef(screenId);
  useEffect(() => {
    console.log('translationId', translationId.current);
    const expires = new Date();
    expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);
    document.cookie =
      'googtrans' +
      '=' +
      '/auto/' +
      initialLanguage +
      ';expires=' +
      expires.toUTCString() +
      ';path=/;';

    let observer: MutationObserver;
    const translateLang = async () => {
      await translate(translationId.current);

      observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.attributeName === 'lang') {
            languageChangeCallback(document.documentElement.lang);

            const elementdiv = document.getElementById(
              translationId.current,
            ) as HTMLDivElement;
            const textareas = document.querySelectorAll(
              '#' + translationId.current + ' textarea',
            ) as NodeListOf<HTMLTextAreaElement>;
            if (elementdiv && isOtherLanguage()) {
              if (isOtherLanguage()) {
                textareas.forEach((textarea) => {
                  textarea.disabled = true;
                });
              }
            } else {
              textareas.forEach((textarea) => {
                textarea.disabled = false;
              });
            }

            const textfields = document.querySelectorAll(
              '#' + translationId.current + ' input',
            ) as NodeListOf<HTMLInputElement>;
            if (elementdiv && isOtherLanguage()) {
              if (isOtherLanguage()) {
                textfields.forEach((textfield) => {
                  textfield.disabled = true;
                });
              }
            } else {
              textfields.forEach((textfield) => {
                textfield.disabled = false;
              });
            }
          }
        });
      });

      observer.observe(document.documentElement, {
        attributes: true,
      });

      setTimeout(() => {
        setTransLoading(true);
      }, 5000);
    };

    setTimeout(() => {
      translateLang();
    }, 1000);

    return () => {
      try {
        observer?.disconnect();
        removeTranslate();
      } catch (err) {
        console.log(err);
      }
    };
  }, []);

  const handlefocus = () => {
    const backuptext = [] as string[];
    const elementdiv = document.getElementById(
      translationId.current,
    ) as HTMLDivElement;
    const textareas = document.querySelectorAll(
      '#' + translationId.current + ' textarea',
    ) as NodeListOf<HTMLTextAreaElement>;
    if (elementdiv) {
      textareas.forEach((textarea, index) => {
        backuptext[index] = textarea.value;
      });
    }
    setTimeout(() => {
      if (document.visibilityState === 'visible') {
        const textareastemp = document.querySelectorAll(
          '#' + translationId.current + ' textarea',
        ) as NodeListOf<HTMLTextAreaElement>;
        if (elementdiv) {
          textareastemp.forEach((textarea, index) => {
            textarea.value = backuptext[index];
          });
        }
      }
    }, 1000);
  };

  useEffect(() => {
    window.addEventListener('visibilitychange', handlefocus);
    return () => {
      window.removeEventListener('visibilitychange', handlefocus);
    };
  }, []);

  useEffect(() => {
    console.log('transLoading', transLoading);
  }, [transLoading]);

  return (
    <div
      id={translationId.current}
      className="translate"
      key={translationId.current}
    >
      {children}
    </div>
  );
};

export default TranslateWidget;
