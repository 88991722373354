import React, { FC, useEffect, useState } from 'react';
import {
  AlertColor,
  Box,
  Dialog,
  DialogContent,
  Grid,
  SxProps,
  Tooltip,
} from '@mui/material';
import { LoaderContext, LoaderContextType } from '../../../layouts/AppSidebar';
import { getAllContacts } from '../../../services/configApi/User';
import { ContactTypes } from '../../../utils/type';
import { ApiError, isCustomError } from '../../../services/ApiResponseHandler';
import SnackBarComponent from '../SnackBarComponent';
import MediumTypography from '../MediumTypography';
import CheckBoxComponent from '../CheckBoxComponent';
import dayjs from 'dayjs';
import { ReactComponent as CloseIcon } from '../../../assets/images/x.svg';
import ButtonComponent from '../ButtonComponent';
import {
  shareOption,
  shareOptionConsent,
  shareOptionEics,
  shareOptionIFSP,
  shareOptionProgressNote,
} from '../../../services/configApi/notification/notification';
import { ConsentListData } from '../../../services/configApi/forms/Consent/ConsentServices';

interface ShareModalProps {
  modalVisible: boolean;
  closeModal: () => void;
  consentData?: ConsentListData;
  screenName: string;
  attendeeId?: string;
  commonAppointmentId?: string;
}

export interface Id {
  contactId: string[];
}

const ShareDownloadModal: FC<ShareModalProps> = ({
  modalVisible,
  closeModal,
  consentData,
  screenName,
  attendeeId,
  commonAppointmentId,
}) => {
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [contacts, setContacts] = React.useState<ContactTypes[]>([]);
  const [contactsLength, setContactsLength] = React.useState<number>();
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrId, setToastrId] = React.useState('');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [checkedIds, setCheckedIds] = useState<Id>({ contactId: [] });

  useEffect(() => {
    getAllContactsAPI();
  }, []);

  const getAllContactsAPI = () => {
    const clientId: string = localStorage.getItem('ClientId') ?? '';
    toggleLoader(true);
    getAllContacts(clientId)
      .then((response: ContactTypes[]) => {
        toggleLoader(false);
        const contactsWithEmail = response.filter(
          (obj) => obj.email && obj.isActive,
        );
        setContacts(contactsWithEmail);
        setContactsLength(contactsWithEmail.length);
      })
      .catch((error) => {
        toggleLoader(false);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('Family.gettoastrError');
          setToastrDefaultMessage('Failed to get contact details');
        }
      });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCheckboxChange = (itemId: string, checked: boolean) => {
    setCheckedIds((prevCheckedIds) => {
      const { contactId } = prevCheckedIds;

      if (checked) {
        return { contactId: [...contactId, itemId] };
      } else {
        return {
          contactId: contactId.filter((id: string) => id !== itemId),
        };
      }
    });
  };

  const handleShare = () => {
    const localTimezone = dayjs.tz.guess();
    toggleLoader(true);
    if (screenName === 'intake') {
      shareOption(localStorage.getItem('ClientId'), localTimezone, checkedIds)
        .then((response) => {
          toggleLoader(false);
          setOpen(true);
          setToastrId(response.message);
          setToastrDefaultMessage(response.message);
          setToastrVariable('success');
          setTimeout(() => {
            closeModal();
          }, 1000);
        })
        .catch((error) => {
          setOpen(true);
          setToastrVariable('error');
          setToastrId('somethingWrongError');
          setToastrDefaultMessage(error);
          toggleLoader(false);
        });
    } else if (screenName === 'consent') {
      shareOptionConsent(
        localStorage.getItem('ClientId'),
        localTimezone,
        checkedIds,
        consentData?.consentType,
        consentData?.consentId,
      )
        .then((response) => {
          toggleLoader(false);
          setOpen(true);
          setToastrId(response.message);
          setToastrDefaultMessage(response.message);
          setToastrVariable('success');
          setTimeout(() => {
            closeModal();
          }, 1000);
        })
        .catch((error) => {
          setOpen(true);
          setToastrVariable('error');
          setToastrId('somethingWrongError');
          setToastrDefaultMessage(error);
          toggleLoader(false);
        });
    } else if (screenName === 'eics') {
      shareOptionEics(
        localStorage.getItem('ClientId'),
        localTimezone,
        checkedIds,
      )
        .then((response) => {
          toggleLoader(false);
          setOpen(true);
          setToastrId(response.message);
          setToastrDefaultMessage(response.message);
          setToastrVariable('success');
          setTimeout(() => {
            closeModal();
          }, 1000);
        })
        .catch((error) => {
          setOpen(true);
          setToastrVariable('error');
          setToastrId('somethingWrongError');
          setToastrDefaultMessage(error);
          toggleLoader(false);
        });
    } else if (screenName === 'progressNote') {
      shareOptionProgressNote(
        localStorage.getItem('ClientId'),
        localTimezone,
        checkedIds,
        attendeeId,
        commonAppointmentId,
      )
        .then((response) => {
          toggleLoader(false);
          setOpen(true);
          setToastrId(response.message);
          setToastrDefaultMessage(response.message);
          setToastrVariable('success');
          setTimeout(() => {
            closeModal();
          }, 1000);
        })
        .catch((error) => {
          setOpen(true);
          setToastrVariable('error');
          setToastrId('somethingWrongError');
          setToastrDefaultMessage(error);
          toggleLoader(false);
        });
    } else if (screenName === 'IFSP') {
      shareOptionIFSP(
        localStorage.getItem('ClientId'),
        localTimezone,
        checkedIds,
      )
        .then((response) => {
          toggleLoader(false);
          setOpen(true);
          setToastrId(response.message);
          setToastrDefaultMessage(response.message);
          setToastrVariable('success');
          setTimeout(() => {
            closeModal();
          }, 1000);
        })
        .catch((error) => {
          setOpen(true);
          setToastrVariable('error');
          setToastrId('somethingWrongError');
          setToastrDefaultMessage(error);
          toggleLoader(false);
        });
    }
  };

  const getContactName = (firstName: string, lastName: string) => {
    return [firstName, lastName].filter(Boolean).join(' ');
  };

  const getReferralContactName = (firstName: string, lastName: string) => {
    if (firstName || lastName) {
      return [firstName, lastName].filter(Boolean).join(' ');
    } else {
      return 'Referral Contact';
    }
  };

  const ellipsisCellSX: SxProps = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    zIndex: 9999,
  };

  return (
    <Box component="div">
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Dialog
        open={modalVisible}
        keepMounted
        onClose={() => ({})}
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="p-md">
          <Box
            component="div"
            className="rowContainer2 flex__justify__space-between"
          >
            <Box
              component="div"
              sx={{ whiteSpace: 'nowrap' }}
              className="mb-xs"
            >
              <MediumTypography
                labelid="Contacts"
                defaultlabel="Contacts"
                textColor="#2a4241"
                fontweight={600}
                fontSize="16px"
              />
            </Box>
            <Box component="div" className="ml-xs" onClick={closeModal}>
              <CloseIcon
                style={{
                  position: 'relative',
                  cursor: 'pointer',
                  top: '10%',
                }}
              />
            </Box>
          </Box>
          {contacts.length > 0 &&
            contacts.map((data) => (
              <Box key={data.contactId}>
                <Grid container className="align__items__center">
                  <Grid item xs={1} lg={1} className="mb-xs">
                    <CheckBoxComponent
                      name="defaultProgram"
                      labelid=""
                      defaultlabelid=""
                      fontWeight={400}
                      fontFamily="Lato-Regular"
                      ischecked={checkedIds.contactId.some(
                        (id) => id === data.contactId,
                      )}
                      CheckHandleChange={(e) =>
                        handleCheckboxChange(data.contactId, e.target.checked)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    lg={10}
                    sx={{
                      overflow: 'hidden',
                      textWrap: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <MediumTypography
                      label={
                        data.contactType === 'Referral Contact'
                          ? getReferralContactName(
                              data.firstName,
                              data.lastName,
                            )
                          : getContactName(data.firstName, data.lastName)
                      }
                      textColor="#2a4241"
                      marginTop="8px"
                      fontweight={500}
                      sxProps={{ ...ellipsisCellSX }}
                    />
                    <Tooltip id="tool-tip" title={data.email} placement="top">
                      <Box>
                        <MediumTypography
                          className="mt-none pt-none"
                          labelid={data.email}
                          defaultlabel={data.email}
                          textColor="#97A6A5"
                          marginTop="6px"
                          fontweight={500}
                          sxProps={{ ...ellipsisCellSX }}
                        />
                      </Box>
                    </Tooltip>
                  </Grid>
                  <Box className="horizontalLine mt-none mb-none mt-xs" />{' '}
                </Grid>
              </Box>
            ))}
          {contactsLength == 0 && (
            <MediumTypography
              labelid="ConsentForms.ContactsLink"
              defaultlabel="There are no Contacts which have email address linked to them"
              textColor="#2a4241"
              fontweight={500}
              fontSize="12px"
            />
          )}
          <Box>
            <Grid
              className="flex__ justifyContent-FlexEnd pt-md"
              container
              direction="row"
              alignItems="right"
            >
              <Grid item>
                <ButtonComponent
                  className="btn-primary btn-cancel mr-md"
                  variantType="contained"
                  defaultLabelId="Cancel"
                  labelId="Contacts.cancelbtn"
                  onClick={() => closeModal()}
                />

                <ButtonComponent
                  className="btn-primary btn-submit"
                  variantType="contained"
                  type="submit"
                  labelId="ConsentForms.listActionShareToolTipLabel"
                  defaultLabelId="Share"
                  disabled={checkedIds.contactId.length === 0}
                  onClick={() => handleShare()}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ShareDownloadModal;
