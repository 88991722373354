import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as Edit } from '../../assets/images/highlight.svg';
import { Box, Button, Grid, Paper, Tooltip } from '@mui/material';
import { ReactComponent as ProfileIcon } from '../../assets/images/profileIcon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone.svg';
import { ReactComponent as MailIcon } from '../../assets/images/mail.svg';
import { ReactComponent as DocIcon } from '../../assets/images/decIcon.svg';
import { useEffect, useState } from 'react';
import {
  Employee,
  LocalEmployeeCredentials,
  LocalProgram,
  getEmployeeByIdForProfile,
} from '../../services/configApi/employees/employeeServices';
import { STORAGE_USER_ID_KEY } from '../../services/Constant';
import { getFullName, getRoleName } from '../../utils/nameUtils';
import MediumTypography from '../../components/formlib/MediumTypography';
import './Profile.css';
import { EDIT_EMPLOYEE_ROUTE_NAME } from '../../routes/Routing';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import FilePreviewDialog from '../../components/formlib/modal/FilePreviewDialog';
import { isPointingToUAT, isSuperSuperAdmin } from '../../utils/utilities';

export default function ProfileInfo() {
  const navigate = useNavigate();
  const [showContainer, setShowContainer] = useState(false);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [employee, setEmployee] = useState<Employee>();
  const [addedToGroup, setAddedToGroup] = useState(false);

  const toggleContainer = () => {
    setShowContainer(!showContainer);
  };

  useEffect(() => {
    const employeeId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (employeeId !== null) {
      getEmployeeByIdForProfile(employeeId)
        .then((response: Employee) => {
          if (response?.group?.id !== null) {
            setAddedToGroup(true);
          } else {
            setAddedToGroup(false);
          }
          setEmployee(response);
        })
        .catch(() => {});
    }
  }, []);

  const getProgramDates = (program: LocalProgram) => {
    let programDates = '';
    if (program.startDate !== null) {
      programDates = program.startDate;
    }
    if (program.endDate !== null) {
      programDates = programDates + ' - ' + program.endDate;
    }
    return programDates;
  };

  const getprimaryAddress = (employeeAddress: {
    primaryAddress1: string | null;
    primaryAddress2: string | null;
    primaryCity: string | null;
    primaryZipcode: string | null;
    primaryState: string | null;
  }) => {
    let fullPrimaryAddress = '';
    if (employeeAddress.primaryAddress1 !== null) {
      fullPrimaryAddress = employeeAddress.primaryAddress1.trim();
    }
    if (
      employeeAddress.primaryAddress2 !== null &&
      employeeAddress.primaryAddress2 !== ''
    ) {
      fullPrimaryAddress =
        fullPrimaryAddress + ', ' + employeeAddress.primaryAddress2;
    }

    if (
      employeeAddress.primaryCity !== null &&
      employeeAddress.primaryCity !== ''
    ) {
      fullPrimaryAddress =
        fullPrimaryAddress + ', ' + employeeAddress.primaryCity;
    }
    if (
      employeeAddress.primaryZipcode !== null &&
      employeeAddress.primaryZipcode !== ''
    ) {
      fullPrimaryAddress =
        fullPrimaryAddress + ', ' + employeeAddress.primaryZipcode;
    }
    if (
      employeeAddress.primaryState !== null &&
      employeeAddress.primaryState !== ''
    ) {
      fullPrimaryAddress =
        fullPrimaryAddress + ', ' + employeeAddress.primaryState;
    }
    return fullPrimaryAddress;
  };

  const getCredentialStatus = (credentials: LocalEmployeeCredentials) => {
    if (credentials.credentialExpirationDate === null) {
      return true;
    }
    const expirationDate = dayjs(credentials.credentialExpirationDate);
    return expirationDate.isBefore(dayjs());
  };

  const getsecondaryAddress = (employeeAddress: {
    secondaryAddress1: string | null;
    secondaryAddress2: string | null;
    secondaryCity: string | null;
    secondaryZipcode: string | null;
    secondaryState: string | null;
  }) => {
    let fullsecondaryAddress = '';
    if (employeeAddress.secondaryAddress1 !== null) {
      fullsecondaryAddress = employeeAddress.secondaryAddress1;
    }
    if (
      employeeAddress.secondaryAddress2 !== null &&
      employeeAddress.secondaryAddress2 !== ''
    ) {
      fullsecondaryAddress =
        fullsecondaryAddress + ',' + employeeAddress.secondaryAddress2;
    }

    if (
      employeeAddress.secondaryCity !== null &&
      employeeAddress.secondaryCity !== ''
    ) {
      fullsecondaryAddress =
        fullsecondaryAddress + ',' + employeeAddress.secondaryCity;
    }
    if (
      employeeAddress.secondaryZipcode !== null &&
      employeeAddress.secondaryZipcode !== ''
    ) {
      fullsecondaryAddress =
        fullsecondaryAddress + ',' + employeeAddress.secondaryZipcode;
    }
    if (
      employeeAddress.secondaryState !== null &&
      employeeAddress.secondaryState !== ''
    ) {
      fullsecondaryAddress =
        fullsecondaryAddress + ',' + employeeAddress.secondaryState;
    }
    return fullsecondaryAddress;
  };

  const getEmergencydetails = (employeeDetail: {
    emergencyFirstName: string | null;
    emergencyLastName: string | null;
  }) => {
    let fullEmergencydetails = '';
    if (employeeDetail.emergencyFirstName !== null) {
      fullEmergencydetails = employeeDetail.emergencyFirstName;
    }

    if (employeeDetail.emergencyLastName !== null) {
      fullEmergencydetails =
        fullEmergencydetails + ' ' + employeeDetail.emergencyLastName;
    }
    return fullEmergencydetails;
  };

  if (employee === undefined) {
    return null;
  }

  const getEmergencyAddress = (employeeAddress: {
    emergencyAddress1: string | null;
    emergencyAddress2: string | null;
    emergencyCity: string | null;
    emergencyZipcode: string | null;
    emergencyState: string | null;
  }) => {
    let fullEmergencyAddress = '';
    if (employeeAddress.emergencyAddress1 !== null) {
      fullEmergencyAddress = employeeAddress.emergencyAddress1;
    }
    if (
      employeeAddress.emergencyAddress2 !== null &&
      employeeAddress.emergencyAddress2 !== ''
    ) {
      fullEmergencyAddress =
        fullEmergencyAddress + ', ' + employeeAddress.emergencyAddress2;
    }
    if (
      employeeAddress.emergencyCity !== null &&
      employeeAddress.emergencyCity !== ''
    ) {
      fullEmergencyAddress =
        fullEmergencyAddress + ', ' + employeeAddress.emergencyCity;
    }
    if (
      employeeAddress.emergencyZipcode !== null &&
      employeeAddress.emergencyZipcode !== ''
    ) {
      fullEmergencyAddress =
        fullEmergencyAddress + ', ' + employeeAddress.emergencyZipcode;
    }
    if (
      employeeAddress.emergencyState !== null &&
      employeeAddress.emergencyState !== ''
    ) {
      fullEmergencyAddress =
        fullEmergencyAddress + ', ' + employeeAddress.emergencyState;
    }
    return fullEmergencyAddress;
  };
  const statusClassName =
    employee.status === 'Active' ? 'activeDot ' : 'inActiveDot';

  const handleButtonClick = (employeeDetails: Employee) => {
    navigate(EDIT_EMPLOYEE_ROUTE_NAME, {
      state: {
        employee: employeeDetails,
        screenFrom: 'profileInfo',
      },
    });
  };

  return (
    <>
      {showFilePreview &&
        employee.employeeCredentials !== null &&
        employee.employeeCredentials.credentialFileUrl !== null && (
          <FilePreviewDialog
            url={employee.employeeCredentials.credentialFileUrl}
            fileName={employee.employeeCredentials.credentialFileUrl
              .split('?')[0]
              .split('/')
              .pop()}
            onClose={() => {
              setShowFilePreview(false);
            }}
          />
        )}
      <Box className="page-background">
        <Card>
          <CardHeader
            sx={{ padding: '10px', marginRight: '10px' }}
            action={
              <Tooltip title="Edit">
                <Box
                  sx={{
                    display:
                      isSuperSuperAdmin() ||
                      (isPointingToUAT() &&
                        (employee.superProgramId === '' ||
                          employee.superProgramId === null)) ||
                      (isPointingToUAT() &&
                        employee.superProgramId ===
                          JSON.parse(
                            localStorage.getItem('selectedProgram') || '{}',
                          ).id) ||
                      (employee.superProgramId === '' && addedToGroup) ||
                      (employee.superProgramId === null && addedToGroup) ||
                      (employee.superProgramId ===
                        JSON.parse(
                          localStorage.getItem('selectedProgram') || '{}',
                        ).id &&
                        addedToGroup)
                        ? 'flex'
                        : 'none',
                    cursor: 'pointer',
                    paddingRight: '10px',
                  }}
                >
                  <IconButton
                    onClick={() => {
                      handleButtonClick(employee);
                    }}
                  >
                    <Edit aria-label="Edit" />
                  </IconButton>

                  <MediumTypography
                    labelid="editText"
                    sxProps={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#97A6A5',
                      paddingTop: '6px',
                    }}
                    onClick={() => {
                      handleButtonClick(employee);
                    }}
                  />
                </Box>
              </Tooltip>
            }
          />
          <CardMedia sx={{ textAlign: 'center' }}>
            <ProfileIcon
              style={{
                width: '93px',
                height: '93px',
              }}
              title="Profile Image"
            />
          </CardMedia>
          <CardContent sx={{ textAlign: 'center', padding: '5px 24px' }}>
            <MediumTypography
              label={getFullName(employee.firstName, employee.lastName)}
              sxProps={{
                fontSize: '18px',
                fontWeight: '700',
                color: '#2A4241',
              }}
            />

            <Box justifyContent={'center'}>
              <MediumTypography
                label={
                  employee.employeeRole === null
                    ? ''
                    : getRoleName(employee.employeeRole)
                }
                sxProps={{
                  fontSize: '12px',
                  fontWeight: '400',
                  marginRight: '5px',
                }}
              />
            </Box>
            <Box
              sx={{ display: 'flex', paddingTop: '5px' }}
              justifyContent={'center'}
            >
              <Box
                className={statusClassName}
                marginRight={1}
                sx={{ textAlign: 'center', marginTop: '5px' }}
              ></Box>
              <MediumTypography
                label={employee.status === null ? '' : employee.status}
                sxProps={{ fontSize: '12px', fontWeight: '400' }}
              />
            </Box>
          </CardContent>
          <CardContent sx={{ textAlign: 'center', padding: '16px 24px' }}>
            <Box
              sx={{ display: 'flex' }}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <PhoneIcon style={{ marginRight: '8px' }} />
              <MediumTypography
                label={
                  employee.phone1 === null
                    ? ''
                    : `+${employee.phoneNumberExtension} - ${employee.phone1}`
                }
                sxProps={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#2A4241',
                  lineHeight: '20px',
                }}
              />
            </Box>

            <Box
              sx={{ display: 'flex' }}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Box>
                <MailIcon style={{ marginTop: '9px' }} />
              </Box>
              <Tooltip
                title={employee.email !== null ? employee.email : ''}
                placement="bottom"
                arrow
              >
                <Box>
                  <MediumTypography
                    className="ellipsis_txt_profile pl-sm"
                    sxProps={{
                      color: '#2A4241',
                      wordBreak: 'break-all',
                      lineHeight: '20px',
                      maxWidth: '250px',
                    }}
                    label={employee.email !== null ? employee.email : ''}
                  />
                </Box>
              </Tooltip>
            </Box>
          </CardContent>

          <CardContent sx={{ textAlign: 'center', padding: '16px 24px' }}>
            <MediumTypography
              labelid="primaryAddressText"
              defaultlabel="Address 1(primary)"
              sxProps={{
                fontSize: '12px',
                fontWeight: '700',
                color: '#97A6A5',
                lineHeight: '20px',
                marginBottom: '3px',
              }}
            />
            <MediumTypography
              sxProps={{
                fontSize: '14px',
                fontWeight: '400',
                color: '#2A4241',
                lineHeight: '20px',
                wordBreak: 'break-all',
              }}
              label={getprimaryAddress(employee)}
            />
          </CardContent>
          {showContainer && (
            <Box>
              {getsecondaryAddress(employee) !== '' &&
                getsecondaryAddress(employee) !== null && (
                  <CardContent
                    sx={{ textAlign: 'center', padding: '16px 24px' }}
                  >
                    <Box>
                      <MediumTypography
                        labelid="secondaryAddressText"
                        defaultlabel="Secondary Address"
                        sxProps={{
                          fontSize: '12px',
                          fontWeight: '700',
                          color: '#97A6A5',
                          lineHeight: '20px',
                          marginBottom: '3px',
                        }}
                      />

                      <MediumTypography
                        label={getsecondaryAddress(employee)}
                        sxProps={{
                          fontSize: '14px',
                          fontWeight: '400',
                          lineHeight: '20px',
                          wordBreak: 'break-all',
                        }}
                      />
                    </Box>
                  </CardContent>
                )}

              <CardContent sx={{ textAlign: 'center', padding: '16px 24px' }}>
                <MediumTypography
                  labelid="emergencyContactText"
                  defaultlabel="Emergency contact"
                  sxProps={{
                    fontSize: '12px',
                    fontWeight: '700',
                    color: '#97A6A5',
                    lineHeight: '20px',
                    marginBottom: '3px',
                  }}
                />

                {employee.emergencyFirstName ? (
                  <MediumTypography
                    label={getEmergencydetails(employee)}
                    sxProps={{
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '20px',
                      wordBreak: 'break-all',
                    }}
                  />
                ) : (
                  '-'
                )}

                {employee.emergencyPhone && (
                  <Box
                    sx={{ display: 'flex' }}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <PhoneIcon style={{ marginRight: '8px' }} />

                    <MediumTypography
                      label={
                        employee.emergencyPhone === null
                          ? ''
                          : employee.emergencyPhone
                      }
                      sxProps={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: '#2A4241',
                        lineHeight: '20px',
                      }}
                    />
                  </Box>
                )}

                {employee.emergencyEmail && (
                  <Box
                    sx={{ display: 'flex' }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <MailIcon
                        style={{ marginTop: '9px', marginRight: '8px' }}
                      />
                    </Box>
                    {employee.emergencyEmail &&
                    employee.emergencyEmail.length > 20 ? (
                      <Tooltip
                        title={employee.emergencyEmail}
                        placement="bottom"
                        arrow
                      >
                        <Box>
                          <MediumTypography
                            className="ellipsis_txt_profile"
                            sxProps={{
                              color: '#2A4241',
                              wordBreak: 'break-all',
                              lineHeight: '20px',
                              maxWidth: '250px',
                            }}
                            label={employee.emergencyEmail}
                          />
                        </Box>
                      </Tooltip>
                    ) : (
                      <Box>
                        <Tooltip
                          title={employee.emergencyEmail}
                          placement="bottom"
                          arrow
                        >
                          <MediumTypography
                            className="ellipsis_txt_profile"
                            sxProps={{
                              color: '#2A4241',
                              wordBreak: 'break-all',
                              lineHeight: '20px',
                              maxWidth: '250px',
                            }}
                            label={
                              employee.emergencyEmail !== null
                                ? employee.emergencyEmail
                                : ''
                            }
                          />
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                )}

                {employee.emergencyAddress1 && (
                  <MediumTypography
                    label={getEmergencyAddress(employee)}
                    sxProps={{
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '20px',
                      wordBreak: 'break-all',
                    }}
                  />
                )}
              </CardContent>
            </Box>
          )}
          <CardContent sx={{ textAlign: 'center', padding: '16px 24px' }}>
            <MediumTypography
              label={showContainer ? '- Less' : '+ More'}
              onClick={toggleContainer}
              sxProps={{
                color: '#008C82',
                fontWeight: '600',
                fontSize: '14px',
                cursor: 'pointer',
              }}
            />
          </CardContent>
          <CardContent sx={{ textAlign: 'center' }}>
            <MediumTypography
              labelid="employmentPayrollInformationText"
              defaultlabel="Employment & Payroll Information"
              sxProps={{
                fontSize: '12px',
                fontWeight: '700',
                color: '#97A6A5',
                lineHeight: '14px',
                marginBottom: '15px',
              }}
            />

            <Paper
              elevation={2}
              sx={{
                width: '100%',
                padding: '24px',
                border: '1px solid #CFDEDE',
                boxShadow: 'none',
              }}
            >
              <Grid container sx={{ mb: 1 }}>
                <Grid item md={7}>
                  <MediumTypography
                    labelid="programPermissionsText"
                    defaultlabel="Program Permissions"
                    sxProps={{
                      color: '#2A4241',
                      lineHeight: '20.44px',
                      textAlign: 'left',
                    }}
                  />
                </Grid>
                <Grid item md={5}>
                  <MediumTypography
                    sxProps={{
                      color: '#2A4241',
                      fontWeight: '700',
                      textAlign: 'left',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 1 }}>
                <Grid item md={7}>
                  <MediumTypography
                    labelid="startDate"
                    defaultlabel="Start Date"
                    sxProps={{
                      color: '#2A4241',
                      lineHeight: '20.44px',
                      textAlign: 'left',
                    }}
                  />
                </Grid>
                <Grid item md={5}>
                  <MediumTypography
                    sxProps={{
                      color: '#2A4241',
                      fontWeight: '700',
                      textAlign: 'left',
                      wordBreak: 'break-all',
                    }}
                    label={
                      employee.startDate === null ? '' : employee.startDate
                    }
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 1 }}>
                <Grid item md={7}>
                  <MediumTypography
                    labelid="employeeTextColon"
                    defaultlabel="Employee Type"
                    sxProps={{
                      color: '#2A4241',
                      lineHeight: '20.44px',
                      textAlign: 'left',
                    }}
                  />
                </Grid>
                <Grid item md={5}>
                  <MediumTypography
                    sxProps={{
                      color: '#2A4241',
                      fontWeight: '700',
                      textAlign: 'left',
                      wordBreak: 'break-word',
                    }}
                    label={
                      employee.employeeType === null
                        ? ''
                        : employee.employeeType
                    }
                  />
                </Grid>
              </Grid>

              {employee.employeeCredentials !== null && (
                <Grid container sx={{ mb: 1 }}>
                  <Grid item md={7}>
                    <MediumTypography
                      labelid="licenseStatusText"
                      defaultlabel="License Status"
                      sxProps={{
                        color: '#2A4241',
                        lineHeight: '20.44px',
                        textAlign: 'left',
                      }}
                    />
                  </Grid>
                  <Grid item md={5}>
                    {employee.employeeCredentials !== null &&
                      employee.employeeCredentials.credentialLicenseNumber !==
                        null && (
                        <Box display={'flex'} alignContent={'center'}>
                          <MediumTypography
                            sxProps={{
                              color: getCredentialStatus(
                                employee.employeeCredentials,
                              )
                                ? '#EB4C60'
                                : '#2A4241',
                              fontWeight: '700',
                              textAlign: 'left',
                              wordBreak: 'break-all',
                            }}
                            label={
                              getCredentialStatus(employee.employeeCredentials)
                                ? 'Expired'
                                : 'Active'
                            }
                          />
                          {employee.employeeCredentials.credentialFileUrl !==
                            null && (
                            <Box
                              sx={{ marginLeft: '8px', cursor: 'pointer' }}
                              onClick={() => {
                                setShowFilePreview(true);
                              }}
                            >
                              <DocIcon />
                            </Box>
                          )}
                        </Box>
                      )}
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={2}>
                <Grid item md={12} mt={2} display="none">
                  <Button
                    sx={{
                      backgroundColor: '#008C82',
                      fontFamily: 'Lato-Regular',
                      fontWeight: 500,
                      width: '100%',
                      '&:hover': {
                        background: '#008C82',
                        color: '#FFF',
                      },
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                  >
                    View My Payroll
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </CardContent>

          <CardContent sx={{ textAlign: 'center' }}>
            <MediumTypography
              labelid="programInformationsText"
              defaultlabel="Program Information"
              sxProps={{
                fontSize: '12px',
                fontWeight: '700',
                color: '#97A6A5',
                lineHeight: '14px',
                marginBottom: '15px',
              }}
            />

            <Paper
              elevation={2}
              sx={{
                width: '100%',
                padding: '24px',
                border: '1px solid #CFDEDE',
                boxShadow: 'none',
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={12}>
                  {employee.programs &&
                    employee.programs.length > 0 &&
                    employee.programs.map((program) => {
                      return (
                        <Box display={'flex'}>
                          <Grid
                            container
                            spacing={5}
                            justifyContent={'center'}
                            sx={{
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Grid item md={2}>
                              <MediumTypography
                                label={
                                  program.acronym !== null
                                    ? program.acronym
                                    : ''
                                }
                                defaultlabel="Program Informations"
                                sxProps={{
                                  fontSize: '14px',
                                  fontWeight: '600',
                                  color: '#008C82',
                                  lineHeight: '26px',
                                  textAlign: 'left',
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={10}
                              sx={{
                                textAlign: 'left',
                              }}
                            >
                              <MediumTypography
                                sxProps={{
                                  color: '#2A4241',
                                  fontWeight: '400',
                                  lineHeight: '26px',
                                }}
                                label={' - ' + getProgramDates(program)}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      );
                    })}
                </Grid>
              </Grid>
            </Paper>
          </CardContent>
        </Card>
        {/*  code need to next develop  */}
        {/* <Card
          sx={{
            maxWidth: 345,
            marginTop: '30px',
          }}
        >
          <CardContent sx={{ textAlign: 'center', paddingTop: '16px 24px' }}>
            <MediumTypography
              labelid="aboutProfileinfoText"
              defaultlabel="About"
              sxProps={{
                fontSize: '12px',
                fontWeight: '700',
                color: '#97A6A5',
                lineHeight: '14px',
                marginBottom: '15px',
              }}
            />

            <MediumTypography
              label="Lorem ipsum dolor sit amet, consectetur adipiscing elit,
 sed do eiusmod tLorem ipsum dolor sit amet, consectetur"
              sxProps={{
                fontSize: '14px',
                fontWeight: '400',
              }}
            />
          </CardContent>
        </Card> */}
      </Box>
    </>
  );
}
