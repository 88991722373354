import {
  Box,
  SxProps,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { Order } from '../../utils/type';
import { visuallyHidden } from '@mui/utils';
import MediumTypography from './MediumTypography';
import CheckBox from '../../components/formlib/CustomCheckBox';

interface EnhancedTableProps<T> {
  onRequestSort?: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order?: Order;
  orderBy?: string;
  headerNames: HeadCell<T>[];
  checkBoxRequired: boolean;
  labelSxProp?: SxProps;
  className?: string;
}

export interface HeadCell<T> {
  id: keyof T;
  labelId: string;
  defaultLabelId: string;
  numeric: boolean;
  requiredSorting: boolean;
}

export const TableHeader = <T,>(props: EnhancedTableProps<T>) => {
  const {
    order,
    orderBy,
    onRequestSort,
    headerNames,
    checkBoxRequired,
    labelSxProp,
    className,
  } = props;

  const createSortHandler =
    (property: keyof T, requiredSort: boolean) =>
    (event: React.MouseEvent<unknown>) => {
      if (requiredSort && onRequestSort) {
        onRequestSort(event, property);
      }
    };

  return (
    <TableHead className={className}>
      <TableRow>
        {checkBoxRequired && (
          <TableCell padding="checkbox">
            <CheckBox value={false} />
          </TableCell>
        )}
        {headerNames.map((cell: HeadCell<T>, index) =>
          cell.id === 'empty' ? (
            <TableCell
              key={cell.labelId}
              sx={{
                backgroundColor: 'transparent !important',
                borderBottom: 'unset',
                borderLeft: index === 0 ? '1px solid #00C6B8' : '0px',
                marginRight: index === 0 ? '20px' : '0px',
              }}
            />
          ) : (
            <TableCell
              key={cell.labelId}
              align={cell.numeric ? 'right' : 'left'}
              padding="none"
              sx={{
                paddingTop: '8px',
                paddingBottom: '8px',
                verticalAlign: 'top',
              }}
            >
              <TableSortLabel
                sx={{
                  fontFamily: 'Lato-Bold',
                  cursor: cell.requiredSorting ? 'pointer' : 'default',
                }}
                active={orderBy === cell.id}
                direction={order}
                onClick={createSortHandler(cell.id, cell.requiredSorting)}
                hideSortIcon={!cell.requiredSorting}
              >
                <MediumTypography
                  sxProps={{
                    fontFamily: 'Lato-Bold',
                    color: '#97A6A5',
                    whiteSpace: 'nowrap',
                    // padding: '8px',
                    ...labelSxProp,
                  }}
                  labelid={cell.labelId}
                  defaultlabel={cell.defaultLabelId}
                />
                {orderBy === cell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
};
