import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Formik, FormikProps } from 'formik';
import { OptionType, ReferralContactType } from '../../utils/type';
import * as yup from 'yup';
import { Box, Grid, AlertColor, Card } from '@mui/material';
import Textinput from '../../components/formlib/Textinput';
import MediumTypography from '../../components/formlib/MediumTypography';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import {
  ContactsTypes,
  addContacts,
  getContactsBaedOnType,
  updateContacts,
} from '../../services/configApi/User';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import ModalPopup from '../../components/formlib/ModalPopup';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { EXCLUDING_DATES } from '../../services/Constant';
import {
  formatPhoneNumber,
  nameValidation,
  nameValidationWithNumbers,
} from '../../utils/formValidationUtil';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import { debounce } from 'lodash';
import CommentTextbox from '../../components/formlib/CommentTextbox';
import { useIntl } from 'react-intl';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';
import _ from 'lodash';
import { datePickerMinDateMaxDateValidate } from '../../utils/dateUtil';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';

const contactValues: ReferralContactType = {
  contactType: '',
  contactId: '',
  firstName: '',
  isEdit: false,
  referralContactDate: null,
  jobTitle: '',
  email: '',
  fax: '',
  phoneNumber: '',
  referralSource: '',
  referralReason: '',
  startDate: dayjs(new Date()),
  endDate: null,
  isActive: true,
  notes: '',
  companyOrMedicalPracticeName: '',
  isHouseHoldMember: false,
};

const initialValidationSchema = yup.object().shape({
  contactType: yup.string(),
  referralContactDate: yup
    .date()
    .required('AddNewReferralDetails.referralContactDate.Validation')
    .nullable(),
  email: yup.string().email('SignIn.validation.inValidEmail').nullable(),
  jobTitle: yup.string().nullable(),
  referralContactType: yup.string().nullable(),
  phoneNuber: yup.string().nullable(),
  fax: yup.string().min(12, 'FaxFormat.Validation').nullable(),
  referralSource: yup.string().required('referralSource.Validation'),
  referralReason: yup.string().required('referralReason.Validation'),
  firstName: yup.string().nullable(),
  contactId: yup.string().nullable(),
});
const validateForm = (value: ReferralContactType) => {
  const errors: Partial<ReferralContactType> = {};

  if (value.referralContactDate) {
    if (dayjs(value.referralContactDate).isAfter(dayjs())) {
      errors.referralContactDate = 'validDate';
    }
  }

  if (datePickerMinDateMaxDateValidate(value.startDate)) {
    errors.referralContactDate = 'datePickerMinDateMaxDateValidate';
  }
  return errors;
};

const ReferralContact = (props: {
  contacttype: string;
  onSuccess: (successerror: AlertColor, id: string, message: string) => void;
  referralValues: ReferralContactType;
  editable: boolean;
}) => {
  const rctl = useIntl();
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [hideBtn, setHideBtn] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const formRef = useRef<FormikProps<ReferralContactType>>(null);
  const [initialReferalValue, setInitialReferalValue] =
    useState<ReferralContactType>(contactValues);
  const { lookups, lookupsDemographics } = useContext(
    LookUpContext,
  ) as LookUpContextType;
  const [referralReasons, setReferralReasons] = useState<OptionType[]>([]);
  const [referralSources, setReferralSources] = useState<OptionType[]>([]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (lookups === null) {
      return;
    }
    setReferralReasons(() => {
      return lookups.referralReason.map((reason) => {
        return {
          label: reason.description,
          id: reason.code,
        };
      });
    });
    setReferralSources(() => {
      return lookups.referralSource.map((source) => {
        return {
          label: source.description,
          id: source.code,
        };
      });
    });
  }, [lookups]);

  useEffect(() => {
    if (!props.editable && props.referralValues.contactId !== '') {
      const clientId: string = localStorage.getItem('ClientId') ?? '';

      getContactsBaedOnType(clientId, props.referralValues.contactId)
        .then(async (response: ContactsTypes[]) => {
          if (response.length > 0) {
            const res = response[0] as ReferralContactType;
            if (res.contactType === 'Referral Contact') {
              setInitialReferalValue({
                contactId: res.contactId,
                firstName: res.firstName,
                referralContactDate: EXCLUDING_DATES.includes(
                  res.referralContactDate as string,
                )
                  ? null
                  : res.referralContactDate,
                jobTitle: res.jobTitle,
                startDate:
                  res.startDate === null ? dayjs(new Date()) : res.startDate,
                endDate: res.endDate,
                isActive: res.isActive,
                email: res.email,
                contactType: props.contacttype,
                isEdit: true,
                fax: res.fax,
                phoneNumber: res.phoneNumber,
                referralSource: res.referralSource,
                referralReason: res.referralReason,
                notes: res.notes,
                companyOrMedicalPracticeName: res.companyOrMedicalPracticeName,
                isHouseHoldMember: res.isHouseHoldMember,
              });
              formRef.current?.setValues(() => ({
                contactId: res.contactId,
                firstName: res.firstName,
                referralContactDate: EXCLUDING_DATES.includes(
                  res.referralContactDate as string,
                )
                  ? null
                  : res.referralContactDate,
                jobTitle: res.jobTitle,
                startDate:
                  res.startDate === null ? dayjs(new Date()) : res.startDate,
                endDate: res.endDate,
                isActive: res.isActive,
                email: res.email,
                contactType: props.contacttype,
                isEdit: true,
                fax: res.fax,
                phoneNumber: res.phoneNumber,
                referralSource: res.referralSource,
                referralReason: res.referralReason,
                notes: res.notes,
                companyOrMedicalPracticeName: res.companyOrMedicalPracticeName,
                isHouseHoldMember: res.isHouseHoldMember,
              }));

              setHideBtn(true);
            }
          }
        })
        .catch((error) => {
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('Family.gettoastrError');
            setToastrDefaultMessage('Failed to get contact details');
          }
        });
    }
  }, [props.referralValues, props.editable]);

  const handleUpdate = (updatedValues: ReferralContactType) => {
    updatedValues.contactType = props.contacttype;
    updatedValues.startDate =
      updatedValues.startDate === null
        ? null
        : dayjs(updatedValues.startDate).format('MM/DD/YYYY');
    updatedValues.endDate =
      updatedValues.endDate === null
        ? null
        : dayjs(updatedValues.endDate).format('MM/DD/YYYY');

    updateContactsAPI(updatedValues);
  };

  const updateContactsAPI = useCallback(
    debounce((vals: ReferralContactType) => {
      updateContacts(props.referralValues.contactId, vals)
        .then(async (response) => {
          if (response) {
            setOpen(true);
            setToastrVariable('success');
            setToastrId('Contact.updateMessage');
            setToastrDefaultMessage(response.message);
            props.onSuccess(
              'success',
              'Contact.updateMessage',
              'Contact Updated Successfully',
            );
          }
        })
        .catch(async (error) => {
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            props.onSuccess(
              'error',
              'Contact.updateFailureMessage',
              'Failed to update contact details',
            );
            setToastrId('Contact.updateFailureMessage');
            setToastrDefaultMessage('Failed to update contact details');
          }
        });
    }, 1000),
    [],
  );

  const addContactsAPI = useCallback(
    debounce((id: string, formVals: ReferralContactType) => {
      addContacts(id, formVals)
        .then(async (response) => {
          if (response) {
            setOpen(true);
            setToastrVariable('success');
            setToastrId('Contact.createdMessage');
            setToastrDefaultMessage(response.message);
            props.onSuccess(
              'success',
              'Contact.createdMessage',
              'Contact Added Successfully',
            );
          }
        })
        .catch(async (error) => {
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            props.onSuccess(
              'error',
              'Contact.updateCreateMessage',
              'Failed to create contact details',
            );
            setToastrId('Contact.updateCreateMessage');
            setToastrDefaultMessage('Failed to create contact details');
          }
        });
    }, 1000),
    [],
  );

  const formHandler = (formValues: ReferralContactType) => {
    const clientId: string = localStorage.getItem('ClientId') ?? '';

    formValues.contactType = props.contacttype;
    formValues.startDate =
      formValues.startDate === null
        ? null
        : dayjs(formValues.startDate).format('MM/DD/YYYY');
    formValues.endDate =
      formValues.endDate === null
        ? null
        : dayjs(formValues.endDate).format('MM/DD/YYYY');

    addContactsAPI(clientId, formValues);
  };

  const clearForm = () => {
    setOpenModal(false);
    formRef.current?.setValues(initialReferalValue);
  };

  return (
    <>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Formik
        initialValues={contactValues}
        innerRef={formRef}
        enableReinitialize={true}
        validateOnChange={true}
        validate={validateForm}
        validationSchema={initialValidationSchema}
        onSubmit={(values) => {
          const finalValues = JSON.parse(JSON.stringify(values));

          if (finalValues.endDate) {
            if (finalValues.endDate == dayjs(new Date()).format('MM/DD/YYYY')) {
              finalValues.isActive = true;
            } else if (
              finalValues.endDate < dayjs(new Date()).format('MM/DD/YYYY')
            ) {
              finalValues.isActive = false;
            }
          }

          if (finalValues.isEdit) {
            handleUpdate(finalValues);
          } else {
            formHandler(finalValues);
          }
        }}
      >
        {({
          setFieldValue,
          handleSubmit,
          errors,
          touched,
          values,
          initialValues,
          handleChange,
        }) => (
          <>
            <Card
              sx={{
                padding: '10px 24px 24px 24px',
                my: '0px',
                ml: '0px',
                height: '100%',
                overflow: 'hidden',
                borderTopLeftRadius: '0px',
                borderTopRightRadius: '0px',
                boxShadow:
                  '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 4px 3px 0px rgba(0,0,0,0.12)',
              }}
            >
              <Grid container rowSpacing={'24px'} columnSpacing={'40px'}>
                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name="firstName"
                    labelid="AddNewReferralDetails.Name"
                    defaultlabelid="Name"
                    Value={values.firstName}
                    inputProps={{
                      maxLength: 50,
                    }}
                    handlechange={(value: string) => {
                      const validatedValue = nameValidation(value);
                      if (validatedValue !== undefined) {
                        setFieldValue('firstName', validatedValue);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name="referralContactJobTitle"
                    Value={values.jobTitle}
                    labelid="AddNewReferralDetails.JobTitle"
                    defaultlabelid="Job Title"
                    inputProps={{
                      maxLength: 50,
                    }}
                    handlechange={(value: string) => {
                      const validatedValue = nameValidation(value);
                      if (validatedValue !== undefined) {
                        setFieldValue('jobTitle', validatedValue);
                      }
                    }}
                  />
                  {errors && errors.jobTitle && touched && touched.jobTitle && (
                    <MediumTypography
                      labelid={errors.jobTitle}
                      defaultlabel="Job Title is Required"
                      className="errorText-md"
                    />
                  )}
                </Grid>
                <Grid item xs={4.5} lg={4.5}>
                  {values.referralContactDate && (
                    <DatePickerComponent
                      value={dayjs(values.referralContactDate) as Dayjs}
                      labelid="AddNewReferralDetails.referralDate"
                      disableFuture={true}
                      minDate={dayjs(lookupsDemographics?.dateOfBirth)}
                      defaultlabelid="Referral Contact Date"
                      handlechange={(date: Dayjs | null) => {
                        const formattedDate = dayjs(date).format('MM/DD/YYYY');
                        if (date == null) {
                          setFieldValue('referralContactDate', null);
                        } else {
                          setFieldValue('referralContactDate', formattedDate);
                        }
                      }}
                      required={true}
                    />
                  )}
                  {!values.referralContactDate && (
                    <DatePickerComponent
                      value={null}
                      labelid="AddNewReferralDetails.referralDate"
                      disableFuture={true}
                      minDate={dayjs(lookupsDemographics?.dateOfBirth)}
                      defaultlabelid="Referral Contact Date"
                      handlechange={(date: Dayjs | null) => {
                        const formattedDate = dayjs(date).format('MM/DD/YYYY');
                        if (date == null) {
                          setFieldValue('referralContactDate', null);
                        } else {
                          setFieldValue('referralContactDate', formattedDate);
                        }
                      }}
                      required={true}
                    />
                  )}

                  {errors.referralContactDate &&
                    touched.referralContactDate && (
                      <MediumTypography
                        labelid={errors.referralContactDate}
                        defaultlabel="Referral Contact Date is Required"
                        className="errorText-md"
                      />
                    )}
                </Grid>
                <Grid item xs={4.5} lg={4.5}>
                  <DropdownComponent
                    names={referralSources}
                    labelid="AddNewReferralDetails.referralSource"
                    defaultlabelid="Referral Source"
                    value={values.referralSource}
                    handleChange={handleChange('referralSource')}
                    Required
                  />
                  {errors.referralSource && touched.referralSource && (
                    <MediumTypography
                      labelid={errors.referralSource}
                      defaultlabel="Referral Source is Required"
                      className="errorText-md"
                    />
                  )}
                </Grid>

                <Grid item xs={4.5} lg={4.5}>
                  <DropdownComponent
                    names={referralReasons}
                    labelid="AddNewReferralDetails.referralReason"
                    defaultlabelid="Referral Reason"
                    value={values.referralReason}
                    handleChange={handleChange('referralReason')}
                    Required
                  />
                  {errors.referralReason && touched.referralReason && (
                    <MediumTypography
                      labelid={errors.referralReason}
                      defaultlabel="Referral Reason is Required"
                      className="errorText-md"
                    />
                  )}
                </Grid>
                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name="referralContactEmail"
                    Value={values.email}
                    labelid="AddNewReferralDetails.Email"
                    defaultlabelid="Email"
                    Required={false}
                    inputProps={{
                      maxLength: 50,
                    }}
                    handlechange={(value: string) => {
                      setFieldValue(`email`, value);
                    }}
                  />
                  {errors.email && touched.email && (
                    <MediumTypography
                      labelid={errors.email}
                      defaultlabel="Invalid Email Address"
                      className="errorText-md"
                    />
                  )}
                </Grid>

                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name="referralContactPhone"
                    Value={values.phoneNumber}
                    labelid="AddNewReferralDetails.referralPhone"
                    defaultlabelid="Phone"
                    inputProps={{
                      maxLength: 50,
                    }}
                    handlechange={(value: string) => {
                      setFieldValue('phoneNumber', formatPhoneNumber(value));
                    }}
                  />
                  {errors &&
                    errors.phoneNumber &&
                    touched &&
                    touched.phoneNumber && (
                      <MediumTypography
                        labelid={errors.phoneNumber}
                        defaultlabel="Phone is Required"
                        className="errorText-md"
                      />
                    )}
                </Grid>
                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name="referralContactFax"
                    Value={values.fax}
                    labelid="AddNewReferralDetails.Fax"
                    defaultlabelid="Fax"
                    Required={false}
                    inputProps={{
                      maxLength: 50,
                    }}
                    handlechange={(value: string) => {
                      setFieldValue('fax', formatPhoneNumber(value));
                    }}
                  />
                  {errors && errors.fax && touched && touched.fax && (
                    <MediumTypography
                      labelid={errors.fax}
                      defaultlabel="Invalid Fax Number"
                      className="errorText-md"
                    />
                  )}
                </Grid>
                <Grid item xs={4.5}>
                  <Textinput
                    name={`companyOrMedicalPracticeName`}
                    labelid="contacts.medicalOrFamilyPracticeName"
                    defaultlabelid="Company/Medical Practice Name"
                    handlechange={(value: string) => {
                      setFieldValue('companyOrMedicalPracticeName', value);
                    }}
                    inputProps={{
                      maxLength: 100,
                    }}
                    Value={values.companyOrMedicalPracticeName}
                  />
                </Grid>
                <Grid item xs={4.5}></Grid>
                <Grid
                  xs={4.5}
                  item
                  sx={{ marginTop: '-1%', marginBottom: '-1%' }}
                >
                  <CheckBoxComponent
                    name="isHouseHoldMember"
                    labelid="Contacts.HouseHold"
                    defaultlabelid="Household Member"
                    fontWeight={400}
                    fontFamily="Lato-Regular"
                    ischecked={values.isHouseHoldMember}
                    CheckHandleChange={(e) => {
                      setFieldValue('isHouseHoldMember', e.target.checked);
                    }}
                  />
                </Grid>
                <Grid xs={4.5} item></Grid>
                <Grid item xs={9} lg={9}>
                  <CommentTextbox
                    name="notes"
                    placeholder={rctl.formatMessage({
                      id: 'AddNewReferralDetails.notes',
                      defaultMessage: 'Notes',
                    })}
                    Value={values.notes}
                    maxLength={2000}
                    handlechange={(value: string) => {
                      const validatedValue = nameValidationWithNumbers(value);
                      if (validatedValue !== undefined) {
                        setFieldValue('notes', validatedValue);
                      }
                    }}
                  />

                  {errors.notes && touched.notes && (
                    <MediumTypography
                      labelid={errors.notes}
                      defaultlabel="Notes is Required"
                      className="errorText-md"
                    />
                  )}
                </Grid>
              </Grid>
            </Card>
            <Box sx={{ marginTop: '20px' }}>
              <Grid
                container
                direction="row"
                alignItems="right"
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Grid item sx={{ marginRight: '16px' }}>
                  <ButtonComponent
                    className="btn-primary btn-cancel"
                    variantType="contained"
                    labelId="Contacts.cancelbtn"
                    defaultLabelId="Cancel"
                    onClick={() => {
                      if (initialReferalValue.contactId !== '') {
                        if (_.isEqual(initialReferalValue, values)) {
                          formRef.current?.resetForm();
                          props.onSuccess('info', '', '');
                        } else {
                          setOpenModal(true);
                        }
                      } else {
                        if (!_.isEqual(initialReferalValue, values)) {
                          setOpenModal(true);
                        } else {
                          setOpenModal(false);
                          formRef.current?.resetForm();
                          props.onSuccess('info', '', '');
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item>
                  {!hideBtn && (
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      type="submit"
                      labelId="Contacts.save"
                      defaultLabelId="Add Contact"
                      onClick={() => {
                        handleSubmit();
                      }}
                    />
                  )}
                  {hideBtn && (
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      type="submit"
                      labelId="Contacts.update"
                      defaultLabelId="Update"
                      onClick={() => {
                        handleSubmit();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
            <ModalPopup
              open={openModal}
              description="formUnsavedChangesMessage"
              onCancel={() => setOpenModal(false)}
              onOk={() => {
                if (initialValues.contactId !== '') {
                  setOpenModal(false);
                  formRef.current?.setValues(formRef.current?.initialValues);
                } else {
                  clearForm();
                }
              }}
              labelId1="Clientpage.Nobtn"
              negativeActionLabel="No"
              labelId2="Clientpage.Yesbtn"
              positiveActionLabel="Yes"
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default ReferralContact;
