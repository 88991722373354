import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

interface PickerProps {
  value: Dayjs | null;
  sxButtonProps?: SxProps;
  onTimeChange: (newTime: Dayjs | null) => void;
  name?: string;
  minTime?: Dayjs;
  maxTime?: Dayjs;
  disable?: boolean;
  minuteStep?: number;
  needCrossIcon?: boolean;
  disableManualInput?: boolean;
}

const AppTimePicker: FC<PickerProps> = ({
  onTimeChange,
  value,
  sxButtonProps,
  minTime,
  maxTime,
  disable,
  minuteStep,
  needCrossIcon,
  disableManualInput,
  name = 'Time',
}) => {
  const [openTo, setOpenTo] = useState<
    'hours' | 'meridiem' | 'minutes' | 'seconds'
  >('hours');
  const [open, setOpen] = useState(false);
  const [valueTime, setValueTime] = useState<Dayjs | null>();
  const [helperTime, setHelperTime] = useState<Dayjs | null>();

  useEffect(() => {
    setValueTime(value);
  }, [value]);

  const handleDateChange = (newTime: Dayjs | null) => {
    if (newTime?.isValid()) {
      setHelperTime(newTime);
      setValueTime(newTime);
      onTimeChange(newTime);
    } else {
      setValueTime(null);
      onTimeChange(null);
    }
  };

  const roundTime = (time: dayjs.Dayjs, minuteSteps: number): dayjs.Dayjs => {
    const minutes = time.minute();
    const roundedMinutes = Math.floor(minutes / minuteSteps) * minuteSteps;
    return time.minute(roundedMinutes).second(0);
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          className={`timePickerStyle ${
            disable ? 'disabledTextColor' : 'notDisabledTextColor'
          }`}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          slotProps={{
            clearIcon: {
              sx: {
                marginRight: '24px',
              },
            },
            field: {
              clearable: needCrossIcon ?? true,
              readOnly: disable,

              onClear: () => {
                setHelperTime(null);
                setValueTime(null);
              },
            },
            textField: {
              disabled: disableManualInput ?? false,
              onBlur: () => {
                if (value) {
                  const step = minuteStep ?? 5;
                  const minutes = dayjs(value).minute();
                  if (minutes % step !== 0) {
                    const roundedTime = roundTime(value, step);
                    setValueTime(roundedTime);
                    onTimeChange(roundedTime);
                  }
                }
              },
              onFocus: () => {
                if (valueTime === null && helperTime) {
                  const step = minuteStep ?? 5;
                  setValueTime(roundTime(helperTime, step));
                  onTimeChange(roundTime(helperTime, step));
                }
              },
              onKeyDown: (event) => {
                if (
                  (event.code === 'Backspace' || event.code === 'Delete') &&
                  !disable
                ) {
                  setHelperTime(null);
                  setValueTime(null);
                }
              },
              size: 'small',
              color: 'primary',
            },
          }}
          sx={{
            width: '105px',
            display: 'flex',
            cursor: disable ? 'not-allowed' : 'pointer',
            backgroundColor: 'transparent',
            border: '0px solid transparent',
            color: '#97a6a5',
            fontSize: '14px',
            fontFamily: 'Lato-Regular',
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                border: '1px solid #00C6B8',
              },
              '&:hover fieldset': {
                borderColor: '#00C6B8',
              },
            },
            ...sxButtonProps,
          }}
          label={name}
          value={valueTime?.isValid() ? valueTime : null}
          openTo={openTo}
          minTime={minTime}
          maxTime={maxTime}
          minutesStep={minuteStep}
          timeSteps={{
            hours: 1,
            minutes: minuteStep ?? 5,
            seconds: 5,
          }}
          onChange={(newTime: Dayjs | null) => {
            handleDateChange(newTime);
            setOpenTo(openTo === 'hours' ? 'minutes' : 'hours');
          }}
          skipDisabled={true}
          disabled={disable}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default AppTimePicker;
