import React, { useEffect } from 'react';
import { ReactComponent as BeginIcon } from '../../assets/images/journey/beginIcon.svg';
import { ReactComponent as ClientIcon } from '../../assets/images/journey/clientIcon.svg';
import { ReactComponent as IntakeIcon } from '../../assets/images/journey/intakeIcon.svg';
import { ReactComponent as AssessmentIcon } from '../../assets/images/journey/assessmentIcon.svg';
import { ReactComponent as EligibilityIcon } from '../../assets/images/journey/eligibilityIcon.svg';
import { ReactComponent as IFSPIcon } from '../../assets/images/journey/ifspIcon.svg';
import { ReactComponent as TPCIcon } from '../../assets/images/journey/tpcIcon.svg';
import { ReactComponent as TransitionIcon } from '../../assets/images/journey/transitionIcon.svg';
import { ReactComponent as EndIcon } from '../../assets/images/journey/endIcon.svg';
import { ReactComponent as NotStartedIcon } from '../../assets/images/journey/notStartedStatus.svg';
import { ReactComponent as CompletedIcon } from '../../assets/images/journey/completedStatus.svg';
import { ReactComponent as InProgressIcon } from '../../assets/images/journey/inProgressStatus.svg';
import { Box } from '@mui/system';
import ClientJourneyFaceSheet, {
  ClientJourneyFaceSheetProps,
} from './ClientJourneyFaceSheet';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ClientJourneyType } from '../../utils/type';

interface TransitionType {
  data: ClientJourneyType[];
  ifspDueDate: string;
}

const iconsMap: Record<
  string,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  'backend.journey_name.referral_begin': BeginIcon,
  'backend.journey_name.client': ClientIcon,
  'backend.journey_name.intake': IntakeIcon,
  'backend.journey_name.evaluation': AssessmentIcon,
  'backend.journey_name.re-evaluation': AssessmentIcon,
  'backend.journey_name.eds': EligibilityIcon,
  'backend.journey_name.ifsp': IFSPIcon,
  'backend.journey_name.tpc': TPCIcon,
  'backend.journey_name.transition_planning': TransitionIcon,
  'backend.journey_name.referral_ends': EndIcon,
};

const statusMap: Record<
  string,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  Completed: CompletedIcon,
  InProgress: InProgressIcon,
  NotStarted: NotStartedIcon,
};

const ClientJourneyWrapper: React.FC<TransitionType> = ({
  data,
  ifspDueDate,
}) => {
  const [journeySteps, setJourneySteps] = React.useState<
    ClientJourneyFaceSheetProps[]
  >([]);

  useEffect(() => {
    const mappedJourney = data.map((step) => {
      return {
        routeId: step.backendId,
        journeyName: step.journeyName, // Extract the journey name from the full path
        Icon: getIcons(step.journeyName), // Assuming you have a function getIcons to get icons based on journey name
        StatusComponent: getStatus(step.status), // Assuming you have a function to get status component based on status
        status: step.status,
        ifspDueDate: ifspDueDate,
      };
    });

    // Set the mapped journey to state
    setJourneySteps(mappedJourney);
  }, [data]);
  const getIcons = (journeyName: string) => {
    const iconKey = Object.keys(iconsMap).find((key) =>
      journeyName.includes(key),
    );
    return iconKey ? iconsMap[iconKey] : BeginIcon;
  };

  const getStatus = (status: string) => {
    const statusKey = Object.keys(statusMap).find((key) =>
      status.includes(key),
    );
    return statusKey ? statusMap[statusKey] : InProgressIcon;
  };

  const showArrows = journeySteps.length > 9;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 9, // Adjust the number of slides to show
    slidesToScroll: 2,
    nextArrow: showArrows ? (
      <Box className="slick-arrow pb-2">{'>'}</Box>
    ) : (
      <Box></Box>
    ),
    prevArrow: showArrows ? (
      <Box className="slick-arrow pb-2">{'<'}</Box>
    ) : (
      <Box></Box>
    ),
  };

  return (
    <Box className="p-lg">
      <Slider {...settings} className="slick-slider">
        {journeySteps.map((step, index) => (
          <Box>
            <ClientJourneyFaceSheet
              key={index}
              journeyName={step.journeyName}
              routeId={step.routeId}
              Icon={step.Icon}
              StatusComponent={step.StatusComponent}
              status={step.status}
              ifspDueDate={ifspDueDate}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default ClientJourneyWrapper;
